import React, { useEffect, useState } from 'react'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material'
import FTSLeadMenu from './FTSLeadMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import {AiOutlineEdit, AiOutlineFilter} from 'react-icons/ai';
import { GoCrossReference } from "react-icons/go";
import { ButtonFilledAutoWidth } from '../../components/button';
import { GetFTSVerticalLeadReportService } from '../../services/ftsVerticalReportServices/FTSVerticalLeadReportServices';
import { DatePicker, Select } from 'antd';

function FTSVerticalLead() {

  const navigate = useNavigate()

  const location = useLocation()
  const {state} = useLocation()
  const [page,setpage] = useState(1)
  const [leads,setleads] = useState({datas:[],pagination:{total:0,totalPages:0}})

  const [search,setsearch] = useState({text:'',from_date:state?.from_date !== undefined ? state?.from_date : '',from_date1:state?.from_date1 !== undefined ? state?.from_date1 : '',to_date:state?.to_date !== undefined ? state?.to_date : '',to_date1:state?.to_date1 !== undefined ? state?.to_date1 : '',status:state?.status !== undefined ? state?.status : ''})
  
  // console.log("location",location)
  // console.log("state",state)
  // console.log("search",search)

  const stages = [{label:'Progress',value:'Progress'},{label:'Rejected',value:'Rejected'},{label:'Converted',value:'Converted'},{label:'Hold',value:'Hold'}]

  useEffect(()=>{
    getdata()
  },[page])

  async function getdata() {
    const response = await GetFTSVerticalLeadReportService(page,search?.text,search?.from_date1,search?.to_date1,search?.status)
    setleads(response?.data)
  }

  async function applyfilter() {
    setpage(1)
    const response = await GetFTSVerticalLeadReportService(1,search?.text,search?.from_date1,search?.to_date1,search?.status)
    setleads(response?.data)
  }

  async function resetfunc() {
    setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',status:''})
    setpage(1)
    const response = await GetFTSVerticalLeadReportService(1,'','','','')
    setleads(response?.data)
  } 


  return (
    <div className='h-screen max-h-screen'>
        <div className='flex'>
            <div className={`min-w-44`}>
                <FTSLeadMenu />
            </div>
            <div  className="w-[98%] px-4 pt-4">
            <div>
          <div className='flex items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total Vertical Leads  ({leads?.pagination?.total})</h6>
            <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? leads?.datas?.length > 0 ? 1 : 0 :  (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>

              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 
              <div>
                <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 mr-2 rounded-md border-slate-300' />
              </div> 

              <div className='mr-2'>
                      <Select
                        bordered={false}
                        placeholder={'Status'}
                        className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px] rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none' 
                        optionFilterProp="children"
                        onChange={(e)=>setsearch({...search,status:e})}
                        value={search?.status === '' ? null : search?.status}
                        options={stages}
                    />
                    </div>

          
              <AiOutlineFilter onClick={applyfilter} size={24} className='cursor-pointer bg-slate-700 p-[5px] rounded text-white' />


              <div className='ml-2'>
              <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
              </div>


       
            </div>
          </div>
              <>
                    {leads?.datas?.length > 0 && 
                    <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
                    <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                        <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[10%] px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600  border-r'>Designation</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600  border-r'>Requirment</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[7%]  px-2 py-1 font-[600] text-slate-600  border-r'>Status</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[8%]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Action</h6>
                    </div>
                  
                    {leads?.datas?.map((d,i)=>(
                    <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
                        <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1  border-r border-slate-200 '>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                        <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 border-r border-slate-200'>{d?.company}</h6>
                        <h6 className='text-[11px] font-[500] w-[10%]  px-2 py-1 font-[800] border-r border-slate-200'>{d?.name}</h6>
                        <h6 className='text-[11px] font-[500] w-[10%]  px-2 py-1 font-[800] border-r border-slate-200'>{d?.mobile}</h6>
                        <h6 className='text-[11px] w-[15%]  px-2 py-1  border-r border-slate-200'>{d?.email}</h6>   
                        <h6 className='text-[11px] w-[10%]  px-2 py-1  border-r border-slate-200'>{d?.designation}</h6>   
                        <h6 className='text-[12px] flex items-start break-word  font-[500] w-[15%] border-r px-2 py-1 border-slate-200 '>{d?.requirment}</h6>
                        <h6 className='text-[12px] flex items-start break-word  font-[500] w-[7%] border-r px-2 py-1 border-slate-200 '><span className='bg-slate-100 px-2 py-0.5 border-slate-400 border rounded text-[8px]'>{d?.status}</span></h6>
                        <h6 className='text-[12px] flex items-center  font-[500] w-[8%]  px-2 py-1 truncate border-slate-200 '>
                          <AiOutlineEdit className='mr-2' onClick={()=>navigate('edit',{state:d})} />
                          <GoCrossReference onClick={()=>navigate('/fts_leads/list/refer_my_lead',{state:d})}  />  
                        </h6>
                    </div>))}
                    </div>}
              </>
          
                 
                    {leads?.datas?.length === 0 &&
                      <div className='grid place-items-center mt-20  items-center justify-center'>
                      <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' /> 
                      <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                      <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
                    </div>
                    }
          </div>
            </div>
        </div>    

    </div>
  )
}

export default FTSVerticalLead