import React, { useEffect, useState } from 'react'
import FTSLeadMenu from './FTSLeadMenu'
import { GetFTSDashboardService } from '../../services/FTSLeadServices'
import { MdCall } from "react-icons/md";
import { CgDatabase } from "react-icons/cg";
import { useNavigate } from 'react-router-dom';
import { GetDepartmentService } from '../../services/DepartmentServices';
import ReactApexChart from 'react-apexcharts';


function FTSDashboard() {


  const navigate = useNavigate()
  const [data,setdata] = useState({})
  const [departments,setdepartments] = useState([])

  

  const [chartOptions1,setchartOptions1] = useState({
    series: [
      { 
        name:'Pending',
        data:[0,0,0,0]
      },
      {
        name:'Progress',
        data:[0,0,0,0]
      },
      {
        name:'Rejected',
        data:[0,0,0,0]
      },
      {
        name:'Converted',
        data:[0,0,0,0]
      },
     
    ],
    chart: {
      toolbar: {
        show: false
      }
    },
      plotOptions: {
        
        bar: {
          horizontal: false,
          dataLabels:{
            enabled: false
          },
          columnWidth: '55%',
          endingShape: 'rounded',
          dataLabels: {
            position: 'top', // top, center, bottom
          },
          
        },
      },
      dataLabels: {
        enabled: false
      },
      grid:{
        show: true,
        borderColor: '#fafafa',
      },
    
      stroke: {
        show: true,
        width: 1,
        curve: 'smooth',
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Transaction','Project','FMS','HR Labs','Fidelitus Gallery','Corp Team','Shilpa Foundation'],
      },
      yaxis: {
        title: {
          text: 'Lead Forcast'
        },
        labels:{
          formatter: function (val) {
            return  numDifferentiation(val)
          }
        }
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return numDifferentiation(val)
          }
        }
      },
      colors:['#008ffb','#ff4560','#ffb01a','#00e396']
  })

  function numDifferentiation(value) {
    const val = Math.abs(value)
    if (val >= 10000000) return `${(value / 10000000).toFixed(0)} Cr`
    if (val >= 100000) return `${(value / 100000).toFixed(0)} Lac`
    return value;
  }
    

  useEffect(()=>{
    getdata()
  },[])

  async function getdata() {
    const response = await GetFTSDashboardService()
    setdata(response?.data)

    const response1= await GetDepartmentService();
    let arr = []
    response1.data.datas.forEach((d)=>{
      if(d?.department_name !== 'Finance Team' && d?.department_name !== 'FTS Team' && d?.department_name !== 'Software Team' && d?.department_name !== 'Digital Media'){
        arr.push({label:d?.department_name,value:d?.id})
      }  
    })
    setdepartments(arr)
    makeanayticsFunc(response,arr)
  }

  


  async function makeanayticsFunc(response,departments) {
    let analytics_data = response?.data?.datas?.today_leads_shared_to_department


    let pending = []
    let progress = []
    let rejected = []
    let converted = []

     departments?.map((d)=>{
        let dataFind = analytics_data?.find((f)=>f?.department?._id == d?.value) 
        let pendingData = [null,undefined]?.includes(dataFind) ? 0 : (dataFind?.data?.find((f)=>f?._id?.converted === '3') !== null ? dataFind?.data?.find((f)=>f?._id?.converted === '3')?.count : 0)
        let progressData = [null,undefined]?.includes(dataFind) ? 0 : (dataFind?.data?.find((f)=>f?._id?.converted === '4') !== null ? dataFind?.data?.find((f)=>f?._id?.converted === '4')?.count : 0)
        let rejectedData = [null,undefined]?.includes(dataFind) ? 0 : (dataFind?.data?.find((f)=>f?._id?.converted === '2') !== null ? dataFind?.data?.find((f)=>f?._id?.converted === '2')?.count : 0)
        let convertedData = [null,undefined]?.includes(dataFind) ? 0 : (dataFind?.data?.find((f)=>f?._id?.converted === '1') !== null ? dataFind?.data?.find((f)=>f?._id?.converted === '1')?.count : 0)
        pending?.push(pendingData)
        progress?.push(progressData)
        rejected?.push(rejectedData)
        converted?.push(convertedData)
     })

     setchartOptions1({
      ...chartOptions1,series:[
      { 
        name:'Pending',
        data:pending
      },
      {
        name:'Progress',
        data:progress
      },
      {
        name:'Rejected',
        data:rejected
      },
      {
        name:'Converted',
        data:converted
      }
    ]})

 
  }

  

  let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });






  return (
    <div className='h-screen max-h-screen'>
    <div className='flex'>
        <div className={`min-w-44`}>
            <FTSLeadMenu />
        </div>
        <div  className="w-[98%] px-4 pt-4">
          
          <div className='border border-slate-100 p-2'>
            <h6 className='font-[800] mb-2 text-[14px]'>FTS Dashboard</h6>
            <div className='grid grid-cols-5 gap-2 items-center justify-evenly border-slate-100'>
                <div onClick={()=>navigate(`/calls/list`)} className=' bg-yellow-200 cursor-pointer px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdCall size={23} className='mr-2 bg-yellow-300 p-1 text-slate-800' />Calls Made</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.datas?.calls_count))?.split('.')[0].slice(1)}</h6>
                </div>

                <div onClick={()=>navigate(`/calls/list`)} className=' bg-blue-200 cursor-pointer px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdCall size={23} className='mr-2 bg-blue-300 p-1 text-slate-800' />Followup Calls</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.datas?.followup_count))?.split('.')[0].slice(1)}</h6>
                </div>

                <div onClick={()=>navigate(`/fts_leads/list`)} className='bg-orange-200 cursor-pointer px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><CgDatabase size={23} className='mr-2 bg-orange-300 p-1 text-slate-800' />FTS Own Leads</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.datas?.fts_total_leads_own))?.split('.')[0].slice(1)}</h6>
                </div>

                <div onClick={()=>navigate(`/fts_leads/shared_to_us`)} className='bg-amber-200 cursor-pointer px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><CgDatabase size={23} className='mr-2 bg-amber-300 p-1 text-slate-800' />FTS Leads Recieved</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.datas?.fts_total_leads_recieved))?.split('.')[0].slice(1)}</h6>
                </div>

                <div onClick={()=>navigate(`/fts_leads/list`)} className='bg-indigo-200 cursor-pointer px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><CgDatabase size={23} className='mr-2 bg-indigo-300 p-1 text-slate-800' />FTS Leads</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.datas?.fts_total_leads))?.split('.')[0].slice(1)}</h6>
                </div>

                <div onClick={()=>navigate(`/fts_leads/shared_by_us`)} className='bg-green-200 cursor-pointer px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><CgDatabase size={23} className='mr-2 bg-green-300 p-1 text-slate-800' />Leads Pending</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(![null,undefined]?.includes(data?.datas?.fts_total_leads_assigned?.find((f)=>f?._id?.converted === '3')) ? data?.datas?.fts_total_leads_assigned?.find((f)=>f?._id?.converted === '3')?.count : 0 ))?.split('.')[0].slice(1)}</h6>
                </div>

                <div onClick={()=>navigate(`/fts_leads/shared_by_us`)} className='bg-cyan-200 cursor-pointer px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><CgDatabase size={23} className='mr-2 bg-cyan-300 p-1 text-slate-800' />Leads Progress</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(![null,undefined]?.includes(data?.datas?.fts_total_leads_assigned?.find((f)=>f?._id?.converted === '4')) ? data?.datas?.fts_total_leads_assigned?.find((f)=>f?._id?.converted === '4')?.count : 0 ))?.split('.')[0].slice(1)}</h6>
                </div>

                <div onClick={()=>navigate(`/fts_leads/shared_by_us`)} className='bg-teal-200 cursor-pointer px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><CgDatabase size={23} className='mr-2 bg-teal-300 p-1 text-slate-800' />Leads Converted</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(![null,undefined]?.includes(data?.datas?.fts_total_leads_assigned?.find((f)=>f?._id?.converted === '1')) ? data?.datas?.fts_total_leads_assigned?.find((f)=>f?._id?.converted === '1')?.count : 0 ))?.split('.')[0].slice(1)}</h6>
                </div>

                <div onClick={()=>navigate(`/fts_leads/shared_by_us`)} className='bg-purple-200 cursor-pointer px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><CgDatabase size={23} className='mr-2 bg-purple-300 p-1 text-slate-800' />Leads Rejected</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(![null,undefined]?.includes(data?.datas?.fts_total_leads_assigned?.find((f)=>f?._id?.converted === '2')) ? data?.datas?.fts_total_leads_assigned?.find((f)=>f?._id?.converted === '2')?.count : 0 ))?.split('.')[0].slice(1)}</h6>
                </div>
              
            </div>  
          </div>

          <div className='border border-slate-100 p-2 mt-2'>
           <h6 className='font-[800] mb-2 text-[14px]'>FTS Analytics</h6>
           <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1.series} type="bar" width={'100%'}  height={220} />

          </div>


          <div className='border border-slate-100 flex justify-between p-2 mt-2'>
            <div className='p-2 min-w-[32%] max-h-[310px] overflow-y-scroll max-w-[32%] border'>
               <h6 className='font-[800] mb-2 text-[12px]'>Today Calls ({data?.datas?.today_calls?.length})</h6>
               <div className='border '>
                <div className='flex bg-slate-100 items-center'>
                  <h6 className='w-[40%] border-r border-slate-300 p-1 text-[12px]'>Contact Info</h6>
                  <h6 className='w-[30%] border-r border-slate-300 p-1 text-[12px]'>Stage</h6>
                  <h6 className='w-[30%] p-1 text-[12px]'>Remarks</h6>
                </div>
                {data?.datas?.today_calls?.map((c)=>(
                <div onClick={()=>navigate('/calls/list/edit',{state:c?._id})} key={c?._id} className='flex border-t cursor-pointer'>
                  <h6 className='min-w-[40%] max-w-[40%] border-r break-all border-slate-300 p-1 text-[11px]'>Company Info : <span className='font-[800]'>{c?.company_name}</span> / Contact Info : <span className='font-[800]'>{c?.contact_name}</span> / <span className='font-[800]'>{c?.phone}</span> / <span className='font-[800]'>{c?.email}</span></h6>
                  <h6 className='min-w-[30%] max-w-[30%] border-r border-slate-300 p-1 text-[12px]'>{c?.stage} / Called Owner : <span className='font-[800] text-[11px]'>{c?.calls_owner?.name}</span></h6>
                  <h6 className='min-w-[30%] max-w-[30%] p-1 text-[12px]'>{c?.remarks}</h6>
                </div>))}
               </div>
            </div>

            <div className='p-2 w-[32%] max-h-[310px] overflow-y-scroll border'>
               <h6 className='font-[800] mb-2 text-[12px]'>Today Leads Shared ({data?.datas?.today_leads_shared?.length})</h6>
               <div className='border '>
                <div className='flex bg-slate-100 items-center'>
                  <h6 className='w-[65%] border-r border-slate-300 p-1 text-[12px]'>Contact Info</h6>
                  <h6 className='w-[35%] p-1 text-[12px]'>Department</h6>
                </div>

                {data?.datas?.today_leads_shared?.map((c)=>(
                <div onClick={()=>navigate('/fts_leads_assign_remark/list/edit',{state:c})} key={c?._id} className='flex border-t cursor-pointer'>
                  <h6 className='min-w-[65%] max-w-[65%] border-r break-all border-slate-300 p-1 text-[11px]'>Company Info : <span className='font-[800]'>{c?.fts_lead?.company_name}</span> / Contact Info : <span className='font-[800]'>{c?.fts_lead?.contact_name}</span> / <span className='font-[800]'>{c?.fts_lead?.phone}</span> / <span className='font-[800]'>{c?.fts_lead?.email}</span></h6>
                  <h6 className='min-w-[35%] max-w-[35%] font-[800] p-1 text-[12px]'>{c?.department_id?.department_name}</h6>
                </div>))}

               </div>
            </div>

            <div className='p-2 w-[32%] max-h-[310px] overflow-y-scroll border'>
               <h6 className='font-[800] mb-2 text-[12px]'>Today Leads Recieved ({data?.datas?.today_leads_recieved?.length})</h6>
                
               <div className='border '>
               <div className='flex bg-slate-100 items-center'>
                 <h6 className='w-[33%] border-r border-slate-300 p-1 text-[12px]'>Received From</h6>
                 <h6 className='w-[33%] border-r border-slate-300 p-1 text-[12px]'>Department</h6>
                 <h6 className='w-[33%] p-1 text-[12px]'>Contact Info</h6>
               </div>

                {data?.datas?.today_leads_recieved?.map((c)=>(
                <div onClick={()=>navigate('/fts_leads/list/edit',{state:c?._id})} key={c?._id} className='flex border-t cursor-pointer'>
                  <h6 className='min-w-[33%] max-w-[33%] border-r break-all border-slate-300 p-1 text-[11px]'>{c?.lead_owner?.name}</h6>
                  <h6 className='min-w-[33%] max-w-[33%] border-r font-[800]  p-1 text-[12px]'>{c?.lead_owner_department?.department_name}</h6>
                  <h6 className='min-w-[33%] max-w-[33%] break-all p-1 text-[12px]'>Company Info : <span className='font-[800]'>{c?.company_name}</span> / Contact Info : <span className='font-[800]'>{c?.contact_name}</span> / <span className='font-[800]'>{c?.phone}</span> / <span className='font-[800]'>{c?.email}</span></h6>
                </div>))}
                </div> 

            </div>
          </div>





        </div>
    </div>
    </div>        
  )
}

export default FTSDashboard