import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import moment from 'moment';
import { DownloadCompanyLeadsService, FilterllCompanyLead1Service, GetCompanyLeadsService, GetTeamMembersLeadsService, SearchAllCompanyLeadService } from '../../services/AdminServicesfile/CompanyLeadServices';
import {
    IconButton,
  } from "@mui/material";
import {Drawer, Modal} from 'antd';
import {AiOutlineMail,AiOutlinePhone,AiOutlineCalendar,AiOutlineCloudDownload, AiOutlineClose, AiOutlineReload} from 'react-icons/ai';
import {HiLogout} from 'react-icons/hi';
import {CiStickyNote} from 'react-icons/ci';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { GetDepartmentService, GetUsersByDepartment } from '../../services/DepartmentServices';
import { Select } from 'antd';
import { ButtonFilledAutoWidth } from '../../components/button';
import { GetStageService } from '../../services/StageServices';
import { DatePicker } from 'antd'
import { GetTeamBasedOnYearInsentiveService, GetTeamMembersDetail } from '../../services/AdminServicesfile/TeamServices';
import { useSelector } from 'react-redux';
import DashboardMenu from '../dashboard/DashboardMenu';
import {RiBillLine} from 'react-icons/ri';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { AiOutlineEdit,AiOutlineFileExcel } from 'react-icons/ai';
import fileDownload from "js-file-download";
import { toast } from 'react-hot-toast';
import { GetLeadNoteService, GetLeadReminderService } from '../../services/LeadServices';
import {VscOpenPreview} from 'react-icons/vsc';
import {IoMdArrowForward} from 'react-icons/io';
import axios from 'axios';
import { AiOutlineDelete } from 'react-icons/ai';
import { DeleteLeadService } from '../../services/LeadServices';
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { GetReportingMembersDetail } from '../../services/AdminServicesfile/ReportingServices';
import {BiCheckbox, BiCheckboxSquare} from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { paginationAction } from '../../redux/actions/staticAction';


function TeamLeads() {

    const dispatch = useDispatch()
    const user = useSelector(state=>state.Auth)
    const user_department = user.department_id[0]

    const [table_heading,settable_heading] = useState({contact_name:true,mobile:true,email:true,designation:true,company_name:true,secondary_contact_person:false,secondary_contact_phone:false,secondary_contact_email:false,lead_source:false,industry:true,land_lord:false,lead_status:true,zone:false,sqft:false,seater:false,rent:false,sale:false,website:false,location:false,requirment:false,address:false,operators:false,transfered:false,important:false,color:false,client:false,sqft_info:false,follow_up_remark:false,called_status:false,follow_up_date:false,contact_stage:false,created_At:true})


    const paginationData = useSelector(state=>state.Static)

    let year = new Date().getFullYear().toString()
    const navigate = useNavigate();

    const [leads,setleads]  = useState({datas:[],pagination:{total:0,totalPages:0,limit:20}})

    
    const [selected_department,setselected_department] = useState(!['',null,undefined,'null','undefined']?.paginationData?.pagination?.selected_department ? paginationData?.pagination?.selected_department : null)
    const [selected_team,setselected_team] = useState(!['',null,undefined,'null','undefined']?.paginationData?.pagination?.selected_team ? paginationData?.pagination?.selected_team : null)
    const [selected_user,setselected_user] = useState(!['',null,undefined,'null','undefined']?.paginationData?.pagination?.selected_user ? paginationData?.pagination?.selected_user : null)
    const [selected_stage,setselected_stage] = useState(!['',null,undefined,'null','undefined']?.paginationData?.pagination?.selected_stage ? paginationData?.pagination?.selected_stage : null)
    const [selected_lead,setselected_lead] = useState({})
    
    const [attachment,setattachment] = useState([])
    const [teams,setteams] = useState([]) 
    const [note,setnote] = useState([])
    const [preview,setpreview] = useState(false)
    const [designtable,setdesigntable] = useState(false)


    const [departments,setdepartments] = useState([])
    const [users,setusers] = useState([])
    const [stages,setstages] = useState([])
    const [page,setpage] = useState(1)
    const [filter_drawer,setfilter_drawer] = useState(false)


    const [search,setsearch] = useState(
      {
        text:!['',null,undefined,'null','undefined']?.paginationData?.pagination?.text ? paginationData?.pagination?.text : '',
        from_date:!['',null,undefined,'null','undefined']?.paginationData?.pagination?.from_date ? paginationData?.pagination?.from_date : '',
        to_date:!['',null,undefined,'null','undefined']?.paginationData?.pagination?.to_date ? paginationData?.pagination?.to_date : '',
        from_date1:!['',null,undefined,'null','undefined']?.paginationData?.pagination?.from_date1 ? paginationData?.pagination?.from_date1 : '',
        to_date1:!['',null,undefined,'null','undefined']?.paginationData?.pagination?.to_date1 ? paginationData?.pagination?.to_date1 : '',
        activate:false
      }
    )

    const stage = selected_lead?.stage?.name === 'Hold' ? [{name:'contacts',value:'Contact',index:0},{name:'hold',value:'Hold',index:1},{name:'options_shared',value:'Options',index:2},{name:'inspections',value:'Inspection',index:3},{name:'loi_agreement',value:'Agreement',index:4},{name:'invoice_raised',value:'Invoice',index:5}] : [{name:'contacts',value:'Contact',index:0},{name:'options_shared',value:'Options',index:1},{name:'inspections',value:'Inspection',index:2},{name:'loi_agreement',value:'Agreement',index:3},{name:'invoice_raised',value:'Invoice',index:4}]


    useEffect(()=>{
      if(['hod','manager','reporting_manager']?.filter((f)=>user.roles?.includes(f))?.length > 0){
        setselected_department({label:user_department.department_name,value:user_department.id})
      }else{
        setselected_department(null)
      }
    },[])


    useEffect(()=>{
      initalFetch()
    },[])


    async function initalFetch(){

      let user_list = ''
      if(user.roles.includes('admin')){
         await getdepartment()
      }else if(user.roles.includes('hod')){
        setselected_department({label:user_department.department_name,value:user_department.id})
        await getuserbaseddepartment(user_department?.id) 
        await getstage(user_department?.id)
        if(user_department?.department_name === 'Transaction Team'){
          let teams_arr = []
          const response1 = await GetTeamBasedOnYearInsentiveService(user_department?.id)
          response1?.data?.datas?.forEach((d)=>{
            let users_arr = []
            let users_arr_id = []
            d?.users?.forEach((d1)=>{
              users_arr?.push({label:d1?.name,value:d1?._id})
              users_arr_id.push(d1?._id)
            })
            teams_arr?.push({value:d._id,label:d?.name,users:users_arr,users_id:users_arr_id})
          })
          setteams(teams_arr)
        } 
      }else if(user.roles.includes('manager') || user.roles.includes('reporting_manager')){
        setselected_department({label:user_department?.department_name,value:user_department?.id})
        getstage(user_department?.id)
        if(user_department?.department_name === 'Transaction Team'){
         let users = await getTeamMembersForCurrentYear()
         user_list = users.map((u)=>u?.value).join(',')+','+user.id
        }else{
          let users = await getTeamMembersForCurrentYear()
          user_list = users.map((u)=>u?.value).join(',')+','+user.id
        }
      }



      if(user.roles.includes('manager') || user.roles.includes('reporting_manager')){        
        await getData(
          search?.from_date1 !== undefined ? search?.from_date1 : '' ,
          search?.to_date1 !== undefined ? search?.to_date1 : '' ,
          search?.text !== undefined ? search?.text : '' ,
          selected_department?.value !== undefined ? selected_department?.value : '',
          selected_user?.value !== undefined ? selected_user?.value : user_list,
          selected_stage?.value !== undefined ? selected_stage?.value : '',
          page
        )
    }else{
      await getData(
        search?.from_date1 !== undefined ? search?.from_date1 : '' ,
        search?.to_date1 !== undefined ? search?.to_date1 : '' ,
        search?.text !== undefined ? search?.text : '' ,
        selected_department?.value !== undefined ? selected_department?.value : '',
        selected_user?.value !== undefined ? selected_user?.value : '',
        selected_stage?.value !== undefined ? selected_stage?.value : '',
        page
      )
    }
    }


    async function getstage(id){
      const response = await GetStageService('Lead_Stage',id)
      let arr = []
      response?.data?.data?.datas?.forEach((d)=>{
          if(d?.name !== 'Calls' && d?.name !== 'Walk-Ins' && d?.name !== 'Meetings' && d?.name !== 'Property-Visit'){
            arr.push({label:d?.name,value:d?._id})
          }
      })
      setstages(arr)
    }

    // get departments  
    async function getdepartment(){
        const response = await GetDepartmentService();
        let data = response?.data?.datas?.find((d)=>d?.id === user_department.id)
       
        let arr = []
        response?.data?.datas.forEach((d)=>{
          if(!['Finance Team','Digital Media']?.includes(d?.department_name)){
            arr.push({label:d?.department_name,value:d?.id})
          }
          
        })
        setdepartments(arr)

        if(!user.roles.includes('admin')){ 
            if(user.roles.includes('hod')){
              getuserbaseddepartment(data?.id) 

              if(data?.department_name === 'Transaction Team'){
                  let teams_arr = []
                  let data = response?.data?.datas?.find((d)=>d?.department_name === 'Transaction Team')
                  const response1 = await GetTeamBasedOnYearInsentiveService(data?.id)
                  response1?.data?.datas?.forEach((d)=>{
                    let users_arr = []
                    let users_arr_id = []
                    d?.users?.forEach((d1)=>{
                      users_arr?.push({label:d1?.name,value:d1?._id})
                      users_arr_id.push(d1?._id)
                    })
                    teams_arr?.push({value:d._id,label:d?.name,users:users_arr,users_id:users_arr_id})
                  })
                  setteams(teams_arr)
              }
            }else if(user.roles.includes('manager')){
              getstage(data?.id)
              getTeamMembersForCurrentYear()
            }else if(user.roles.includes('reporting_manager')){
              getstage(data?.id)
              getTeamMembersForCurrentYear()
            }
        }

        if(!user.roles.includes('admin')){
          setselected_department({label:user?.department_id[0]?.department_name,value:user?.department_id[0]?.id})
          if(user.roles.includes('hod')){
            getuserbaseddepartment(user?.department_id[0]?.id)
          }else if(user.roles.includes('manager')){
            getTeamMembersForCurrentYear()
          }
        }
        
    }
    
    // get team member if roles become manager or reporting_manager
    async function getTeamMembersForCurrentYear(){
      let arr = []

      if(user.roles.includes('manager')){
        const response = await GetTeamMembersDetail(user?.id)
        response?.data?.datas[0]?.users?.forEach((d)=>{
            arr.push({label:d?.name,value:d?._id})
        })
        setusers(arr)


        let users_list = '';
        if(selected_user === null ){
            users_list = arr.map((a)=>a?.value)?.join(',')         
        }else{
            users_list = selected_user?.value
        }

       
       

      }else if(user.roles.includes('reporting_manager')){
        const response = await GetReportingMembersDetail(user?.id)
        response?.data?.datas[0]?.users?.forEach((d)=>{
            arr.push({label:d?.name,value:d?._id})
        })
        setusers(arr)

        

        let users_list = '';
        if(selected_user === null){
            users_list = arr.map((a)=>a?.value)?.join(',')         
        }else{
            users_list = selected_user?.value
        }
      
      }  
      return arr
    }

    // get team member based department 
    async function getuserbaseddepartment(id){
      if(id !== undefined){
      const response = await GetUsersByDepartment(id) 
        let arr = []
        response?.data?.datas?.forEach((d)=>{
            arr.push({label:d?.name,value:d?._id})
        })
      setusers(arr)
      }
    }

    // delete data  
    async function deleteData(d){
      const response = await DeleteLeadService(d._id)
      if(response.status === 200){
        toast.success("Deleted Successfully")
      }
    }

    // apply filter function 
    async function applyfilterfunction(){
      console.log("clicked here")
      setpage(1)
      await getData(
        search?.from_date1,
        search?.to_date1,
        search?.text,
        selected_department?.value !== undefined ? selected_department?.value : '',
        selected_user?.value !== undefined ? selected_user?.value : '',
        selected_stage?.value !== undefined ? selected_stage?.value : '',
        1
      )

      dispatch(paginationAction({
        type:'TeamLeads',
        text:search?.text,
        from_date:search?.from_date,
        to_date:search?.to_date,
        from_date1:search?.from_date1,
        to_date1:search?.to_date1,
        activate:true,
        selected_department:selected_department,
        selected_team:selected_team,
        page:1,
        selected_stage:selected_stage,
        selected_user:selected_user,
        users:users
      }))
    }
  
   
    // console.log("users",users)
    
    // select change 
    async function handlechange(val,type){
      // console.log("clear teig",val,type)
        if(type === 'department'){
            setpage(1)
            let department = departments.find(d=>d.value === val)
            setselected_department(department)
            if(department?.label === 'Transaction Team'){
              let teams_arr = []
              await getuserbaseddepartment(val)
              const response1 = await GetTeamBasedOnYearInsentiveService(val)
              response1?.data?.datas?.forEach((d)=>{
                let users_arr = []
                let users_arr_id = []
                d?.users?.forEach((d1)=>{
                  users_arr?.push({label:d1?.name,value:d1?._id})
                  users_arr_id.push(d1?._id)
                })
                teams_arr?.push({value:d._id,label:d?.name,users:users_arr,users_id:users_arr_id})
              })
              setselected_team(null)
              setteams(teams_arr)
              getstage(department.value)
            }else{
              setselected_department(department !== undefined ? department : null)
              setselected_team(null)
              if(department?.value !== undefined){
                await getuserbaseddepartment(department?.value)
                getstage(department.value)
              }
            }
        

          // setselected_stage(null)
          await getData(search?.from_date1,search?.to_date1,search?.text,department?.value,selected_user?.value !== undefined ? selected_user?.value :'',selected_stage?.value !== undefined ? selected_stage?.value : '', 1);
          
          dispatch(paginationAction({
            type:'TeamLeads',
            text:search?.text,
            from_date:search?.from_date,
            to_date:search?.to_date,
            from_date1:search?.from_date1,
            to_date1:search?.to_date1,
            activate:true,
            selected_department:department,
            selected_team:selected_team,
            page:1,
            selected_stage:selected_stage,
            selected_user:selected_user,
            users:users
          })) 
        
        

        }else if(type === 'users'){
            if(val !== undefined){
              let user1 = users.find(d=>d.value === val)
              setpage(1)
              setselected_user(user1 !== undefined ? user1 : null)
              await getData(search?.from_date1,search?.to_date1,search?.text,selected_department?.value !== undefined ? selected_department?.value : '' , user1?.value !== undefined ? user1?.value : '',selected_stage?.value !== undefined ? selected_stage?.value : '', 1);
              
              dispatch(paginationAction({
                type:'TeamLeads',
                text:search?.text,
                from_date:search?.from_date,
                to_date:search?.to_date,
                from_date1:search?.from_date1,
                to_date1:search?.to_date1,
                activate:true,
                selected_department:selected_department,
                selected_team:selected_team,
                page:1,
                selected_stage:selected_stage,
                selected_user:user1,
                users:users
              })) 
            }else{
              setpage(1)
              setselected_user(null)
              if(user?.roles?.includes('manager') || user?.roles?.includes('reporting_manager')){
                let userList = users.map((u)=>u.value).join(',')
                await getData(search?.from_date1,search?.to_date1,search?.text,selected_department?.value !== undefined ? selected_department?.value : '' , userList,selected_stage?.value !== undefined ? selected_stage?.value : '', 1);
              }else{
                await getData(search?.from_date1,search?.to_date1,search?.text,selected_department?.value !== undefined ? selected_department?.value : '' ,  '',selected_stage?.value !== undefined ? selected_stage?.value : '', 1);

              }
            }

        }else if(type === 'stages'){
            let stage = stages.find(d=>d.value === val)
            setpage(1)
            setselected_stage(stage !== undefined ? stage : null)
            if(user.roles.includes('manager') || user.roles.includes('reporting_manager')){
              let selected_user_list = ''
              if(selected_user !== null && selected_user.value !== undefined && selected_user.value !== null && selected_user.value !== ''){
                selected_user_list = user.id
              }else{
                selected_user_list = users.map((u)=>u.value).join(',') + user.id
              }
              await getData(search?.from_date1,search?.to_date1,search?.text,selected_department?.value !== undefined ? selected_department?.value : '' , selected_user_list , stage?.value !== undefined ? stage?.value : '', 1);
            }else{
              await getData(search?.from_date1,search?.to_date1,search?.text,selected_department?.value !== undefined ? selected_department?.value : '' , selected_user?.value !== undefined ? selected_user?.value : '', stage?.value !== undefined ? stage?.value : '', 1);
            }
           
       
            dispatch(paginationAction({
              type:'TeamLeads',
              text:search?.text,
              from_date:search?.from_date,
              to_date:search?.to_date,
              from_date1:search?.from_date1,
              to_date1:search?.to_date1,
              activate:true,
              selected_department:selected_department,
              selected_team:selected_team,
              page:1,
              selected_stage:stage !== undefined ? stage : null,
              selected_user:selected_user,
              users:users
            })) 
       
        }else if(type === 'team'){
          if(val !== undefined){
              let team_select = teams?.find((t)=>t?.value === val)
              
              setusers(team_select !== undefined ? team_select.users : [])
              setselected_team(team_select !== undefined ? team_select : null)
              setselected_user(null)
              setpage(1)
              let userList = team_select.users.map((u)=>u.value).join(',')
              await getData(search?.from_date1,search?.to_date1,search?.text,selected_department?.value !== undefined ? selected_department?.value : '' , userList, stage?.value !== undefined ? stage?.value : '', 1);
              dispatch(paginationAction({
                type:'TeamLeads',
                text:search?.text,
                from_date:search?.from_date,
                to_date:search?.to_date,
                from_date1:search?.from_date1,
                to_date1:search?.to_date1,
                activate:true,
                selected_department:selected_department,
                selected_team:team_select,
                page:1,
                selected_stage:selected_stage,
                selected_user:selected_user,
                users:team_select.users
              })) 
          }else{
            setselected_team(null)
            setpage(1)
            await getData(search?.from_date1,search?.to_date1,search?.text,selected_department?.value !== undefined ? selected_department?.value : '' , '' , stage?.value !== undefined ? stage?.value : '', 1);
            dispatch(paginationAction({
              type:'TeamLeads',
              text:search?.text,
              from_date:search?.from_date,
              to_date:search?.to_date,
              from_date1:search?.from_date1,
              to_date1:search?.to_date1,
              activate:true,
              selected_department:selected_department,
              selected_team:null,
              page:1,
              selected_stage:selected_stage,
              selected_user:null,
              users:users
            })) 
          }
        }
    }
 
    //  note attachment section
    async function getnote_attachment_basedonselectedlead(id){
        const response = await GetLeadNoteService(id)
        const response1 = await GetLeadReminderService(id) 
        setnote(response?.data)
        setattachment(response1?.data)
        
    }

    // console.log("roles here --->", user?.roles)

    const resetfilter=async()=>{
      setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false})
      setselected_department(null)
      setselected_user(null)
      setselected_team(null)
      setselected_stage(null)
      setpage(1)
     
      let roles = user?.roles
      if(roles?.includes('admin')){
        await getData('','','','','','',1);
        dispatch(paginationAction({
          type:'TeamLeads',
          text:search?.text,
          from_date:search?.from_date,
          to_date:search?.to_date,
          from_date1:search?.from_date1,
          to_date1:search?.to_date1,
          activate:true,
          selected_department:null,
          selected_team:null,
          page:1,
          selected_stage:null,
          selected_user:null,
          users:users
        }))
      }else if(roles?.includes('hod')){
        setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false})
        let departmentFind = user.department_id[0]
        setselected_department({label:departmentFind.department_name,value:departmentFind.id})
        if(departmentFind.department_name == 'Transaction Team'){
           await getTeamMembersForCurrentYear()
           await getuserbaseddepartment(departmentFind.id)
        }
        setselected_user(null)
        setselected_team(null)
        setselected_stage(null)
        setpage(1)
        await getData('','','',departmentFind?.id,'','',1);
        dispatch(paginationAction({
          type:'TeamLeads',
          text:search?.text,
          from_date:search?.from_date,
          to_date:search?.to_date,
          from_date1:search?.from_date1,
          to_date1:search?.to_date1,
          activate:true,
          selected_department:{label:departmentFind.department_name,value:departmentFind.id},
          selected_team:null,
          page:1,
          selected_stage:null,
          selected_user:null,
          users:users
        }))
      }else if(roles?.includes('manager') || roles?.includes('reporting_manager')){
        setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false})
        let departmentFind = user.department_id[0]
        setselected_department({label:departmentFind.department_name,value:departmentFind.id})
        let users = []
        if(departmentFind.department_name == 'Transaction Team'){
          users = await getTeamMembersForCurrentYear()
          // await getuserbaseddepartment(departmentFind.id)
        }

        let userList = users.length > 0 ? users?.map((u)=>u.value).join(',') : user.id
        setselected_user(null)
        setselected_team(null)
        setselected_stage(null)
        setpage(1)
        await getData('','','',departmentFind?.id,userList,'',1);

        dispatch(paginationAction({
          type:'TeamLeads',
          text:search?.text,
          from_date:search?.from_date,
          to_date:search?.to_date,
          from_date1:search?.from_date1,
          to_date1:search?.to_date1,
          activate:true,
          selected_department:{label:departmentFind?.department_name,value:departmentFind?.id},
          selected_team:null,
          page:1,
          selected_stage:null,
          selected_user:null,
          users:users
        }))
      }
      
    }

    // return file name 
    function returnfilename(v){
        let string = v?.split("/")[v?.split("/")?.length - 1]
        return string;
    }
    
    // download file
    const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
                toast.success("Attachment Downloaded") 
            })
    }
    
    // size file
    function getFileSizeString(fileSizeInBytes) {
        var i = -1;
        var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
        fileSizeInBytes /= 1024;
        i++;
        } while (fileSizeInBytes > 1024);
        return Math.max(fileSizeInBytes, 0.1).toFixed(1).slice(0,4) + byteUnits[i];
    }

    function resetformtable_heading(){
      settable_heading({contact_name:true,mobile:true,designation:true,email:true,company_name:true,industry:true,lead_status:true,lead_owner:true,created_At:true,actions:true,requirment:false,location:false,sqft_info:false,lead_operator:false,called_status:false,follow_up_date:false,follow_up_remark:false})
      setdesigntable(!designtable)
    }  

    async function setpagefun(v){
      setpage(v)
      if(user?.roles?.includes('manager') || user?.roles?.includes('reporting_manager')){
        let userList = selected_user?.value !== '' ? selected_user?.value : users.length > 0 ? users?.map((u)=>u.value).join(',') : user.id
        await getData(search?.from_date1,search?.to_date1,search?.text,selected_department?.value !== undefined ? selected_department?.value : '' , !['',null,undefined]?.includes(selected_user?.value) ? selected_user?.value : userList , selected_stage?.value !== undefined ? selected_stage?.value : '', v);
      }else{
        if(((user?.roles.includes('hod') || user?.roles.includes('admin')) && selected_department?.value == 'Transaction Team') && selected_team !== null){
          let userList = selected_team.users.map((u)=>u.value).join(',')
          await getData(search?.from_date1,search?.to_date1,search?.text,selected_department?.value !== undefined ? selected_department?.value : '' , !['',null,undefined]?.includes(selected_user?.value) ? selected_user?.value : selected_team !== null ? userList : '' , selected_stage?.value !== undefined ? selected_stage?.value : '', v);
        }else{
          await getData(search?.from_date1,search?.to_date1,search?.text,selected_department?.value !== undefined ? selected_department?.value : '' , selected_user?.value !== undefined ? selected_user?.value : '' , selected_stage?.value !== undefined ? selected_stage?.value : '', v);
        }
      }
    }

    async function downloadExcel(){
      let type = ''
      if(selected_stage?.label === 'Contact' ) type = 'Contact'
      if(selected_stage?.label === 'Options Shared' )  type = 'Options Shared'
      if(selected_stage?.label === 'Inspection' )  type = 'Inspection'
      if(selected_stage?.label === 'LOI/Agreement' )  type = 'LOI&Agreement'
      if(selected_stage?.label === 'Invoice Raised' )  type = 'Invoice Raised'
      if(selected_stage?.label === 'Calls' )  type = 'Calls'
      if(selected_stage?.label === 'Walk-Ins' )  type = 'Walk-Ins'
      if(selected_stage?.label === 'Property-Visit' )  type = 'Property-Visit'
      if(selected_stage?.label === 'Meetings' )  type = 'Meetings'
      if(selected_stage?.label === 'Hold' )  type = 'Hold'
              
      
      const response = await DownloadCompanyLeadsService(selected_department?.value !== undefined ? selected_department?.value : user?.roles?.includes('hod') ? user_department.id : null ,selected_stage?.value !== undefined  ? (selected_stage?.label !== 'LOI/Agreement' ? selected_stage?.label : type) : null,selected_user?.value !== undefined ? selected_user?.value : null,page)
      let path = response.data.path.replace('public/','')

      handleDownload1(`${process.env.REACT_APP_AWS_IMAGE_URL}${path}`,path.split('/')[path.split('/')?.length - 1])
    
    }

    const handleDownload1 = (url, filename) => {
      axios.get(url, {
          responseType: 'blob',
      })
          .then((res) => {
              fileDownload(res.data, filename)
              toast.success("File Downloaded")
          })
    }

    async function getData(from_date,to_date,search_text,department,user,stage,page) {
      const response = await FilterllCompanyLead1Service(from_date,to_date,search_text,department,user,stage,page)
      setleads(response?.data)
    }

  return (
    <div style={{width:'100%',minHeight:'100vh',maxHeight:'100vh',height:'100%',overflow:'hidden',position:'relative'}}>
   
    <Modal maskClosable={true} width={370} className='absolute left-[40%] top-0' open={designtable} footer={false} closable={false}>
      <div className='relative'>
         <AiOutlineReload onClick={()=>resetformtable_heading()} className='absolute right-7 top-0 cursor-pointer' />
         <AiOutlineClose onClick={()=>setdesigntable(!designtable)} className='absolute right-0 top-0 cursor-pointer' />
         <h6 className='text-[14px] font-[700]'>Customize Lead Table</h6>
         <h6 className='text-[11px] font-[400] bg-slate-100 p-1 my-1'>Use the below form to update the table customization as per your requirment</h6>

         <div className='flex flex-wrap  my-[5px]'>
           <div onClick={()=>settable_heading({...table_heading,contact_name:!table_heading.contact_name})} className={`flex items-center ${table_heading?.contact_name && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Contact Name </h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,mobile:!table_heading.mobile})} className={`flex items-center  ${table_heading?.mobile && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Mobile</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,designation:!table_heading.designation})} className={`flex items-center  ${table_heading?.designation && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Designation</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,email:!table_heading.email})} className={`flex items-center  ${table_heading?.email && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Email</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,company_name:!table_heading.company_name})} className={`flex items-center  ${table_heading?.company_name && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Company Name</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,industry:!table_heading.industry})} className={`flex items-center  ${table_heading?.industry && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Industry</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,requirment:!table_heading.requirment})} className={`flex items-center  ${table_heading?.requirment && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Requirment</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,sqft_info:!table_heading.sqft_info})} className={`flex items-center  ${table_heading?.sqft_info && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>SQFT Info</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,location:!table_heading.location})} className={`flex items-center  ${table_heading?.location && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Location</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,lead_status:!table_heading.lead_status})} className={`flex items-center  ${table_heading?.lead_status && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Lead Status</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,called_status:!table_heading.called_status})} className={`flex items-center  ${table_heading?.called_status && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Called Status</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,follow_up:!table_heading.follow_up})} className={`flex items-center  ${table_heading?.follow_up && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Follow Up</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,follow_up_date:!table_heading.follow_up_date})} className={`flex items-center  ${table_heading?.follow_up_date && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Follow Up Date</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,follow_up_remark:!table_heading.follow_up_remark})} className={`flex items-center  ${table_heading?.follow_up_remark && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Follow Up Remark</h6>
           </div>

           <div onClick={()=>settable_heading({...table_heading,lead_owner:!table_heading.lead_owner})} className={`flex items-center  ${table_heading?.lead_owner && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Lead Owner</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,lead_operator:!table_heading.lead_operator})} className={`flex items-center  ${table_heading?.lead_operator && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Lead Operators</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,created_At:!table_heading.created_At})} className={`flex items-center  ${table_heading?.created_At && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Created At</h6>
           </div>

         </div>
      </div>
    </Modal>
   
    <Drawer maskStyle={{background:'black',opacity:0.8}} onClose={()=>setpreview(false)}  maskClosable={()=>setpreview(false)} open={preview} width={600} closable={false}>
       
       <div >
         <div className='mb-3 -mt-3 flex items-center justify-between border-b pb-3 border-slate-100'>
          <h6  onClick={()=>setpreview(false)} className='flex cursor-pointer items-center'>
          <HiLogout size={20} className='text-slate-300' /> 
          <span className='ml-2 font-[600]'>Lead Preview</span>
          </h6>

          <h6 onClick={()=>navigate(`/leads/contacts/detail`,{state:selected_lead?._id})} className='border cursor-pointer flex items-center p-1.5 px-2 font-[500] text-[11px] border-slate-100 rounded'>View Full Detail <IoMdArrowForward size={15} className='ml-1' /></h6>
         </div>
         <div className='border relative border-slate-100 '>
          <div className='flex absolute right-2 top-1'>
              <CopyToClipboard text={selected_lead?.email}>
             <AiOutlineMail size={22} className='border cursor-pointer p-1 mr-2 rounded-full text-slate-400' />
             </CopyToClipboard>
             <CopyToClipboard text={selected_lead?.phone}>
             <AiOutlinePhone size={22} className='border cursor-pointer p-1 mr-2 rounded-full text-slate-400'  />
             </CopyToClipboard>
             
             <AiOutlineEdit onClick={()=>navigate('/leads/contacts/edit',{state:selected_lead?._id})} size={22} className='border cursor-pointer p-1 rounded-full text-slate-400'  />
          </div>
          <div className='flex p-2 items-center border-b   border-slate-100 '>
           <h6 className='bg-purple-200 w-9 h-9 text-[12px] flex items-center justify-center uppercase rounded-full '>{selected_lead?.contact_name?.slice(0,2)}</h6>
           <div className='ml-2'>
            <h6 className='mb-0 font-[700]'>{selected_lead?.contact_name}</h6>
            <h6 className='flex text-[11px] -mt-0.5 items-center text-slate-400'><AiOutlineMail  className='mr-2'/> {selected_lead?.email} <AiOutlinePhone className='mx-2' /> {selected_lead?.phone} </h6>
           </div>
          </div>
          <div className='flex items-center justify-evenly'>
            <div className='border-r  border-slate-100 w-full pl-3 py-2'>
              <h6 className='text-slate-400 text-[11px]'>Lead owner</h6>
              <h6 className='font-[600] text-[12px]'>{selected_lead?.lead_owner?.name}</h6>
            </div>

            <div className='border-r  border-slate-100 w-full pl-3  '>
              <h6 className='text-slate-400 text-[11px]'>Company</h6>
              <h6 className='font-[600] text-[12px]'>{selected_lead?.company_name}</h6>
            </div>

            <div className='border-r  border-slate-100 w-full pl-3  py-2'>
              <h6 className='text-slate-400 text-[11px]'>Job Title</h6>
              <h6 className='font-[600] text-[12px]'>{selected_lead?.designation}</h6>
            </div>

            <div className=' w-full pl-3 py-2'>
              <h6 className='text-slate-400 text-[11px]'>Requirment</h6>
              <h6 className='font-[600] text-[11px]'>{selected_lead?.sqft && 'SQFT,'} {selected_lead?.seater && 'SEATER,'} {selected_lead?.rent && 'RENT,'} {selected_lead?.sale && 'SALE'}</h6>
            </div>

          </div>
         </div>

        {/* <h6>{JSON.stringify(JSON.stringify(selected_lead?.stage?.name?.split(' '))?.includes('Invoice'))}</h6> */}
         <div className='mt-2 flex justify-between'>
         {stage?.map((s,i)=>(
          <h6 key={i} className={` w-full mx-0.5 font-[700] cursor-pointer text-center p-1 text-[11px] ${stage?.find((f)=>JSON.stringify(JSON.stringify(selected_lead?.stage?.name?.split(' '))?.includes(f?.value)) === 'true')?.index > i ? 'bg-green-100 text-green-600' : stage?.find((f)=>JSON.stringify(JSON.stringify(selected_lead?.stage?.name?.split(' '))?.includes(f?.value)) === 'true')?.index < i  ?  'bg-slate-100 text-slate-600' : 'bg-green-600 text-white'}   `}>{s?.value}</h6>
         ))}
         </div>

         <div className='flex items-center my-4 pb-4 border-slate-100 border-b justify-between'>
          <h6 className='text-[11px]'>Lead Source <span className='text-[11px] font-[700]'>{selected_lead?.lead_source?.name}</span> </h6>
          <h6 className='text-[11px]'>Last Activity : <span className='text-[11px] font-[700]'> {moment(selected_lead?.updatedAt).format('lll')}</span></h6>
         </div>


         {(attachment?.datas?.length === 0 && note?.datas?.length === 0 )&& 
              <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' /> 
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[80%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any particual notes or reminders added for this particular leads.</h6>
              </div>
         }

         {attachment?.datas?.length !== 0 && 
         <>
         <h6 className='text-[12px] font-[700]'> Reminder <span className='bg-slate-100 p-1 px-2 rounded font-[400] text-slate-800 text-[12px]'>{attachment?.datas?.length}</span></h6>
         {attachment?.datas?.map((n)=>(
         <div className='border mt-2 p-2 rounded border-slate-100'>
              <div className='border-b flex items-center justify-between pb-2 mb-2 border-slate-100'> 
                 <h6 className='text-[11px] flex items-center '><CiStickyNote size={21} className='bg-slate-100 text-blue-600 p-1 mr-2' /> <span className='font-[500] mr-1'>Reminder </span> by <span  className='font-[500] ml-1'> {n?.user_id?.name}</span></h6>
                 <h6 className='text-[10px] text-slate-400 flex items-center '><AiOutlineCalendar size={21} className='p-1 mr-1' /> <span className='font-[500] mr-1'>{moment(n?.createdAt)?.format('lll')}</span></h6>
              </div>   
              <h6 className='text-[12px] font-[500] mb-1'>{n?.title}</h6>
              <h6 className='leading-tight text-slate-400 text-[11px] font-[400]'>{n?.description} <span className='text-black'> {moment(n?.on_date_time)?.format('lll')} </span></h6>
              
         </div>
         ))}
         </>}

         {note?.datas?.length !== 0 && 
         <>
         <h6 className='text-[12px] font-[700] my-4'> Notes <span className='bg-slate-100 p-1 px-2 rounded font-[400] text-slate-800 text-[12px]'>{note?.datas?.length}</span></h6>
         
         {note?.datas?.map((n)=>(
         <div className='border mt-2 p-2 rounded border-slate-100'>
              <div className='border-b flex items-center justify-between pb-2 mb-2 border-slate-100'> 
                 <h6 className='text-[11px] flex items-center '><CiStickyNote size={21} className='bg-slate-100 text-blue-600 p-1 mr-2' /> <span className='font-[500] mr-1'>Note </span> by <span  className='font-[500] ml-1'> {n?.user_id?.name}</span></h6>
                 <h6 className='text-[10px] text-slate-400 flex items-center '><AiOutlineCalendar size={21} className='p-1 mr-1' /> <span className='font-[500] mr-1'>{moment(n?.createdAt)?.format('lll')}</span></h6>
              </div>   
              <h6 className='text-[12px] font-[500] mb-1'>{n?.note_title}</h6>
              <h6 className='leading-tight text-slate-400 text-[11px] font-[400]'>{n?.summary}</h6>
              {(n?.note_file !== null && n?.note_file !== '' && n?.note_file !== undefined) &&
                         <>
                         <div className='mt-1 border-slate-100  flex relative py-1'>
                          <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' alt="no" className='w-5 h-5 object-contain' />
                          <div className='ml-1 -mt-0.5'>
                          <h6 className='font-[600] text-[11px]'>{returnfilename(n?.note_file)}</h6>
                          <h6 className='font-[600] text-[9px] -mt-0.5'>{getFileSizeString(n?.filesize)} </h6>
                          </div>
                         {(n?.note_file !== null && n?.note_file !== undefined && n?.note_file !== '') && <AiOutlineCloudDownload size={23} className='absolute right-1 top-1.5 bg-slate-100 p-1 rounded-full' onClick={()=>handleDownload(`${process.env.REACT_APP_AWS_IMAGE_URL}${n?.note_file}`,returnfilename(n?.note_file))}/>}

                          </div>
                          </>}
         </div>
         ))}
         </>}


       </div>
    </Drawer>
   
    <div > 
        <div className='flex'>
        <div className='w-44 border-r'>
        <DashboardMenu />
        </div>  
        <div className='px-4 w-[87%] pt-5'>
      
      
        
        <div>
          <div>
            <div className={`border-b ${(user?.roles?.includes('admin') || user?.roles?.includes('hod')) ? 'block' : 'flex justify-between'} pb-2 mb-5`}>
              <div className={`flex items-center mb-1 ${(user?.roles?.includes('admin') || user?.roles?.includes('hod')) && 'justify-between'}`}>
              <h6 className='font-[700] text-[14px] '> {!(user?.roles?.includes('hod') || user?.roles?.includes('manager')) ?  selected_department?.label?.split(' ')[0]  :  selected_department !== null ? 'Team' : ' Fidelitus'} Leads ({leads?.pagination?.total})</h6>

            {(user?.roles?.includes('admin') || user?.roles?.includes('hod')) &&
            <div className='flex items-center  text-[12px] mr-2'>
                <h6 className='mr-2 font-[600]'>{page === 1 ? leads?.datas?.length > 0 ? 1 : 0 : (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                <IconButton  onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>
                <div>
                <IconButton onClick={()=>{page > 1 ? setpagefun(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpagefun(page+1) : console.log('')}} ><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                </div>
            </div>}
               </div>

          
            <div className='flex items-center ' >
              {!(user?.roles?.includes('admin') || user?.roles?.includes('hod')) &&
                <div className='flex items-center  text-[12px] mr-2'>
                  <h6 className='mr-2 font-[600]'>{page === 1 ? leads?.datas?.length > 0 ? 1 : 0 : (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                  <IconButton  onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>

                  <div>
                  <IconButton onClick={()=>{page > 1 ? setpagefun(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                  <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpagefun(page+1) : console.log('')}} ><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                  </div>
              </div>}
              

              

                  <DatePicker size='small'  ampm={false} placeholder='From Date' className='text-[11px] py-[4px]   w-28 border-slate-300 mr-1'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

                  <DatePicker size='small'  ampm={false} placeholder='To Date' className='text-[11px] py-[4px]  w-28 border-slate-300 mr-1'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 
                  <input id="search_text" type='text' value={search.text} placeholder='Search text' onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[4px] focus:ring-0 mr-1 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />

              
                  {user?.roles?.includes('admin') &&
                  <div className='border border-slate-300 rounded-md py-[2px] mr-[5px] h-[32px]'>
                  <Select
                      placeholder='Select Department'
                      bordered={false}
                      size='small'
                      defaultValue={selected_department}
                      value={selected_department}
                      style={{ width: 110 }}
                      onChange={(v)=>{handlechange(v,'department')}}
                      options={departments}
                  />
                </div>}

                {/* {console.log("user_department",user_department)} */}
               {/* {console.log("selected_department",selected_department)}
               {console.log("user_department",user_department)}
               {console.log("user?.roles",user?.roles?.includes('admin') || user?.roles?.includes('hod'))}
               {console.log("user?.roles1",selected_department?.label === 'Transaction Team' || user_department?.department_name === 'Transaction Team')} */}

               {(user?.roles?.includes('admin') || user?.roles?.includes('hod')) && (selected_department?.label === 'Transaction Team' || user_department?.department_name === 'Transaction Team') &&
                <div className='border border-slate-300 rounded-md py-[2px] mr-[5px] h-[32px]'>
                <Select
                      allowClear={true}
                      placeholder='Select Team'
                      bordered={false}
                      size='small'
                      defaultValue={selected_team}
                      value={selected_team}
                      style={{ width: 110 }}
                      onChange={(v)=>{handlechange(v,'team')}}
                      options={teams}
                  />
                 </div>}


            
            <div className='border border-slate-300 rounded-md py-[2px] h-[32px]'>
              <Select
                  allowClear={true}
                  placeholder='Select User'
                  bordered={false}
                  size='small'
                  defaultValue={selected_user}
                  value={selected_user}
                  style={{ width: 110 }}
                  onChange={(v)=>{handlechange(v,'users')}}
                  options={users}
              />
            </div>

              <div className='ml-1 rounded-md border border-slate-300 py-[2px] h-[32px]'>
              <Select
                  allowClear={true}
                  placeholder='Select Stage'
                  bordered={false}
                  size='small'
                  defaultValue={selected_stage}
                  value={selected_stage}
                  style={{ width: 110 }}
                  onChange={(v)=>{handlechange(v,'stages')}}
                  options={stages}
              />
              </div>

            
              <div className='ml-1 mr-1'>
              <ButtonFilledAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
              </div> 
              <div className='mr-1'>
              <ButtonFilledAutoWidth btnName="Reset Filter" onClick={resetfilter} />
              </div>
              <TbAdjustmentsHorizontal onClick={()=>setdesigntable(!designtable)} size={28} className='bg-slate-600 text-white cursor-pointer rounded-[4px] p-[6px]' />
              {user.roles.includes('admin') &&          
              <AiOutlineFileExcel onClick={downloadExcel} size={28} className='ml-2 bg-slate-600 text-white cursor-pointer rounded-[4px] p-[6px]'/>}

            </div>

          </div>


        
          {leads?.datas?.length > 0 && 
          <div className={`${(selected_team !== null && selected_stage?.label === "Invoice Raised") ? 'max-h-[80vh]' : 'max-h-[80vh]' } border-t border-l border-r max-content overflow-y-scroll`}>
          <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
              <h6 className='sticky top-0 z-50 text-[12px] w-[50px]  px-2 py-1 font-[600] text-slate-600 border-r '></h6>
              {table_heading?.contact_name && <h6 className='sticky top-0 z-50  text-[12px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Name</h6>}
              {table_heading?.mobile && <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>}
              {table_heading?.designation && <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Designation</h6>}
              {table_heading?.email && <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>}
              {table_heading?.company_name && <h6 className='sticky top-0 z-50  text-[12px] w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>}
              {table_heading?.industry && <h6 className='sticky top-0 z-50  text-[12px] w-[130px] px-2 py-1 font-[600] text-slate-600 border-r'>Industry</h6>}
              {table_heading?.lead_status && <h6 className='sticky top-0 z-50  text-[12px] w-[100px] px-2 py-1 font-[600] text-slate-600 border-r'>Lead Status</h6>}
              {table_heading?.contact_stage && <h6 className='sticky top-0 z-50  text-[12px] w-[100px] px-2 py-1 font-[600] text-slate-600 border-r'>Contact Status</h6>}
              {table_heading?.lead_owner && <h6 className='sticky top-0 z-50  text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Lead Owner</h6>}
              {table_heading?.lead_operator && <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Operators</h6>}
              {table_heading?.sqft_info && <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Sqft/Seater/Acres</h6>}
              {table_heading?.location && <h6 className='sticky top-0 z-50  text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Location</h6>}
              {table_heading?.requirment && <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment</h6>}
              {table_heading?.called_status && <h6 className='sticky top-0 z-50  text-[12px] w-[70px] px-2 py-1 font-[600] text-slate-600 border-r'>Called</h6>}
              {table_heading?.follow_up && <h6 className='sticky top-0 z-50  text-[12px] w-[70px] px-2 py-1 font-[600] text-slate-600 border-r'>FollowUp</h6>}
              {table_heading?.follow_up_date && <h6 className='sticky top-0 z-50  text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Follow Up Date</h6>}
              {table_heading?.follow_up_remark && <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Remark</h6>}
              {table_heading?.created_At && <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created On</h6>}
              <h6 className='sticky top-0 z-50  text-[12px] w-[110px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
          </div>
        
          {leads?.datas?.map((d,i)=>(
          <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
              <h6 className='text-[12px] font-[500] w-[50px]  px-2 py-1  border-r border-slate-200 '>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
            {table_heading?.contact_name && <h6 className='text-[12px] font-[500] w-[170px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.contact_name}</h6>}
            {table_heading?.mobile && <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.phone}</h6>}
            {table_heading?.designation && <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.designation}</h6>}
            {table_heading?.email && <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.email}</h6>}
            {table_heading?.company_name && <h6 className='text-[12px] font-[500] w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d?.company_name}</h6>}
            {table_heading?.industry && <h6 className='text-[12px] font-[500] w-[130px] px-2 py-1 truncate border-r  border-slate-200'>{d?.industry?.name}</h6>}
            {table_heading?.lead_status && <h6 className='text-[12px] font-[500] w-[100px] px-2 py-1 truncate border-r  border-slate-200'>{d?.stage?.name}</h6>}
            {table_heading?.contact_stage && <h6 className='text-[12px] font-[500] w-[100px] px-2 py-1 truncate border-r  border-slate-200'>{d?.contact_stage}</h6>}
            {table_heading?.lead_owner && <h6 className='text-[12px] font-[500] w-[120px] px-2 py-1 truncate border-r  border-slate-200'>{d?.lead_owner?.name}</h6>}
            {table_heading?.lead_operator && <h6 className='text-[12px] font-[500] w-[150px] px-2 py-1 truncate border-r  border-slate-200'>{d?.operators?.map((d)=><span className='bg-slate-100 mr-[4px] text-[8px] p-[4px] rounded font-[600]'>{d?.name?.split(' ')[0]}</span>)}</h6>}
            {table_heading?.sqft_info && <h6 className='text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.sqft_info}</h6>}
            {table_heading?.location && <h6 className='text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.location}</h6>}
            {table_heading?.requirment && <h6 className='text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.requirment}</h6>}
            {table_heading?.called_status && <h6 className='text-[12px] font-[500] w-[70px] px-2 py-1 truncate border-r  border-slate-200'>{!d?.called_status ? <BiCheckbox size={20} className='text-slate-400' /> : <BiCheckboxSquare size={20} className='text-slate-700' /> }</h6>}
            {table_heading?.follow_up && <h6 className='text-[12px] font-[500] w-[70px] px-2 py-1 truncate border-r  border-slate-200'>{!d?.follow_up ? <BiCheckbox size={20} className='text-slate-400' /> : <BiCheckboxSquare size={20} className='text-slate-700' /> }</h6>}
            {table_heading?.follow_up_date && <h6 className='text-[12px] font-[500] w-[120px] px-2 py-1 truncate border-r  border-slate-200'>{moment(d?.follow_up_date)?.format('LLL')}</h6>}
            {table_heading?.follow_up_remark && <h6 className='text-[12px] font-[500] w-[150px] px-2 py-1 truncate border-r  border-slate-200'>{d?.follow_up_remark}</h6>}
            {table_heading?.created_At && <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>}
              <h6 className='text-[12px] flex  font-[500] w-[110px]  px-2 py-1 truncate border-slate-200 '>{<RiBillLine onClick={()=>navigate('/leads/contacts/detail',{state:d?._id})} />} <AiOutlineEdit className='ml-2' onClick={()=>navigate('/leads/contacts/edit',{state:d?._id})}/> <VscOpenPreview size={13} className="z-10 ml-2" onClick={()=>{getnote_attachment_basedonselectedlead(d?._id);setselected_lead(d);setpreview(true)}}/> {user?.roles?.includes('delete_data') && <AiOutlineDelete  size={13} className="z-10 ml-2" onClick={()=>deleteData(d)} />} </h6>
          </div>))}
          </div>}


          
          {leads?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
            <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' /> 
            <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
            <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
          </div>
          }


          </div>

        


        </div>

        
      
        </div>
        </div>
    </div>
    </div>
  )
}

export default TeamLeads