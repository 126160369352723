import React, { useState, useEffect } from 'react';
import FTSLeadMenu from './FTSLeadMenu';
import { GetDasboarddMontlyReviewReportService, GetVerticalDasboarddMontlyReviewReportService } from '../../services/ftsVerticalReportServices/FTSVerticalLeadReportServices';
import { GetDepartmentService } from '../../services/DepartmentServices';
import { DatePicker } from 'antd';
import { BiFilterAlt } from 'react-icons/bi'
import { BsArrowRepeat } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BiTable } from "react-icons/bi";
import { PiChartBarBold } from "react-icons/pi";
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { IconButton } from '@mui/material'


function FTSVerticalAnalytics() {

  const user = useSelector(state=>state.Auth)
  const [department, setdepartment] = useState([])

  const navigate = useNavigate()

  const [step, setstep] = useState(1)
  const [step1, setstep1] = useState(1)
  

  const [search, setsearch] = useState({ from_date: '', to_date: '', from_date1: '', to_date1: '', assigned_department: '', recieved_department: '' });

  const [mainData,setmainData] = useState({})
  const [data1, setdata1] = useState({})
  

  const [page, setpage] = useState(1)
  const [page1, setpage1] = useState(1)
  const [page2, setpage2] = useState(1)

  const converted = [
    { label: 'Converted', value: 1 },
    { label: 'Rejected', value: 2 },
    { label: 'Pending', value: 3 },
    { label: 'Progress', value: 4 },
    { label: 'Lead Generated', value: 5 },
  ]

  useEffect(() => {
    getdepartment()
    getdata()
    getdata1()
  }, [page])

  async function getdata() {
    const response = await GetVerticalDasboarddMontlyReviewReportService(search?.from_date1, search?.to_date1, user?.department_id[0]?.id)
    setmainData(response?.data?.data)
  }

  async function getdata1() {
      const response = await GetDasboarddMontlyReviewReportService(search?.from_date1, search?.to_date1, user?.department_id[0].id , page, page1, page2)
      let d = response?.data?.data
      let form = [
        {
          name: 'count',
          data: [
            d?.count?.new_leads_totalData,
            d?.count?.lead_recieved_from_other_bu_totalData,
            d?.count?.lead_recieved_share_to_other_bu_totalData,
            (d?.count?.new_converted_leads_totalData + d?.count?.lead_recieved_from_other_bu_converted),
            (d?.count?.new_rejected_leads_totalData + d?.count?.lead_recieved_from_other_bu_rejected)
          ]
        }]
  
      console.log("form", form)
      setchartOptions1({ ...chartOptions1, series: [...form] })
      setdata1(response?.data?.data)
    }

  async function getdepartment() {
    const response = await GetDepartmentService()
    let d = response?.data?.datas
    let user_department = user?.department_id[0]?.department_name

    let arr = []
    
    
    let transaction = d?.find((f) => f?.department_name === "Transaction Team")
    let project = d?.find((f) => f?.department_name === "Project Team")
    let hr = d?.find((f) => f?.department_name === "HR Team")
    let fms = d?.find((f) => f?.department_name === "FMS Team")
    let fidelitus_gallery = d?.find((f) => f?.department_name === "Fidelitus Gallery")
    let shilpa_foundation = d?.find((f) => f?.department_name === "Shilpa Foundation")
    let corp_team = d?.find((f) => f?.department_name === "Corp Team")
    let fts_team = d?.find((f) => f?.department_name === "FTS Team")
    let backend_team = d?.find((f) => f?.department_name === "Backend Team")

    if (![null, undefined, '', 'null', 'undefined']?.includes(transaction) && transaction?.department_name !== user_department) {
      arr.push(transaction)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(project) && project?.department_name !== user_department) {
      arr.push(project)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(hr) && hr?.department_name !== user_department) {
      arr.push(hr)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(fms) && fms?.department_name !== user_department) {
      arr.push(fms)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(fidelitus_gallery) && fidelitus_gallery?.department_name !== user_department) {
      arr.push(fidelitus_gallery)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(shilpa_foundation) && shilpa_foundation?.department_name !== user_department) {
      arr.push(shilpa_foundation)
    }

    if (![null, undefined, '', 'null', 'undefined']?.includes(corp_team) && corp_team?.department_name !== user_department) {
      arr.push(corp_team)
    }

    if (![null, undefined, '', 'null', 'undefined']?.includes(backend_team) && backend_team?.department_name !== user_department) {
      arr.push(backend_team)
    }

    if (![null, undefined, '', 'null', 'undefined']?.includes(fts_team) && fts_team?.department_name !== user_department) {
      arr.push(fts_team)
    }
    setdepartment(arr)
  }

  async function applyfilterfunction() {
    const response = await GetVerticalDasboarddMontlyReviewReportService(search?.from_date1, search?.to_date1, user?.department_id[0]?.id)
    setmainData(response?.data?.data)
  }

  async function resetfilterfunction() {
    setsearch({ from_date: '', to_date: '', from_date1: '', to_date1: '', assigned_department: '', recieved_department: '' })
    setpage(1)
    const response = await GetVerticalDasboarddMontlyReviewReportService('', '', user?.department_id[0]?.id)
    setmainData(response?.data?.data)

  }

  function getleadSharedCount(department){
    let findData =  mainData?.totalftsLeadShared?.find((f)=>f?._id?.department_name === department)
    if(findData !== undefined && findData !== null){
      return findData?.total
    }
    return 0
  }

  function getleadRecivedCount(department,stage){
    let findData =  mainData?.totalftsLeadReceieved?.find((f)=>f?._id?.department_name === department)
    
    console.log("department",department)
    console.log("findData",findData)
    
    if(findData !== undefined && findData !== null){
      if(stage == 'Converted'){
        return findData?.converted
      }
      if(stage == 'Rejected'){
        return findData?.rejected
      }
      if(stage == 'Pending'){
        return findData?.pending
      }
      if(stage == 'Progress'){
        return findData?.progress
      }
      if(stage == 'Recieved'){
        return findData?.total
      }
    }
    return 0
  }

  function openVerticalLead(v){
      navigate('/fts_leads/vertical_lead',{state:{...search,status:v}})
  }


  function openLeadShared(v){
    navigate('/fts_leads/shared_to_fts',{state:{...search,department:v.id,status:''}})
  }

  function openLeadRecieved(v,type){
    console.log("val here v",v)
    console.log("type here v",type)
    let findData = converted.find((f)=>f.label == type)

    console.log("findData",findData)
    navigate('/fts_leads/lead_received_from_fts',{state:{...search,department:v.id,status:findData?.value}})
  }

   const [chartOptions1, setchartOptions1] = useState({
      series: [],
      chart: {
        toolbar: {
          show: false
        }
      },
      plotOptions: {
  
        bar: {
          horizontal: false,
          distributed: true,
          columnWidth: '55%',
          endingShape: 'rounded',
          dataLabels: {
            position: 'top', // top, center, bottom
          },
  
        },
      },
      colors: ["#3a7aba", "#40ad75", "#d48d37", "#c42b8c", "#a61b2d"],
      dataLabels: {
        enabled: false
      },
      grid: {
        show: true,
        borderColor: '#fafafa',
      },
  
      stroke: {
        width: 1,
  
        curve: 'smooth',
        colors: ['transparent']
      },
      xaxis: {
        // categories: ['New Leads Generated Internally', 'No of Leads receieved by CRM/Corp/Social Media', 'No of leads shared by business units to other business units', 'No of new leads converted', 'No of New Leads Rejected'],
        categories: ['New Leads Generated', 'Leads Recieved (from CRM/FTS)', 'Leads Shared (other BU)', 'New Leads converted', 'Leads Rejected'],
      },
      yaxis: {
        title: {
          text: 'Count'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      },
      fill: ['#F44336', '#E91E63', '#9C27B0', '#E91E63', '#9C27B0']
    })
  


  return (
    <div className="flex bg-white">
      <div className="min-w-44">
        <FTSLeadMenu />
      </div>

      <div className="p-3 bg-white w-full h-full text-black">
        <div className="mb-3  border-b flex justify-between items-center p-2">
          <h2 className="text-sm font-extrabold text-gray-700">{user?.department_id[0]?.department_name} Analytics</h2>

          <div className='flex items-center'>
            <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, from_date: v, from_date1: v1 }) }} />
            <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, to_date: v, to_date1: v1 }) }} />

            <BiFilterAlt onClick={() => applyfilterfunction(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
            <BsArrowRepeat onClick={() => resetfilterfunction(1)} size={25} className='bg-slate-200 p-[5px]  text-black cursor-pointer rounded ml-1' />
            <div onClick={() => setstep(step == 1 ? 2 : 1)} className='flex cursor-pointer items-center px-[7px]'>
              <BiTable size={20} className={`${step == 1 ? 'bg-slate-700 text-white' : 'text-black bg-gray-100'} px-[3px] rounded mr-1 py-[2px]`} />
              <PiChartBarBold size={20} className={`${step == 2 ? 'bg-slate-700 text-white' : 'text-black bg-gray-100'} px-[3px] rounded mr-1 py-[2px]`} />
            </div>
          </div>

        </div>

       {step == 1 && 
        <>
          <div className="overflow-x-auto">
          <h2 className="text-sm mt-2 font-extrabold text-gray-700">Lead Shared To Other Vertical</h2>

            
            <div className="w-full mt-2 border text-sm text-left">

              <div className="flex items-center text-slate-600 text-[11.5px] font-[700]">
                <h6 className="p-1 w-[10%] text-[11px] text-slate-600 bg-yellow-200 uppercase">OUR Vertical</h6>
                {department?.map((d) => (
                  <h6
                    key={d?._id}
                    className="p-1 text-[10px] border-l text-slate-600 uppercase text-center"
                    style={{
                      minWidth: `${85 / department?.length}%`,
                      maxWidth: `${85 / department?.length}%`,
                    }}
                  >
                    {d?.department_name}
                  </h6>
                  ))}
              </div>

              <div>
                

                <div className="border-t flex  items-center">
                  <h6 className="p-1 text-[11px] uppercase text-gray-700 bg-yellow-200 font-bold w-[10%]">Shared</h6>
                  {department?.map((d) => (
                    <h6
                      onClick={()=>openLeadShared(d)}
                      key={d?._id}
                      className="p-1 text-[12px] font-[800]  border-l cursor-pointer  text-center"
                      style={{
                        minWidth: `${85 / department?.length}%`,
                        maxWidth: `${85 / department?.length}%`,
                      }}
                    >
                      {getleadSharedCount(d?.department_name)}
                    </h6>
                  ))}
                </div>
                </div>
            </div>



            <h2 className="text-sm mt-5 font-extrabold text-gray-700">Lead Receieved From Other Vertical</h2>


            <div className="w-full mt-2 border text-sm text-left">



            <div className="flex items-center text-slate-600 text-[11.5px] font-[700]">
              <h6 className="p-1 w-[10%] text-[11px] text-black bg-orange-200 uppercase">OTHER VERTICAL</h6>
              {department?.map((d) => (
                <h6
                  key={d?._id}
                  className="p-1 text-[10px] border-l text-slate-600 uppercase text-center"
                  style={{
                    minWidth: `${85 / department?.length}%`,
                    maxWidth: `${85 / department?.length}%`,
                  }}
                >
                  {d?.department_name}
                </h6>
                ))}
            </div>

            <div>

            

              <div className="border-t flex  items-center">
                  <h6 className="p-1 text-[11px] uppercase bg-orange-200 font-bold w-[10%]">Recieved</h6>
                  {department?.map((d) => (
                    <h6
                      key={d?._id}
                      onClick={()=>openLeadRecieved(d,)}
                      className="p-1 text-[12px] cursor-pointer font-[800]  border-l  text-center"
                      style={{
                        minWidth: `${85 / department?.length}%`,
                        maxWidth: `${85 / department?.length}%`,
                      }}
                    >
                      {getleadRecivedCount(d?.department_name,'Recieved')}
                    </h6>
                  ))}
                </div>

              

                <div className="border-t flex  items-center">
                  <h6 className="p-1 text-[11px] uppercase bg-orange-200 font-bold w-[10%]">Pending</h6>
                  {department?.map((d) => (
                    <h6
                      key={d?._id}
                      onClick={()=>openLeadRecieved(d,'Pending')}
                      className="p-1 text-[12px] cursor-pointer font-[800]  border-l  text-center"
                      style={{
                        minWidth: `${85 / department?.length}%`,
                        maxWidth: `${85 / department?.length}%`,
                      }}
                    >
                      {getleadRecivedCount(d?.department_name,'Pending')}
                    </h6>
                  ))}
                </div>

                <div className="border-t flex  items-center">
                  <h6 className="p-1 text-[11px] uppercase bg-orange-200 font-bold w-[10%]">Converted</h6>
                  {department?.map((d) => (
                    <h6
                      key={d?._id}
                      onClick={()=>openLeadRecieved(d,'Converted')}
                      className="p-1 text-[12px] cursor-pointer font-[800]  border-l  text-center"
                      style={{
                        minWidth: `${85 / department?.length}%`,
                        maxWidth: `${85 / department?.length}%`,
                      }}
                    >
                      {getleadRecivedCount(d?.department_name,'Converted')}
                    </h6>
                  ))}
                </div>

                <div className="border-t flex  items-center">
                  <h6 className="p-1 text-[11px] uppercase bg-orange-200 font-bold w-[10%]">Rejected</h6>
                  {department?.map((d) => (
                    <h6
                      key={d?._id}
                      onClick={()=>openLeadRecieved(d,'Rejected')}
                      className="p-1 text-[12px] cursor-pointer font-[800]  border-l  text-center"
                      style={{
                        minWidth: `${85 / department?.length}%`,
                        maxWidth: `${85 / department?.length}%`,
                      }}
                    >
                      {getleadRecivedCount(d?.department_name,'Rejected')}
                    </h6>
                  ))}
                </div>

            
              </div>
            </div>


          </div>
          <div className="flex mt-4 justify-between items-start">
            <div className='w-[30%]'>

              <h6 className='font-[700] text-[13px] border-b pb-1 mb-1 text-gray-700'>Total Leads Generated</h6>
              <div className='flex border'>
                <div className='w-[50%]'>
                  <h6 className="p-1 bg-blue-200 text-[13px] border-r font-bold">Leads</h6>
                  <h6 className="p-1 bg-blue-200 text-[13px] border-r border-t font-bold">Progress</h6>
                  <h6 className="p-1 bg-blue-200 text-[13px] border-r border-t font-bold">Converted</h6>
                  <h6 className="p-1 bg-blue-200 text-[13px] border-r border-t font-bold">Rejected</h6>
                  <h6 className="p-1 bg-blue-200 text-[13px] border-r border-t font-bold">Hold</h6>
                </div>
                <div className='w-[50%] cursor-pointer' >
                  <h6 onClick={()=>openVerticalLead('')} className="p-1 text-[13px] text-blue-600 font-bold">{(mainData?.totalftsvertical_data?.length > 0 ? mainData?.totalftsvertical_data[0]?.count : 0)}</h6>
                  <h6 onClick={()=>openVerticalLead('Progress')} className="p-1 text-[13px] border-t font-bold">{(mainData?.totalftsvertical_data?.length > 0 ? mainData?.totalftsvertical_data[0]?.progress  : 0)}</h6>
                  <h6 onClick={()=>openVerticalLead('Converted')} className="p-1 text-[13px] border-t font-bold">{(mainData?.totalftsvertical_data?.length > 0 ? mainData?.totalftsvertical_data[0]?.converted  : 0)}</h6>
                  <h6 onClick={()=>openVerticalLead('Rejected')} className="p-1 text-[13px] border-t font-bold">{(mainData?.totalftsvertical_data?.length > 0 ? mainData?.totalftsvertical_data[0]?.rejected  : 0)}</h6>
                  <h6 onClick={()=>openVerticalLead('Hold')} className="p-1 text-[13px] border-t font-bold">{(mainData?.totalftsvertical_data?.length > 0 ? mainData?.totalftsvertical_data[0]?.hold  : 0)}</h6>
                </div>
              </div>
            </div>

          </div>
        </>}

        {step == 2 && 
        <>

           <div className='border  relative w-[100%] flex-1  mt-2 border-slate-100 px-3 py-2 relative'>
              <h1 className='text-[13px] font-[700]'>Total Report</h1>
              <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1?.series} type="bar" width={'100%'} height={240} />
            </div>

            <div>


                <div className='flex items-center border-t border-b mt-2'>
                  <h6 onClick={() => setstep1(1)} className={`text-[11px] px-2 py-1 font-[600] cursor-pointer ${step1 == 1 && 'bg-slate-700 text-white'}`}>Lead Generated</h6>
                  <h6 onClick={() => setstep1(2)} className={`text-[11px] px-2 py-1 font-[600] cursor-pointer ${step1 == 2 && 'bg-slate-700 text-white'}`}>Lead Recieved</h6>
                  <h6 onClick={() => setstep1(3)} className={`text-[11px] px-2 py-1 font-[600] cursor-pointer ${step1 == 3 && 'bg-slate-700 text-white'}`}>Lead Shared</h6>
                </div>

                {step1 == 1 &&
                  <>
                    {data1?.data?.new_leads_generated?.new_leads_generated?.length > 0 &&
                      <>
                        <div className='flex items-center justify-between'>
                          <h6 className='font-[800] text-[13px] mt-4'>Vertical Lead</h6>
                          <div>
                            <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                            <IconButton onClick={() => { page < data1?.data?.new_leads_generated?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data1?.data?.new_leads_generated?.pagination?.totalPages === page || data1?.data?.new_leads_generated?.new_leads_generated?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                          </div>
                        </div>
                        <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
                          <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                            <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[10%] px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600  border-r'>Designation</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[25%]  px-2 py-1 font-[600] text-slate-600  border-r'>Requirment</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[7%]  px-2 py-1 font-[600] text-slate-600 '>Status</h6>
                          </div>

                          {data1?.data?.new_leads_generated?.new_leads_generated?.map((d, i) => (
                            <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
                              <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1  border-r border-slate-200 '>{(page > 1 ? i + 1 + (data1?.data?.new_leads_generated?.pagination?.limit * (page - 1)) : i + 1)}</h6>
                              <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 border-r border-slate-200'>{d?.company}</h6>
                              <h6 className='text-[11px] font-[500] w-[10%]  px-2 py-1 font-[800] border-r border-slate-200'>{d?.name}</h6>
                              <h6 className='text-[11px] font-[500] w-[10%]  px-2 py-1 font-[800] border-r border-slate-200'>{d?.mobile}</h6>
                              <h6 className='text-[11px] w-[20%]  px-2 py-1  border-r border-slate-200'>{d?.email}</h6>
                              <h6 className='text-[11px] w-[10%]  px-2 py-1  border-r border-slate-200'>{d?.designation}</h6>
                              <h6 className='text-[12px] flex items-start break-word  font-[500] w-[25%] border-r px-2 py-1 border-slate-200 '>{d?.requirment}</h6>
                              <h6 className='text-[12px] flex items-start break-word  font-[500] w-[7%]  px-2 py-1 border-slate-200 '><span className='bg-slate-100 px-2 py-0.5 border-slate-400 border rounded text-[8px]'>{d?.status}</span></h6>
                            </div>))}
                        </div>
                      </>
                    }
                  </>}
                {step1 == 2 &&
                  <>
                    {data1?.data?.lead_recieved_from_other_bu?.lead_recieved_from_other_bu?.length > 0 &&
                      <>
                        <div className='flex items-center justify-between'>
                          <h6 className='font-[800] text-[13px] mt-4'>Lead Recieved From Other BU</h6>
                          <div>
                            <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                            <IconButton onClick={() => { page < data1?.data?.lead_recieved_from_other_bu?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data1?.data?.lead_recieved_from_other_bu?.pagination?.totalPages === page || data1?.data?.lead_recieved_from_other_bu?.lead_recieved_from_other_bu?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                          </div>
                        </div>
                        <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
                          <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                            <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person Info</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Created Department</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Assigned Department</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600  border-r'>Remarks</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 '>Stage</h6>
                          </div>

                          {data1?.data?.lead_recieved_from_other_bu?.lead_recieved_from_other_bu?.map((d, i) => (
                            <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
                              <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1 border-r border-slate-200 '>{page === 1 ? i + 1 : (i + 1) + ((page - 1) * data1?.data?.lead_recieved_from_other_bu?.pagination?.limit)}</h6>
                              <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 border-r border-slate-200'>{d?.fts_lead?.company_name}
                                {d?.zone !== undefined && <h6 className='text-[10px]'>Zone : {d?.zone}</h6>}</h6>
                              <h6 className='text-[12px] font-[500] w-[15%] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                                <div>
                                  <h6>{d?.fts_lead?.contact_name}</h6>
                                  <span>{d?.fts_lead?.phone} / {d?.fts_lead?.email}  </span>
                                </div>
                              </h6>
                              <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
                                {d?.fts_lead?.lead_owner_department?.department_name}
                                <h6 className='text-[10px]'>Created By : {d?.fts_lead?.lead_owner?.name}</h6>
                                <h6 className='text-[10px] font-[500]'>{d?.fts_lead?.previously_handled_by}</h6>
                              </h6>
                              <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
                                <span className='bg-slate-100 px-2 py-1 rounded text-[10px]'>{d?.department_id?.department_name}</span>
                              </h6>
                              <h6 className='text-[11px]  w-[15%]  px-2 py-1  border-r border-slate-200'>
                                Requirment :  <span className='font-[800]'>{d?.requirment}</span>  /
                                Remarks :  <span className='font-[800]'>{d?.remarks}</span>

                                {/* {d?.converted == '1' && <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>} */}

                              </h6>
                              <h6 className='text-[12px]  flex items-start break-word  font-[500] w-[10%] px-2 py-1 border-slate-200 '>
                                <span className='text-[11px] font-[600] rounded'>{d?.converted === '5' && <span className='bg-orange-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '5' && 'Lead Generated'}</span>} {d?.converted === '1' && <span className='bg-green-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '1' && 'Converted'}</span>} {d?.converted === '2' && <span className='bg-red-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '2' && 'Rejected'}</span>} {d?.converted === '3' && <span className='bg-yellow-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '3' && 'Pending'}</span>} {d?.converted === '4' && <span className='bg-blue-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '4' && 'Progress'}</span>}</span>
                              </h6>

                            </div>))}
                        </div>
                      </>
                    }
                  </>}
           
                {step1 == 3 &&
                  <>
                    {data1?.data?.lead_recieved_share_to_other_bu?.lead_recieved_share_to_other_bu?.length > 0 &&
                      <>
                        <div className='flex items-center justify-between'>

                          <h6 className='font-[800] text-[13px] mt-4'>Lead Shared To Other BU</h6>
                          <div>
                            <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                            <IconButton onClick={() => { page < data1?.data?.lead_recieved_share_to_other_bu?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data1?.data?.lead_recieved_share_to_other_bu?.pagination?.totalPages === page || data1?.data?.lead_recieved_share_to_other_bu?.lead_recieved_share_to_other_bu?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                          </div>
                        </div>
                        <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                          <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                            <h6 className='top-0 bg-white z-50 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Info</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Recieved Department</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[180px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Converted</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[180px] px-2 py-1 font-[600] text-slate-600 border-r'>Summary</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 '>Created On</h6>
                          </div>

                          {data1?.data?.lead_recieved_share_to_other_bu?.lead_recieved_share_to_other_bu?.map((d, i) => (
                            <div key={d?._id} className={`flex z-50 h-full border-b border-slate-200 z-0`}  >
                              <h6 className='sticky top-0 z-30 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex items-center justify-center'>{page === 1 ? i + 1 : (i + 1) + ((page - 1) * data1?.data?.lead_recieved_share_to_other_bu?.pagination?.limit)}</h6>
                              <h6 className='text-[12px] font-[500] w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d?.fts_lead?.company_name}</h6>
                              <h6 className='text-[12px] font-[500] w-[180px] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                                <div>
                                  <h6>{d?.fts_lead?.contact_name}</h6>
                                  <span>{d?.fts_lead?.phone} / {d?.fts_lead?.email}  </span>
                                </div>
                              </h6>
                              <h6 className='text-[12px] font-[500] w-[150px] px-2 py-1 break-all border-r border-slate-200'>
                                <span className='text-sky-500 text-[11px] font-[600]'>{d?.department_id?.department_name}</span>
                              </h6>
                              <h6 className={`text-[12px] font-[500] w-[180px] px-2 py-1  truncate  border-r  border-slate-200 ${d?.requirment ? 'block' : 'text-[#fff]'}`}>{d?.requirment ? d?.requirment : 'Not Added'}</h6>
                              <h6 className='text-[12px] text-center font-[500] w-[80px] px-2 py-1 truncate border-r  border-slate-200'>{d?.converted === '5' && <span className='bg-orange-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '5' && 'Lead Generated'}</span>} {d?.converted === '1' && <span className='bg-green-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '1' && 'Converted'}</span>} {d?.converted === '2' && <span className='bg-red-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '2' && 'Rejected'}</span>} {d?.converted === '3' && <span className='bg-yellow-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '3' && 'Pending'}</span>} {d?.converted === '4' && <span className='bg-blue-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '4' && 'Progress'}</span>} </h6>
                              <h6 className={`text-[12px] font-[500] w-[180px] px-2 py-1 truncate border-r  border-slate-200 ${d?.remarks ? 'block' : 'text-[#fff]'}`}>{d?.remarks ? d?.remarks : 'Not Added'}</h6>

                              <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>

                            </div>))}
                        </div>
                      </>
                    }
                  </>}
              </div>
        </>}



      </div>
    </div>
  );
}

export default FTSVerticalAnalytics;

