import { IconButton, Tooltip } from '@mui/material'
import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import moment from 'moment';
import {AiOutlineDownload, AiOutlineEdit} from 'react-icons/ai';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';

import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import {Modal} from 'antd';
import { DatePicker } from 'antd';
import {AiOutlineDelete} from 'react-icons/ai';
import axios from 'axios';
import fileDownload from "js-file-download";
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { DeleteDBRequirmentInfoService, GetDBRequirmentInfoService } from '../../../services/database/DBrequirmentInfoServices';
import DatabaseMenu from '../DatabaseMenu';
import { IoFilterCircleOutline } from "react-icons/io5";

function RequirmentInfoList() {

  const location = useLocation();
  const user = useSelector(state=>state.Auth)
  const user_department = user.department_id[0]
  const navigate = useNavigate();

  const [page,setpage] = useState(1)

  const [delete_modal,setdelete_modal] = useState(false)

  let path = location?.pathname?.split('/')[2]

  const [step,setstep] = useState("Pending")

  const [requirmentInfo,setrequirmentInfo]  = useState({})

  const [selected_requirmentInfo,setselected_requirmentInfo] = useState(null)

  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',zone:'',country:'',activate:false,department:''})

 
  useEffect(()=>{
    getdata()
  },[step,page])
  
  async function getdata() {
    const response = await GetDBRequirmentInfoService(page,search?.from_date1,search?.to_date1,search?.text,step)
    setrequirmentInfo(response?.data)
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            toast.success("Attachment Downloaded") 
        })
  }

  async function deleteData(d){
    const response = await DeleteDBRequirmentInfoService(d)
    if(response.status === 200){
      toast.success("Deleted Successfully")
      setdelete_modal(false)
      getdata()
    }
  }


  async function resetfunc() {
    setpage(1)
    setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',zone:'',country:'',activate:false,department:''})
    const response = await GetDBRequirmentInfoService(1,'','','',step)
    setrequirmentInfo(response?.data)
  }

  
  
  

  return (
    <div className='h-screen max-h-screen '>



     <Modal open={delete_modal} width={350} closable={false} footer={false} className='absolute top-0 left-[40%]'>
        <h6 className="font-bold text-[13px]  mb-2 w-full">Delete Data</h6>
        <h6 className="text-[11px]  mb-2 w-full">Are you sure want to delete the selected data which has requirment info : <span className='font-[800]'>{selected_requirmentInfo?.requirment}</span>  </h6>

        <div className='mt-2 flex items-center'>
          <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>setdelete_modal(false)}/>
          <div className='ml-2'>
          <ButtonFilledAutoWidth btnName="Save" onClick={()=>deleteData(selected_requirmentInfo?._id)} />
          </div>
        </div>
     </Modal> 

    

   
      <div  className="w-[100%] flex">
        <div className='min-w-[180px] max-w-[180px]'>
        <DatabaseMenu />
        </div>
        <div  className="min-w-[87%] max-w-[87%] px-4">
        <div className=' relative mt-4 pr-5 w-[98%] mb-1'>
    

    </div>  
            <div  >
            <div className='flex items-center justify-between border-b pb-2'>
                <h6 className='font-[700] text-[14px] '>Total Requirement Info List ({requirmentInfo?.pagination?.total})</h6>
                <div className='flex items-center'>
                <div className='flex items-center text-[12px] mr-2'>

                    <h6 className='mr-2 font-[600]'>{page == 1 ? requirmentInfo?.datas?.length > 0 ? 1 : 0 :  (page - 1) * requirmentInfo?.pagination?.limit } - {requirmentInfo?.pagination?.limit} of {requirmentInfo?.pagination?.total} </h6>
                    <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < requirmentInfo?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(requirmentInfo?.pagination?.totalPages === page || requirmentInfo?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                </div>


                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                
                <div >
                    

                <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
                
                </div> 

                <IoFilterCircleOutline onClick={()=>{setpage(1);getdata()}} size={30} className='ml-2 p-1.5 cursor-pointer rounded-[5px] bg-slate-800 border border-slate-500 text-white' />
        
                </div>
            </div>
                
        <div className='border-b'>
        <span onClick={()=>{setstep('Pending');setpage(1)}} className={`text-[12px] font-[500] cursor-pointer px-4 p-1.5 w-[100px] ${step === 'Pending' && 'bg-slate-700 text-[#fff]'}`}>Pending</span>
        <span onClick={()=>{setstep('Progress');setpage(1)}} className={`text-[12px] font-[500] cursor-pointer px-4 p-1.5 w-[100px] ${step === 'Progress' && 'bg-slate-700 text-[#fff]'}`}>Progress</span>
        <span onClick={()=>{setstep('Completed');setpage(1)}} className={`text-[12px] font-[500] cursor-pointer px-4 p-1.5 w-[100px] ${step === 'Completed' && 'bg-slate-700 text-[#fff]'}`}>Completed</span>
        </div>
            
            {requirmentInfo?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
            <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
            <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
            <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>
            }


            {requirmentInfo?.datas?.length > 0 &&
            <div className='max-h-[82vh] mt-4 border overflow-y-scroll'>
                    <div className='flex text-[12px] font-[600] text-slate-600 z-50 sticky top-0 bg-white  border-slate-200'>
                        <h6 className='min-w-[10%] p-1 max-w-[10%] border-r'>SL NO</h6>
                        <h6 className='min-w-[15%] p-1 max-w-[15%] border-r'>User Info</h6>
                        <h6 className='min-w-[25%] p-1 max-w-[25%] border-r'>Requirement</h6>
                        <h6 className='min-w-[15%] p-1 max-w-[15%] border-r'>Status</h6>
                        <h6 className='min-w-[15%] p-1 max-w-[15%] border-r'>Remarks / Handled By</h6>
                        <h6 className='min-w-[10%] p-1 max-w-[10%] border-r'>Created At</h6>
                        <h6 className='min-w-[10%] p-1 max-w-[10%] '>Actions</h6>
                    </div>
                    {requirmentInfo?.datas?.map((r,i)=>(
                    <div key={r?._id} className='flex text-[12px] z-50 stickt top-0 border-t border-slate-200'>
                    <h6 className='min-w-[10%] p-1 max-w-[10%] border-r'>{i+1}</h6>
                    <h6 className='min-w-[15%] p-1 max-w-[15%] border-r font-[600]'>{r?.created_by?.name}</h6>
                    <h6 className='min-w-[25%] p-1 max-w-[25%] border-r'>{r?.requirment} <br></br>
                    <h6  onClick={()=>navigate('/leads/contacts/detail',{state:r?.lead_id?._id})} className='text-blue-500 underline font-[600] cursor-pointer'>View Lead Info</h6>
                    </h6>
                    <h6 className='min-w-[15%] p-1 max-w-[15%] border-r'>{r?.status}</h6>
                    <h6 className='min-w-[15%] p-1 max-w-[15%] border-r '>
                    {r?.remarks !== '' && `Remarks :`} {r?.remarks} <br></br>
                        <span className='font-[600]'>{r?.handled_by?.name}</span> 
                        {r?.file !== '' &&
                        <div className='flex relative mt-1'>
                            <img src="https://cdn-icons-png.flaticon.com/512/179/179483.png" className='w-5 h-5 object-contain z-0' />
                            <h6 className='text-[9px] font-[800] ml-1 w-[65%]  overflow-hidden break-all line-clamp-2'>{r?.file?.split('/')[r?.file?.split('/')?.length - 1]}</h6>
                            <AiOutlineDownload size={13} className='cursor-pointer absolute right-1 bg-slate-700 text-white p-1 text-[35px] rounded ' onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${r?.file}`,r?.file?.split('/')[r?.file?.split('/')?.length - 1])} />
                        </div>}
                    </h6>
                    <h6 className='min-w-[10%] p-1 max-w-[10%] border-r'>{moment(r?.createdAt)?.format('LLL')}</h6>
                    <h6 className='min-w-[10%] flex p-1 max-w-[10%] '>
                        <AiOutlineEdit className='mr-2' onClick={()=>navigate('edit',{state:r})} />
                        <AiOutlineDelete  onClick={()=>{setselected_requirmentInfo(r);setdelete_modal(true)}} />
                    </h6>
                    </div>))}
            </div>}

         
            </div>

            
        


        </div>
      </div>
   
    </div>
  )
}

export default RequirmentInfoList