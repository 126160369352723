import React,{useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import GoBack from '../../components/back/GoBack';
import { Select } from 'antd';
import { TextAreaInput1, TextInput } from '../../components/input';
import { ButtonFilledAutoWidth } from '../../components/button';
import { toast } from 'react-hot-toast'
import { BiCheckbox,BiCheckboxSquare } from 'react-icons/bi'
import { useSelector } from 'react-redux';
import { CreateFTSVerticalReportService, UpdateFTSVerticalReportService } from '../../services/ftsVerticalReportServices/FTSVerticalReportServices';
import { GetDepartmentService } from '../../services/DepartmentServices';
import FTSLeadMenu from '../fts_lead/FTSLeadMenu';
import ErrorComponent from '../../components/errorDesign/ErrorComponent';
import { CreateFTSVerticalMonthlyMeetingReviewService, UpdateFTSVerticalMonthlyMeetingReviewService, UploadFTSVerticalMonthlyMeetingReviewDocsService, UploadFTSVerticalMonthlyMeetingReviewService } from '../../services/ftsVerticalReportServices/FTSVerticalMonthlyMeetingReviewServices';
import Uploader from '../../components/Uploader';


function FTSVerticalMonthlyMeetingReviewCE() {

  const {state,pathname} = useLocation();
  const navigate = useNavigate();


  const status = [{label:"Pending",value:"Pending"},{label:"Wip",value:"Wip"},{label:"Converted",value:"Converted"},{label:"Lost",value:"Lost"}]

  
  const path = pathname.split('/')[1]



  // const [data,setdata] = useState({new_clients_approched:'',no_of_proposal_terms_sents:'',no_of_agreement_signed:'',no_of_active_req_shared_by_clients:'',references_provided_to_other_business_verticals:'',source_of_lead:'',department:''});  
  // const [error,seterror] = useState({new_clients_approched:'',no_of_proposal_terms_sents:'',no_of_agreement_signed:'',no_of_active_req_shared_by_clients:'',references_provided_to_other_business_verticals:'',source_of_lead:'',department:''}); 

  const [data,setdata] = useState({new_leads_generated:'',leads_recieved:'',leads_converted:'',leads_share:'',leads_rejected:'',department:''});  
  const [error,seterror] = useState({new_leads_generated:'',leads_recieved:'',leads_converted:'',leads_share:'',leads_rejected:'',department:''}); 


  const [departments,setdepartments] = useState([])
  
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  useEffect(()=>{

    if(state?._id !== undefined){

        let d = state 
        delete d.created_by
        setdata({
            ...data,
            ...state,
            department:{label:d?.department?.department_name,value:d?.department?._id}
        })
    }
    getdepartment()
  },[state])


  async function getdepartment() {
    const response = await GetDepartmentService();
    let arr = []
    response.data.datas.forEach((d)=>{
      if(d?.department_name !== 'Finance Team' && d?.department_name !== 'Gallery Team'  && d?.department_name !== 'Shilpa Foundation'  && d?.department_name !== 'Software Team' && d?.department_name !== 'Digital Media'  && d?.department_name !== 'Fidelitus Gallery' && d?.department_name !== 'Software Team' && d?.department_name !== 'Backend Team'){
        arr.push({label:d?.department_name,value:d?.id})
      }  
    })
    setdepartments(arr)
  }

   async function uploadfile(v,name){
      const fd = new FormData()
      fd.append('file',v); 
      const response = await UploadFTSVerticalMonthlyMeetingReviewDocsService(fd)
      if(response?.status === 200){
        setdata({...data,[name]:response?.data?.data})
        seterror({...error,[name]:''})
      }
    }

  async function submitform(){
    if(!data.new_leads_generated){
        seterror({...error,new_leads_generated:'This field is required *'})
    }else if(!data.leads_recieved){
        seterror({...error,leads_recieved:'This field is required *'})
    }else if(!data.leads_share){
      seterror({...error,leads_share:'This field is required *'})
    }else if(!data.leads_converted){
      seterror({...error,leads_converted:'This field is required *'})
    }else if(!data.leads_rejected){
      seterror({...error,leads_rejected:'This field is required *'})
    }else if(!data.department){
      seterror({...error,department:'This field is required *'})
    }else{
        let sendData = {...data}

        if(data?.created_by !== ''){
           sendData['created_by'] = data?.created_by?.value !== undefined ? data?.created_by?.value : data?.created_by
        }
        if(data?.department?.value !== undefined){
          sendData['department'] = data?.department?.value
        }else{
          delete sendData['department']
        }

        if(state?._id === undefined || state?._id === null){
            const response = await CreateFTSVerticalMonthlyMeetingReviewService(sendData)
            if (response.status === 201) {
            resetform()
            toast.success('FTS Vertical Monthly Meeting Review Created Successfully')
            }   
          
      }else{
        sendData["id"] = state?._id
        const response = await UpdateFTSVerticalMonthlyMeetingReviewService(sendData,state?._id)
        if (response.status === 200) {
          resetform()
          navigate(-1)
          toast.success('FTS Vertical Monthly Meeting Review Updated Successfully')
        }   
      }
    }
  }


  function resetform(){
    setdata({new_leads_generated:'',leads_recieved:'',leads_converted:'',leads_share:'',leads_rejected:'',department:''})
    seterror({new_leads_generated:'',leads_recieved:'',leads_converted:'',leads_share:'',leads_rejected:'',department:''})
  }

  function openFile(v){
    let url = `${process.env.REACT_APP_BACKEND_IMAGE_URL}${v}`
    window.open(url,'_blank')
   }

  return (
    <div className='flex '>
        {/* {pathname !== '/conveyance_vochuer_create' &&
        <FinanceMenu />} */}
         <div className={`min-w-44 ${path == 'profile' && 'min-w-[180px] pl-2'}`}>
             <FTSLeadMenu />
            </div>

        <div className={`w-full md:w-72 lg:w-72 px-4 pt-5`} >

            <GoBack /> 
            
            

            <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} Monthly Meeting Review</h6>
            <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Monthly Meeting Review</b> for your reference.</h6>
        



          


            <TextInput 
                label={'New leads generated Internally '}  
                variant="standard"
                name="new_leads_generated"
                type="number"
                mandatory={true}
                error={error.new_leads_generated}
                value={data.new_leads_generated}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'No. of leads received by CRM/ Corp/ Social Media. '}  
                variant="standard"
                name="leads_recieved"
                mandatory={true}
                type="number"
                error={error.leads_recieved}
                value={data.leads_recieved}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'No. of New Leads shared by business units to other business units. '}  
                variant="standard"
                mandatory={true}
                name="leads_share"
                type="number"
                error={error.leads_share}
                value={data.leads_share}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'No. of new leads converted'}  
                variant="standard"
                mandatory={true}
                name="leads_converted"
                type="number"
                error={error.leads_converted}
                value={data.leads_converted}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'No. of new leads rejected.'}  
                variant="standard"
                name="leads_rejected"
                type="number"
                mandatory={true}
                error={error.leads_rejected}
                value={data.leads_rejected}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


            {/* <TextInput 
                label={'Source of Lead'}  
                name="source_of_lead"
                type="text"
                mandatory={false}
                error={error.source_of_lead}
                value={data.source_of_lead}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> */}

             
            <>
           

            <h6 className='text-[11px] font-[600] mb-1 mt-2' >{"Department"}</h6>

            <div className='border border-l-4 border-l-slate-700 border-gray-300 py-[2px]'>
            <Select
              bordered={false}
              size='small'
              allowClear={true}
              onClear={()=>setdata({...data,department:''})}
              defaultValue={data?.department}
              value={data?.department}
              className='w-[100%]'
              options={departments}
              onChange={(v)=>{setdata({...data,department:departments?.find((f)=>f?.value === v)});seterror({...error,department:''})}}
            />
            </div>

            <ErrorComponent  error={error?.department}/>
            </>
 
            <div className='relative'>
            {!(data.file === '' || data.file == null) && <h6 onClick={()=>openFile(data?.file)} className='underline text-[10px] absolute cursor-pointer right-0 top-0 font-[600]'>View File</h6>}

            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Excel File</h6>    
            <Uploader image={data?.file} name="file"   setimagefunc={(e)=>{uploadfile(e,'file')}}  removeimageuploadfunc = {()=>setdata({...data,file:''})} /> 
            </div> 



            <div className='mt-5'>
            <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE DATA" : "ADD DATA"}  onClick={submitform} />  
            </div>
            
        </div>
   </div> 
  )
}

export default FTSVerticalMonthlyMeetingReviewCE