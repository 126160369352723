import React, { useEffect, useState } from 'react'
import GoBack from '../../../components/back/GoBack'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { TextAreaInput1, TextInput, TextInput1 } from '../../../components/input'
import DatabaseMenu from '../DatabaseMenu'
import { ButtonFilled, ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
// import { CreatePropertyTypeService, UpdatePropertyTypeService } from '../../../../services/database/databaseoptions/PropertyTypeServices'
import { CreateApprovedTypeService, UpdateApprovedTypeService } from '../../../services/database/databaseoptions/ApprovedTypeServices'
import { Modal, Select } from 'antd'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import {IoClose} from 'react-icons/io5'
import { Tooltip } from '@mui/material'
import { GetPropertyCategoryService } from '../../../services/database/databaseoptions/PropertyCategoryServices'
import { GetPropertyZoneService } from '../../../services/database/databaseoptions/PropertyZoneServices'
import { FilterLocationBasedZoneService, GetLocationBasedZoneService } from '../../../services/database/databaseoptions/LocationBasedZoneServices'
import { GetPropertyAmenitiesService } from '../../../services/database/databaseoptions/PropertyAmenitiesServices'
import { GetPropertyTypeService } from '../../../services/database/databaseoptions/PropertyTypeServices'
import { GetPropertyStatusService } from '../../../services/database/databaseoptions/PropertyStatusServices'
import { CreateManagedOfficeService, UpdateManagedOfficeService, UploadManagedOfficeAttachmentService } from '../../../services/database/mainoptions/ManagedOfficeServices'
import { GetFurnishTypeService } from '../../../services/database/databaseoptions/FurnishTypeServices'
import { AiFillPlusSquare } from "react-icons/ai";
import { CreateOfficeSpaceService, DeleteOfficeFloorOrUnitService, GetOfficeSpaceDetailService, UpdateOfficeSpaceService } from '../../../services/database/mainoptions/OfficeSpaceServices'
import { MdOutlineDeleteOutline } from "react-icons/md";
import ErrorComponent from '../../../components/errorDesign/ErrorComponent'
import { GetPropertyFacilityService } from '../../../services/database/databaseoptions/PropertyFacilityServices'

function OfficeSpaceCE() {
  
  const [data,setdata] = useState({property_category:'',property_type:'',building_name:'',zone:'',location_of_property:'',landmark_to_property:'',longitude:'',latitude:'',google_coordinate_link:'',office_space_address:'',total_land_area:'',total_built_up_area:'',total_floors_in_building:'',floor_plate_size:'',floor_area_efficiency:'',maintenace_charges:'',rent_price:'',sale_price:'',cafeteria:'',cafeteria_charges:'',power_and_backup:'',security_deposit:'',lock_in_period:'',lease_term_and_tenure:'',oc_availability:false,status:'',owner_name:'',owner_no:'',manager_name:'',manager_no:'',main_image:'',multiple_image:'',building_plan:'',presentation_ppt:'',term_sheet:'',remarks:'',is_deleted:false,facility_type:''})  
  const [error,seterror] = useState({property_category:'',property_type:'',building_name:'',zone:'',location_of_property:'',landmark_to_property:'',longitude:'',latitude:'',google_coordinate_link:'',office_space_address:'',total_land_area:'',total_built_up_area:'',total_floors_in_building:'',floor_plate_size:'',floor_area_efficiency:'',maintenace_charges:'',rent_price:'',sale_price:'',cafeteria:'',cafeteria_charges:'',power_and_backup:'',security_deposit:'',lock_in_period:'',lease_term_and_tenure:'',oc_availability:false,status:'',owner_name:'',owner_no:'',manager_name:'',manager_no:'',main_image:'',multiple_image:'',building_plan:'',presentation_ppt:'',term_sheet:'',remarks:'',is_deleted:'',facility_type:''})  

   
  const [floors,setfloors] = useState([])
  const [floorsErr,setfloorsErr] = useState([])
  
  const [floorData,setfloorData] = useState({floor_type:'',floor_name:'',floor_number:'',area_offered_in_sqft:'',floor_sale_price:'',floor_rent_per_sqft_per_month:'',maintenace_charges:'',floor_lock_in_period:'',floor_lease_term:'',car_parking_slots:'',car_parking_charges:'',facility_type:'',furnished_details:'',possession_type:'',security_deposit:'',escalation:'',status:'',owner_name:'',owner_no:'',manager_name:'',manager_no:'',main_image:'',multiple_image:'',building_plan:'',presentation_ppt:'',term_sheet:'',is_deleted:false})
  const [floorDataErr,setfloorDataErr] = useState({floor_type:'',floor_name:'',floor_number:'',area_offered_in_sqft:'',floor_sale_price:'',floor_rent_per_sqft_per_month:'',maintenace_charges:'',floor_lock_in_period:'',floor_lease_term:'',car_parking_slots:'',car_parking_charges:'',facility_type:'',furnished_details:'',possession_type:'',security_deposit:'',escalation:'',status:'',owner_name:'',owner_no:'',manager_name:'',manager_no:'',main_image:'',multiple_image:'',building_plan:'',presentation_ppt:'',term_sheet:'',is_deleted:''})

  const [unitData,setunitData] = useState({unit_type:'',unit_name:'',unit_number:'',area_offered_in_sqft:'',furnished_details:'',facility_type:'',car_parking_slots:'',car_parking_charges:'',rent_per_sqft_per_month:'',maintenace_charges:'',sale_price:'',lock_in_period:'',lease_term_and_tenure:'',security_deposit:'',escalation:'',possession_type:'',status:'',owner_name:'',owner_no:'',manager_name:'',manager_no:'',main_image:'',multiple_image:'',unit_plan:'',presentation_ppt:'',term_sheet:'',is_deleted:false})
  const [unitDataErr,setunitDataErr] = useState({unit_type:'',unit_name:'',unit_number:'',area_offered_in_sqft:'',furnished_details:'',facility_type:'',car_parking_slots:'',car_parking_charges:'',rent_per_sqft_per_month:'',maintenace_charges:'',sale_price:'',lock_in_period:'',lease_term_and_tenure:'',security_deposit:'',escalation:'',possession_type:'',status:'',owner_name:'',owner_no:'',manager_name:'',manager_no:'',main_image:'',multiple_image:'',unit_plan:'',presentation_ppt:'',term_sheet:'',is_deleted:''})


  const [step,setstep] = useState(1)

  const [statusArr,setstatusArr] = useState([])
  const [propertyTypeArr,setpropertyTypeArr] = useState([])
  const [baseTypeArr,setbaseTypeArr] = useState([])
  const [amenitiesArr,setamenitiesArr] = useState([])
  const [zonesArr,setzonesArr] = useState([])
  const [facilityTypeArr,setfacilityTypeArr] = useState([])
  const [propertyCategoryArr,setpropertyCategoryArr] = useState([])
  const [locationOfPropertyArr,setlocationOfPropertyArr] = useState([])


  const navigate = useNavigate()
  const  {state} = useLocation()

 

  useEffect(()=>{
    if(state !== undefined && state !== null){
        getdata()
    }
  },[state])

   async function getdata() {
      const response = await GetOfficeSpaceDetailService(state)
      let d = response?.data?.datas[0]

      let passData = {
        property_category:{label:d?.property_category?.name,value:d?.property_category?._id},
        property_type:{label:d?.property_type?.name,value:d?.property_type?._id},
        building_name:d?.building_name,
        zone:{label:d?.zone?.name,value:d?.zone?._id},
        location_of_property:{label:d?.location_of_property?.name,value:d?.location_of_property?._id},
        facility_type:d?.facility_type?._id !== undefined ? {label:d?.location_of_property?.name,value:d?.location_of_property?._id} : '',
        landmark_to_property:d?.landmark_to_property,
        longitude:d?.longitude,
        latitude:d?.latitude,
        google_coordinate_link:d?.google_coordinate_link,
        office_space_address:d?.office_space_address,
        total_land_area:d?.total_land_area,
        total_built_up_area:d?.total_built_up_area,
        total_floors_in_building:d?.total_floors_in_building,
        floor_plate_size:d?.floor_plate_size,
        floor_area_efficiency:d?.floor_area_efficiency,
        maintenace_charges:d?.maintenace_charges,
        rent_price:d?.rent_price,
        sale_price:d?.sale_price,
        cafeteria:d?.cafeteria,
        cafeteria_charges:d?.cafeteria_charges,
        power_and_backup:d?.power_and_backup,
        security_deposit:d?.security_deposit,
        lock_in_period:d?.lock_in_period,
        lease_term_and_tenure:d?.lease_term_and_tenure,
        oc_availability:d?.oc_availability,
        status:{label:d?.status?.name,value:d?.status?._id},
        owner_name:d?.owner_name,
        owner_no:d?.owner_no,
        manager_name:d?.manager_name,
        manager_no:d?.manager_no,
        main_image:d?.main_image,
        multiple_image:d?.multiple_image,
        building_plan:d?.building_plan,
        presentation_ppt:d?.presentation_ppt,
        term_sheet:d?.term_sheet,
        is_deleted:false
      }

      let floorInfo = d?.floors 
      let floorData = []
      let floorErr = []
      for(let i=0;i<floorInfo?.length;i++){
        let unitData = []
        let unitErr = []
        if(floorInfo[i]?.units?.length > 0){
           for(let i1=0;i1<floorInfo[i]?.units?.length;i1++){
                unitData.push({
                    ...floorInfo[i]?.units[i1],
                    unit_type:{label:floorInfo[i]?.units[i1]?.unit_type?.name,value:floorInfo[i]?.units[i1]?.unit_type?._id},
                    facility_type:{label:floorInfo[i]?.units[i1]?.facility_type?.name,value:floorInfo[i]?.units[i1]?.facility_type?._id},
                    status:{label:floorInfo[i]?.units[i1]?.status?.name,value:floorInfo[i]?.units[i1]?.status?._id}
                })
                unitErr.push(unitDataErr)
           }
        }
        floorData.push({
            ...floorInfo[i],
            floor_type:{label:floorInfo[i]?.floor_type?.name,value:floorInfo[i]?.floor_type?._id},
            facility_type:{label:floorInfo[i]?.facility_type?.name,value:floorInfo[i]?.facility_type?._id},
            status:{label:floorInfo[i]?.status?.name,value:floorInfo[i]?.status?._id},
            units:unitData})
        // console.log("when i is",i, "unitErr is",unitErr)
        floorErr[i] = {...floorDataErr,units:unitErr}
      } 
      
    //   console.log("floorErr",floorErr)

      setfloorsErr(floorErr)
      setfloors(floorData)
      setdata(passData)
    }

    // console.log("floors : -> ",floors)
    // console.log("floorDataErr : -> ",floorDataErr)


  async function submitApi() {

        if(!data?.property_type){
            setstep(1)
            seterror({...error,property_type:'This Field is required*'})
        }else if(!data?.property_category){
            setstep(1)
            seterror({...error,property_category:'This Field is required*'})
        }else if(!data?.building_name){
            setstep(1)
            seterror({...error,building_name:'This Field is required*'})
        }else if(!data?.zone){
            setstep(1)
            seterror({...error,zone:'This Field is required*'})
        }else if(!data?.location_of_property){
            setstep(1)
            seterror({...error,location_of_property:'This Field is required*'})
        }else if(!data?.landmark_to_property){
            setstep(1)
            seterror({...error,landmark_to_property:'This Field is required*'})
        }else if(!data?.status){
            setstep(1)
            seterror({...error,status:'This Field is required*'})
        }else if(!data?.main_image){
            setstep(1)
            seterror({...error,main_image:'This Field is required*'})
        }else{
            if(floors?.length > 0){
                for(let i=0;i<floors?.length;i++){
                    let errorData = [...floorsErr]
                    if(!floors[i]?.floor_type){
                        setstep(2)
                        if (!errorData[i]) {
                            errorData[i] = {floor_type:"This Field is required*"}; 
                        }else{
                            errorData[i] = {floor_type:"This Field is required*"}; 
                        }
                        setfloorsErr(errorData)
                    }else if(!floors[i]?.floor_name){
                        setstep(2)
                        if (!errorData[i]) {
                            errorData[i] = {floor_name:"This Field is required*"}; 
                        }else{
                            errorData[i] = {floor_name:"This Field is required*"}; 
                        }
                       
                        setfloorsErr(errorData)
                    }else if(!floors[i]?.floor_number){
                        setstep(2)
                        if (!errorData[i]) {
                            errorData[i] = {floor_number:"This Field is required*"}; 
                        }else {
                            errorData[i] = {floor_number:"This Field is required*"}; 
                        }
                       
                        setfloorsErr(errorData)
                    }else if(!floors[i]?.facility_type){
                        setstep(2)
                        if (!errorData[i]) {
                            errorData[i] = {facility_type:"This Field is required*"}; 
                        }else {
                            errorData[i] = {facility_type:"This Field is required*"}; 
                        }
                        
                        setfloorsErr(errorData)
                    }else if(!floors[i]?.status){
                        setstep(2)
                        if (!errorData[i]) {
                            errorData[i] = {status:"This Field is required*"}; 
                        }else {
                            errorData[i] = {status:"This Field is required*"}; 
                        }
                        
                        setfloorsErr(errorData)
                    }else if(!floors[i]?.main_image){
                        setstep(2)
                        if (!errorData[i]) {
                            errorData[i] = {main_image:"This Field is required*"}; 
                        }else{
                            errorData[i] = {main_image:"This Field is required*"}; 
                        }
                        
                        setfloorsErr(errorData)
                    }else{
                        if(floors[i]?.units?.length > 0){
                            // console.log("floors[i]?.units?.length",floors[i]?.units?.length)
                            for(let i1=0;i1<floors[i]?.units?.length;i1++){
                                let errorData = [...floorsErr]
                                let unitsErrorData = [...floorsErr[i]?.units]
                                let unitErrorData = {...unitsErrorData[i1]}
                              
                                if(!floors[i]?.units[i1]?.unit_type){
                                    setstep(2)
                                    unitErrorData["unit_type"] = "This Field is required*"; 
                                    unitsErrorData[i1] = unitErrorData
                                    errorData[i]['units'] = unitsErrorData
                                    // console.log("errorData",errorData)
                                    setfloorsErr(errorData)
                                    // errorData[i]?.units = unitErrorData
                                    // setfloorsErr(errorData)
    
                                }else if(!floors[i]?.units[i1]?.unit_name){
                                    setstep(2)
                                    unitErrorData["unit_name"] = "This Field is required*"; 
                                    unitsErrorData[i1] = unitErrorData
                                    errorData[i]['units'] = unitsErrorData
                                   
                                    setfloorsErr(errorData)
                                }else if(!floors[i]?.units[i1]?.unit_number){
                                    setstep(2)
                                    unitErrorData["unit_number"] = "This Field is required*"; 
                                    unitsErrorData[i1] = unitErrorData
                                    errorData[i]['units'] = unitsErrorData
                                   
                                    setfloorsErr(errorData)
                                }else if(!floors[i]?.units[i1]?.facility_type){
                                    setstep(2)
                                    unitErrorData["facility_type"] = "This Field is required*"; 
                                    unitsErrorData[i1] = unitErrorData
                                    errorData[i]['units'] = unitsErrorData
                                   
                                    setfloorsErr(errorData)
                                }else if(!floors[i]?.units[i1]?.status){
                                    setstep(2)
                                    unitErrorData["status"] = "This Field is required*"; 
                                    unitsErrorData[i1] = unitErrorData
                                    errorData[i]['units'] = unitsErrorData
                                    setfloorsErr(errorData)
                                }else if(!floors[i]?.units[i1]?.main_image){
                                    setstep(2)
                                    if (!errorData[i]) {
                                        errorData[i] = {main_image:"This Field is required*"}; 
                                    }else {
                                        errorData[i] = {main_image:"This Field is required*"}; 
                                    }
                                    
                                    setfloorsErr(errorData)
                                }else{
                                    console.log("Loop Outside")
                                    if((i == (floors?.length - 1)) && (floors[i]?.units?.length > 0 || (i1 == (floors[i]?.units?.length -1)))){
                                    console.log("Loop Inside")
                                        saveData()
                                    }
                                }
                            }
                        }else{
                            console.log("ammmmmaa")
                            if((i == (floors?.length - 1)) && (floors[i]?.units == undefined || floors[i]?.units?.length === 0)){
                                saveData()
                            }
                        }
                    }
                }
    
            }else{
                console.log(
                    "amam"
                )
                saveData()
            }

            
        }
  }

  async function saveData(){
    let send_data = {...data}

            if(data?.property_category?.value !== undefined){
                send_data['property_category'] = data?.property_category?.value
            }else{
                delete send_data['property_category']
            }

            if(data?.zone?.value !== undefined){
                send_data['zone'] = data?.zone?.value
            }else{
                delete send_data['zone']
            }

            if(data?.facility_type?.value !== undefined){
                send_data['facility_type'] = data?.facility_type?.value
            }else{
                delete send_data['facility_type']
            }

            if(data?.location_of_property?.value !== undefined){
                send_data['location_of_property'] = data?.location_of_property?.value
            }else{
                delete send_data['location_of_property']
            }

            if(data?.status?.value !== undefined){
                send_data['status'] = data?.status?.value
            }else{
                delete send_data['status']
            }

            if(data?.property_type?.value !== undefined){
                send_data['property_type'] = data?.property_type?.value
            }else{
                delete send_data['property_type']
            }

            let floorsData = []
            for (let i=0;i<floors?.length;i++){

                let passDataFloor = {...floors[i]}

                if(floors[i]?.status?.value !== undefined){
                    passDataFloor['status'] = floors[i]?.status?.value !== undefined ? floors[i]?.status?.value : ''
                }else{
                    delete passDataFloor['status']
                }
                if(floors[i]?.facility_type?.value !== undefined){
                    passDataFloor['facility_type'] = floors[i]?.facility_type?.value !== undefined ? floors[i]?.facility_type?.value : ''
                }else{
                    delete passDataFloor['facility_type']
                }
                if(floors[i]?.floor_type?.value !== undefined){
                    passDataFloor['floor_type'] = floors[i]?.floor_type?.value !== undefined ? floors[i]?.floor_type?.value : ''
                }else{
                    delete passDataFloor['floor_type']
                }

                if(floors[i]?.units !== undefined && floors[i]?.units?.length > 0){
                    let unitsData = []
                    for (let i1=0;i1<floors[i]?.units?.length;i1++){
                      
                        let passData = {...floors[i]?.units[i1]}
                        if(floors[i]?.units[i1]?.unit_type?.value !== undefined){
                            passData['unit_type'] = floors[i]?.units[i1]?.unit_type?.value !== undefined ? floors[i]?.units[i1]?.unit_type?.value :''
                        }else{
                            delete passData.unit_type
                        }
                        if(floors[i]?.units[i1]?.status?.value !== undefined){
                            passData['status'] = floors[i]?.units[i1]?.status?.value !== undefined ? floors[i]?.units[i1]?.status?.value :''
                        }else{
                            delete passData.status
                        }
                        if(floors[i]?.units[i1]?.facility_type?.value !== undefined){
                            passData['facility_type'] = floors[i]?.units[i1]?.facility_type?.value !== undefined ? floors[i]?.units[i1]?.facility_type?.value :''
                        }else{
                            delete passData.facility_type
                        }
                        unitsData.push({...passData})
                    }
                   
                   
                    passDataFloor['units'] = unitsData

                   
                    floorsData?.push(passDataFloor)

                }else{
                    let passData = {...floors[i]}
                    if(floors[i]?.status?.value !== undefined){
                        passData['status'] = floors[i]?.status?.value !== undefined ? floors[i]?.status?.value : ''
                    }else{
                        delete passData['status']
                    }
                    if(floors[i]?.facility_type?.value !== undefined){
                        passData['facility_type'] = floors[i]?.facility_type?.value !== undefined ? floors[i]?.facility_type?.value : ''
                    }else{
                        delete passData['facility_type']
                    }
                    if(floors[i]?.floor_type?.value !== undefined){
                        passData['floor_type'] = floors[i]?.floor_type?.value !== undefined ? floors[i]?.floor_type?.value : ''
                    }else{
                        delete passData['floor_type']
                        passData['status'] = floors[i]?.status?.value !== undefined ? floors[i]?.status?.value : ''
                    }
                    passData['units'] = []
                    floorsData?.push(passData)
                }

            
            }

            send_data['floors'] = floorsData

            // console.log("send_data",send_data)

            if(state !== undefined && state !== null){
                const response = await UpdateOfficeSpaceService(send_data,state)
                if(response?.status === 200){
                    toast.success("Office Space Updated Successfully")
                    resetform()
                    navigate(-1)
                }
            }else{
                const response = await CreateOfficeSpaceService(send_data)
                if(response?.status === 201){
                    toast.success("Office Space Created Successfully")
                    resetform()
                }
            }
  }

  async function submitform(){
    let errorStatus = false
    if(step == 1){
        if(!data?.property_type){
            errorStatus = true
            seterror({...error,property_type:'This Field is required*'})
        }else if(!data?.property_category){
            errorStatus = true
            seterror({...error,property_category:'This Field is required*'})
        }else if(!data?.building_name){
            errorStatus = true
            seterror({...error,building_name:'This Field is required*'})
        }else if(!data?.zone){
            errorStatus = true
            seterror({...error,zone:'This Field is required*'})
        }else if(!data?.location_of_property){
            errorStatus = true
            seterror({...error,location_of_property:'This Field is required*'})
        }else if(!data?.landmark_to_property){
            errorStatus = true
            seterror({...error,landmark_to_property:'This Field is required*'})
        }else if(!data?.status){
            errorStatus = true
            seterror({...error,status:'This Field is required*'})
        }else if(!data?.main_image){
            errorStatus = true
            seterror({...error,main_image:'This Field is required*'})
        }else{
            if(floors?.length > 0){
                for(let i=0;i<floors?.length;i++){
                    let errorData = [...floorsErr]
                    if(!floors[i]?.floor_type){
                        if (!errorData[i]) {
                            errorStatus = true
                            errorData[i] = {floor_type:"This Field is required*"}; 
                        }
                        setfloorsErr(errorData)
                    }else if(!floors[i]?.floor_name){
                        if (!errorData[i]) {
                            errorStatus = true
                            errorData[i] = {floor_name:"This Field is required*"}; 
                        }
                       
                        setfloorsErr(errorData)
                    }else if(!floors[i]?.floor_number){
                        if (!errorData[i]) {
                            errorStatus = true
                            errorData[i] = {floor_number:"This Field is required*"}; 
                        }
                       
                        setfloorsErr(errorData)
                    }else if(!floors[i]?.status){
                        if (!errorData[i]) {
                            errorStatus = true
                            errorData[i] = {status:"This Field is required*"}; 
                        }
                        
                        setfloorsErr(errorData)
                    }else if(!floors[i]?.main_image){
                        if (!errorData[i]) {
                            errorStatus = true
                            errorData[i] = {status:"This Field is required*"}; 
                        }
                        
                        setfloorsErr(errorData)
                    }else{
                        if(floors[i]?.units?.length > 0){
                            for(let i1=0;i1<floors[i]?.units?.length;i1++){
                                let errorData = [...floorsErr]
                                let unitsErrorData = [...floorsErr[i]?.units]
                                let unitErrorData = {...unitsErrorData[i1]}
                              
                                if(!floors[i]?.units[i1]?.unit_type){
                                    errorStatus = true
                                    unitErrorData["unit_type"] = "This Field is required*"; 
                                    unitsErrorData[i1] = unitErrorData
                                    errorData[i]['units'] = unitsErrorData
                                    // console.log("errorData",errorData)
                                    setfloorsErr(errorData)
                                    // errorData[i]?.units = unitErrorData
                                    // setfloorsErr(errorData)
    
                                }else if(!floors[i]?.units[i1]?.unit_name){
                                    errorStatus = true
                                    unitErrorData["unit_name"] = "This Field is required*"; 
                                    unitsErrorData[i1] = unitErrorData
                                    errorData[i]['units'] = unitsErrorData
                                   
                                    setfloorsErr(errorData)
                                }else if(!floors[i]?.units[i1]?.unit_number){
                                    errorStatus = true
                                    unitErrorData["unit_number"] = "This Field is required*"; 
                                    unitsErrorData[i1] = unitErrorData
                                    errorData[i]['units'] = unitsErrorData
                                   
                                    setfloorsErr(errorData)
                                }else if(!floors[i]?.units[i1]?.facility_type){
                                    errorStatus = true
                                    unitErrorData["facility_type"] = "This Field is required*"; 
                                    unitsErrorData[i1] = unitErrorData
                                    errorData[i]['units'] = unitsErrorData
                                   
                                    setfloorsErr(errorData)
                                }else if(!floors[i]?.units[i1]?.status){
                                    errorStatus = true
                                    unitErrorData["status"] = "This Field is required*"; 
                                    unitsErrorData[i1] = unitErrorData
                                    errorData[i]['units'] = unitsErrorData
                                    setfloorsErr(errorData)
                                }else if(!floors[i]?.units[i1]?.main_image){
                                    errorStatus = true
                                    if (!errorData[i]) {
                                        errorData[i] = {main_image:"This Field is required*"}; 
                                    }else {
                                        errorData[i] = {main_image:"This Field is required*"}; 
                                    }
                                    
                                    setfloorsErr(errorData)
                                }else{
                                    if((i == floors?.length - 1) && ( floors[i]?.units?.length > 0 || (i1 == floors[i]?.units?.length -1)) && !error){
                                        submitApi()
                                    }
                                }
                            }

                        }else{
                            if(i == floors?.length - 1){
                                submitApi()
                            }
                        }
                    }
                }

            }else{
            console.log("13")
                submitApi()
            }
        }
    }else if(step == 2){
        if(floors?.length > 0){
            for(let i=0;i<floors?.length;i++){
                let errorData = [...floorsErr]
                if(!floors[i]?.floor_type){
                    if (!errorData[i]) {
                        errorStatus = true
                        errorData[i] = {floor_type:"This Field is required*"}; 
                    }else{
                        errorStatus = true
                        errorData[i] = {floor_type:"This Field is required*"}; 
                    }
                    setfloorsErr(errorData)
                }else if(!floors[i]?.floor_name){
                    if (!errorData[i]) {
                        errorStatus = true
                        errorData[i] = {floor_name:"This Field is required*"}; 
                    }else{
                        errorStatus = true
                        errorData[i] = {floor_name:"This Field is required*"}; 
                    }
                   
                    setfloorsErr(errorData)
                }else if(!floors[i]?.floor_number){
                    if (!errorData[i]) {
                        errorStatus = true
                        errorData[i] = {floor_number:"This Field is required*"}; 
                    }else {
                        errorStatus = true
                        errorData[i] = {floor_number:"This Field is required*"}; 
                    }
                   
                    setfloorsErr(errorData)
                }else if(!floors[i]?.facility_type){
                    if (!errorData[i]) {
                        errorStatus = true
                        errorData[i] = {facility_type:"This Field is required*"}; 
                    }else {
                        errorStatus = true
                        errorData[i] = {facility_type:"This Field is required*"}; 
                    }
                    
                    setfloorsErr(errorData)
                }else if(!floors[i]?.status){
                    if (!errorData[i]) {
                        errorStatus = true
                        errorData[i] = {status:"This Field is required*"}; 
                    }else {
                        errorStatus = true
                        errorData[i] = {status:"This Field is required*"}; 
                    }
                    
                    setfloorsErr(errorData)
                }else if(!floors[i]?.main_image){
                    if (!errorData[i]) {
                        errorStatus = true
                        errorData[i] = {main_image:"This Field is required*"}; 
                    }else{
                        errorStatus = true
                        errorData[i] = {main_image:"This Field is required*"}; 
                    }
                    
                    setfloorsErr(errorData)
                }else{
                    // console.log("i here",i,"floors[i]?.units",floors[i]?.units?.length)
                    if(floors[i]?.units !== undefined && floors[i]?.units?.length > 0){
                        for(let i1=0;i1<floors[i]?.units?.length;i1++){
                            let errorData = [...floorsErr]
                            let unitsErrorData = [...floorsErr[i]?.units]
                            let unitErrorData = {...unitsErrorData[i1]}
                          
                            if(!floors[i]?.units[i1]?.unit_type){
                                errorStatus = true
                                unitErrorData["unit_type"] = "This Field is required*"; 
                                unitsErrorData[i1] = unitErrorData
                                errorData[i]['units'] = unitsErrorData

                                setfloorsErr(errorData)
                              

                            }else if(!floors[i]?.units[i1]?.unit_name){
                                errorStatus = true
                                unitErrorData["unit_name"] = "This Field is required*"; 
                                unitsErrorData[i1] = unitErrorData
                                errorData[i]['units'] = unitsErrorData
                               
                                setfloorsErr(errorData)
                            }else if(!floors[i]?.units[i1]?.unit_number){
                                errorStatus = true
                                unitErrorData["unit_number"] = "This Field is required*"; 
                                unitsErrorData[i1] = unitErrorData
                                errorData[i]['units'] = unitsErrorData
                               
                                setfloorsErr(errorData)
                            }else if(!floors[i]?.units[i1]?.facility_type){
                                errorStatus = true
                                // console.log("here we are",floors[i]?.units[i1]?.facility_type)
                                unitErrorData["facility_type"] = "This Field is required*"; 
                                unitsErrorData[i1] = unitErrorData
                                errorData[i]['units'] = unitsErrorData
                               
                                setfloorsErr(errorData)
                            }else if(!floors[i]?.units[i1]?.status){
                                errorStatus = true
                                // console.log("here okk na")
                                unitErrorData["status"] = "This Field is required*"; 
                                unitsErrorData[i1] = unitErrorData
                                errorData[i]['units'] = unitsErrorData
                                setfloorsErr(errorData)
                            }else if(!floors[i]?.units[i1]?.main_image){
                                errorStatus = true
                                // console.log("here okk na")
                                unitErrorData["main_image"] = "This Field is required*"; 
                                unitsErrorData[i1] = unitErrorData
                                errorData[i]['units'] = unitsErrorData
                                setfloorsErr(errorData)
                                
                                setfloorsErr(errorData)
                            }else{
                                
                                console.log("om here")
                                if(!errorStatus && (i == floors?.length - 1) && ( floors[i]?.units?.length > 0 || (i1 == floors[i]?.units?.length -1))){
                                    submitApi()
                                }
                            }
                        }
                    }else{
                        console.log("here we are")
                        if(!errorStatus && (i == (floors?.length - 1)) && (floors[i]?.units === undefined || floors[i]?.units?.length === 0)){
                            submitApi()
                        }
                    }
                }
            }

        }else{
            if(!errorStatus){
              submitApi()
            }
        }
    }
  }


  console.log("floorsErr : ",floorsErr)
  console.log("floors : ",floors)

  async function handlechange(e){
        setdata({...data,[e.target.name]:e.target.value})
        seterror({...error,[e.target.name]:''})
  }

  async function handlechange1(e,i) {
    let {name,value} = e.target
    let floorData = [...floors]
    let floorErrData = [...floorsErr]
    floorData[i][name] = value
    floorErrData[i][name] = ''
    setfloors(floorData)
    setfloorsErr(floorErrData)
  }

  async function handlechange2(e,i1,i) {
    let {name,value} = e.target
    let floorData = [...floors]
    let floorErrData = [...floorsErr]
    floorData[i]['units'][i1][name] = value
    floorErrData[i]['units'][i1][name] = ''
    setfloors(floorData)
    setfloorsErr(floorErrData)
    
  }

  async function uploadfilefunc(v,name,type,i,type1,i1){

    const fd = new FormData()
    fd.append('image',v); 
    const response = await UploadManagedOfficeAttachmentService(fd)
    if(response?.status === 200){
      if(type === undefined){  
        setdata({...data,[name]:response?.data?.data})
        seterror({...error,[name]:''})
      }else if(type1 === undefined){
        let floorsData = [...floors]
        let floorsErrData = [...floorsErr]
        console.log("floorsData",floorsData)
        console.log("floorsErrData",floorsErrData)
        if(floorsData[i][name] === undefined){
         floorData[i]['main_image'] = response?.data?.data
        }else{
        floorsData[i][name] = response?.data?.data
        }
        floorsErrData[i][name] = ''
        // console.log("floorsData",floorsData)
        setfloors(floorsData)
        setfloorsErr(floorsErrData)
      }else{
        let floorsData = [...floors]
        let floorsErrData = [...floorsErr]
        // console.log("floorsData",floorsData )
        // console.log("floorsData[i]['units'][i1][name]",floorsData[i]['units'][i1][name] )
        floorsData[i]['units'][i1][name] = response?.data?.data
        floorsErrData[i]['units'][i1][name] = ''
        setfloors(floorsData)
        setfloorsErr(floorsErrData)
      }
    }
  }

  async function getoptions(v,type) {
    if(type == 'base_type'){
        const response = await GetPropertyTypeService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setbaseTypeArr(arr)
    }
    if(type == 'status'){
        const response = await GetPropertyStatusService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        console.log("d here okk na",d)
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setstatusArr(arr)
    }
    if(type == 'facility_type'){
        const response = await GetPropertyFacilityService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setfacilityTypeArr(arr)
    }
    if(type == 'amenities'){
        const response = await GetPropertyAmenitiesService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setamenitiesArr(arr)
    }
    if(type == 'location_of_property'){
        const response = await GetLocationBasedZoneService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setlocationOfPropertyArr(arr)
    }
    if(type == 'property_category'){
        const response = await GetPropertyCategoryService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setpropertyCategoryArr(arr)
    }
    if(type == 'zone'){
        const response = await GetPropertyZoneService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setzonesArr(arr)
    }
    if(type == 'property_type'){
        const response = await GetPropertyTypeService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setpropertyTypeArr(arr)
    }
  }

  async function onChange(v,type){
    if(type == 'amenities'){
        let arr = []
        v?.forEach((v1) =>{
            let amenitiesFind = amenitiesArr?.find((f)=>f?.value == v1)
            if(amenitiesFind !== null && amenitiesFind !== undefined){
                arr?.push(amenitiesFind)
            }else{
             let amenitiesFind = data.amenities?.find((f)=>f?.value == v1)
              arr?.push(amenitiesFind)
            }
        })
        setdata({...data,amenities:arr})
        seterror({...error,amenities:''})
    }
    if(type == 'zone'){
        let zoneFind = zonesArr?.find((f)=>f?.value == v)
        
        let locationSelected = data?.location_of_property

        const response = await FilterLocationBasedZoneService(zoneFind?.value)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id,zone:zoneFind})
        })
        setlocationOfPropertyArr(arr)

        if(locationSelected?.zone?.value !== zoneFind?.value){
            setdata({...data,zone:zoneFind,location_of_property:''})
            seterror({...error,zone:''})
        }else{
          setdata({...data,zone:zoneFind})
          seterror({...error,zone:''})
        }

    }
    if(type == 'facility_type'){
        let facilityTypeFind = facilityTypeArr?.find((f)=>f?.value == v)
        setdata({...data,facility_type:facilityTypeFind})
        seterror({...error,facility_type:''})
    }
    if(type == 'status'){
        let statusFind = statusArr?.find((f)=>f?.value == v)
        setdata({...data,status:statusFind})
        seterror({...error,status:''})
    }
    if(type == 'base_type'){
        let baseTypeFind = baseTypeArr?.find((f)=>f?.value == v)
        setdata({...data,base_type:baseTypeFind})
        seterror({...error,base_type:''})
    }
    if(type == 'property_type'){
        let propertyTypeFind = propertyTypeArr?.find((f)=>f?.value == v)
        setdata({...data,property_type:propertyTypeFind})
        seterror({...error,property_type:''})
    }
    if(type == 'property_category'){
        let propertyCategoryFind = propertyCategoryArr?.find((f)=>f?.value == v)
        setdata({...data,property_category:propertyCategoryFind})
        seterror({...error,property_category:''})
    }
    if(type == 'location_of_property'){
        let locationofPropertyFind = locationOfPropertyArr?.find((f)=>f?.value == v)
        setdata({...data,location_of_property:locationofPropertyFind})
        seterror({...error,location_of_property:''})
    }
  }

  async function onChange1(v,type,type1,i){
    if(type == 'status'){
        let statusFind = statusArr?.find((f)=>f?.value == v)
        let floorData = [...floors]
        let floorErrData = [...floorsErr]
        floorData[i] = {...floorData[i],status:statusFind}
        floorErrData[i] = {...floorErrData[i],status:''}
        setfloors(floorData)
        setfloorsErr(floorErrData)
    }

    if(type == 'floor_type'){
        let floorTypeFind = propertyTypeArr?.find((f)=>f?.value == v)
        let floorData = [...floors]
        let floorErrData = [...floorsErr]
        floorData[i] = {...floorData[i],floor_type:floorTypeFind}
        floorErrData[i] = {...floorErrData[i],floor_type:''}
        setfloors(floorData)
        setfloorsErr(floorErrData)
    }

    if(type == 'facility_type'){
        let facilityTypeFind = facilityTypeArr?.find((f)=>f?.value == v)
        let floorData = [...floors]
        let floorErrData = [...floorsErr]
        floorData[i] = {...floorData[i],facility_type:facilityTypeFind}
        floorErrData[i] = {...floorErrData[i],facility_type:''}
        setfloors(floorData)
        setfloorsErr(floorErrData)
    }
  }

  async function onChange2(v,type,type1,i,type2,i1){
    if(type == 'status'){
        let statusFind = statusArr?.find((f)=>f?.value == v)
        let floorData = [...floors]
        let floorErrData = [...floorsErr]
        let unitData = {...floorData[i].units[i1]}
        let unitErrData = {...floorErrData[i].units[i1]}
        unitData['status'] = statusFind
        unitErrData['status'] = ''
        floorData[i].units[i1] = unitData
        floorErrData[i].units[i1] = unitErrData
        setfloors(floorData)
        setfloorsErr(floorErrData)
    }

    if(type == 'unit_type'){
        let unitTypeFind = propertyTypeArr?.find((f)=>f?.value == v)
        let floorData = [...floors]
        let floorErrData = [...floorsErr]
        let unitData = {...floorData[i].units[i1]}
        let unitErrData = {...floorErrData[i].units[i1]}
        unitData['unit_type'] = unitTypeFind
        unitErrData['unit_type'] = ''
        floorData[i].units[i1] = unitData
        floorErrData[i].units[i1] = unitErrData
        setfloors(floorData)
        setfloorsErr(floorErrData)
    }

    if(type == 'facility_type'){
        let facilityTypeFind = facilityTypeArr?.find((f)=>f?.value == v)
        let floorData = [...floors]
        let floorErrData = [...floorsErr]
        let unitData = {...floorData[i].units[i1]}
        let unitErrData = {...floorErrData[i].units[i1]}
        unitData['facility_type'] = facilityTypeFind
        unitErrData['facility_type'] = ''
        floorData[i].units[i1] = unitData
        floorErrData[i].units[i1] = unitErrData
        setfloors(floorData)
        setfloorsErr(floorErrData)
    }
  }

 function resetform(){
    setdata({property_category:'',property_sub_category:'',property_type:'',building_name:'',zone:'',location_of_property:'',landmark_to_property:'',longitude:'',latitude:'',google_coordinate_link:'',office_space_address:'',total_land_area:'',total_built_up_area:'',total_floors_in_building:'',floor_plate_size:'',floor_area_efficiency:'',maintenace_charges:'',rent_price:'',sale_price:'',cafeteria:'',cafeteria_charges:'',power_and_backup:'',security_deposit:'',lock_in_period:'',lease_term_and_tenure:'',oc_availability:false,status:'',owner_name:'',owner_no:'',manager_name:'',manager_no:'',main_image:'',multiple_image:'',building_plan:'',presentation_ppt:'',term_sheet:'',is_deleted:false,facility_type:''})
    seterror({property_category:'',property_sub_category:'',property_type:'',building_name:'',zone:'',location_of_property:'',landmark_to_property:'',longitude:'',latitude:'',google_coordinate_link:'',office_space_address:'',total_land_area:'',total_built_up_area:'',total_floors_in_building:'',floor_plate_size:'',floor_area_efficiency:'',maintenace_charges:'',rent_price:'',sale_price:'',cafeteria:'',cafeteria_charges:'',power_and_backup:'',security_deposit:'',lock_in_period:'',lease_term_and_tenure:'',oc_availability:false,status:'',owner_name:'',owner_no:'',manager_name:'',manager_no:'',main_image:'',multiple_image:'',building_plan:'',presentation_ppt:'',term_sheet:'',is_deleted:'',facility_type:''})

    setfloorData({floor_type:'',floor_name:'',floor_number:'',area_offered_in_sqft:'',floor_sale_price:'',floor_rent_per_sqft_per_month:'',maintenace_charges:'',floor_lock_in_period:'',floor_lease_term:'',car_parking_slots:'',car_parking_charges:'',facility_type:'',furnished_details:'',possession_type:'',status:'',owner_name:'',owner_no:'',manager_name:'',manager_no:'',main_image:'',multiple_image:'',building_plan:'',presentation_ppt:'',term_sheet:'',is_deleted:false})
    setfloorDataErr({floor_type:'',floor_name:'',floor_number:'',area_offered_in_sqft:'',floor_sale_price:'',floor_rent_per_sqft_per_month:'',maintenace_charges:'',floor_lock_in_period:'',floor_lease_term:'',car_parking_slots:'',car_parking_charges:'',facility_type:'',furnished_details:'',possession_type:'',status:'',owner_name:'',owner_no:'',manager_name:'',manager_no:'',main_image:'',multiple_image:'',building_plan:'',presentation_ppt:'',term_sheet:'',is_deleted:''})

    setunitData({unit_type:'',unit_name:'',unit_number:'',area_offered_in_sqft:'',maintenace_charges:'',car_parking_slots:'',car_parking_charges:'',lock_in_period:'',lease_term_and_tenure:'',escalation:'',facility_type:'',furnished_details:'',possession_type:'',status:'',owner_name:'',owner_no:'',manager_name:'',manager_no:'',main_image:'',multiple_image:'',unit_plan:'',presentation_ppt:'',term_sheet:'',is_deleted:false})
    setunitDataErr({unit_type:'',unit_name:'',unit_number:'',area_offered_in_sqft:'',maintenace_charges:'',car_parking_slots:'',car_parking_charges:'',lock_in_period:'',lease_term_and_tenure:'',escalation:'',facility_type:'',furnished_details:'',possession_type:'',status:'',owner_name:'',owner_no:'',manager_name:'',manager_no:'',main_image:'',multiple_image:'',unit_plan:'',presentation_ppt:'',term_sheet:'',is_deleted:''})
    
    setfloors([])
  
}

 function addFloor() {
    let oldData = [...floors]
    let oldDataError = [...floorsErr]
    if(floors.length > 0){
        oldData.push({...floorData,id:oldData?.length+1})
        oldDataError.push({...floorDataErr,id:oldData?.length+1})
        setfloors(oldData)
        setfloorsErr(oldDataError)
    }else{
        setfloors([{...floorData,id:oldData?.length+1}])
        setfloorsErr([{...floorDataErr,id:oldData?.length+1}])
    }
 }

 function addUnits(i){
    let floorData = floors[i]
    console.log("i here",i)
    console.log("floorData",floorData)
    if(floorData !== undefined){
        if(floorData?.units !== undefined && floorData?.units?.length > 0){
            let oldData = [...floors]
            let oldDataError = [...floorsErr]
            oldData[i] = {...floors[i],units:[...floors[i].units,{...unitData,id:floors[i].units.length+1}]}
            oldDataError[i] = {...floorsErr[i],units:[...floorsErr[i].units,{...unitDataErr,id:floorsErr[i].units.length+1}]}
            setfloors(oldData)
            setfloorsErr(oldDataError)
        }else{
            let oldData = [...floors]
            let oldDataError = [...floorsErr]
            oldData[i] = {...floors[i],units:[{...unitData,id:1}]}
            oldDataError[i] = {...floorsErr[i],units:[{...unitDataErr,id:1}]}
            setfloors(oldData)
            setfloorsErr(oldDataError)
        }
    }

 }

 async function removeImage(name,i,type,i1,type1) {
    if(type1 === undefined){
        let floorsData = [...floors]
        floorsData[i][name] = ''
        setfloors(floorsData)
    }else{
        let floorsData = [...floors]
        floorsData[i]['units'][i1][name] = ''
        setfloors(floorsData)
    } 
 }

 async function removedatafun(type,i,i1) {
    // console.log("type",type)
    // console.log("i",i)
    // console.log("i1",i1)
   
    if(type == 'floor'){
        let OldData = [...floors]
        let floorData = OldData[i]
        if(state !== undefined && state !== null){
            if(floorData?._id !== undefined){
                const response = await DeleteOfficeFloorOrUnitService('floor',floorData?._id)
                if(response?.status == 200){
                    toast.success("Floor Deleted From DB")
                }
                getdata()
            }

        }
        OldData?.splice(i,1)
        setfloors(OldData)
    }else if(type == 'unit'){

        let OldData = [...floors]
        let floorData = OldData[i]
        let unitData = floorData?.units[i1]
         
        if(state !== undefined && state !== null){
            if(unitData?._id !== undefined){
                const response = await DeleteOfficeFloorOrUnitService('unit',unitData?._id)
                if(response?.status == 200){
                    toast.success("Unit Deleted From DB")
                }
                getdata()
            }
        }else{
            floorData?.units?.splice(i1,1)
            OldData[i] = floorData
            setfloors(OldData)
        }

    }
    
 }

 function openfilefunc(v){
    window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${v?.replace('public/','')}`,'_blank')
  }

  return (
    <div className='flex'>
         
         

        <div className='min-w-[180px] w-[180px] max-w-[180px]'>
        <DatabaseMenu /> 
        </div>
        <div className='ml-4 w-full mt-4 pr-5 min-h-screen max-h-screen h-screen overflow-y-scroll'>
        <div>
        <GoBack />

        <div className='border-b   pb-2'>
        <h6 className='font-[800] mb-1'>Create / Edit Office Space</h6> 
        <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the office space for the property function.</h6> 
        </div> 


       <div className=''>
        <div className='flex  border-b text-[11px]'>
           <h6 onClick={()=>setstep(1)} className={`w-[90px] cursor-pointer p-[4px] text-center ${step == 1 && 'bg-slate-600 text-white'} font-[600]`}>Building Info</h6>
           <h6 onClick={()=>setstep(2)} className={`w-[90px] cursor-pointer p-[4px] text-center ${step == 2 && 'bg-slate-600 text-white'} font-[600]`}>Floor Info</h6>
        </div>    

        {step === 1 && 
        <div className='flex pt-4 flex-wrap items-start justify-between'>
        
            
        <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <h6 className='text-[12px] font-semibold mb-1'>Property Type*</h6>
                <Select 
                    bordered={false}
                    filterOption={false}
                    showSearch={true}
                    allowClear={true} 
                    options={propertyTypeArr}
                    value={data?.property_type}
                    optionFilterProp="children"
                    onChange={(v)=>onChange(v,'property_type')}
                    onSearch={(v)=>getoptions(v,'property_type')}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                
                <ErrorComponent error={error?.property_type} />
               
                </div>    

               <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <h6 className='text-[12px] font-semibold mb-1'>Property Category*</h6>
                <Select 
                    bordered={false}
                    filterOption={false}
                    showSearch={true}
                    allowClear={true} 
                    options={propertyCategoryArr}
                    value={data?.property_category}
                    optionFilterProp="children"
                    onChange={(v)=>onChange(v,'property_category')}
                    onSearch={(v)=>getoptions(v,'property_category')}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                
                <ErrorComponent error={error?.property_category} />
               
                </div>


                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>                
                <TextInput 
                    mandatory={true}
                    label={'Building Name'}  
                    variant="standard"
                    name="building_name"
                    type="text"
                    value={data.building_name}
                    error={error.building_name}
                    handlechange={handlechange}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>  
                </div>    
                
                

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <h6 className='text-[12px] font-semibold mb-1'>Zone*</h6>
                <Select 
                    bordered={false}
                    filterOption={false}
                    showSearch={true}
                    allowClear={true} 
                    options={zonesArr}
                    value={data?.zone}
                    optionFilterProp="children"
                    onChange={(v)=>onChange(v,'zone')}
                    onSearch={(v)=>getoptions(v,'zone')}
                    style={{fontSize:'12px',height:'32px'}}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                
                <ErrorComponent error={error?.zone} />
                
                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <h6 className='text-[12px] font-semibold mb-1'>Location Of Property*</h6>
                <Select 
                    multiple={true}
                    bordered={false}
                    value={data?.location_of_property}
                    options={locationOfPropertyArr}
                    onChange={(v)=>onChange(v,'location_of_property')}
                    style={{fontSize:'12px',height:'32px'}}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                    
                <ErrorComponent error={error?.location_of_property} />

                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={true}
                    label={'LandMark To Property'}  
                    variant="standard"
                    name="landmark_to_property"
                    type="text"
                    value={data.landmark_to_property}
                    error={error.landmark_to_property}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Longitude'}  
                    variant="standard"
                    name="longitude"
                    type="text"
                    value={data.longitude}
                    error={error.longitude}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div>  

                 

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Latitude'}  
                    variant="standard"
                    name="latitude"
                    type="text"
                    value={data.latitude}
                    error={error.latitude}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>  
                </div>   

                 <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                    <TextInput 
                    mandatory={false}
                    label={'Google Coordinate Link'}  
                    variant="standard"
                    name="google_coordinate_link"
                    type="text"
                    value={data.google_coordinate_link}
                    error={error.google_coordinate_link}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>  

                    
                 </div>

                 <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Office Space Address'}  
                    variant="standard"
                    name="office_space_address"
                    type="text"
                    value={data.office_space_address}
                    error={error.office_space_address}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>  
                </div>
 


                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>            
                <TextInput 
                    mandatory={false}
                    label={'Total Land Area'}  
                    variant="standard"
                    name="total_land_area"
                    type="text"
                    value={data.total_land_area}
                    error={error.total_land_area}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>  
                </div>    

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Total Built Up Area'}  
                    variant="standard"
                    name="total_built_up_area"
                    type="text"
                    value={data.total_built_up_area}
                    error={error.total_built_up_area}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>  
                </div>   

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Total Floors In Building'}  
                    variant="standard"
                    name="total_floors_in_building"
                    type="text"
                    value={data.total_floors_in_building}
                    error={error.total_floors_in_building}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div>   

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Floor Plate Size'}  
                    variant="standard"
                    name="floor_plate_size"
                    type="text"
                    value={data.floor_plate_size}
                    error={error.floor_plate_size}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div>  


                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Floor Area Efficiency'}  
                    variant="standard"
                    name="floor_area_efficiency"
                    type="text"
                    value={data.floor_area_efficiency}
                    error={error.floor_area_efficiency}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div> 

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Maintenance Charges'}  
                    variant="standard"
                    name="maintenace_charges"
                    type="text"
                    value={data.maintenace_charges}
                    error={error.maintenace_charges}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div>   

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Rent Price'}  
                    variant="standard"
                    name="rent_price"
                    type="text"
                    value={data.rent_price}
                    error={error.rent_price}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div>   

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Sale Price'}  
                    variant="standard"
                    name="sale_price"
                    type="text"
                    value={data.sale_price}
                    error={error.sale_price}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div>  
  

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Cafeteria'}  
                    variant="standard"
                    name="cafeteria"
                    type="text"
                    value={data.cafeteria}
                    error={error.cafeteria}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div>   

                 <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Cafeteria Charges'}  
                    variant="standard"
                    name="cafeteria_charges"
                    type="text"
                    value={data.cafeteria_charges}
                    error={error.cafeteria_charges}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div>   

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Power And Backup'}  
                    variant="standard"
                    name="power_and_backup"
                    type="text"
                    value={data.power_and_backup}
                    error={error.power_and_backup}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div>     
 
                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Security Deposit'}  
                    variant="standard"
                    name="security_deposit"
                    type="text"
                    value={data.security_deposit}
                    error={error.security_deposit}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div>    


                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Lock In Period'}  
                    variant="standard"
                    name="lock_in_period"
                    type="text"
                    value={data.lock_in_period}
                    error={error.lock_in_period}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div>   

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Lease Term And Tenure'}  
                    variant="standard"
                    name="lease_term_and_tenure"
                    type="text"
                    value={data.lease_term_and_tenure}
                    error={error.lease_term_and_tenure}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div>  

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                    <h6 className='text-[11px] font-[600] mt-4 mb-1' >OC Availablility</h6>
                    <div onClick={()=>setdata({...data,oc_availability:!data?.oc_availability})}>
                    {!data?.oc_availability ?   
                    <BiCheckbox size={20} className='text-slate-300' /> : 
                    <BiCheckboxSquare size={20} className='text-slate-700' /> }
                    </div>
                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <h6 className='text-[12px] font-semibold mb-1'>Status*</h6>
                <Select 
                    multiple={true}
                    bordered={false}
                    value={data?.status}
                    options={statusArr}
                    showSearch={true}
                    allowClear={true} 
                    onChange={(v)=>onChange(v,'status')}
                    onSearch={(v)=>getoptions(v,'status')}
                    style={{fontSize:'12px',height:'32px'}}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                
                <ErrorComponent error={error?.status} />
                
                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mt-0'>
                    <h6 className='text-[12px] font-semibold mb-1'>Facility Type*</h6>
                    <Select 
                        bordered={false}
                        filterOption={false}
                        showSearch={true}
                        allowClear={true} 
                        options={facilityTypeArr}
                        value={data?.facility_type}
                        optionFilterProp="children"
                        width={'100%'}
                        onChange={(v)=>onChange(v,'facility_type')}
                        onSearch={(v)=>getoptions(v,'facility_type')}
                        style={{fontSize:'12px',width:'100%',height:'32px'}}
                        className='min-w-[100%] max-w-[100%] border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                    <ErrorComponent error={error?.facility_type} />
                </div>  

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Owner Name'}  
                    variant="standard"
                    name="owner_name"
                    type="text"
                    value={data.owner_name}
                    error={error.owner_name}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div>    

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Owner No'}  
                    variant="standard"
                    name="owner_no"
                    type="text"
                    value={data.owner_no}
                    error={error.owner_no}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                </div>   

                  <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                    <TextInput 
                        mandatory={false}
                        label={'Manager Name'}  
                        variant="standard"
                        name="manager_name"
                        type="text"
                        value={data.manager_name}
                        error={error.manager_name}
                        handlechange={handlechange}
                        placeholder="Enter contact name"
                        InputLabelProps={{
                            style: { color: '#fff', }, 
                        }}/> 
                    </div>   

                   <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                    <TextInput 
                        mandatory={false}
                        label={'Manager No'}  
                        variant="standard"
                        name="manager_no"
                        type="text"
                        value={data.manager_no}
                        error={error.manager_no}
                        handlechange={handlechange}
                        placeholder="Enter contact name"
                        InputLabelProps={{
                            style: { color: '#fff', }, 
                        }}/> 
                    </div>     

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <h6 className='text-[11px] font-[600] mb-1' >Main Image</h6>
                {(data.main_image === '' || data.main_image == null) ?
                    <form onClick={()=>document.querySelector(`.input-field15`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-l-4 border-l-slate-700 border-slate-300 `}>
                        <input type='file' onChange={({target:{files}})=>{
                        files[0] && uploadfilefunc(files[0],'main_image')
                        }} accept="*" className='input-field15' hidden />
                    </form> 
                :   
                <div className='relative'>
                    <h6 onClick={()=>openfilefunc(data?.main_image)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                    <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                        <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,main_image:''})}/></Tooltip>
                        <h6 className='text-[12px] truncate w-48 ml-0'>{data?.main_image}</h6>
                    </div>
                </div>
                }

                <ErrorComponent error={error?.main_image} />
                

                </div>


            
{/* 
                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <h6 className='text-[12px] font-semibold mb-1'>Property Sub Category*</h6>
                <Select 
                multiple={true}
                bordered={false}
                value={data?.property_sub_category}
                options={[]}
                onChange={(v)=>handleSelect(v,'property_sub_category')}
                style={{fontSize:'12px',height:'32px'}}
                className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                </div> */}

                


               

                
               
                   


                

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                    <h6 className='text-[11px] font-[600] mb-1' >Multiple Image</h6>
                    {(data.multiple_image === '' || data.multiple_image == null) ?
                        <form onClick={()=>document.querySelector(`.input-field13`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                            <input type='file' onChange={({target:{files}})=>{
                            files[0] && uploadfilefunc(files[0],'multiple_image')
                            }} accept="*" className='input-field13' hidden />
                        </form> 
                    :
                    <div className='relative'>
                        <h6 onClick={()=>openfilefunc(data?.multiple_image)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                        <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                            <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,multiple_image:''})}/></Tooltip>
                            <h6 className='text-[12px] truncate w-48 ml-0'>{data?.multiple_image}</h6>
                        </div>
                    </div>    
                    }

                <ErrorComponent error={error?.multiple_image} />

                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                    <h6 className='text-[11px] font-[600] mb-1' >Building Plan</h6>
                    {(data.building_plan === '' || data.building_plan == null) ?
                        <form onClick={()=>document.querySelector(`.input-field12`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                            <input type='file' onChange={({target:{files}})=>{
                            files[0] && uploadfilefunc(files[0],'building_plan')
                            }} accept="*" className='input-field12' hidden />
                        </form> 
                    :
                    <div className='relative'>
                        <h6 onClick={()=>openfilefunc(data?.building_plan)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                        <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                            <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,building_plan:''})}/></Tooltip>
                            <h6 className='text-[12px] truncate w-48 ml-0'>{data?.building_plan}</h6>
                        </div>
                    </div>    
                    }

                <ErrorComponent error={error?.building_plan} />

                </div>


                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                    <h6 className='text-[11px] font-[600] mb-1' >Presentation PPT</h6>
                    {(data.presentation_ppt === '' || data.presentation_ppt == null) ?
                        <form onClick={()=>document.querySelector(`.input-field11`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                            <input type='file' onChange={({target:{files}})=>{
                            files[0] && uploadfilefunc(files[0],'presentation_ppt')
                            }} accept="*" className='input-field11' hidden />
                        </form> 
                    :
                    <div className='relative'>
                        <h6 onClick={()=>openfilefunc(data?.presentation_ppt)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                        <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                            <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,presentation_ppt:''})}/></Tooltip>
                            <h6 className='text-[12px] truncate w-48 ml-0'>{data?.presentation_ppt}</h6>
                        </div>
                    </div>    
                    }

                    <ErrorComponent error={error?.presentation_ppt} />

                    </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <h6 className='text-[11px] font-[600] mb-1' >Term Sheet</h6>
                {(data.term_sheet === '' || data.term_sheet == null) ?
                    <form onClick={()=>document.querySelector(`.input-field14`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                        <input type='file' onChange={({target:{files}})=>{
                        files[0] && uploadfilefunc(files[0],'term_sheet')
                        }} accept="*" className='input-field14' hidden />
                    </form> 
                :
                <div className='relative'>
                    <h6 onClick={()=>openfilefunc(data?.term_sheet)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                    <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                        <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,term_sheet:''})}/></Tooltip>
                        <h6 className='text-[12px] truncate w-48 ml-0'>{data?.term_sheet}</h6>
                    </div>
                </div>    
                }

                <ErrorComponent error={error?.term_sheet} />

                </div>
    
                    <div className='min-w-[100%] w-[100%] max-w-[100%] mb-2'>
                <TextAreaInput1 
                    mandatory={false}
                    label={'Remarks'}  
                    variant="standard"
                    name="remarks"
                    type="text"
                    value={data?.remarks}
                    error={error?.remarks}
                    handlechange={handlechange}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div> 


        </div>}

        {step === 2 && 
        <div className='pt-4 relative '>

            <div className='flex items-center px-2 justify-between'>
                <h6 className='font-[700] text-[12px]'>Floor Length ({floors?.length})</h6>
                <ButtonFilledAutoWidth onClick={()=>addFloor()}  btnName="Add Floor" />
            </div>
           

           {floors?.map((f,i)=>(
            <div className='w-[98.5%] mt-2 relative mx-auto border p-4'>
                <MdOutlineDeleteOutline onClick={()=>removedatafun('floor',i)} className='bg-red-200 p-1 cursor-pointer absolute right-2 top-2 text-[18px]' />
                
                <div className='w-[15px] h-[20px] absolute top-0 left-0 bg-green-800/60'>
                </div>    
                <div className='flex flex-wrap justify-between'>
                <div className='min-w-[24%] w-[24%] max-w-[24%] mt-2'>
                    <h6 className='text-[12px] font-semibold mb-1'>Floor Type*</h6>
                    <Select 
                        bordered={false}
                        filterOption={false}
                        showSearch={true}
                        allowClear={true} 
                        options={propertyTypeArr}
                        value={floors[i]?.floor_type}
                        optionFilterProp="children"
                        width={'100%'}
                        onChange={(v)=>onChange1(v,'floor_type','floor',i)}
                        onSearch={(v)=>getoptions(v,'property_type')}
                        style={{fontSize:'12px',width:'100%',height:'32px'}}
                        className='border-l-4  border-l-slate-600 min-w-[100%] max-w-[100%] border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                        <ErrorComponent error={floorsErr[i]?.floor_type} />
                </div>   

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={true}
                    label={'Floor Name'}  
                    variant="standard"
                    name="floor_name"
                    type="text"
                    value={floors[i]?.floor_name}
                    error={floorsErr[i]?.floor_name}
                  
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={true}
                    label={'Floor No'}  
                    variant="standard"
                    name="floor_number"
                    type="text"
                    value={floors[i]?.floor_number}
                    error={floorsErr[i]?.floor_number}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div> 

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Area Offered In Sqft'}  
                    variant="standard"
                    name="area_offered_in_sqft"
                    type="text"
                    value={floors[i]?.area_offered_in_sqft}
                    error={floorsErr[i]?.area_offered_in_sqft}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div> 

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Floor Sale Price'}  
                    variant="standard"
                    name="floor_sale_price"
                    type="text"
                    value={floors[i]?.floor_sale_price}
                    error={floorsErr[i]?.floor_sale_price}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div> 

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Floor Rent/Sqft Month'}  
                    variant="standard"
                    name="floor_rent_per_sqft_per_month"
                    type="text"
                    value={floors[i]?.floor_rent_per_sqft_per_month}
                    error={floorsErr[i]?.floor_rent_per_sqft_per_month}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div>  

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Maintenance Charges'}  
                    variant="standard"
                    name="maintenace_charges"
                    type="text"
                    value={floors[i]?.maintenace_charges}
                    error={floorsErr[i]?.maintenace_charges}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div> 

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Floor Lock In Period'}  
                    variant="standard"
                    name="floor_lock_in_period"
                    type="text"
                    value={floors[i]?.floor_lock_in_period}
                    error={floorsErr[i]?.floor_lock_in_period}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Floor Lease Term'}  
                    variant="standard"
                    name="floor_lease_term"
                    type="text"
                    value={floors[i]?.floor_lease_term}
                    error={floorsErr[i]?.floor_lease_term}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div>  

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Car Parking Slots'}  
                    variant="standard"
                    name="car_parking_slots"
                    type="text"
                    value={floors[i]?.car_parking_slots}
                    error={floorsErr[i]?.car_parking_slots}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div>  

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Car Parking Charges'}  
                    variant="standard"
                    name="car_parking_charges"
                    type="text"
                    value={floors[i]?.car_parking_charges}
                    error={floorsErr[i]?.car_parking_charges}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div>  
                
                <div className='min-w-[24%] w-[24%] max-w-[24%] mt-2'>
                    <h6 className='text-[12px] font-semibold mb-1'>Facility Type*</h6>
                    <Select 
                        bordered={false}
                        filterOption={false}
                        showSearch={true}
                        allowClear={true} 
                        options={facilityTypeArr}
                        value={floors[i]?.facility_type}
                        optionFilterProp="children"
                        width={'100%'}
                        onChange={(v)=>onChange1(v,'facility_type','floor',i)}
                        onSearch={(v)=>getoptions(v,'facility_type')}
                        style={{fontSize:'12px',width:'100%',height:'32px'}}
                        className='border-l-4  border-l-slate-600 min-w-[100%] max-w-[100%] border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                    <ErrorComponent error={floorsErr[i]?.facility_type} />
                </div>   


                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Furnished Details'}  
                    variant="standard"
                    name="furnished_details"
                    type="text"
                    value={floors[i]?.furnished_details}
                    error={floorsErr[i]?.furnished_details}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div> 

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Possession Type'}  
                    variant="standard"
                    name="possession_type"
                    type="text"
                    value={floors[i]?.possession_type}
                    error={floorsErr[i]?.possession_type}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div> 

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Security Deposit'}  
                    variant="standard"
                    name="security_deposit"
                    type="text"
                    value={floors[i]?.security_deposit}
                    error={floorsErr[i]?.security_deposit}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div> 

                {/* <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Status'}  
                    variant="standard"
                    name="status"
                    type="text"
                    value={floors[i]?.status}
                    error={floorsErr[i]?.status}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div>  */}

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <h6 className='text-[12px] font-semibold mb-1'>Status*</h6>
                    <Select 
                    multiple={true}
                    bordered={false}
                    value={floors[i]?.status}
                    options={statusArr}
                    showSearch={true}
                    allowClear={true} 
                    onChange={(v)=>onChange1(v,'status','floor',i)}
                    onSearch={(v)=>getoptions(v,'status')}
                    style={{fontSize:'12px',height:'32px'}}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                    <ErrorComponent error={floorsErr[i]?.status} />
              
                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Owner Name'}  
                    variant="standard"
                    name="owner_name"
                    type="text"
                    value={floors[i]?.owner_name}
                    error={floorsErr[i]?.owner_name}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div> 

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Owner No'}  
                    variant="standard"
                    name="owner_no"
                    type="text"
                    value={floors[i]?.owner_no}
                    error={floorsErr[i]?.owner_no}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div> 

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Manager Name'}  
                    variant="standard"
                    name="manager_name"
                    type="text"
                    value={floors[i]?.manager_name}
                    error={floorsErr[i]?.manager_name}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                <TextInput 
                    mandatory={false}
                    label={'Manager No'}  
                    variant="standard"
                    name="manager_no"
                    type="text"
                    value={floors[i]?.manager_no}
                    error={floorsErr[i]?.manager_no}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div> 

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                    <h6 className='text-[11px] font-[600] mb-1' >Main Image</h6>
                    {(floors[i]?.main_image === '' || floors[i]?.main_image == null) ?
                        <form onClick={()=>document.querySelector(`.input-field10`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-l-4 border-l-slate-700 border-slate-300 `}>
                            <input type='file' onChange={({target:{files}})=>{
                            files[0] && uploadfilefunc(files[0],'main_image','floor',i)
                            }} accept="*" className='input-field10' hidden />
                        </form> 
                    :
                    <div className='relative'>
                        <h6 onClick={()=>openfilefunc(floors[i]?.main_image)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                        <div className='p-2 border border-slate-300 border-l-4 border-l-slate-700 relative flex flex-col  cursor-pointer'>
                            <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>removeImage('main_image',i,'floor')}/></Tooltip>
                            <h6 className='text-[12px] truncate w-48 ml-0'>{floors[i]?.main_image}</h6>
                        </div>
                    </div>    
                    }

                    <ErrorComponent error={floorsErr[i]?.main_image} />
                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                    <h6 className='text-[11px] font-[600] mb-1' >Multiple Image</h6>
                    {(floors[i]?.multiple_image === '' || floors[i]?.multiple_image == null) ?
                        <form onClick={()=>document.querySelector(`.input-field9`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                            <input type='file' onChange={({target:{files}})=>{
                            files[0] && uploadfilefunc(files[0],'multiple_image','floor',i)
                            }} accept="*" className='input-field9' hidden />
                        </form> 
                    :
                    <div className='relative'>
                        <h6 onClick={()=>openfilefunc(floors[i]?.multiple_image)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                        <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                            <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>removeImage('multiple_image',i,'floor')}/></Tooltip>
                            <h6 className='text-[12px] truncate w-48 ml-0'>{floors[i]?.multiple_image}</h6>
                        </div>
                    </div>    
                    }
                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                    <h6 className='text-[11px] font-[600] mb-1' >Building Plan</h6>
                    {(floors[i]?.building_plan === '' || floors[i]?.building_plan == null) ?
                        <form onClick={()=>document.querySelector(`.input-field8`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                            <input type='file' onChange={({target:{files}})=>{
                            files[0] && uploadfilefunc(files[0],'building_plan','floor',i)
                            }} accept="*" className='input-field8' hidden />
                        </form> 
                    :
                    <div className='relative'>
                        <h6 onClick={()=>openfilefunc(floors[i]?.building_plan)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                        <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                            <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>removeImage('building_plan',i,'floor')}/></Tooltip>
                            <h6 className='text-[12px] truncate w-48 ml-0'>{floors[i]?.building_plan}</h6>
                        </div>
                    </div>    
                    }
                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                    <h6 className='text-[11px] font-[600] mb-1' >Presentation PPT</h6>
                    {(floors[i]?.presentation_ppt === '' || floors[i]?.presentation_ppt == null) ?
                        <form onClick={()=>document.querySelector(`.input-field7`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                            <input type='file' onChange={({target:{files}})=>{
                            files[0] && uploadfilefunc(files[0],'presentation_ppt','floor',i)
                            }} accept="*" className='input-field7' hidden />
                        </form> 
                    :
                    <div className='relative'>
                        <h6 onClick={()=>openfilefunc(floors[i]?.presentation_ppt)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                        <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                            <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>removeImage('presentation_ppt',i,'floor')}/></Tooltip>
                            <h6 className='text-[12px] truncate w-48 ml-0'>{floors[i]?.presentation_ppt}</h6>
                        </div>
                    </div>    
                    }
                </div>

                <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                    <h6 className='text-[11px] font-[600] mb-1' >Term Sheet</h6>
                    {(floors[i]?.term_sheet === '' || floors[i]?.term_sheet == null) ?
                        <form onClick={()=>document.querySelector(`.input-field6`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                            <input type='file' onChange={({target:{files}})=>{
                            files[0] && uploadfilefunc(files[0],'term_sheet','floor',i)
                            }} accept="*" className='input-field6' hidden />
                        </form> 
                    :
                    <div className='relative'>
                        <h6 onClick={()=>openfilefunc(floors[i]?.term_sheet)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                        <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                            <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>removeImage('term_sheet',i,'floor')}/></Tooltip>
                            <h6 className='text-[12px] truncate w-48 ml-0'>{floors[i]?.term_sheet}</h6>
                        </div>
                    </div>    
                    }
                </div>

                <div className='min-w-[100%] w-[100%] max-w-[100%] mb-2'>
                <TextAreaInput1 
                    mandatory={false}
                    label={'Remarks'}  
                    variant="standard"
                    name="remarks"
                    type="text"
                    value={floors[i]?.remarks}
                    error={floorsErr[i]?.remarks}
                    handlechange={(e)=>handlechange1(e,i)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
                </div> 

                </div>    
                 
                <div className='relative mt-4 border-t'>

                    <div className='flex items-center px-2 pt-4 mb-4 justify-between'>
                        <h6 className='font-[700] text-[12px]'>Units Length ({floors[i]?.units !== undefined ? floors[i]?.units?.length : 0})</h6>
                        <ButtonFilledAutoWidth onClick={()=>addUnits(i)}  btnName="Add Unit" />
                    </div>

                    {(floors[i]?.units !== undefined && floors[i]?.units?.length > 0) &&
                    <div className='border relative mb-4 p-4'>
                       
                        {floors[i]?.units?.map((u,i1)=>(
                            <div className='flex border relative p-4 mb-2 relative flex-wrap justify-between'>
                    <MdOutlineDeleteOutline  onClick={()=>removedatafun('unit',i,i1)} className='bg-red-200 p-1 cursor-pointer absolute right-2 top-2 text-[18px]' />

                            <div className='w-[15px] h-[20px] absolute top-0 left-0 bg-yellow-800/60'>
                            </div>    

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <h6 className='text-[12px] font-semibold mb-1'>Unit Type*</h6>
                                <Select 
                                multiple={true}
                                bordered={false}
                                value={floors[i]?.units[i1]?.unit_type}
                                options={propertyTypeArr}
                                showSearch={true}
                                allowClear={true} 
                                onChange={(v)=>onChange2(v,'unit_type','',i,'unit',i1)}
                                onSearch={(v)=>getoptions(v,'property_type')}
                                style={{fontSize:'12px',height:'32px'}}
                                className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                                <ErrorComponent error={floorsErr[i]?.units[i1]?.unit_type} />
                        
                            </div>
                          

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={true}
                                label={'Unit Name'}  
                                variant="standard"
                                name="unit_name"
                                type="text"
                                value={floors[i]?.units[i1]?.unit_name}
                                error={floorsErr[i]?.units[i1]?.unit_name}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={true}
                                label={'Unit No'}  
                                variant="standard"
                                name="unit_number"
                                type="text"
                                value={floors[i]?.units[i1]?.unit_number}
                                error={floorsErr[i]?.units[i1]?.unit_number}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div> 

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Area Offered In Sqft'}  
                                variant="standard"
                                name="area_offered_in_sqft"
                                type="text"
                                value={floors[i]?.units[i1]?.area_offered_in_sqft}
                                error={floorsErr[i]?.units[i1]?.area_offered_in_sqft}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div> 

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Furnish Details*'}  
                                variant="standard"
                                name="furnished_details"
                                type="text"
                                value={floors[i]?.units[i1]?.furnished_details}
                                error={floorsErr[i]?.units[i1]?.furnished_details}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            {/* <h6 className='text-[12px] font-semibold mb-1'></h6>
                                <Select 
                                multiple={true}
                                bordered={false}
                                value={floors[i]?.units[i1]?.furnished_details}
                                options={statusArr}
                                showSearch={true}
                                allowClear={true} 
                                onChange={(v)=>onChange1(v,'furnished_details','floor',i)}
                                onSearch={(v)=>getoptions(v,'furnished_details')}
                                style={{fontSize:'12px',height:'32px'}}
                                className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                                <ErrorComponent error={floorsErr[i]?.units[i1]?.furnished_details} />
                         */}
                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <h6 className='text-[12px] font-semibold mb-1'>Facility Type*</h6>

                                <Select 
                                multiple={true}
                                bordered={false}
                                value={floors[i]?.units[i1]?.facility_type}
                                options={facilityTypeArr}
                                showSearch={true}
                                allowClear={true} 
                                filterOption={false}
                                onChange={(v)=>onChange2(v,'facility_type','',i,'unit',i1)}
                                onSearch={(v)=>getoptions(v,'facility_type')}
                                style={{fontSize:'12px',height:'32px'}}
                                className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                                <ErrorComponent error={floorsErr[i]?.units[i1]?.facility_type} />
                        
                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Car Parking Slots'}  
                                variant="standard"
                                name="car_parking_slots"
                                type="text"
                                value={floors[i]?.units[i1]?.car_parking_slots}
                                error={floorsErr[i]?.units[i1]?.car_parking_slots}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div>  

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Car Parking Charges'}  
                                variant="standard"
                                name="car_parking_charges"
                                type="text"
                                value={floors[i]?.units[i1]?.car_parking_charges}
                                error={floorsErr[i]?.units[i1]?.car_parking_charges}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div>  

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Rent/Sqft Per Month'}  
                                variant="standard"
                                name="rent_per_sqft_per_month"
                                type="text"
                                value={floors[i]?.units[i1]?.rent_per_sqft_per_month}
                                error={floorsErr[i]?.units[i1]?.rent_per_sqft_per_month}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div>  
                            
                            
                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Maintenance Charges'}  
                                variant="standard"
                                name="maintenace_charges"
                                type="text"
                                value={floors[i]?.units[i1]?.maintenace_charges}
                                error={floorsErr[i]?.units[i1]?.maintenace_charges}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div> 

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Sale Price'}  
                                variant="standard"
                                name="sale_price"
                                type="text"
                                value={floors[i]?.units[i1]?.sale_price}
                                error={floorsErr[i]?.units[i1]?.sale_price}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Lock In Period'}  
                                variant="standard"
                                name="lock_in_period"
                                type="text"
                                value={floors[i]?.units[i1]?.lock_in_period}
                                error={floorsErr[i]?.units[i1]?.lock_in_period}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Lease Term and Tenure'}  
                                variant="standard"
                                name="lease_term_and_tenure"
                                type="text"
                                value={floors[i]?.units[i1]?.lease_term_and_tenure}
                                error={floorsErr[i]?.units[i1]?.lease_term_and_tenure}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Security Deposit'}  
                                variant="standard"
                                name="security_deposit"
                                type="text"
                                value={floors[i]?.units[i1]?.security_deposit}
                                error={floorsErr[i]?.units[i1]?.security_deposit}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Escalation'}  
                                variant="standard"
                                name="escalation"
                                type="text"
                                value={floors[i]?.units[i1]?.escalation}
                                error={floorsErr[i]?.units[i1]?.escalation}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Possession Type'}  
                                variant="standard"
                                name="possession_type"
                                type="text"
                                value={floors[i]?.units[i1]?.possession_type}
                                error={floorsErr[i]?.units[i1]?.possession_type}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div> 

                         

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <h6 className='text-[12px] font-semibold mb-1'>Status*</h6>
                            <Select 
                                multiple={true}
                                bordered={false}
                                value={floors[i]?.units[i1]?.status}
                                options={statusArr}
                                showSearch={true}
                                allowClear={true} 
                                filterOption={false}
                                onChange={(v)=>onChange2(v,'status','floor',i,'unit',i1)}
                                onSearch={(v)=>getoptions(v,'status')}
                                style={{fontSize:'12px',height:'32px'}}
                                className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                            
                            <ErrorComponent error={floorsErr[i]?.units[i1]?.status} />
                            
                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Owner Name'}  
                                variant="standard"
                                name="owner_name"
                                type="text"
                                value={floors[i]?.units[i1]?.owner_name}
                                error={floorsErr[i]?.units[i1]?.owner_name}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div> 

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Owner No'}  
                                variant="standard"
                                name="owner_no"
                                type="text"
                                value={floors[i]?.units[i1]?.owner_no}
                                error={floorsErr[i]?.units[i1]?.owner_no}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div> 

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Manager Name'}  
                                variant="standard"
                                name="manager_name"
                                type="text"
                                value={floors[i]?.units[i1]?.manager_name}
                                error={floorsErr[i]?.units[i1]?.manager_name}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                            <TextInput 
                                mandatory={false}
                                label={'Manager No'}  
                                variant="standard"
                                name="manager_no"
                                type="text"
                                value={floors[i]?.units[i1]?.manager_no}
                                error={floorsErr[i]?.units[i1]?.manager_no}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div> 

                          

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                                <h6 className='text-[11px] font-[600] mb-1' >Main Image</h6>
                                {(floors[i]?.units[i1]?.main_image === '' || floors[i]?.units[i1]?.main_image == null) ?
                                    <form onClick={()=>document.querySelector(`.input-field`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-l-4 border-l-slate-700 border-slate-300 `}>
                                        <input type='file' onChange={({target:{files}})=>{
                                        files[0] && uploadfilefunc(files[0],'main_image','floor',i,"unit",i1)
                                        }} accept="*" className='input-field' hidden />
                                    </form> 
                                :
                                <div className='relative'>
                                    <h6 onClick={()=>openfilefunc(floors[i]?.units[i1]?.main_image)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                                    <div className='p-2 border border-slate-300 border-l-4 border-l-slate-700 relative flex flex-col  cursor-pointer'>
                                        <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>removeImage('main_image',i,'floor',i1,'unit')}/></Tooltip>
                                        <h6 className='text-[12px] truncate w-48 ml-0'>{floors[i]?.units[i1]?.main_image}</h6>
                                    </div>
                                </div>    
                                }

                               <ErrorComponent error={floorsErr[i]?.units[i1]?.main_image} />

                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                                <h6 className='text-[11px] font-[600] mb-1' >Multiple Image</h6>
                                {(floors[i]?.units[i1]?.multiple_image === '' || floors[i]?.units[i1]?.multiple_image == null) ?
                                    <form onClick={()=>document.querySelector(`.input-field2`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                                        <input type='file' onChange={({target:{files}})=>{
                                        files[0] && uploadfilefunc(files[0],'multiple_image','floor',i,"unit",i1)
                                        }} accept="*" className='input-field2' hidden />
                                    </form> 
                                :
                                <div className='relative'>
                                    <h6 onClick={()=>openfilefunc(floors[i]?.units[i1]?.multiple_image)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                                    <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                                        <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>removeImage('multiple_image',i,'floor',i1,'unit')}/></Tooltip>
                                        <h6 className='text-[12px] truncate w-48 ml-0'>{floors[i]?.units[i1]?.multiple_image}</h6>
                                    </div>
                                </div>    
                                }
                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                                <h6 className='text-[11px] font-[600] mb-1' >Unit Plan</h6>
                                {(floors[i]?.units[i1]?.unit_plan === '' || floors[i]?.units[i1]?.unit_plan == null) ?
                                    <form onClick={()=>document.querySelector(`.input-field3`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                                        <input type='file' onChange={({target:{files}})=>{
                                        files[0] && uploadfilefunc(files[0],'unit_plan','floor',i,"unit",i1)
                                        }} accept="*" className='input-field3' hidden />
                                    </form> 
                                :
                                <div className='relative'>
                                    <h6 onClick={()=>openfilefunc(floors[i]?.units[i1]?.unit_plan)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                                    <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                                        <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>removeImage('unit_plan',i,'floor',i1,'unit')}/></Tooltip>
                                        <h6 className='text-[12px] truncate w-48 ml-0'>{floors[i]?.units[i1]?.unit_plan}</h6>
                                    </div>
                                </div>    
                                }
                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                                <h6 className='text-[11px] font-[600] mb-1' >Presentation PPT</h6>
                                {(floors[i]?.units[i1]?.presentation_ppt === '' || floors[i]?.units[i1]?.presentation_ppt == null) ?
                                    <form onClick={()=>document.querySelector(`.input-field4`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                                        <input type='file' onChange={({target:{files}})=>{
                                        files[0] && uploadfilefunc(files[0],'presentation_ppt','floor',i,'unit',i1)
                                        }} accept="*" className='input-field4' hidden />
                                    </form> 
                                :
                                <div className='relative'>
                                    <h6 onClick={()=>openfilefunc(floors[i]?.units[i1]?.presentation_ppt)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                                    <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                                        <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>removeImage('presentation_ppt',i,'floor',i1,'unit')}/></Tooltip>
                                        <h6 className='text-[12px] truncate w-48 ml-0'>{floors[i]?.units[i1]?.presentation_ppt}</h6>
                                    </div>
                                </div>    
                                    
                                }
                            </div>

                            <div className='min-w-[24%] w-[24%] max-w-[24%] mb-2'>
                                <h6 className='text-[11px] font-[600] mb-1' >Term Sheet</h6>
                                {(floors[i]?.units[i1]?.term_sheet === '' || floors[i]?.units[i1]?.term_sheet == null) ?
                                    <form onClick={()=>document.querySelector(`.input-field5`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                                        <input type='file' onChange={({target:{files}})=>{
                                        files[0] && uploadfilefunc(files[0],'term_sheet','floor',i,'unit',i1)
                                        }} accept="*" className='input-field5' hidden />
                                    </form> 
                                :
                                <div className='relative'>
                                    <h6 onClick={()=>openfilefunc(floors[i]?.units[i1]?.term_sheet)} className='text-[9px] font-[800] underline cursor-pointer text-blue-500 absolute right-0 -top-4'>View Attachment</h6>
                                    <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                                        <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>removeImage('term_sheet',i,'floor',i1,'unit')}/></Tooltip>
                                        <h6 className='text-[12px] truncate w-48 ml-0'>{floors[i]?.units[i1]?.term_sheet}</h6>
                                    </div>
                                </div>    
                                }

                          
                            </div>

                            <div className='min-w-[100%] w-[100%] max-w-[100%] mb-2'>
                            <TextAreaInput1 
                                mandatory={false}
                                label={'Remarks'}  
                                variant="standard"
                                name="remarks"
                                type="text"
                                value={floors[i]?.units[i1]?.remarks}
                                error={floorsErr[i]?.units[i1]?.remarks}
                                handlechange={(e)=>handlechange2(e,i1,i)}
                                placeholder=""
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}/>
                            </div> 
                            </div>    
                        ))}
                    </div>}
                </div>

             
            </div>    
           ))}


        </div>}

       

        <div className='flex mt-2 mb-10 justify-end border-t pt-2'>
            <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>navigate(-1)} />  
            <h6 className='w-[10px]'></h6>
            <ButtonFilledAutoWidth  btnName="Save" onClick={submitform} />  
        </div>  
        </div>  

        </div> 
    </div >
    </div>

  )
}

export default OfficeSpaceCE