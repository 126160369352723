
import React, { useState, useEffect } from 'react'
import { AiOutlineContacts, AiOutlineFileDone, AiOutlineClose } from 'react-icons/ai';
import { VscInspect } from 'react-icons/vsc';
import { RiBillLine, RiFilePaperLine } from 'react-icons/ri';
import DashboardMenu from './DashboardMenu'
import { GetHomeData } from '../../services/StickyNotesServices';
import { GetTodayLeadReminderService, getInsentiveTargetAchieved, getPaymentCollectionPending, getTargetAchieved } from '../../services/LeadServices';
import ReactApexChart from 'react-apexcharts';
import { MdOutlineLeaderboard, MdOutlineHourglassTop, MdOutlineDesignServices, MdOutlineHighQuality, MdOutlineTireRepair } from 'react-icons/md';
import { BiPaste, BiNote } from 'react-icons/bi'
import { IoConstructOutline } from 'react-icons/io5'
import { LuCalendarClock, LuClock12 } from 'react-icons/lu';
import { BiTask, BiLastPage } from 'react-icons/bi';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LogOutAction } from '../../redux/actions/authAction';
import { IoMdStopwatch } from 'react-icons/io'
import { VscServerProcess } from 'react-icons/vsc'
import { BsDoorClosed } from 'react-icons/bs'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { Modal, Tooltip } from 'antd';
import { TbArrowsSplit2 } from "react-icons/tb";
import { CiViewTable } from "react-icons/ci";
import Priceconstants from '../../constants/imageConstants';
import { PiRocketLaunchLight } from "react-icons/pi";
import { AiOutlineBorderOuter } from "react-icons/ai";

function Dashboard() {


  const navigator = useNavigate()
  const dispatch = useDispatch()

  const [modal, setmodal] = useState(false)
  const user = useSelector(state => state.Auth)

  const user_department = user?.department_id[0]?.department_name
  const [homeData, sethomeData] = useState([])
  const [reminderData, setreminderData] = useState([])
  const [revenue, setrevenue] = useState({})
  const [revenue1, setrevenue1] = useState({})
  const [incentive, setincentive] = useState(false)
  const [incentiveModal, setincentiveModal] = useState(false);

  const [target_achieved, settarget_achieved] = useState({ threshold: 0, target_achieved: '', visible: false })


  let department_list = ['Transaction Team', 'Project Team', 'FMS Team', 'HR Team', 'Fidelitus Gallery', 'Shilpa Foundation','Corp Team','Backend Team']


  const chartOptions = {
    plotOptions: {
      color: ['#000', '#000'],
      size: '40%',
      radialBar: {
        inverseOrder: false,
        startAngle: 0,
        endAngle: 360,
        offsetX: 0,
        offsetY: 0,
        hollow: {
          margin: 5,
          size: '50%',
          color: '#f2f2f2',
          image: undefined,
          imageWidth: 150,
          imageHeight: 150,
          imageOffsetX: 0,
          imageOffsetY: 0,
          imageClipped: true,
          position: 'front',

        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: '#f2f2f2',
          strokeWidth: '50%',
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            opacity: 1
          }
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            fontSize: '12px',
            fontFamily: undefined,
            fontWeight: 400,
            color: undefined,
            color: '#000',
          },
          value: {
            show: true,
            fontSize: '14px',
            fontFamily: undefined,
            fontWeight: 900,
            color: undefined,
            offsetY: 1,
            formatter: function (val) {
              return val + '%'
            }
          },

        },

      },
    },
    labels: ['Target Achieved'],
    fill: {
      colors: ['#008ef2'],
    }
  }

  const [chartOptions1, setchartOptions1] = useState({
    series: [],

    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {

      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },

      },
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#fafafa',
    },

    stroke: {
      // show: true,
      width: 1,

      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Contact', 'Options', 'Inspection', 'Agreement', 'Invoice', 'Hold'],
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        }
      }
    },
    fill: ['#027cd1']
  })

  const chartOptions2 = {
    plotOptions: {
      color: ['#000', '#000'],
      size: '40%',
      radialBar: {
        inverseOrder: false,
        startAngle: 0,
        endAngle: 360,
        offsetX: 0,
        offsetY: 0,
        hollow: {
          margin: 5,
          size: '50%',
          color: '#f2f2f2',
          image: undefined,
          imageWidth: 150,
          imageHeight: 150,
          imageOffsetX: 0,
          imageOffsetY: 0,
          imageClipped: true,
          position: 'front',

        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: '#f2f2f2',
          strokeWidth: '50%',
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            opacity: 1
          }
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            fontSize: '12px',
            fontFamily: undefined,
            fontWeight: 400,
            color: undefined,
            color: '#000',
          },
          value: {
            show: true,
            fontSize: '14px',
            fontFamily: undefined,
            fontWeight: 900,
            color: undefined,
            offsetY: 1,
            formatter: function (val) {
              return val + '%'
            }
          },

        },

      },
    },
    labels: ['Payment Collected'],
    fill: {
      colors: ['#008ef2'],
    }
  }

  let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  useEffect(() => {
    gethomData()
    getRevenueGenerated()
    GetTodayReminder()
    // getInsentiveTargetAchieved()
  }, [])

  // console.log("revenue",revenue)
  async function gethomData() {
    const response = await GetHomeData()


    sethomeData(response?.data?.data)

    if (response?.status == 401) {
      dispatch(LogOutAction())
      navigator('/login')
    }


    let d = response?.data?.data
    let series1
    let category
    if (user_department === 'Transaction Team') {
      series1 = [
        {
          name: 'count',
          data: [
            (d?.counts?.find(f => f?.stage === 'Contact') !== undefined && d?.counts?.find(f => f?.stage === 'Contact') !== null) ? d?.counts?.find(f => f?.stage === 'Contact')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Options Shared') !== undefined && d?.counts?.find(f => f?.stage === 'Options Shared') !== null) ? d?.counts?.find(f => f?.stage === 'Options Shared')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Inspection') !== undefined && d?.counts?.find(f => f?.stage === 'Inspection') !== null) ? d?.counts?.find(f => f?.stage === 'Inspection')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'LOI/Agreement') !== undefined && d?.counts?.find(f => f?.stage === 'LOI/Agreement') !== null) ? d?.counts?.find(f => f?.stage === 'LOI/Agreement')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Invoice Raised') !== undefined && d?.counts?.find(f => f?.stage === 'Invoice Raised') !== null) ? d?.counts?.find(f => f?.stage === 'Invoice Raised')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Hold') !== undefined && d?.counts?.find(f => f?.stage === 'Hold') !== null) ? d?.counts?.find(f => f?.stage === 'Hold')?.count : 0,
          ]
        }
      ]
      category = ['Contact', 'Options', 'Inspection', 'Agreement', 'Invoice', 'Hold']
    } else if (user_department === 'Project Team') {
      series1 = [
        {
          name: 'count',
          data: [
            (d?.counts?.find(f => f?.stage === 'Contact') !== undefined && d?.counts?.find(f => f?.stage === 'Contact') !== null) ? d?.counts?.find(f => f?.stage === 'Contact')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Design') !== undefined && d?.counts?.find(f => f?.stage === 'Design') !== null) ? d?.counts?.find(f => f?.stage === 'Design')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'QS') !== undefined && d?.counts?.find(f => f?.stage === 'QS') !== null) ? d?.counts?.find(f => f?.stage === 'QS')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'MEP') !== undefined && d?.counts?.find(f => f?.stage === 'MEP') !== null) ? d?.counts?.find(f => f?.stage === 'MEP')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'BOQ') !== undefined && d?.counts?.find(f => f?.stage === 'BOQ') !== null) ? d?.counts?.find(f => f?.stage === 'BOQ')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Execution') !== undefined && d?.counts?.find(f => f?.stage === 'Execution') !== null) ? d?.counts?.find(f => f?.stage === 'Execution')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Closed') !== undefined && d?.counts?.find(f => f?.stage === 'Closed') !== null) ? d?.counts?.find(f => f?.stage === 'Closed')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Contact In Future') !== undefined && d?.counts?.find(f => f?.stage === 'Contact In Future') !== null) ? d?.counts?.find(f => f?.stage === 'Contact In Future')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Lost') !== undefined && d?.counts?.find(f => f?.stage === 'Lost') !== null) ? d?.counts?.find(f => f?.stage === 'Lost')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Hold') !== undefined && d?.counts?.find(f => f?.stage === 'Hold') !== null) ? d?.counts?.find(f => f?.stage === 'Hold')?.count : 0,
          ]
        }
      ]
      category = ['Contact', 'Design', 'QS', 'MEP', 'BOQ', 'Execution', 'Closed', 'Contact In Future', 'Lost', 'Hold']

    } else if (user_department === 'HR Team') {
      series1 = [
        {
          name: 'count',
          data: [
            (d?.counts?.find(f => f?.stage === 'Follow Up') !== undefined && d?.counts?.find(f => f?.stage === 'Follow Up') !== null) ? d?.counts?.find(f => f?.stage === 'Follow Up')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Waiting For Requirment') !== undefined && d?.counts?.find(f => f?.stage === 'Waiting For Requirment') !== null) ? d?.counts?.find(f => f?.stage === 'Waiting For Requirment')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'In Progress') !== undefined && d?.counts?.find(f => f?.stage === 'In Progress') !== null) ? d?.counts?.find(f => f?.stage === 'In Progress')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Closed') !== undefined && d?.counts?.find(f => f?.stage === 'Closed') !== null) ? d?.counts?.find(f => f?.stage === 'Closed')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Lost') !== undefined && d?.counts?.find(f => f?.stage === 'Lost') !== null) ? d?.counts?.find(f => f?.stage === 'Lost')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Hold') !== undefined && d?.counts?.find(f => f?.stage === 'Hold') !== null) ? d?.counts?.find(f => f?.stage === 'Hold')?.count : 0
          ]
        }
      ]
      category = ['Follow Up', 'Waiting For Requirment', 'In Progress', 'Closed', 'Lost', 'Hold']

    } else if (user_department === 'FMS Team') {
      series1 = [
        {
          name: 'count',
          data: [
            (d?.counts?.find(f => f?.stage === 'Contact') !== undefined && d?.counts?.find(f => f?.stage === 'Contact') !== null) ? d?.counts?.find(f => f?.stage === 'Contact')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Meeting&Discussion') !== undefined && d?.counts?.find(f => f?.stage === 'Meeting&Discussion') !== null) ? d?.counts?.find(f => f?.stage === 'Meeting&Discussion')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Assesment') !== undefined && d?.counts?.find(f => f?.stage === 'Assesment') !== null) ? d?.counts?.find(f => f?.stage === 'Assesment')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Proposal Sent') !== undefined && d?.counts?.find(f => f?.stage === 'Proposal Sent') !== null) ? d?.counts?.find(f => f?.stage === 'Proposal Sent')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Negotiation') !== undefined && d?.counts?.find(f => f?.stage === 'Negotiation') !== null) ? d?.counts?.find(f => f?.stage === 'Negotiation')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Final Closure') !== undefined && d?.counts?.find(f => f?.stage === 'Final Closure') !== null) ? d?.counts?.find(f => f?.stage === 'Final Closure')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Agreement') !== undefined && d?.counts?.find(f => f?.stage === 'Agreement') !== null) ? d?.counts?.find(f => f?.stage === 'Agreement')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Lost') !== undefined && d?.counts?.find(f => f?.stage === 'Lost') !== null) ? d?.counts?.find(f => f?.stage === 'Lost')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Hold') !== undefined && d?.counts?.find(f => f?.stage === 'Hold') !== null) ? d?.counts?.find(f => f?.stage === 'Hold')?.count : 0
          ]
        }
      ]
      category = ['Contact', 'Meeting/Discussion', 'Assesment', 'Proposal Sent', 'Negotiation', 'Final Closure', 'Agreement', 'Lost', 'Hold']

    }
    else if (user_department === 'Fidelitus Gallery') {
      series1 = [
        {
          name: 'count',
          data: [
            (d?.counts?.find(f => f?.stage === 'Contact') !== undefined && d?.counts?.find(f => f?.stage === 'Contact') !== null) ? d?.counts?.find(f => f?.stage === 'Contact')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Follow Up') !== undefined && d?.counts?.find(f => f?.stage === 'Follow Up') !== null) ? d?.counts?.find(f => f?.stage === 'Follow Up')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Proposal Sent') !== undefined && d?.counts?.find(f => f?.stage === 'Proposal Sent') !== null) ? d?.counts?.find(f => f?.stage === 'Proposal Sent')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Invoice Raised') !== undefined && d?.counts?.find(f => f?.stage === 'Invoice Raised') !== null) ? d?.counts?.find(f => f?.stage === 'Invoice Raised')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Hold') !== undefined && d?.counts?.find(f => f?.stage === 'Hold') !== null) ? d?.counts?.find(f => f?.stage === 'Hold')?.count : 0
          ]
        }
      ]
      category = ['Contact', 'Follow Up', 'Proposal Sent', 'Invoice Raised', 'Hold']

    }else if (user_department === 'Shilpa Foundation') {
      series1 = [
        {
          name: 'count',
          data: [
            (d?.counts?.find(f => f?.stage === 'Contact') !== undefined && d?.counts?.find(f => f?.stage === 'Contact') !== null) ? d?.counts?.find(f => f?.stage === 'Contact')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Follow Up') !== undefined && d?.counts?.find(f => f?.stage === 'Follow Up') !== null) ? d?.counts?.find(f => f?.stage === 'Follow Up')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Proposal Sent') !== undefined && d?.counts?.find(f => f?.stage === 'Proposal Sent') !== null) ? d?.counts?.find(f => f?.stage === 'Proposal Sent')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Purchase Order') !== undefined && d?.counts?.find(f => f?.stage === 'Purchase Order') !== null) ? d?.counts?.find(f => f?.stage === 'Purchase Order')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Invoice Raised') !== undefined && d?.counts?.find(f => f?.stage === 'Invoice Raised') !== null) ? d?.counts?.find(f => f?.stage === 'Invoice Raised')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Hold') !== undefined && d?.counts?.find(f => f?.stage === 'Hold') !== null) ? d?.counts?.find(f => f?.stage === 'Hold')?.count : 0
          ]
        }
      ]
      category = ['Contact', 'Follow Up', 'Proposal Sent','Purchase Order', 'Invoice Raised', 'Hold']

    }   else if (user_department === 'Corp Team' || user_department === 'Backend Team' || user_department === 'Digital Media') {
      series1 = [
        {
          name: 'count',
          data: [
            (d?.counts?.find(f => f?.stage === 'Contact') !== undefined && d?.counts?.find(f => f?.stage === 'Contact') !== null) ? d?.counts?.find(f => f?.stage === 'Contact')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Followup') !== undefined && d?.counts?.find(f => f?.stage === 'Follow Up') !== null) ? d?.counts?.find(f => f?.stage === 'Followup')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Converted') !== undefined && d?.counts?.find(f => f?.stage === 'Converted') !== null) ? d?.counts?.find(f => f?.stage === 'Converted')?.count : 0,
            (d?.counts?.find(f => f?.stage === 'Hold') !== undefined && d?.counts?.find(f => f?.stage === 'Hold') !== null) ? d?.counts?.find(f => f?.stage === 'Hold')?.count : 0
          ]
        }
      ]
      category = ['Contact', 'Followup', 'Converted', 'Hold']

    }


    setchartOptions1({
      ...chartOptions1
      , series: series1
      , xaxis: {
        categories: category,
      },

    })

    if (user_department === 'Transaction Team') {
      const response1 = await getInsentiveTargetAchieved()
      setincentive(response1?.data?.data)
    }
  }

  async function getRevenueGenerated() {
    const response = await getTargetAchieved()
    const response1 = await getPaymentCollectionPending()
    setrevenue(response?.data?.data[0])

    let d = response?.data?.data[0]

    if (d?.incentive?.show_incentive_to_user) {
      setincentiveModal(true)
    } else {
      let threshold = 0
      let target_achieved = 0

      // d?.employeee_threshold_yearly_target?.slice(0,d?.current_period)?.reduce((a,b)=>a+b)
      // d?.target_achieved


      for (let i = 0; i < d?.current_period; i++) {
        // console.log("d?.employeee_threshold_yearly_target_achieved[i]",d?.employeee_threshold_yearly_target_achieved[i])
        // console.log("d?.employeee_threshold_yearly_target[i]",d?.employeee_threshold_yearly_target[i])
        if(i < (d?.current_period - 1)){
          if (d?.employeee_threshold_yearly_target_achieved[i] <  d?.employeee_threshold_yearly_target[i]) {
            threshold += (d?.employeee_threshold_yearly_target[i] - d?.employeee_threshold_yearly_target_achieved[i])
            // threshold += d?.employeee_threshold_yearly_target_achieved[i]
          }
        }else{
          threshold += d?.employeee_threshold_yearly_target[i]
        }
        // console.log("i is == ", i, "Threshold is", threshold)
          // target_achieved += d?.employeee_threshold_yearly_target[i]
      }



      // console.log("target_achieved",target_achieved)
      settarget_achieved({ threshold:!isNaN(threshold) ? threshold : 0, target_achieved: !isNaN(d?.employeee_threshold_yearly_target_achieved[d?.current_period - 1]) ? d?.employeee_threshold_yearly_target_achieved[d?.current_period - 1] : 0, visible: true })
    }
    setrevenue1(response1?.data?.data[0])

    // console.log("revenue1",response1?.data?.data[0])
  }

  async function GetTodayReminder() {
    const response = await GetTodayLeadReminderService()
    console.log("reminderData", reminderData)
    setreminderData(response?.data?.data)
  }

  function calculatedataType(d, type) {

    let total = 0
    if (type == 'invoice_amount') {
      d?.invoice_data[0]?.data?.forEach((d) => {
        total += d?.amount
      })
    }

    if (type == 'invoice_amount_with_gst') {
      d?.invoice_data[0]?.data?.forEach((d) => {
        total += !['', null, undefined, 'null', 'undefined']?.includes(d?.invoice_amount_with_gst) ? d?.invoice_amount_with_gst : 0
      })
    }

    if (type == 'invoice_amount_without_gst') {
      d?.invoice_data[0]?.data?.forEach((d) => {
        total += !['', null, undefined, 'null', 'undefined']?.includes(d?.invoice_amount_without_gst) ? d?.invoice_amount_without_gst : 0
      })
    }

    if (type == 'amount_with_gst') {
      d?.invoice_payment_data[0]?.data?.forEach((d) => {
        total += d?.amount_with_gst
      })
    }

    if (type == 'amount_without_gst') {
      d?.invoice_payment_data[0]?.data?.forEach((d) => {
        total += d?.amount_without_gst
      })
    }

    if (type == 'amount_with_gst_incl_tds_value') {
      d?.invoice_payment_data[0]?.data?.forEach((d) => {
        total += d?.amount_with_gst_incl_tds_value !== undefined ? d?.amount_with_gst_incl_tds_value : 0
      })
    }

    return total

  }



  // console.log("target_achieved", target_achieved)
  // console.log("user roles", user?.roles)
  // console.log("revenue here",revenue)

  return (
    <div className='h-screen relative min-h-screen max-h-screen overflow-hidden'>

      <Modal open={modal} closable={false} footer={false} className='absolute top-0 left-[32%]'>
        <div className='relative'>
          <AiOutlineClose className='absolute right-0 cursor-pointer' onClick={() => setmodal(!modal)} />

          <h6 className='font-[800] text-[13px]'>Payment Collection vs Pending</h6>

          <div className='border p-2 mt-2'>
            <h6 className='text-[13px] font-[800]'>Invoice Info</h6>
            <div className='border mt-2'>
              <div className='flex'>
                <h6 className='text-[12px] p-1 w-[10%] border-r'>Sl No</h6>
                <h6 className='text-[12px] p-1 w-[30%] border-r'>Amount</h6>
                <h6 className='text-[12px] p-1 w-[30%] border-r'>Incl Gst</h6>
                <h6 className='text-[12px] p-1 w-[30%] '>Excl Gst</h6>
              </div>
              {revenue1?.invoice_data !== undefined && revenue1?.invoice_data[0]?.data?.map((d, i) => (
                <div className='flex border-t'>
                  <h6 className='text-[11px] font-[600] p-1 w-[10%] border-r'>{i + 1}</h6>
                  <h6 className='text-[11px] font-[600] p-1 w-[30%] border-r'>{Priceconstants(d?.amount)}</h6>
                  <h6 className='text-[11px] font-[600] p-1 w-[30%] border-r'>{Priceconstants(d?.invoice_amount_with_gst)}</h6>
                  <h6 className='text-[11px] font-[600] p-1 w-[30%] '>{Priceconstants(d?.invoice_amount_without_gst)}</h6>
                </div>))}
              {revenue1?.invoice_data !== undefined &&
                <div className='flex border-t'>
                  <h6 className='text-[12px] font-[800] p-1 w-[10%] border-r'></h6>
                  <h6 className='text-[12px] font-[800] p-1 w-[30%] border-r'>{Priceconstants(calculatedataType(revenue1, 'invoice_amount'))}</h6>
                  <h6 className='text-[12px] font-[800] p-1 w-[30%] border-r'>{Priceconstants(calculatedataType(revenue1, 'invoice_amount_with_gst'))}</h6>
                  <h6 className='text-[12px] font-[800] p-1 w-[30%] '>{Priceconstants(calculatedataType(revenue1, 'invoice_amount_without_gst'))}</h6>
                </div>}
            </div>
          </div>

          <div className='border p-2 mt-2'>
            <h6 className='text-[13px] font-[800]'>Payment Info Approved</h6>
            <div className='border mt-2'>
              <div className='flex'>
                <h6 className='text-[12px] p-1 w-[10%] border-r'>Sl No</h6>
                <h6 className='text-[12px] p-1 w-[30%] border-r'>Amount With Gst</h6>
                <h6 className='text-[12px] p-1 w-[30%] border-r'>Amount Excl Gst</h6>
                <h6 className='text-[12px] p-1 w-[30%] '>TDS Incl Amount</h6>
              </div>
              {revenue1?.invoice_recieved_data !== undefined && revenue1?.invoice_recieved_data[0]?.data?.map((d, i) => (
                <div className='flex border-t'>
                  <h6 className='text-[11px] font-[600] p-1 w-[10%] border-r'>{i + 1}</h6>
                  <h6 className='text-[11px] font-[600] p-1 w-[30%] border-r'>{Priceconstants(d?.amount_with_gst)}</h6>
                  <h6 className='text-[11px] font-[600] p-1 w-[30%] border-r'>{Priceconstants(d?.amount_without_gst)}</h6>
                  <h6 className='text-[11px] font-[600] p-1 w-[30%] '>{Priceconstants(d?.amount_with_gst_incl_tds_value)}</h6>
                </div>))}
              {revenue1?.invoice_recieved_data !== undefined &&
                <div className='flex border-t'>
                  <h6 className='text-[12px] font-[800] p-1 w-[10%] border-r'></h6>
                  <h6 className='text-[12px] font-[800] p-1 w-[30%] border-r'>{Priceconstants(calculatedataType(revenue1, 'amount_with_gst'))}</h6>
                  <h6 className='text-[12px] font-[800] p-1 w-[30%] border-r'>{Priceconstants(calculatedataType(revenue1, 'amount_without_gst'))}</h6>
                  <h6 className='text-[12px] font-[800] p-1 w-[30%] '>{Priceconstants(calculatedataType(revenue1, 'amount_with_gst_incl_tds_value'))}</h6>
                </div>}
            </div>
          </div>

        </div>
      </Modal>



      <div className='flex'>
        <DashboardMenu />
        <div className='w-[86%] flex h-screen min-h-screen max-h-screen overflow-y-scroll'>
          <div className='w-[100%] px-4 py-4'>
            {incentiveModal &&
              <>
                {((!user?.roles?.includes('hod') && (!revenue?.eligible_for_incentive) && (target_achieved?.threshold > 0 || target_achieved?.target_achieved > 0))) ?
                  <>
                    <div className={`bg-red-100 relative p-4 mb-4`}>
                      <h6 className='font-[800] text-[14px] '>You haven't reached the threshold for incentives</h6>
                      <h6 className='font-[800] text-[14px] '>Better Luck Next Time!!!</h6>
                      <h6 className='font-[400] leading-[16px] text-[12px] -mt-0'>Try to bring new business and close leads to break the threshold Quarter {revenue?.current_period} and get incentive for upcoming Quarter </h6>
                      <span className='animate-bounce text-[80px] absolute right-2 top-5'>😭</span>

                      <div className='w-max bg-red-200 border mt-1 cursor-pointer z-50 border-red-400 rounded-[5px] px-1 '>
                        <button onClick={() => { setincentiveModal(false); navigator('/dashboard/incentive_dashboard') }}>
                          <h6 className='text-[11px] flex items-center text-red-800 font-[800] px-2 py-1'>Incentive Released  <AiOutlineInfoCircle size={14} className='ml-2' /></h6>
                        </button>
                      </div>
                    </div>
                  </>
                  :

                  <>
                    <div className={`bg-violet-100 relative p-4 mb-2`}>
                      <h6 className='font-[800] text-[14px] '>You have recieved an incentive</h6>
                      <h6 className='font-[800] text-[14px] '>Congrats!!!</h6>
                      <h6 className='font-[400] leading-[16px] text-[12px] -mt-0'>We are proud of your work please check the below incentive recieved based on your work done for the Quarter {revenue?.current_period}</h6>
                      <span className='animate-bounce text-[80px] absolute right-2 top-5'>🥳</span>

                      <div className='w-max bg-violet-200 border mt-1 cursor-pointer z-50 border-violet-400 rounded-[5px] px-1 '>
                        <button onClick={() => { setincentiveModal(false); navigator('/dashboard/incentive_dashboard') }}>
                          <h6 className='text-[11px] flex items-center text-violet-800 font-[800] px-2 py-1'>Incentive Released  <AiOutlineInfoCircle size={14} className='ml-2' /></h6>
                        </button>
                      </div>
                    </div>
                  </>}
              </>}

            {(['bd_user', 'manager', 'hod'].filter((f) => user?.roles?.includes(f)).length > 0 && user?.department_id[0].department_name == 'Transaction Team') &&
              <>
                {((!user?.roles?.includes('hod') && (target_achieved?.visible))) &&

                  <>
                    <div className={`bg-blue-100 relative p-4 mb-2`}>
                      <h6 className='font-[800] text-[14px] '>{target_achieved?.target_achieved > target_achieved?.threshold ? 'Congrats your are Now eligble for an incentive' : 'Your short away from reaching the target to get an incentive amount achieve the target more than shown threshold'}</h6>
                      {/* <h6 className='font-[800] text-[14px] '>Congrats!!!</h6> */}

                      <div className='flex items-center'>
                        <h6 className='text-[13px] mr-1'>Threshold : <span className='font-[800]'>{rupeeIndian?.format(target_achieved?.threshold)?.split('.')[0]}</span></h6>  /
                        <h6 className='text-[13px] ml-1'>Achieved : <span className='font-[800]'>{rupeeIndian?.format(target_achieved?.target_achieved)?.split('.')[0]}</span></h6>
                      </div>

                      <h6 className='bg-blue-200 p-2 my-2 text-[11px] font-[600]'>Note: The Above calcualtion is made based on the payment added and been approved by your superiors or reporting head for any clarrification contact them.</h6>
                      <h6 className='font-[400] leading-[16px] text-[12px] -mt-0'>{(target_achieved?.target_achieved > target_achieved?.threshold) && `We are proud of your work please check the below incentive recieved based on your work done for the Quarter ${revenue?.current_period}`}</h6>
                      {/* <span className='animate-bounce text-[80px] absolute right-2 top-5'>🥳</span> */}

                      {/* <div className='w-max bg-violet-200 border mt-1 cursor-pointer z-50 border-violet-400 rounded-[5px] px-1 '>
                <button onClick={() =>{setincentiveModal(false);navigator('/dashboard/incentive_dashboard')}}>
                 <h6 className='text-[11px] flex items-center text-violet-800 font-[800] px-2 py-1'>Incentive Released  <AiOutlineInfoCircle size={14} className='ml-2'  /></h6>  
               </button>
             </div> */}
                    </div>
                  </>}
              </>}




            {user_department === 'Transaction Team' &&
              <div className='grid border-l border-r grid-cols-6 items-center justify-evenly border-t border-b border-slate-100'>
                <div className='border-r  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/contacts')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> My Contacts</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify((homeData?.counts?.find(f => f?.stage === 'Contact') !== undefined && homeData?.counts?.find(f => f?.stage === 'Contact') !== null) ? homeData?.counts?.find(f => f?.stage === 'Contact')?.count : 0))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/options_shared')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />My Options Shared</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify((homeData?.counts?.find(f => f?.stage === 'Options Shared') !== undefined && homeData?.counts?.find(f => f?.stage === 'Options Shared') !== null) ? homeData?.counts?.find(f => f?.stage === 'Options Shared')?.count : 0))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/inspections')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><VscInspect size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />My Inspection</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(((homeData?.counts?.find(f => f?.stage === 'Inspection') !== undefined && homeData?.counts?.find(f => f?.stage === 'Inspection') !== null) ? homeData?.counts?.find(f => f?.stage === 'Inspection')?.count : 0) + ((homeData?.counts?.find(f => f?.stage === 'Landlord Meeting') !== undefined && homeData?.counts?.find(f => f?.stage === 'Landlord Meeting') !== null) ? homeData?.counts?.find(f => f?.stage === 'Landlord Meeting')?.count : 0))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/loi_agreement')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Total LOI /Agreement</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'LOI/Agreement') !== undefined && homeData?.counts?.find(f => f?.stage === 'LOI/Agreement') !== null) ? homeData?.counts?.find(f => f?.stage === 'LOI/Agreement')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/invoice_raised')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiFilePaperLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Total Invoice Raised </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Invoice Raised') !== undefined && homeData?.counts?.find(f => f?.stage === 'Invoice Raised') !== null) ? homeData?.counts?.find(f => f?.stage === 'Invoice Raised')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 py-2 w-full' onClick={() => navigator('/leads/hold')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiFilePaperLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />My Hold Clients </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Hold') !== undefined && homeData?.counts?.find(f => f?.stage === 'Hold') !== null) ? homeData?.counts?.find(f => f?.stage === 'Hold')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>


              </div>}

            {user_department === 'Project Team' &&
              <div className='grid border-l border-r grid-cols-6 items-center justify-evenly border-t border-b border-slate-100'>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/contacts')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> My Contacts</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify((homeData?.counts?.find(f => f?.stage === 'Contact') !== undefined && homeData?.counts?.find(f => f?.stage === 'Contact') !== null) ? homeData?.counts?.find(f => f?.stage === 'Contact')?.count : 0))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r border-b border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/design_shared')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineDesignServices size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Design</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify((homeData?.counts?.find(f => f?.stage === 'Design') !== undefined && homeData?.counts?.find(f => f?.stage === 'Design') !== null) ? homeData?.counts?.find(f => f?.stage === 'Design')?.count : 0))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/qs_stage')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineHighQuality size={23} className='mr-2 bg-slate200 p-1 text-slate-500' />QS</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(((homeData?.counts?.find(f => f?.stage === 'QS') !== undefined && homeData?.counts?.find(f => f?.stage === 'QS') !== null) ? homeData?.counts?.find(f => f?.stage === 'QS')?.count : 0))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/mep_stage')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineTireRepair size={23} className='mr-2 bg-slate200 p-1 text-slate-500' /> MEP</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'MEP') !== undefined && homeData?.counts?.find(f => f?.stage === 'MEP') !== null) ? homeData?.counts?.find(f => f?.stage === 'MEP')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/boq_stage')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> BOQ </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'BOQ') !== undefined && homeData?.counts?.find(f => f?.stage === 'BOQ') !== null) ? homeData?.counts?.find(f => f?.stage === 'BOQ')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2  border-b border-slate-100 py-2 w-full' onClick={() => navigator('/leads/execution_stage')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><IoConstructOutline size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Execution </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Execution') !== undefined && homeData?.counts?.find(f => f?.stage === 'Execution') !== null) ? homeData?.counts?.find(f => f?.stage === 'Execution')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/closed_stage')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineFileDone size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Closed </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Closed') !== undefined && homeData?.counts?.find(f => f?.stage === 'Closed') !== null) ? homeData?.counts?.find(f => f?.stage === 'Closed')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/contact_in_future')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><BiPaste size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Contact In Future </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Contact In Future') !== undefined && homeData?.counts?.find(f => f?.stage === 'Contact In Future') !== null) ? homeData?.counts?.find(f => f?.stage === 'Contact In Future')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/lost_client')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><BiNote size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Lost CLient </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Lost') !== undefined && homeData?.counts?.find(f => f?.stage === 'Lost') !== null) ? homeData?.counts?.find(f => f?.stage === 'Lost')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/hold_client')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineHourglassTop size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Hold Client </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Hold') !== undefined && homeData?.counts?.find(f => f?.stage === 'Hold') !== null) ? homeData?.counts?.find(f => f?.stage === 'Hold')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>


              </div>}

            {user_department === 'HR Team' &&
              <div className='grid border-l border-r grid-cols-6 items-center justify-evenly border-t border-b border-slate-100'>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/follow_up')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> My Contacts</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify((homeData?.counts?.find(f => f?.stage === 'Contact') !== undefined && homeData?.counts?.find(f => f?.stage === 'Contact') !== null) ? homeData?.counts?.find(f => f?.stage === 'Contact')?.count : 0))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2  border-b border-slate-100 py-2 w-full' onClick={() => navigator('/leads/waiting_requirment')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><IoMdStopwatch size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Waiting Requirment </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Waiting For Requirment') !== undefined && homeData?.counts?.find(f => f?.stage === 'Waiting For Requirment') !== null) ? homeData?.counts?.find(f => f?.stage === 'Waiting For Requirment')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2  border-b border-slate-100 py-2 w-full' onClick={() => navigator('/leads/in_progress')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><VscServerProcess size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />In Progress </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'In Progress') !== undefined && homeData?.counts?.find(f => f?.stage === 'In Progress') !== null) ? homeData?.counts?.find(f => f?.stage === 'In Progress')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/closed')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineFileDone size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Closed </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Closed') !== undefined && homeData?.counts?.find(f => f?.stage === 'Closed') !== null) ? homeData?.counts?.find(f => f?.stage === 'Closed')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/lost')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><BiNote size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Lost CLient </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Lost') !== undefined && homeData?.counts?.find(f => f?.stage === 'Lost') !== null) ? homeData?.counts?.find(f => f?.stage === 'Lost')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/hold')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineHourglassTop size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Hold Client </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Hold') !== undefined && homeData?.counts?.find(f => f?.stage === 'Hold') !== null) ? homeData?.counts?.find(f => f?.stage === 'Hold')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>
              </div>}

            {user_department === 'FMS Team' &&
              <div className='grid border-l border-r grid-cols-6 items-center justify-evenly border-t border-b border-slate-100'>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/contact')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> My Contacts</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify((homeData?.counts?.find(f => f?.stage === 'Contact') !== undefined && homeData?.counts?.find(f => f?.stage === 'Contact') !== null) ? homeData?.counts?.find(f => f?.stage === 'Contact')?.count : 0))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2  border-b border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/meeting_discussion')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><BsDoorClosed size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Meeeting/Discussion</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Meeting&Discussion') !== undefined && homeData?.counts?.find(f => f?.stage === 'Meeting&Discussion') !== null) ? homeData?.counts?.find(f => f?.stage === 'Meeting&Discussion')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2  border-b border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/assesement')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineDesignServices size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Assesment </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Assesment') !== undefined && homeData?.counts?.find(f => f?.stage === 'Assesment') !== null) ? homeData?.counts?.find(f => f?.stage === 'Assesment')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-b  border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/proposal_sent')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><PiRocketLaunchLight size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Proposal Sent </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Proposal Sent') !== undefined && homeData?.counts?.find(f => f?.stage === 'Proposal Sent') !== null) ? homeData?.counts?.find(f => f?.stage === 'Proposal Sent')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-b  border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/negotiation')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><BiNote size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Negotiation </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Negotiation') !== undefined && homeData?.counts?.find(f => f?.stage === 'Negotiation') !== null) ? homeData?.counts?.find(f => f?.stage === 'Negotiation')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-b  border-slate-100 py-2 w-full' onClick={() => navigator('/leads/final_closure')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><BiLastPage size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Final Closure </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Final Closure') !== undefined && homeData?.counts?.find(f => f?.stage === 'Final Closure') !== null) ? homeData?.counts?.find(f => f?.stage === 'Final Closure')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/agreement')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Agreement </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Agreement') !== undefined && homeData?.counts?.find(f => f?.stage === 'Agreement') !== null) ? homeData?.counts?.find(f => f?.stage === 'Agreement')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/lost')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><BiNote size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Lost Client </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Lost') !== undefined && homeData?.counts?.find(f => f?.stage === 'Lost') !== null) ? homeData?.counts?.find(f => f?.stage === 'Lost')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/hold')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineHourglassTop size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Hold Client </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Hold') !== undefined && homeData?.counts?.find(f => f?.stage === 'Hold') !== null) ? homeData?.counts?.find(f => f?.stage === 'Hold')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>
              </div>}

              {(user_department === 'Fidelitus Gallery') &&
              <div className='grid border-l border-r grid-cols-5 items-center justify-evenly border-t border-b border-slate-100'>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/contacts')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> My Contacts</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify((homeData?.counts?.find(f => f?.stage === 'Contact') !== undefined && homeData?.counts?.find(f => f?.stage === 'Contact') !== null) ? homeData?.counts?.find(f => f?.stage === 'Contact')?.count : 0))?.split('.')[0].slice(1)}</h6>
                </div>


                <div className='px-2  border-b border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/follow_up')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineDesignServices size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Follow Up </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Followup') !== undefined && homeData?.counts?.find(f => f?.stage === 'Followup') !== null) ? homeData?.counts?.find(f => f?.stage === 'Followup')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2  border-b border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/proposal_sent')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><PiRocketLaunchLight size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Proposal Sent </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Proposal Sent') !== undefined && homeData?.counts?.find(f => f?.stage === 'Proposal Sent') !== null) ? homeData?.counts?.find(f => f?.stage === 'Proposal Sent')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>


                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/invoice_raised')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Invoice Raised </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Invoice Raised') !== undefined && homeData?.counts?.find(f => f?.stage === 'Invoice Raised') !== null) ? homeData?.counts?.find(f => f?.stage === 'Invoice Raised')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/hold')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineHourglassTop size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Hold Client </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Hold') !== undefined && homeData?.counts?.find(f => f?.stage === 'Hold') !== null) ? homeData?.counts?.find(f => f?.stage === 'Hold')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>
              </div>}

              {(user_department === 'Shilpa Foundation') &&
              <div className='grid border-l border-r grid-cols-5 items-center justify-evenly border-t border-b border-slate-100'>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/contacts')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> My Contacts</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify((homeData?.counts?.find(f => f?.stage === 'Contact') !== undefined && homeData?.counts?.find(f => f?.stage === 'Contact') !== null) ? homeData?.counts?.find(f => f?.stage === 'Contact')?.count : 0))?.split('.')[0].slice(1)}</h6>
                </div>


                <div className='px-2  border-b border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/follow_up')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineDesignServices size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Follow Up </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Followup') !== undefined && homeData?.counts?.find(f => f?.stage === 'Followup') !== null) ? homeData?.counts?.find(f => f?.stage === 'Followup')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2  border-b border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/proposal_sent')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><PiRocketLaunchLight size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Proposal Sent </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Proposal Sent') !== undefined && homeData?.counts?.find(f => f?.stage === 'Proposal Sent') !== null) ? homeData?.counts?.find(f => f?.stage === 'Proposal Sent')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>


                <div className='px-2 border-r border-b border-slate-100 py-2 w-full' onClick={() => navigator('/leads/purchase_order')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineBorderOuter size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Purchase Order </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Purchase Order') !== undefined && homeData?.counts?.find(f => f?.stage === 'Purchase Order') !== null) ? homeData?.counts?.find(f => f?.stage === 'Purchase Order')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-b border-slate-100 py-2 w-full' onClick={() => navigator('/leads/invoice_raised')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Invoice Raised </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Invoice Raised') !== undefined && homeData?.counts?.find(f => f?.stage === 'Invoice Raised') !== null) ? homeData?.counts?.find(f => f?.stage === 'Invoice Raised')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/hold')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineHourglassTop size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Hold Client </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Hold') !== undefined && homeData?.counts?.find(f => f?.stage === 'Hold') !== null) ? homeData?.counts?.find(f => f?.stage === 'Hold')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>
              </div>}

              {(user_department === 'Corp Team' || user_department === 'Backend Team') &&
              <div className='grid border-l border-r grid-cols-4 items-center justify-evenly border-t border-b border-slate-100'>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/contacts')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> My Contacts</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify((homeData?.counts?.find(f => f?.stage === 'Contact') !== undefined && homeData?.counts?.find(f => f?.stage === 'Contact') !== null) ? homeData?.counts?.find(f => f?.stage === 'Contact')?.count : 0))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2  border-b border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/follow_up')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineDesignServices size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Follow Up </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Followup') !== undefined && homeData?.counts?.find(f => f?.stage === 'Followup') !== null) ? homeData?.counts?.find(f => f?.stage === 'Followup')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/converted')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Converted </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Converted') !== undefined && homeData?.counts?.find(f => f?.stage === 'Converted') !== null) ? homeData?.counts?.find(f => f?.stage === 'Converted')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/hold')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineHourglassTop size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Hold Client </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Hold') !== undefined && homeData?.counts?.find(f => f?.stage === 'Hold') !== null) ? homeData?.counts?.find(f => f?.stage === 'Hold')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>
              </div>}

              {(user_department === 'Digital Media') &&
              <div className='grid border-l border-r grid-cols-4 items-center justify-evenly border-t border-b border-slate-100'>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={() => navigator('/leads/contacts')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> My Contacts</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify((homeData?.counts?.find(f => f?.stage === 'Contact') !== undefined && homeData?.counts?.find(f => f?.stage === 'Contact') !== null) ? homeData?.counts?.find(f => f?.stage === 'Contact')?.count : 0))?.split('.')[0].slice(1)}</h6>
                </div>


                <div className='px-2  border-b border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/followup')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineDesignServices size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Follow Up </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Followup') !== undefined && homeData?.counts?.find(f => f?.stage === 'Followup') !== null) ? homeData?.counts?.find(f => f?.stage === 'Followup')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>


                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/agreement')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Converted </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Converted') !== undefined && homeData?.counts?.find(f => f?.stage === 'Converted') !== null) ? homeData?.counts?.find(f => f?.stage === 'Converted')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={() => navigator('/leads/hold')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineHourglassTop size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Hold Client </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((homeData?.counts?.find(f => f?.stage === 'Hold') !== undefined && homeData?.counts?.find(f => f?.stage === 'Hold') !== null) ? homeData?.counts?.find(f => f?.stage === 'Hold')?.count : 0)?.split('.')[0].slice(1)}</h6>
                </div>
              </div>}


            {(user_department === 'Transaction Team' || department_list?.includes(user_department)) &&
              <div className='flex h-[280px]'>
                {user_department === 'Transaction Team' &&
                  <div className='border-b border-t border-l mr-2 border-r pr-2  w-[40%] flex-0.4  mt-2 border-slate-100 px-3 py-2 relative'>
                    <Tooltip placement='bottom' color="#f3f3f3" style={{ background: '#f3f3f3', color: 'black', fontSize: '10px' }} overlayInnerStyle={{ color: 'black', padding: '4px', fontSize: '10px', lineHeight: '15px', width: '250px' }} title="Yet to achieve is calculated based on the total yearly target divided by the current period and percentage describe the amount of percentage you have achieved based on your target">
                      <span className='absolute right-2'> <AiOutlineInfoCircle size={14} /></span>
                    </Tooltip>



                    <h6 className='text-[13px] font-[700]'>Revenue Forecast</h6>

                    {/* <h6>{console.log("revenue printing",revenue)}</h6> */}
                    {/* {((revenue?.employeee_threshold_yearly_target !== undefined && revenue?.employeee_threshold_yearly_target !== null) && revenue?.employeee_threshold_yearly_target[revenue?.current_period - 1] <= revenue?.invoice_amount) &&  */}
                    {revenue?.incentive?.show_incentive_to_user && (revenue?.eligible_for_incentive || revenue?.team_member_incentive_amount > 0) &&
                      <div className='absolute right-2 top-8'>
                        <div onClick={() => navigator('/dashboard/incentive_dashboard')} className='bg-sky-100 p-1 w-max h-[38px] cursor-pointer'>
                          <h6 className='text-[10px]'>Congrats you are eligible for incentive check your</h6>
                          <h6 className='font-[800] text-[10px] ml-1'>
                            {revenue?.current_period}
                            {['st', 'nd', 'rd', 'th'][revenue?.current_period - 1]} Quarter Incentive
                          </h6>
                        </div>
                      </div>}
                    <div className='flex '>
                      <div className='-ml-14 mt-5'>
                        <ReactApexChart options={chartOptions} size={'10'} series={
                          [
                            (revenue?.employee_indivisual_target_current_period === undefined || revenue?.employee_indivisual_target_current_period === 0) ? 0 : Math.floor((revenue?.target_achieved / revenue?.employee_indivisual_target_assigned_by_head) * 100)

                          ]} type="radialBar" width={240} height={240} />
                      </div>
                      <div className='w-[100%] -ml-[40px] -ml-2'>

                        <div className='mt-16 w-full  relative'>
                          <h6 className='text-[11px] font-[600]'>Yearly Target </h6>
                          <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-2 dark:bg-gray-700">
                            <div className={`bg-gray-700  rounded-full h-2.5 flex items-center justify-center dark:bg-gray-300 font-[900  text-[8px] text-white text-center leading-none w-[100%]`} style={{ width: revenue?.employee_indivisual_target_assigned_by_head > 0 ? '100%' : '0%' }}  >
                              {revenue?.employee_indivisual_target_assigned_by_head > 0 ? '100%' : '0%'}
                            </div>
                          </div>
                          <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(revenue?.employee_indivisual_target_assigned_by_head !== undefined ? revenue?.employee_indivisual_target_assigned_by_head : 0))?.split('.')[0]}</h6>

                        </div>

                        <div className='ml-0 mt-4 w-full relative'>
                          <h6 className='text-[11px] font-[600]'>Yet To Achieve </h6>
                          <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                            {/* {console.log("(100 - (revenue?.target_achieved/revenue?.employee_indivisual_target_assigned_by_head)*100)",(100 - (revenue?.target_achieved/revenue?.employee_indivisual_target_assigned_by_head)*100))}
                    {console.log("revenue?.target_achieved",revenue?.target_achieved)}
                    {console.log("revenue?.employee_indivisual_target_assigned_by_head",revenue?.employee_indivisual_target_assigned_by_head)}
                    {console.log("revenue?.target_achieved/revenue?.employee_indivisual_target_assigned_by_head)*100",(revenue?.target_achieved/revenue?.employee_indivisual_target_assigned_by_head)*100)} */}

                            {/* {{width:
                    revenue?.employee_indivisual_target_assigned_by_head > 0 ? 
                    ((revenue?.target_achieved/revenue?.employee_indivisual_target_assigned_by_head)*100) > 100 
                    ? '0%':  
                    `${(100 - (revenue?.target_achieved/revenue?.employee_indivisual_target_assigned_by_head)*100) > 0 
                    ? (100 - (revenue?.target_achieved/revenue?.employee_indivisual_target_assigned_by_head)*100) : 0}%` 
                    : '0%'}} */}


                            <div className={`bg-gray-700  rounded-full h-2.5 flex items-center justify-center dark:bg-gray-300 font-[900]  text-[8px] text-white text-center leading-none `}
                              style={{
                                width:
                                  revenue?.employee_indivisual_target_assigned_by_head > 0 ?
                                    revenue?.target_achieved == 0
                                      ? '100%' :
                                      (100 - ((revenue?.target_achieved / revenue?.employee_indivisual_target_assigned_by_head) * 100) > 0 ? `${(100 - ((revenue?.target_achieved / revenue?.employee_indivisual_target_assigned_by_head) * 100))}%` : '0%')
                                    :
                                    '0%',
                                position: 'relative'
                              }} >



                              {revenue?.employee_indivisual_target_assigned_by_head > 0 ? (Math.round(100 - (revenue?.target_achieved / revenue?.employee_indivisual_target_assigned_by_head) * 100)) > 0 ? (Math.round(100 - (revenue?.target_achieved / revenue?.employee_indivisual_target_assigned_by_head) * 100)) : 0 : 0}%
                            </div>

                          </div>
                          <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{revenue?.employee_indivisual_target_assigned_by_head > 0 ? revenue?.employee_indivisual_target_assigned_by_head - revenue?.target_achieved > 0 ? rupeeIndian?.format(JSON.stringify(revenue?.employee_indivisual_target_assigned_by_head - revenue?.target_achieved))?.split('.')[0] : rupeeIndian?.format(JSON.stringify(0)).split('.')[0] : rupeeIndian?.format(JSON.stringify(0)).split('.')[0]}</h6>

                        </div>

                      </div>
                    </div>

                  </div>}


                  {/* {console.log("chartOptions1.series",chartOptions1.series)} */}

                {department_list?.includes(user_department) &&
                  <div className={`border ${user_department === 'Transaction Team' ? 'w-[100%]' : 'w-[100%]'} flex-0.6  mt-2 border-slate-100 px-3 py-2 relative`}>
                    <h1 className='text-[13px] font-[700]'>Total Leads</h1>
                    <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1.series} type="bar" width={'100%'} height={240} />
                  </div>}


              </div>
            }


            <div className='flex w-full mt-2 h-[250px]'>
              <div className='border p-2 w-[30%] mr-2 border-slate-100 '>
                <h6 className='pt-1 text-[13px] font-[700]'>Today Work</h6>
                <h6 className='p-1 text-[12px] bg-slate-100'>The total task made for today is <span className='font-[800]'>{homeData?.data?.length > 0 && homeData?.data[0]?.today_calls + homeData?.data[0]?.today_meetings + homeData?.data[0]?.today_property_visit + homeData?.data[0]?.today_leads + homeData?.data[0]?.today_notes}</span> which includes Daily Task as well as leads activity </h6>

                {(['bd_user', 'manager', 'hod'].filter((f) => user?.roles?.includes(f)).length > 0 && user?.department_id[0].department_name == 'Transaction Team') &&
                  <div className='flex mt-4 border-b border-t pt-2 border-slate-100 pb-2'>
                    <BiTask size={30} className='bg-orange-200 p-1.5' />
                    <div className='ml-2'>
                      <h6 className='text-[13px] mb-0  font-[700]'>FollowUp Task</h6>
                      <h6 className='text-[12px] -mt-0.5 font-[500]'>Today Calls Made({homeData?.data?.length > 0 && homeData?.data[0]?.today_calls}),Today Meetings ({homeData?.data?.length > 0 && homeData?.data[0]?.today_meetings}),
                        {user_department === 'Transaction Team' &&
                          `Property Visit (${homeData?.data?.length > 0 && homeData?.data[0]?.today_property_visit}),`}

                        Walk Ins ({homeData?.data?.length > 0 && homeData?.data[0]?.today_walkins})</h6>
                    </div>
                  </div>}

                <div className='flex mt-2 border-b border-slate-100 pb-2'>
                  <MdOutlineLeaderboard size={30} className='bg-green-200 p-1.5' />
                  <div className='ml-2'>
                    <h6 className='text-[13px] mb-0  font-[700]'>Today Leads</h6>
                    <h6 className='text-[12px] -mt-0.5 font-[500]'>Leads Worked on today ({homeData?.data?.length > 0 && homeData?.data[0]?.today_leads}), Notes Added ({homeData?.data?.length > 0 && homeData?.data[0]?.today_notes})</h6>
                  </div>
                </div>
              </div>
              <div className='border pb-10 overflow-hidden p-2 w-[30%] mr-2 border-slate-100 '>
                <h6 className='pt-1 text-[13px] font-[700] mb-3'>Meeting</h6>

                {reminderData?.length > 0 &&
                  <div className='h-full overflow-y-scroll'>
                    <div className=''>

                      {reminderData?.map((d) => (
                        <div className=' relative border-b border-slate-100 pb-1 flex my-1'>
                          <LuClock12 size={22} className='bg-slate-500 text-white p-[4px]' />
                          <div className='ml-2 -mt-0.5'>
                            <h6 className='text-[12px] min-w-[70%] w-[70%] max-w-[70%] mb-0 pb-0 font-[900]'>{d?.title}</h6>
                            <h6 className='text-[11.5px] -mt-0.5 text-slate-700'>{d?.description}
                              <span className='text-[10px] font-[700] ml-1 text-sky-800 underline cursor-pointer' onClick={() => navigator('/leads/contacts/detail', { state: d?.type_id?._id })}> View Info</span>
                            </h6>
                            {/* <h6  className='text-[10px] font-[700]'>Lead Details:</h6>
                    <h6 className='text-[10.5px]'>{d?.type_id?.contact_name}</h6>
                    <h6 className='text-[10.5px]'>Company Name <span className='font-[800]'>{d?.type_id?.company_name}</span> </h6> */}
                            <h6 className='text-[10px] absolute right-1  top-0.5' >{moment(d?.on_date_time)?.format('lll')}</h6>
                          </div>
                        </div>

                      ))}
                    </div>
                  </div>}

                {reminderData?.length === 0 &&
                  <div className='grid place-items-center mt-10'>
                    <LuCalendarClock size={50} className="text-slate-200" />
                    <h6 className='text-[13px] mt-2 text-center'>No Meetings found for today</h6>
                    <h6 className='text-[11px] text-center'>We found that you have not added any meeting for today session.</h6>
                  </div>}


              </div>

              <>
                {user_department === 'Transaction Team' &&
                  <div className='border relative p-2 w-[39.75%]  border-slate-100 '>
                    {user?.roles?.includes('admin') &&
                      <div onClick={() => setmodal(!modal)} className='mr-2 cursor-pointer absolute cursor-pointer flex items-center right-[130px] text-[10px] bg-slate-100 hover:bg-slate-200 p-[4px] rounded'>
                        <CiViewTable size={16} />
                      </div>
                    }
                    <div onClick={() => navigator('/dashboard/out_standing')} className='mr-2 cursor-pointer absolute flex items-center right-[10px] text-[10px] bg-slate-100 hover:bg-slate-200 p-[4px] rounded'>
                      <span className='font-[800]'>View Outstanding</span>
                      <TbArrowsSplit2 className='right-[10px] ml-[5px]' />
                    </div>
                    <h6 className='pt-1 text-[13px] font-[700]'>Payment Pending</h6>
                    <div className='flex '>
                      <div className='-ml-10'>
                        {/* <ReactApexChart options={chartOptions2} size={'10'} series={[revenue?.invoice_total_payment_received > 0 ? 100 - Math.round(100-(revenue?.invoice_total_payment_received/revenue?.invoice_total_payment_generated)*100) : 0]} type="radialBar" width={240} height={240} /> */}
                        <ReactApexChart options={chartOptions2} size={'10'} series={[revenue1?.invoice_total_payment_generated > 0 ? 100 - Math.round(100 - (revenue1?.invoice_total_payment_generated / revenue1?.invoice_amount) * 100) : 0]} type="radialBar" width={240} height={240} />
                      </div>

                      <div className='w-full '>
                        <div className='ml-0 mt-12 w-full relative'>
                          <h6 className='text-[11px] font-[600]'>Invoice Amount</h6>
                          <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                            <div style={{ width: revenue1?.invoice_amount > 0 ? '100%' : '0%' }} className="bg-gray-700 font-[900]  h-2.5 rounded-full flex items-center justify-center dark:bg-gray-300 w-[100%] text-[8px]  text-white text-center leading-none " >{revenue1?.invoice_amount > 0 ? '100' : '0'}%</div>
                          </div>
                          <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(revenue1?.invoice_amount))?.split('.')[0]}</h6>

                        </div>
                        <div className='ml-0 w-full relative -mt-1'>
                          <h6 className='text-[11px] font-[600]'>Payment Yet to collect </h6>
                          <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                            <div style={{ width: revenue1?.invoice_amount === 0 ? '0%' : `${Math.round(100 - (revenue1?.invoice_total_payment_generated / revenue1?.invoice_amount) * 100)}%` }} className={`bg-gray-700 h-2.5 rounded-full dark:bg-gray-300 flex items-center justify-center text-[8px] text-white text-center leading-none `} >{revenue1?.invoice_total_payment_generated === 0 ? 0 : Math.round(100 - (revenue1?.invoice_total_payment_generated / revenue1?.invoice_amount) * 100)}%</div>
                          </div>
                          <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(revenue1?.invoice_amount - revenue1?.invoice_total_payment_generated > 0 ? revenue1?.invoice_amount - revenue1?.invoice_total_payment_generated : 0))?.split('.')[0]}</h6>

                        </div>
                      </div>
                    </div>
                  </div>}
              </>

            </div>



          </div>



        </div>
      </div>
    </div>

  )
}

export default Dashboard