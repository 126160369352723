import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { TextAreaInput1, TextInput } from '../../components/input';
import { ButtonFilled, ButtonOutlined } from '../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import GoBack from '../../components/back/GoBack';
import { UpdateFidelitusLeadAssigned } from '../../services/AssignLeadServices';
import { GetUsersByDepartment } from '../../services/DepartmentServices';
import { BiCheckbox, BiCheckboxSquare } from 'react-icons/bi'
import FTSLeadMenu from './FTSLeadMenu';
import { UpdateFTSLeadAssignedNew } from '../../services/FTSLeadServices';

// import DailyTaskCE from './dailyTask/DailyTaskCE';


function FTSLeadRecievedFromFTSRemark() {

  const { state } = useLocation();

  const [data, setdata] = useState({
    name: '',
    phone: '',
    email: '',
    company_name: '',
    address: '',
    status: '',
    summary: '',
    remarks: '',
    send_notification: false,
    assigned_to: '',
    lead_type: 'lead'
  })

  const [users, setusers] = useState([])


  const [stage, setstage] = useState({ selected_stage: '', date: '', time: '', file: '' })
  const [stageerr, setstageerr] = useState({ selected_stage: '', date: '', time: '', file: '' })
  const [loader, setloader] = useState(false);

  const converted = [
    { label: 'Converted', value: 1 },
    { label: 'Rejected', value: 2 },
    { label: 'Pending', value: 3 },
    { label: 'Progress', value: 4 },
    { label: 'Lead Generated', value: 5 },
  ]



  const [error, seterror] = useState({
    department_id: '',
    contact_name: '',
    phone: '',
    email: '',
    designation: '',
    company_name: '',
    secondary_contact_person: '',
    secondary_contact_phone: '',
    secondary_contact_email: '',
    lead_source: '',
    industry: '',
    stage: '',
    zone: '',
    sqft: '',
    seater: '',
    rent: '',
    sale: '',
    website: '',
    requirment: '',
    address: '',
    operators: '',
    transferred: '',
    important: '',
    color: ''
  })

  const navigate = useNavigate()
  const location = useLocation()

  const roles = useSelector(state => state.Auth.roles)

  async function handleSelect(e, e1) {
    // console.log("e1",e1,"e",e)

    if (e1 === 'Converted') {
      setdata({ ...data, converted: converted?.find((i) => i.value == e) })
      seterror({ ...error, converted: '' })
    }
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '1px solid rgb(227 231 234)',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0px 6px',
      fontSize: '13px',
      fontWeight: 600,
      marginBottom: '0px',
      paddingBottom: '0px'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
  };

  async function searchUser() {
    const response = await GetUsersByDepartment(state?.department_id?._id)
    let arr = []
    response.data.datas.forEach((d) => {
      arr.push({ label: d?.name, value: d?._id })
    })
    setusers(arr)
  }

  useEffect(() => {
    if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') {
      geteditdata()
    }
  }, [])

  async function geteditdata() {
    let d = state?.fts_lead
    searchUser()
    setdata({
      id: state?._id,
      contact_name: d?.contact_name,
      phone: d?.phone,
      email: d?.email,
      company_name: d?.company_name,
      converted: converted?.find((i) => i.value == state?.converted),
      requirment: state?.requirment,
      address: d?.address,
      remarks: state?.remarks,
      assigned_to: state?.assigned_to !== undefined ? { label: state?.assigned_to?.name, value: state?.assigned_to?._id } : '',
      lead_type: state?.lead_type !== undefined ? state?.lead_type : 'lead',
      previously_handled_by: d?.previously_handled_by
    })
  }

  function handlechange(e) {
    setdata({ ...data, [e.target.name]: e.target.value })
    seterror({ ...error, [e.target.name]: '' })
  }

  async function submitform() {
    if (data.remarks === '') {
      seterror({ ...error, remarks: 'This Field is required*' })
    } else {
      let send_data = { ...data }
      send_data['converted'] = data?.converted?.value
      send_data['fidelitus_lead'] = state?.fts_lead._id

      if (data?.assigned_to?.label !== undefined) {
        send_data['send_notification'] = data?.send_notification
        send_data['assigned_to'] = data?.assigned_to?.value
      } else {
        delete send_data.assigned_to
      }


      if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') {
        setloader(true)

        const response = await UpdateFTSLeadAssignedNew({ id: data?.id, data: send_data })
        if (response.status === 200) {
          setloader(false)
          toast.success('Updated Successfully')
          resetform()
          navigate(-1)
        }
      }

    }
  }

  function resetform() {
    setdata({
      department_id: '',
      contact_name: '',
      phone: '',
      email: '',
      designation: '',
      company_name: '',
      secondary_contact_person: '',
      secondary_contact_phone: '',
      secondary_contact_email: '',
      lead_source: '',
      industry: '',
      stage: '',
      zone: '',
      sqft: false,
      seater: false,
      rent: false,
      sale: false,
      website: '',
      requirment: '',
      address: '',
      operators: [],
      transferred: '',
      important: '',
      color: ''
    })
    seterror({
      department_id: '',
      contact_name: '',
      phone: '',
      email: '',
      designation: '',
      company_name: '',
      secondary_contact_person: '',
      secondary_contact_phone: '',
      secondary_contact_email: '',
      lead_source: '',
      industry: '',
      stage: '',
      zone: '',
      sqft: '',
      seater: '',
      rent: '',
      sale: '',
      website: '',
      requirment: '',
      address: '',
      operators: '',
      transferred: '',
      important: '',
      color: ''
    })

    setstage({ selected_stage: '', date: '', time: '', file: '' })
    setstageerr({ selected_stage: '', date: '', time: '', file: '' })
  }

  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
      <div className='block sm:flex'>
        <FTSLeadMenu />
        <div className='pr-5 ml-5 min-h-screen max-h-screen h-screen overflow-y-scroll' >





          <div className='w-[85%] min-w-[38vw] max-w-[85vw] '>
            <GoBack />
            <div className='border-b  pb-2'>
              <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit' ? 'Edit' : 'Create'} Assign Lead</h6>
              <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the Assign Lead for your fidelitus vertical .</h6>
            </div>
            <Grid container spacing={2}  >
              <Grid item xs={12} md={6} >
                <TextInput
                  mandatory={true}
                  label={'Name'}
                  variant="standard"
                  name="contact_name"
                  type="text"
                  readOnly={true}
                  value={data.contact_name}
                  error={error.contact_name}
                  handlechange={handlechange}
                  placeholder="Enter contact name"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />
                <TextInput
                  mandatory={true}
                  label={'Phone'}
                  readOnly={true}
                  variant="standard"
                  name="phone"
                  type="text"
                  value={data.phone}
                  error={error.phone}
                  handlechange={handlechange}
                  placeholder="Enter contact phone"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />
                <TextInput
                  mandatory={true}
                  label={'Email'}
                  readOnly={true}
                  variant="standard"
                  name="email"
                  type="text"
                  value={data.email}
                  error={error.email}
                  handlechange={handlechange}
                  placeholder="Enter contact email"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />

                <TextInput
                  mandatory={true}
                  label={'Company Name'}
                  readOnly={true}
                  variant="standard"
                  name="company_name"
                  type="text"
                  value={data.company_name}
                  error={error.company_name}
                  handlechange={handlechange}
                  placeholder="Enter company name"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />
                <TextAreaInput1
                  label={'Company Address'}
                  readOnly={true}
                  variant="standard"
                  name="address"
                  type="text"
                  value={data.address}
                  error={error.address}
                  handlechange={handlechange}
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />

                <div className='-mt-2'>
                  <TextAreaInput1
                    label={`Previously Handled By`}
                    variant="standard"
                    name="requirment"
                    type="text"
                    readOnly={true}
                    value={data?.previously_handled_by}
                    error={error?.previously_handled_by}
                    handlechange={(e) => setdata({ ...data, requirment: e.target.value })}
                    InputLabelProps={{
                      style: { color: '#fff', },
                    }}
                  />
                </div>
                <div className='-mt-2'>

                  <TextAreaInput1
                    label={`Requirment`}
                    variant="standard"
                    name="requirment"
                    type="text"
                    readOnly={true}
                    value={data?.requirment}
                    error={error?.requirment}
                    handlechange={(e) => setdata({ ...data, requirment: e.target.value })}
                    InputLabelProps={{
                      style: { color: '#fff', },
                    }}
                  />
                </div>


                {/* <>
             <TextInput 
              mandatory={true}
            label={'Designation'}  
            variant="standard"
            name="designation"
            type="text"
            value={data.designation}
            error={error.designation}
            handlechange={handlechange}
            placeholder="Enter Designation"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
             <TextInput 
            label={'Secondary Contact'}  
            variant="standard"
            name="secondary_contact_person"
            type="text"
            value={data.secondary_contact_person}
            error={error.secondary_contact_person}
            handlechange={handlechange}
            placeholder="Enter secondary contact name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
             <TextInput 
            label={'Secondary Phone'}  
            variant="standard"
            name="secondary_contact_phone"
            type="text"
            value={data.secondary_contact_phone}
            error={error.secondary_contact_phone}
            handlechange={handlechange}
            placeholder="Enter secondary contact phone"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
             <TextInput 
            label={'Secondary Email'}  
            variant="standard"
            name="secondary_contact_email"
            type="text"
            value={data.secondary_contact_email}
            error={error.secondary_contact_email}
            handlechange={handlechange}
            placeholder="Enter secondary contact email"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
            </>
            */}

              </Grid>
              <Grid item xs={12} md={6} >



                {/* <>
        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Contact Source</h6>
         <Select
           value={data.lead_source}
           error={error.lead_source}
           placeholder="" 
           bordered={false}
           onChange={(e)=>handleSelect(e,'Country')} 
           styles={customStyles} 
           options={contactSourceArr} 
           style={{fontSize:'12px'}}
           className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
           />
         {error.lead_source !== '' && error.lead_source !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.lead_source}</span>
         </div>}

         <h6 className='text-[12px] font-semibold mb-1 mt-1'>Industry </h6>
         <Select
           value={data.industry} 
           error={error.industry}
           placeholder="" 
           bordered={false}
           onChange={(e)=>handleSelect(e,'Industry')} 
           styles={customStyles} 
           options={IndustyArr} 
           className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
           
           />
         {error.industry !== '' && error.industry !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.industry}</span>
         </div>}
         </> */}




                {/* <h6 className='text-[12px] font-semibold mb-1 mt-1'>Department</h6>
         <Select
           value={selected_department} 
           mode="multiple"
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'Department')} 
           styles={customStyles} 
           options={DepartmentArr}
           className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
           />
         {error.stage !== '' && error.stage !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.stage}</span>
         </div>}


         {selected_department?.map((s,i)=>(
            <div>
                <TextAreaInput1 
                    label={`${s?.label} Summary`}  
                    variant="standard"
                    name="address"
                    type="text"
                    value={s.summary}
                    error={error.address}
                    handlechange={(e)=>updatesummary(i,e)}
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

            </div>
         ))} */}

                <h6 className='text-[12px] font-semibold mb-1 mt-1'>Status</h6>

                <Select
                  value={data?.converted}
                  bordered={false}
                  placeholder=""
                  onChange={(e) => handleSelect(e, 'Converted')}
                  styles={customStyles}
                  options={[
                    { label: 'Converted', value: 1 },
                    { label: 'Rejected', value: 2 },
                    { label: 'Pending', value: 3 },
                    { label: 'Progress', value: 4 },
                    { label: 'Lead Generated', value: 5 },
                  ]}
                  className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />

                {(state?.department_id?.department_name === 'Transaction Team' && (roles?.includes('hod') || roles?.includes('admin') || roles?.includes('lead_forwader'))) &&

                  <>
                    <h6 className='text-[12px] font-semibold mb-1 mt-1'>Lead Type</h6>

                    <Select
                      value={data?.lead_type}
                      bordered={false}
                      placeholder=""
                      onChange={(e) => setdata({ ...data, lead_type: e })}
                      styles={customStyles}
                      options={[
                        { label: 'Commercial', value: 'commercial' },
                        { label: 'Resedential', value: 'resedential' },
                        { label: 'ILS', value: 'land' },
                        { label: 'Database', value: 'db' },
                      ]}
                      className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                    />


                    <h6 className='text-[12px] font-semibold mb-1 mt-1'>Assigned To</h6>
                    <Select
                      value={data?.assigned_to}
                      bordered={false}
                      placeholder=""
                      onChange={(e) => setdata({ ...data, assigned_to: e })}
                      styles={customStyles}
                      options={users}
                      className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                    />

                    <div className='flex items-center mt-1' onClick={() => setdata({
                      ...data, send_notification: !data
                        .send_notification
                    })}>
                      {!data?.send_notification ? <BiCheckbox size={18} className='text-slate-300' /> : <BiCheckboxSquare size={18} className='text-slate-700' />}
                      <h6 className='text-[11px] ml-1' >Send Notification For Assigned Uer</h6>
                    </div>
                  </>}



                <TextAreaInput1
                  label={`Summary`}
                  variant="standard"
                  name="remarks"
                  type="text"
                  value={data?.remarks}
                  error={error?.remarks}
                  handlechange={(e) => setdata({ ...data, remarks: e.target.value })}
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }}
                />

              </Grid>
            </Grid>


            <div >
              <div className='flex items-center mt-5 mb-10  border-t pt-5'>
                <div className='mr-2'>
                  <ButtonOutlined btnName={'Back'} onClick={() => navigate(-1)} />
                </div>
                <div>
                  <ButtonFilled btnName={'Save'} onClick={() => submitform()} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default FTSLeadRecievedFromFTSRemark