import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { TextAreaInput1, TextInput, TextInput1 } from '../../components/input';
import { BiErrorCircle } from 'react-icons/bi'
import { GetStageService } from '../../services/StageServices';
import { ButtonFilled, ButtonFilledAutoWidth, ButtonOutlined, ButtonOutlinedAutoWidth } from '../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { DatePicker, Modal, Select, TimePicker } from 'antd';
import GoBack from '../../components/back/GoBack';
import { GetDepartmentService } from '../../services/DepartmentServices';
import CallsMenu from './CallsMenu';
import { CreateCallService, GetCallDetailService, UpdateCallService } from '../../services/CallsLeadServices';
// import DailyTaskCE from './dailyTask/DailyTaskCE';
import { RiCheckboxCircleLine, RiCheckboxCircleFill } from "react-icons/ri";
import { IoIosCheckbox, IoIosCheckboxOutline } from "react-icons/io";
import { CreateReminderService } from '../../services/ReminderServices';
import ErrorComponent from '../../components/errorDesign/ErrorComponent';
import { AiOutlineClose } from 'react-icons/ai';
import { UploadProfiledata, getMyProfile } from '../../services/AuthServices';
import { AiOutlineCalendar } from "react-icons/ai";
import { useSelector } from 'react-redux';

function CallsLeadCE() {
  const auth = useSelector(state => state.Auth)
  const [userDepartment, setUserDepartment] = useState({ department_name: '' })

  const department_FCPL_FTS_BACKEND = auth?.department_id?.filter((f) => f?.department_name == 'FTS Team' || f?.department_name == 'Corp Team' || f?.department_name == 'Backend Team')?.length > 0 ? true : false

  const [data, setdata] = useState({
    department_id: '',
    contact_name: '',
    phone: '',
    email: '',
    designation: '',
    company_name: '',
    lead_work: '',
    lead_handled_by: '',
    secondary_contact_person: '',
    secondary_contact_phone: '',
    secondary_contact_email: '',
    lead_source: '',
    industry: '',
    stage: '',
    zone: '',
    sqft: false,
    seater: false,
    rent: false,
    sale: false,
    called: false,
    website: '',
    requirment: '',
    address: '',
    transferred: '',
    important: '',
    color: '',
    land_lord: false,
    previously_handled_by: '',
    zone: '',
    city: '',
    state: '',
    remarks: '',
    follow_up: false,
    follow_up_date: '',
    operator: '',
    contact_source: '',
    stage: '',
    create_contact: false,
    fts_contact: false
  })

  const user = useSelector(state => state.Auth)
  // console.log("user",user)
  const fts_department = user?.department_id.filter((f) => f?.department_name === 'FTS Team').length > 0
  const [ftsassign, setftsassign] = useState(false)
  const [old_fidelitus_lead_assigned, setold_fidelitus_lead_assigned] = useState([])
  const [DepartmentArr, setDepartmentArr] = useState([])
  const [selected_department, setselected_department] = useState([])
  const [contactSourceArr, setcontactSourceArr] = useState([])
  const [stage, setstage] = useState({ selected_stage: '', date: '', time: '', file: '' })
  const [stageerr, setstageerr] = useState({ selected_stage: '', date: '', time: '', file: '' })
  const [IndustyArr, setIndustyArr] = useState([])
  const [leadStatusArr, setleadStatusArr] = useState([])
  const [zoneArr, setzoneArr] = useState([])
  const [loader, setloader] = useState(false);
  const [reminderModal, setreminderModal] = useState(false);

  const [reminderData, setreminderData] = useState({ title: '', description: '', date: '', date1: '', time: '', time1: '' })
  const [reminderDataErr, setreminderDataErr] = useState({ title: '', description: '', date: '', date1: '', time: '', time1: '' })

  const [error, seterror] = useState({
    department_id: '',
    contact_name: '',
    phone: '',
    email: '',
    designation: '',
    company_name: '',
    secondary_contact_person: '',
    secondary_contact_phone: '',
    secondary_contact_email: '',
    lead_source: '',
    industry: '',
    stage: '',
    zone: '',
    sqft: '',
    seater: '',
    rent: '',
    sale: '',
    website: '',
    requirment: '',
    address: '',
    operators: '',
    transferred: '',
    important: '',
    color: '',
    remarks: '',
    called: false,
    operator: '',
    contact_source: '',
    stage: ''
  })

  const navigate = useNavigate()
  const location = useLocation()

  const openCalendar = () => {
    window.open("/calendar/list", "_blank");
  };


  async function handleSelect(e, e1) {
    if (e1 === 'Department') {
      setdata({ ...data, department_id: DepartmentArr?.find((f) => f?.value === e) })
      seterror({ ...error, department_id: '' })
    }

    if (e1 === 'SelectedDepartment') {

      // console.log("e here",e)
      // console.log("e1 here",e1)
      setselected_department_error('')

      if (selected_department1?.length > 0) {


        let arr = []
        e?.forEach((d) => {
          console.log("d here", d)
          let data = DepartmentArr?.find((f) => f?.value === d)
          console.log("data", data)
          if (data !== undefined) {
            arr.push({ ...data, summary: '', estimated_revenue: 0, actual_revenue_achieved: 0 })
          }
        })

        setselected_department1(arr)
      } else {
        let arr = []
        e?.forEach((d) => {
          console.log("d here", d)
          let data = DepartmentArr?.find((f) => f?.value === d)
          console.log("data", data)
          if (data !== undefined) {
            arr.push({ ...data, summary: '', estimated_revenue: 0, actual_revenue_achieved: 0 })
          }
        })

        setselected_department1(arr)
      }
    }

    if (e1 === 'Industry') {
      setdata({ ...data, industry: IndustyArr?.find((f) => f?.value === e) })
      seterror({ ...error, industry: '' })
    }

    if (e1 === 'Contact_Source') {
      setdata({ ...data, contact_source: contactSourceArr?.find((f) => f?.value === e) })
      seterror({ ...error, contact_source: '' })
    }

    if (e1 === 'Stage') {
      if (e === 'Call Back Later' && data?.id !== undefined) {
        setreminderModal(true)
      }
      setdata({ ...data, stage: { label: e, value: e } })
      seterror({ ...error, stage: '' })
    }
    // console.log("@@@@@@@@@@@_____Data", location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'create')
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '1px solid rgb(227 231 234)',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0px 6px',
      fontSize: '13px',
      fontWeight: 600,
      marginBottom: '0px',
      paddingBottom: '0px'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
  };

  const [selected_department1, setselected_department1] = useState([])
  const [selected_department_error, setselected_department_error] = useState('')


  async function getbasicdatareq() {
    const response = await GetStageService('Lead_Stage')
    const response1 = await GetStageService('Contact_Source')
    const response2 = await GetStageService('Industry')
    const response3 = await GetStageService('Zones')
    const response4 = await GetDepartmentService()
    // console.log("response4",response4)

    let arr = []
    let arr1 = []
    let arr2 = []
    let arr3 = []
    let arr4 = []

    response.data.data.datas.forEach((d) => {
      if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'create') {
        if (d?.name !== 'Calls' && d?.name !== 'Walk-Ins' && d?.name !== 'Meetings' && d?.name !== 'Invoice Raised' && d?.name !== 'Property-Visit') {
          arr.push({ label: d?.name, value: d?._id })
        }
      } else {
        if (d?.name !== 'Calls' && d?.name !== 'Walk-Ins' && d?.name !== 'Meetings' && d?.name !== 'Property-Visit') {
          arr.push({ label: d?.name, value: d?._id })
        }
      }

      if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'create' && location?.state !== null && location?.state?.type === 'convert') {
        setdata({ ...location?.state?.data, stage: arr?.find(s => s.label === 'Contact') })
      }
      // arr.push({value:d._id,label:d.name})
    })
    response1.data.data.datas.forEach((d) => {
      arr1.push({ value: d._id, label: d.name })
    })
    response2.data.data.datas.forEach((d) => {
      arr2.push({ value: d._id, label: d.name })
    })
    response3.data.data.datas.forEach((d) => {
      arr3.push({ value: d._id, label: d.name })
    })
    response4.data.datas.forEach((d) => {
      arr4.push({ value: d.id, label: d.department_name })
    })

    setleadStatusArr(arr)
    setcontactSourceArr(arr1)
    setIndustyArr(arr2)
    setzoneArr(arr3)
    setDepartmentArr(arr4)

    if (location?.pathname?.split('/')[2] === 'create' && location?.pathname?.split('/')[1] == 'contacts') {
      let lead_stage = response.data.data.datas.find((d) => d?.name === 'Contact')
      setdata({ ...data, stage: { value: lead_stage._id, label: lead_stage?.name } })
    }
  }

  useEffect(() => {
    getbasicdatareq()
    if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') {
      geteditdata()
    }
    getprofile()
  }, [])



  async function getprofile() {
    const response = await getMyProfile(auth.id);
    let d = response?.data?.data[0];
    const departmentName = d?.department_id[0]?.department_name;
    // Set userDepartment
    setUserDepartment({ department_name: departmentName });
    // Set the data.department_id to the corresponding department object
    const selectedDepartment = DepartmentArr.find(dept => dept.label === departmentName);
    if (selectedDepartment) {
      setdata(prevData => ({ ...prevData, department_id: selectedDepartment }));
    }
  }


  async function geteditdata() {
    const response = await GetCallDetailService(location?.state);
    let d = response?.data?.datas[0]




    setdata({
      id: d?._id,
      department_id: d?.department_id?._id !== undefined ? { label: d?.department_id?.department_name, value: d?.department_id?._id } : '',
      contact_name: d?.contact_name,
      phone: d?.phone,
      email: d?.email,
      designation: d?.designation,
      company_name: d?.company_name,
      industry: (d?.industry !== undefined && d?.industry !== null) ? { value: d?.industry?._id, label: d?.industry?.name } : {},
      stage: (d?.stage !== undefined && d?.stage !== null) ? { value: d?.stage?._id, label: d?.stage?.name } : {},
      zone: (d?.zone !== undefined && d?.zone !== null) ? { value: d?.zone?._id, label: d?.zone?.name } : {},
      sqft: d?.sqft,
      seater: d?.seater,
      rent: d?.rent,
      sale: d?.sale,
      website: d?.website,
      requirment: d?.requirment,
      address: d?.address,
      transferred: d?.transferred,
      important: d?.important,
      remarks: d?.remarks !== undefined ? d?.remarks : '',
      color: d?.color,
      previously_handled_by: d?.previously_handled_by,
      zone: d?.zone !== undefined ? d?.zone : '',
      state: d?.state !== undefined ? d?.state : '',
      city: d?.city !== undefined ? d?.city : '',
      called: d?.called,
      follow_up: d?.follow_up,
      follow_up_date: d?.follow_up_date,
      operator: d?.operator === undefined ? '' : d?.operator,
      contact_source: d?.contact_source === undefined ? '' : { label: d?.contact_source?.name, value: d?.contact_source?.value },
      stage: d?.stage === undefined ? d?.stage : { label: d?.stage, value: d?.stage }
    })

    let assign_list = []
    d?.fidelitusleadassigneds?.forEach((f) => {
      assign_list.push({ label: f?.department_id?.department_name, value: f?.department_id?._id, summary: f?.requirment })
    })

    setold_fidelitus_lead_assigned(d?.fidelitusleadassigneds)

    setselected_department(assign_list)

  }

  // console.log("data?.operators",data?.operators)
  function handlechange(e) {
    setdata({ ...data, [e.target.name]: e.target.value })
    seterror({ ...error, [e.target.name]: '' })
  }

  function handlechange1(e) {
    setreminderData({ ...reminderData, [e.target.name]: e.target.value })
    setreminderDataErr({ ...reminderDataErr, [e.target.name]: '' })
  }

  console.log("selected_department --->", selected_department1)

  async function submitform() {

    if (!data.contact_name) {
      seterror({ ...error, contact_name: 'This Field is required*' })
    } else if (!data.phone) {
      seterror({ ...error, phone: 'This Field is required*' })
    } else if (!data.email) {
      seterror({ ...error, email: 'This Field is required*' })
    } else if (!data.company_name) {
      seterror({ ...error, company_name: 'This Field is required*' })
    } else if (!data.stage?.label) {
      seterror({ ...error, stage: 'This Field is required*' })
    } else {
      let send_data = {}
      send_data['contact_name'] = data?.contact_name;
      send_data['phone'] = data?.phone;
      send_data['email'] = data?.email;
      send_data['company_name'] = data?.company_name;
      send_data['designation'] = data.designation;
      send_data['lead_handled_by'] = data?.lead_handled_by?.value;
      send_data['lead_work'] = data?.lead_work;
      send_data['called'] = data?.called;
      send_data['follow_up'] = data?.follow_up;
      if (data?.follow_up) {
        send_data['follow_up_date'] = data?.follow_up_date;
      }
      send_data['address'] = data?.address;
      send_data['previously_handled_by'] = data?.previously_handled_by;
      send_data['zone'] = data?.zone?.value;
      send_data['city'] = data?.city;
      send_data['create_contact'] = data?.create_contact;
      send_data['fts_contact'] = data?.fts_contact;
      send_data['remarks'] = data?.remarks !== undefined ? data?.remarks : '';
      send_data['requirment'] = data?.requirment !== undefined ? data?.requirment : '';
      if (data?.contact_source?.value !== undefined) {
        send_data['contact_source'] = data?.contact_source !== '' ? data?.contact_source?.value : '';
      }
      send_data['stage'] = data?.stage !== undefined ? data?.stage?.value : '';


      send_data['selected_department1'] = selected_department1


      if (data?.department_id?.value !== undefined) {
        send_data['department_id'] = data?.department_id?.value
      }

      if (data?.industry?.value !== undefined) {
        send_data['industry'] = data?.industry?.value
      }

      if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') {


        setloader(true)
        const response = await UpdateCallService(data?.id, send_data)
        if (response.status === 200) {
          setloader(false)
          // console.log("Anna data?.stage?.label",data?.stage?.label,"stage?.selected_stage?.label",stage?.selected_stage?.label)
          setloader(false)
          toast.success('Calls Updated Successfully')
          resetform()
          navigate(-1)
        }
      } else {
        setloader(true)
        const response = await CreateCallService(send_data)
        if (response.status === 201) {
          setloader(false)
          toast.success('Calls Created Successfully')
          resetform()
        }
      }
    }
  }

  function resetform() {
    setdata({
      department_id: '',
      contact_name: '',
      phone: '',
      email: '',
      designation: '',
      company_name: '',
      secondary_contact_person: '',
      secondary_contact_phone: '',
      secondary_contact_email: '',
      lead_source: '',
      industry: '',
      stage: '',
      zone: '',
      sqft: false,
      seater: false,
      rent: false,
      sale: false,
      website: '',
      requirment: '',
      address: '',
      operator: '',
      transferred: '',
      important: '',
      color: '',
      called: false
    })
    seterror({
      department_id: '',
      contact_name: '',
      phone: '',
      email: '',
      designation: '',
      company_name: '',
      secondary_contact_person: '',
      secondary_contact_phone: '',
      secondary_contact_email: '',
      lead_source: '',
      industry: '',
      stage: '',
      zone: '',
      sqft: '',
      seater: '',
      rent: '',
      sale: '',
      website: '',
      requirment: '',
      address: '',
      operators: '',
      operator: '',
      transferred: '',
      important: '',
      color: ''
    })

    setstage({ selected_stage: '', date: '', time: '', file: '' })
    setstageerr({ selected_stage: '', date: '', time: '', file: '' })
  }


  const stages = [
    { label: 'New Contact', value: 'New Contact' },
    { label: 'Not Recieved', value: 'Not Recieved' },
    { label: 'Invalid No', value: 'Invalid No' },
    { label: 'Call Back Later', value: 'Call Back Later' },
    { label: 'Not Interested', value: 'Not Interested' },
    { label: 'Lead Generated', value: 'Lead Generated' },
    { label: 'Not a Concern Person', value: 'Not a Concern Person' },
  ]

  const filteredStages = data?.id == undefined ? stages.filter(stage => stage.label !== "Call Back Later" && stage.label !== "Lead Generated") : stages;

  async function updatesummary(i, e) {
    let old_Data = [...selected_department1]
    old_Data[i]['summary'] = e?.target?.value
    setselected_department1([...old_Data])
  }

  async function createReminder() {
    if (!reminderData.title) {
      setreminderDataErr({ ...reminderData, title: 'This Field is required*' })
    } else if (!reminderData.description) {
      setreminderDataErr({ ...reminderData, description: 'This Field is required*' })
    } else if (!reminderData.date) {
      setreminderDataErr({ ...reminderData, date: 'This Field is required*' })
    } else if (!reminderData.time) {
      setreminderDataErr({ ...reminderData, time: 'This Field is required*' })
    } else {
      let passData = {
        ...reminderData,
        type: 'CallLead',
        type_id: data?.id,
        on_date_time: reminderData?.date1 + ' ' + reminderData?.time1,
        notify: 'Email',
        repeat: 'Once',
      }
      const response = await CreateReminderService(passData)
      if (response?.status == 200) {
        toast.success("Reminder Created Successfully")
        setreminderModal(false)
        setreminderData({ title: '', description: '', date: '', date1: '', time: '', time1: '' })
        setreminderDataErr({ title: '', description: '', date: '', date1: '', time: '', time1: '' })
      }

    }
  }


  const handleClose = () => {
    setreminderModal(false)
  }

  function closeftsassign() {
    if (selected_department1.length > 0) {
      setftsassign(false)
    } else {
      setftsassign(false)
      setdata({ ...data, fts_contact: false })
    }
  }

  function removeftsassign() {
    setftsassign(false)
    setselected_department1([])
    setdata({ ...data, fts_contact: false })
  }

  async function updateestimatedrevenue(i, e) {
    let old_Data = [...selected_department1]
    old_Data[i]['estimated_revenue'] = e?.target?.value
    setselected_department1([...old_Data])
  }

  async function updateactualrevenue(i, e) {
    let old_Data = [...selected_department1]
    old_Data[i]['actual_revenue_achieved'] = e?.target?.value
    setselected_department1([...old_Data])
  }

  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>

      <Modal open={ftsassign} width={350} closable={false} footer={false}>
        <div className='relative'>
          <AiOutlineClose onClick={closeftsassign} className='absolute -right-2 -top-2 cursor-pointer' />
          <h6 className='font-[700]'>Assign Lead Department</h6>
          <h6 className='bg-slate-100 p-2 text-[11px] leading-[14px]'>Use the below form to assign leads to other department</h6>

          <h6 className='text-[12px] font-semibold mb-1 mt-1'>Department</h6>
          <Select
            value={selected_department1}
            mode="multiple"
            bordered={false}
            placeholder=""
            onChange={(e) => handleSelect(e, 'SelectedDepartment')}
            styles={customStyles}
            options={DepartmentArr.filter(dept =>
              dept.label !== userDepartment.department_name && dept.label !== "Software Team" && dept.label !== "Digital Media" && dept.label !== "Finance Team" && dept.label !== "FTS Team"
            )}
            className='border-l-4 border-l-slate-600 min-w-[100%] max-w-[100%] border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
          />
          <ErrorComponent error={selected_department_error} />
          {error.stage !== '' && error.stage !== undefined &&
            <div className='flex items-center mt-1'>
              <BiErrorCircle className='text-red-500' size={14} />
              <span className='text-[10px] text-red-500 ml-1'>{error.stage}</span>
            </div>}


          {selected_department1?.map((s, i) => (
            <div>
              <TextAreaInput1
                label={`${s?.label} Requirment`}
                variant="standard"
                name="address"
                type="text"
                value={s.summary}
                error={error.address}
                handlechange={(e) => updatesummary(i, e)}
                InputLabelProps={{
                  style: { color: '#fff', },
                }} />

              {department_FCPL_FTS_BACKEND &&
                <>
                  <TextInput1
                    label={`${s?.label} Estimated Revenue`}
                    variant="standard"
                    name="number"
                    type="number"
                    value={s.estimated_revenue}
                    handlechange={(e) => updateestimatedrevenue(i, e)}
                    InputLabelProps={{
                      style: { color: '#fff', },
                    }} />

                  <TextInput1
                    label={`${s?.label} Actual Revenue`}
                    variant="standard"
                    name="number"
                    type="number"
                    value={s.actual_revenue_achieved}
                    handlechange={(e) => updateactualrevenue(i, e)}
                    InputLabelProps={{
                      style: { color: '#fff', },
                    }} />
                </>}

            </div>
          ))}


          <div className='mt-2 flex border-t pt-2 justify-end'>
            <ButtonOutlinedAutoWidth btnName="Cancel" onClick={() => removeftsassign()} />
            <div className='w-2'></div>
            <ButtonFilledAutoWidth btnName="Save" onClick={closeftsassign} />
          </div>



        </div>


      </Modal>

      <div className='block sm:flex'>
        <CallsMenu />
        <div className='pr-5 ml-5 min-h-screen max-h-screen h-screen overflow-y-scroll' >

          <Modal open={reminderModal} closable={false} footer={false} width={300}>
            <div className='relative'>
              <AiOutlineClose onClick={handleClose} size={15} className='absolute -right-[15px] -top-[10px]  cursor-pointer' />
              <h6 className='font-[800]'>Create a Call Back Reminder for the selected Contact</h6>



              <div>

                <TextInput
                  mandatory={true}
                  label={'Title'}
                  variant="standard"
                  name="title"
                  type="text"
                  value={reminderData.title}
                  error={reminderDataErr.title}
                  handlechange={handlechange1}
                  placeholder="Enter contact email"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />

                <TextAreaInput1
                  mandatory={true}
                  label={'Description'}
                  variant="standard"
                  name="description"
                  type="text"
                  value={reminderData.description}
                  error={reminderDataErr.description}
                  handlechange={handlechange1}
                  placeholder=""
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />



                <div className='flex mt-2'>
                  <div>
                    <h6 className='text-[11px] font-[700] mb-1'>Date</h6>
                    <div className='border border-slate-200 mr-2 border-l-4 border-l-slate-600'>
                      <DatePicker placeholder='' onChange={(e, e1) => { setreminderData({ ...reminderData, date: e, date1: e1 }); setreminderDataErr({ ...reminderDataErr, date: '' }) }} bordered={false} />
                    </div>
                    <ErrorComponent error={reminderDataErr?.date} />
                  </div>

                  <div>
                    <h6 className='text-[11px] font-[700] mb-1'>Time</h6>
                    <div className='border border-slate-200 border-l-4 border-l-slate-600'>
                      <TimePicker placeholder='' onChange={(e, e1) => { setreminderData({ ...reminderData, time: e, time1: e1 }); setreminderDataErr({ ...reminderDataErr, time: '' }) }} bordered={false} format={"hh:mm"} />
                    </div>
                    <ErrorComponent error={reminderDataErr?.time} />

                  </div>
                </div>
              </div>

              <div className='flex justify-end mt-2 pt-2 border-t border-slate-200'>
                <ButtonFilledAutoWidth onClick={createReminder} btnName="Save" />
              </div>
            </div>
          </Modal>


          <div className='w-[85%] min-w-[38vw] max-w-[38vw] '>
            <GoBack />
            <div className='border-b  pb-2'>
              <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit' ? 'Edit' : 'Create'} Call / Contact Lead</h6>
              <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the Call / Contact created by you.</h6>
            </div>
            <div className='w-[100%] overflow-x-hidden'>
              <Grid container spacing={2}  >
                <Grid item xs={12} md={6} >
                  <TextInput
                    mandatory={true}
                    label={'Name'}
                    variant="standard"
                    name="contact_name"
                    type="text"
                    value={data.contact_name}
                    error={error.contact_name}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                      style: { color: '#fff', },
                    }} />
                  <TextInput
                    mandatory={true}
                    label={'Phone'}
                    variant="standard"
                    name="phone"
                    type="text"
                    value={data.phone}
                    error={error.phone}
                    handlechange={handlechange}
                    placeholder="Enter contact phone"
                    InputLabelProps={{
                      style: { color: '#fff', },
                    }} />
                  <TextInput
                    mandatory={true}
                    label={'Email'}
                    variant="standard"
                    name="email"
                    type="text"
                    value={data.email}
                    error={error.email}
                    handlechange={handlechange}
                    placeholder="Enter contact email"
                    InputLabelProps={{
                      style: { color: '#fff', },
                    }} />

                  <TextInput
                    label={'Designation'}
                    variant="standard"
                    name="designation"
                    type="text"
                    value={data.designation}
                    error={error.designation}
                    handlechange={handlechange}
                    placeholder="Enter designation"
                    InputLabelProps={{
                      style: { color: '#fff', },
                    }} />

                  <TextInput
                    mandatory={true}
                    label={'Company Name'}
                    variant="standard"
                    name="company_name"
                    type="text"
                    value={data.company_name}
                    error={error.company_name}
                    handlechange={handlechange}
                    placeholder="Enter company name"
                    InputLabelProps={{
                      style: { color: '#fff', },
                    }} />
                  <TextAreaInput1
                    label={'Location'}
                    variant="standard"
                    name="address"
                    type="text"
                    value={data.address}
                    error={error.address}
                    handlechange={handlechange}
                    InputLabelProps={{
                      style: { color: '#fff', },
                    }} />

                  <TextAreaInput1
                    label={'Requirment'}
                    variant="standard"
                    name="requirment"
                    type="text"
                    value={data.requirment}
                    error={error.requirment}
                    handlechange={handlechange}
                    InputLabelProps={{
                      style: { color: '#fff', },
                    }} />







                </Grid>
                <Grid item xs={12} md={6} >



                  <>
                    <h6 className='text-[12px] font-semibold mb-1 mt-[8px]'>Department</h6>
                    <Select
                      value={data.department_id}
                      error={error.department_id}
                      placeholder=""
                      bordered={false}
                      onChange={(e) => handleSelect(e, 'Department')}
                      styles={customStyles}
                      options={DepartmentArr}
                      style={{ fontSize: '12px' }}
                      className='w-full border border-slate-300 outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                    />
                    {error.department_id !== '' && error.department_id !== undefined &&
                      <div className='flex items-center mt-1'>
                        <BiErrorCircle className='text-red-500' size={14} />
                        <span className='text-[10px] text-red-500 ml-1'>{error.department_id}</span>
                      </div>}


                    <div className='flex'>
                      <div className='mr-5 mt-5 flex'>
                        <h6 onClick={() => setdata({ ...data, called: !data.called })} className={`text-[10px] ${data?.called ? 'bg-slate-600 text-white' : 'bg-slate-100'} p-[4px] cursor-pointer font-[600] mb-1 mt-1 mr-5`}>Called Status</h6>
                        <h6 onClick={() => setdata({ ...data, follow_up: !data.follow_up })} className={`text-[10px] ${data?.follow_up ? 'bg-slate-600 text-white' : 'bg-slate-100'} p-[4px] cursor-pointer font-[600] mb-1 mt-1 mr-5`}>FollowUp Status</h6>

                        {/* <div onClick={()=>setdata({...data,called:!data.called})}>
          {data.called ?
          <RiCheckboxCircleFill size={24}  className='text-green-500'/>
          :
          <RiCheckboxCircleLine size={24} className='text-slate-200' />}
        </div> */}
                      </div>

                      <div>
                        {/* <h6 onClick={()=>setdata({...data,follow_up:!data.follow_up})} className='text-[10px] bg-slate-100 p-[4px] cursor-pointer font-semibold mb-1 mt-1'>FollowUp Status</h6> */}

                        {/* <div onClick={()=>setdata({...data,follow_up:!data.follow_up})}>
          {data.follow_up ?
          <RiCheckboxCircleFill size={24}  className='text-green-500'/>
          :
          <RiCheckboxCircleLine size={24} className='text-slate-200' />}
        </div> */}
                      </div>
                    </div>

                    {data.follow_up && <>
                      <h6 className='text-[12px] font-semibold mb-1 mt-1'>FollowUp Date</h6>
                      <input className='border p-1.5 w-full focus:ring-0 outline-none  text-[11px] border-gray-300 ' type='Date' value={data?.follow_up_date?.slice(0, 10)} onChange={(e) => { setdata({ ...data, follow_up_date: e.target.value }); seterror({ ...error, follow_up_date: '' }) }} />
                    </>}
                    <div className="flex items-center justify-between">
                      <h6 className="text-[12px] font-semibold mb-1 mt-1">Stage</h6>

                      {data?.stage?.label === "Call Back Later" && data?.id !== undefined && (
                        <AiOutlineCalendar
                          className="text-gray-600 text-lg cursor-pointer"
                          onClick={openCalendar}
                        />
                      )}
                    </div>
                    <Select
                      value={data.stage}
                      error={error.stage}
                      placeholder=""
                      bordered={false}
                      onChange={(e) => handleSelect(e, 'Stage')}
                      styles={customStyles}
                      options={filteredStages}
                      style={{ fontSize: '12px' }}
                      className="w-full border border-slate-300 border-l-4 border-l-slate-600 h-[33px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none"
                    />


                    {error.stage !== '' && error.stage !== undefined &&
                      <div className='flex items-center mt-1'>
                        <BiErrorCircle className='text-red-500' size={14} />
                        <span className='text-[10px] text-red-500 ml-1'>{error.stage}</span>
                      </div>}


                    {data?.stage?.label === 'Lead Generated' &&
                      <div className='bg-slate-50  p-[4px] mt-2'>
                        <h6 className='text-[11px] font-[500]'>Lead Generated would you like to create the contact for the Lead if you like to click on the below button</h6>

                        {console.log("fts_department", fts_department)}
                        <div className='flex items-center'>
                          {!fts_department &&
                            <h6 className='flex items-center mt-[5px] mr-2'>
                              <span className='cursor-pointer' onClick={() => setdata({ ...data, create_contact: !data.create_contact })}>{data?.create_contact ? <IoIosCheckbox size={20} className={`text-slate-700`} /> : <IoIosCheckboxOutline size={20} className={`text-slate-400`} />}</span>
                              <span className='text-[11px] ml-[4px] font-[600]'>Create Lead</span>
                            </h6>}

                          <h6 className='flex items-center mt-[5px]'>
                            <span className='cursor-pointer' onClick={() => { setdata({ ...data, fts_contact: !data.fts_contact }); setftsassign(true) }}>{data?.fts_contact ? <IoIosCheckbox size={20} className={`text-slate-700`} /> : <IoIosCheckboxOutline size={20} className={`text-slate-400`} />}</span>
                            <span className='text-[11px] ml-[4px] font-[600]'>Create FTS Lead</span>
                          </h6>
                        </div>
                      </div>}
                    <h6 className='text-[12px] font-semibold mb-1 mt-1'>Contact Source</h6>
                    <Select
                      value={data.contact_source}
                      error={error.contact_source}
                      placeholder=""
                      bordered={false}
                      onChange={(e) => handleSelect(e, 'Contact_Source')}
                      styles={customStyles}
                      options={contactSourceArr}
                      style={{ fontSize: '12px' }}
                      className='w-full border border-slate-300 h-[33px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                    />



                    <h6 className='text-[12px] font-semibold mb-1 mt-1'>Industry</h6>
                    <Select
                      value={data.industry}
                      error={error.industry}
                      placeholder=""
                      bordered={false}
                      onChange={(e) => handleSelect(e, 'Industry')}
                      styles={customStyles}
                      options={IndustyArr}
                      style={{ fontSize: '12px' }}
                      className='w-full border border-slate-300 h-[33px]   outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                    />


                    <TextAreaInput1
                      label={'Remarks'}
                      variant="standard"
                      name="remarks"
                      type="text"
                      value={data.remarks}
                      error={error.remarks}
                      handlechange={handlechange}
                      InputLabelProps={{
                        style: { color: '#fff', },
                      }} />

                    <TextInput
                      label={'Operator'}
                      variant="standard"
                      name="operator"
                      type="text"
                      value={data.operator}
                      error={error.operator}
                      handlechange={handlechange}
                      placeholder="Enter operator name"
                      InputLabelProps={{
                        style: { color: '#fff', },
                      }} />

                  </>








                </Grid>
              </Grid>
            </div>


            <div >
              <div className='flex items-center mt-5 mb-10  border-t pt-5'>
                <div className='mr-2'>
                  <ButtonOutlined btnName={'Back'} onClick={() => navigate(-1)} />
                </div>
                <div>
                  {loader ?
                    <ButtonFilled btnName={loader ? 'Loading' : 'Save'} /> :
                    <ButtonFilled btnName={loader ? 'Loading' : 'Save'} onClick={() => submitform(null)} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
export default CallsLeadCE;