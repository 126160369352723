import React, { useState, useEffect } from 'react'
import FTSLeadMenu from './FTSLeadMenu'
import { GetDepartmentService } from '../../services/DepartmentServices';
import { DatePicker, Select } from 'antd'
import { BiFilterAlt } from 'react-icons/bi'
import { RxReset } from "react-icons/rx";
import { GetDasboarddMontlyReviewReportService } from '../../services/ftsVerticalReportServices/FTSVerticalLeadReportServices';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { IconButton } from '@mui/material'

function FTSMonthlyReview() {

  const [search, setsearch] = useState({ from_date: '', to_date: '', from_date1: '', to_date1: '', department: '', })

  const [departments, setdepartments] = useState([])
  const [data1, setdata1] = useState({})
  const [filterApplied, setfilterApplied] = useState(false)

  const [page, setpage] = useState(1)
  const [page1, setpage1] = useState(1)
  const [page2, setpage2] = useState(1)

  const [step, setstep] = useState(1)

  const [chartOptions1, setchartOptions1] = useState({
    series: [],
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {

      bar: {
        horizontal: false,
        distributed: true,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },

      },
    },
    colors: ["#3a7aba", "#40ad75", "#d48d37", "#c42b8c", "#a61b2d"],
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#fafafa',
    },

    stroke: {
      width: 1,

      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      // categories: ['New Leads Generated Internally', 'No of Leads receieved by CRM/Corp/Social Media', 'No of leads shared by business units to other business units', 'No of new leads converted', 'No of New Leads Rejected'],
      categories: ['New Leads Generated', 'Leads Recieved (from CRM/FTS)', 'Leads Shared (other BU)', 'New Leads converted', 'Leads Rejected'],
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        }
      }
    },
    fill: ['#F44336', '#E91E63', '#9C27B0', '#E91E63', '#9C27B0']
  })




  // const [chartOptions1, setchartOptions1] = useState({
  //   series: [
  //     {
  //       name: "Leads",
  //       data: [],
  //     }
  //   ],
  //   chart: {
  //     toolbar: {
  //       show: false
  //     }
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       distributed: true,
  //       columnWidth: '50%',
  //       borderRadius: 6,
  //     },
  //   },
  //   colors: ["#3d85c6", "#3d85c6", "#3d85c6", "#3d85c6", "#3d85c6"],
  //   dataLabels: {
  //     enabled: true,
  //     formatter: function (val) {
  //       return val;
  //     },
  //     offsetY: -20,
  //     style: {
  //       fontSize: "14px",
  //       fontWeight: "bold",
  //       colors: ["#000"],
  //     },
  //     background: {
  //       enabled: true,
  //       foreColor: "#fff",
  //       padding: 6,
  //       borderRadius: 20,
  //       backgroundColor: "#fff",
  //     },
  //   },
  //   grid: {
  //     show: true,
  //     borderColor: '#eee',
  //   },
  //   stroke: {
  //     width: 2,
  //     colors: ['transparent']
  //   },
  //   xaxis: {
  //     categories: [
  //       'New Leads Generated',
  //       'Leads Received (from CRM/FTS)',
  //       'Leads Shared (other BU)',
  //       'New Leads Converted',
  //       'Leads Rejected'
  //     ],
  //     labels: {
  //       rotate: -45,
  //       style: {
  //         fontSize: "12px",
  //         fontWeight: "bold",
  //         colors: ["#333"]
  //       }
  //     }
  //   },
  //   yaxis: {
  //     title: {
  //       text: 'Count',
  //       style: {
  //         fontSize: "14px",
  //         fontWeight: "bold"
  //       }
  //     }
  //   },
  //   fill: {
  //     opacity: 0.9,
  //     type: "gradient",
  //     gradient: {
  //       shade: "light",
  //       type: "vertical",
  //       gradientToColors: ["#3d85c6", "#3d85c6", "#3d85c6", "#3d85c6", "#3d85c6"],
  //       stops: [0, 100]
  //     }
  //   },
  //   tooltip: {
  //     theme: "dark",
  //     y: {
  //       formatter: function (val) {
  //         return val + " leads";
  //       }
  //     }
  //   }
  // });






  useEffect(() => {
    getdepartment()
    getdata1()
  }, [page, page1, page2])

  async function getdepartment() {
    const response = await GetDepartmentService();
    let arr = []
    response.data.datas.forEach((d) => {
      if (d?.department_name !== 'Finance Team' && d?.department_name !== 'Software Team' && d?.department_name !== 'Digital Media' && d?.department_name !== 'Backend Team') {
        arr.push({ label: d?.department_name, value: d?.id })
      }
    })
    setdepartments(arr)
  }

  async function getdata1() {
    const response = await GetDasboarddMontlyReviewReportService(search?.from_date1, search?.to_date1, search?.department?.value, page, page1, page2)
    let d = response?.data?.data
    let form = [
      {
        name: 'count',
        data: [
          d?.count?.new_leads_totalData,
          d?.count?.lead_recieved_from_other_bu_totalData,
          d?.count?.lead_recieved_share_to_other_bu_totalData,
          (d?.count?.new_converted_leads_totalData + d?.count?.lead_recieved_from_other_bu_converted),
          (d?.count?.new_rejected_leads_totalData + d?.count?.lead_recieved_from_other_bu_rejected)
        ]
      }]

    console.log("form", form)
    setchartOptions1({ ...chartOptions1, series: [...form] })
    setdata1(response?.data?.data)
  }

  async function applyFilter() {
    const response = await GetDasboarddMontlyReviewReportService(search?.from_date1, search?.to_date1, search?.department?.value, page, page1, page2)
    let d = response?.data?.data
    setfilterApplied(true)

    let form = [
      {
        name: 'count',
        data: [
          d?.count?.new_leads_totalData,
          d?.count?.lead_recieved_from_other_bu_totalData,
          d?.count?.lead_recieved_share_to_other_bu_totalData,
          (d?.count?.new_converted_leads_totalData + d?.count?.lead_recieved_from_other_bu_converted),
          (d?.count?.new_rejected_leads_totalData + d?.count?.lead_recieved_from_other_bu_rejected)
        ]
      }]
    setchartOptions1({ ...chartOptions1, series: [...form] })
    setdata1(response?.data?.data)
  }

  async function resetfilter() {
    setsearch({ from_date: '', to_date: '', from_date1: '', to_date1: '', department: '', })
    setpage(1)
    setpage1(1)
    setpage2(1)
    const response = await GetDasboarddMontlyReviewReportService('', '', '', 1, 1, 1)
    setfilterApplied(false)
    let d = response?.data?.data
    let form = [
      {
        name: 'count',
        data: [
          d?.count?.new_leads_totalData,
          d?.count?.lead_recieved_from_other_bu_totalData,
          d?.count?.lead_recieved_share_to_other_bu_totalData,
          d?.count?.new_converted_leads_totalData,
          d?.count?.new_rejected_leads_totalData
        ]
      }]
    setchartOptions1({ ...chartOptions1, series: [...form] })
    setdata1(response?.data?.data)
  }



  return (
    <div className='h-screen max-h-screen'>
      <div className='flex'>
        <div className={`min-w-44`}>
          <FTSLeadMenu />
        </div>
        <div className="w-[98%] px-4 pt-4">
          <div className='md:w-[100%] flex flex-wrap md:flex-nowrap items-center -mt-1 border-b justify-between pb-1'>
            <h6 className='font-[800] text-[13px]'>Monthly Meeting Report  </h6>

            <div className='flex  items-center text-[12px]'>

              <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, from_date: v, from_date1: v1 }) }} />

              <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, to_date: v, to_date1: v1 }) }} />



              <div className='mr-2'>
                <Select
                  bordered={false}
                  placeholder={'Department'}
                  className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px] rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                  optionFilterProp="children"
                  onChange={(e) => setsearch({ ...search, department: departments?.find((f) => f?.value === e) })}
                  value={search?.department === '' ? null : search?.department}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={departments}
                />
              </div>

              <div className='ml-0 mr-1'>
                <BiFilterAlt onClick={() => applyFilter(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
              </div>
              <div className='ml-1 mr-2'>
                <RxReset onClick={resetfilter} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />
              </div>


            </div>
          </div>
          <div className='overflow-x-hidden h-[90vh] mb-[50px] overflow-y-scroll'>
            <div className='border  relative w-[100%] flex-1  mt-2 border-slate-100 px-3 py-2 relative'>
              <h1 className='text-[13px] font-[700]'>Total Report</h1>
              <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1?.series} type="bar" width={'100%'} height={240} />
            </div>

            {search?.department !== '' && filterApplied &&
              <div>


                <div className='flex items-center border-t border-b mt-2'>
                  <h6 onClick={() => setstep(1)} className={`text-[11px] px-2 py-1 font-[600] cursor-pointer ${step == 1 && 'bg-slate-700 text-white'}`}>Lead Generated</h6>
                  <h6 onClick={() => setstep(2)} className={`text-[11px] px-2 py-1 font-[600] cursor-pointer ${step == 2 && 'bg-slate-700 text-white'}`}>Lead Recieved</h6>
                  <h6 onClick={() => setstep(3)} className={`text-[11px] px-2 py-1 font-[600] cursor-pointer ${step == 3 && 'bg-slate-700 text-white'}`}>Lead Shared</h6>
                </div>

                {step == 1 &&
                  <>
                    {data1?.data?.new_leads_generated?.new_leads_generated?.length > 0 &&
                      <>
                        <div className='flex items-center justify-between'>
                          <h6 className='font-[800] text-[13px] mt-4'>Vertical Lead</h6>
                          <div>
                            <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                            <IconButton onClick={() => { page < data1?.data?.new_leads_generated?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data1?.data?.new_leads_generated?.pagination?.totalPages === page || data1?.data?.new_leads_generated?.new_leads_generated?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                          </div>
                        </div>
                        <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
                          <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                            <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[10%] px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600  border-r'>Designation</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[25%]  px-2 py-1 font-[600] text-slate-600  border-r'>Requirment</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[7%]  px-2 py-1 font-[600] text-slate-600 '>Status</h6>
                          </div>

                          {data1?.data?.new_leads_generated?.new_leads_generated?.map((d, i) => (
                            <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
                              <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1  border-r border-slate-200 '>{(page > 1 ? i + 1 + (data1?.data?.new_leads_generated?.pagination?.limit * (page - 1)) : i + 1)}</h6>
                              <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 border-r border-slate-200'>{d?.company}</h6>
                              <h6 className='text-[11px] font-[500] w-[10%]  px-2 py-1 font-[800] border-r border-slate-200'>{d?.name}</h6>
                              <h6 className='text-[11px] font-[500] w-[10%]  px-2 py-1 font-[800] border-r border-slate-200'>{d?.mobile}</h6>
                              <h6 className='text-[11px] w-[20%]  px-2 py-1  border-r border-slate-200'>{d?.email}</h6>
                              <h6 className='text-[11px] w-[10%]  px-2 py-1  border-r border-slate-200'>{d?.designation}</h6>
                              <h6 className='text-[12px] flex items-start break-word  font-[500] w-[25%] border-r px-2 py-1 border-slate-200 '>{d?.requirment}</h6>
                              <h6 className='text-[12px] flex items-start break-word  font-[500] w-[7%]  px-2 py-1 border-slate-200 '><span className='bg-slate-100 px-2 py-0.5 border-slate-400 border rounded text-[8px]'>{d?.status}</span></h6>
                            </div>))}
                        </div>
                      </>
                    }
                  </>}
                {step == 2 &&
                  <>
                    {data1?.data?.lead_recieved_from_other_bu?.lead_recieved_from_other_bu?.length > 0 &&
                      <>
                        <div className='flex items-center justify-between'>
                          <h6 className='font-[800] text-[13px] mt-4'>Lead Recieved From Other BU</h6>
                          <div>
                            <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                            <IconButton onClick={() => { page < data1?.data?.lead_recieved_from_other_bu?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data1?.data?.lead_recieved_from_other_bu?.pagination?.totalPages === page || data1?.data?.lead_recieved_from_other_bu?.lead_recieved_from_other_bu?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                          </div>
                        </div>
                        <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
                          <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                            <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person Info</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Created Department</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Assigned Department</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600  border-r'>Remarks</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 '>Stage</h6>
                          </div>

                          {data1?.data?.lead_recieved_from_other_bu?.lead_recieved_from_other_bu?.map((d, i) => (
                            <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
                              <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1 border-r border-slate-200 '>{page === 1 ? i + 1 : (i + 1) + ((page - 1) * data1?.data?.lead_recieved_from_other_bu?.pagination?.limit)}</h6>
                              <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 border-r border-slate-200'>{d?.fts_lead?.company_name}
                                {d?.zone !== undefined && <h6 className='text-[10px]'>Zone : {d?.zone}</h6>}</h6>
                              <h6 className='text-[12px] font-[500] w-[15%] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                                <div>
                                  <h6>{d?.fts_lead?.contact_name}</h6>
                                  <span>{d?.fts_lead?.phone} / {d?.fts_lead?.email}  </span>
                                </div>
                              </h6>
                              <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
                                {d?.fts_lead?.lead_owner_department?.department_name}
                                <h6 className='text-[10px]'>Created By : {d?.fts_lead?.lead_owner?.name}</h6>
                                <h6 className='text-[10px] font-[500]'>{d?.fts_lead?.previously_handled_by}</h6>
                              </h6>
                              <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
                                <span className='bg-slate-100 px-2 py-1 rounded text-[10px]'>{d?.department_id?.department_name}</span>
                              </h6>
                              <h6 className='text-[11px]  w-[15%]  px-2 py-1  border-r border-slate-200'>
                                Requirment :  <span className='font-[800]'>{d?.requirment}</span>  /
                                Remarks :  <span className='font-[800]'>{d?.remarks}</span>

                                {/* {d?.converted == '1' && <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>} */}

                              </h6>
                              <h6 className='text-[12px]  flex items-start break-word  font-[500] w-[10%] px-2 py-1 border-slate-200 '>
                                <span className='text-[11px] font-[600] rounded'>{d?.converted === '5' && <span className='bg-orange-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '5' && 'Lead Generated'}</span>} {d?.converted === '1' && <span className='bg-green-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '1' && 'Converted'}</span>} {d?.converted === '2' && <span className='bg-red-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '2' && 'Rejected'}</span>} {d?.converted === '3' && <span className='bg-yellow-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '3' && 'Pending'}</span>} {d?.converted === '4' && <span className='bg-blue-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '4' && 'Progress'}</span>}</span>
                              </h6>

                            </div>))}
                        </div>
                      </>
                    }
                  </>}
                {step == 3 &&
                  <>
                    {data1?.data?.lead_recieved_share_to_other_bu?.lead_recieved_share_to_other_bu?.length > 0 &&
                      <>
                        <div className='flex items-center justify-between'>

                          <h6 className='font-[800] text-[13px] mt-4'>Lead Shared To Other BU</h6>
                          <div>
                            <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                            <IconButton onClick={() => { page < data1?.data?.lead_recieved_share_to_other_bu?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data1?.data?.lead_recieved_share_to_other_bu?.pagination?.totalPages === page || data1?.data?.lead_recieved_share_to_other_bu?.lead_recieved_share_to_other_bu?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                          </div>
                        </div>
                        <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                          <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                            <h6 className='top-0 bg-white z-50 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Info</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Recieved Department</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[180px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Converted</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[180px] px-2 py-1 font-[600] text-slate-600 border-r'>Summary</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 '>Created On</h6>
                          </div>

                          {data1?.data?.lead_recieved_share_to_other_bu?.lead_recieved_share_to_other_bu?.map((d, i) => (
                            <div key={d?._id} className={`flex z-50 h-full border-b border-slate-200 z-0`}  >
                              <h6 className='sticky top-0 z-30 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex items-center justify-center'>{page === 1 ? i + 1 : (i + 1) + ((page - 1) * data1?.data?.lead_recieved_share_to_other_bu?.pagination?.limit)}</h6>
                              <h6 className='text-[12px] font-[500] w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d?.fts_lead?.company_name}</h6>
                              <h6 className='text-[12px] font-[500] w-[180px] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                                <div>
                                  <h6>{d?.fts_lead?.contact_name}</h6>
                                  <span>{d?.fts_lead?.phone} / {d?.fts_lead?.email}  </span>
                                </div>
                              </h6>
                              <h6 className='text-[12px] font-[500] w-[150px] px-2 py-1 break-all border-r border-slate-200'>
                                <span className='text-sky-500 text-[11px] font-[600]'>{d?.department_id?.department_name}</span>
                              </h6>
                              <h6 className={`text-[12px] font-[500] w-[180px] px-2 py-1  truncate  border-r  border-slate-200 ${d?.requirment ? 'block' : 'text-[#fff]'}`}>{d?.requirment ? d?.requirment : 'Not Added'}</h6>
                              <h6 className='text-[12px] text-center font-[500] w-[80px] px-2 py-1 truncate border-r  border-slate-200'>{d?.converted === '5' && <span className='bg-orange-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '5' && 'Lead Generated'}</span>} {d?.converted === '1' && <span className='bg-green-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '1' && 'Converted'}</span>} {d?.converted === '2' && <span className='bg-red-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '2' && 'Rejected'}</span>} {d?.converted === '3' && <span className='bg-yellow-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '3' && 'Pending'}</span>} {d?.converted === '4' && <span className='bg-blue-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '4' && 'Progress'}</span>} </h6>
                              <h6 className={`text-[12px] font-[500] w-[180px] px-2 py-1 truncate border-r  border-slate-200 ${d?.remarks ? 'block' : 'text-[#fff]'}`}>{d?.remarks ? d?.remarks : 'Not Added'}</h6>

                              <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>

                            </div>))}
                        </div>
                      </>
                    }
                  </>}
              </div>}
          </div>

        </div>
      </div>
    </div>
  )
}

export default FTSMonthlyReview








///////////=================================================================>>>>> Old
// import React, { useState, useEffect } from 'react'
// import FTSLeadMenu from './FTSLeadMenu'
// import { GetDepartmentService } from '../../services/DepartmentServices';
// import { DatePicker, Select } from 'antd'
// import { BiFilterAlt } from 'react-icons/bi'
// import { RxReset } from "react-icons/rx";
// import { GetDasboarddMontlyReviewReportService } from '../../services/ftsVerticalReportServices/FTSVerticalLeadReportServices';
// import ReactApexChart from 'react-apexcharts';
// import moment from 'moment';
// import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
// import { IconButton } from '@mui/material'

// function FTSMonthlyReview() {

//   const [search, setsearch] = useState({ from_date: '', to_date: '', from_date1: '', to_date1: '', department: '', })

//   const [departments, setdepartments] = useState([])
//   const [data1, setdata1] = useState({})
//   const [filterApplied, setfilterApplied] = useState(false)

//   const [page, setpage] = useState(1)
//   const [page1, setpage1] = useState(1)
//   const [page2, setpage2] = useState(1)

//   const [step, setstep] = useState(1)

//   const [chartOptions1, setchartOptions1] = useState({
//     series: [],
//     chart: {
//       toolbar: {
//         show: false
//       }
//     },
//     plotOptions: {

//       bar: {
//         horizontal: false,
//         distributed: true,
//         columnWidth: '55%',
//         endingShape: 'rounded',
//         dataLabels: {
//           position: 'top', // top, center, bottom
//         },

//       },
//     },
//     colors: ["#3a7aba", "#40ad75", "#d48d37", "#c42b8c", "#a61b2d"],
//     dataLabels: {
//       enabled: false
//     },
//     grid: {
//       show: true,
//       borderColor: '#fafafa',
//     },

//     stroke: {
//       width: 1,

//       curve: 'smooth',
//       colors: ['transparent']
//     },
//     xaxis: {
//       // categories: ['New Leads Generated Internally', 'No of Leads receieved by CRM/Corp/Social Media', 'No of leads shared by business units to other business units', 'No of new leads converted', 'No of New Leads Rejected'],
//       categories: ['New Leads Generated', 'Leads Recieved (from CRM/FTS)', 'Leads Shared (other BU)', 'New Leads converted', 'Leads Rejected'],
//     },
//     yaxis: {
//       title: {
//         text: 'Count'
//       }
//     },
//     fill: {
//       opacity: 1
//     },
//     tooltip: {
//       y: {
//         formatter: function (val) {
//           return val
//         }
//       }
//     },
//     fill: ['#F44336', '#E91E63', '#9C27B0', '#E91E63', '#9C27B0']
//   })

//   useEffect(() => {
//     getdepartment()
//     getdata1()
//   }, [page, page1, page2])

//   async function getdepartment() {
//     const response = await GetDepartmentService();
//     let arr = []
//     response.data.datas.forEach((d) => {
//       if (d?.department_name !== 'Finance Team' && d?.department_name !== 'Software Team' && d?.department_name !== 'Digital Media' && d?.department_name !== 'Backend Team') {
//         arr.push({ label: d?.department_name, value: d?.id })
//       }
//     })
//     setdepartments(arr)
//   }

//   async function getdata1() {
//     const response = await GetDasboarddMontlyReviewReportService(search?.from_date1, search?.to_date1, search?.department?.value, page, page1, page2)
//     let d = response?.data?.data
//     let form = [
//       {
//         name: 'count',
//         data: [
//           d?.count?.new_leads_totalData,
//           d?.count?.lead_recieved_from_other_bu_totalData,
//           d?.count?.lead_recieved_share_to_other_bu_totalData,
//           (d?.count?.new_converted_leads_totalData + d?.count?.lead_recieved_from_other_bu_converted),
//           (d?.count?.new_rejected_leads_totalData + d?.count?.lead_recieved_from_other_bu_rejected)
//         ]
//       }]

//     console.log("form", form)
//     setchartOptions1({ ...chartOptions1, series: [...form] })
//     setdata1(response?.data?.data)
//   }

//   async function applyFilter() {
//     const response = await GetDasboarddMontlyReviewReportService(search?.from_date1, search?.to_date1, search?.department?.value, page, page1, page2)
//     let d = response?.data?.data
//     setfilterApplied(true)

//     let form = [
//       {
//         name: 'count',
//         data: [
//           d?.count?.new_leads_totalData,
//           d?.count?.lead_recieved_from_other_bu_totalData,
//           d?.count?.lead_recieved_share_to_other_bu_totalData,
//           (d?.count?.new_converted_leads_totalData + d?.count?.lead_recieved_from_other_bu_converted),
//           (d?.count?.new_rejected_leads_totalData + d?.count?.lead_recieved_from_other_bu_rejected)
//         ]
//       }]
//     setchartOptions1({ ...chartOptions1, series: [...form] })
//     setdata1(response?.data?.data)
//   }

//   async function resetfilter() {
//     setsearch({ from_date: '', to_date: '', from_date1: '', to_date1: '', department: '', })
//     setpage(1)
//     setpage1(1)
//     setpage2(1)
//     const response = await GetDasboarddMontlyReviewReportService('', '', '', 1, 1, 1)
//     setfilterApplied(false)
//     let d = response?.data?.data
//     let form = [
//       {
//         name: 'count',
//         data: [
//           d?.count?.new_leads_totalData,
//           d?.count?.lead_recieved_from_other_bu_totalData,
//           d?.count?.lead_recieved_share_to_other_bu_totalData,
//           d?.count?.new_converted_leads_totalData,
//           d?.count?.new_rejected_leads_totalData
//         ]
//       }]
//     setchartOptions1({ ...chartOptions1, series: [...form] })
//     setdata1(response?.data?.data)
//   }



//   return (
//     <div className='h-screen max-h-screen'>
//       <div className='flex'>
//         <div className={`min-w-44`}>
//           <FTSLeadMenu />
//         </div>
//         <div className="w-[98%] px-4 pt-4">
//           <div className='md:w-[100%] flex flex-wrap md:flex-nowrap items-center -mt-1 border-b justify-between pb-1'>
//             <h6 className='font-[800] text-[13px]'>Monthly Meeting Report  </h6>

//             <div className='flex  items-center text-[12px]'>

//               <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, from_date: v, from_date1: v1 }) }} />

//               <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, to_date: v, to_date1: v1 }) }} />



//               <div className='mr-2'>
//                 <Select
//                   bordered={false}
//                   placeholder={'Department'}
//                   className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px] rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
//                   optionFilterProp="children"
//                   onChange={(e) => setsearch({ ...search, department: departments?.find((f) => f?.value === e) })}
//                   value={search?.department === '' ? null : search?.department}
//                   filterOption={(input, option) =>
//                     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
//                   }
//                   options={departments}
//                 />
//               </div>

//               <div className='ml-0 mr-1'>
//                 <BiFilterAlt onClick={() => applyFilter(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
//               </div>
//               <div className='ml-1 mr-2'>
//                 <RxReset onClick={resetfilter} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />
//               </div>


//             </div>
//           </div>
//           <div className='overflow-x-hidden h-[90vh] mb-[50px] overflow-y-scroll'>
//             <div className='border  relative w-[100%] flex-1  mt-2 border-slate-100 px-3 py-2 relative'>
//               <h1 className='text-[13px] font-[700]'>Total Report</h1>
//               <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1?.series} type="bar" width={'100%'} height={240} />
//             </div>

//             {search?.department !== '' && filterApplied &&
//               <div>


//                 <div className='flex items-center border-t border-b mt-2'>
//                   <h6 onClick={() => setstep(1)} className={`text-[11px] px-2 py-1 font-[600] cursor-pointer ${step == 1 && 'bg-slate-700 text-white'}`}>Lead Generated</h6>
//                   <h6 onClick={() => setstep(2)} className={`text-[11px] px-2 py-1 font-[600] cursor-pointer ${step == 2 && 'bg-slate-700 text-white'}`}>Lead Recieved</h6>
//                   <h6 onClick={() => setstep(3)} className={`text-[11px] px-2 py-1 font-[600] cursor-pointer ${step == 3 && 'bg-slate-700 text-white'}`}>Lead Shared</h6>
//                 </div>

//                 {step == 1 &&
//                   <>
//                     {data1?.data?.new_leads_generated?.new_leads_generated?.length > 0 &&
//                       <>
//                         <div className='flex items-center justify-between'>
//                           <h6 className='font-[800] text-[13px] mt-4'>Vertical Lead</h6>
//                           <div>
//                             <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                             <IconButton onClick={() => { page < data1?.data?.new_leads_generated?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data1?.data?.new_leads_generated?.pagination?.totalPages === page || data1?.data?.new_leads_generated?.new_leads_generated?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                           </div>
//                         </div>
//                         <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
//                           <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
//                             <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[10%] px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600  border-r'>Designation</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[25%]  px-2 py-1 font-[600] text-slate-600  border-r'>Requirment</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[7%]  px-2 py-1 font-[600] text-slate-600 '>Status</h6>
//                           </div>

//                           {data1?.data?.new_leads_generated?.new_leads_generated?.map((d, i) => (
//                             <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
//                               <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1  border-r border-slate-200 '>{(page > 1 ? i + 1 + (data1?.data?.new_leads_generated?.pagination?.limit * (page - 1)) : i + 1)}</h6>
//                               <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 border-r border-slate-200'>{d?.company}</h6>
//                               <h6 className='text-[11px] font-[500] w-[10%]  px-2 py-1 font-[800] border-r border-slate-200'>{d?.name}</h6>
//                               <h6 className='text-[11px] font-[500] w-[10%]  px-2 py-1 font-[800] border-r border-slate-200'>{d?.mobile}</h6>
//                               <h6 className='text-[11px] w-[20%]  px-2 py-1  border-r border-slate-200'>{d?.email}</h6>
//                               <h6 className='text-[11px] w-[10%]  px-2 py-1  border-r border-slate-200'>{d?.designation}</h6>
//                               <h6 className='text-[12px] flex items-start break-word  font-[500] w-[25%] border-r px-2 py-1 border-slate-200 '>{d?.requirment}</h6>
//                               <h6 className='text-[12px] flex items-start break-word  font-[500] w-[7%]  px-2 py-1 border-slate-200 '><span className='bg-slate-100 px-2 py-0.5 border-slate-400 border rounded text-[8px]'>{d?.status}</span></h6>
//                             </div>))}
//                         </div>
//                       </>
//                     }
//                   </>}
//                 {step == 2 &&
//                   <>
//                     {data1?.data?.lead_recieved_from_other_bu?.lead_recieved_from_other_bu?.length > 0 &&
//                       <>
//                         <div className='flex items-center justify-between'>
//                           <h6 className='font-[800] text-[13px] mt-4'>Lead Recieved From Other BU</h6>
//                           <div>
//                             <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                             <IconButton onClick={() => { page < data1?.data?.lead_recieved_from_other_bu?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data1?.data?.lead_recieved_from_other_bu?.pagination?.totalPages === page || data1?.data?.lead_recieved_from_other_bu?.lead_recieved_from_other_bu?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                           </div>
//                         </div>
//                         <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
//                           <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
//                             <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person Info</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Created Department</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Assigned Department</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600  border-r'>Remarks</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 '>Stage</h6>
//                           </div>

//                           {data1?.data?.lead_recieved_from_other_bu?.lead_recieved_from_other_bu?.map((d, i) => (
//                             <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
//                               <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1 border-r border-slate-200 '>{page === 1 ? i + 1 : (i + 1) + ((page - 1) * data1?.data?.lead_recieved_from_other_bu?.pagination?.limit)}</h6>
//                               <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 border-r border-slate-200'>{d?.fts_lead?.company_name}
//                                 {d?.zone !== undefined && <h6 className='text-[10px]'>Zone : {d?.zone}</h6>}</h6>
//                               <h6 className='text-[12px] font-[500] w-[15%] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
//                                 <div>
//                                   <h6>{d?.fts_lead?.contact_name}</h6>
//                                   <span>{d?.fts_lead?.phone} / {d?.fts_lead?.email}  </span>
//                                 </div>
//                               </h6>
//                               <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
//                                 {d?.fts_lead?.lead_owner_department?.department_name}
//                                 <h6 className='text-[10px]'>Created By : {d?.fts_lead?.lead_owner?.name}</h6>
//                                 <h6 className='text-[10px] font-[500]'>{d?.fts_lead?.previously_handled_by}</h6>
//                               </h6>
//                               <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
//                                 <span className='bg-slate-100 px-2 py-1 rounded text-[10px]'>{d?.department_id?.department_name}</span>
//                               </h6>
//                               <h6 className='text-[11px]  w-[15%]  px-2 py-1  border-r border-slate-200'>
//                                 Requirment :  <span className='font-[800]'>{d?.requirment}</span>  /
//                                 Remarks :  <span className='font-[800]'>{d?.remarks}</span>

//                                 {/* {d?.converted == '1' && <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>} */}

//                               </h6>
//                               <h6 className='text-[12px]  flex items-start break-word  font-[500] w-[10%] px-2 py-1 border-slate-200 '>
//                                 <span className='text-[11px] font-[600] rounded'> {d?.converted === '1' && <span className='bg-green-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '1' && 'Converted'}</span>} {d?.converted === '2' && <span className='bg-red-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '2' && 'Rejected'}</span>} {d?.converted === '3' && <span className='bg-yellow-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '3' && 'Pending'}</span>} {d?.converted === '4' && <span className='bg-blue-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '4' && 'Progress'}</span>}</span>
//                               </h6>

//                             </div>))}
//                         </div>
//                       </>
//                     }
//                   </>}
//                 {step == 3 &&
//                   <>
//                     {data1?.data?.lead_recieved_share_to_other_bu?.lead_recieved_share_to_other_bu?.length > 0 &&
//                       <>
//                         <div className='flex items-center justify-between'>

//                           <h6 className='font-[800] text-[13px] mt-4'>Lead Shared To Other BU</h6>
//                           <div>
//                             <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                             <IconButton onClick={() => { page < data1?.data?.lead_recieved_share_to_other_bu?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data1?.data?.lead_recieved_share_to_other_bu?.pagination?.totalPages === page || data1?.data?.lead_recieved_share_to_other_bu?.lead_recieved_share_to_other_bu?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                           </div>
//                         </div>
//                         <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
//                           <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
//                             <h6 className='top-0 bg-white z-50 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Info</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Recieved Department</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[180px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Converted</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[180px] px-2 py-1 font-[600] text-slate-600 border-r'>Summary</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 '>Created On</h6>
//                           </div>

//                           {data1?.data?.lead_recieved_share_to_other_bu?.lead_recieved_share_to_other_bu?.map((d, i) => (
//                             <div key={d?._id} className={`flex z-50 h-full border-b border-slate-200 z-0`}  >
//                               <h6 className='sticky top-0 z-30 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex items-center justify-center'>{page === 1 ? i + 1 : (i + 1) + ((page - 1) * data1?.data?.lead_recieved_share_to_other_bu?.pagination?.limit)}</h6>
//                               <h6 className='text-[12px] font-[500] w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d?.fts_lead?.company_name}</h6>
//                               <h6 className='text-[12px] font-[500] w-[180px] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
//                                 <div>
//                                   <h6>{d?.fts_lead?.contact_name}</h6>
//                                   <span>{d?.fts_lead?.phone} / {d?.fts_lead?.email}  </span>
//                                 </div>
//                               </h6>
//                               <h6 className='text-[12px] font-[500] w-[150px] px-2 py-1 break-all border-r border-slate-200'>
//                                 <span className='text-sky-500 text-[11px] font-[600]'>{d?.department_id?.department_name}</span>
//                               </h6>
//                               <h6 className={`text-[12px] font-[500] w-[180px] px-2 py-1  truncate  border-r  border-slate-200 ${d?.requirment ? 'block' : 'text-[#fff]'}`}>{d?.requirment ? d?.requirment : 'Not Added'}</h6>
//                               <h6 className='text-[12px] text-center font-[500] w-[80px] px-2 py-1 truncate border-r  border-slate-200'> {d?.converted === '1' && <span className='bg-green-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '1' && 'Converted'}</span>} {d?.converted === '2' && <span className='bg-red-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '2' && 'Rejected'}</span>} {d?.converted === '3' && <span className='bg-yellow-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '3' && 'Pending'}</span>} {d?.converted === '4' && <span className='bg-blue-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '4' && 'Progress'}</span>} </h6>
//                               <h6 className={`text-[12px] font-[500] w-[180px] px-2 py-1 truncate border-r  border-slate-200 ${d?.remarks ? 'block' : 'text-[#fff]'}`}>{d?.remarks ? d?.remarks : 'Not Added'}</h6>

//                               <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>

//                             </div>))}
//                         </div>
//                       </>
//                     }
//                   </>}
//               </div>}
//           </div>

//         </div>
//       </div>
//     </div>
//   )
// }

// export default FTSMonthlyReview