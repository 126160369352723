import React, { useState, useEffect } from 'react';
import FTSLeadMenu from './FTSLeadMenu';
import ReactApexChart from 'react-apexcharts';
import { GetVericalBasedAssignedRecievedService } from '../../services/ftsVerticalReportServices/FTSVerticalLeadReportServices';
import { GetDepartmentService } from '../../services/DepartmentServices';
import Priceconstants from '../../constants/imageConstants';
import { DatePicker, Select } from 'antd';
import { BiFilterAlt } from 'react-icons/bi'
import { BsArrowRepeat } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { SearchUser } from '../../services/AuthServices';


function FTSleadsAnalytics() {

  const navigate = useNavigate()
  const [department, setdepartment] = useState([])


  const [search, setsearch] = useState({ from_date: '', to_date: '', from_date1: '', to_date1: '', assigned_department: '', recieved_department: '', user: '' });
  const [data1, setdata1] = useState({})
  const [users,setusers] = useState([])


  const [page, setpage] = useState(1)

  useEffect(() => {
    getdepartment()
    getdata1()
  }, [page])

  async function getdata1() {
    const response = await GetVericalBasedAssignedRecievedService(search?.from_date1, search?.to_date1, search?.assigned_department?.value, search?.recieved_department?.value, page,search?.user)
    setdata1(response?.data?.data)
  }

  function handlechange(e){
    setsearch({...search,user:users.find((f)=>f.value == e)})
  }

  async function getdepartment() {
    const response = await GetDepartmentService()
    let d = response?.data?.datas

    let arr = []
    let transaction = d?.find((f) => f?.department_name === "Transaction Team")
    let project = d?.find((f) => f?.department_name === "Project Team")
    let hr = d?.find((f) => f?.department_name === "HR Team")
    let fms = d?.find((f) => f?.department_name === "FMS Team")
    let fidelitus_gallery = d?.find((f) => f?.department_name === "Fidelitus Gallery")
    let shilpa_foundation = d?.find((f) => f?.department_name === "Shilpa Foundation")

    if (![null, undefined, '', 'null', 'undefined']?.includes(transaction)) {
      arr.push(transaction)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(project)) {
      arr.push(project)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(hr)) {
      arr.push(hr)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(fms)) {
      arr.push(fms)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(fidelitus_gallery)) {
      arr.push(fidelitus_gallery)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(shilpa_foundation)) {
      arr.push(shilpa_foundation)
    }
    setdepartment(arr)
  }

  const teams = [
    "Transaction Team",
    "Project Team",
    "HR Team",
    "FMS Team",
    "Fidelitus Gallery",
    "Shilpa Foundation",
  ];

  const returnTotalLeads = 0


  const seriesData = teams.map((team) => getLeadCount(team));


  const leadsChartOptions = {
    chart: { type: 'donut' },
    labels: teams,
    colors: ["#FF4560", "#008FFB", "#00E396", "#FEB019", "#775DD0", "#546E7A"],
    dataLabels: { enabled: false },
    legend: { show: false }
  };


  const teamNames = [
    "Transaction Team",
    "Project Team",
    "HR Team",
    "FMS Team",
    "Fidelitus Gallery",
    "Shilpa Foundation"
  ];

  function numDifferentiation(value) {
    const val = Math.abs(value)
    if (val >= 10000000) return `${(value / 10000000).toFixed(0)} Cr`
    if (val >= 100000) return `${(value / 100000).toFixed(0)} Lac`
    return value;
  }

  const estimationValuesforChart = teamNames.map((team) => getEstiamtedRevenue(team));
  const actualEstimationValuessforChart = teamNames.map((team) => getActualRevenue(team));

  const barChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: { show: false }
    },
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {

      bar: {
        horizontal: false,
        dataLabels: {
          enabled: false
        },
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },

      },
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#fafafa',
    },

    stroke: {
      show: true,
      width: 1,
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Transaction', 'Project', 'HR Labs', 'FMS', 'Fidelitus Gallery', 'Shilpa Foundation'],
    },
    yaxis: {
      title: {
        text: 'Estimation / Actual Forecast'
      },
      labels: {
        formatter: function (val) {
          return numDifferentiation(val)
        }
      }
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return numDifferentiation(val)
        }
      }
    },
  };

  //barchart end 
  function getLeadCount(v) {
    if (data1.length > 0) {
      let findData = data1?.find((f) => f?._id?.department_name == v)
      if (![null, undefined, 'null', 'undefined']?.includes(findData)) {
        return findData?.count
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  function getEstiamtedRevenue(v) {
    if (data1.length > 0) {
      let findData = data1?.find((f) => f?._id?.department_name == v)
      if (![null, undefined, 'null', 'undefined']?.includes(findData)) {
        return findData?.estimated_revenue
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  function getActualRevenue(v) {
    if (data1.length > 0) {
      let findData = data1?.find((f) => f?._id?.department_name == v)
      if (![null, undefined, 'null', 'undefined']?.includes(findData)) {
        return findData?.actual_revenue_achieved
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  function retutnTotalValue(type) {
    if (data1.length > 0) {
      let total = 0
      if (type == 'leads') {
        total += data1?.reduce((a, d) => a + d?.count, 0)
        return total
      }

      if (type == 'estimated_revenue') {
        total += data1?.reduce((a, d) => a + d?.estimated_revenue, 0)
        return total
      }

      if (type == 'actual_revenue_achieved') {
        total += data1?.reduce((a, d) => a + d?.actual_revenue_achieved, 0)
        return total
      }
    } else {
      return 0
    }
  }

  async function onSearch(v){
    const response = await SearchUser(v)
    let arr = []
    response?.data?.forEach((d)=>{
      arr.push({value:d?._id,label:d?.name})
    })
    setusers(arr)
  }

  async function applyfilterfunction() {
    const response = await GetVericalBasedAssignedRecievedService(search?.from_date1, search?.to_date1, search?.assigned_department?.value, search?.recieved_department?.value, page,search?.user)
    setdata1(response?.data?.data)
  }

  async function resetfilterfunction() {
    setsearch({ from_date: '', to_date: '', from_date1: '', to_date1: '', assigned_department: '', recieved_department: '',user:'' })
    setpage(1)
    const response = await GetVericalBasedAssignedRecievedService('', '', '', '', 1,'')
    setdata1(response?.data?.data)

  }

  async function navigateDetail(d) {
    let findData = department?.find((f) => f?.department_name == d)
    let findData1 = users?.find((f) => f?.value == search?.user)
    if (findData && findData1) {
      navigate('detail', { state: { ...search, recieved_department: { label: findData?.department_name, value: findData?.id },user:findData1 } })
    }else if(findData){
      navigate('detail', { state: { ...search, recieved_department: { label: findData?.department_name, value: findData?.id },user:'' } })
    }
  }



  return (
    <div className="flex bg-white">
      <div className="min-w-44">
        <FTSLeadMenu />
      </div>

      <div className="p-3 bg-white w-full h-full text-black">
        <div className="mb-3  border-b flex justify-between items-center p-2">
          <h2 className="text-sm font-extrabold text-gray-700">FTS Leads Analytics</h2>

          <div className='flex items-center'>
            <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, from_date: v, from_date1: v1 }) }} />
            <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, to_date: v, to_date1: v1 }) }} />

            <div className='mx-1  h-[30px] rounded-md border border-slate-300 py-[2px]'>
                <Select
                   showSearch
                   bordered={false}
                   placeholder="Search User"
                   optionFilterProp="children"
                   onChange={(v)=>setsearch({...search,user:v})}
                   onSearch={onSearch}
                   value={search?.user === '' ? null : search?.user}
                   style={{ width: 110,height:25,marginTop:'-15px' }}
                   allowClear
                   onClear={()=>setsearch({...search,user:''})}
                   filterOption={false}
                   options={users}
                />
              </div>
                
            <BiFilterAlt onClick={() => applyfilterfunction(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
            <BsArrowRepeat onClick={() => resetfilterfunction(1)} size={25} className='bg-slate-200 p-[5px]  text-black cursor-pointer rounded ml-1' />
          </div>

        </div>

        <div className="overflow-x-auto">
          <div className="w-full border text-sm text-left">

            <div className="flex items-center text-slate-600 text-[11.5px] font-[700]">
              <h6 className="p-1 w-[15%] text-slate-600">(FTS/CORP/BACKEND)</h6>
              {department?.map((d) => (
                <h6 key={d?._id} className="p-1 border-l text-slate-600 uppercase min-w-[14.33%] max-w-[14.33%] text-center">{d?.department_name}</h6>
              ))}
            </div>

            <div>
              <div className="border-t flex  items-center">
                <h6 className="p-1 text-[13px] text-gray-700  font-bold w-[15%]">Leads</h6>
                <h6 onClick={() => navigateDetail('Transaction Team')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('Transaction Team')}</h6>
                <h6 onClick={() => navigateDetail('Project Team')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('Project Team')}</h6>
                <h6 onClick={() => navigateDetail('HR Team')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('HR Team')}</h6>
                <h6 onClick={() => navigateDetail('FMS Team')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('FMS Team')}</h6>

                <h6 onClick={() => navigateDetail('Fidelitus Gallery')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('Fidelitus Gallery')}</h6>
                <h6 onClick={() => navigateDetail('Shilpa Foundation')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('Shilpa Foundation')}</h6>
              </div>

              <div className="border-t flex  items-center">
                <h6 className="p-1 text-[13px] text-gray-700  font-bold w-[15%]">Estimated Revenue</h6>
                <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('Transaction Team'))}</h6>
                <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('Project Team'))}</h6>
                <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('HR Team'))}</h6>
                <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('FMS Team'))}</h6>
                <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('Fidelitus Gallery'))}</h6>
                <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('Shilpa Foundation'))}</h6>
              </div>

              <div className="border-t flex  items-center">
                <h6 className="p-1 text-[13px] text-gray-700  font-bold w-[15%]">Actual Revenue</h6>
                <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('Transaction Team'))}</h6>
                <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('Project Team'))}</h6>
                <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('HR Team'))}</h6>
                <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('FMS Team'))}</h6>
                <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('Fidelitus Gallery'))}</h6>
                <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('Shilpa Foundation'))}</h6>
              </div>



            </div>
          </div>
        </div>

        <div className="flex mt-4 justify-between items-start">
          <div className="flex-[0.3] relative h-[280px] border mr-2 border-slate-200">
            <h6 className='font-[800] text-[13px] px-2 pt-2 text-gray-700' >Lead Forecast</h6>
            <div className='absolute top-[140px] left-[100px] z-50' >
              <h6 className='font-[800] text-center text-[11px]'>Total Leads Assigned</h6>
              <h6 className='text-center text-blue-600 font-[900]'>{retutnTotalValue("leads")}</h6>
            </div>
            <ReactApexChart options={leadsChartOptions} series={seriesData} type="donut" width={320} />
          </div>
          <div className="flex-[0.7] h-[280px] border border-slate-200">

            <h6 className='font-[800] text-[13px] px-2 pt-2 text-gray-700'>Revenue Forecast</h6>
            <ReactApexChart
              options={barChartOptions}
              series={[
                { name: "Estimation Revenue", data: estimationValuesforChart },
                { name: "Actual Revenue", data: actualEstimationValuessforChart }
              ]}
              type="bar"
              width={'100%'}
              height={250}
            />
          </div>
        </div>

        <div className="flex mt-4 justify-between items-start">
          <div className='w-[30%]'>

            <h6 className='font-[700] text-[13px] border-b pb-1 mb-1 text-gray-700'>Total Analytics Report</h6>
            <div className='flex border'>
              <div className='w-[50%]'>
                <h6 className="p-1 text-[13px] border-r text-slate-600 font-bold">Leads</h6>
                <h6 className="p-1 text-[13px] border-r border-t text-slate-600 font-bold">Estimated Revenue</h6>
                <h6 className="p-1 text-[13px] border-r border-t text-slate-600 font-bold">Actual Revenue</h6>
              </div>
              <div className='w-[50%]'>
                <h6 className="p-1 text-[13px] text-blue-600 font-bold">{retutnTotalValue('leads')}</h6>
                <h6 className="p-1 text-[13px] border-t font-bold">{Priceconstants(retutnTotalValue('estimated_revenue'))}</h6>
                <h6 className="p-1 text-[13px] border-t font-bold">{Priceconstants(retutnTotalValue('actual_revenue_achieved'))}</h6>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default FTSleadsAnalytics;

