import { get } from "../../helpers/apihelpers";


// create lead getleads ,getdetaillead,updatelead
export const GetCompanyLeadsService=async(department,stage,user,page)=>{
    
    try {
        const result = await get(`api/lead/getallleads/${department}/${stage}/${user}/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadCompanyLeadsService=async(department,stage,user,page)=>{
    
    try {
        const result = await get(`api/lead/getallleads_excel/${department}/${stage}/${user}/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetTeamMembersLeadsService=async(department,stage,user,page)=>{
    try {
        const result = await get(`api/lead/getteamleads/${department}/${stage}/${user}/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SearchCompanyLeadService = async({search_text,status,from_date,to_date,page,department_id,employee_id})=>{
    try {
        const result = await get(`api/lead/search?search_text=${search_text}&status=${status}&department_id=${department_id}&employee_id=${employee_id}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const SearchAllCompanyLeadService = async({search_text,status,from_date,to_date,page,department_id,employee_id})=>{
    try {
        const result = await get(`api/lead/search_all?search_text=${search_text}&status=${status}&department_id=${department_id}&employee_id=${employee_id}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterllCompanyLead1Service = async(from_date,to_date,search_text,department,user,stage,page)=>{
    try {
        const result = await get(`api/lead/search_all_latest?search_text=${search_text}&status=${stage}&department_id=${department}&employee_id=${user}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadDataService = async()=>{
    try {
        const result = await get(`api/analytics/department_report`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadDataCountService = async()=>{
    try {
        // department_count_report
        // department_customize_report_count
        const result = await get(`api/analytics/department_customize_report_count`);
        return result;
    } catch (err) {
        return err.response;
    }
}


