import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { TextAreaInput1, TextInput, TextInput1 } from '../../components/input';
import { BiErrorCircle } from 'react-icons/bi'
import { GetStageService } from '../../services/StageServices';
import { ButtonFilled, ButtonOutlined } from '../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import moment from 'moment'
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import GoBack from '../../components/back/GoBack';
import { GetDepartmentService } from '../../services/DepartmentServices';
import FTSLeadMenu from './FTSLeadMenu';
import { CreateFTSLeadsService1, GetFTSLeadsDetailService, UpdateFTSLeadsService } from '../../services/FTSLeadServices';
// import DailyTaskCE from './dailyTask/DailyTaskCE';
import { BiCheckbox, BiCheckboxSquare } from 'react-icons/bi'
import ErrorComponent from '../../components/errorDesign/ErrorComponent';


function FTSLeadCE() {

  const user = useSelector(state => state.Auth)
  const departmentFTS = user?.department_id?.filter((f) => f?.department_name == 'FTS Team')?.length > 0 ? true : false
  const department_FCPL_FTS_BACKEND = user?.department_id?.filter((f) => f?.department_name == 'FTS Team' || f?.department_name == 'Corp Team' || f?.department_name == 'Backend Team')?.length > 0 ? true : false

  const { pathname } = useLocation()
  let path = pathname.split("/")[pathname.split("/").length - 1]



  const [old_Data, setold_Data] = useState({})

  const [data, setdata] = useState({
    department_id: '',
    contact_name: '',
    phone: '',
    email: '',
    designation: '',
    company_name: '',
    lead_work: '',
    lead_handled_by: '',
    secondary_contact_person: '',
    secondary_contact_phone: '',
    secondary_contact_email: '',
    lead_source: '',
    industry: '',
    stage: '',
    zone: '',
    sqft: false,
    seater: false,
    rent: false,
    sale: false,
    website: '',
    requirment: '',
    address: '',
    operators: [],
    transferred: '',
    important: '',
    color: '',
    land_lord: false,
    previously_handled_by: '',
    zone: '',
    city: '',
    state: '',
    remarks: ''
  })

  const [old_fts_lead_assigned, setold_fts_lead_assigned] = useState([])

  const [DepartmentArr, setDepartmentArr] = useState([])
  const [selected_department, setselected_department] = useState([])
  const [selected_department_error, setselected_department_error] = useState('')


  const [contactSourceArr, setcontactSourceArr] = useState([])
  const [stage, setstage] = useState({ selected_stage: '', date: '', time: '', file: '' })
  const [stageerr, setstageerr] = useState({ selected_stage: '', date: '', time: '', file: '' })
  const [IndustyArr, setIndustyArr] = useState([])
  const [leadStatusArr, setleadStatusArr] = useState([])
  const [zoneArr, setzoneArr] = useState([])
  const [OperatorsArr, setOperatorsArr] = useState([])
  const [loader, setloader] = useState(false);


  const leadSourceArr = [
    { label: 'WalkIns', value: 'WalkIns' },
    { label: 'Social Media', value: 'Social Media' },
    { label: 'Events', value: 'Events' },
    { label: 'Client Engagement', value: 'Client Engagement' },
    { label: 'Calls', value: 'Calls' },
    { label: 'Relationship', value: 'Relationship' },
    { label: 'Meeting', value: 'Meeting' },
    { label: 'Old Data', value: 'Old Data' },
  ]

  const [error, seterror] = useState({
    department_id: '',
    contact_name: '',
    phone: '',
    email: '',
    designation: '',
    company_name: '',
    secondary_contact_person: '',
    secondary_contact_phone: '',
    secondary_contact_email: '',
    lead_source: '',
    industry: '',
    stage: '',
    zone: '',
    sqft: '',
    seater: '',
    rent: '',
    sale: '',
    website: '',
    requirment: '',
    address: '',
    operators: '',
    transferred: '',
    important: '',
    color: '',
    remarks: ''
  })

  const navigate = useNavigate()
  const location = useLocation()



  // console.log("old_fts_lead_assigned",old_fts_lead_assigned)



  async function handleSelect(e, e1) {
    // console.log("e1",e1,"e",e)

    if (e1 === 'Country') {
      setdata({ ...data, lead_source: contactSourceArr?.find((i) => i.value === e) })
      seterror({ ...error, lead_source: '' })
    } else if (e1 === 'Industry') {
      setdata({ ...data, industry: IndustyArr?.find((i) => i.value === e) })
      seterror({ ...error, industry: '' })
    } else if (e1 === 'LeadStage') {
      setstage({ ...stage, selected_stage: leadStatusArr?.find((i) => i.value === e) })
    } else if (e1 === 'Zone') {
      setdata({ ...data, zone: zoneArr?.find((i) => i.value === e) })
      seterror({ ...error, zone: '' })
    } else if (e1 === 'Operator') {
      let arr = []
      e?.forEach((ele) => {
        arr?.push(OperatorsArr?.find((i) => i.value === ele))
      })
      setdata({ ...data, operators: arr })
      seterror({ ...error, operators: '' })
    } else if (e1 === 'lead_handled_by') {
      setdata({ ...data, lead_handled_by: DepartmentArr?.find((f) => f?.value === e) })
      seterror({ ...error, lead_handled_by: '' })
    } else if (e1 === 'Department') {
      setselected_department_error('')
      if (selected_department?.length > 0) {

        let arr = []


        if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') {
          e?.forEach((d) => {
            if (old_fts_lead_assigned?.length > 0) {
              if (old_fts_lead_assigned?.filter(ol => ol?.department_id?._id === d)?.length > 0) {
                arr.push({
                  label: old_fts_lead_assigned?.filter(ol => ol?.department_id?._id === d)[0]?.department_id?.department_name,
                  value: old_fts_lead_assigned?.filter(ol => ol?.department_id?._id === d)[0]?.department_id?._id,
                  summary: selected_department?.filter((f) => f.value === d)?.length > 0 ? selected_department?.filter((f) => f.value === d)[0]?.summary : old_fts_lead_assigned?.filter(ol => ol?.department_id?._id === d)[0]?.department_id?.requirment,
                  id: old_fts_lead_assigned?.filter(ol => ol?.department_id?._id === d)[0]?.department_id?._id,
                  actual_revenue_achieved: old_fts_lead_assigned?.filter(ol => ol?.department_id?._id === d)[0]?.department_id?.actual_revenue_achieved !== undefined ? old_fts_lead_assigned?.filter(ol => ol?.department_id?._id === d)[0]?.department_id?.actual_revenue_achieved : 0,
                  estimated_revenue: old_fts_lead_assigned?.filter(ol => ol?.department_id?._id === d)[0]?.department_id?.estimated_revenue !== undefined ? old_fts_lead_assigned?.filter(ol => ol?.department_id?._id === d)[0]?.department_id?.estimated_revenue : 0

                })
              } else {
                let data = DepartmentArr?.find((f) => f?.value === d)
                if (data !== undefined) {
                  arr.push({ ...data, summary: '', estimated_revenue: 0, actual_revenue_achieved: 0 })
                }
              }
            } else {

            }
          })

          setselected_department(arr)

        } else {
          e?.forEach((d) => {
            let data = DepartmentArr?.find((f) => f?.value === d)
            if (data !== undefined) {
              arr.push({ ...data, summary: '' })
            }
          })
          setselected_department(arr)
        }

      } else {
        let arr = []
        let data = DepartmentArr?.find((f) => f?.value === e[0])
        if (data !== undefined) {
          arr.push({ ...data, summary: '' })
        }
        setselected_department(arr)
      }
    }
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '1px solid rgb(227 231 234)',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0px 6px',
      fontSize: '13px',
      fontWeight: 600,
      marginBottom: '0px',
      paddingBottom: '0px'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
  };

  async function getbasicdatareq() {
    const response = await GetStageService('Lead_Stage')
    const response1 = await GetStageService('Contact_Source')
    const response2 = await GetStageService('Industry')
    const response3 = await GetStageService('Zones')
    const response4 = await GetDepartmentService()
    // console.log("response4",response4)

    let arr = []
    let arr1 = []
    let arr2 = []
    let arr3 = []
    let arr4 = []

    response.data.data.datas.forEach((d) => {
      if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'create') {
        if (d?.name !== 'Calls' && d?.name !== 'Walk-Ins' && d?.name !== 'Meetings' && d?.name !== 'Invoice Raised' && d?.name !== 'Property-Visit') {
          arr.push({ label: d?.name, value: d?._id })
        }
      } else {
        if (d?.name !== 'Calls' && d?.name !== 'Walk-Ins' && d?.name !== 'Meetings' && d?.name !== 'Property-Visit') {
          arr.push({ label: d?.name, value: d?._id })
        }
      }

      if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'create' && location?.state !== null && location?.state?.type === 'convert') {
        setdata({ ...location?.state?.data, stage: arr?.find(s => s.label === 'Contact') })
      }
      // arr.push({value:d._id,label:d.name})
    })
    response1.data.data.datas.forEach((d) => {
      arr1.push({ value: d._id, label: d.name })
    })
    response2.data.data.datas.forEach((d) => {
      arr2.push({ value: d._id, label: d.name })
    })
    response3.data.data.datas.forEach((d) => {
      arr3.push({ value: d._id, label: d.name })
    })
    response4.data.datas.forEach((d) => {
      if (!['Finance Team', 'Backend Team', 'Digital Media', 'Software Team']?.includes(d?.department_name)) {
        if (user?.department_id[0].department_name !== d?.department_name) {
          arr4.push({ value: d.id, label: d.department_name })
        }
      }
    })

    setleadStatusArr(arr)
    setcontactSourceArr(arr1)
    setIndustyArr(arr2)
    setzoneArr(arr3)
    setDepartmentArr(arr4)

    if (location?.pathname?.split('/')[2] === 'create' && location?.pathname?.split('/')[1] == 'contacts') {
      let lead_stage = response.data.data.datas.find((d) => d?.name === 'Contact')
      setdata({ ...data, stage: { value: lead_stage._id, label: lead_stage?.name } })
    }
  }

  useEffect(() => {
    getbasicdatareq()

    if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') {
      geteditdata()
    } else if (path == 'refer_my_lead') {
      let d = location?.state
      setdata({
        check_created_by_FTS: false,
        lead_owner_department: user.department_id[0].id,
        lead_work: '',
        contact_name: d?.name,
        phone: d?.mobile,
        email: d?.email,
        company_name: d?.company,
        previously_handled_by: user.name,
        requirment: '',
        remarks: '',
      })

    } else if (location?.state !== null && location?.state?.type !== undefined && location?.state?.type === 'convert') {
      let datas = location?.state?.data
      delete datas._id
      delete datas.followup_owner
      delete datas.stage
      setold_Data({ ...datas })
      setdata({ ...datas })
    }
  }, [])


  console.log("location?.state",location?.state)

  async function geteditdata() {
    const response = await GetFTSLeadsDetailService(location?.state?._id !== undefined ? location?.state?._id : location?.state);
    let d = response?.data?.datas[0]

    setold_Data(d)

    setdata({
      ...d,
      id: d?._id,
      department_id: d?.department_id,
      contact_name: d?.contact_name,
      phone: d?.phone,
      email: d?.email,
      designation: d?.designation,
      company_name: d?.company_name,
      secondary_contact_person: d?.secondary_contact_person,
      secondary_contact_phone: d?.secondary_contact_phone,
      secondary_contact_email: d?.secondary_contact_email,
      lead_work: d?.lead_work,
      lead_owner_department: (d?.lead_owner_department !== undefined && d?.lead_owner_department !== null) ? d?.lead_owner_department : '',
      lead_handled_by: { label: d?.lead_handled_by?.department_name, value: d?.lead_handled_by?._id },
      lead_source: (d?.lead_source !== undefined && d?.lead_source !== null) ? { value: d?.lead_source, label: d?.lead_source } : {},
      industry: (d?.industry !== undefined && d?.industry !== null) ? { value: d?.industry?._id, label: d?.industry?.name } : {},
      stage: (d?.stage !== undefined && d?.stage !== null) ? { value: d?.stage?._id, label: d?.stage?.name } : {},
      zone: (d?.zone !== undefined && d?.zone !== null) ? { value: d?.zone?._id, label: d?.zone?.name } : {},
      sqft: d?.sqft,
      seater: d?.seater,
      rent: d?.rent,
      sale: d?.sale,
      website: d?.website,
      requirment: d?.requirment,
      address: d?.address,
      transferred: d?.transferred,
      important: d?.important,
      remarks: d?.remarks !== undefined ? d?.remarks : '',
      color: d?.color,
      previously_handled_by: d?.previously_handled_by,
      zone: d?.zone !== undefined ? d?.zone : '',
      state: d?.state !== undefined ? d?.state : '',
      city: d?.city !== undefined ? d?.city : '',
    })

    let assign_list = []
    d?.ftsleadassigneds?.forEach((f) => {
      assign_list.push({
        label: f?.department_id?.department_name,
        value: f?.department_id?._id,
        summary: f?.requirment,
        actual_revenue_achieved: f?.actual_revenue_achieved !== undefined ? f?.actual_revenue_achieved : 0,
        estimated_revenue: f?.estimated_revenue !== undefined ? f?.estimated_revenue : 0
      })
    })


    // console.log("d here",d)



    setold_fts_lead_assigned(d?.ftsleadassigneds)

    setselected_department(assign_list)

  }

  // console.log("data?.operators",data?.operators)
  function handlechange(e) {
    setdata({ ...data, [e.target.name]: e.target.value })
    seterror({ ...error, [e.target.name]: '' })
  }


  // console.log("data?.department_id ",data?.lead_owner_department )

  async function submitform(stagedata) {

    if (!data.contact_name) {
      seterror({ ...error, contact_name: 'This Field is required*' })
    } else if (!data.phone) {
      seterror({ ...error, phone: 'This Field is required*' })
    } else if (!data.email) {
      seterror({ ...error, email: 'This Field is required*' })
    } else if (!data.company_name) {
      seterror({ ...error, company_name: 'This Field is required*' })
    } else if (!departmentFTS && selected_department.length == 0) {
      setselected_department_error('This Field is required*')
    } else {

      // console.log("location?.pathname?.split('/')[2] === 'edit'",location?.pathname?.split('/')[2])
      let send_data = {}
      send_data['contact_name'] = data?.contact_name;
      send_data['phone'] = data?.phone;
      send_data['email'] = data?.email;
      send_data['lead_owner_department'] = location?.pathname?.split('/')[3] === 'edit' ? data?.lead_owner_department?._id : user.department_id[0]['id']
      send_data['company_name'] = data?.company_name;
      send_data['designation'] = data.designation;
      send_data['lead_handled_by'] = data?.lead_handled_by?.value;
      send_data['lead_work'] = data?.lead_work;
      send_data['address'] = data?.address;
      send_data['previously_handled_by'] = data?.previously_handled_by;

      if (data?.zone?.value !== undefined) {
        send_data['zone'] = data?.zone?.value;
      }
      if (data?.lead_source?.value !== undefined) {
        send_data['lead_source'] = data?.lead_source?.value;
      }
      send_data['city'] = data?.city;
      send_data['remarks'] = data?.remarks !== undefined ? data?.remarks : '';
      send_data['requirment'] = data?.requirment !== undefined ? data?.requirment : '';


      send_data['department'] = selected_department



      if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') {
        if (selected_department.length > 0) {
          send_data['department'] = selected_department
        }

        if (departmentFTS) {
          send_data['check_created_by_FTS'] = true
        } else {
          send_data['lead_shared'] = true
        }

        if ((departmentFTS && data?.lead_shared)) {
          send_data['lead_shared_status'] = data?.lead_shared_status?.value
        }

        setloader(true)
        const response = await UpdateFTSLeadsService(send_data, data?.id)
        if (response.status === 200) {
          setloader(false)
          // console.log("Anna data?.stage?.label",data?.stage?.label,"stage?.selected_stage?.label",stage?.selected_stage?.label)
          setloader(false)
          toast.success('Lead Updated Successfully')
          resetform()
          navigate(-1)
        }
      } else {


        setloader(true)

        if (path !== 'shared_to_fts') {
          if (departmentFTS) {
            send_data['check_created_by_FTS'] = true
          } else {
            send_data['lead_shared'] = true
            send_data['check_created_by_FTS'] = true
            send_data['lead_shared_status'] = 'Accepted'
          }
          const response = await CreateFTSLeadsService1(send_data)
          if (response.status === 201) {
            setloader(false)
            toast.success('FTS Lead Created Successfully')
            resetform()
          }
        } else {
          if (departmentFTS) {
            send_data['check_created_by_FTS'] = true
          } else {
            send_data['lead_shared'] = true
            send_data['check_created_by_FTS'] = true
            send_data['lead_shared_status'] = 'Accepted'
          }
          const response = await CreateFTSLeadsService1(send_data)
          if (response.status === 201) {
            setloader(false)
            toast.success('FTS Lead Created Successfully')
            resetform()
          }
        }
      }
    }
  }

  function resetform() {
    setdata({
      department_id: '',
      contact_name: '',
      phone: '',
      email: '',
      designation: '',
      company_name: '',
      lead_work: '',
      lead_handled_by: '',
      secondary_contact_person: '',
      secondary_contact_phone: '',
      secondary_contact_email: '',
      lead_source: '',
      industry: '',
      stage: '',
      zone: '',
      sqft: false,
      seater: false,
      rent: false,
      sale: false,
      website: '',
      requirment: '',
      address: '',
      operators: [],
      transferred: '',
      important: '',
      color: '',
      land_lord: false,
      previously_handled_by: '',
      zone: '',
      city: '',
      state: '',
      remarks: ''
    })
    seterror({
      department_id: '',
      contact_name: '',
      phone: '',
      email: '',
      designation: '',
      company_name: '',
      secondary_contact_person: '',
      secondary_contact_phone: '',
      secondary_contact_email: '',
      lead_source: '',
      industry: '',
      stage: '',
      zone: '',
      sqft: '',
      seater: '',
      rent: '',
      sale: '',
      website: '',
      requirment: '',
      address: '',
      operators: '',
      transferred: '',
      important: '',
      color: '',
      remarks: ''
    })

    setselected_department([])

    setstage({ selected_stage: '', date: '', time: '', file: '' })
    setstageerr({ selected_stage: '', date: '', time: '', file: '' })
  }




  const options = [{ label: 'North', value: 'North' }, { label: 'South', value: 'South' }, { label: 'East', value: 'East' }, { label: 'West', value: 'West' }]
  const leadSharedStatus = [{ label: 'Pending', value: 'Pending' }, { label: 'Accepted', value: 'Accepted' }, { label: 'Rejected', value: 'Rejected' }]

  async function updatesummary(i, e) {
    let old_Data = [...selected_department]
    old_Data[i]['summary'] = e?.target?.value
    setselected_department([...old_Data])
  }

  async function updateestimatedrevenue(i, e) {
    let old_Data = [...selected_department]
    old_Data[i]['estimated_revenue'] = e?.target?.value
    setselected_department([...old_Data])
  }

  async function updateactualrevenue(i, e) {
    let old_Data = [...selected_department]
    old_Data[i]['actual_revenue_achieved'] = e?.target?.value
    setselected_department([...old_Data])
  }

  // console.log("data",data)

  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
      <div className='block sm:flex'>
        <FTSLeadMenu />
        <div className='pr-5 ml-5 min-h-screen max-h-screen h-screen overflow-y-scroll' >




          <div className='w-[85%] min-w-[38vw] max-w-[85vw] '>
            <GoBack />
            <div className='border-b  pb-2'>
              <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit' ? 'Edit' : 'Create'} FTS Lead</h6>
              <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the FTS Lead for your FTS vertical.</h6>

              {/* <h6>{JSON.stringify(location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit')}</h6>
      <h6>{old_Data?.lead_owner_department?.department_name}</h6> */}
            </div>

            {((location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') && (old_Data?.lead_shared) && (old_Data?.lead_owner_department?.department_name !== 'FTS Team')) &&
              <div className='bg-blue-100 mt-4 p-2 text-[12px]'>
                <h6 className='font-[400]'>Lead Owner Department 11: <span className='font-[800]'>{old_Data?.lead_owner_department?.department_name}</span> / Lead Created By : <span className='font-[800]'>{old_Data?.lead_owner?.name}</span> / Created At : <span className='font-[800]'>{moment(old_Data?.createdAt)?.format('LLL')}</span></h6>
              </div>}

            <Grid container spacing={2}  >
              <Grid item xs={12} md={6} >
                <TextInput
                  mandatory={true}
                  label={'Name'}
                  variant="standard"
                  name="contact_name"
                  type="text"
                  value={data.contact_name}
                  error={error.contact_name}
                  handlechange={handlechange}
                  placeholder="Enter contact name"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />
                <TextInput
                  mandatory={true}
                  label={'Phone'}
                  variant="standard"
                  name="phone"
                  type="text"
                  value={data.phone}
                  error={error.phone}
                  handlechange={handlechange}
                  placeholder="Enter contact phone"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />
                <TextInput
                  mandatory={true}
                  label={'Email'}
                  variant="standard"
                  name="email"
                  type="text"
                  value={data.email}
                  error={error.email}
                  handlechange={handlechange}
                  placeholder="Enter contact email"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />

                <TextInput
                  mandatory={true}
                  label={'Company Name'}
                  variant="standard"
                  name="company_name"
                  type="text"
                  value={data.company_name}
                  error={error.company_name}
                  handlechange={handlechange}
                  placeholder="Enter company name"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />
                <TextAreaInput1
                  label={'Company Address'}
                  variant="standard"
                  name="address"
                  type="text"
                  value={data.address}
                  error={error.address}
                  handlechange={handlechange}
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />

                <h6 className='text-[12px] font-semibold mb-1 mt-1'>Lead Source*</h6>
                <Select
                  value={data.lead_source}
                  bordered={false}
                  onChange={(e) => { setdata({ ...data, lead_source: leadSourceArr.find((f) => f.value === e) }); seterror({ ...error, lead_source: '' }) }}
                  options={leadSourceArr}
                  className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'


                />

                {error.lead_source !== '' && error.lead_source !== undefined &&
                  <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{error.lead_source}</span>
                  </div>}


                <h6 className='text-[12px] font-semibold mb-1 mt-1'>Zone*</h6>
                <Select
                  value={data.zone}
                  bordered={false}
                  onChange={(e) => { setdata({ ...data, zone: options.find((f) => f.value === e) }); seterror({ ...error, zone: '' }) }}
                  options={options}
                  className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'


                />

                {error.zone !== '' && error.zone !== undefined &&
                  <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{error.zone}</span>
                  </div>}

                {/* <h6>{JSON.stringify(departmentFTS)}</h6>
         <h6>{JSON.stringify(data?.lead_shared)}</h6> */}

                {(departmentFTS && data?.lead_shared) &&
                  <>
                    <h6 className='text-[12px] font-semibold mb-1 mt-1'>Lead Shared Status</h6>
                    <Select
                      value={data.lead_shared_status}
                      bordered={false}
                      onChange={(e) => { setdata({ ...data, lead_shared_status: leadSharedStatus.find((f) => f.value === e) }); seterror({ ...error, lead_shared_status: '' }) }}
                      options={leadSharedStatus}
                      className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'


                    />

                    {error.lead_shared_status !== '' && error.lead_shared_status !== undefined &&
                      <div className='flex items-center mt-1'>
                        <BiErrorCircle className='text-red-500' size={14} />
                        <span className='text-[10px] text-red-500 ml-1'>{error.lead_shared_status}</span>
                      </div>}
                  </>}

                <TextInput
                  mandatory={false}
                  label={'City Name'}
                  variant="standard"
                  name="city"
                  type="text"
                  value={data.city}
                  error={error.city}
                  handlechange={handlechange}
                  placeholder="Enter company name"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />

                <TextAreaInput1
                  label={'Previously Handled By'}
                  variant="standard"
                  name="previously_handled_by"
                  type="text"
                  value={data.previously_handled_by}
                  error={error.previously_handled_by}
                  handlechange={handlechange}
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />

                <TextAreaInput1
                  label={'Requirment'}
                  variant="standard"
                  name="requirment"
                  type="text"
                  value={data.requirment}
                  error={error.requirment}
                  handlechange={handlechange}
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />


                <TextAreaInput1
                  label={'Remarks'}
                  variant="standard"
                  name="remarks"
                  type="text"
                  value={data.remarks}
                  error={error.remarks}
                  handlechange={handlechange}
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />



                {/* onClick={()=>setdata({...data,lead_shared:!data.lead_shared})} */}
                {(departmentFTS && data?.lead_shared && location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') &&
                  <div className='flex items-center mt-1' >
                    {!data?.lead_shared ? <BiCheckbox size={18} className='text-slate-300' /> : <BiCheckboxSquare size={18} className='text-slate-700' />}
                    <h6 className='text-[11px] ml-1' >Leads Recieved From Other Department</h6>
                  </div>}




              </Grid>
              <Grid item xs={12} md={6} >



                {/* {departmentFTS &&
         <> */}
                <h6 className='text-[12px] font-semibold mb-1 mt-1'>Department</h6>
                <Select
                  value={selected_department}
                  mode="multiple"
                  bordered={false}
                  placeholder=""
                  onChange={(e) => handleSelect(e, 'Department')}
                  styles={customStyles}
                  options={DepartmentArr}
                  className='border-l-4 border-l-slate-600 min-w-[270px] max-w-[270px] border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />
                <ErrorComponent error={selected_department_error} />
                {error.stage !== '' && error.stage !== undefined &&
                  <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{error.stage}</span>
                  </div>}


                {selected_department?.map((s, i) => (
                  <div>
                    <TextAreaInput1
                      label={`${s?.label} Requirment`}
                      variant="standard"
                      name="address"
                      type="text"
                      value={s.summary}
                      error={error.address}
                      handlechange={(e) => updatesummary(i, e)}
                      InputLabelProps={{
                        style: { color: '#fff', },
                      }} />


                    {department_FCPL_FTS_BACKEND &&
                      <>
                        <TextInput1
                          label={`${s?.label} Estimated Revenue`}
                          variant="standard"
                          name="number"
                          type="number"
                          value={s.estimated_revenue}
                          handlechange={(e) => updateestimatedrevenue(i, e)}
                          InputLabelProps={{
                            style: { color: '#fff', },
                          }} />

                        <TextInput1
                          label={`${s?.label} Actual Revenue`}
                          variant="standard"
                          name="number"
                          type="number"
                          value={s.actual_revenue_achieved}
                          handlechange={(e) => updateactualrevenue(i, e)}
                          InputLabelProps={{
                            style: { color: '#fff', },
                          }} />
                      </>}

                  </div>
                ))}
                {/* </>} */}


              </Grid>
            </Grid>


            <div >

              {(old_Data?._id == undefined || (old_Data.lead_shared && old_Data?.lead_shared_status == "Pending") || department_FCPL_FTS_BACKEND) && <>
                <div className='flex items-center mt-5 mb-10  border-t pt-5'>

                  <div className='mr-2'>
                    <ButtonOutlined btnName={'Back'} onClick={() => navigate(-1)} />
                  </div>
                  <div>
                    {loader ?
                      <ButtonFilled btnName={loader ? 'Loading' : 'Save'} /> :
                      <ButtonFilled btnName={loader ? 'Loading' : 'Save'} onClick={() => submitform(null)} />}
                  </div>

                </div>
              </>}
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default FTSLeadCE