import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { ButtonFilledAutoWidth } from '../../components/button'
import { TextAreaInput1, TextInput } from '../../components/input'
import { Select } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import GoBack from '../../components/back/GoBack'
import Uploader from '../../components/Uploader'
import ErrorComponent from '../../components/errorDesign/ErrorComponent'
import SettingsMenu from '../staticscreens/SettingsMenu'
import { GetFidelitusCorpEventService } from '../../services/fidelituscorpservices/FidelitusCorpEventListServices'
import { CreateFidelitusCorpVisitorService, UpdateFidelitusCorpVisitorService, UploadFidelitusCorpVisitorService } from '../../services/fidelituscorpservices/FidelitusCorpVIsitorListServices'
import { GetDepartmentService, GetUsersByDepartment } from '../../services/DepartmentServices'

function FidelitusCorpVisitorListCE() {

  const [data, setdata] = useState({ image: '', name: '', mobile: '', email: '', address: '', designation: '', remarks: '', company: '', department: '', meet_user: '', purpose: '' });
  const [error, seterror] = useState({ image: '', name: '', mobile: '', email: '', address: '', designation: '', remarks: '', company: '', department: '', meet_user: '', purpose: '' });


  const { state } = useLocation();
  const [events, setevents] = useState([])
  const [department, setdepartment] = useState([])
  const [users, setusers] = useState([])
  const navigate = useNavigate();





  useEffect(() => {
    if (state?._id !== null && state?._id !== undefined) {

      let obj = {
        ...state,

      }
      if (state?.meet_user?._id !== undefined) {
        obj['meet_user'] = { label: state?.meet_user?.name, value: state?.meet_user?._id }
      }
      if (state?.department?._id !== undefined) {
        obj['department'] = { label: state?.department?.department_name, value: state?.department?._id }
      }
      delete obj.created_by
      setdata({ ...data, ...obj })
    }
    getdepartments()

  }, [state])

  function handlechange(e) {
    setdata({ ...data, [e.target.name]: e.target.value })
    seterror({ ...error, [e.target.name]: '' })
  }

  async function getdepartments() {
    const response = await GetDepartmentService()
    let arr = []
    let d = response?.data?.datas
    d?.forEach((d1) => {
      arr?.push({ label: d1?.department_name, value: d1?.id })
    })
    setdepartment(arr)
  }

  async function getuserbaseddepartments(id) {
    const response = await GetUsersByDepartment(id)
    let arr = []
    let d = response?.data?.datas
    d?.forEach((d1) => {
      arr?.push({ label: d1?.name, value: d1?._id })
    })
    setusers(arr)
  }



  async function submitform() {
    if (!data.name) {
      seterror({ ...error, name: 'Name field is required *' })
    } else if (!data.mobile) {
      seterror({ ...error, mobile: 'Mobile field is required *' })
    } else {
      let sendData = { ...data }

      if (data?.department?.label !== undefined) {
        sendData['department'] = data?.department?.value
      }

      if (data?.meet_user?.label !== undefined) {
        sendData['meet_user'] = data?.meet_user?.value
      }


      if (state?._id === undefined || state?._id === null) {
        const response = await CreateFidelitusCorpVisitorService(sendData)
        if (response.status === 201) {
          resetform()
          toast.success('Visitor Created Successfully')
        }
        if (response.status === 422) {
          if (response?.data?.errors?.title) {
            seterror({ ...error, title: response?.data?.errors?.title })
          }
        }
      } else {
        sendData["id"] = state?._id
        const response = await UpdateFidelitusCorpVisitorService(sendData, state?._id)
        if (response.status === 200) {
          resetform()
          navigate(-1)
          toast.success('Visitor Updated Successfully')
        }
        if (response.status === 422) {
          if (response?.data?.errors?.title) {
            seterror({ ...error, title: response?.data?.errors?.title })
          }
        }
      }
    }
  }

  async function resetform() {
    setdata({ image: '', name: '', mobile: '', email: '', address: '', designation: '', remarks: '', company: '', fidelituscorp_event: '' })
    seterror({ image: '', name: '', mobile: '', email: '', address: '', designation: '', remarks: '', company: '', fidelituscorp_event: '' })
  }


  async function uploadfile(v, name) {
    const fd = new FormData()
    fd.append('file', v);
    const response = await UploadFidelitusCorpVisitorService(fd)
    if (response?.status === 200) {
      setdata({ ...data, [name]: response?.data?.data })
      seterror({ ...error, [name]: '' })
    }
  }

  async function searchData(v, type) {

    const response = await GetFidelitusCorpEventService(1, v, '', '', 'null')

    let d = response?.data?.datas
    console.log("response?.data", response?.data)
    let arr = []


    d.forEach((d1) => {
      arr.push({ label: d1?.title, value: d1?._id })
    })
    setevents(arr)
  }



  async function handleselect(v, type) {
    if (type == 'department') {
      let findData = department.find((f) => f.value === v)
      if (findData !== null) {
        getuserbaseddepartments(findData?.value)
        setdata({ ...data, [type]: findData })
      }

    }
    if (type == 'meet_user') {
      let findData = users.find((f) => f.value === v)
      if (findData !== null) {
        setdata({ ...data, [type]: findData })
      }
    }

  }


  return (
    <div className='flex '>


      <SettingsMenu />

      <div className='sm:w-full lg:w-72 px-4 pt-5' >


        <GoBack />



        <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} Visitor</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b>Visitor details</b> came to visit you.</h6>


        <TextInput
          mandatory={true}
          label={'Name'}
          variant="standard"
          name="name"
          type="text"
          error={error.name}
          value={data.name}
          handlechange={handlechange}
          placeholder=""
          InputLabelProps={{
            style: { color: '#fff', },
          }} />



        <TextInput
          mandatory={true}
          label={'Mobile'}
          variant="standard"
          name="mobile"
          error={error.mobile}
          value={data.mobile}
          handlechange={handlechange}
          placeholder=""
          InputLabelProps={{
            style: { color: '#fff', },
          }} />

        <TextInput
          label={'Email'}
          variant="standard"
          name="email"
          type="text"
          error={error.email}
          value={data.email}
          handlechange={handlechange}
          placeholder=""
          InputLabelProps={{
            style: { color: '#fff', },
          }} />

        <TextInput
          label={'Designation'}
          variant="standard"
          name="designation"
          type="text"
          error={error.designation}
          value={data.designation}
          handlechange={handlechange}
          placeholder=""
          InputLabelProps={{
            style: { color: '#fff', },
          }} />




        <TextAreaInput1
          label={'Address'}
          variant="standard"
          name="address"
          type="text"
          error={error.address}
          value={data.address}
          handlechange={handlechange}
          placeholder=""
          InputLabelProps={{
            style: { color: '#fff', },
          }} />

        <TextInput
          label={'Company'}
          variant="standard"
          name="company"
          type="text"
          error={error.company}
          value={data.company}
          handlechange={handlechange}
          placeholder=""
          InputLabelProps={{
            style: { color: '#fff', },
          }} />


        <TextAreaInput1
          label={'Remarks'}
          variant="standard"
          name="remarks"
          type="text"
          error={error.remarks}
          value={data.remarks}
          handlechange={handlechange}
          placeholder=""
          InputLabelProps={{
            style: { color: '#fff', },
          }} />

        <h6 className='text-[11px] mt-2 font-[600] mb-1' >Visitor Image</h6>
        <Uploader image={data?.image} setimagefunc={(e) => { uploadfile(e, 'image') }} removeimageuploadfunc={() => setdata({ ...data, image: '' })} />


        <h6 className='text-[11px] mt-2 font-[600] mb-1' >Department</h6>
        <Select
          value={data?.department}
          allowClear={true}
          type="department"
          filterOption={false}
          showSearch={true}
          onChange={e => { handleselect(e, 'department') }}
          options={department}
          bordered={false}
          className='w-[100%] border border-slate-300'
        />

        <ErrorComponent error={error?.department} />


        <h6 className='text-[11px] mt-2 font-[600] mb-1' >User</h6>
        <Select
          value={data?.meet_user}
          allowClear={true}
          type="department"
          filterOption={false}
          showSearch={true}
          onChange={e => { handleselect(e, 'meet_user') }}
          options={users}
          bordered={false}
          className='w-[100%] border border-slate-300'
        />

        <ErrorComponent error={error?.meet_user} />



        <TextAreaInput1
          label={'Purpose'}
          variant="standard"
          name="purpose"
          type="text"
          error={error.purpose}
          value={data.purpose}
          handlechange={handlechange}
          placeholder=""
          InputLabelProps={{
            style: { color: '#fff', },
          }} />


        <div className='mt-5'>
          <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE VISITOR DATA" : "ADD VISITOR DATA"} onClick={submitform} />
        </div>


      </div>
    </div>
  )
}

export default FidelitusCorpVisitorListCE