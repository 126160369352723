import { deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const CreateCalendarService=async(data)=>{
    try {
        const result = await post(`api/calendar/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateCalendarService=async(data,id)=>{
    try {
        const result = await put(`api/calendar/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetCalendarService=async(month,year,page=1)=>{
    try {
        const result = await get(`api/calendar/get?month=${month}&year=${year}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterCalendarService=async(from_date,to_date,type,page=1,user='')=>{
    try {
        const result = await get(`api/calendar/filter?from_date=${from_date}&to_date=${to_date}&type=${type}&user=${user}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterAdminCalendarService=async(from_date,to_date,type,page=1,user='')=>{
    try {
        const result = await get(`api/calendar/filter_admin?from_date=${from_date}&to_date=${to_date}&type=${type}&user=${user}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetCalendarTodayWorkService=async(page=1)=>{
    try {
        const result = await get(`api/calendar/get_today_calendar_work?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetCalendarFollowupService=async(id)=>{
    try {
        const result = await get(`api/calendar/calendar_followup/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteCalendarService=async(id)=>{
    try {
        const result = await deleteRequest(`api/calendar/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadCalendarService=async(data)=>{
    try {
        const result = await postfd(`api/calendar/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}