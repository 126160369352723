import React,{useEffect, useState} from 'react'



import { TextInput } from '../../components/input'
import { LogInService } from '../../services/AuthServices';
import { useDispatch } from 'react-redux';
import { LogInAction, LogOutAction } from '../../redux/actions/authAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {AiFillEye,AiFillEyeInvisible} from 'react-icons/ai';


function Login() {

    const [data,setdata] = useState({user_id:'',password:''});
    const [error,seterror] = useState({user_id:'',password:''});

    const [stateMsg,setstateMsg] = useState('')
    const [visible,setvisible] = useState(false)

    
    const dispatch = useDispatch();
    const {state} = useLocation();
    const navigate = useNavigate();



    useEffect(()=>{
        if(state !== null){
        setstateMsg(state)
        }
        dispatch(LogOutAction())

    },[])


    function handlechange(e){
        setdata({...data,[e.target.name] : e.target.value})
        seterror({...error,[e.target.name]:''})
    }

    function onKeyDownHandler(e){
       
        if (e.keyCode === 13) {
            submitform();
        }
    }

    async function submitform(){

        if(!data.user_id){
            toast.error('FCPL ID is required')
            seterror({...error,user_id:'FCPL ID is required'})
        }else if(!data.password){
            toast.error('Password field is required')
            seterror({...error,password:'Password field is required'})
        }else{
            const response = await LogInService(data)
            let d = response?.data
            if(response.status === 200){
      
                dispatch(LogInAction(response.data))

                if(d?.is_verified && d?.is_active && d?.app_access){   
                    if(response?.data?.roles?.includes('admin')){
                        navigate('/reminder/active')
                    }else if(response?.data?.roles?.includes('bd_user') || response?.data?.roles?.includes('manager') || response?.data?.roles?.includes('hod')){
                        navigate('/dashboard')
                    }else if(response?.data?.roles?.includes('finance_head') || response?.data?.roles?.includes('finance_manager')){
                        navigate('/finance')
                    }else if(response?.data?.roles?.includes('hr_admin') || response?.data?.roles?.includes('backend_user')){
                        navigate('/tasks')
                    }else if(response?.data?.roles?.includes('gallery_inventory')){
                        navigate('/gallery')
                    }else if(response?.data?.roles?.includes('ats_user') || response?.data?.roles?.includes('ats_manager') || response?.data?.roles?.includes('ats_head')){
                        navigate('/ats')
                    }else if(response?.data?.roles?.includes('onboard_executive')){
                        navigate('/tasks')
                    }else if(response?.data?.roles?.includes('db_head') || response?.data?.roles?.includes('db_manager')){
                        navigate('/database/dashboard')
                    }else if(response?.data?.roles?.includes('db_user')){
                        navigate('/database/managed_office')
                    }else if(response?.data?.roles?.includes('fts_user') || response?.data?.roles?.includes('fts_admin')){
                        navigate('/fts_leads/dashboard')
                    }
                }else if(d?.user_in_review && d?.app_access && !d?.is_active && !d?.exit_form){
                        navigate(`/self_onboard`,{state:d})
                }else if(d?.exit_form && d?.app_access && !d?.is_active && !d?.user_in_review){
                    navigate(`/exit_formalities`,{state:d})
                }else{
                    toast.error("We Couldn't Find Your Account")
                }     
                
            }else if(response.status === 401){
                toast.error(response.data.errors)
                seterror({...error,password:response.data.errors})
            }
        }
    }

  return (

   

    <div className='flex min-h-screen w-full max-h-screen justify-between'>

     <div className='hidden md:block md:min-w-[70vw] md:w-[70vw] md:max-w-[70vw]  bg-slate-800'>
        <img src="https://fidecrmfiles.s3.amazonaws.com/crmposter.avif" className='w-full h-full object-cover opacity-20' />
    </div>   


    <div className='w-[100vw] md:min-w-[30vw] md:w-[30vw] md:max-w-[30vw] flex justify-center items-center' >

         <div className='mx-auto'>
            <div className='p-1 w-72'  >  

            <h6 className='text-[18px] font-[800]'>Sign In</h6>      
            <h6 className={`text-[10px] font-[400] leading-normal mb-4 my-2`}>Add your employee Id and password to access all the features provided to you.</h6>
            
            <form
             onKeyDown={onKeyDownHandler}
             onSubmit={submitform}
            > 
            <TextInput 
                label={'USER ID or FCPL ID'}  
                variant="standard"
                name="user_id"
                type="text"
                // error={error.user_id}
                value={data.user_id}
                handlechange={handlechange}
                placeholder="Enter your FCPL_ID"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


            <div className='relative'>
            <TextInput 
                label={'Password'}  
                variant="standard"
                name="password"
                type={visible ? "text" : "password"}
                // error={error.password}
                value={data.password}
                handlechange={handlechange}
                placeholder="Enter your password"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  
               {visible ?  <AiFillEye className='absolute right-1 top-[20px] cursor-pointer text-slate-200 ' onClick={()=>setvisible(!visible)} /> : <AiFillEyeInvisible className='absolute right-1 top-[20px] cursor-pointer text-slate-200 ' onClick={()=>setvisible(!visible)} />}
            </div>



                <h6 onClick={submitform} className="bg-slate-600 cursor-pointer text-center mt-4 text-white font-[500] py-[8px] px-4 mt-0  w-full rounded-md border-slate-600  text-[14px] hover:border-slate-600">SIGN IN</h6>
            </form>
            {/* <Box mt={3} >

            <ButtonFilled btnName="SIGN IN"  onClick={submitform} />  
            </Box> */}

            <h6 className='text-[10px] mt-4 text-800 leading-normal'>By continuing, you agree to Fidelitus<span className='text-black font-bold text-[#0a69da]'> Conditions of Use</span>  and <span className='text-black font-bold text-[#0a69da]'> Privacy Notice </span> .</h6>


            <h6 onClick={()=>navigate('/forgot_password')}  className="w-full  py-2 px-4 mt-5 rounded text-center  text-[12px] cursor-pointer">Forgot Password</h6>

            </div>
        </div>  
    </div> 

    {/* <Card variant='outlined' sx={{textAlign:'center',padding:2,marginTop:2}} className="border-[#d7dee3] border">
        <h6 className='text-[13px] font-[500]'>New to Fidelitus? <span className='text-[#0a69da] cursor-pointer'> Create an account.</span></h6>
    </Card> */}
    {/* <Copyright sx={{ mt: 8, mb: 4 }} className="center w-full" /> */}
    </div>    


      
  )
}

export default Login