import React from 'react';
import { RiUser6Line, RiWalkLine, RiBillLine } from 'react-icons/ri';
import { BsEnvelopePaper } from 'react-icons/bs'
import { useLocation, useNavigate } from 'react-router-dom';
import { GrMultiple } from 'react-icons/gr';
import { FcInspection } from 'react-icons/fc';
import { BiPhoneCall, BiFoodMenu } from 'react-icons/bi';
import { AiOutlineClockCircle, AiOutlineFileDone } from 'react-icons/ai';
import { MdOutlineMeetingRoom, MdOutlineOutbond, MdOutlineLandscape, MdOutlineHourglassTop, MdOutlineMoneyOffCsred, MdOutlineDesignServices, MdOutlineHighQuality, MdOutlineTireRepair } from 'react-icons/md';
// import {DatePicker} from 'antd';
import { IoConstructOutline } from 'react-icons/io5'
import { IoMdStopwatch } from 'react-icons/io'
import { BiLandscape, BiPaste, BiNote, BiLastPage } from 'react-icons/bi'
import { useSelector } from 'react-redux';
import { VscServerProcess } from 'react-icons/vsc'
import { BsDoorClosed } from 'react-icons/bs'
import { RiChatFollowUpLine } from "react-icons/ri";
import { RiFilePaper2Line } from "react-icons/ri";
import { TbNotes } from "react-icons/tb";
import { MdPersonOutline } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";

function LeadMenu() {

  const { pathname } = useLocation();
  const user = useSelector(state => state.Auth)
  const path = pathname.split('/')[pathname.split('/').length - 1]
  const path1 = pathname.split('/')[1]





  const navigate = useNavigate();

  const menu = [
    { name: 'My Contacts', icon: BiPhoneCall, path: '/leads/contacts', id: 1, color: '' },
    { name: 'Options Shared', icon: GrMultiple, path: '/leads/options_shared', id: 2, color: '' },
    { name: 'My Inspection', icon: FcInspection, path: '/leads/inspections', id: 3, color: '' },
    { name: 'LOI Signing', icon: MdOutlineOutbond, path: '/leads/loi_signing', id: 4, color: '' },
    // {name:'LOI / Agreement',icon:RiBillLine,path:'/leads/loi_agreement',id:4,color:''},
    { name: 'Agreement', icon: RiBillLine, path: '/leads/loi_agreement', id: 4, color: '' },
    { name: 'Invoice Raised', icon: BsEnvelopePaper, path: '/leads/invoice_raised', id: 5, color: '' },
    { name: 'Non Payable', icon: MdOutlineMoneyOffCsred, path: '/leads/non_payable_closure', id: 5, color: '' },
    { name: 'Hold Client', icon: MdOutlineHourglassTop, path: '/leads/hold', id: 6, color: '' }
  ]

  const menu_projects = [
    { name: 'My Contacts', icon: BiPhoneCall, path: '/leads/contacts', id: 1, color: '' },
    { name: 'Design Shared', icon: MdOutlineDesignServices, path: '/leads/design_shared', id: 2, color: '' },
    { name: 'QS Stage', icon: MdOutlineHighQuality, path: '/leads/qs_stage', id: 3, color: '' },
    { name: 'MEP Stage', icon: MdOutlineTireRepair, path: '/leads/mep_stage', id: 4, color: '' },
    { name: 'BOQ Stage', icon: RiBillLine, path: '/leads/boq_stage', id: 5, color: '' },
    { name: 'Execution Stage', icon: IoConstructOutline, path: '/leads/execution_stage', id: 5, color: '' },
    { name: 'Closed Stage', icon: AiOutlineFileDone, path: '/leads/closed_stage', id: 5, color: '' },
    { name: 'Contact In Future', icon: BiPaste, path: '/leads/contact_in_future', id: 5, color: '' },
    { name: 'Lost Client', icon: BiNote, path: '/leads/lost_client', id: 6, color: '' },
    { name: 'Hold Client', icon: MdOutlineHourglassTop, path: '/leads/hold_client', id: 6, color: '' }
  ]

  const menu_hr = [
    { name: 'Contact', icon: MdPersonOutline, path: '/leads/contact', id: 1, color: '' },
    { name: 'Follow Up', icon: BiPhoneCall, path: '/leads/follow_up', id: 1, color: '' },
    { name: 'Waiting Requirment', icon: IoMdStopwatch, path: '/leads/waiting_requirment', id: 2, color: '' },
    { name: 'In Progress', icon: VscServerProcess, path: '/leads/in_progress', id: 2, color: '' },
    { name: 'Closed', icon: AiOutlineFileDone, path: '/leads/closed', id: 3, color: '' },
    { name: 'Lost', icon: BiNote, path: '/leads/lost', id: 4, color: '' },
    { name: 'Hold', icon: MdOutlineHourglassTop, path: '/leads/hold', id: 5, color: '' }
  ]

  const menu_fms = [
    { name: 'Contact', icon: BiPhoneCall, path: '/leads/contact', id: 1, color: '' },
    { name: 'Meeting/Discusion', icon: BsDoorClosed, path: '/leads/meeting_discussion', id: 2, color: '' },
    { name: 'Assesment', icon: MdOutlineDesignServices, path: '/leads/assesement', id: 2, color: '' },
    { name: 'Proposal Sent', icon: AiOutlineFileDone, path: '/leads/proposal_sent', id: 3, color: '' },
    { name: 'Negotiation', icon: BiNote, path: '/leads/negotiation', id: 4, color: '' },
    { name: 'Final Closure', icon: BiLastPage, path: '/leads/final_closure', id: 5, color: '' },
    { name: 'Agreement', icon: RiBillLine, path: '/leads/agreement', id: 5, color: '' },
    { name: 'Lost', icon: BiNote, path: '/leads/lost', id: 4, color: '' },
    { name: 'Hold', icon: MdOutlineHourglassTop, path: '/leads/hold', id: 5, color: '' }
  ]

  const menu_fidelitus_gallery = [
    { name: 'Contact', icon: BiPhoneCall, path: '/leads/contacts', id: 1, color: '' },
    { name: 'Follow Up', icon: AiOutlineFileDone, path: '/leads/follow_up', id: 2, color: '' },
    // { name: 'Converted', icon: RiBillLine, path: '/leads/converted', id: 3, color: '' },
    { name: 'Proposal Sent', icon: RiFilePaper2Line, path: '/leads/proposal_sent', id: 3, color: '' },
    { name: 'Invoice Raised', icon: RiBillLine, path: '/leads/invoice_raised', id: 5, color: '' },
    { name: 'Hold', icon: MdOutlineHourglassTop, path: '/leads/hold', id: 4, color: '' }
  ]

  const menu_shilpa_foundation = [
    { name: 'Contact', icon: BiPhoneCall, path: '/leads/contacts', id: 1, color: '' },
    { name: 'Follow Up', icon: RiChatFollowUpLine, path: '/leads/follow_up', id: 2, color: '' },
    { name: 'Proposal Sent', icon: RiFilePaper2Line, path: '/leads/proposal_sent', id: 3, color: '' },
    { name: 'Purchase Order', icon: TbNotes, path: '/leads/purchase_order', id: 4, color: '' },
    { name: 'Invoice Raised', icon: RiBillLine, path: '/leads/invoice_raised', id: 5, color: '' },
    { name: 'Utlization Report', icon: HiOutlineDocumentReport, path: '/leads/utlization_report', id: 7, color: '' },
    { name: 'Hold', icon: MdOutlineHourglassTop, path: '/leads/hold', id: 6, color: '' }
  ]

  const menu_digital_media = [
    { name: 'Contact', icon: BiPhoneCall, path: '/leads/contacts', id: 1, color: '' },
    { name: 'Follow Up', icon: AiOutlineFileDone, path: '/leads/follow_up', id: 2, color: '' },
    { name: 'Converted', icon: RiBillLine, path: '/leads/converted', id: 3, color: '' },
    { name: 'Hold', icon: MdOutlineHourglassTop, path: '/leads/hold', id: 4, color: '' }
  ]

  const menu_corp = [
    { name: 'Contact', icon: BiPhoneCall, path: '/leads/contacts', id: 1, color: '' },
    { name: 'Follow Up', icon: AiOutlineFileDone, path: '/leads/follow_up', id: 2, color: '' },
    { name: 'Converted', icon: RiBillLine, path: '/leads/converted', id: 3, color: '' },
    { name: 'Hold', icon: MdOutlineHourglassTop, path: '/leads/hold', id: 4, color: '' }
  ]

  const menu_backend = [
    { name: 'Contact', icon: BiPhoneCall, path: '/leads/contacts', id: 1, color: '' },
    { name: 'Follow Up', icon: AiOutlineFileDone, path: '/leads/follow_up', id: 2, color: '' },
    { name: 'Converted', icon: RiBillLine, path: '/leads/converted', id: 3, color: '' },
    { name: 'Hold', icon: MdOutlineHourglassTop, path: '/leads/hold', id: 4, color: '' }
  ]

  const menu_finance = [
    { name: 'Contact', icon: BiPhoneCall, path: '/leads/contacts', id: 1, color: '' },
    { name: 'Follow Up', icon: AiOutlineFileDone, path: '/leads/follow_up', id: 2, color: '' },
    { name: 'Converted', icon: RiBillLine, path: '/leads/converted', id: 3, color: '' },
    { name: 'Hold', icon: MdOutlineHourglassTop, path: '/leads/hold', id: 4, color: '' }
  ]

  const menu_software = [
    { name: 'Contact', icon: BiPhoneCall, path: '/leads/contacts', id: 1, color: '' },
    { name: 'Follow Up', icon: AiOutlineFileDone, path: '/leads/follow_up', id: 2, color: '' },
    { name: 'Converted', icon: RiBillLine, path: '/leads/converted', id: 3, color: '' },
    { name: 'Hold', icon: MdOutlineHourglassTop, path: '/leads/hold', id: 4, color: '' }
  ]

  const menu_fts = [
    { name: 'Contact', icon: BiPhoneCall, path: '/leads/contacts', id: 1, color: '' },
    { name: 'Follow Up', icon: AiOutlineFileDone, path: '/leads/follow_up', id: 2, color: '' },
    { name: 'Converted', icon: RiBillLine, path: '/leads/converted', id: 3, color: '' },
    { name: 'Hold', icon: MdOutlineHourglassTop, path: '/leads/hold', id: 4, color: '' }
  ]




  const menu2 = [
    { name: 'My Landlord', icon: BiLandscape, path: '/leads/landlords', id: 1, color: '' },
    { name: 'Invoice Landlord', icon: GrMultiple, path: '/leads/landlord_invoice', id: 2, color: '' },
  ]

  const menu3 = [
    { name: 'Inspection Review', icon: FcInspection, path: '/leads/inspection_review', id: 1, }
  ]

  const menu1 = [
    { name: 'Daily Tasks', icon: BiFoodMenu, path: `/leads/daily_tasks/daily_tasks`, id: 1, color: '' },
    // {name:'Today Calls',icon:RiUser6Line,path:`/leads/daily_tasks/calls`,id:1,color:''},
    { name: 'Walk Ins', icon: RiWalkLine, path: `/leads/daily_tasks/walk_ins`, id: 2, color: '' },
    // {name:'Property Visits',icon:MdOutlineMeetingRoom,path:`/leads/daily_tasks/property_visit`,id:3,color:''},
    { name: 'Meetings', icon: AiOutlineClockCircle, path: `/leads/daily_tasks/meeting`, id: 3, color: '' },
    // {name:'Scouting',icon:MdOutlineLandscape,path:`/leads/daily_tasks/scouting`,id:4,color:''},
  ]

  const menu4 = [
    { name: 'Daily Task', icon: BiFoodMenu, path: '/leads/daily_tasks/list', id: 1, }
  ]

  return (
    <div className='mr-0 min-h-screen overflow-y-scroll no-scrollbar max-h-screen border-r min-w-44  w-44 max-w-44  px-2' >

      {/* {path !== 'daily_tasks'  && */}
      <div className='mb-4 mt-5'>
        <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Basic Option</h6>

        {user?.department_id?.filter((f) => f?.department_name === 'Transaction Team')?.length > 0 &&
          <>
            {menu.map((m) => (
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
            ))}
          </>}

        {user?.department_id?.filter((f) => f?.department_name === 'Project Team')?.length > 0 &&
          <>
            {menu_projects.map((m) => (
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
            ))}
          </>}

        {user?.department_id?.filter((f) => f?.department_name === 'HR Team')?.length > 0 &&
          <>
            {menu_hr.map((m) => (
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
            ))}
          </>}

        {user?.department_id?.filter((f) => f?.department_name === 'FMS Team')?.length > 0 &&
          <>
            {menu_fms.map((m) => (
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
            ))}
          </>}


        {user?.department_id?.filter((f) => f?.department_name === 'Fidelitus Gallery')?.length > 0 &&
          <>
            {menu_fidelitus_gallery.map((m) => (
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
            ))}
          </>}

        {user?.department_id?.filter((f) => f?.department_name === 'Shilpa Foundation')?.length > 0 &&
          <>
            {menu_shilpa_foundation.map((m) => (
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
            ))}
          </>}


        {user?.department_id?.filter((f) => f?.department_name === 'Digital Media')?.length > 0 &&
          <>
            {menu_digital_media.map((m) => (
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
            ))}
          </>}


        {user?.department_id?.filter((f) => f?.department_name === 'Software Team')?.length > 0 &&
          <>
            {menu_software.map((m) => (
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
            ))}
          </>}


        {user?.department_id?.filter((f) => f?.department_name === 'FTS Team')?.length > 0 &&
          <>
            {menu_fts.map((m) => (
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
            ))}
          </>}


        {user?.department_id?.filter((f) => f?.department_name === 'Corp Team')?.length > 0 &&
          <>
            {menu_corp.map((m) => (
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
            ))}
          </>}

        {user?.department_id?.filter((f) => f?.department_name === 'Backend Team')?.length > 0 &&
          <>
            {menu_backend.map((m) => (
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
            ))}
          </>}

        {user?.department_id?.filter((f) => f?.department_name === 'Finance Team')?.length > 0 &&
          <>
            {menu_finance.map((m) => (
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
            ))}
          </>}

        {user?.department_id?.filter((f) => f?.department_name === 'Transaction Team')?.length > 0 &&
          <>
            <h6 className='border-t pt-4 mt-4 ml-2 mb-2 font-[700] text-[12px]'>Landlord Option</h6>

            {menu2.map((m) => (
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
            ))}
          </>}
      </div>
      {/* } */}

      {user?.department_id?.filter((f) => f?.department_name === 'Transaction Team')?.length > 0 &&
        <div className={` w-full border-t pt-4 `}>
          <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Inspection Review </h6>
          {menu3.map((m) => (
            <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
              {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
              <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
              <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
            </div>
          ))}
        </div>}

      {user?.department_id?.filter((f) => f?.department_name === 'Transaction Team')?.length > 0 &&
        <div className={` w-full border-t pt-4 `}>
          <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>More Option </h6>
          {menu1.map((m) => (
            <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
              {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
              <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
              <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
            </div>
          ))}
        </div>
      }

      {/* {user?.department_id?.filter((f)=>f?.department_name === 'Transaction Team')?.length > 0 &&
        <div className={` w-full border-t pt-4 `}>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Daily Task</h6>
            {menu4.map((m)=>(
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16} />
                 <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>    
            ))}
        </div>
        }     */}


    </div>
  )
}

export default LeadMenu