import React, { useEffect, useState } from 'react'
import FTSLeadMenu from './FTSLeadMenu'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton,Tooltip } from '@mui/material'
import { AiOutlineDelete, AiOutlineEdit, AiOutlineFilter } from 'react-icons/ai'
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonFilledAutoWidth } from '../../components/button';
import { GetFTSLeadsSharedToUsBasedUserFTSService } from '../../services/FTSLeadServices';
import { useSelector } from 'react-redux';
import { DatePicker, Select } from 'antd';
import { GetDepartmentService } from '../../services/DepartmentServices';

function FTSLeadRecievedFromFTS() {

  const {state} = useLocation()
  
  const navigate = useNavigate()  
  const [search,setsearch] = useState({ text: '', from_date: state?.from_date != undefined ? state?.from_date : '', from_date1: state?.from_date1 != undefined ? state?.from_date1 : '', to_date: state?.to_date != undefined ? state?.to_date : '', to_date1: state?.to_date1 != undefined ? state?.to_date1 : '', department: state?.department !== undefined ? state?.department : '',status:state?.status !== undefined ? state?.status : '' })
  const [page,setpage] = useState(1)  
  const [department,setdepartment] = useState([])  
  const [leads,setleads] = useState({pagination:{total:0,totalPages:0,limit:25}})

  let user = useSelector(state=>state.Auth)

  const converted = [
    { label: 'Converted', value: 1 },
    { label: 'Rejected', value: 2 },
    { label: 'Pending', value: 3 },
    { label: 'Progress', value: 4 },
    { label: 'Lead Generated', value: 5 },
  ]

  useEffect(()=>{
    getdata()
    getdepartments()
  },[page])

  
  async function getdepartments() {
     const response = await GetDepartmentService()
     let arr = []
     response.data.datas.forEach((d)=>{
      if(!['Finance Team','Software Team', 'Digital Media']?.includes(d?.department_name)){
        if(user.department_id[0].department_name !== d.department_name){
          arr.push({value:d.id,label:d.department_name})
        }
      }
      setdepartment(arr)
     })
  }


  async function getdata(){
    const response = await GetFTSLeadsSharedToUsBasedUserFTSService(page,search?.text,search?.from_date1,search?.to_date1,search?.department,search?.status)
    setleads(response?.data)
  }

  async function resetfunc() {
    setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',department:'',status:''})
    setpage(1)
    const response = await GetFTSLeadsSharedToUsBasedUserFTSService(1,'','','','','')
    setleads(response?.data)
  }

  // async function deleteData() {
    
  // }

  async function applyfilter() {
    setpage(1)
    const response = await GetFTSLeadsSharedToUsBasedUserFTSService(1,search?.text,search?.from_date1,search?.to_date1,search?.department,search?.status)
    setleads(response?.data)
  }


  return (
    <div className='h-screen max-h-screen'>
       <div className='flex'>
            <div className={`min-w-44`}>
                <FTSLeadMenu />
            </div>
        <div  className="w-[87%] px-4 pt-4">
            <div >
                <div className='flex items-center justify-between border-b pb-2'>
                    <h6 className='font-[700] text-[14px] '>Total Leads Recieved From FTS and Other BU ({leads?.pagination?.total})</h6>
                    <div className='flex items-center'>
                    <div className='flex items-center text-[12px]'>

                        <h6 className='mr-2 font-[600]'>{page == 1 ? leads?.datas?.length > 0 ? 1 : 0 :  (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                        <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                        <div>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                        </div>

                        <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                        <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 
                        <div>
                          <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 mr-2 rounded-md border-slate-300' />
                        </div> 

                        <div className='mr-2'>
                                <Select
                                  bordered={false}
                                  placeholder={'Department'}
                                  className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px] rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none' 
                                  optionFilterProp="children"
                                  onChange={(e)=>setsearch({...search,department:e})}
                                  value={search?.department === '' ? null : search?.department}
                                  options={department}
                              />
                              </div>

                              <div className='mr-2'>
                                <Select
                                  bordered={false}
                                  placeholder={'Status'}
                                  className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px] rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none' 
                                  optionFilterProp="children"
                                  onChange={(e)=>setsearch({...search,status:e})}
                                  value={search?.status === '' ? null : search?.status}
                                  options={converted}
                              />
                              </div>


                        <AiOutlineFilter onClick={applyfilter} size={24} className='cursor-pointer bg-slate-700 p-[5px] rounded text-white' />

                    </div>



            
                    </div>
                </div>

                        {leads?.datas?.length === 0 &&
                            <div className='grid place-items-center mt-20  items-center justify-center'>
                                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
                            </div>
                        }
                
                
                        {leads?.datas?.length > 0 &&
                        <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                            <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                                <h6  className='top-0 bg-white z-50 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Info</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Assigned Department</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Converted</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Summary</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created On</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                            </div>
                        
                            {leads?.datas?.map((d,i)=>(
                            <div  key={d?._id} className={`flex z-50 h-full border-b border-slate-200 z-0`}  >
                                <h6  className='sticky top-0 z-30 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* leads?.pagination?.limit)}</h6>
                                <h6 className='text-[12px] font-[500] w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d?.fts_lead?.company_name}</h6>
                                <h6 className='text-[12px] font-[500] w-[180px] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                                  <div>
                                  <h6>{d?.fts_lead?.contact_name}</h6>  
                                  <span>{d?.fts_lead?.phone} / {d?.fts_lead?.email}  </span>
                                  </div>
                                </h6>
                                <h6 className='text-[12px] font-[500] w-[150px] px-2 py-1 break-all border-r border-slate-200'>
                                    <span className='text-sky-500'>{d?.fts_lead?.lead_owner_department?.department_name}</span>
                                    <h6 className='text-[10px]'>Created By : {d?.fts_lead?.lead_owner?.name}</h6>
                                  {d?.fts_lead?.previously_handled_by != '' && <h6 className='text-[10px] font-[500]'>Previously Handled User : <span className='font-[800]'>{d?.fts_lead?.previously_handled_by}</span></h6>}
                                </h6>

                         
                    
                                <h6 className={`text-[12px] font-[500] w-[150px] px-2 py-1  truncate  border-r  border-slate-200 ${d?.requirment ? 'block' : 'text-[#fff]'}`}>{d?.requirment ? d?.requirment : 'Not Added'}</h6>
                                <h6 className='text-[12px] text-center font-[500] w-[80px] px-2 py-1 truncate border-r  border-slate-200'>{d?.converted === '5' && <span className='bg-orange-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '5' && 'Lead Generated'}</span>} {d?.converted === '1' && <span className='bg-green-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '1' && 'Converted'}</span>} {d?.converted === '2' &&  <span className='bg-red-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '2' && 'Rejected'}</span>} {d?.converted === '3' &&  <span className='bg-yellow-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '3' && 'Pending'}</span>} {d?.converted === '4' &&  <span className='bg-blue-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '4' && 'Progress'}</span>} </h6>
                                <h6 className={`text-[12px] font-[500] w-[150px] px-2 py-1 truncate border-r  border-slate-200 ${d?.remarks ? 'block' : 'text-[#fff]'}`}>{d?.remarks ? d?.remarks : 'Not Added'}</h6>
                                
                                <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>
                                <h6  className='text-[12px] w-[70px]  px-2 py-1 flex'>
                                
                                <Tooltip title="Edit">
                                <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('/fts_leads_assign_remark/list/edit',{state:d})}/></span>
                                </Tooltip>
                    
                                {/* {user?.roles?.includes('delete_data') &&
                                <Tooltip title="Delete">
                                <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{deleteData(d?._id)}}/></span>
                                </Tooltip>} */}
                                </h6>
                            </div>))}
                         </div>}
       </div>     
       </div>     
     </div>
    </div> 
  )
}

export default FTSLeadRecievedFromFTS