import { IconButton } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import GovtMenu from './GovtMenu'
import { DatePicker, Modal, Drawer, Select } from 'antd';
import { BsArrowRepeat } from 'react-icons/bs';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { AiOutlineEdit, AiOutlineDelete, AiOutlineClose } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { CreateCasesFollowupService, DeleteCasesFollowupService, DeleteCasesService, GetCasesFollowupService, GetCasesService, UpdateCasesFollowupService } from '../../services/govtservices/CaseServices';
import toast from 'react-hot-toast';
import { RiBillLine } from 'react-icons/ri';
import { TextAreaInput1, TextInput } from '../../components/input';
import { MdOutlineEventRepeat } from "react-icons/md";

function CasesList() {
  const roles = useSelector(state => state.Auth.roles)
  const [data, setdata] = useState({})
  const [followup, setfollowup] = useState([])
  const [selectedData, setselectedData] = useState({})
  const [selectedData1, setselectedData1] = useState({})

  const [modal, setmodal] = useState(false)
  const [modal1, setmodal1] = useState(false)
  const [modal2, setmodal2] = useState(false)
  const [modal3, setmodal3] = useState(false)

  const [search, setsearch] = useState({ from_date: '', to_date: '', search_text: '', from_date1: '', to_date1: '' })
  const [page, setpage] = useState(1)
  const [step, setstep] = useState(1)

  const navigate = useNavigate()

  useEffect(() => {
    getdata()
  }, [step, page])

  async function getdata() {
    const response = await GetCasesService(page, step, search?.from_date1, search?.to_date1, search?.search_text)
    setdata(response?.data)
    console.log("######### from case", response.data)
  }

  async function applyfilterfunction() {
    setpage(1)
    // step,1,search?.from_date1,search?.to_date1,search?.search_text
    const response = await GetCasesService(page, step, search?.from_date1, search?.to_date1, search?.search_text)
    setdata(response?.data)
  }

  async function resetfunc() {
    setpage(1)
    setsearch({ from_date: '', to_date: '', search_text: '', from_date1: '', to_date1: '' })
    const response = await GetCasesService(1, step, '', '', '')
    setdata(response?.data)
  }

  async function deleteData() {
    const response = await DeleteCasesService(selectedData?._id)
    if (response?.status == 200) {
      toast.success("Deleted Successfully!")
      getdata()
      setmodal(false)
    }
  }

  async function getfollwup(d) {
    const response = await GetCasesFollowupService('case', d?._id)
    setfollowup(response?.data?.datas)
  }

  console.log("@@@@@@@@@@@@@@Case", data)

  async function deleteData1() {
    const response = await DeleteCasesFollowupService(selectedData1?._id)
    if (response?.status == 200) {
      toast.success("Deleted Successfully!")
      let oldData = selectedData?.govtfollowups?.filter((f) => f?._id !== selectedData1?._id)
      setselectedData({ ...selectedData, govtfollowups: oldData })
      setmodal2(false)
      getfollwup(selectedData)
    }
  }

  async function submitDataFollowUp() {

    if (selectedData1?._id === undefined) {
      let sendData = { ...selectedData1 }
      sendData['case'] = selectedData?._id
      const response = await CreateCasesFollowupService(sendData)
      if (response?.status === 201) {
        getdata()
        setselectedData1({ date: '', remarks: '' })
        toast.success("Case Followup Created Successfully!")
        setmodal3(false)
        setmodal2(false)
        setmodal1(false)
      }
    } else {
      let sendData = { ...selectedData1 }
      delete sendData.created_by
      const response = await UpdateCasesFollowupService(selectedData1?._id, sendData)
      if (response?.status === 200) {
        getdata()
        setselectedData1({ date: '', remarks: '' })
        getfollwup(selectedData)
        toast.success("Case Followup Updated Successfully!")
        setmodal3(false)
        setmodal2(false)
        // setmodal1(false)
      }
    }
  }





  return (
    <div className='h-screen flex w-[100%] max-h-screen '>

      <Modal
        keepMounted
        open={modal}
        onClose={() => setmodal(false)}
        width={300}
        footer={false}
        closable={false}

      >
        <div >
          <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
          <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
          <div className='flex justify-end mt-3 '>
            {/* <div className='mr-1 w-full'> */}
            <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={() => setmodal(false)} />
            {/* </div> */}
            <div className='ml-2'>
              <ButtonFilledAutoWidth btnName={'Confirm'} onClick={() => deleteData()} />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        keepMounted
        open={modal2}
        onClose={() => setmodal2(false)}
        width={300}
        footer={false}
        closable={false}

      >
        <div >
          <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
          <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
          <div className='flex justify-end mt-3 '>
            {/* <div className='mr-1 w-full'> */}
            <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={() => setmodal2(false)} />
            {/* </div> */}
            <div className='ml-2'>
              <ButtonFilledAutoWidth btnName={'Confirm'} onClick={() => deleteData1()} />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        keepMounted
        open={modal3}
        onClose={() => setmodal3(false)}
        width={300}
        footer={false}
        closable={false}

      >
        <div >
          <h6 className="font-bold text-[13px] w-full">Create / Edit Followup</h6>
          <h6 className='bg-slate-100 text-[12px] p-2 leading-[14px] font-[400]'>Are you sure want to create or edit the followup for the selected data </h6>


          <TextInput
            label={'Date'}
            name={'date'}
            type="date"
            mandatory={true}
            value={selectedData1?.date}
            handlechange={(e) => setselectedData1({ ...selectedData1, date: e.target.value })}
          />


          <TextAreaInput1
            label={'Remarks'}
            name={'remarks'}
            mandatory={true}
            value={selectedData1?.remarks}
            handlechange={(e) => setselectedData1({ ...selectedData1, remarks: e.target.value })}
          />


          <div className='flex justify-end mt-3 '>
            <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={() => setmodal3(false)} />
            <div className='ml-2'>
              <ButtonFilledAutoWidth btnName={'Confirm'} onClick={() => submitDataFollowUp()} />
            </div>
          </div>
        </div>
      </Modal>

      <Drawer open={modal1} closable={false}>
        <div className='relative'>
          <AiOutlineClose onClick={() => setmodal1(false)} className='absolute right-0' />

          <h6 className='text-[14px] font-[700]'>Followup Tracker ({followup?.length})</h6>
          <h6 className='text-[12px] bg-slate-100 p-2 my-1'>Are you sure  want to check all the followup tracks made for the selected Lead</h6>

          <div className='border-t border-r border-l'>
            {followup?.map((g, i) => (
              <div key={i} className='px-2 py-1 border-b  relative'>
                <h6 className='text-[11px]'><span className='font-[600]'>Follow up Date : </span>{moment(g?.date)?.format('LL')}</h6>

                <h6 className='text-[10.5px] bg-slate-100 p-2'><span>Remarks : </span>{g?.remarks}</h6>
                <h6 className='text-[9px]'><span className='font-[600]'>Created Date : </span>{moment(g?.createdAt)?.format('LLL')}</h6>
                <h6 className='text-[9px]'><span className='font-[600]'>Created By : </span>{g?.created_by?.name}</h6>

                <div className='flex absolute top-0 right-0 bg-white p-1 border-l border-b items-center'>
                  <AiOutlineEdit size={14} className='p-[2px] pr-[4px] border-r' onClick={() => { setselectedData1({ ...g, date: g?.date?.slice(0, 10) }); setmodal3(true) }} />
                  {(roles?.includes('admin') || roles?.includes('lawyer_hod')) &&
                    <AiOutlineDelete size={14} className='p-[2px] pl-[4px]' onClick={() => { setselectedData1(g); setmodal2(true) }} />}
                </div>
              </div>
            ))}
          </div>

        </div>
      </Drawer>

      <div className='flex w-[100%] items-start'>
        <div className='w-44'>
          <GovtMenu />
        </div>
        <div className='ml-5 mt-4 min-w-[84%] max-w-[84%]'>
          <div className='flex items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total Cases List ({data?.pagination?.total})</h6>
            <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit} - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                  <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                  <IconButton onClick={() => { page < data?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>

                </div>
                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, from_date: v, from_date1: v1 }) }} />

                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, to_date: v, to_date1: v1 }) }} />


                <div className='mr-2'>


                  <input id="search_text" placeholder='Search text' type='text' value={search.search_text} onChange={(e) => setsearch({ ...search, search_text: e.target.value })} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />

                </div>

                <ButtonFilledAutoWidth btnName="Add Filter" onClick={() => applyfilterfunction(1)} />
                <h6 className='w-[5px]'></h6>
                <ButtonFilledAutoWidth btnName="Add Data" onClick={() => navigate('create')} />
              </div>




            </div>
          </div>
          <div className='flex  items-center border-b'>
            <h6 onClick={() => { setstep(1); setpage(1) }} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Company</h6>
            <h6 onClick={() => { setstep(2); setpage(1) }} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Litigation</h6>
          </div>
          {data?.datas?.length == 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
              <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' />
              <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
              <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>
          }
          {data?.datas?.length > 0 &&
            <div className='max-h-[82vh] mt-4 border-t w-[100%]  border-l border-r overflow-y-scroll'>
              <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                <h6 className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'> SL NO </h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Name</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[200px]  px-2 py-1 font-[600] text-slate-600 border-r'>Description</h6>
                <h6 className='top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Recieved Date</h6>
                <h6 className='top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Department</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Status</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[200px] px-2 py-1 font-[600] text-slate-600 border-r'>Remarks</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[130px] px-2 py-1 font-[600] text-slate-600 border-r'>Next Hearing Date</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[80px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
              </div>
              {data?.datas?.map((d, i) => (
                <div key={i} className=' flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                  <h6 className=' bg-white  text-[12px] w-[60px]  px-2 py-1 font-[400]  border-r flex  justify-center'> {page === 1 ? i + 1 : (i + 1) + ((page - 1) * data?.pagination?.limit)}</h6>
                  <h6 className='  text-[12px] w-[140px] px-2 py-1 font-[400]  border-r'>{d?.caseBoughtBy}</h6>
                  <h6 className='  text-[12px] w-[200px]  px-2 py-1 font-[400]  border-r'>{d?.caseDescription} <br></br> {(d?.attachment !== null && d?.attachment !== undefined && d?.attachment !== '') && <a href={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.attachment?.replace('public/', '')}`} target='_blank'><span className='text-slate-700 cursor-pointer text-[10px] font-[800] underline'>Download File</span></a>}</h6>
                  <h6 className='  text-[12px] w-[120px]  px-2 py-1 font-[400]  border-r'>{moment(d?.caseRecievedDate)?.format('LL')}</h6>
                  <h6 className='  text-[12px] w-[120px]  px-2 py-1 font-[400]  border-r'>{d?.department?.department_name}</h6>
                  <h6 className='  text-[12px] w-[100px] px-2 py-1 font-[400]  border-r'>{d?.stage}</h6>
                  <h6 className='  text-[12px] w-[200px] px-2 py-1 font-[400]  border-r'>{d?.remarks}</h6>
                  <h6 className='  text-[12px] w-[130px] px-2 py-1 font-[400]  border-r'>{(d?.caseRehearingDate !== '' && d?.caseRehearingDate !== undefined && d?.caseRehearingDate !== null) && moment(d?.caseRehearingDate)?.format('LL')}</h6>
                  <h6 className='  text-[12px] w-[80px]  px-2 py-1 flex font-[600] text-slate-600 sticky right-0'>

                    <MdOutlineEventRepeat size={16} className='mr-2' onClick={() => { setselectedData(d); setselectedData1({ date: '', remarks: '' }); setmodal3(true) }} />
                    <RiBillLine size={14} className='mr-2' onClick={() => { setmodal1(true); setselectedData(d); getfollwup(d) }} />
                    <AiOutlineEdit size={14} className='mr-2' onClick={() => { navigate('edit', { state: d }) }} />
                    {(roles?.includes('admin') || roles?.includes('lawyer_hod')) &&
                      <AiOutlineDelete size={14} onClick={() => { setselectedData(d); setmodal(true) }} />}
                  </h6>
                </div>))}
            </div>}
        </div>
      </div>

    </div>
  )
}

export default CasesList