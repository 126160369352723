import Login from "../../screens/auth/Login";
import DesignationCE from "../../screens/options/designation/DesignationCE";
import DepartmentCE from "../../screens/options/department/DepartmentCE";
import DepartmentList from "../../screens/options/department/DepartmentList";
import DesignationList from "../../screens/options/designation/DesignationList";
import RoleList from "../../screens/options/roles/RoleList";
import RoleCE from "../../screens/options/roles/RoleCE";
import StageRoot from "../../screens/options/stage/StageRoot";
import StageCE from "../../screens/options/stage/StageCE";
import StageList from "../../screens/options/stage/StageList";
import Settings from "../../screens/staticscreens/Settings";
import Support from "../../screens/staticscreens/Support";
import Profile from "../../screens/profile/Profile";
import CSDTList from "../../screens/options/csdt/CSDTList";
import CSDTCE from "../../screens/options/csdt/CSDTCE";
import LeadCE from "../../screens/lead/LeadCE";
import LeadDetail from "../../screens/lead/LeadDetail";
import LeadNotesList from "../../screens/lead/leadoptions/leadnotes/LeadNotesList";
import LeadNotesCE from "../../screens/lead/leadoptions/leadnotes/LeadNotesCE";
import LeadReminderCE from "../../screens/lead/leadoptions/leadreminders/LeadReminderCE";
import LeadReminderlist from "../../screens/lead/leadoptions/leadreminders/LeadReminderlist";
import Leadfollowupslist from "../../screens/lead/leadoptions/leadfollowups/Leadfollowupslist";
import LeadfollowupsCE from "../../screens/lead/leadoptions/leadfollowups/LeadfollowupsCE";
import OnboardList from "../../screens/onboard/OnboardList";
import Onboard from "../../screens/onboard/Onboard";
import PrivacyPolicy from "../../screens/staticscreens/PrivacyPolicy";
import Mainprofile from "../../screens/profile/Mainprofile";
import UpdatePassword from "../../screens/auth/UpdatePassword";
import Account from "../../screens/profile/Account";
import ThemeUser from "../../screens/profile/ThemeUser";
import UserNotification from "../../screens/profile/UserNotification";
import UserOrganization from "../../screens/profile/UserOrganization";
import Accessability from "../../screens/profile/Accessablity";
import LeadBase from "../../screens/lead/LeadBase";
import AdminLeads from "../../screens/admin/AdminLeads";
import Team from "../../screens/admin/team/Team";
import TeamAdd from "../../screens/admin/team/TeamAdd";
import Target from "../../screens/admin/target/Target";
import TargetAdd from "../../screens/admin/target/TargetAdd";
import TeamTarget from "../../screens/admin/team/TeamTarget";
import InvoiceRequest from "../../screens/invoice/InvoiceRequest";
import InvoiceRequestDetail from "../../screens/invoice/InvoiceRequestDetail";
import LeadInvoice from "../../screens/lead/LeadInvoice";
import Teamleads from "../../screens/teamdata/Teamleads";
import InsentiveRequirmentCE from "../../screens/staticscreens/InsentiveRequirmentCE";
import Incentive from "../../screens/incentive/Incentive";
import Payment from "../../screens/invoice/Payment";
import PaymentDetail from "../../screens/invoice/PaymentDetail";
import InsentiveRequirment from "../../screens/admin/insentive/InsentiveRequirment";
import Dashboard from "../../screens/dashboard/Dashboard";
import FinanceDashboard from "../../screens/dashboard/FinanceDashboard";
import FinanceDashboarddata from "../../screens/dashboard/FinanceDashboarddata";
import FiananceList from "../../screens/dashboard/FiananceList";
import LeadLandlord from "../../screens/lead/LeadLandlord";
import TeamTargets from "../../screens/teamdata/TeamTargets";
import FinanceMenu from "../../screens/finance/FinanceMenu";
import DashboardMenu from "../../screens/dashboard/DashboardMenu";
import IncentiveRequirmentList from "../../screens/incentive/IncentiveRequirmentList";
import Finance from "../../screens/finance/Finance";
import DailyTaskCE from "../../screens/lead/dailyTask/DailyTaskCE";
import LandLordCE from "../../screens/lead/LandLordCE";
import LandLordDetail from "../../screens/lead/LandLordDetail";
import PaymentShareRecieved from "../../screens/lead/PaymentShareRecieved";
import FinanceDashboardMain from "../../screens/finance/FinanceDashboardMain";
import LeadPushtoDetail from "../../screens/lead/LeadPushtoDetail";
import InvoicePushtoDetail from "../../screens/invoice/InvoicePushtoDetail";
import AdminDashboard from '../../screens/admin/AdminDashboard'
import TeamTargetsIndivisualdetail from "../../screens/teamdata/TeamTargetsIndivisualdetail";
import InsentiveDashboard from "../../screens/dashboard/InsentiveDashboard";
import DownloadReport from "../../screens/staticscreens/DownloadReport";
import EmployeeTargets from "../../screens/admin/incentive_history/EmployeeTargets";
import EmployeeTargetsDetailInsentive from "../../screens/admin/incentive_history/EmployeeTargetsDetailInsentive";
import EmployeeTargetsDetailInsentivedetail from "../../screens/admin/incentive_history/EmployeeTargetsDetailInsentivedetail";
import InvoiceRequestModify from "../../screens/controller/InvoiceRequestModify";
import InvoiceAttachmentModify from "../../screens/controller/InvoiceAttachmentModify";
import PaymentShare from "../../screens/controller/PaymentShare";
import GalleryAppBar from "../../screens/gallery/GalleryAppBar";
import GalleryMainPage from "../../screens/gallery/GalleryMainPage";
import VisitorList from "../../screens/gallery/visitors/VisitorList";
import VisitorCE from "../../screens/gallery/visitors/VisitorCE";
import GalleryProductList from "../../screens/gallery/products/ProductList";
import GalleryProductCE from "../../screens/gallery/products/ProductCE";
import GalleryProducCategorytList from "../../screens/gallery/category/CategoryList";
import GalleryProductCategoryCE from "../../screens/gallery/category/CategoryCE";
import TaskDashboard from "../../screens/tasks/TaskDashboard";
import TaskList from "../../screens/tasks/Tasklist";
import TaskCE from "../../screens/tasks/TaskCE";
import { GetGalleryProductDetail } from "../../services/Gallery/GalleryProductServices";
import ProductDetail from "../../screens/gallery/products/ProductDetail";
import FidelitusLeads from "../../screens/dashboard/FidelitusLeads";
import FidelitusVerticalLeads from "../../screens/dashboard/FidelitusVerticalLeads";
import AssignLeadMenu from "../../screens/fidelitus_lead/AssignLeadMenu";
import AssignLeadBase from "../../screens/fidelitus_lead/AssignLeadBase";
import AssignLeadCE from "../../screens/fidelitus_lead/AssignLeadCE";
import ManageLeadlist from "../../screens/fidelitus_lead/ManageLeadList";
import AssignLeadRemark from "../../screens/fidelitus_lead/AssignLeadRemark";
import TeamTaskCE from "../../screens/tasks/TeamTaskCE";
import TaskBoard from "../../screens/tasks/TaskBoard";
import TeamTaskRoot from "../../screens/tasks/TeamTaskRoot";
import AtsMenu from "../../screens/ats/AtsMenu";
import AtsClientManagment from "../../screens/ats/AtsClientManagment";
import AtsJobDescription from "../../screens/ats/AtsJobDescription";
import AtsEmployeeManagment from "../../screens/ats/AtsEmployeeManagment";
import AtsClientManagmentCE from "../../screens/ats/AtsClientManagmentCE";
import AtsClientManagmentDetail from "../../screens/ats/AtsClientManagmentDetail";
import AtsJobDescriptionCE from "../../screens/ats/AtsJobDescriptionCE";
import AtsJobDescriptionDetail from "../../screens/ats/AtsJobDescriptionDetail";
import AtsEmployeeManagmentCE from "../../screens/ats/AtsEmployeeManagmentCE";
import AtsDashboard from "../../screens/ats/AtsDashboard";
import AdminReminderMenu from "../../screens/admin_reminder/AdminReminderMenu";
import AdminReminderCE from "../../screens/admin_reminder/AdminReminderCE";
import AdminReminderRoot from "../../screens/admin_reminder/AdminReminderRoot";
import AtsTrackOption from "../../screens/ats/AtsTrackOption";
import AtsTrackOptionCE from "../../screens/ats/AtsTrackOptionCE";
import AtsTeamReport from "../../screens/ats/AtsTeamReport";
import AtsOverallReport from "../../screens/ats/AtsOverallReport";
import AtsCandidateManage from "../../screens/ats/AtsCandidateManage";
import ProfitLossMenu from "../../screens/profit_loss/ProfiltLossMenu";
import ProfitLossList from "../../screens/profit_loss/ProfitLossList";
import ProfitLossListCE from "../../screens/profit_loss/ProfitLossListCE";
import AdminProfitLoss from "../../screens/dashboard/AdminProfitLoss";
import WeeklyReport from "../../screens/week_report/WeeklyReport";
import SelfOnboard from "../../screens/onboard/SelfOnboard";
import ExitForm from "../../screens/onboard/ExitForm";
import ExitMenu from "../../screens/exit/ExitMenu";
import ExitMenuList from "../../screens/exit/ExitMenuList";
import EntryMenuList from "../../screens/exit/EntryMenuList";
import ExitMenuListEdit from "../../screens/exit/ExitMenuListEdit";
import OnboardDetail from "../../screens/onboard/OnboardDetail";
import EntryMenuListEdit from "../../screens/exit/EntryMenuListEdit";
import ProfileDocuments from "../../screens/profile/ProfileDocuments";
import TeamDailyTask from "../../screens/teamdata/TeamDailyTask";
import Collegues from "../../screens/profile/Collegues";
import LeadInspectionReview from "../../screens/lead/LeadInspectionReviews";
import LeadInspectionReviewDetail from "../../screens/lead/LeadInspectionReviewDetail";
import LeadInspectionReviewParamsDetail from "../../screens/lead/LeadInspectionReviewParamsDetail";
import AdminInspectionReview from "../../screens/admin/AdminInspectionReview";
import AdminInspectionReviewDetail from "../../screens/admin/AdminInspectionReviewDetail";
import AtsEmployeeListBasedOnHiringStage from "../../screens/ats/AtsEmployeeListBasedOnHiringStage";
import CallsMenu from "../../screens/calls/CallsMenu";
import CallsLeadlist from "../../screens/calls/CallsLeadlist";
import CallsLeadBase from "../../screens/calls/CallsLeadBase";
import CallsLeadCE from "../../screens/calls/CallsLeadCE";
import Reimbusment from "../../screens/profile/Reimbusment";
import ReimbusmentCE from "../../screens/profile/ReimbusmentCE";
import IlsMenu from "../../screens/ils/IlsMenu";
import IlsLandData from "../../screens/ils/IlsLandData";
import IlsLandDataCE from "../../screens/ils/IlsLandDataCE";
import IlsLandRequirment from "../../screens/ils/IlsLandRequirment";
import IlsLandRequirmentCE from "../../screens/ils/IlsLandRequirmentCE";
import IlsLandDataDetail from "../../screens/ils/IlsLandDataDetail";
import IlsLandRequirmentDetail from "../../screens/ils/IlsLandRequirmentDetail";
import IlsLandDataAdmin from "../../screens/ils/IlsLandDataAdmin";
import IlsLandRequirmentAdmin from "../../screens/ils/IlsLandRequirmentAdmin";
import AdminTeamTasks from "../../screens/tasks/AdminTeamTasks";
import ProfileLetter from "../../screens/profile/ProfileLetter";
import Reporting from "../../screens/admin/reporting/Reporting";
import ReportingCE from "../../screens/admin/reporting/ReportingCE";
import FcaresMenu from "../../screens/fcares/FcaresMenu";
import FCaresUserList from "../../screens/fcares/fcaresuser/FCaresUserList";
import FCaresReportList from "../../screens/fcares/fcaresreport/FCaresReportList";
import FCaresUserCE from "../../screens/fcares/fcaresuser/FCaresUserCE";
import FCaresPropertyList from "../../screens/fcares/fcaresproperty/FcaresPropertyList";
import FCaresPropertyCE from "../../screens/fcares/fcaresproperty/FCaresPropertyCE";
import RevenueCollection from "../../screens/admin/collection/RevenueCollection";
import AdminCalls from "../../screens/admin/AdminCalls";
import FcaresMaintanenceList from "../../screens/fcares/fcaresmaintainence/FcaresMaintanenceList";
import FCaresTicketList from "../../screens/fcares/fcarestickets/FCaresTicketList";
import FCaresTicketCE from "../../screens/fcares/fcarestickets/FCaresTicketCE";
import FCaresUpcomingBillList from "../../screens/fcares/fcaresupcomingbill/FCaresUpcomingBillList";
import FCaresUpcomingBillCE from "../../screens/fcares/fcaresupcomingbill/FCaresUpcomingBillCE";
import FCaresTenantList from "../../screens/fcares/fcarestenants/FCaresTenantList";
import FCaresTenantCE from "../../screens/fcares/fcarestenants/FCaresTenantCE";
import DatabaseMenu from "../../screens/database/DatabaseMenu";
import MdFollowUpList from "../../screens/md_follow_up/MdFollowUpList";
import MdFollowUpMenu from "../../screens/md_follow_up/MdFollowUpMenu";
import MdFollowUpCE from "../../screens/md_follow_up/MdFollowUpCE";
import LeadDailyTask from "../../screens/lead/dailyTask/LeadDailyTask";
import LeadDailyTaskCE from "../../screens/lead/dailyTask/LeadDailyTaskCE";
import FCaresPropertyListAttachment from "../../screens/fcares/fcaresproperty/FCaresPropertyListAttachment";
import PlotTypeList from "../../screens/database/database_options/plot_type/PlotTypeList";
import PlotTypeCE from "../../screens/database/database_options/plot_type/PlotTypeCE";
import PropertyTypeCE from "../../screens/database/database_options/property_type/PropertyTypeCE";
import PropertyTypeList from "../../screens/database/database_options/property_type/PropertyTypeList";
import KathaTypeList from "../../screens/database/database_options/katha_type/KathaTypeList";
import KathaTypeCE from "../../screens/database/database_options/katha_type/KathaTypeCE";
import PropertyAmenitiesList from "../../screens/database/database_options/property_amenities/PropertyAmenitiesList";
import PropertyAmenitiesCE from "../../screens/database/database_options/property_amenities/PropertyAmenitiesCE";
import OwnerTypeList from "../../screens/database/database_options/owner_type/OwnerTypeList";
import OwnerTypeCE from "../../screens/database/database_options/owner_type/OwnerTypeCE";
import PropertyZoneList from "../../screens/database/database_options/property_zone_type/PropertyZoneList";
import PropertyZoneCE from "../../screens/database/database_options/property_zone_type/PropertyZoneCE";
import ApprovedTypeList from "../../screens/database/database_options/approved_type/ApprovedTypeList";
import ApprovedTypeCE from "../../screens/database/database_options/approved_type/ApprovedTypeCE";
import ConversionTypeList from "../../screens/database/database_options/conversion_type/ConversionTypeList";
import ConversionTypeCE from "../../screens/database/database_options/conversion_type/ConversionTypeCE";
import FurnishTypeList from "../../screens/database/database_options/furnish_type/FurnishTypeList";
import FurnishTypeCE from "../../screens/database/database_options/furnish_type/FurnishTypeCE";
import LocationBasedZoneList from "../../screens/database/database_options/location_based_zone/LocationBasedZoneList";
import LocationBasedZoneCE from "../../screens/database/database_options/location_based_zone/LocationBasedZoneCE";
import PropertyStatusList from "../../screens/database/database_options/property_status/PropertyStatusList";
import PropertyStatusCE from "../../screens/database/database_options/property_status/PropertyStatusCE";
import ManagedOfficeList from "../../screens/database/commercial/ManagedOfficeList";
import ManagedOfficeCE from "../../screens/database/commercial/ManagedOfficeCE";
import OfficeSpaceList from "../../screens/database/commercial/OfficeSpaceList";
import OfficeSpaceCE from "../../screens/database/commercial/OfficeSpaceCE";
import Outstandinglist from "../../screens/outstanding/Outstandinglist";
import PropertyCategoryList from "../../screens/database/database_options/property_category/PropertyCategoryList";
import PropertyCategoryCE from "../../screens/database/database_options/property_category/PropertyCategoryCE";
import AtsWebsiteManagement from "../../screens/ats/AtsWebsiteManagement";
import AtsWebsiteManagmentCE from "../../screens/ats/AtsWebsiteManagmentCE";
import MomTaskList from "../../screens/tasks/MomTasklist";
import MomCE from "../../screens/tasks/MomCE";
import AdminMomTaskList from "../../screens/tasks/AdminMomTaskList";
import FCaresServicesList from "../../screens/fcares/fcaresamenities/FCaresServicesList";
import FCaresServicesCE from "../../screens/fcares/fcaresamenities/FCaresServicesCE";
import FCaresQueryList from "../../screens/fcares/fcaresquery/FCaresQueryList";
import FCaresQueryCE from "../../screens/fcares/fcaresquery/FCaresQueryCE";
import FcareDashboard from "../../screens/fcares/FcareDashboard";
import FinanceReimbusmentList from "../../screens/finance/FinanceReimbusmentList";
import ApprovalReimbusmentCE from "../../screens/finance/FinanceReimbusmentCE";
import HeadReimbusmentlist from "../../screens/dashboard/HeadReimbusmentlist";
import HeadReimbusmentCE from "../../screens/dashboard/HeadReimbusmentCE";
import Agreementlist from "../../screens/agreement_followup/Agreementlist";
import AgreementFollowUpMenu from "../../screens/agreement_followup/AgreementFollowUpMenu";
import AgreementlistCE from "../../screens/agreement_followup/AgreementlistCE";
import AgreementFollowuplistuser from "../../screens/agreement_followup/AgreementFollowuplistuser";
import NMRlist from "../../screens/nmr/NMRlist";
import NMRMenu from "../../screens/nmr/NMRMenu";
import NMRCE from "../../screens/nmr/NMRCE";
import NMRAdminlist from "../../screens/nmr/NMRAdminlist";
import WebsiteMenu from "../../screens/website/WebsiteMenu";
import HRStageList from "../../screens/website/hr/stage/HRStageList";
import HRStageCE from "../../screens/website/hr/stage/HRStageCE";
import HRBlogList from "../../screens/website/hr/blogs/HRBlogList";
import HRBlogCE from "../../screens/website/hr/blogs/HRBlogCE";
import HRArticalList from "../../screens/website/hr/artical/HRArticalList";
import HRArticalCE from "../../screens/website/hr/artical/HRArticalCE";
import FMSStageList from "../../screens/website/fms/stage/FMSStageList";
import FMSStageCE from "../../screens/website/fms/stage/FMSStageCE";
import FMSBlogList from "../../screens/website/fms/blogs/FMSBlogList";
import FMSBlogCE from "../../screens/website/fms/blogs/FMSBlogCE";
import ProjectStageList from "../../screens/website/project/stage/ProjectStageList";
import ProjectStageCE from "../../screens/website/project/stage/ProjectStageCE";
import ProjectBlogList from "../../screens/website/project/blogs/ProjectBlogList";
import ProjectBlogCE from "../../screens/website/project/blogs/ProjectBlogCE";
import TransactionBlogCE from "../../screens/website/transaction/blogs/TransactionBlogCE";
import TransactionBlogList from "../../screens/website/transaction/blogs/TransactionBlogList";
import TransactionStageCE from "../../screens/website/transaction/stage/TransactionStageCE";
import TransactionStageList from "../../screens/website/transaction/stage/TransactionStageList";
import ProjectVideoList from "../../screens/website/project/video/ProjectVideoList";
import ProjectVideoCE from "../../screens/website/project/video/ProjectVideoCE";
import GovtMenu from "../../screens/govt_case_notice/GovtMenu";
import CasesList from "../../screens/govt_case_notice/CasesList";
import CasesListCE from "../../screens/govt_case_notice/CasesListCE";
import NoticeList from "../../screens/govt_case_notice/NoticeList";
import NoticeListCE from "../../screens/govt_case_notice/NoticeListCE";
import OutstandingDetail from "../../screens/outstanding/OutstandingDetail";
import ShilpaFoundationMenu from "../../screens/shilpa_foundation/ShilpaFoundationMenu";
import ShilpaFoundationEventList from "../../screens/shilpa_foundation/ShilpaFoundationEventList";
import ShilpaFoundationEventListCE from "../../screens/shilpa_foundation/ShilpaFoundationEventListCE";
import ShilpaFoundationUserList from "../../screens/shilpa_foundation/ShilpaFoundationUserList";
import ShilpaFoundationUserListCE from "../../screens/shilpa_foundation/ShilpaFoundationUserListCE";
import ShilpaFoundationEventUserList from "../../screens/shilpa_foundation/ShilpaFoundationEventUserList";
import FidelitusGalleryEventList from "../../screens/gallery/visitorapp/FidelitusGalleryEventList";
import FidelitusGalleryEventListCE from "../../screens/gallery/visitorapp/FidelitusGalleryEventListCE";
import FidelitusGalleryUserList from "../../screens/gallery/visitorapp/FidelitusGalleryUserList";
import FidelitusGalleryUserListCE from "../../screens/gallery/visitorapp/FidelitusGalleryUserListCE";
import FidelitusGalleryEventUserList from "../../screens/gallery/visitorapp/FidelitusGalleryEventUserList";
import FidelitusCorpEventList from "../../screens/corp_options/FidelitusCorpEventList";
import FidelitusCorpEventListCE from "../../screens/corp_options/FidelitusCorpEventListCE";
import FidelitusCorpUserListCE from "../../screens/corp_options/FidelitusCorpUserListCE";
import FidelitusCorpUserList from "../../screens/corp_options/FidelitusCorpUserList";
import FidelitusCorpEventUserList from "../../screens/corp_options/FidelitusCorpEventUserList";
import FidelitusCorpVisitorList from "../../screens/corp_options/FidelitusCorpVisitorList";
import FidelitusCorpVisitorListCE from "../../screens/corp_options/FidelitusCorpVisitorListCE";
import CORPStageList from "../../screens/website/corp/stage/CORPStageList";
import CORPStageCE from "../../screens/website/corp/stage/CORPStageCE";
import CORPBlogList from "../../screens/website/corp/blogs/CORPBlogList";
import CORPBlogCE from "../../screens/website/corp/blogs/CORPBlogCE";
import ProjectJobOpeningCE from "../../screens/website/project/job_opening/ProjectJobOpeningCE";
import ProjectJobOpeningList from "../../screens/website/project/job_opening/ProjectJobOpeningList";
import ProjectJobApplyList from "../../screens/website/project/job_apply_based_opening/ProjectJobApplyList";
import ProjectJobApplyCE from "../../screens/website/project/job_apply_based_opening/ProjectJobApplyCE";
import ProjectJobApplyBasedJobList from "../../screens/website/project/job_apply_based_opening/ProjectJobApplyBasedJobList";
import FCaresReportDetail from "../../screens/fcares/fcaresreport/FCaresReportDetail";
import InductionList from "../../screens/dashboard/InductionList";
import MdLeadsData from "../../screens/dashboard/MdLeadsData";
import CoursesList from "../../screens/dashboard/CoursesList";
import SuperVisorList from "../../screens/dashboard/SuperVisorList";
import MDMeetlist from "../../screens/tasks/MDMeetlist";
import MDMeetCE from "../../screens/tasks/MDMeetCE";
import ConveyanceVochuerList from "../../screens/finance/finance_forms/ConveyanceVochuerList";
import ConveyanceVochuerCE from "../../screens/finance/finance_forms/ConveyanceVochuerCE";
import SupportTicketMenu from "../../screens/support_ticket/SupportTicketMenu";
import SupportTicketList from "../../screens/support_ticket/SupportTicketList";
import SupportTicketCE from "../../screens/support_ticket/SupportTicketCE";
import ReceptionMenu from "../../screens/reception/ReceptionMenu";
import CabUsageList from "../../screens/reception/cab_usage/CabUsageList";
import CabUsageCE from "../../screens/reception/cab_usage/CabUsageCE";
import StaffMomentCE from "../../screens/reception/staff_moment/StaffMomentCE";
import StaffMomentList from "../../screens/reception/staff_moment/StaffMomentList";
import StaffMomentApprovalList from "../../screens/reception/staff_approval/StaffMomentApprovalList";
import ItMenu from "../../screens/it/ItMenu";
import ItDashboard from "../../screens/it/ItDashboard";
import FeatureOptionList from "../../screens/it/features_option/FeatureOptionList";
import FeatureOptionCE from "../../screens/it/features_option/FeatureOptionCE";
import InventoryOptionList from "../../screens/it/inventory_option/InventoryOptionList";
import InventoryOptionCE from "../../screens/it/inventory_option/InventoryOptionCE";
import AutoTaskList from "../../screens/profile/AutoTaskList";
import SupportOptionList from "../../screens/it/support_option/SupportOptionList";
import UpcomingPurchaseOptionList from "../../screens/it/purchase_option/UpcomingPurchaseOptionList";
import UpcomingPurchaseOptionCE from "../../screens/it/purchase_option/UpcomingPurchaseOptionCE";
import InventoryPurchaseOptionList from "../../screens/it/purchase_option/InventoryPurchaseOptionList";
import InventoryPurchaseOptionCE from "../../screens/it/purchase_option/InventoryPurchaseOptionCE";
import AssignAssetOptionList from "../../screens/it/assign_asset_option/AssignAssetOptionList";
import AssignAssetOptionCE from "../../screens/it/assign_asset_option/AssignAssetOptionCE";
import KRAList from "../../screens/kra/kraList";
import KRACE from "../../screens/kra/KRACE";
import MyKRA from "../../screens/profile/mykra";
import MyAsset from "../../screens/profile/MyAsset";
import AssignAssetOptionDetail from "../../screens/it/assign_asset_option/AssignAssetOptionDetail";
import MDCallsMenu from "../../screens/md_calls/MDCallsMenu";
import MDCallsLeadBase from "../../screens/md_calls/MDCallsLeadBase";
import MDCallsLeadCE from "../../screens/md_calls/MDCallsLeadCE";
import CalendarMenu from "../../screens/calendar/CalendarMenu";
import Calendar from "../../screens/calendar/Calendar";
import InventoryOptionDetail from "../../screens/it/inventory_option/InventoryOptionDetail";
import BackendTeamTasks from "../../screens/tasks/BackendTeamTasks";
import AppUpdateList from "../../screens/staticscreens/AppUpdateList";
import AppUpdateCE from "../../screens/staticscreens/AppUpdateCE";
import CalendarCE from "../../screens/calendar/CalendarCE";
import SupportOptionCE from "../../screens/it/support_option/SupportOptionCE";
import DashboardReport from "../../screens/dashboard/DashboardReport";
import CalendarReportDashboard from "../../screens/calendar/CalendarReportDashboard";
import CalendarReport from "../../screens/dashboard/CalendarReport";
import FTSLeadlist from "../../screens/fts_lead/FTSLeadlist";
import FTSLeadMenu from "../../screens/fts_lead/FTSLeadMenu";
import FTSLeadShared from "../../screens/fts_lead/FTSLeadShared";
import FTSDashboard from "../../screens/fts_lead/FTSDashboard";
import FTSLeadCE from "../../screens/fts_lead/FTSLeadCE";
import FTSLeadRecieved from "../../screens/fts_lead/FTSLeadRecieved";
import FTSLeadSharedToFTS from "../../screens/fts_lead/FTSLeadSharedToFTS";
import FTSLeadRecievedFromFTS from "../../screens/fts_lead/FTSLeadRecievedFromFTS";
import FTSLeadRecievedFromFTSRemark from "../../screens/fts_lead/FTSLeadRecievedFromFTSRemarks";
import FTSLeadRemark from "../../screens/fts_lead/FTSLeadRemark";
import RevenueForecastList from "../../screens/finance/finance_forms/RevenueForecastList";
import RevenueForecastCE from "../../screens/finance/finance_forms/RevenueForecastCE";
import ManagedOfficeDetail from "../../screens/database/commercial/ManagedOfficeDetail";
import OfficeSpaceDetail from "../../screens/database/commercial/OfficeSpaceDetail";
import FTSVerticalReportList from "../../screens/fts_vertical_report/FTSVerticalReportList";
import FTSVerticalReportCE from "../../screens/fts_vertical_report/FTSVerticalReportCE";
import FTSVerticalReportDashboard from "../../screens/fts_vertical_report/FTSVerticalReportDashboard";
import FTSVerticalMonthlyMeetingReviewList from "../../screens/fts_vertical_report/FTSVerticalMonthlyMeetingReviewList";
import FTSVerticalMonthlyMeetingReviewCE from "../../screens/fts_vertical_report/FTSVerticalMonthlyMeetingReviewCE";
import PropertyFacilityCE from "../../screens/database/database_options/property_facility/PropertyFacilityCE";
import PropertyFacilityList from "../../screens/database/database_options/property_facility/PropertyFacilityList";
import DBDashboard from "../../screens/database/DBDashboard";
import RequirmentInfoList from "../../screens/database/requirmentInfo/RequirmentInfoList";
import RequirmentInfoListCE from "../../screens/database/requirmentInfo/RequirmentInfoListCE";
import FTSVerticalLead from "../../screens/fts_lead/FTSVerticalLead";
import FTSVerticalLeadCE from "../../screens/fts_lead/FTSVerticalLeadCE";
import FTSMonthlyReview from "../../screens/fts_lead/FTSMonthlyReview";
import FTSCallslist from "../../screens/fts_lead/FTSCallsList";
import FTSCallsCE from "../../screens/fts_lead/FTSCallsCE";
import FTSleadsAnalytics from "../../screens/fts_lead/FTSleadsAnalytics";
import FTSleadsAnalyticsDetail from "../../screens/fts_lead/FTSleadsAnalyticsDetail";
import FTSLeadShareEdit from "../../screens/fts_lead/FTSLeadShareEdit";
import FTSVerticalAnalytics from "../../screens/fts_lead/FTSVerticalAnalytics";
import FTSLeadSharedToFTSUserDetail from "../../screens/fts_lead/FTSLeadSharedToFTSUserDetail";
import TeamLeads1 from "../../screens/teamdata/Teamleads1";
import TeamLeads from "../../screens/teamdata/Teamleads";

export const routes = [
    {
        path: "/",
        component: <Login />,
        private: false,
        roles: []
    },
    {
        path: "/settings/department",
        component: <DepartmentList />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin', 'onboard_executive']
    },
    {
        path: "/settings/app_update",
        component: <AppUpdateList />,
        private: true,
        roles: ['admin',]
    },
    {
        path: "/settings/app_update/:type",
        component: <AppUpdateCE />,
        private: true,
        roles: ['admin',]
    },
    {
        path: "/settings/kra",
        component: <KRAList />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin',]
    },
    {
        path: "/settings/kra/:type",
        component: <KRACE />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin',]
    },
    {
        path: "/dashboard/my_dashboard",
        component: <Dashboard />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/dashboard_report",
        component: <DashboardReport />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/calendar_report",
        component: <CalendarReport />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/profit_loss_admin",
        component: <AdminProfitLoss />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/weekly_report",
        component: <WeeklyReport />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/reporting_manager_weekly_report",
        component: <WeeklyReport />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/weekly_report1",
        component: <WeeklyReport />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/my_revenue",
        component: <PaymentShareRecieved />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/employee_target",
        component: <EmployeeTargets />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/employee_target/list",
        component: <EmployeeTargetsDetailInsentive />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/employee_target/list/detail",
        component: <EmployeeTargetsDetailInsentivedetail />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/fidelitus_leads",
        component: <FidelitusLeads />,
        private: true,
        roles: []
    },
    {
        path: "/calendar",
        component: <CalendarMenu />,
        private: true,
        roles: []
    },
    {
        path: "/calendar/list",
        component: <Calendar />,
        private: true,
        roles: []
    },
    {
        path: "/calendar/dashboard",
        component: <CalendarReportDashboard />,
        private: true,
        roles: []
    },
    {
        path: "/calendar/list/:type",
        component: <CalendarCE />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/fidelitus_vertical_leads",
        component: <FidelitusVerticalLeads />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/admin_inspection_review",
        component: <AdminInspectionReview />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/admin_inspection_review/detail",
        component: <AdminInspectionReviewDetail />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard",
        component: <DashboardMenu />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/md_leads_data",
        component: <MdLeadsData />,
        private: true,
        roles: []
    },
    {
        path: "/settings/finance_dashboard",
        component: <FinanceDashboard />,
        private: true,
        roles: []
    },
    {
        path: "/settings/finance_dashboard_data",
        component: <FiananceList />,
        private: true,
        roles: []
    },
    {
        path: "/finance/finance_dashboard_data",
        component: <FiananceList />,
        private: true,
        roles: []
    },
    {
        path: "/finance/dashboard",
        component: <FinanceDashboardMain />,
        private: true,
        roles: []
    },
    {
        path: "/finance/finance_dashboard",
        component: <FinanceDashboard />,
        private: true,
        roles: []
    },
    {
        path: "/finance/conveyance_vochuer",
        component: <ConveyanceVochuerList />,
        private: true,
        roles: []
    },
    {
        path: "/profile/auto_task_list",
        component: <AutoTaskList />,
        private: true,
        roles: []
    },
    {
        path: "/profile/conveyance_vochuer",
        component: <ConveyanceVochuerList />,
        private: true,
        roles: []
    },
    {
        path: "/finance/conveyance_vochuer/:type",
        component: <ConveyanceVochuerCE />,
        private: true,
        roles: []
    },
    {
        path: "/profile/conveyance_vochuer/:type",
        component: <ConveyanceVochuerCE />,
        private: true,
        roles: []
    },
    {
        path: "/settings/finance_dashboard_data/:type",
        component: <FinanceDashboarddata />,
        private: true,
        roles: []
    },
    {
        path: "/finance/finance_dashboard_data/:type",
        component: <FinanceDashboarddata />,
        private: true,
        roles: []
    },
    {
        path: "/settings/department/create",
        component: <DepartmentCE />,
        private: true,
        roles: ['controller', 'admin']
    },
    {
        path: "/settings/department/edit",
        component: <DepartmentCE />,
        private: true,
        roles: ['controller', 'admin']
    },
    {
        path: "/settings/designation",
        component: <DesignationList />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/designation/create",
        component: <DesignationCE />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/designation/edit",
        component: <DesignationCE />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/roles",
        component: <RoleList />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/roles/create",
        component: <RoleCE />,
        private: true,
        roles: ['controller', 'admin']
    },
    {
        path: "/settings/roles/edit",
        component: <RoleCE />,
        private: true,
        roles: ['controller', 'admin']
    },
    {
        path: "/settings/stages",
        component: <StageRoot />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/stages/:type",
        component: <StageList />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/stages/:type/create",
        component: <StageCE />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/stages/:type/edit",
        component: <StageCE />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/incentive_requirment",
        component: <IncentiveRequirmentList />,
        private: true,
        roles: ['controller', 'admin']
    },
    {
        path: "/settings/incentive_requirment/:type",
        component: <InsentiveRequirmentCE />,
        private: true,
        roles: ['controller', 'admin']
    },
    {
        path: "/settings",
        component: <Settings />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin', 'onboard_executive']
    },
    {
        path: "/support",
        component: <Support />,
        private: true,
        roles: []
    },
    {
        path: "/profile",
        component: <Mainprofile />,
        private: true,
        roles: []
    },
    {
        path: "/profile/kra",
        component: <MyKRA />,
        private: true,
        roles: []
    },
    {
        path: "/profile/assets",
        component: <MyAsset />,
        private: true,
        roles: []
    },
    {
        path: "/settings/invoice_request",
        component: <InvoiceRequestModify />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/invoice_attachments",
        component: <InvoiceAttachmentModify />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/csdt_list",
        component: <CSDTList />,
        private: true,
        roles: ['controller', 'admin']
    },
    {
        path: "/settings/csdt_list/create",
        component: <CSDTCE />,
        private: true,
        roles: ['controller', 'admin']
    },
    {
        path: "/settings/csdt_list/edit",
        component: <CSDTCE />,
        private: true,
        roles: ['controller', 'admin']
    },
    {
        path: "/leads/contacts",
        component: <LeadBase />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/inspection_review",
        component: <LeadInspectionReview />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/inspection_review/detail",
        component: <LeadInspectionReviewDetail />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/assign_leads",
        component: <AssignLeadMenu />,
        private: true,
        roles: ['admin', 'md_lead', 'hod', 'lead_handler']
    },
    {
        path: "/calls",
        component: <CallsMenu />,
        private: true,
        roles: []
    },
    {
        path: "/calls/list",
        component: <CallsLeadBase />,
        private: true,
        roles: []
    },
    {
        path: "/calls/list/:type",
        component: <CallsLeadCE />,
        private: true,
        roles: []
    },
    {
        path: "/md_calls",
        component: <MDCallsMenu />,
        private: true,
        roles: ['admin', 'tele_caller']
    },
    {
        path: "/md_calls/list",
        component: <MDCallsLeadBase />,
        private: true,
        roles: ['admin', 'tele_caller']
    },
    {
        path: "/md_calls/list/:type",
        component: <MDCallsLeadCE />,
        private: true,
        roles: ['admin', 'tele_caller']
    },
    {
        path: "/manage_leads/list",
        component: <AssignLeadMenu />,
        private: true,
        roles: ['admin', 'md_lead']
    },
    {
        path: "/assign_leads/list",
        component: <AssignLeadBase />,
        private: true,
        roles: ['admin', 'md_lead', 'hod', 'lead_handler']
    },
    {
        path: "/assign_leads/list/:type",
        component: <AssignLeadCE />,
        private: true,
        roles: ['admin', 'md_lead', 'hod', 'lead_handler']
    },
    {
        path: "/leads/options_shared",
        component: <LeadBase />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/inspections",
        component: <LeadBase />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/loi_agreement",
        component: <LeadBase />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/invoice_raised",
        component: <LeadBase />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/hold",
        component: <LeadBase />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/landlords",
        component: <LeadBase />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/landlords/create",
        component: <LandLordCE />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/landlords/edit",
        component: <LandLordCE />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/landlords/detail",
        component: <LandLordDetail />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/:type",
        component: <LeadBase />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/:type/detail",
        component: <LeadDetail />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/invoice_raised/detail/:id",
        component: <LeadPushtoDetail />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/:type/detail/notes",
        component: <LeadNotesList />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/:type/detail/reminder",
        component: <LeadReminderlist />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/leads/:type/detail/notes/create",
        component: <LeadNotesCE />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/:type/detail/reminder/create",
        component: <LeadReminderCE />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/:type/detail/followup",
        component: <Leadfollowupslist />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/:type/detail/followup/create",
        component: <LeadfollowupsCE />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/:type/detail/followup/edit",
        component: <LeadfollowupsCE />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/:type/create",
        component: <LeadBase />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/:type/edit",
        component: <LeadBase />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/daily_tasks/list",
        component: <LeadDailyTask />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/daily_tasks/list/:type",
        component: <LeadDailyTaskCE />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/daily_tasks/:type",
        component: <LeadBase />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/leads/daily_tasks/:type/:type",
        component: <DailyTaskCE />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/:type/detail/edit",
        component: <LeadCE />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/assign_leads_remark/list/edit",
        component: <AssignLeadRemark />,
        private: true,
        roles: ['controller', 'admin', 'md_lead', 'bd_user', 'hod', 'manager', 'lead_handler']
    },
    {
        path: "/profit_loss",
        component: <ProfitLossMenu />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'revenue_creator', 'finance_head']
    },
    {
        path: "/profit_loss/:type",
        component: <ProfitLossList />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'hr_admin', 'revenue_creator', 'finance_head']
    },
    {
        path: "/profit_loss/:type/:type",
        component: <ProfitLossListCE />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'hr_admin', 'revenue_creator', 'finance_head']
    },
    {
        path: "/invoice_raised/invoice",
        component: <LeadInvoice />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/settings/onboard_list",
        component: <OnboardList />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin', 'onboard_executive']
    },
    {
        path: "/settings/onboard_list/detail",
        component: <OnboardDetail />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin', 'onboard_executive']
    },
    {
        path: "/settings/onboard_list/create",
        component: <Onboard />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin', 'onboard_executive']
    },
    {
        path: "/settings/onboard_list/edit",
        component: <Onboard />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin', 'onboard_executive']
    },
    {
        path: "/settings/payment_shares",
        component: <PaymentShare />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/onboard_list/edit",
        component: <Onboard />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin', 'onboard_executive']
    },
    {
        path: "/privacy",
        component: <PrivacyPolicy />,
        private: false,
        roles: []

    },
    {
        path: "/profile",
        component: <Profile />,
        private: true,
        roles: []
    },
    {
        path: "/profile/basic",
        component: <Mainprofile />,
        private: true,
        roles: []
    },
    {
        path: "/profile/update_password",
        component: <UpdatePassword />,
        private: true,
        roles: []
    },
    {
        path: "/profile/account",
        component: <Account />,
        private: true,
        roles: []
    },
    {
        path: "/profile/documents",
        component: <ProfileDocuments />,
        private: true,
        roles: []
    },
    {
        path: "/profile/edit_profile",
        component: <ProfileLetter />,
        private: true,
        roles: []
    },
    {
        path: "/profile/collegues",
        component: <Collegues />,
        private: true,
        roles: []
    },
    {
        path: "/settings/insentive_list",
        component: <Settings />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/insentive_list/:type",
        component: <InsentiveRequirmentCE />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/profile/appearance",
        component: <ThemeUser />,
        private: true,
        roles: []
    },
    {
        path: "/profile/reimbusment",
        component: <Reimbusment />,
        private: true,
        roles: []
    },
    {
        path: "/profile/reimbusment/:type",
        component: <ReimbusmentCE />,
        private: true,
        roles: []
    },
    {
        path: "/profile/notifications",
        component: <UserNotification />,
        private: true,
        roles: []
    },
    {
        path: "/profile/organiztion",
        component: <UserOrganization />,
        private: true,
        roles: []
    },
    {
        path: "/profile/accessibility",
        component: <Accessability />,
        private: true,
        roles: []
    },
    {
        path: "/leads",
        component: <LeadBase />,
        private: true,

    },
    // {
    //     path:"/password_reset",
    //     component:<ForgotPassword />,
    //     private:false,
    //     roles:[]
    // },
    // admin
    {
        path: "/company_leads",
        component: <AdminLeads />,
        private: true,
        roles: ['controller', 'admin']
    },
    {
        path: "/daily_tasks",
        component: <LeadBase />,
        private: true,
        roles: []
    },
    {
        path: "/daily_tasks/:type",
        component: <LeadBase />,
        private: true,
        roles: []
    },
    {
        path: "/settings/team",
        component: <Team />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager', 'hr_admin', 'onboard_executive']
    },
    {
        path: "/settings/reporting",
        component: <Reporting />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager', 'hr_admin', 'onboard_executive']
    },
    {
        path: "/settings/reporting/:type",
        component: <ReportingCE />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager', 'hr_admin', 'onboard_executive']
    },
    {
        path: "/settings/team/create",
        component: <TeamAdd />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/team/edit",
        component: <TeamAdd />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/team/target",
        component: <TeamTarget />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/employee_target",
        component: <Target />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/employee_target/create",
        component: <TargetAdd />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/settings/employee_target/edit",
        component: <TargetAdd />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/finance/reimbusment_list",
        component: <FinanceReimbusmentList />,
        private: true,
        roles: ['controller', 'admin', 'finance_head', 'finance_manager', 'finanace_employee']
    },
    {
        path: "/finance/reimbusment_list/:type",
        component: <ApprovalReimbusmentCE />,
        private: true,
        roles: ['controller', 'admin', 'finance_head', 'finance_manager', 'finanace_employee']
    },
    {
        path: "/finance/invoice",
        component: <InvoiceRequest />,
        private: true,
        roles: ['controller', 'admin', 'finance_head', 'finance_manager', 'finanace_employee']
    },
    {
        path: "/finance/invoice/detail",
        component: <InvoiceRequestDetail />,
        private: true,
        roles: ['controller', 'admin', 'finance_head', 'finance_manager', 'finanace_employee']
    },
    {
        path: "/finance/invoice/detail/:id",
        component: <InvoicePushtoDetail />,
        private: true,
        roles: ['controller', 'admin', 'bd_user', 'hod', 'manager']
    },
    {
        path: "/invoice/delete",
        component: <InvoiceRequestDetail />,
        private: true,
        roles: ['controller', 'admin', 'finance_head', 'finance_manager', 'finanace_employee']
    },
    {
        path: "/dashboard/team_leads",
        component: <TeamLeads />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager', 'reporting_manager']
    },
    {
        path: "/dashboard/induction_list",
        component: <InductionList />,
        private: true,
        roles: ['controller', 'admin', 'hod',]
    },
    {
        path: "/dashboard/course_list",
        component: <CoursesList />,
        private: true,
        roles: ['controller', 'admin', 'hod',]
    },
    {
        path: "/dashboard/supervisor_list",
        component: <SuperVisorList />,
        private: true,
        roles: ['controller', 'admin', 'hod',]
    },
    {
        path: "/dashboard/reimbusment_list",
        component: <HeadReimbusmentlist />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager', 'reporting_manager']
    },
    {
        path: "/dashboard/reimbusment_list/:type",
        component: <HeadReimbusmentCE />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager', 'reporting_manager']
    },
    {
        path: "/dashboard/out_standing",
        component: <Outstandinglist />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager', 'bd_user', 'reporting_manager']
    },
    {
        path: "/dashboard/out_standing/detail",
        component: <OutstandingDetail />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager', 'bd_user', 'reporting_manager']
    },
    {
        path: "/dashboard/daily_tasks",
        component: <TeamDailyTask />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager']
    },
    {
        path: "/dashboard/reporting_manager_daily_tasks",
        component: <TeamDailyTask />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager', 'reporting_manager']
    },
    {
        path: "/dashboard/admin_dashboard",
        component: <AdminDashboard />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager']
    },
    {
        path: "/dashboard/team_target_achieved",
        component: <TeamTargets />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager']
    },
    {
        path: "/dashboard/incentive_dashboard",
        component: <InsentiveDashboard />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager', 'bd_user']
    },
    {
        path: "/dashboard/team_target_achieved_detail_user",
        component: <TeamTargetsIndivisualdetail />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager']
    },
    {
        path: "/incentive",
        component: <Incentive />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager', 'bd_user']
    },
    {
        path: "/incentive_requriment",
        component: <InsentiveRequirment />,
        private: true,
        roles: ['controller', 'admin']
    },
    {
        path: "/dashboard/payment",
        component: <Payment />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager']

    },
    {
        path: "/dashboard/payment/detail",
        component: <PaymentDetail />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager']
    },
    {
        path: "/nmr",
        component: <NMRMenu />,
        private: true,
        roles: []
    },
    {
        path: "/nmr/list",
        component: <NMRlist />,
        private: true,
        roles: []
    },
    {
        path: "/nmr/list/:type",
        component: <NMRCE />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/admin_nmr_list",
        component: <NMRAdminlist />,
        private: true,
        roles: []
    },
    {
        path: "/payment/deleted",
        component: <PaymentDetail />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager']
    },
    {
        path: "/landlord_invoice",
        component: <LeadLandlord />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'manager']
    },
    {
        path: "/settings/finance",
        component: <FinanceMenu />,
        private: true,
        roles: ['controller', 'admin', 'hod', 'hr_admin']
    },
    {
        path: "/settings/reports",
        component: <DownloadReport />,
        private: true,
        roles: ['controller', 'admin', 'hr_admin']
    },
    {
        path: "/finance",
        component: <Finance />,
        private: true,
        roles: []
    },

    // gallery 
    {
        path: "/gallery",
        component: <GalleryMainPage />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/gallery/visitor_list",
        component: <VisitorList />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/gallery/visitor_list/:type",
        component: <VisitorCE />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/gallery/product_list",
        component: <GalleryProductList />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/gallery/product_list/detail/:id",
        component: <ProductDetail />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/gallery/product_list/:type",
        component: <GalleryProductCE />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/gallery/events",
        component: <FidelitusGalleryEventList />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/gallery/events/:type",
        component: <FidelitusGalleryEventListCE />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/gallery/events/:type",
        component: <FidelitusGalleryEventListCE />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/gallery/users",
        component: <FidelitusGalleryUserList />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/gallery/users/:type",
        component: <FidelitusGalleryUserListCE />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/gallery/events/users_list",
        component: <FidelitusGalleryEventUserList />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/gallery/:type1",
        component: <GalleryProducCategorytList />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },

    {
        path: "/gallery/:type1/:type",
        component: <GalleryProductCategoryCE />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/tasks",
        component: <TaskDashboard />,
        private: true,
        roles: []
    },
    {
        path: "/tasks/dashboard",
        component: <TaskDashboard />,
        private: true,
        roles: []
    },
    {
        path: "/tasks/projects/list/create",
        component: <TeamTaskCE />,
        private: true,
        roles: []
    },
    {
        path: "/tasks/projects/list/edit",
        component: <TeamTaskCE />,
        private: true,
        roles: []
    },
    {
        path: "/tasks/mom_tasks/lists",
        component: <MomTaskList />,
        private: true,
        roles: []
    },
    {
        path: "/tasks/mom_tasks/lists/:type",
        component: <MomCE />,
        private: true,
        roles: []
    },
    {
        path: "/tasks/md_meet/:type",
        component: <MDMeetlist />,
        private: true,
        roles: []
    },
    {
        path: "/tasks/md_meet/lists/:type",
        component: <MDMeetCE />,
        private: true,
        roles: []
    },
    {
        path: "/tasks/:type/:type",
        component: <TaskList />,
        private: true,
        roles: []
    },
    {
        path: "/tasks/:type/:type/:type",
        component: <TaskCE />,
        private: true,
        roles: []
    },
    // {
    //     path:"/tasks/:type/list/:type/detail",
    //     component:<TaskBoard/>,
    //     private:true,
    //     roles:[]
    // },
    {
        path: "/tasks/:type/list/detail",
        component: <TeamTaskRoot />,
        private: true,
        roles: []
    },
    {
        path: "/ats",
        component: <AtsMenu />,
        private: true,
        roles: []
    },
    {
        path: "/ats/dashboard",
        component: <AtsDashboard />,
        private: true,
        roles: []
    },
    {
        path: "/ats/website_candidate",
        component: <AtsWebsiteManagement />,
        private: true,
        roles: []
    },
    {
        path: "/ats/website_candidate/:type",
        component: <AtsWebsiteManagmentCE />,
        private: true,
        roles: []
    },
    {
        path: "/ats/client_managment",
        component: <AtsClientManagment />,
        private: true,
        roles: []
    },
    {
        path: "/ats/client_managment/detail",
        component: <AtsClientManagmentDetail />,
        private: true,
        roles: []
    },
    {
        path: "/ats/client_managment/:type",
        component: <AtsClientManagmentCE />,
        private: true,
        roles: []
    },
    {
        path: "/ats/job_description",
        component: <AtsJobDescription />,
        private: true,
        roles: []
    },
    {
        path: "/ats/job_description/detail",
        component: <AtsJobDescriptionDetail />,
        private: true,
        roles: []
    },
    {
        path: "/ats/job_description/:type",
        component: <AtsJobDescriptionCE />,
        private: true,
        roles: []
    },
    {
        path: "/ats/employee_managment",
        component: <AtsEmployeeManagment />,
        private: true,
        roles: []
    },
    {
        path: "/ats/employee_managment/:type",
        component: <AtsEmployeeManagmentCE />,
        private: true,
        roles: []
    },
    {
        path: "/ats/track_option",
        component: <AtsTrackOption />,
        private: true,
        roles: []
    },
    {
        path: "/ats/employee_status_update",
        component: <AtsCandidateManage />,
        private: true,
        roles: []
    },
    {
        path: "/ats/track_option/:type",
        component: <AtsTrackOptionCE />,
        private: true,
        roles: []
    },
    {
        path: "/ats/team_report",
        component: <AtsTeamReport />,
        private: true,
        roles: []
    },
    {
        path: "/ats/overall_report",
        component: <AtsOverallReport />,
        private: true,
        roles: []
    },
    {
        path: "/ats/overall_report/employee_based_on_hiring_stage",
        component: <AtsEmployeeListBasedOnHiringStage />,
        private: true,
        roles: []
    },
    {
        path: "/reminder",
        component: <AdminReminderMenu />,
        private: true,
        roles: ['admin_reminder', 'admin']
    },
    {
        path: "/reminder/:type",
        component: <AdminReminderRoot />,
        private: true,
        roles: ['admin_reminder', 'admin']
    },
    {
        path: "/reminder/:type/:type",
        component: <AdminReminderCE />,
        private: true,
        roles: ['admin_reminder', 'admin']
    },
    {
        path: "/self_onboard",
        component: <SelfOnboard />,
        private: true,
        roles: []
    },
    {
        path: "/exit_formalities",
        component: <ExitForm />,
        private: true,
        roles: []
    },
    {
        path: "/in_out",
        component: <ExitMenu />,
        private: true,
        roles: []
    },
    {
        path: "/in_out/entry_approval/list",
        component: <EntryMenuList />,
        private: true,
        roles: []
    },
    {
        path: "/in_out/entry_approval/list/update",
        component: <EntryMenuListEdit />,
        private: true,
        roles: []
    },
    {
        path: "/in_out/exit_approval/list",
        component: <ExitMenuList />,
        private: true,
        roles: []
    },
    {
        path: "/in_out/exit_approval/list/update",
        component: <ExitMenuListEdit />,
        private: true,
        roles: []
    },
    {
        path: "/ils",
        component: <IlsMenu />,
        private: true,
        roles: []
    },
    {
        path: "/ils/land_data",
        component: <IlsLandData />,
        private: true,
        roles: []
    },
    {
        path: "/ils/land_data/:type",
        component: <IlsLandDataCE />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/land_data_admin",
        component: <IlsLandDataAdmin />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/land_data_admin/:type",
        component: <IlsLandDataCE />,
        private: true,
        roles: []
    },
    {
        path: "/ils/land_data/detail",
        component: <IlsLandDataDetail />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/land_requirment/",
        component: <IlsLandRequirmentAdmin />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/land_requirment/detail",
        component: <IlsLandRequirmentDetail />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/land_requirment/:type",
        component: <IlsLandRequirmentCE />,
        private: true,
        roles: []
    },
    {
        path: "/ils/:type",
        component: <IlsLandRequirment />,
        private: true,
        roles: []
    },
    {
        path: "/ils/:type/detail",
        component: <IlsLandRequirmentDetail />,
        private: true,
        roles: []
    },
    {
        path: "/ils/:type/:type",
        component: <IlsLandRequirmentCE />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/backend_daily_tasks",
        component: <AdminTeamTasks />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/backend_team_work",
        component: <BackendTeamTasks />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/mom_tasks",
        component: <AdminMomTaskList />,
        private: true,
        roles: []
    },
    {
        path: "/fcares",
        component: <FcaresMenu />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/dashboard",
        component: <FcareDashboard />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/user_list",
        component: <FCaresUserList />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/tenant_list",
        component: <FCaresTenantList />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/tenant_list/:type",
        component: <FCaresTenantCE />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/services_list",
        component: <FCaresServicesList />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/services_list/:type",
        component: <FCaresServicesCE />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/team_collection",
        component: <RevenueCollection />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/user_list/:type",
        component: <FCaresUserCE />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/upcoming_bill_list",
        component: <FCaresUpcomingBillList />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/upcoming_bill_list/:type",
        component: <FCaresUpcomingBillCE />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/ticket_list",
        component: <FCaresTicketList />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/ticket_list/:type",
        component: <FCaresTicketCE />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/query_list",
        component: <FCaresQueryList />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/query_list/:type",
        component: <FCaresQueryCE />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/property_list",
        component: <FCaresPropertyList />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/property_list/attachments",
        component: <FCaresPropertyListAttachment />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/property_list/maintenance",
        component: <FcaresMaintanenceList />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/property_list/:type",
        component: <FCaresPropertyCE />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/report_list",
        component: <FCaresReportList />,
        private: true,
        roles: []
    },
    {
        path: "/fcares/report_list/detail",
        component: <FCaresReportDetail />,
        private: true,
        roles: []
    },
    {
        path: "/dashboard/team_calls",
        component: <AdminCalls />,
        private: true,
        roles: ['admin', 'hod', 'manager']
    },
    {
        path: "/database",
        component: <DatabaseMenu />,
        private: true,
        roles: []
    },
    {
        path: "/database/dashboard",
        component: <DBDashboard />,
        private: true,
        roles: []
    },
    {
        path: "/database/requirment_info",
        component: <RequirmentInfoList />,
        private: true,
        roles: []
    },
    {
        path: "/database/requirment_info/:type",
        component: <RequirmentInfoListCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/plot_type",
        component: <PlotTypeList />,
        private: true,
        roles: []
    },
    {
        path: "/database/plot_type/:type",
        component: <PlotTypeCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/property_type",
        component: <PropertyTypeList />,
        private: true,
        roles: []
    },
    {
        path: "/database/property_type/:type",
        component: <PropertyTypeCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/facility_type",
        component: <PropertyFacilityList />,
        private: true,
        roles: []
    },
    {
        path: "/database/facility_type/:type",
        component: <PropertyFacilityCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/property_category",
        component: <PropertyCategoryList />,
        private: true,
        roles: []
    },
    {
        path: "/database/property_category/:type",
        component: <PropertyCategoryCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/katha_type",
        component: <KathaTypeList />,
        private: true,
        roles: []
    },
    {
        path: "/database/katha_type/:type",
        component: <KathaTypeCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/property_amenities",
        component: <PropertyAmenitiesList />,
        private: true,
        roles: []
    },
    {
        path: "/database/property_amenities/:type",
        component: <PropertyAmenitiesCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/owner_type",
        component: <OwnerTypeList />,
        private: true,
        roles: []
    },
    {
        path: "/database/owner_type/:type",
        component: <OwnerTypeCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/property_zone",
        component: <PropertyZoneList />,
        private: true,
        roles: []
    },
    {
        path: "/database/property_zone/:type",
        component: <PropertyZoneCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/approved_type",
        component: <ApprovedTypeList />,
        private: true,
        roles: []
    },
    {
        path: "/database/approved_type/:type",
        component: <ApprovedTypeCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/conversion_type",
        component: <ConversionTypeList />,
        private: true,
        roles: []
    },

    {
        path: "/database/conversion_type/:type",
        component: <ConversionTypeCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/furnish_type",
        component: <FurnishTypeList />,
        private: true,
        roles: []
    },
    {
        path: "/database/furnish_type/:type",
        component: <FurnishTypeCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/location_based_zone",
        component: <LocationBasedZoneList />,
        private: true,
        roles: []
    },
    {
        path: "/database/location_based_zone/:type",
        component: <LocationBasedZoneCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/property_status",
        component: <PropertyStatusList />,
        private: true,
        roles: []
    },
    {
        path: "/database/property_status/:type",
        component: <PropertyStatusCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/managed_office",
        component: <ManagedOfficeList />,
        private: true,
        roles: []
    },
    {
        path: "/database/managed_office/detail",
        component: <ManagedOfficeDetail />,
        private: true,
        roles: []
    },
    {
        path: "/database/managed_office/:type",
        component: <ManagedOfficeCE />,
        private: true,
        roles: []
    },
    {
        path: "/database/office_space",
        component: <OfficeSpaceList />,
        private: true,
        roles: []
    },
    {
        path: "/database/office_space/detail",
        component: <OfficeSpaceDetail />,
        private: true,
        roles: []
    },
    {
        path: "/database/office_space/:type",
        component: <OfficeSpaceCE />,
        private: true,
        roles: []
    },
    {
        path: "/md_followup",
        component: <MdFollowUpMenu />,
        private: true,
        roles: []
    },
    {
        path: "/md_followup/lead/list",
        component: <MdFollowUpList />,
        private: true,
        roles: []
    },

    {
        path: "/md_followup/:type/list",
        component: <MdFollowUpList />,
        private: true,
        roles: []
    },
    {
        path: "/md_followup/lead/list/:type",
        component: <MdFollowUpCE />,
        private: true,
        roles: []
    },
    {
        path: "/md_followup/:type/list/:type",
        component: <MdFollowUpCE />,
        private: true,
        roles: []
    },
    {
        path: "/agreement",
        component: <AgreementFollowUpMenu />,
        private: true,
        roles: []
    },
    {
        path: "/agreement/list",
        component: <Agreementlist />,
        private: true,
        roles: []
    },
    {
        path: "/agreement/followup",
        component: <AgreementFollowuplistuser />,
        private: true,
        roles: []
    },
    {
        path: "/agreement/list/:type",
        component: <AgreementlistCE />,
        private: true,
        roles: []
    },
    {
        path: "/agreement/followup/:type",
        component: <AgreementlistCE />,
        private: true,
        roles: []
    },
    {
        path: "/website",
        component: <WebsiteMenu />,
        private: true,
        roles: []
    },
    {
        path: "/website/hr_website_stage",
        component: <HRStageList />,
        private: true,
        roles: []
    },
    {
        path: "/website/hr_website_stage/:type",
        component: <HRStageCE />,
        private: true,
        roles: []
    },
    {
        path: "/website/hr_blogs",
        component: <HRBlogList />,
        private: true,
        roles: []
    },
    {
        path: "/website/hr_blogs/:type",
        component: <HRBlogCE />,
        private: true,
        roles: []
    },
    {
        path: "/website/hr_artical",
        component: <HRArticalList />,
        private: true,
        roles: []
    },
    {
        path: "/website/hr_artical/:type",
        component: <HRArticalCE />,
        private: true,
        roles: []
    },
    {
        path: "/website/fms_website_stage",
        component: <FMSStageList />,
        private: true,
        roles: []
    },
    {
        path: "/website/fms_website_stage/:TYPE",
        component: <FMSStageCE />,
        private: true,
        roles: []
    },
    {
        path: "/website/fms_blogs",
        component: <FMSBlogList />,
        private: true,
        roles: []
    },
    {
        path: "/website/fms_blogs/:type",
        component: <FMSBlogCE />,
        private: true,
        roles: []
    },
    {
        path: "/website/corp_website_stage",
        component: <CORPStageList />,
        private: true,
        roles: []
    },
    {
        path: "/website/corp_website_stage/:TYPE",
        component: <CORPStageCE />,
        private: true,
        roles: []
    },
    {
        path: "/website/corp_blogs",
        component: <CORPBlogList />,
        private: true,
        roles: []
    },
    {
        path: "/website/corp_blogs/:type",
        component: <CORPBlogCE />,
        private: true,
        roles: []
    },
    {
        path: "/website/project_website_stage",
        component: <ProjectStageList />,
        private: true,
        roles: []
    },
    {
        path: "/website/project_website_stage/:type",
        component: <ProjectStageCE />,
        private: true,
        roles: []
    },
    {
        path: "/website/project_blogs",
        component: <ProjectBlogList />,
        private: true,
        roles: []
    },
    {
        path: "/website/project_blogs/:type",
        component: <ProjectBlogCE />,
        private: true,
        roles: []
    },
    {
        path: "/website/project_videos",
        component: <ProjectVideoList />,
        private: true,
        roles: []
    },
    {
        path: "/website/project_videos/:type",
        component: <ProjectVideoCE />,
        private: true,
        roles: []
    },
    {
        path: "/website/project_job_opening",
        component: <ProjectJobOpeningList />,
        private: true,
        roles: []
    },
    {
        path: "/website/project_job_opening/:type",
        component: <ProjectJobOpeningCE />,
        private: true,
        roles: []
    },
    {
        path: "/website/project_job_applicant",
        component: <ProjectJobApplyList />,
        private: true,
        roles: []
    },
    {
        path: "/website/project_job_applicant_list_based_job",
        component: <ProjectJobApplyBasedJobList />,
        private: true,
        roles: []
    },
    {
        path: "/website/project_job_applicant/:type",
        component: <ProjectJobApplyCE />,
        private: true,
        roles: []
    },
    {
        path: "/website/transaction_website_stage",
        component: <TransactionStageList />,
        private: true,
        roles: []
    },
    {
        path: "/website/transaction_website_stage/:type",
        component: <TransactionStageCE />,
        private: true,
        roles: []
    },
    {
        path: "/website/transaction_blogs",
        component: <TransactionBlogList />,
        private: true,
        roles: []
    },
    {
        path: "/website/transaction_blogs/:type",
        component: <TransactionBlogCE />,
        private: true,
        roles: []
    },
    {
        path: "/govt",
        component: <GovtMenu />,
        private: true,
        roles: []
    },

    {
        path: "/govt/cases",
        component: <CasesList />,
        private: true,
        roles: []
    },
    {
        path: "/govt/notices",
        component: <NoticeList />,
        private: true,
        roles: []
    },
    {
        path: "/govt/cases/:type",
        component: <CasesListCE />,
        private: true,
        roles: []
    },
    {
        path: "/govt/notices/:type",
        component: <NoticeListCE />,
        private: true,
        roles: []
    },
    {
        path: "/shilpa_foundation",
        component: <ShilpaFoundationMenu />,
        private: true,
        roles: []
    },
    {
        path: "/shilpa_foundation/events",
        component: <ShilpaFoundationEventList />,
        private: true,
        roles: []
    },
    {
        path: "/shilpa_foundation/events/users_list",
        component: <ShilpaFoundationEventUserList />,
        private: true,
        roles: []
    },
    {
        path: "/shilpa_foundation/events/:type",
        component: <ShilpaFoundationEventListCE />,
        private: true,
        roles: []
    },
    {
        path: "/shilpa_foundation/users",
        component: <ShilpaFoundationUserList />,
        private: true,
        roles: []
    },
    {
        path: "/shilpa_foundation/users/:type",
        component: <ShilpaFoundationUserListCE />,
        private: true,
        roles: []
    },
    {
        path: "/settings/events",
        component: <FidelitusCorpEventList />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },
    {
        path: "/settings/events/:type",
        component: <FidelitusCorpEventListCE />,
        private: true,
        roles: ['gallery_inventory', 'admin', 'controller']
    },

    {
        path: "/settings/users",
        component: <FidelitusCorpUserList />,
        private: true,
        roles: []
    },
    {
        path: "/settings/users/:type",
        component: <FidelitusCorpUserListCE />,
        private: true,
        roles: []
    },
    {
        path: "/settings/events/users_list",
        component: <FidelitusCorpEventUserList />,
        private: true,
        roles: []
    },
    {
        path: "/settings/visitors",
        component: <FidelitusCorpVisitorList />,
        private: true,
        roles: ['admin', 'controller']
    },
    {
        path: "/settings/visitors/:type",
        component: <FidelitusCorpVisitorListCE />,
        private: true,
        roles: ['admin', 'controller']
    },
    {
        path: "/support_ticket",
        component: <SupportTicketMenu />,
        private: true,
        roles: []
    },
    {
        path: "/support_ticket/:type",
        component: <SupportTicketList />,
        private: true,
        roles: []
    },
    {
        path: "/support_ticket/:type/:type",
        component: <SupportTicketCE />,
        private: true,
        roles: []
    },

    {
        path: "/reception",
        component: <ReceptionMenu />,
        private: true,
        roles: []
    },
    {
        path: "/reception/cab_usage",
        component: <CabUsageList />,
        private: true,
        roles: []
    },
    {
        path: "/reception/cab_usage/:type",
        component: <CabUsageCE />,
        private: true,
        roles: []
    },
    {
        path: "/reception/staff_moment",
        component: <StaffMomentList />,
        private: true,
        roles: []
    },
    {
        path: "/reception/staff_moment/:type",
        component: <StaffMomentCE />,
        private: true,
        roles: []
    },
    {
        path: "/reception/staff_moment_approval",
        component: <StaffMomentApprovalList />,
        private: true,
        roles: []
    },
    {
        path: "/it",
        component: <ItMenu />,
        private: true,
        roles: []
    },
    {
        path: "/it/dashboard",
        component: <ItDashboard />,
        private: true,
        roles: []
    },
    {
        path: "/it/feature/:type",
        component: <FeatureOptionList />,
        private: true,
        roles: []
    },
    {
        path: "/it/feature/:type/:type",
        component: <FeatureOptionCE />,
        private: true,
        roles: []
    },
    {
        path: "/it/inventory/:type",
        component: <InventoryOptionList />,
        private: true,
        roles: []
    },
    {
        path: "/it/inventory/:type/detail",
        component: <InventoryOptionDetail />,
        private: true,
        roles: []
    },
    {
        path: "/it/inventory/:type/:type",
        component: <InventoryOptionCE />,
        private: true,
        roles: []
    },
    {
        path: "/it/upcoming_puchase/:type",
        component: <UpcomingPurchaseOptionList />,
        private: true,
        roles: []
    },
    {
        path: "/it/upcoming_puchase/:type/:type",
        component: <UpcomingPurchaseOptionCE />,
        private: true,
        roles: []
    },
    {
        path: "/it/inventory_puchase/:type",
        component: <InventoryPurchaseOptionList />,
        private: true,
        roles: []
    },
    {
        path: "/it/inventory_puchase/:type/:type",
        component: <InventoryPurchaseOptionCE />,
        private: true,
        roles: []
    },
    {
        path: "/it/ticket_raised",
        component: <SupportOptionList />,
        private: true,
        roles: []
    },
    {
        path: "/it/ticket_raised/:type",
        component: <SupportOptionCE />,
        private: true,
        roles: []
    },
    {
        path: "/it/assign_asset",
        component: <AssignAssetOptionList />,
        private: true,
        roles: []
    },
    {
        path: "/it/assign_asset/detail",
        component: <AssignAssetOptionDetail />,
        private: true,
        roles: []
    },
    {
        path: "/it/assign_asset/:type",
        component: <AssignAssetOptionCE />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads",
        component: <FTSLeadMenu />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads/list",
        component: <FTSLeadlist />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads/calls_list",
        component: <FTSCallslist />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads/calls_list/:type",
        component: <FTSCallsCE />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads/list/:type",
        component: <FTSLeadCE />,
        private: true,
        roles: []
    },

    {
        path: "/fts_leads/list/:type",
        component: <FTSLeadCE />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads/shared_by_us",
        component: <FTSLeadShared />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads/lead_received_from_fts",
        component: <FTSLeadRecievedFromFTS />,
        private: false,
        roles: []
    },
    {
        path: "/fts_leads/monthly_review",
        component: <FTSMonthlyReview />,
        private: false,
        roles: []
    },
    {
        path: "/fts_leads/leads_Analytics",
        component: <FTSleadsAnalytics />,
        private: false,
        roles: []
    },
    {
        path: "/fts_leads/leads_Analytics/detail",
        component: <FTSleadsAnalyticsDetail />,
        private: false,
        roles: []
    },
    {
        path: "/fts_leads/lead_received_from_fts/edit",
        component: <FTSLeadRecievedFromFTSRemark />,
        private: false,
        roles: []
    },
    {
        path: "/fts_leads/shared_to_us",
        component: <FTSLeadRecieved />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads/dashboard",
        component: <FTSDashboard />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads/shared_to_fts",
        component: <FTSLeadSharedToFTS />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads/shared_to_fts_user_detail",
        component: <FTSLeadSharedToFTSUserDetail />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads/vertical_Analytics",
        component: <FTSVerticalAnalytics />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads_assign_remark/list/edit",
        component: <FTSLeadRemark />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads_assign/list/edit",
        component: <FTSLeadShareEdit />,
        private: true,
        roles: []
    },
    {
        path: "/finance/revenue_forecast",
        component: <RevenueForecastList />,
        private: true,
        roles: []
    },
    {
        path: "/finance/revenue_forecast/:type",
        component: <RevenueForecastCE />,
        private: true,
        roles: []
    },
    {
        path: "/fts/vertical_report_dashboard",
        component: <FTSVerticalReportDashboard />,
        private: true,
        roles: []
    },
    {
        path: "/fts/vertical_report",
        component: <FTSVerticalReportList />,
        private: true,
        roles: []
    },
    {
        path: "/fts/vertical_report/:type",
        component: <FTSVerticalReportCE />,
        private: true,
        roles: []
    },
    {
        path: "/fts/monthly_meeting_report",
        component: <FTSVerticalMonthlyMeetingReviewList />,
        private: true,
        roles: []
    },
    {
        path: "/fts/monthly_meeting_report/:type",
        component: <FTSVerticalMonthlyMeetingReviewCE />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads/vertical_lead",
        component: <FTSVerticalLead />,
        private: true,
        roles: []
    },
    {
        path: "/fts_leads/vertical_lead/:type",
        component: <FTSVerticalLeadCE />,
        private: true,
        roles: []
    },
]

// /assign_leads_remark/list/edit