import React, { useState, useEffect } from 'react'
import { DeleteInvoiceService, GetCurrentInsentivePayments, GetInvoiceFilterService, GetInvoiceService } from '../../services/InvoiceServices'
import { RiBillLine } from 'react-icons/ri';
import { IconButton } from '@mui/material'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import FinanceMenu from '../finance/FinanceMenu';
import { BsArrowRepeat } from 'react-icons/bs';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { AiOutlineDelete } from 'react-icons/ai';
import { ButtonOutlinedAutoWidth } from '../../components/button';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { BiTable } from "react-icons/bi";
import { RiLayoutGridLine } from "react-icons/ri";
import Priceconstants from '../../constants/imageConstants';
import { Select } from 'antd';
import { SearchUser } from '../../services/AuthServices';

function InvoiceRequest() {

  const user = useSelector(state => state.Auth)

  const [data, setdata] = useState([])
  const [current_tab, setcurrent_tab] = useState('1')
  const [invoice_no, setinvoice_no] = useState('')
  const [selectedUser, setselectedUser] = useState('')
  const [users, setusers] = useState([])
  const [page, setpage] = useState(1)
  const [filterApplied, setfilterApplied] = useState(false)
  const navigator = useNavigate()

  const [step, setstep] = useState(1)


  useEffect(() => {
    setfilterApplied(false)
    setinvoice_no('')
    if (current_tab === '1') {
      getInvoiceRequest('0')
    } else if (current_tab === '2') {
      getInvoiceRequest('2')
    } else if (current_tab === '3') {
      getInvoiceRequest('1')
    } else if (current_tab === '4') {
      getPaymentDeleted()
    } else if (current_tab === '5') {
      getPaymentApprovalPending()
    }
  }, [current_tab])


  useEffect(() => {
    if (!filterApplied) {
      if (current_tab === '1') {
        getInvoiceRequest('0')
      } else if (current_tab === '2') {
        getInvoiceRequest('2')
      } else if (current_tab === '3') {
        getInvoiceRequest('1')
      } else if (current_tab === '4') {
        getPaymentDeleted()
      } else if (current_tab === '5') {
        getPaymentApprovalPending()
      }
    }
  }, [page])


  useEffect(() => {
    if (filterApplied) {
      if (current_tab === '1') {
        getInvoiceFilterRequest('0')
      } else if (current_tab === '2') {
        getInvoiceFilterRequest('2')
      } else if (current_tab === '3') {
        getInvoiceFilterRequest('1')
      }
    }
  }, [page, filterApplied])

  async function getInvoiceRequest(v, user = '') {
    const response = await GetInvoiceService(v, page, user !== '' ? user : selectedUser !== '' ? selectedUser?.value : '')
    // console.log("response?.data",response?.data)
    setdata(response.data)
  }



  async function getPaymentDeleted() {
    const response = await GetCurrentInsentivePayments('3', page)
    setdata(response.data)
  }

  async function getPaymentApprovalPending() {
    const response = await GetCurrentInsentivePayments('4', page)
    setdata(response.data)
  }

  async function getInvoiceFilterRequest(v, user = '') {
    const response = await GetInvoiceFilterService(v, page, invoice_no, user !== '' ? user : selectedUser !== '' ? selectedUser?.value : '')
    setdata(response.data)
  }

  async function setactivetab(v) {
    setcurrent_tab(v)
  }

  async function applyFilter() {
    setfilterApplied(true)
    if (current_tab === '1') {
      const response = await GetInvoiceFilterService('0', page, invoice_no, selectedUser !== '' ? selectedUser?.value : '')
      setdata(response.data)
      // getInvoiceFilterRequest('0')
    } else if (current_tab === '2') {
      const response = await GetInvoiceFilterService('2', page, invoice_no, selectedUser !== '' ? selectedUser?.value : '')
      setdata(response.data)
      // getInvoiceFilterRequest('2')
    } else if (current_tab === '3') {
      const response = await GetInvoiceFilterService('1', page, invoice_no, selectedUser !== '' ? selectedUser?.value : '')
      setdata(response.data)
      // getInvoiceFilterRequest('1')
    }
  }

  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  async function resetfunc() {
    setfilterApplied(false)
    setinvoice_no('')
    setselectedUser('')
    setpage(1)
    if (current_tab === '1') {
      // getInvoiceRequest('0','')
      const response = await GetInvoiceFilterService('0', page, '', '')
      setdata(response.data)
    } else if (current_tab === '2') {
      const response = await GetInvoiceFilterService('2', page, '', '')
      setdata(response.data)
      // getInvoiceRequest('2','')
    } else if (current_tab === '3') {
      const response = await GetInvoiceFilterService('1', page, '', '')
      setdata(response.data)
      // getInvoiceRequest('1','')
    } else if (current_tab === '4') {
      getPaymentDeleted()
    } else if (current_tab === '5') {
      getPaymentApprovalPending()
    }
  }



  const tabs_list = [{ 'value': 'Not Raised123', 'key': '1' }, { 'value': 'Revise Invoice', 'key': '2' }, { 'value': 'Invoice Raised', 'key': '2' }, { 'value': 'Payment Deleted', 'key': '4' }, { 'value': 'Approval Pending', 'key': '5' }]

  async function deleteData(id) {
    if (current_tab === '1' || current_tab === '2' || current_tab === '3') {
      const response = await DeleteInvoiceService(id)
      if (response.status === 200) {
        toast.success("Deleted successully")
        if (current_tab === '1') {
          getInvoiceRequest('0')
        } else if (current_tab === '2') {
          getInvoiceRequest('2')
        } else if (current_tab === '3') {
          getInvoiceRequest('1')
        }
      }
    }

  }

  async function onSearch(v) {
    const response = await SearchUser(v)
    let arr = []
    response?.data?.forEach((d) => {
      arr.push({ value: d?._id, label: d?.name })
    })
    setusers(arr)
  }


  return (
    <div >

      <div className='flex'>
        <FinanceMenu />
        <div className='px-2 pt-4 w-[85%]'>


          <div className='flex justify-between border-b'>
            <div className='flex items-center'>
              {tabs_list?.map((t, i) => (
                <h6 key={i} onClick={() => setactivetab((i + 1).toString())} className={`px-2  text-[11px] py-1.5 font-[600] cursor-pointer  ${current_tab === (i + 1).toString() ? 'bg-slate-600 text-white' : 'border-white'}`}>{t?.value}</h6>
              ))}
            </div>

            <div onClick={() => setstep(step == 1 ? 2 : 1)} className='flex cursor-pointer items-center'>
              <BiTable size={20} className={`${step == 1 ? 'bg-slate-700 text-white' : 'text-black bg-gray-100'} px-[3px] rounded mr-1 py-[2px]`} />
              <RiLayoutGridLine size={20} className={`${step == 2 ? 'bg-slate-700 text-white' : 'text-black bg-gray-100'} px-[3px] rounded mr-1 py-[2px]`} />
            </div>
          </div>

          <div className='mt-2'>
            <div className='-mt-1  flex items-center justify-between'>
              <h6 className='text-[11px] font-[700]'>Total {(current_tab === '1' || current_tab === '2' || current_tab === '5') ? 'Pending' : 'Completed'} ({data?.pagination?.total})</h6>
              <div >
                <div className='flex items-center text-[12px]'>
                  <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit} - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                  <IconButton onClick={resetfunc} ><BsArrowRepeat size={16} /></IconButton>

                  <div>
                    <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }} ><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                    <IconButton onClick={() => { page < data?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data?.pagination?.total === 0 || data?.pagination?.totalPages === page) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                  </div>
                  {(current_tab !== '4' && current_tab !== '5') &&
                    <>

                      <div className='border mr-[5px] w-[120px] rounded-[5px] border-slate-300'>
                        <Select
                          showSearch
                          bordered={false}
                          value={selectedUser === '' ? null : selectedUser}
                          onSearch={onSearch}
                          filterOption={false}
                          placeholder="Search User"
                          onChange={(e) => setselectedUser(users.find((f) => f?.value === e))}
                          className='w-full h-[20px] rounded-[0px]'
                          options={users}
                        />
                      </div>

                      <input id="search_text" placeholder='Invoice no' type='text' value={invoice_no} onChange={(e) => setinvoice_no(e.target.value)} className='border pl-2 py-[3px] focus:ring-0 mr-1 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
                      <ButtonOutlinedAutoWidth onClick={() => applyFilter()} btnName="Add Filter" />
                    </>}
                </div>
              </div>
            </div>

            <div className='h-[85vh] min-h-[85vh] overflow-y-scroll max-h-[85vh]'>

              {step === 1 &&
                <>
                  <div className='border-t border-l border-r mt-2'>
                    <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                      <h6 className='sticky top-0 z-50  text-[12px] w-[80px]  px-2 py-1 font-[600] text-slate-600 border-r'>SL No</h6>
                      <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Date</h6>
                      <h6 className='sticky top-0 z-50  text-[12px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Client Particulars</h6>
                      {['1', '2', '3']?.includes(current_tab) && <>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Vch No</h6>
                      </>}
                      <h6 className='sticky top-0 z-50  text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Without Tax</h6>
                      <h6 className='sticky top-0 z-50  text-[12px] w-[90px] px-2 py-1 font-[600] text-slate-600 border-r'>With Tax</h6>
                      {['1', '2', '3']?.includes(current_tab) && <>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[50px] px-2 py-1 font-[600] text-slate-600 border-r'>TDS</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Payment Recieve</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] bg-slate-300 text-slate-600 border-r'>Payment Calculate</h6>
                      </>}
                      <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Operative Name</h6>
                      <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                    </div>
                    {['1', '2', '3']?.includes(current_tab) &&
                      <>
                        {data?.datas?.map((d, i) => (
                          <div className='flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                            <h6 className=' text-[12px] w-[80px]  px-2 py-1 font-[500] border-r'>{page === 1 ? i + 1 : (i + 1) + ((page - 1) * data?.pagination?.limit)}</h6>
                            <h6 className=' text-[12px] w-[130px]  px-2 py-1 font-[500] border-r'>{moment(d?.invoice_raising_date)?.format('D-MMM-yyyy')}</h6>
                            <h6 className=' text-[12px] w-[170px]  px-2 py-1 font-[500] border-r'>{d?.client?.contact_name} - {d?.client?.company_name}</h6>
                            <h6 className=' text-[12px] w-[150px] px-2 py-1 font-[500] border-r'>{d?.invoice_no}</h6>
                            <h6 className=' text-[12px] w-[120px] px-2 py-1 font-[500] border-r'>{Priceconstants(d?.invoice_amount_without_gst)}</h6>
                            <h6 className=' text-[12px] w-[90px] px-2 py-1 font-[500] border-r'>{Priceconstants(d?.invoice_amount_with_gst)}</h6>
                            <h6 className=' text-[12px] w-[50px] px-2 py-1 font-[500] border-r'>{d?.invoicepayments?.tds_deduction !== undefined ? `${d?.invoicepayments?.tds_deduction}%` : `0%`}</h6>
                            <h6 className=' text-[12px] w-[150px] px-2 py-1 font-[500] border-r'>{d?.invoicepayments?.amount_with_gst !== undefined ? Priceconstants(d?.invoicepayments?.amount_with_gst) : Priceconstants(0)}</h6>
                            <h6 className=' text-[12px] w-[150px] px-2 py-1 font-[500] bg-slate-300 border-r'>{d?.invoicepayments?.amount_with_gst_incl_tds_value !== undefined ? Priceconstants(d?.invoicepayments?.amount_with_gst_incl_tds_value) : Priceconstants(0)}</h6>
                            <h6 className=' text-[12px] w-[140px] px-2 py-1 font-[500] border-r'>{d?.employee?.name}</h6>
                            <h6 className=' text-[12px] w-[120px] flex  px-2 py-1 font-[500] '>
                              <Tooltip title='View'><span className='cursor-pointer' onClick={() => navigator('detail', { state: d })}><RiBillLine /></span></Tooltip>
                              {user?.roles?.includes('delete_data') && <Tooltip title='Delete'><span className=' cursor-pointer ml-2' onClick={() => deleteData(d?._id)}><AiOutlineDelete /></span></Tooltip>}
                            </h6>
                          </div>))}
                      </>}
                    {['4', '5']?.includes(current_tab) &&
                      <>
                        {data?.data?.map((d, i) => (
                          <div className='flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                            <h6 className=' text-[12px] w-[80px]  px-2 py-1 font-[500] border-r'>{page === 1 ? i + 1 : (i + 1) + ((page - 1) * data?.pagination?.limit)}</h6>
                            <h6 className=' text-[12px] w-[130px]  px-2 py-1 font-[500] border-r'>{moment(d?.invoice_raising_date)?.format('D-MMM-yyyy')}</h6>
                            <h6 className=' text-[12px] w-[170px]  px-2 py-1 font-[500] border-r'>{d?.client?.contact_name} - {d?.client?.company_name}</h6>
                            <h6 className=' text-[12px] w-[130px] px-2 py-1 font-[500] border-r'>{Priceconstants(d?.amount_with_gst)}</h6>
                            <h6 className=' text-[12px] w-[100px] px-2 py-1 font-[500] border-r'>{Priceconstants(d?.amount_without_gst)}</h6>
                            {/* <h6 className=' text-[12px] w-[100px] px-2 py-1 font-[500] border-r'>TDS Rate</h6> */}
                            {/* <h6 className=' text-[12px] w-[140px] px-2 py-1 font-[500] border-r'>{d?.invoicepayments?.tds_deduction !== undefined ? Priceconstants(d?.invoicepayments?.tds_deduction) : Priceconstants(0)}</h6> */}
                            {/* <h6 className=' text-[12px] w-[150px] px-2 py-1 font-[500] border-r'>{d?.invoicepayments?.amount_with_gst !== undefined ? Priceconstants(d?.invoicepayments?.amount_with_gst) : Priceconstants(0)}</h6> */}
                            <h6 className=' text-[12px] w-[140px] px-2 py-1 font-[500] border-r'>{d?.raised_by?.name}</h6>
                            <h6 className=' text-[12px] w-[120px]  px-2 py-1 font-[500] '>
                              <Tooltip title='View'><span className='cursor-pointer' onClick={() => navigator('detail', { state: { _id: d?.invoice?._id } })}><RiBillLine /></span></Tooltip>
                            </h6>
                          </div>))}
                      </>}

                  </div>
                </>}

              {step === 2 &&
                <>
                  {current_tab === '1' &&
                    <div className='grid grid-cols-4 '>
                      {data?.datas?.map((d) => (
                        <div key={d?._id} className='border p-2 m-1 relative'>
                          <Tooltip title='View'><span className='absolute right-1 cursor-pointer' onClick={() => navigator('detail', { state: d })}><RiBillLine /></span></Tooltip>
                          {user?.roles?.includes('delete_data') && <Tooltip title='Delete'><span className='absolute right-7 cursor-pointer' onClick={() => deleteData(d?._id)}><AiOutlineDelete /></span></Tooltip>}
                          <h6 className='text-[11px] font-[400]'>Invoice Amount :<span className='font-[900]'> {rupee.format(d?.amount)?.split('.')[0]}</span> </h6>
                          <h6 className='text-[11px] font-[400]'>Invoice To :<span className='font-[900]'> {d?.client?.land_lord ? 'Land Lord' : 'Client'}</span> </h6>
                          <div className='bg-gray-100 p-2 my-1 '>
                            <h6 className='text-[11px] font-[400]'>Client Info : <span className='font-[900]'>{d?.client?.contact_name}</span>  </h6>
                            <h6 className='text-[11px] font-[400]'>Company Name : {d?.client?.company_name}</h6>
                            {d?.client?.address !== '' && <h6 className='text-[11px] font-[400]'>Address : {d?.client?.address}</h6>}
                          </div>
                          <h6 className='text-[11px] font-[400]'>Requested By : {d?.employee?.name} [{d?.employee?.department_id[0]?.department_name}]</h6>

                          <h6 className='text-[11px] font-[400]'>Invoice Owner : {d?.employee?.name}</h6>
                          <h6 className='text-[10px] font-[400]'>Invoice Release Request on : <span className='font-[900] text-[9px]'> {moment(d?.invoice_raising_date).format('LL')} </span></h6>
                        </div>
                      ))}
                    </div>}

                  {current_tab === '2' &&
                    <div className='grid grid-cols-4'>
                      {data?.datas?.map((d) => (
                        <div key={d?._id} className='border p-2 m-1 relative'>
                          {user?.roles?.includes('delete_data') && <Tooltip title='Delete'><span className='absolute right-7 cursor-pointer' onClick={() => deleteData(d?._id)}><AiOutlineDelete /></span></Tooltip>}
                          <Tooltip title='View'><span className='absolute right-1 cursor-pointer' onClick={() => navigator('detail', { state: d })}><RiBillLine /></span></Tooltip>
                          <h6 className='text-[11px] font-[400]'>Invoice Amount :<span className='font-[900]'> {rupee.format(d?.amount)?.split('.')[0]}</span> </h6>
                          <h6 className='text-[11px] font-[400]'>Invoice To :<span className='font-[900]'> {d?.client?.land_lord ? 'Land Lord' : 'Client'}</span> </h6>
                          <div className='bg-gray-100 p-2 my-1 '>
                            <h6 className='text-[11px] font-[400]'>Client Info : <span className='font-[900]'>{d?.client?.contact_name}</span>  </h6>
                            <h6 className='text-[11px] font-[400]'>Company Name : {d?.client?.company_name}</h6>
                            {d?.client?.address !== '' && <h6 className='text-[11px] font-[400]'>Address : {d?.client?.address}</h6>}
                          </div>
                          <h6 className='text-[11px] font-[400]'>Requested By : {d?.employee?.name} [{d?.employee?.department_id[0]?.department_name}]</h6>

                          <h6 className='text-[11px] font-[400]'>Invoice Owner : {d?.employee?.name}</h6>
                          <h6 className='text-[10px] font-[400]'>Invoice Release Request on : <span className='font-[900] text-[9px]'> {moment(d?.invoice_raising_date).format('LL')} </span></h6>
                        </div>
                      ))}
                    </div>}

                  {current_tab === '3' &&
                    <div className='grid grid-cols-4'>
                      {data?.datas?.map((d) => (
                        <div key={d?._id} className='border p-2 m-1 relative'>
                          {user?.roles?.includes('delete_data') && <Tooltip title='Delete'><span className='absolute right-7 cursor-pointer' onClick={() => deleteData(d?._id)}><AiOutlineDelete /></span></Tooltip>}
                          <Tooltip title='View'><span className='absolute right-1 cursor-pointer' onClick={() => navigator('detail', { state: d })}><RiBillLine /></span></Tooltip>
                          <h6 className='text-[11px] font-[400]'>Invoice Amount :<span className='font-[900]'> {rupee.format(d?.amount)?.split('.')[0]}</span> </h6>
                          <h6 className='text-[11px] font-[400]'>Invoice To :<span className='font-[900]'> {d?.client?.land_lord ? 'Land Lord' : 'Client'}</span> </h6>
                          <div className='bg-gray-100 p-2 my-1 '>
                            <h6 className='text-[11px] font-[400]'>Client Info : <span className='font-[900]'>{d?.client?.contact_name}</span>  </h6>
                            <h6 className='text-[11px] font-[400]'>Company Name : {d?.client?.company_name}</h6>
                            {d?.client?.address !== '' && <h6 className='text-[11px] font-[400]'>Address : {d?.client?.address}</h6>}
                          </div>
                          <h6 className='text-[11px] font-[400]'>Requested By : {d?.employee?.name} [{d?.employee?.department_id[0]?.department_name}]</h6>

                          <h6 className='text-[11px] font-[400]'>Invoice Owner : {d?.employee?.name}</h6>
                          <h6 className='text-[10px] font-[400]'>Invoice Release Request on : <span className='font-[900] text-[9px]'> {moment(d?.invoice_raising_date).format('LL')} </span></h6>
                        </div>
                      ))}
                    </div>}

                  {current_tab === '4' &&
                    <div className='grid grid-cols-4'>
                      {data?.data?.map((d) => (
                        <div key={d?._id} className='border p-2 m-1 relative'>
                          <Tooltip title='View'><span className='absolute right-1 cursor-pointer' onClick={() => navigator('detail', { state: { _id: d?.invoice?._id } })}><RiBillLine /></span></Tooltip>
                          <h6 className='text-[11px] font-[400]'>Invoice Amount :<span className='font-[900]'> {rupee.format(d?.amount_with_gst)?.split('.')[0]}</span> </h6>
                          <div className='bg-gray-100 p-2 my-1 '>
                            <h6 className='text-[11px] font-[400]'>Client Info : <span className='font-[900]'>{d?.client?.contact_name}</span>  </h6>
                            <h6 className='text-[11px] font-[400]'>Company Name : {d?.client?.company_name}</h6>
                            {d?.client?.address !== '' && <h6 className='text-[11px] font-[400]'>Address : {d?.client?.address}</h6>}
                          </div>
                          <h6 className='text-[11px] font-[400]'>Requested By : {d?.invoice?.employee?.name}</h6>

                          <h6 className='text-[11px] font-[400]'>Invoice Owner : {d?.invoice?.employee?.name}</h6>
                          <h6 className='text-[10px] font-[400]'>Invoice Release Request on : <span className='font-[900] text-[9px]'> {moment(d?.invoice?.invoice_raising_date).format('LL')} </span></h6>

                          <div className='bg-red-100 p-2 my-1 '>
                            <h6 className='text-[11px] font-[400]'>Delete Reason : <span className='font-[700]'>{d?.deleted_reason}</span>  </h6>
                          </div>
                        </div>
                      ))}
                    </div>}

                  {current_tab === '5' &&
                    <div className='grid grid-cols-4'>
                      {data?.data?.map((d) => (
                        <div key={d?._id} className='border p-2 m-1 relative'>
                          <Tooltip title='View'><span className='absolute right-1 cursor-pointer' onClick={() => navigator('detail', { state: { _id: d?.invoice?._id } })}><RiBillLine /></span></Tooltip>
                          <h6 className='text-[11px] font-[400]'>Invoice Amount :<span className='font-[900]'> {rupee.format(d?.amount_without_gst)?.split('.')[0]}</span> </h6>
                          <div className='bg-gray-100 p-2 my-1 '>
                            <h6 className='text-[11px] font-[400]'>Client Info : <span className='font-[900]'>{d?.client?.contact_name}</span>  </h6>
                            <h6 className='text-[11px] font-[400]'>Company Name : {d?.client?.company_name}</h6>
                            {d?.client?.address !== '' && <h6 className='text-[11px] font-[400]'>Address : {d?.client?.address}</h6>}
                          </div>
                          <h6 className='text-[11px] font-[400]'>Requested By : {d?.invoice?.employee?.name}</h6>

                          <h6 className='text-[11px] font-[400]'>Invoice Owner : {d?.invoice?.employee?.name}</h6>
                          <h6 className='text-[10px] font-[400]'>Invoice Release Request on : <span className='font-[900] text-[9px]'> {moment(d?.invoice?.invoice_raising_date).format('LL')} </span></h6>


                        </div>
                      ))}
                    </div>}
                </>}
            </div>


          </div>


        </div>
      </div>
    </div>
  )
}

export default InvoiceRequest