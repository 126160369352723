import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { ButtonFilledAutoWidth } from '../../../components/button'
import { TextAreaInput1, TextInput } from '../../../components/input'
import { useNavigate, useLocation } from 'react-router-dom'
import { CreateRoleService, UpdateRoleService } from '../../../services/RoleServices'
import SettingsMenu from '../../staticscreens/SettingsMenu'
import GoBack from '../../../components/back/GoBack'

function RoleCE() {

  const [data, setdata] = useState({ role_name: '', display_name: '', description: '' });
  const [error, seterror] = useState({ role_name: '', display_name: '', description: '' });

  const { state } = useLocation();
  const navigate = useNavigate();



  useEffect(() => {
    if (state?.id !== null && state?.id !== undefined) {
      setdata({ ...data, role_name: state.role_name, display_name: state.display_name, description: state.description !== undefined ? state.description : '' })
    }

  }, [state])

  function handlechange(e) {
    setdata({ ...data, [e.target.name]: e.target.value })
    seterror({ ...error, [e.target.name]: '' })
  }

  async function submitform() {
    if (!data.role_name) {
      seterror({ ...error, role_name: 'Role Name field is required *' })
    } else if (!data.display_name) {
      seterror({ ...error, display_name: 'Display Name field is required *' })
    } else {
      if (state?.id === undefined || state?.id === null) {
        const response = await CreateRoleService(data)
        if (response.status === 201) {
          setdata({ ...data, role_name: '', display_name: '', description: '' })
          seterror({ ...error, role_name: '', display_name: '', description: '' })
          toast.success('Role Created Successfully')
        }
        if (response.status === 422) {
          if (response?.data?.errors?.role_name) {
            seterror({ ...error, role_name: response?.data?.errors?.role_name })
          } else if (response?.data?.errors?.display_name) {
            seterror({ ...error, display_name: response?.data?.errors?.display_name })
          }
        }
      } else {
        let send_data = { ...data }
        send_data["id"] = state?.id
        const response = await UpdateRoleService(send_data, state?.id)
        if (response.status === 200) {
          setdata({ ...data, designation_name: '', description: '' })
          seterror({ ...error, designation_name: '', description: '' })
          navigate(-1)
          toast.success('Role Updated Successfully')
        }
        if (response.status === 422) {
          if (response?.data?.errors?.role_name) {
            seterror({ ...error, role_name: response?.data?.errors?.role_name })
          } else if (response?.data?.errors?.display_name) {
            seterror({ ...error, display_name: response?.data?.errors?.display_name })
          }
        }
      }
    }
  }



  return (
    <div className='flex '>


      <SettingsMenu />

      <div className='sm:w-full lg:w-72 px-4 pt-5' >


        <GoBack />



        <h6 className='font-bold'>{(state?.id !== null && state?.id !== undefined) ? 'Edit' : 'Add'}  Role</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Role</b> for your company employees to access there features.</h6>


        <TextInput
          label={'Role Name'}
          variant="standard"
          name="role_name"
          type="text"
          error={error.role_name}
          value={data.role_name}
          handlechange={handlechange}
          placeholder="Enter your Role name"
          InputLabelProps={{
            style: { color: '#fff', },
          }} />

        <TextInput
          label={'Display Name'}
          variant="standard"
          name="display_name"
          type="text"
          error={error.display_name}
          value={data.display_name}
          handlechange={handlechange}
          placeholder="Enter your Display name"
          InputLabelProps={{
            style: { color: '#fff', },
          }} />

        <TextAreaInput1
          label={'Description'}
          variant="standard"
          name="description"
          type="text"
          error={error.description}
          value={data.description}
          handlechange={handlechange}
          placeholder=""
          InputLabelProps={{
            style: { color: '#fff', },
          }} />

        <div className='mt-5'>
          <ButtonFilledAutoWidth btnName={(state?.id !== null && state?.id !== undefined) ? "UPDATE ROLE" : "ADD ROLE"} onClick={submitform} />
        </div>


      </div>
    </div>
  )
}

export default RoleCE