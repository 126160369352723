import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateFTSVerticalLeadReportService = async (data) => {
    try {
        const result = await post(`api/fts_vertical_report_lead/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFTSVerticalLeadReportService = async (data, id) => {
    try {
        const result = await put(`api/fts_vertical_report_lead/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFTSVerticalLeadReportGraphDataService = async (department) => {
    try {
        const result = await get(`api/fts_vertical_report_lead/get_report_data?department=${department}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFTSVerticalLeadReportService = async (page, text, from_date, to_date, status) => {
    try {
        const result = await get(`api/fts_vertical_report_lead/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&status=${status}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFTSVerticalLeadReportService = async (id) => {
    try {
        const result = await deleteRequest(`api/fts_vertical_report_lead/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadFTSVerticalLeadReportService = async (data) => {
    try {
        const result = await postfd(`api/fts_vertical_report_lead/upload_excel`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadFTSVerticalLeadReportService = async (page, text, from_date, to_date) => {
    try {
        const result = await get(`api/fts_vertical_report_lead/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetDasboarddMontlyReviewReportService = async (from_date, to_date, department, page, page1, page2) => {
    try {
        const result = await get(`api/fts_vertical_report_lead/report_data_montly_review?from_date=${from_date}&to_date=${to_date}&department=${department}&page=${page}&page1=${page1}&page2=${page2}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetVerticalDasboarddMontlyReviewReportService = async (from_date, to_date, department) => {
    try {
        const result = await get(`api/fts_vertical_report_lead/report_vertical_analytics?from_date=${from_date}&to_date=${to_date}&department=${department}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetVericalBasedAssignedRecievedService = async (from_date = '', to_date = '', assigned_department = '', recieved_department = '', page = 1,user ='') => {
    try {
        const result = await get(`api/fts_lead/get_vertical_based_assigned_recieved?from_date=${from_date}&to_date=${to_date}&assigned_department=${assigned_department}&recieved_department=${recieved_department}&page=${page}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetVericalBasedAssignedRecievedService1 = async (from_date = '', to_date = '', assigned_department = '', recieved_department = '', user = '', page = 1) => {
    try {
        const result = await get(`api/fts_lead/get_vertical_based_assigned_recieved1?from_date=${from_date}&to_date=${to_date}&assigned_department=${assigned_department}&recieved_department=${recieved_department}&user=${user}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetVericalBasedAssignedRecievedBasedUserService = async (from_date = '', to_date = '', assigned_department = '', recieved_department = '', page = 1,user='') => {
    try {
        const result = await get(`api/fts_lead/get_vertical_based_assigned_recieved_based_user?from_date=${from_date}&to_date=${to_date}&assigned_department=${assigned_department}&recieved_department=${recieved_department}&page=${page}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}