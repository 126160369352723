import React, { useState, useEffect } from 'react'
import DashboardMenu from './DashboardMenu'
import { DatePicker, Modal, Select } from 'antd'
import { ButtonOutlinedAutoWidth } from '../../components/button'
import { IconButton } from '@mui/material'
import { BsArrowRepeat } from 'react-icons/bs'
import { GetDepartmentService } from '../../services/DepartmentServices'
import { FilterVerticalProfitLossService } from '../../services/VerticalProfitLoss'
import ReactApexChart from 'react-apexcharts';
// import moment from 'moment'
import { AiOutlineClose, AiOutlineContacts, AiOutlineInfoCircle } from 'react-icons/ai';
import Priceconstants from '../../constants/imageConstants'
import { TbTimelineEventText } from "react-icons/tb";
import { MdOutlineExpand } from "react-icons/md";
import { MdMoney } from "react-icons/md";
import { LuPaintbrush2 } from "react-icons/lu";
import moment from 'moment'
import { useLocation } from 'react-router-dom'

function AdminProfitLoss() {

  const { pathname } = useLocation()

  const [step, setstep] = useState(1)
  const [substep, setsubstep] = useState(1)
  const [data, setdata] = useState({})
  const [data1, setdata1] = useState({})
  const [page, setpage] = useState(1)

  const [modalQuestionaries, setmodalQuestionaries] = useState(false)
  const [selected_department, setselected_department] = useState({})

  const [selected_data, setselected_data] = useState({})
  const [search, setsearch] = useState({ text: '', from_date: '', to_date: '', from_date1: '', to_date1: '', department: '', activate: false })

  const [department, setdepartment] = useState([])


  const [modal, setmodal] = useState(false)
  const [selectedData, setselectedData] = useState({
    type: '',
    name: '',
    datas: []
  })


  const [overall_leads, setoverall_leads] = useState([])
  const [overall_invoice, setoverall_invoice] = useState([])
  const [overall_expenses, setoverall_expenses] = useState([])

  const [overall_loan_information, setoverall_loan_information] = useState([])
  const [overall_emi_information, setoverall_emi_information] = useState([])

  const [overall_events, setoverall_events] = useState([])
  const [overall_department_work, setoverall_department_work] = useState([])


  const [overallReport, setoverallReport] = useState({
    my_turnover: 0,
    net_revenue: 0,
    my_expenses: 0,
    net_profit: 0,
    net_payables: 0,
    net_outstanding: 0,
    lead_count: 0
  })

  const [shilpaFoundation, setshilpaFoundation] = useState({
    fund_raised: 0,
    fund_utilized: 0,
    fund_remaining: 0,
    events: [],
    leads: [],
    expense: [],
    shilpa_foundation_receipt_payment: []
  })

  const [galleryData, setgalleryData] = useState({
    new_painting: 0,
    total_painting: 0,
    sold_painting: 0,
    events: [],
  })

  // console.log("galleryData",galleryData)

  const [departmentReport, setdepartmentReport] = useState([])
  const [selecteddepartmentReport, setselecteddepartmentReport] = useState({})

  const [departmentSet, setdepartmentSet] = useState({ departmentArr: [], shilpaFoundation: {}, galleryTeam: {} })

  const [chartOptions1, setchartOptions1] = useState({
    series: [
      {
        name: 'Turnover',
        data: []
      },
      {
        name: 'Net Revenue',
        data: []
      },
      {
        name: 'Profit',
        data: []
      },
      {
        name: 'Expenses',
        data: []
      },
      {
        name: 'Account Balance',
        data: []
      },
      {
        name: 'Outstanding',
        data: []
      }
    ],

    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {

      bar: {
        horizontal: false,
        dataLabels: {
          enabled: false
        },
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },

      },
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#fafafa',
    },

    stroke: {
      show: true,
      width: 1,
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Transaction', 'Project', 'HR', 'FMS', 'Gallery'],
    },
    yaxis: {
      title: {
        text: 'Revenue Forcast'
      },
      labels: {
        formatter: function (val) {
          return numDifferentiation(val)
        }
      }
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return numDifferentiation(val)
        }
      }
    },
    colors: ['#008ffb', '#00e396', '#ffb01a', '#ff4560', '#775dd0']
  })

  const [chartOptions2, setchartOptions2] = useState({
    series: [],
    plotOptions: {
      color: ['#000', '#000'],
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    yaxis: {
      // title: {
      //   text: 'Revenue Forcast'
      // },
      labels: {
        formatter: function (val) {
          return numDifferentiation(val)
        }
      }
    },
    labels: ['Fund Raised', 'Fund Utilized', 'Fund Remaining'],
    colors: ['#008ffb', '#00e396', '#ffb01a', '#ff4560', '#775dd0']
  })

  const [chartOptions3, setchartOptions3] = useState({
    series: [{
      name: 'Amount',
      data: []
    }],
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {

      bar: {
        horizontal: false,
        dataLabels: {
          enabled: false
        },
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },

      },
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#fafafa',
    },
    stroke: {
      show: true,
      width: 1,
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      // categories: ['Sales', 'Receipt','Profit', 'Expenses', 'Net Profit','Payables','Outstanding'],
      categories: ['Sales', 'Receipt', 'Profit', 'Expenses', 'Net Profit', 'Payables', 'Outstanding'],
    },
    yaxis: {
      title: {
        text: ''
        // text: 'Revenue Forcast'
      },
      labels: {
        formatter: function (val) {
          return numDifferentiation(val)
        }
      }
    },
    fill: {
      opacity: 1,
      // colors:['#ff9987','#ebca98','#fffdba','#cfffcc','#bde3fc']
    },
    tooltip: {
      y: {
        formatter: function (val) {
          // console.log("val",val)
          return numDifferentiation(val)
        }
      }
    },
    colors: [
      function ({ value, seriesIndex, dataPointIndex, w }) {
        if (dataPointIndex == 0) {
          return "#008ffb";
        } else if (dataPointIndex == 1) {
          return "#00e396";
        } else if (dataPointIndex == 2) {
          return "#ffb01a";
        } else if (dataPointIndex == 3) {
          return "#ff4560";
        } else if (dataPointIndex == 4) {
          return "#775dd0";
        } else if (dataPointIndex == 5) {
          return "#ff78ae";
        } else if (dataPointIndex == 6) {
          return "#ed9e5c";
        }
      }
    ]
    // colors:['#008ffb','#00e396','#ffb01a','#ff4560','#775dd0']

    // colors:['#9ed7ff','#b3ffbb','#ffe6bf','#ffc8c2','#e0c9ff']

    // colors:['#1f1f1f','#c5c7fa','#b7eebd','#ff6d6a','#102688']
  })

  const [chartOptions4, setchartOptions4] = useState({
    series: [{
      name: 'Amount',
      data: []
    }],
    chart: {
      toolbar: {
        show: false
      },
      height: 400, // Increase height
      width: '100%', // Ensure
    },
    plotOptions: {

      bar: {
        horizontal: false,
        dataLabels: {
          enabled: false
        },
        columnWidth: '70%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },

      },
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#fafafa',
    },
    stroke: {
      show: true,
      width: 1,
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Sales', 'Receipt', 'Expenses', 'Account Balance', 'Net Payables', 'Outstanding'],
      labels: {
        style: {
          fontSize: '10px',
          fontWeight: 'bold',
        },
      }

    },
    yaxis: {
      title: {
        text: 'Revenue Forcast'
      },
      labels: {
        formatter: function (val) {
          return numDifferentiation(val)
        }
      }
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return numDifferentiation(val)
        }
      }
    },
    colors: [
      function ({ value, seriesIndex, dataPointIndex, w }) {
        if (dataPointIndex == 0) {
          return "#008ffb";
        } else if (dataPointIndex == 1) {
          return "#00e396";
        } else if (dataPointIndex == 2) {
          return "#ffb01a";
        } else if (dataPointIndex == 3) {
          return "#ff4560";
        } else if (dataPointIndex == 4) {
          return "#775dd0";
        } else if (dataPointIndex == 5) {
          return "#f2ff91";
        } else if (dataPointIndex == 6) {
          return "#ff78ae";
        } else if (dataPointIndex == 7) {
          return "#ff78ae";
        } else if (dataPointIndex == 8) {
          return "#ff78ae";
        }
      }
    ]
  })

  function numDifferentiation(value) {
    const val = Math.abs(value)
    if (val >= 10000000) return `${(value / 10000000).toFixed(0)} Cr`
    if (val >= 100000) return `${(value / 100000).toFixed(0)} Lac`
    return value;
  }


  useEffect(() => {
    getDepartment()
    applyfilterfunction()
  }, [])


  async function getDepartment() {
    let arr = []
    const response = await GetDepartmentService()
    const transactionTeam = response?.data?.datas?.find((f) => f?.department_name === 'Transaction Team')
    const projectTeam = response?.data?.datas?.find((f) => f?.department_name === 'Project Team')
    const hrTeam = response?.data?.datas?.find((f) => f?.department_name === 'HR Team')
    const fmsTeam = response?.data?.datas?.find((f) => f?.department_name === 'FMS Team')
    const galleryTeam = response?.data?.datas?.find((f) => f?.department_name === 'Fidelitus Gallery')
    const digitalMediaTeam = response?.data?.datas?.find((f) => f?.department_name === 'Digital Media')
    const mdTeam = response?.data?.datas?.find((f) => f?.department_name === 'Corp Team')
    const shilpaFoundationTeam = response?.data?.datas?.find((f) => f?.department_name === 'Shilpa Foundation')

    arr = [
      { label: transactionTeam?.department_name, value: transactionTeam?.id },
      { label: projectTeam?.department_name, value: projectTeam?.id },
      { label: hrTeam?.department_name, value: hrTeam?.id },
      { label: fmsTeam?.department_name, value: fmsTeam?.id },
      { label: galleryTeam?.department_name, value: galleryTeam?.id },
      { label: shilpaFoundationTeam?.department_name, value: shilpaFoundationTeam?.id },
      { label: digitalMediaTeam?.department_name, value: digitalMediaTeam?.id },
      { label: mdTeam?.department_name, value: mdTeam?.id },
    ]
    setdepartment(arr)

    setdepartmentSet({ ...departmentSet, departmentArr: arr, shilpaFoundation: shilpaFoundationTeam, galleryTeam: galleryTeam })
    applyfilterfunction(arr, shilpaFoundationTeam, galleryTeam)
  }

  async function resetfilterfunction(departmentArr, shilpaFoundationTeam, galleryTeam) {
    const response = await FilterVerticalProfitLossService({ text: '', from_date: '', to_date: '', from_date1: '', to_date1: '', department: '', activate: false })
    let d = response?.data?.datas

    setdata1(d)

    let my_turnover = 0;
    let net_revenue = 0;
    let my_expenses = 0;
    let net_profit = 0;
    let net_outstanding = 0;
    let net_payables = 0;
    let lead_count = 0;

    let leads = []
    let invoice_info = []
    let events = []
    let expenses_bifurcation = []
    let department_work = []
    let loan_information = []
    let emi_information = []

    if (galleryTeam !== null && galleryTeam !== undefined) {
      const gallery_docs = d?.find((f) => f?._id === galleryTeam?.id)
      let events_array = []

      if (gallery_docs?.events?.length > 0) {
        let arr = []
        gallery_docs?.events?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: galleryTeam?.department })
          })
        })
        events_array = [...events_array, ...arr]
      }

      let datObj = {
        total_painting: gallery_docs?.total_no_of_painting !== undefined ? gallery_docs?.total_no_of_painting : 0,
        new_painting: gallery_docs?.new_paintings_added_count !== undefined ? gallery_docs?.new_paintings_added_count : 0,
        sold_painting: gallery_docs?.paintings_sold_count !== undefined ? gallery_docs?.paintings_sold_count : 0,
        events: events_array,
      }

      setgalleryData(datObj)
    }

    if (shilpaFoundationTeam !== null && shilpaFoundationTeam !== undefined) {
      const shilpa_foundation_docs = d?.find((f) => f?._id === shilpaFoundationTeam?.id)
      let leads_array = []
      let expenses_array = []
      let events_array = []
      let shilpa_foundation_receipt_payment_array = []

      if (shilpa_foundation_docs?.leads?.length > 0) {
        let arr = []
        shilpa_foundation_docs?.leads?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: shilpa_foundation_docs?.department })
          })
        })
        leads_array = [...leads_array, ...arr]
      }

      if (shilpa_foundation_docs?.shilpa_foundation_receipt_payment?.length > 0) {
        let arr = []
        shilpa_foundation_docs?.shilpa_foundation_receipt_payment?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: shilpa_foundation_docs?.department })
          })
        })
        shilpa_foundation_receipt_payment_array = [...shilpa_foundation_receipt_payment_array, ...arr]
      }

      if (shilpa_foundation_docs?.events?.length > 0) {
        let arr = []
        shilpa_foundation_docs?.events?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: shilpa_foundation_docs?.department })
          })
        })
        events_array = [...events_array, ...arr]
      }

      if (shilpa_foundation_docs?.expenses_bifurcation?.length > 0) {
        let arr = []
        shilpa_foundation_docs?.expenses_bifurcation?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: shilpa_foundation_docs?.department })
          })
        })
        expenses_array = [...expenses_array, ...arr]
      }

      let datObj = {
        fund_raised: shilpa_foundation_docs?.fund_raised === undefined ? 0 : shilpa_foundation_docs?.fund_raised,
        fund_utilized: shilpa_foundation_docs?.fund_utilized === undefined ? 0 : shilpa_foundation_docs?.fund_utilized,
        fund_remaining: shilpa_foundation_docs?.balance === undefined ? 0 : shilpa_foundation_docs?.balance,
        events: events_array,
        leads: leads_array,
        expense: expenses_array,
        shilpa_foundation_receipt_payment: shilpa_foundation_receipt_payment_array,
      }

      // console.log("datObj",datObj)
      setshilpaFoundation(datObj)

      setchartOptions2({
        ...chartOptions2,
        series: [datObj?.fund_raised, datObj?.fund_utilized, datObj?.fund_remaining]
      })
    } else {

    }

    d?.forEach((d1) => {
      if (d1?.leads?.length > 0) {
        let arr = []
        d1?.leads?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        leads = [...leads, ...arr]
      }
      if (d1?.invoice_info?.length > 0) {
        let arr = []
        d1?.invoice_info?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        invoice_info = [...invoice_info, ...arr]

      }
      if (d1?.new_events?.length > 0) {
        let arr = []
        d1?.new_events?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        events = [...events, ...arr]
      }
      if (d1?.department_work?.length > 0) {
        let arr = []
        d1?.department_work?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        department_work = [...department_work, ...arr]
      }
      if (d1?.expenses_bifurcation?.length > 0) {
        let arr = []
        d1?.expenses_bifurcation?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        expenses_bifurcation = [...expenses_bifurcation, ...arr]

      }
      if (d1?.turn_over !== undefined) {
        my_turnover += d1.turn_over
      }
      if (d1?.revenue !== undefined) {
        net_revenue += d1.revenue
      }
      if (d1?.profit !== undefined) {
        net_profit += d1.profit
      }
      if (d1?.expense !== undefined) {
        my_expenses += d1.expense
      }
      if (d1?.outstanding !== undefined) {
        net_outstanding += d1.outstanding
      }
      if (d1?.payables !== undefined) {
        net_payables += d1.payables
      }
      if (d1?.loan_details?.length > 0) {
        let arr = []
        d1?.loan_details?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        loan_information = [...loan_information, ...arr]
      }
      if (d1?.emi_details?.length > 0) {
        let arr = []
        d1?.emi_details?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        emi_information = [...emi_information, ...arr]
      }

    })

    setoverall_leads(leads)
    setoverall_invoice(invoice_info)
    setoverall_expenses(expenses_bifurcation)
    setoverall_events(events)
    setoverall_department_work(department_work)
    setoverall_emi_information(emi_information)
    setoverall_loan_information(loan_information)

    let chartOptionseries3 = [
      {
        name: 'Amount',
        data: [
          my_turnover, net_revenue, my_expenses, net_profit, net_payables, net_outstanding
        ]
      }
    ]

    let departmentwise_turnover = []
    let departmentwise_net_revenue = []
    let departmentwise_expenses = []
    let departmentwise_invoice_info = []
    let departmentwise_net_profit = []
    let departmentwise_outstanding = []
    let departmentwise_net_payables = []

    let team_data = {
      department_name: '',
      department_id: '',
      my_turnover: '',
      net_revenue: '',
      my_expenses: '',
      net_profit: '',
      net_outstanding: '',
      net_payables: '',
      total_leads: '',
      lead_data: [],
      event_data: [],
      expenses_data: [],
      new_painting: 0,
    }

    if (departmentArr?.length > 0) {
      let arr = []
      departmentArr?.forEach((d2) => {
        let department = { department_name: d2?.label, _id: d2?.value }
        let d1 = d?.filter((f) => f?._id === d2?.value)
        // console.log("d1 here anna",d1)
        if (d1 === null || d1 === undefined || d1.length === 0) {

          team_data = {
            department_name: d2?.label,
            department_id: d2?.value,
            my_turnover: 0,
            net_revenue: 0,
            my_expenses: 0,
            net_profit: 0,
            net_outstanding: 0,
            net_payables: 0,
            total_leads: 0,
            lead_data: [],
            invoice_info: [],
            event_data: [],
            expenses_data: [],
            new_painting: 0,
            sold_painting: 0,
            employee_entry: 0,
            employee_exit: 0,
          }
          departmentwise_turnover.push(0)
          departmentwise_net_revenue.push(0)
          departmentwise_expenses.push(0)
          departmentwise_net_profit.push(0)
          departmentwise_invoice_info.push(0)
          departmentwise_outstanding.push(0)
          departmentwise_net_payables.push(0)
        } else {
          let arr = []
          let arr1 = []
          let arr2 = []
          let arr3 = []
          let arr4 = []


          let employee_entry = 0;
          let employee_exit = 0;
          let my_expenses = 0;
          let my_turnover = 0;
          let net_outstanding = 0;
          let net_payables = 0;
          let net_profit = 0;
          let net_revenue = 0;
          let total_painting = 0;
          let new_painting = 0;
          let sold_painting = 0;
          let lead_count_department = 0;


          d1?.forEach((d2, i) => {
            employee_entry += d2?.employee_info_entry !== undefined ? d2?.employee_info_entry : 0
            employee_exit += d2?.employee_info_exit !== undefined ? d2?.employee_info_exit : 0
            my_expenses += d2?.expense !== undefined ? d2?.expense : 0
            my_turnover += d2?.turn_over !== undefined ? d2?.turn_over : 0
            net_outstanding += d2?.outstanding !== undefined ? d2?.outstanding : 0
            net_payables += d2?.payables !== undefined ? d2?.payables : 0
            net_profit += d2?.profit !== undefined ? d2?.profit : 0
            net_revenue += d2?.revenue !== undefined ? d2?.revenue : 0
            total_painting += d2?.total_no_of_painting !== undefined ? d2?.total_no_of_painting : 0
            new_painting += d2?.new_paintings_added_count !== undefined ? d2?.new_paintings_added_count : 0
            sold_painting += d2?.paintings_sold_count !== undefined ? d2?.paintings_sold_count : 0


            if (d2?.leads?.length > 0) {


              d2?.leads?.forEach((d3) => {

                d3?.forEach((d4) => {
                  // lead_count_department +=  d2?.leads?.length
                  lead_count_department += 1

                  let obj = {
                    ...d4,
                    department: department
                  }
                  arr.push(obj)
                })

              })
            }

            if (d2.invoice_info?.length > 0) {
              d2?.invoice_info?.forEach((d3) => {
                d3?.forEach((d4) => {
                  let obj = {
                    ...d4,
                    department: department
                  }
                  arr3.push(obj)
                })

              })
            }

            if (d2?.new_events?.length > 0) {
              d2?.new_events?.forEach((d3) => {
                d3?.forEach((d4) => {
                  let obj = {
                    ...d4,
                    department: department
                  }
                  arr1.push(obj)
                })
              })
            }

            if (d2?.expenses_bifurcation?.length > 0) {
              d2?.expenses_bifurcation?.forEach((d3) => {
                d3?.forEach((d4) => {
                  let obj = {
                    ...d4,
                    department: department
                  }
                  arr2.push(obj)
                })
              })
            }

            if (d2?.department_work?.length > 0) {
              d2?.department_work?.forEach((d3) => {
                d3?.forEach((d4) => {
                  let obj = {
                    ...d4,
                    department: department
                  }
                  arr4.push(obj)
                })
              })
            }


            // if(i === (d1.length -1)){}

          })

          // console.log("my_expenses",my_expenses)

          team_data = {
            department_name: d2?.label,
            department_id: d2?.value,
            my_turnover: my_turnover,
            net_revenue: net_revenue,
            my_expenses: my_expenses,
            net_profit: net_profit,
            net_outstanding: net_outstanding,
            net_payables: net_payables,
            total_leads: lead_count_department,
            lead_data: arr,
            event_data: arr1,
            expenses_data: arr2,
            invoice_info: arr3,
            department_work: arr4,
            new_painting: new_painting,
            total_painting: total_painting,
            sold_painting: sold_painting,
            employee_entry: employee_entry,
            employee_exit: employee_exit,
          }

          lead_count += arr?.length

          // console.log("da1 here Department",d1?.department?.department_name,d1?.leads?.length)
          departmentwise_turnover.push(my_turnover)
          departmentwise_net_revenue.push(net_revenue)
          departmentwise_expenses.push(my_expenses)
          departmentwise_invoice_info.push(invoice_info)
          departmentwise_net_profit.push(net_profit)
          departmentwise_outstanding.push(net_outstanding)
          departmentwise_net_payables.push(net_payables)
        }

        arr.push(team_data)
      })

      setdepartmentReport(arr)

      if (selected_department?.value !== undefined) {
        let datas = departmentReport?.find((f) => f?.department_id === selected_department?.value)

        console.log("datas", datas)

        setselecteddepartmentReport(datas)
        let chartOptionseries4 = [
          {
            name: 'Amount',
            data: [
              datas.my_turnover,
              datas.net_revenue,
              datas.my_expenses,
              datas.net_profit,
              datas.net_outstanding
            ]
          }
        ]

        setchartOptions4({
          ...chartOptions4, series: chartOptionseries4
        })
      }
    }

    setoverallReport({
      my_turnover,
      net_revenue,
      my_expenses,
      net_profit,
      net_outstanding,
      net_payables,
      lead_count
    })

    setchartOptions3({
      ...chartOptions3, series: chartOptionseries3
    })

    setchartOptions1({
      ...chartOptions1, series: [
        {
          name: 'Turnover',
          data: departmentwise_turnover
        },
        {
          name: 'Net Revenue',
          data: departmentwise_net_revenue
        },
        {
          name: 'Expenses',
          data: departmentwise_expenses
        },
        {
          name: 'Net Profit',
          data: departmentwise_net_profit
        },
        {
          name: 'Net Payables',
          data: net_payables
        },
        {
          name: 'Outstanding',
          data: departmentwise_outstanding
        }
      ]
    })
    setdata(response?.data)
  }

  async function applyfilterfunction(departmentArr, shilpaFoundationTeam, galleryTeam) {
    const response = await FilterVerticalProfitLossService(search)
    let d = response?.data?.datas

    // console.log("d here anna",d)

    setdata1(d)

    let my_turnover = 0;
    let net_revenue = 0;
    let profit = 0;
    let my_expenses = 0;
    let net_profit = 0;
    let net_payables = 0;
    let net_outstanding = 0;
    let lead_count = 0;

    let leads = []
    let invoice_info = []
    let events = []
    let expenses_bifurcation = []
    let loan_information = []
    let emi_information = []
    let department_work = []

    if (galleryTeam !== null && galleryTeam !== undefined) {
      const gallery_docs = d?.find((f) => f?._id === galleryTeam?.id)
      let events_array = []

      if (gallery_docs?.events?.length > 0) {
        let arr = []
        gallery_docs?.events?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: galleryTeam?.department })
          })
        })
        events_array = [...events_array, ...arr]
      }

      let datObj = {
        total_painting: gallery_docs?.total_no_of_painting !== undefined ? gallery_docs?.total_no_of_painting : 0,
        new_painting: gallery_docs?.new_paintings_added_count !== undefined ? gallery_docs?.new_paintings_added_count : 0,
        sold_painting: gallery_docs?.paintings_sold_count !== undefined ? gallery_docs?.paintings_sold_count : 0,
        events: events_array,
      }

      setgalleryData(datObj)
    }

    if (shilpaFoundationTeam !== null && shilpaFoundationTeam !== undefined) {
      const shilpa_foundation_docs = d?.find((f) => f?._id === shilpaFoundationTeam?.id)
      let leads_array = []
      let expenses_array = []
      let events_array = []
      let shilpa_foundation_receipt_payment_array = []


      if (shilpa_foundation_docs?.leads?.length > 0) {
        let arr = []
        shilpa_foundation_docs?.leads?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: shilpa_foundation_docs?.department })
          })
        })
        leads_array = [...leads_array, ...arr]
      }

      if (shilpa_foundation_docs?.shilpa_foundation_receipt_payment?.length > 0) {
        let arr = []
        shilpa_foundation_docs?.shilpa_foundation_receipt_payment?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: shilpa_foundation_docs?.department })
          })
        })
        shilpa_foundation_receipt_payment_array = [...shilpa_foundation_receipt_payment_array, ...arr]
      }

      if (shilpa_foundation_docs?.events?.length > 0) {
        let arr = []
        shilpa_foundation_docs?.events?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: shilpa_foundation_docs?.department })
          })
        })
        events_array = [...events_array, ...arr]
      }

      if (shilpa_foundation_docs?.expenses_bifurcation?.length > 0) {
        let arr = []
        shilpa_foundation_docs?.expenses_bifurcation?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: shilpa_foundation_docs?.department })
          })
        })
        expenses_array = [...expenses_array, ...arr]
      }

      let datObj = {
        fund_raised: shilpa_foundation_docs?.fund_raised === undefined ? 0 : shilpa_foundation_docs?.fund_raised,
        fund_utilized: shilpa_foundation_docs?.fund_utilized === undefined ? 0 : shilpa_foundation_docs?.fund_utilized,
        fund_remaining: shilpa_foundation_docs?.balance === undefined ? 0 : shilpa_foundation_docs?.balance,
        events: events_array,
        leads: leads_array,
        expense: expenses_array,
        shilpa_foundation_receipt_payment: shilpa_foundation_receipt_payment_array,
      }

      // console.log("datObj",datObj)
      setshilpaFoundation(datObj)


      setchartOptions2({
        ...chartOptions2,
        series: [datObj?.fund_raised, datObj?.fund_utilized, datObj?.fund_remaining]
      })
    }

    d?.forEach((d1) => {
      if (d1?.leads?.length > 0) {
        let arr = []
        d1?.leads?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        leads = [...leads, ...arr]
      }
      if (d1?.invoice_info?.length > 0) {
        let arr = []
        d1?.invoice_info?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        invoice_info = [...invoice_info, ...arr]

      }
      if (d1?.new_events?.length > 0) {
        let arr = []
        d1?.new_events?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        events = [...events, ...arr]
      }
      if (d1?.department_work?.length > 0) {
        let arr = []
        d1?.department_work?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        department_work = [...department_work, ...arr]
      }
      if (d1?.expenses_bifurcation?.length > 0) {
        let arr = []
        d1?.expenses_bifurcation?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        expenses_bifurcation = [...expenses_bifurcation, ...arr]
      }
      // added here okk
      if (d1?.loan_details?.length > 0) {
        let arr = []
        d1?.loan_details?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        loan_information = [...loan_information, ...arr]
      }
      if (d1?.emi_details?.length > 0) {
        let arr = []
        d1?.emi_details?.forEach((d2) => {
          d2?.forEach((d3) => {
            arr.push({ ...d3, department: d1?.department })
          })
        })
        emi_information = [...emi_information, ...arr]
      }
      if (d1?.turn_over !== undefined) {
        my_turnover += d1.turn_over
      }
      if (d1?.revenue !== undefined) {
        net_revenue += d1.revenue
      }
      if (d1?.profit !== undefined) {
        profit += d1.profit
      }
      if (d1?.net_profit !== undefined) {
        net_profit += d1.net_profit
      }
      if (d1?.expense !== undefined) {
        my_expenses += d1.expense
      }
      if (d1?.outstanding !== undefined) {
        net_outstanding += d1.outstanding
      }
      if (d1?.payables !== undefined) {
        net_payables += d1.payables
      }


    })

    setoverall_leads(leads)
    setoverall_invoice(invoice_info)
    setoverall_expenses(expenses_bifurcation)
    setoverall_events(events)
    setoverall_emi_information(emi_information)
    setoverall_loan_information(loan_information)
    setoverall_department_work(department_work)

    let chartOptionseries3 = [
      {
        name: 'Amount',
        data: [
          my_turnover,
          net_revenue,
          profit,
          my_expenses,
          net_profit,
          net_payables,
          net_outstanding
        ]
      }
    ]

    let departmentwise_turnover = []
    let departmentwise_net_revenue = []
    let departmentwise_expenses = []
    let departmentwise_invoice_info = []
    let departmentwise_profit = []
    let departmentwise_net_profit = []
    let departmentwise_outstanding = []
    let departmentwise_net_payables = []

    let team_data = {
      department_name: '',
      department_id: '',
      my_turnover: '',
      net_revenue: '',
      my_expenses: '',
      vendor_expenses: '',
      profit: '',
      net_profit: '',
      net_outstanding: '',
      total_leads: '',
      lead_data: [],
      event_data: [],
      expenses_data: [],
      new_painting: 0,
      total_no_of_inhouse_headcount: '',
      total_no_of_external_contractor_if_applicable: '',
      risk_managent_compliance: {},
      financial_health: {},
      innovation_and_growth: {},
      planning_and_outlook: {},
      customer_and_stakeholder_satisfaction: {},
      employee_engagment_and_development: {},
    }

    if (departmentArr?.length > 0) {
      let arr = []
      departmentArr?.forEach((d2) => {
        let department = { department_name: d2?.label, _id: d2?.value }
        let d1 = d?.filter((f) => f?._id === d2?.value)
        // console.log("d2 here",d2)
        // console.log("d1 here",d1)
        // console.log("department",department?.department_name)
        // console.log("d1?.customer_and_stakeholder_satisfaction",d1?.customer_and_stakeholder_satisfaction)
        if (d1 === null || d1 === undefined || d1.length === 0) {

          team_data = {
            department_name: d2?.label,
            department_id: d2?.value,
            my_turnover: 0,
            net_revenue: 0,
            my_expenses: 0,
            net_profit: 0,
            net_payables: 0,
            net_outstanding: 0,
            total_leads: 0,
            lead_data: [],
            invoice_info: [],
            event_data: [],
            expenses_data: [],
            emi_details: [],
            loan_details: [],
            new_painting: 0,
            sold_painting: 0,
            employee_entry: 0,
            employee_exit: 0,
            total_no_of_inhouse_headcount: '',
            total_no_of_external_contractor_if_applicable: '',
            risk_managent_compliance: {},
            financial_health: {},
            innovation_and_growth: {},
            planning_and_outlook: {},
            customer_and_stakeholder_satisfaction: {},
            employee_engagment_and_development: {},
          }
          departmentwise_turnover.push(0)
          departmentwise_net_revenue.push(0)
          departmentwise_expenses.push(0)
          departmentwise_net_profit.push(0)
          departmentwise_invoice_info.push(0)
          departmentwise_outstanding.push(0)
          departmentwise_net_payables.push(0)
        } else {
          let arr = []
          let arr1 = []
          let arr2 = []
          let arr3 = []
          let arr4 = []
          let arr5 = []
          let arr6 = []


          let employee_entry = 0;
          let employee_exit = 0;
          let my_expenses = 0;
          let vendor_expenses = 0;
          let my_turnover = 0;
          let net_outstanding = 0;
          let net_payables = 0;
          let profit = 0;
          let net_profit = 0;
          let net_revenue = 0;
          let total_painting = 0;
          let new_painting = 0;
          let sold_painting = 0;
          let lead_count_department = 0;


          // console.log("d 1 here annaaaaa",d1)

          d1?.forEach((d2, i) => {
            employee_entry += d2?.employee_info_entry !== undefined ? d2?.employee_info_entry : 0
            employee_exit += d2?.employee_info_exit !== undefined ? d2?.employee_info_exit : 0
            my_expenses += d2?.expense !== undefined ? d2?.expense : 0
            vendor_expenses += d2?.vendor_expenses !== undefined ? d2?.expense : 0
            my_turnover += d2?.turn_over !== undefined ? d2?.turn_over : 0
            net_outstanding += d2?.outstanding !== undefined ? d2?.outstanding : 0
            net_payables += d2?.payables !== undefined ? d2?.payables : 0
            profit += d2?.profit !== undefined ? d2?.profit : 0
            net_profit += d2?.net_profit !== undefined ? d2?.net_profit : 0
            net_revenue += d2?.revenue !== undefined ? d2?.revenue : 0
            total_painting += d2?.total_no_of_painting !== undefined ? d2?.total_no_of_painting : 0
            new_painting += d2?.new_paintings_added_count !== undefined ? d2?.new_paintings_added_count : 0
            sold_painting += d2?.paintings_sold_count !== undefined ? d2?.paintings_sold_count : 0


            if (d2?.leads?.length > 0) {


              d2?.leads?.forEach((d3) => {

                d3?.forEach((d4) => {
                  // lead_count_department +=  d2?.leads?.length
                  lead_count_department += 1

                  let obj = {
                    ...d4,
                    department: department
                  }
                  arr.push(obj)
                })

              })
            }

            if (d2.invoice_info?.length > 0) {
              d2?.invoice_info?.forEach((d3) => {
                d3?.forEach((d4) => {
                  let obj = {
                    ...d4,
                    department: department
                  }
                  arr3.push(obj)
                })

              })
            }

            if (d2?.new_events?.length > 0) {
              d2?.new_events?.forEach((d3) => {
                d3?.forEach((d4) => {
                  let obj = {
                    ...d4,
                    department: department
                  }
                  arr1.push(obj)
                })
              })
            }

            if (d2?.expenses_bifurcation?.length > 0) {
              d2?.expenses_bifurcation?.forEach((d3) => {
                d3?.forEach((d4) => {
                  let obj = {
                    ...d4,
                    department: department
                  }
                  arr2.push(obj)
                })
              })
            }

            if (d2?.department_work?.length > 0) {
              d2?.department_work?.forEach((d3) => {
                d3?.forEach((d4) => {
                  let obj = {
                    ...d4,
                    department: department
                  }
                  arr4.push(obj)
                })
              })
            }

            if (d2?.emi_details?.length > 0) {
              d2?.emi_details?.forEach((d3) => {
                d3?.forEach((d4) => {
                  let obj = {
                    ...d4,
                    department: department
                  }
                  arr5.push(obj)
                })
              })
            }

            if (d2?.loan_details?.length > 0) {
              d2?.loan_details?.forEach((d3) => {
                d3?.forEach((d4) => {
                  let obj = {
                    ...d4,
                    department: department
                  }
                  arr6.push(obj)
                })
              })
            }


            // if(i === (d1.length -1)){}

          })

          // console.log("my_expenses",my_expenses)

          team_data = {
            department_name: d2?.label,
            department_id: d2?.value,
            my_turnover: my_turnover,
            net_revenue: net_revenue,
            my_expenses: my_expenses,
            vendor_expenses: vendor_expenses,
            profit: profit,
            net_profit: net_profit,
            net_outstanding: net_outstanding,
            net_payables: net_payables,
            total_leads: lead_count_department,
            lead_data: arr,
            event_data: arr1,
            expenses_data: arr2,
            invoice_info: arr3,
            department_work: arr4,
            emi_details: arr5,
            loan_details: arr6,
            new_painting: new_painting,
            total_painting: total_painting,
            sold_painting: sold_painting,
            employee_entry: employee_entry,
            employee_exit: employee_exit,
            total_no_of_inhouse_headcount: d1[0]?.total_no_of_inhouse_headcount !== undefined ? d1[0]?.total_no_of_inhouse_headcount : '',
            total_no_of_external_contractor_if_applicable: d1[0]?.total_no_of_external_contractor_if_applicable !== undefined ? d1[0]?.total_no_of_external_contractor_if_applicable : '',
            risk_managent_compliance: d1[0]?.risk_managent_compliance !== undefined ? d1[0]?.risk_managent_compliance : {},
            financial_health: d1[0]?.financial_health !== undefined ? d1[0]?.financial_health : {},
            innovation_and_growth: d1[0]?.innovation_and_growth !== undefined ? d1[0]?.innovation_and_growth : {},
            planning_and_outlook: d1[0]?.planning_and_outlook !== undefined ? d1[0]?.planning_and_outlook : {},
            customer_and_stakeholder_satisfaction: d1[0]?.customer_and_stakeholder_satisfaction !== undefined ? d1[0]?.customer_and_stakeholder_satisfaction : {},
            employee_engagment_and_development: d1[0]?.employee_engagment_and_development !== undefined ? d1[0]?.employee_engagment_and_development : {},

          }

          lead_count += arr?.length

          // console.log("da1 here Department",d1?.department?.department_name,d1?.leads?.length)
          departmentwise_turnover.push(my_turnover)
          departmentwise_net_revenue.push(net_revenue)
          departmentwise_expenses.push(my_expenses)
          departmentwise_invoice_info.push(invoice_info)
          departmentwise_profit.push(profit)
          departmentwise_net_profit.push(net_profit)
          departmentwise_outstanding.push(net_outstanding)
          departmentwise_net_payables.push(net_payables)
        }

        arr.push(team_data)
      })

      setdepartmentReport(arr)

      if (selected_department?.value !== undefined) {
        let datas = departmentReport?.find((f) => f?.department_id === selected_department?.value)

        setselecteddepartmentReport(datas)
        let chartOptionseries4 = [
          {
            name: 'Amount',
            data: [
              datas.my_turnover,
              datas.net_revenue,
              datas.profit,
              datas.my_expenses,
              datas.net_profit,
              datas.net_outstanding
            ]
          }
        ]

        setchartOptions4({
          ...chartOptions4, series: chartOptionseries4
        })
      }
    }

    setoverallReport({
      my_turnover,
      net_revenue,
      profit,
      my_expenses,
      net_profit,
      net_outstanding,
      net_payables,
      lead_count
    })

    setchartOptions3({
      ...chartOptions3, series: chartOptionseries3
    })

    setchartOptions1({
      ...chartOptions1, series: [
        {
          name: 'Turnover',
          data: departmentwise_turnover
        },
        {
          name: 'Net Revenue',
          data: departmentwise_net_revenue
        },
        {
          name: 'Expenses',
          data: departmentwise_expenses
        },
        {
          name: 'Net Profit',
          data: departmentwise_net_profit
        },
        {
          name: 'Payables',
          data: departmentwise_net_payables
        },
        {
          name: 'Outstanding',
          data: departmentwise_outstanding
        }
      ]
    })
    setdata(response?.data)
  }

  // console.log("departmentReport",departmentReport)

  async function resetfunc() {
    setsearch({ text: '', from_date: '', to_date: '', from_date1: '', to_date1: '', activate: false })
    setdata({})
    setdata1({})
    setselected_data({})
    setstep(1)
    resetfilterfunction(departmentSet?.departmentArr, departmentSet?.shilpaFoundation, departmentSet?.galleryTeam)
  }

  let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });


  // console.log("chartOptionseries3",chartOptionseries3)


  async function setstepfunc(v) {
    if (v === 1) {
      setstep(1)
    } else {
      setstep(2)
      if (search?.department === '' || search?.department === null || search?.department === undefined || selected_department?.value === undefined) {
        setselected_department(department[0])
        setsearch({ ...search, department: department[0] })

        let datas = departmentReport?.find((f) => f?.department_id === department[0]?.value)

        console.log("datas  111", datas)

        setselecteddepartmentReport(datas)
        let chartOptionseries4 = [
          {
            name: 'Amount',
            data: [
              datas.my_turnover,
              datas.net_revenue,
              datas.my_expenses,
              datas.net_profit,
              datas.net_payables,
              datas.net_outstanding
            ]
          }
        ]

        setchartOptions4({
          ...chartOptions4, series: chartOptionseries4
        })
      } else {
        let datas = departmentReport?.find((f) => f?.department_id === selected_department?.value)

        setselecteddepartmentReport(datas)
        let chartOptionseries4 = [
          {
            name: 'Amount',
            data: [
              datas.my_turnover,
              datas.net_revenue,
              datas.my_expenses,
              datas.net_profit,
              datas.net_payables,
              datas.net_outstanding
            ]
          }
        ]

        setchartOptions4({
          ...chartOptions4, series: chartOptionseries4
        })
      }
    }
  }


  async function handleChangedepartment(e) {
    setsearch({ ...search, department: department?.find((f) => f?.value === e) })
    setselected_department(department?.find((f) => f?.value === e))
    if (step === 2) {
      let datas = departmentReport?.find((f) => f?.department_id === e)
      setselecteddepartmentReport(datas)

      let categories = []

      console.log("datas?.department_name", datas?.department_name)
      if (datas?.department_name === 'Project Team' || datas?.department_name === 'FMS Team') {
        categories = ['Turn Over', 'Revenue', 'Profit', 'Expenses', 'Vendor Expenses', 'Net Profit', 'Net Payables', 'Outstanding']
      } else {
        categories = ['Sales', 'Receipt', 'Expenses', 'Account Balance', 'Net Payables', 'Outstanding']
      }

      let chartOptionseries4 = []

      console.log("categories", categories)


      if (datas?.department_name === 'Project Team' || datas?.department_name === 'FMS Team') {
        chartOptionseries4 = [
          {
            name: 'Amount',
            data: [
              datas.my_turnover,
              datas.net_revenue,
              datas.profit,
              datas.my_expenses,
              datas.vendor_expenses,
              datas.net_profit,
              datas.net_payables,
              datas.net_outstanding

            ]
          }
        ]
      } else {
        chartOptionseries4 = [
          {
            name: 'Amount',
            data: [
              datas.my_turnover,
              datas.net_revenue,
              datas.my_expenses,
              datas.net_profit,
              datas.net_payables,
              datas.net_outstanding
            ]
          }
        ]
      }



      setchartOptions4({
        ...chartOptions4,
        series: chartOptionseries4,
        xaxis: {
          categories: categories,
        },

      })

      // console.log("categories", categories)
      // console.log("chartOptionseries4", chartOptionseries4)
    }
  }

  // console.log("chartOptions4", chartOptions4)


  // console.log("selecteddepartmentReport", selecteddepartmentReport)
  // console.log("overall_loan_information",overall_loan_information)

  return (
    <div className='flex max-h-screen min-h-screen  '>

      <Modal open={modalQuestionaries} width={'80%'} footer={false} closable={false} >

        <div className='bg-white pt-5 relative'>

          <AiOutlineClose onClick={() => setmodalQuestionaries(!modalQuestionaries)} className='absolute -right-0 -top-0 cursor-pointer' />

          <div>
            <div className='flex border'>
              <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Total Number of In House Headcount</h6>
              <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.total_no_of_inhouse_headcount}</h6>
            </div>

            <div className='flex border border-t-0'>
              <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Total Number of external/contractors Headcount(if applicable)</h6>
              <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.total_no_of_external_contractor_if_applicable}</h6>
            </div>
          </div>

          <div className='mt-5'>
            <h6 className='font-[800] mb-2 text-[13px]'>Financial Health</h6>

            <div>
              <div className='flex border'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Avg total Gross Salaries paid in last quarter</h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.financial_health?.avg_gross_salary_paid_in_last_quarter}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Avg Monthly rent paid in last quarter</h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.financial_health?.avg_monthly_rent_paid_in_last_quarter}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Avg  Operational cost (Consumables, Cabs etc) in Quarter</h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.financial_health?.avg_operation_cost}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Avg cost of vendor/services (payroll, legal, CA etc) in last quarter</h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.financial_health?.avg_cost_for_vendor_service_quarter}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Avg Employee engagement expenses (team lunch etc) in last quarter</h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.financial_health?.avg_employee_engagement_expenses_quarter}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Avg Fuel/Food or any other allowances paid in last quarters</h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.financial_health?.avg_fue_food_or_any_allowence_paid_quarter}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Total Incentives paid in last quarter</h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.financial_health?.total_incentive_paid_in_last_quarter}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Total statutory payment paid last quarter(TDS, GST etc)</h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.financial_health?.total_statutory_payment_paid_in_last_quarter}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Total Quarterly Target set for entire BU </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.financial_health?.total_quarterly_target_set_for_entire_bu}</h6>
              </div>

              <div className='flex border mt-5'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Total Invoices raised in Last Few Quarter individully</h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.financial_health?.total_invoice_raised}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Profit Variance (Total revenue generated - total expense paid)</h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.financial_health?.profit_variance}</h6>
              </div>


            </div>

          </div>

          <div className='mt-5'>
            <h6 className='font-[800] mb-2 text-[13px]'>Risk Management and Compliance:</h6>

            <div>
              <div className='flex border'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Any Risks identified </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.risk_managent_compliance?.any_risk_identified}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Any Mitigation plans in place</h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.risk_managent_compliance?.any_mitigation_plans_in_place}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Compliance:Is it up to date </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.risk_managent_compliance?.compliance_is_upto_date}</h6>
              </div>
            </div>
          </div>

          <div className='mt-5'>
            <h6 className='font-[800] mb-2 text-[13px]'>Employee Engagment and Development</h6>

            <div>
              <div className='flex border'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Total Number of people hired in last quarter </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.employee_engagment_and_development?.total_no_of_people_hired_in_last_quarter}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Total number of people exited in Quarter</h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.employee_engagment_and_development?.total_no_of_people_exited_in_last_quarter}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>List down primary reasons for exits  </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.employee_engagment_and_development?.list_down_primary_reason_for_exits}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>What plans are in place to reduce attrition  </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.employee_engagment_and_development?.which_plans_are_in_place_to_reduce_attrition}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Are there any employee development and engagement programs?   </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.employee_engagment_and_development?.are_there_any_employee_development_and_engagement_programs}</h6>
              </div>
            </div>
          </div>

          <div className='mt-5'>
            <h6 className='font-[800] mb-2 text-[13px]'>Customer and Stakeholder Satisfaction:</h6>

            <div>
              <div className='flex border'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>What is your customer satisfaction rate  </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.customer_and_stakeholder_satisfaction?.what_is_your_customer_satisfaction_rate}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Any notable customer feedback received recently? </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.customer_and_stakeholder_satisfaction?.any_notable_customer_feedback_recieved_recently}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>How would you rate your stakeholder relationship health?  </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.customer_and_stakeholder_satisfaction?.how_would_you_rate_your_stakeholder_relationship_health}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Any customers lost due to lack of engagment   </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.customer_and_stakeholder_satisfaction?.any_customer_lost_due_to_lack_of_engagement}</h6>
              </div>

            </div>
          </div>

          <div className='mt-5'>
            <h6 className='font-[800] mb-2 text-[13px]'>Planning and outlook </h6>

            <div>
              <div className='flex border'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>How is the market outlook for Upcoming Quarter for you?  </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.planning_and_outlook?.how_is_market_outlook_for_quarter}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Any expansion plan into new markets </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.planning_and_outlook?.any_expansion_plan_into_new_market}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Any additional headcount hiring planned: list them  </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.planning_and_outlook?.any_additional_headcount_hiring_planned}</h6>
              </div>

            </div>
          </div>

          <div className='mt-5'>
            <h6 className='font-[800] mb-2 text-[13px]'>Innovation and Growth: </h6>

            <div>
              <div className='flex border'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Has your BU adopted technology? mention an example  </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.innovation_and_growth?.has_your_bu_adapted_technology}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Any new systems or process implemented </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.innovation_and_growth?.any_new_system_or_process_implemented}</h6>
              </div>

              <div className='flex border border-t-0'>
                <h6 className='font-[600] p-1 border-r w-[60%] text-[12px] bg-slate-100'>Any new systems or process planned for next quarter </h6>
                <h6 className='text-[12px] p-1'>{selecteddepartmentReport?.innovation_and_growth?.any_new_system_or_process_implemented}</h6>
              </div>

            </div>
          </div>
        </div>


      </Modal>


      <Modal open={modal} footer={false} width={'70vw'} closable={false} className="absolute top-0 left-[15vw]">
        <div className='relative max-h-[50vh] overflow-y-scroll no-scrollbar'>
          <h6 className='text-[14px] font-[800]'>{selectedData?.name} ({selectedData?.data?.length})</h6>
          <AiOutlineClose onClick={() => setmodal(!modal)} className='absolute right-[10px] top-[0px]' />

          {selectedData?.type === 'Overall Event' &&
            <div>
              <div className='border  h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                <div className='flex bg-slate-100'>
                  <h6 className='text-[11px] font-[600]  p-[4px] border-r w-[30%]'>Department</h6>
                  <h6 className='text-[12px] font-[600]  p-[4px] border-r w-[30%]'>Event Name</h6>
                  <h6 className='text-[12px]  font-[600] p-[4px]  w-[40%]'>Event Date</h6>
                </div>

                {selectedData?.data?.map((e) => (
                  <div className='flex border-t'>
                    <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.department?.department_name}</h6>
                    <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.event_name}</h6>
                    <h6 className='text-[11px]  p-[4px]  w-[40%] font-[900]'>{moment(e?.launched_date)?.format('LLL')}</h6>
                  </div>))}
              </div>

            </div>}


          {selectedData?.type === 'Media Work' &&
            <div className='border h-auto  h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
              <div className='flex bg-slate-100'>
                <h6 className='text-[11px]  p-[4px] border-r w-[25%]'>Department</h6>
                <h6 className='text-[11px]  p-[4px] border-r w-[25%]'>Name</h6>
                <h6 className='text-[11px]  p-[4px] border-r w-[25%]'>Work </h6>
                <h6 className='text-[11px]  p-[4px] w-[25%]'>Expenses</h6>
              </div>

              {selectedData?.data?.map((e) => (
                <div className='flex border-t'>
                  <h6 className='text-[10px]  p-[4px] border-r w-[25%]'>{e?.department?.department_name}</h6>
                  <h6 className='text-[11px] font-[800]  p-[4px] border-r w-[25%]'>{e?.name}</h6>
                  <h6 className='text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{e?.work}</h6>
                  <h6 className='text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.expense)}</h6>
                </div>))}
            </div>}


          {selectedData?.type === 'Invoice Info' &&
            <div>
              <div className='border  h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                <div className='flex bg-slate-100'>
                  <h6 className='text-[11px] font-[600]  p-[4px] border-r w-[20%]'>Client</h6>
                  <h6 className='text-[10px] font-[600]  p-[4px] border-r w-[30%]'>Requirment</h6>
                  <h6 className='text-[10px]  font-[600] p-[4px] border-r w-[16%]'>Invoice Amount</h6>
                  <h6 className='text-[10px]  font-[600] p-[4px] border-r w-[16%]'>Collected</h6>
                  <h6 className='text-[10px]  font-[600] p-[4px]  w-[16%]'>Outstanding</h6>
                </div>

                {selectedData?.data?.map((e) => (
                  <div className='flex border-t'>
                    <h6 className='text-[11px]  p-[4px] border-r w-[20%]'>{e?.department?.department_name} / <span className='font-[800]'> {e?.client_name}</span></h6>
                    <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.requirment}</h6>
                    <h6 className='text-[11px]  p-[4px] border-r w-[16%] font-[900]'>{Priceconstants(e?.invoice_amt)}</h6>
                    <h6 className='text-[11px]  p-[4px] border-r w-[16%] font-[900]'>{Priceconstants(e?.collected)}</h6>
                    <h6 className='text-[11px]  p-[4px]  w-[16%] font-[900]'>{Priceconstants(e?.outstanding)}</h6>
                  </div>))}
              </div>

            </div>}

          {selectedData?.type === 'Overall Lead' &&
            <div>
              <div className='border  h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                <div className='flex bg-slate-100'>
                  <h6 className='text-[11px] font-[600]  p-[4px] border-r w-[30%]'>Department</h6>
                  <h6 className='text-[12px] font-[600]  p-[4px] border-r w-[30%]'>Company/Client Name</h6>
                  <h6 className='text-[12px]  font-[600] p-[4px]  w-[40%]'>Requirment</h6>
                </div>

                {selectedData?.data?.map((e) => (
                  <div className='flex border-t'>
                    <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.department?.department_name}</h6>
                    <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.client_name}</h6>
                    <h6 className='text-[11px]  p-[4px]  w-[40%] font-[900]'>{e?.requirement}</h6>
                  </div>))}
              </div>

            </div>}

          {/* {console.log("selectedData?.data",selectedData?.data)} */}

          {selectedData?.type === 'Overall Expense' &&
            <div>
              <div className='border  h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                <div className='flex bg-slate-100'>
                  <h6 className='text-[11px] font-[600]  p-[4px] border-r w-[30%]'>Department</h6>
                  <h6 className='text-[12px] font-[600]  p-[4px] border-r w-[30%]'>Expense Type</h6>
                  <h6 className='text-[12px] font-[600]  p-[4px] border-r w-[30%]'>User</h6>
                  <h6 className='text-[12px]  font-[600] p-[4px]  w-[40%]'>Amount</h6>
                </div>

                {selectedData?.data?.map((e) => (
                  <div className='flex border-t'>
                    <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.department?.department_name}</h6>
                    <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.name}</h6>
                    <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.user}</h6>
                    <h6 className='text-[11px]  p-[4px]  w-[40%] font-[900]'>{Priceconstants(e?.amount)}</h6>
                  </div>))}
              </div>

            </div>}

          {selectedData?.type === 'Overall Receipt Payment' &&
            <div>
              <div className='border  h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                <div className='flex bg-slate-100'>
                  <h6 className='text-[11px] font-[600]  p-[4px] border-r w-[30%]'>Date</h6>
                  <h6 className='text-[12px] font-[600]  p-[4px] border-r w-[30%]'>Particulars</h6>
                  <h6 className='text-[12px] font-[600]  p-[4px] border-r w-[30%]'>Receipt</h6>
                  <h6 className='text-[12px] font-[600]  p-[4px] border-r w-[30%]'>Payment</h6>
                  <h6 className='text-[12px] font-[600]  p-[4px] border-r w-[30%]'>Closing Available Balance</h6>
                  <h6 className='text-[12px]  font-[600] p-[4px]  w-[40%]'>Remarks</h6>
                </div>

                {selectedData?.data?.map((e) => (
                  <div className='flex border-t'>
                    <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{moment(e?.date)?.format('LL')}</h6>
                    <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.particulars}</h6>
                    <h6 className='text-[11px]  p-[4px] border-r w-[30%] font-[900]'>{Priceconstants(e?.receipt)}</h6>
                    <h6 className='text-[11px]  p-[4px] border-r w-[30%] font-[900]'>{Priceconstants(e?.payment)}</h6>
                    <h6 className='text-[11px]  p-[4px] border-r w-[30%] font-[900]'>{Priceconstants(e?.closing_available_balance)}</h6>
                    <h6 className='text-[11px]  p-[4px]  w-[40%]'>{e?.remarks}</h6>
                  </div>))}
              </div>

            </div>}

          {selectedData?.type === 'EMI Information' &&
            <div>
              <div className='border  h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                <div className='flex bg-slate-100'>
                  <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>Department</h6>
                  <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>EMI Payable</h6>
                  <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>Total Amount</h6>
                  <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>Amount Paid</h6>
                  <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>Current Month Paid</h6>
                  <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Remarks</h6>
                </div>

                {selectedData?.data?.map((e) => (
                  <div className='flex border-t'>
                    <h6 className='truncate text-[10px]  p-[4px] border-r w-[25%]'>{e?.department?.department_name}</h6>
                    <h6 className='truncate text-[11px] font-[800]  p-[4px] border-r w-[25%]'>{e?.emi_payable}</h6>
                    <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.total_amount)}</h6>
                    <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.amount_paid)}</h6>
                    <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.current_month_amount_paid)}</h6>
                    <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{e?.remarks}</h6>
                  </div>))}
              </div>

            </div>}

          {selectedData?.type === 'Loan Information' &&
            <div>
              <div className='border  h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                <div className='flex bg-slate-100'>
                  <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Department Name</h6>
                  <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Bank/Property Name</h6>
                  <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Loan Amount</h6>
                  <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Amount Paid</h6>
                  <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Amount Pending</h6>
                  <h6 className='text-[11px] truncate p-[4px] w-[25%]'>Remarks</h6>
                </div>

                {selectedData?.data?.map((e) => (
                  <div className='flex border-t'>
                    <h6 className='truncate text-[10px]  p-[4px] border-r w-[25%]'>{e?.department?.department_name}</h6>
                    <h6 className='truncate text-[11px] font-[800]  p-[4px] border-r w-[25%]'>{e?.bank_name_or_party_name}</h6>
                    <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.loan_amount)}</h6>
                    <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.amount_paid)}</h6>
                    <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.amount_pending)}</h6>
                    <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{e?.remarks}</h6>
                  </div>))}
              </div>

            </div>}


          {/* {overall_emi_information?.length > 0 &&
                    <div className='border h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'> 
                    <div className='flex bg-slate-100'>
                      <h6 className='text-[11px]  p-[4px] border-r w-[25%]'>Department</h6>
                      <h6 className='text-[11px]  p-[4px] border-r w-[25%]'>EMI Payable</h6>
                      <h6 className='text-[11px]  p-[4px] border-r w-[25%]'>Total Amount</h6>
                      <h6 className='text-[11px]  p-[4px] border-r w-[25%]'>Amount Paid</h6>
                      <h6 className='text-[11px]  p-[4px] w-[25%]'>Current Month Paid</h6>
                    </div> 

                    {overall_emi_information?.map((e)=>(
                    <div className='flex border-t'>
                      <h6 className='text-[10px]  p-[4px] border-r w-[25%]'>{e?.department?.department_name}</h6>
                      <h6 className='text-[11px] font-[800]  p-[4px] border-r w-[25%]'>{e?.emi_payable}</h6>
                      <h6 className='text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.total_amount)}</h6>
                      <h6 className='text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.amount_paid)}</h6>
                      <h6 className='text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.current_month_amount_paid)}</h6>
                    </div> ))}
                    </div>} */}
        </div>
      </Modal>

      {pathname === '/dashboard/profit_loss_admin' && <DashboardMenu />}
      {/* {Priceconstants} */}
      <div className={`mx-5 ${pathname === '/dashboard/profit_loss_admin' && 'w-[85%]'} mb-10`}>
        <div className='flex items-center justify-between  pb-2 pt-2'>
          <h6 className='font-[700] text-[14px] '>Profit/Loss Report  ({data?.pagination?.total})</h6>
          <div className='flex items-center'>
            <div className='flex items-center text-[12px] mr-2 '>

              <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>
            </div>


            <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[5.5px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { setsearch({ ...search, from_date: v, from_date1: v1 }) }} />

            <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[5.5px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { setsearch({ ...search, to_date: v, to_date1: v1 }) }} />

            {step === 2 &&
              <Select value={search?.department} onChange={(e) => handleChangedepartment(e)} bordered={false} options={department} placeholder="select department" className='w-[150px] mr-2 border border-slate-300 rounded-[5px] ' />}


            <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={() => applyfilterfunction(departmentSet.departmentArr, departmentSet.shilpaFoundation, departmentSet.galleryTeam)} />


          </div>
        </div>

        <div className='flex bg-slate-100 items-center justify-center py-[4px]'>
          <h6 onClick={() => setstepfunc(1)} className={`-ml-4 cursor-pointer py-[4px] rounded ${step == 1 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px] mr-2 text-center text-[12px] font-[600]`}>Over All</h6>
          <h6 onClick={() => setstepfunc(2)} className={`cursor-pointer py-[4px] rounded ${step == 2 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px]  text-center text-[12px] font-[600]`}>Team</h6>
        </div>

        {step === 1 &&
          <div className='overflow-y-scroll no-scrollbar no-scrollbar'>
            <div className='grid border border-slate-100 mt-2 grid-cols-6'>

              <div className='border-r border-slate-100 px-2 py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />My Sales</h6>
                <h6 className='text-[14px] mt-1 font-[800]'>{rupeeIndian?.format(overallReport?.my_turnover)?.split('.')[0]}</h6>
              </div>

              <div className='border-r border-slate-100 px-2 py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />My Receipt</h6>
                <h6 className='text-[14px] mt-1 font-[800]'>{rupeeIndian?.format(JSON.stringify(overallReport?.net_revenue))?.split('.')[0]}</h6>
              </div>

              <div className='border-r border-slate-100 px-2 py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> My Expenses</h6>
                <h6 className='text-[14px] mt-1 font-[800]'>{rupeeIndian?.format(JSON.stringify(overallReport?.my_expenses))?.split('.')[0]}</h6>
              </div>

              <div className='border-r border-slate-100 px-2 py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Account Balance</h6>
                <h6 className='text-[14px] mt-1 font-[800]'>{rupeeIndian?.format(JSON.stringify(overallReport?.net_profit))?.split('.')[0]}</h6>
              </div>

              <div className='px-2 py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdMoney size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Net Payables</h6>
                <h6 className='text-[14px] mt-1 font-[800]'>{rupeeIndian?.format(JSON.stringify(overallReport?.net_payables))?.split('.')[0]}</h6>
              </div>

              <div className='px-2 py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Net Outstanding</h6>
                <h6 className='text-[14px] mt-1 font-[800]'>{rupeeIndian?.format(JSON.stringify(overallReport?.net_outstanding))?.split('.')[0]}</h6>
              </div>

            </div>

            <div className='flex '>



              <div className='border w-[70%] flex-0.7 h-[340px]  mt-2 border-slate-100 px-3 py-2 relative'>
                <div onClick={() => { substep === 1 ? setsubstep(2) : setsubstep(1) }} className='border w-[35px] rounded-[4px] border-slate-100 h-[18px] absolute right-[30px]'>
                  <div className='flex'>
                    {substep === 1 && <h6 className='w-[16px] rounded-[4px] h-[12px] my-[2px] mx-[1px] bg-slate-700'></h6>}
                    {substep === 2 && <h6 className='w-[16px] ml-[17px] rounded-[4px] h-[12px] my-[2px] mx-[1px] bg-slate-700'></h6>}
                  </div>
                </div>
                <AiOutlineInfoCircle size={16} className='absolute right-[10px] top-[10px] cursor-pointer' />
                <h1 className='text-[13px] font-[800]'>{substep === 1 ? `Fidelitus Revenue Report` : `Revenue Report From All Vertical`}</h1>
                <div className='pl-2'>
                  {substep === 1 ?
                    <ReactApexChart options={chartOptions3} size={'10'} series={chartOptions3.series} type="bar" width={'100%'} height={300} />
                    :
                    <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1.series} type="bar" width={'100%'} height={300} />
                  }
                </div>


              </div>

              <div className='w-[30%] relative flex-0.3 ml-[10px]  mt-2  '>
                <div className='h-[200px border border-slate-100  px-3 py-2 relative'>
                  {/* <div  className='absolute right-[10px] top-[4px]'>
                <TbTimelineEventText size={12} />
                </div> */}
                  <h1 className='text-[13px] font-[800] '>Shilpa Foundation</h1>
                  <div className='flex items-start'>
                    <div className='-ml-2'>
                      <ReactApexChart options={chartOptions2} dataLabels={{ enabled: false }} size={'10'} series={chartOptions2.series} type="donut" width={155} height={155} />
                    </div>
                    <div className='ml-[10px] mt-4'>
                      <h6 className='text-[11px]'>Fund Raised : <span className='font-[800] text-[12px]'>{Priceconstants(shilpaFoundation?.fund_raised)}</span> </h6>
                      <h6 className='text-[11px]'>Fund Utilized : <span className='font-[800] text-[12px]'>{Priceconstants(shilpaFoundation?.fund_utilized)}</span> </h6>
                      <h6 className='text-[11px]'>Fund Remaining : <span className='font-[800] text-[12px]'>{Priceconstants(shilpaFoundation?.fund_remaining)}</span> </h6>
                    </div>
                  </div>
                  <div className='flex flex-wrap -mt-0 text-white'>
                    <div onClick={() => { setmodal(true); setselectedData({ data: shilpaFoundation?.events, type: 'Overall Event', name: 'Shilpa Foundation Event' }) }} className='flex items-center cursor-pointer bg-slate-500 p-[5px] mr-1 mb-1'>
                      <TbTimelineEventText />
                      <h6 className='text-[10px] pl-[2px] font-[600]'>Events [{shilpaFoundation?.events?.length}]</h6>
                    </div>

                    <div onClick={() => { setmodal(true); setselectedData({ data: shilpaFoundation?.leads, type: 'Overall Lead', name: 'Shilpa Foundation Leads' }) }} className='flex items-center cursor-pointer bg-slate-500 p-[5px] mr-1 mb-1'>
                      <TbTimelineEventText />
                      <h6 className='text-[10px] pl-[2px] font-[600]'>Leads [{shilpaFoundation?.leads?.length}]</h6>
                    </div>

                    <div onClick={() => { setmodal(true); setselectedData({ data: shilpaFoundation?.expense, type: 'Overall Expense', name: 'Shilpa Foundation Expenses' }) }} className='flex items-center cursor-pointer bg-slate-500 p-[5px] mr-1 mb-1'>
                      <TbTimelineEventText />
                      <h6 className='text-[10px] pl-[2px] font-[600]'>Expense [{shilpaFoundation?.expense?.length}]</h6>
                    </div>

                    <div onClick={() => { setmodal(true); setselectedData({ data: shilpaFoundation?.shilpa_foundation_receipt_payment, type: 'Overall Receipt Payment', name: 'Shilpa Foundation Receipt Payment' }) }} className='flex items-center cursor-pointer bg-slate-500 p-[5px] mr-1 mb-1'>
                      <TbTimelineEventText />
                      <h6 className='text-[10px] pl-[2px] font-[600]'>Receipt / Payment [{shilpaFoundation?.shilpa_foundation_receipt_payment?.length}]</h6>
                    </div>


                  </div>

                </div>


                <div className='border border-slate-100 mt-[10px] px-3 py-2 relative'>
                  <h6 className='text-[12px] font-[800]'>Leads Generated ({overallReport.lead_count})</h6>
                  <div className='flex items-center mt-2 justify-between'>
                    <div className='w-[100%] p-2  -mt-3 -ml-2'>
                      <div className='flex flex-wrap'>
                        {departmentReport?.map((d) => (
                          <div className='text-[11px] mr-[2px] flex mt-[2px] min-w-[70px] p-[5px] border'>
                            <h6 className='text-[10px] text-center'>{d?.department_name} :</h6>
                            <h6 className='text-[10px] text-center font-[900] ml-[4px]'> {d?.lead_data?.length}</h6>
                          </div>))}
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div className='mt-2  flex min-h-[170px] max-h-[170px] overflow-hidden'>
              {/* <div className=' w-[20%] pt-[0px]  p-[10px] border-slate-100 border'> 
            <h6 className='text-[13px] font-[700] mt-2'>Gallery Update</h6>

            <div className='flex mt-[5px] p-[5px] border border-slate-100 '>
              <LuPaintbrush2 size={20} className='bg-green-100 p-[4px]' />
              <div className='ml-[10px]'> 
                <h6 className='text-[12px] font-[400]'>New Painting - <span className='font-[800]'>({galleryData?.new_painting})</span></h6>
              </div> 
            </div> 

              <div className='flex mt-[5px] p-[5px] border border-slate-100 '>
              <LuPaintbrush2 size={20} className='bg-red-100 p-[4px]' />
              <div className='ml-[10px]'> 
                <h6 className='text-[12px] font-[400]'>Sold Painting - <span className='font-[800]'>({galleryData?.sold_painting})</span></h6>
              </div> 
            </div>   

            <div className='flex  mt-[5px] relative p-[5px] border border-slate-100 '>
              <TbTimelineEventText size={20} className='bg-sky-100 p-[4px]' />
              <div className='ml-[10px]'> 
                <h6 className='text-[12px] font-[400]'>New Events -<span className='font-[800]'> ({galleryData?.events?.length})</span></h6>
              </div> 
              <TbTimelineEventText onClick={()=>{setmodal(true);setselectedData({data:galleryData?.events,type:'Overall Events',name:'Gallery Events Report'})}} size={15} className='bg-slate-700 text-white absolute right-[0px] top-[0px] p-[4px]' />

            </div>  
            </div> */}

              <div className='relative w-[40%]   relative pt-[0px]  p-[10px] border-slate-100 border'>
                <h6 className='text-[13px] font-[700] mt-2'>Outstanding Info ({overall_invoice?.length})</h6>
                <MdOutlineExpand onClick={() => { setmodal(true); setselectedData({ data: overall_invoice, type: 'Invoice Info', name: 'Outstanding Report' }) }} className='absolute right-[10px] top-[10px]' />

                {overall_invoice?.length > 0 &&
                  <div className='border h-auto  h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                    <div className='flex bg-slate-100'>
                      {/* <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>Department</h6> */}
                      <h6 className='text-[11px]  p-[4px] border-r w-[40%]'>Client Name</h6>
                      <h6 className='text-[11px]  p-[4px] border-r w-[20%]'>Invoice Amt</h6>
                      <h6 className='text-[11px]  p-[4px] border-r w-[20%]'>Collected</h6>
                      <h6 className='text-[11px]  p-[4px]  w-[20%]'>Outstanding</h6>
                    </div>

                    {overall_invoice?.map((e) => (
                      <div className='flex border-t'>
                        <h6 className='text-[10px]  p-[4px] border-r w-[40%]'>{e?.department?.department_name}/ {e?.client_name}</h6>
                        <h6 className='text-[11px] font-[800]  p-[4px] border-r w-[20%]'>{Priceconstants(parseInt(e?.invoice_amt))}</h6>
                        <h6 className='text-[11px] font-[800] p-[4px] border-r w-[20%] font-[900]'>{Priceconstants(parseInt(e?.collected))}</h6>
                        <h6 className='text-[11px] font-[800] p-[4px]  w-[20%] font-[900]'>{Priceconstants(parseInt(e?.outstanding))}</h6>
                      </div>))}
                  </div>}

              </div>

              <div className=' ml-[1%] relative w-[30%] relative pt-[0px]  p-[10px] border-slate-100 border'>
                <h6 className='text-[13px] font-[700] mt-2'>New Clients ({overall_leads?.length})</h6>
                <MdOutlineExpand onClick={() => { setmodal(true); setselectedData({ data: overall_leads, type: 'Overall Lead', name: 'Overall Leads Report' }) }} className='absolute right-[10px] top-[10px]' />

                {overall_leads?.length > 0 &&
                  <div className='border h-[75%] overflow-y-scroll no-scrollbar mt-[10px]'>
                    <div className='flex bg-slate-100'>
                      <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>Department</h6>
                      <h6 className='text-[12px]  p-[4px] border-r w-[30%]'>Company/Client Name</h6>
                      <h6 className='text-[12px]  p-[4px]  w-[40%]'>Requirment</h6>
                    </div>

                    {overall_leads?.map((e) => (
                      <div className='flex border-b'>
                        <h6 className='text-[10px]  p-[4px] border-r w-[30%]'>{e?.department?.department_name}</h6>
                        <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.client_name}</h6>
                        <h6 className='text-[11px]  p-[4px]  w-[40%] font-[900]'>{e?.requirement}</h6>
                      </div>))}
                  </div>}

              </div>

              <div className=' ml-[1%] w-[30%] h-auto pt-[0px] relative p-[10px] border-slate-100 border'>
                <h6 className='text-[13px] font-[700] mt-2'>Expenses Bifurcation ({overall_expenses?.length})</h6>
                <MdOutlineExpand onClick={() => { setmodal(true); setselectedData({ data: overall_expenses, type: 'Overall Expense', name: 'Overall Expenses Report' }) }} className='absolute right-[10px] top-[10px]' />

                {overall_expenses?.length > 0 &&
                  <div className='border h-[75%]  overflow-y-scroll no-scrollbar mt-[10px]'>
                    <div className='flex bg-slate-100'>
                      <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>Department</h6>
                      <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>Expense Type</h6>
                      <h6 className='text-[11px]  p-[4px]  w-[40%]'>Expense Amount</h6>
                    </div>

                    {overall_expenses?.map((e) => (
                      <div className='flex border-t'>
                        <h6 className='text-[11px]  p-[4px] border-r font-[500] w-[30%]'>{e?.department?.department_name}</h6>
                        <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.name}</h6>
                        <h6 className='text-[11px] font-[800] p-[4px]  w-[40%] font-[500]'>{Priceconstants(e?.amount)}</h6>
                      </div>))}
                  </div>}



              </div>
            </div>


            <div className='flex mt-2'>
              <div className='w-[50%]  pt-[0px]  p-[10px] border-slate-100 border'>
                <h6 className='text-[13px] font-[700] mt-2'>Gallery Update</h6>

                <div className='flex'>
                  <div className='flex mt-[5px] mr-[5px] p-[5px] border border-slate-100 '>
                    <LuPaintbrush2 size={20} className='bg-green-100 p-[4px]' />
                    <div className='ml-[10px]'>
                      <h6 className='text-[12px] font-[400]'>Total Painting - <span className='font-[800]'>({galleryData?.total_painting})</span></h6>
                    </div>
                  </div>

                  <div className='flex mt-[5px] mr-[5px] p-[5px] border border-slate-100 '>
                    <LuPaintbrush2 size={20} className='bg-green-100 p-[4px]' />
                    <div className='ml-[10px]'>
                      <h6 className='text-[12px] font-[400]'>New Painting - <span className='font-[800]'>({galleryData?.new_painting})</span></h6>
                    </div>
                  </div>

                  <div className='flex mt-[5px] mr-[5px] p-[5px] border border-slate-100 '>
                    <LuPaintbrush2 size={20} className='bg-red-100 p-[4px]' />
                    <div className='ml-[10px]'>
                      <h6 className='text-[12px] font-[400]'>Sold Painting - <span className='font-[800]'>({galleryData?.sold_painting})</span></h6>
                    </div>
                  </div>

                  <div className='flex mt-[5px] mr-[5px] relative p-[5px] border border-slate-100 '>
                    <TbTimelineEventText size={20} className='bg-sky-10 0 p-[4px]' />
                    <div className='ml-[10px]'>
                      <h6 className='text-[12px] font-[400]'>New Events -<span className='font-[800]'> ({galleryData?.events?.length})</span></h6>
                    </div>
                    <TbTimelineEventText onClick={() => { setmodal(true); setselectedData({ data: galleryData?.events, type: 'Overall Events', name: 'Gallery Events Report' }) }} size={15} className='bg-slate-700 text-white absolute right-[0px] -top-[10px] p-[4px]' />

                  </div>
                </div>
              </div>

              <div className='w-[60%] ml-[1%] relative pt-[0px]  p-[10px] border-slate-100 border'>
                <h6 className='text-[13px] font-[700] mt-2'>Department Work</h6>
                <MdOutlineExpand onClick={() => { setmodal(true); setselectedData({ data: overall_department_work, type: 'Media Work', name: 'Department Work Report' }) }} className='absolute right-[10px] top-[10px]' />

                {overall_department_work?.length > 0 &&
                  <div className='border h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                    <div className='flex bg-slate-100'>
                      <h6 className='text-[11px]  p-[4px] border-r w-[25%]'>Department</h6>
                      <h6 className='text-[11px]  p-[4px] border-r w-[25%]'>Name</h6>
                      <h6 className='text-[11px]  p-[4px] border-r w-[25%]'>Work </h6>
                      <h6 className='text-[11px]  p-[4px] w-[25%]'>Expenses</h6>
                    </div>

                    {overall_department_work?.map((e) => (
                      <div className='flex border-t'>
                        <h6 className='text-[10px]  p-[4px] border-r w-[25%]'>{e?.department?.department_name}</h6>
                        <h6 className='text-[11px] font-[800]  p-[4px] border-r w-[25%]'>{e?.name}</h6>
                        <h6 className='text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{e?.work}</h6>
                        <h6 className='text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.expense)}</h6>
                      </div>))}
                  </div>}
              </div>

            </div>

            <div className='flex mt-2'>
              <div className='w-[50%] relative  pt-[0px]  p-[10px] border-slate-100 border'>
                <h6 className='text-[13px] font-[700] mt-2'>Loan Information</h6>
                <MdOutlineExpand onClick={() => { setmodal(true); setselectedData({ data: overall_loan_information, type: 'Loan Information', name: 'Loan Information' }) }} className='absolute right-[10px] top-[10px]' />

                {overall_loan_information?.length > 0 &&
                  <div className='border h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                    <div className='flex bg-slate-100'>
                      <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Department Name</h6>
                      <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Bank/Property Name</h6>
                      <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Loan Amount</h6>
                      <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Amount Paid</h6>
                      <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Amount Pending</h6>
                      <h6 className='text-[11px] truncate p-[4px] w-[25%]'>Remarks</h6>
                    </div>

                    {overall_loan_information?.map((e) => (
                      <div className='flex border-t'>
                        <h6 className='truncate text-[10px]  p-[4px] border-r w-[25%]'>{e?.department?.department_name}</h6>
                        <h6 className='truncate text-[11px] font-[800]  p-[4px] border-r w-[25%]'>{e?.bank_name_or_party_name}</h6>
                        <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.loan_amount)}</h6>
                        <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.amount_paid)}</h6>
                        <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.amount_pending)}</h6>
                        <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{e?.remarks}</h6>
                      </div>))}
                  </div>}
              </div>

              <div className='w-[60%] ml-[1%] relative pt-[0px]  p-[10px] border-slate-100 border'>
                <h6 className='text-[13px] font-[700] mt-2'>EMI Information</h6>
                <MdOutlineExpand onClick={() => { setmodal(true); setselectedData({ data: overall_emi_information, type: 'EMI Information', name: 'EMI Information' }) }} className='absolute right-[10px] top-[10px]' />

                {overall_emi_information?.length > 0 &&
                  <div className='border h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                    <div className='flex bg-slate-100'>
                      <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Department</h6>
                      <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>EMI Payable</h6>
                      <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>Total Amount</h6>
                      <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>Amount Paid</h6>
                      <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>Current Month Paid</h6>
                      <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>Remarks</h6>
                    </div>

                    {overall_emi_information?.map((e) => (
                      <div className='flex border-t'>
                        <h6 className='text-[10px] truncate p-[4px] border-r w-[25%]'>{e?.department?.department_name}</h6>
                        <h6 className='text-[11px] truncate font-[800]  p-[4px] border-r w-[25%]'>{e?.emi_payable}</h6>
                        <h6 className='text-[11px] truncate font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.total_amount)}</h6>
                        <h6 className='text-[11px] truncate font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.amount_paid)}</h6>
                        <h6 className='text-[11px] truncate font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.current_month_amount_paid)}</h6>
                        <h6 className='text-[11px] truncate font-[800] p-[4px] border-r w-[25%] font-[900]'>{e?.remarks}</h6>
                      </div>))}
                  </div>}
              </div>
            </div>


          </div>}

        {step === 2 &&
          <h6 onClick={() => setmodalQuestionaries(!modalQuestionaries)} className='cursor-pointer font-[700] text-[10px] bg-slate-600 px-2 py-1 cursor-pointer rounded w-max text-white absolute right-6 top-14'>View Questonaries</h6>}

        {step === 2 &&
          <div className='overflow-y-scroll relative no-scrollbar no-scrollbar'>
            <div className='grid border border-slate-100 mt-2 grid-cols-6'>

              <div className='border-r border-slate-100 px-2 py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />My Sales</h6>
                <h6 className='text-[14px] mt-1 font-[800]'>{rupeeIndian?.format(selecteddepartmentReport?.my_turnover)?.split('.')[0]}</h6>
              </div>

              <div className='border-r border-slate-100 px-2 py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Net Receipt</h6>
                <h6 className='text-[14px] mt-1 font-[800]'>{rupeeIndian?.format(JSON.stringify(selecteddepartmentReport?.net_revenue))?.split('.')[0]}</h6>
              </div>

              <div className='border-r border-slate-100 px-2 py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> My Expenses</h6>
                <h6 className='text-[14px] mt-1 font-[800]'>{rupeeIndian?.format(JSON.stringify(selecteddepartmentReport?.my_expenses))?.split('.')[0]}</h6>
              </div>

              <div className='border-r border-slate-100 px-2 py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Account Balance</h6>
                <h6 className='text-[14px] mt-1 font-[800]'>{rupeeIndian?.format(JSON.stringify(selecteddepartmentReport?.net_profit))?.split('.')[0]}</h6>
              </div>

              <div className='px-2 py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdMoney size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Net Payables</h6>
                <h6 className='text-[14px] mt-1 font-[800]'>{rupeeIndian?.format(JSON.stringify(selecteddepartmentReport?.net_payables))?.split('.')[0]}</h6>
              </div>

              <div className='px-2 py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Net Outstanding</h6>
                <h6 className='text-[14px] mt-1 font-[800]'>{rupeeIndian?.format(JSON.stringify(selecteddepartmentReport?.net_outstanding))?.split('.')[0]}</h6>
              </div>

            </div>


            <div className='flex '>



              <div className='border w-[70%] flex-0.6  mt-2 border-slate-100 px-3 py-2 relative'>
                <h1 className='text-[13px] font-[800]'>{`Revenue Report 11 ${selected_department?.label}`}</h1>
                <ReactApexChart options={chartOptions4} size={'10'} series={chartOptions4?.series} type="bar" width={'100%'} height={240} />
              </div>

              <div className='w-[30%] flex-0.3 ml-2  mt-2  relative'>
                <div className='border border-slate-100 px-3 py-2'>
                  <h1 className='text-[12px] font-[800]'>Analytic Ratio Based on Revenue</h1>
                  <h6 className='text-[9px] leading-tight uppercase font-[700] text-slate-400 mt-1'>You can view the ratio rate of the team. </h6>

                  <div className='flex justify-evenly min-w-[100%] mt-4 -ml-2 mb-2'>
                    <div className={`px-[10px] py-[5px] font-[800] text-[12px] bg-sky-200`}>
                      {selecteddepartmentReport?.my_turnover === 0 ? 0 : parseFloat(((selecteddepartmentReport?.my_turnover) / (selecteddepartmentReport?.my_turnover + selecteddepartmentReport?.net_revenue + selecteddepartmentReport?.my_expenses + selecteddepartmentReport?.net_profit + selecteddepartmentReport?.net_outstanding)) * 100).toFixed(2)}%
                    </div>
                    <div className={`px-[10px] py-[5px] font-[800] text-[12px] bg-green-200`}>
                      {selecteddepartmentReport?.net_revenue === 0 ? 0 : parseFloat(((selecteddepartmentReport?.net_revenue) / (selecteddepartmentReport?.my_turnover + selecteddepartmentReport?.net_revenue + selecteddepartmentReport?.my_expenses + selecteddepartmentReport?.net_profit + selecteddepartmentReport?.net_outstanding)) * 100).toFixed(2)}%
                    </div>
                    <div className={`px-[10px] py-[5px] font-[800] text-[12px] bg-orange-200`}>
                      {selecteddepartmentReport?.my_expenses === 0 ? 0 : parseFloat(((selecteddepartmentReport?.my_expenses) / (selecteddepartmentReport?.my_turnover + selecteddepartmentReport?.net_revenue + selecteddepartmentReport?.my_expenses + selecteddepartmentReport?.net_profit + selecteddepartmentReport?.net_outstanding)) * 100).toFixed(2)}%
                    </div>
                    <div className={`px-[10px] py-[5px] font-[800] text-[12px] bg-red-200`}>
                      {selecteddepartmentReport?.net_profit === 0 ? 0 : parseFloat(((selecteddepartmentReport?.net_profit) / (selecteddepartmentReport?.my_turnover + selecteddepartmentReport?.net_revenue + selecteddepartmentReport?.my_expenses + selecteddepartmentReport?.net_profit + selecteddepartmentReport?.net_outstanding)) * 100).toFixed(2)}%
                    </div>
                    <div className={`px-[10px] py-[5px] font-[800] text-[12px] bg-purple-200`}>
                      {selecteddepartmentReport?.net_outstanding === 0 ? 0 : parseFloat(((selecteddepartmentReport?.net_outstanding) / (selecteddepartmentReport?.my_turnover + selecteddepartmentReport?.net_revenue + selecteddepartmentReport?.my_expenses + selecteddepartmentReport?.net_profit + selecteddepartmentReport?.net_outstanding)) * 100).toFixed(2)}%
                    </div>

                  </div>
                </div>

                <div className='px-3 py-2'>

                  <h6 className='text-[10px] p-2  font-[700] bg-slate-50 mb-4'>From the above graph you can distinguish between the ratio of profit or loss based on department</h6>
                  <div className='flex flex-wrap'>
                    <div className='flex items-center pr-2 pb-2'>
                      <h6 className='bg-sky-200 w-[10px] h-[10px] rounded-[5px]'></h6>
                      <h6 className='text-[11px] pl-[4px]'>Sales</h6>
                    </div>

                    <div className='flex items-center pr-2 pb-2'>
                      <h6 className='bg-green-200 w-[10px] h-[10px] rounded-[5px]'></h6>
                      <h6 className='text-[11px] pl-[4px]'>Receipt</h6>
                    </div>

                    <div className='flex items-center pr-2 pb-2'>
                      <h6 className='bg-orange-200 w-[10px] h-[10px] rounded-[5px]'></h6>
                      <h6 className='text-[11px] pl-[4px]'>Expenses</h6>
                    </div>

                    <div className='flex items-center pr-2 pb-2'>
                      <h6 className='bg-red-200 w-[10px] h-[10px] rounded-[5px]'></h6>
                      <h6 className='text-[11px] pl-[4px]'>Net Profit</h6>
                    </div>

                    <div className='flex items-center pr-2 pb-2'>
                      <h6 className='bg-purple-200 w-[10px] h-[10px] rounded-[5px]'></h6>
                      <h6 className='text-[11px] pl-[4px]'>Outstanding</h6>
                    </div>

                  </div>

                  <div className='border'>
                    <div className='flex bg-slate-100 text-[11px]'>
                      <h6 className='w-[50%] p-[2px] border-r border-slate-300'>New Employee</h6>
                      <h6 className='w-[50%] p-[2px]'>Exit Employee</h6>
                    </div>
                    <div className='flex text-[11px]'>
                      <h6 className='w-[50%] font-[700] p-[2px] border-r border-slate-300'>{selecteddepartmentReport?.employee_entry}</h6>
                      <h6 className='w-[50%] font-[700] p-[2px]'>{selecteddepartmentReport?.employee_exit}</h6>
                    </div>

                  </div>
                </div>
              </div>



            </div>

            <div className='mt-2  flex  min-h-[170px] overflow-hidden max-h-[170px]'>

              {/* {selected_department?.label === 'Fidelitus Gallery' &&
            <div className=' w-[20%] pt-[0px]  p-[10px] border-slate-100 border'> 
            <h6 className='text-[13px] font-[700] mt-2'>Gallery Update</h6>

            <div className='flex mt-[5px] p-[5px] border border-slate-100 '>
              <LuPaintbrush2 size={20} className='bg-green-100 p-[4px]' />
              <div className='ml-[10px]'> 
                <h6 className='text-[12px] font-[400]'>New Painting - <span className='font-[800]'>({galleryData?.new_painting})</span></h6>
              </div> 
            </div> 

              <div className='flex mt-[5px] p-[5px] border border-slate-100 '>
              <LuPaintbrush2 size={20} className='bg-red-100 p-[4px]' />
              <div className='ml-[10px]'> 
                <h6 className='text-[12px] font-[400]'>Sold Painting - <span className='font-[800]'>({galleryData?.sold_painting})</span></h6>
              </div> 
            </div>   

            <div className='flex  mt-[5px] relative p-[5px] border border-slate-100 '>
              <TbTimelineEventText size={20} className='bg-sky-100 p-[4px]' />
              <div className='ml-[10px]'> 
                <h6 className='text-[12px] font-[400]'>New Events -<span className='font-[800]'> ({galleryData?.events?.length})</span></h6>
              </div> 
              <TbTimelineEventText onClick={()=>{setmodal(true);setselectedData({data:galleryData?.events,type:'Overall Events',name:'Gallery Events Report'})}} size={15} className='bg-slate-700 text-white absolute right-[0px] top-[0px] p-[4px]' />

            </div>  
            </div>
            } */}

              <div className={`relative w-[50%] relative pt-[0px]  p-[10px] border-slate-100 border`}>
                <h6 className='text-[13px] font-[700] mt-2'>Outstanding Info ({selecteddepartmentReport?.invoice_info?.length})</h6>
                <MdOutlineExpand onClick={() => { setmodal(true); setselectedData({ data: selecteddepartmentReport?.invoice_info, type: 'Invoice Info', name: 'Team Outstanding Report' }) }} className='absolute right-[10px] top-[10px]' />

                {selecteddepartmentReport?.invoice_info?.length > 0 &&
                  <div className='border h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                    <div className='flex bg-slate-100'>
                      <h6 className='text-[11px]  p-[4px] border-r w-[40%]'>Client/Requirment</h6>
                      <h6 className='text-[12px]  p-[4px] border-r w-[20%]'>Invoice Amount</h6>
                      <h6 className='text-[12px]  p-[4px] border-r w-[20%]'>Collected</h6>
                      <h6 className='text-[12px]  p-[4px] border-r w-[20%]'>Outstanding</h6>
                    </div>

                    {selecteddepartmentReport?.invoice_info?.map((e) => (
                      <div className='flex border-t'>
                        <h6 className='text-[11px]  p-[4px] border-r w-[40%]'>{e?.client_name}/{e?.requirment}</h6>
                        <h6 className='text-[11px]  p-[4px] border-r w-[20%]'>{Priceconstants(parseInt(e?.invoice_amt))}</h6>
                        <h6 className='text-[11px]  p-[4px] border-r w-[20%] font-[900]'>{Priceconstants(parseInt(e?.collected))}</h6>
                        <h6 className='text-[11px]  p-[4px] border-r w-[20%] font-[900]'>{Priceconstants(parseInt(e?.outstanding))}</h6>
                      </div>))}
                  </div>}

              </div>

              <div className={`relative w-[50%] ml-[1%] relative pt-[0px]  p-[10px] border-slate-100 border`}>
                <h6 className='text-[13px] font-[700] mt-2'>New Clients ({selecteddepartmentReport?.lead_data?.length})</h6>
                <MdOutlineExpand onClick={() => { setmodal(true); setselectedData({ data: selecteddepartmentReport?.lead_data, type: 'Overall Lead', name: 'Team Leads Report' }) }} className='absolute right-[10px] top-[10px]' />

                {selecteddepartmentReport?.lead_data?.length > 0 &&
                  <div className='border h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                    <div className='flex bg-slate-100'>
                      <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>Department</h6>
                      <h6 className='text-[12px]  p-[4px] border-r w-[30%]'>Company/Client Name</h6>
                      <h6 className='text-[12px]  p-[4px]  w-[40%]'>Requirment</h6>
                    </div>

                    {selecteddepartmentReport?.lead_data?.map((e) => (
                      <div className='flex border-t'>
                        <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.department?.department_name}</h6>
                        <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.client_name}</h6>
                        <h6 className='text-[11px]  p-[4px]  w-[40%] font-[900]'>{e?.requirement}</h6>
                      </div>))}
                  </div>}

              </div>

              <div className={`ml-[1%] w-[50%] pt-[0px] relative p-[10px] border-slate-100 border`}>
                <h6 className='text-[13px] font-[700] mt-2'>Expenses Bifurcation ({selecteddepartmentReport?.expenses_data?.length})</h6>
                <MdOutlineExpand onClick={() => { setmodal(true); setselectedData({ data: selecteddepartmentReport?.expenses_data, type: 'Overall Expense', name: 'Overall Expenses Report' }) }} className='absolute right-[10px] top-[10px]' />

                {selecteddepartmentReport?.expenses_data?.length > 0 &&
                  <div className='border h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                    <div className='flex bg-slate-100'>
                      <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>Department</h6>
                      <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>Expense Type</h6>
                      <h6 className='text-[11px]  p-[4px]  w-[40%]'>Expense Amount</h6>
                    </div>

                    {selecteddepartmentReport?.expenses_data?.map((e) => (
                      <div className='flex border-t'>
                        <h6 className='text-[11px]  p-[4px] border-r font-[500] w-[30%]'>{e?.department?.department_name}</h6>
                        <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.name}</h6>
                        <h6 className='text-[11px]  p-[4px]  w-[40%] font-[700]'>{Priceconstants(e?.amount)}</h6>
                      </div>))}
                  </div>}



              </div>
            </div>

            {selected_department?.label === "Shilpa Foundation" &&
              <div className='mt-2  flex  min-h-[170px] border border-slate-100 p-2 overflow-hidden max-h-[170px]'>

                <h6 className='text-[13px] font-[700] mt-2'>Receipt and Payment Report</h6>

                {selecteddepartmentReport?.shilpa_foundation_receipt_payment?.length > 0 &&
                  <div className='border h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                    <div className='flex bg-slate-100'>
                      <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>Department</h6>
                      <h6 className='text-[12px]  p-[4px] border-r w-[30%]'>Company/Client Name</h6>
                      <h6 className='text-[12px]  p-[4px]  w-[40%]'>Requirment</h6>
                    </div>

                    {selecteddepartmentReport?.shilpa_foundation_receipt_payment?.map((e) => (
                      <div className='flex border-t'>
                        <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.department?.department_name}</h6>
                        <h6 className='text-[11px]  p-[4px] border-r w-[30%]'>{e?.client_name}</h6>
                        <h6 className='text-[11px]  p-[4px]  w-[40%] font-[900]'>{e?.requirement}</h6>
                      </div>))}
                  </div>}

              </div>}

            <div className='flex mt-2'>
              {selected_department?.label === "Fidelitus Gallery" &&
                <div className='w-[50%]  pt-[0px] mr-[1%] p-[10px] border-slate-100 border'>
                  <h6 className='text-[13px] font-[700] mt-2'>Gallery Update</h6>

                  <div className='flex'>
                    <div className='flex mt-[5px] mr-[5px] p-[5px] border border-slate-100 '>
                      <LuPaintbrush2 size={20} className='bg-green-100 p-[4px]' />
                      <div className='ml-[10px]'>
                        <h6 className='text-[12px] font-[400]'>Total Painting - <span className='font-[800]'>({galleryData?.total_painting})</span></h6>
                      </div>
                    </div>

                    <div className='flex mt-[5px] mr-[5px] p-[5px] border border-slate-100 '>
                      <LuPaintbrush2 size={20} className='bg-green-100 p-[4px]' />
                      <div className='ml-[10px]'>
                        <h6 className='text-[12px] font-[400]'>New Painting - <span className='font-[800]'>({galleryData?.new_painting})</span></h6>
                      </div>
                    </div>

                    <div className='flex mt-[5px] mr-[5px] p-[5px] border border-slate-100 '>
                      <LuPaintbrush2 size={20} className='bg-red-100 p-[4px]' />
                      <div className='ml-[10px]'>
                        <h6 className='text-[12px] font-[400]'>Sold Painting - <span className='font-[800]'>({galleryData?.sold_painting})</span></h6>
                      </div>
                    </div>

                    <div className='flex mt-[5px] mr-[5px] relative p-[5px] border border-slate-100 '>
                      <TbTimelineEventText size={20} className='bg-sky-10 0 p-[4px]' />
                      <div className='ml-[10px]'>
                        <h6 className='text-[12px] font-[400]'>New Events -<span className='font-[800]'> ({galleryData?.events?.length})</span></h6>
                      </div>
                      <TbTimelineEventText onClick={() => { setmodal(true); setselectedData({ data: galleryData?.events, type: 'Overall Events', name: 'Gallery Events Report' }) }} size={15} className='bg-slate-700 text-white absolute right-[0px] -top-[10px] p-[4px]' />

                    </div>
                  </div>
                </div>}

              {selected_department?.label === "Digital Media" &&
                <div className='w-[60%] ml-[0%] relative pt-[0px]  p-[10px] border-slate-100 border'>
                  <h6 className='text-[13px] font-[700] mt-2'>Department Task</h6>
                  <MdOutlineExpand onClick={() => { setmodal(true); setselectedData({ data: overall_department_work, type: 'Media Work', name: 'Department Work Report' }) }} className='absolute right-[10px] top-[10px]' />

                  {overall_department_work?.length > 0 &&
                    <div className='border h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                      <div className='flex bg-slate-100'>
                        <h6 className='text-[11px]  p-[4px] border-r w-[25%]'>Department</h6>
                        <h6 className='text-[11px]  p-[4px] border-r w-[25%]'>Name</h6>
                        <h6 className='text-[11px]  p-[4px] border-r w-[25%]'>Work </h6>
                        <h6 className='text-[11px]  p-[4px] w-[25%]'>Expenses</h6>
                      </div>

                      {overall_department_work?.map((e) => (
                        <div className='flex border-t'>
                          <h6 className='text-[10px]  p-[4px] border-r w-[25%]'>{e?.department?.department_name}</h6>
                          <h6 className='text-[11px] font-[800]  p-[4px] border-r w-[25%]'>{e?.name}</h6>
                          <h6 className='text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{e?.work}</h6>
                          <h6 className='text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.expense)}</h6>
                        </div>))}
                    </div>}
                </div>}

            </div>

            <div className='flex mt-2'>
              <div className='w-[50%] relative  pt-[0px]  p-[10px] border-slate-100 border'>
                <h6 className='text-[13px] font-[700] mt-2'>Loan Information</h6>
                <MdOutlineExpand onClick={() => { setmodal(true); setselectedData({ data: selecteddepartmentReport?.loan_details, type: 'Loan Information', name: 'Loan Information' }) }} className='absolute right-[10px] top-[10px]' />

                {selecteddepartmentReport?.loan_details?.length > 0 &&
                  <div className='border h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                    <div className='flex bg-slate-100'>
                      <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Department Name</h6>
                      <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Bank/Property Name</h6>
                      <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Loan Amount</h6>
                      <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Amount Paid</h6>
                      <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Amount Pending</h6>
                      <h6 className='text-[11px] truncate p-[4px] w-[25%]'>Remarks</h6>
                    </div>

                    {selecteddepartmentReport?.loan_details?.map((e) => (
                      <div className='flex border-t'>
                        <h6 className='truncate text-[10px]  p-[4px] border-r w-[25%]'>{e?.department?.department_name}</h6>
                        <h6 className='truncate text-[11px] font-[800]  p-[4px] border-r w-[25%]'>{e?.bank_name_or_party_name}</h6>
                        <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.loan_amount)}</h6>
                        <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.amount_paid)}</h6>
                        <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.amount_pending)}</h6>
                        <h6 className='truncate text-[11px] font-[800] p-[4px] border-r w-[25%] font-[900]'>{e?.remarks}</h6>
                      </div>))}
                  </div>}
              </div>

              <div className='w-[60%] ml-[1%] relative pt-[0px]  p-[10px] border-slate-100 border'>
                <h6 className='text-[13px] font-[700] mt-2'>EMI Information</h6>
                <MdOutlineExpand onClick={() => { setmodal(true); setselectedData({ data: selecteddepartmentReport?.emi_details, type: 'EMI Information', name: 'EMI Information' }) }} className='absolute right-[10px] top-[10px]' />

                {selecteddepartmentReport?.emi_details?.length > 0 &&
                  <div className='border h-[70%] overflow-y-scroll no-scrollbar mt-[10px]'>
                    <div className='flex bg-slate-100'>
                      <h6 className='text-[11px] truncate p-[4px] border-r w-[25%]'>Department</h6>
                      <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>EMI Payable</h6>
                      <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>Total Amount</h6>
                      <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>Amount Paid</h6>
                      <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>Current Month Paid</h6>
                      <h6 className='text-[11px] truncate  p-[4px] border-r w-[25%]'>Remarks</h6>
                    </div>

                    {selecteddepartmentReport?.emi_details?.map((e) => (
                      <div className='flex border-t'>
                        <h6 className='text-[10px] truncate p-[4px] border-r w-[25%]'>{e?.department?.department_name}</h6>
                        <h6 className='text-[11px] truncate font-[800]  p-[4px] border-r w-[25%]'>{e?.emi_payable}</h6>
                        <h6 className='text-[11px] truncate font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.total_amount)}</h6>
                        <h6 className='text-[11px] truncate font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.amount_paid)}</h6>
                        <h6 className='text-[11px] truncate font-[800] p-[4px] border-r w-[25%] font-[900]'>{Priceconstants(e?.current_month_amount_paid)}</h6>
                        <h6 className='text-[11px] truncate font-[800] p-[4px] border-r w-[25%] font-[900]'>{e?.remarks}</h6>
                      </div>))}
                  </div>}
              </div>
            </div>

          </div>}

        <div className='min-h-[20px] opacity-0'>
          <h1>Hello</h1>
        </div>

        <div>

        </div>
      </div>

    </div>
  )
}

export default AdminProfitLoss