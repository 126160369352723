import React,{useState,useEffect} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import {TextInput} from '../../components/input';
// import Select from 'react-select'
import {BiErrorCircle,BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { CreateOnboardService, GenearteUserLetterListService, GetOnboardDetailService, GetUserLetterList, ShareUserLetterGeneratedService, UpdateOnboardService, UpdateRoleService, UploadUserImageService } from '../../services/OnboardServices';
import { GetDepartmentService } from '../../services/DepartmentServices';
import { GetDesignationService } from '../../services/DesignationService';
import { GetTaluk } from '../../services/CSDTServices';
import toast from 'react-hot-toast';

import { GetRoleService } from '../../services/RoleServices';
import SettingsMenu from '../staticscreens/SettingsMenu';
import GoBack from '../../components/back/GoBack';
import {Modal, Select} from 'antd';
import { IoMdShareAlt,IoIosRefresh } from "react-icons/io";
import { Tooltip } from '@mui/material'
import { VscOpenPreview } from "react-icons/vsc";
// import logger from 'logging-library';
import { AiOutlineCopy, AiOutlineDownload } from 'react-icons/ai';
// import { CustomErrorComponent } from 'custom-error';
import fileDownload from "js-file-download";
import axios from 'axios';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import Uploader from '../../components/Uploader';
import { instituteArr } from '../../constants/dataConstants';
import { CreateInductionUserListService, GetCourseListService, GetSuperVisorListService, LoginInstituteService } from '../../services/InductionServices';


const file = 'http://localhost:4001/appointment_letters/first.pdf'
const type = 'pdf'


function Onboard() {

  const navigate = useNavigate()  
  const location = useLocation()
  const {state} = useLocation()
  const userroles = useSelector((state)=>state.Auth.roles)


  const [token,settoken] = useState('')


  const [loader,setloader] = useState(false)
  const [induction,setinduction] = useState(false)

  const option = [
    {label:'Pending',value:'Pending'},
    {label:'Approved',value:'Approved'},
    {label:'Rejected',value:'Rejected'},
  ]

  const [data,setdata] = useState({
    name:'',
    employee_id:'',
    profile_photo:'',
    department:'',
    designation_id:'',
    official_phone:'',
    official_email:'',
    dob:'',
    doj:'',
    doe:'',
    email:'',
    phone:'',
    roles:[],
    is_active:false,
    branch:'',
    is_verified:false,
    exit_form:false,
    is_partially_verified:false,
    sent_for_review:false,
    app_access:false,
    crendtials_shared:false,
    user_in_review:false,
    edit_profile:false,
    induction_account_created:false,
    ctc:'',
    letter_department:''
  })


  const [inductionData,setinductionData] = useState({
    employeeName:'',
    employeeEmail:'',
    employeeDepartment:'',
    employeePhone:'',
    supervisorId:'',
    courseIds:[],
  }) 

  const [inductionDataErr,setinductionDataErr] = useState({
    employeeName:'',
    employeeEmail:'',
    employeeDepartment:'',
    employeePhone:'',
    supervisorId:'',
    courseIds:'',
  }) 

  const [coursesArr,setcoursesArr] = useState([])

  const [letters,setletters] = useState({appointment:'',offer_letter:'',exit_letter:'',data_added:'',appointment_letter:false,exit_letter1:false})

  useEffect(()=>{
    if(location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit'){
      getdata()
    }
    getoptions()
  },[])

  async function getToken(e) {
    const response = await LoginInstituteService(e)
    settoken(response?.data?.token)
    getCoursesList(response?.data?.token)
    getSuperVisorList(response?.data?.token)
 }

 async function getCoursesList(token){
  const header = `Bearer ${token}`;
  let headers = { "headers": {Authorization: header }}
  const response = await GetCourseListService(headers)

  let arr = []
  response?.data?.forEach((d)=>{
    arr?.push({label:d?.courseName,value:d?.id})
  })
  setinductioncourseIdArr(arr)
}

async function getSuperVisorList(token){
  const header = `Bearer ${token}`;
  let headers = { "headers": {Authorization: header }}
  const response = await GetSuperVisorListService(headers)
  let arr = []
  response?.data?.forEach((d)=>{
    arr?.push({label:d?.supervisorName,value:d?.id})
  })
  setinductionsuperVisorArr(arr)
}


  const [departmentArr,setdepartmentArr] = useState([])
  const [inductionsuperVisorArr,setinductionsuperVisorArr] = useState([])
  const [inductioncourseIdArr,setinductioncourseIdArr] = useState([])
  const [designationArr,setdesignationArr] = useState([])
  const [branchArr,setbranchArr] = useState([])
  const [rolesArr,setrolesArr] = useState([])

  const [share,setshare] = useState({email:'',visible:false,type:''})

  const [error,seterror] = useState({
    name:'',
    employee_id:'',
    department:'',
    designation_id:'',
    official_phone:'',
    official_email:'',
    dob:'',
    doj:'',
    doe:'',
    email:'',
    phone:'',
    is_active:false,
    branch:'',
    is_verified:false,
    exit_form:false,
    is_partially_verified:false,
    sent_for_review:false,
    app_access:false,
    crendtials_shared:false,
    ctc:'',
    letter_department:''
  })

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function getoptions(){
    const response = await GetDepartmentService()
    const response1 = await GetDesignationService()
    const response2 = await GetTaluk()
    const response3 = await GetRoleService()
    

    let arr = []
    let arr1 = []
    let arr2 = []
    let arr3 = []

    response?.data?.datas?.forEach((d)=>{
        arr.push({value:d?.id,label:d?.department_name})
    })
    response1?.data?.datas?.forEach((d)=>{
        arr1.push({value:d?.id,label:d?.designation_name})
    })
    response2?.data?.data?.datas?.forEach((d)=>{
        arr2.push({value:d?._id,label:d?.display_name})
    })

    response3?.data?.datas?.forEach((d)=>{
      arr3.push({value:d?.id,label:d?.display_name})
    })

    setdepartmentArr(arr)
    setdesignationArr(arr1)
    setbranchArr(arr2)
    setrolesArr(arr3)
  }

  async function getuserLetter(){
    const response = await GetUserLetterList(state)
    if(response?.data?.data !== null){
      let d = response?.data?.data
      setletters({
        appointment:d?.appointment,
        exit_letter:d?.exit_letter,
        id:d?._id,
        data_added:true
      })
    }else{
      setletters({...letters,data_added:false})

    }
  }

  async function getdata(){
    const response = await GetOnboardDetailService(state)

    let d = response?.data?.datas[0]
    let roles = []
    d?.user_roles?.forEach((r)=>{
      roles.push({label:r?.roles?.display_name,value:r?.roles?._id})
    })

    if(d?.is_verified){
      getuserLetter()
    }
    setdata({...data,
      name:d?.name,
      profile_photo:d?.profile_photo,
      employee_id:d?.employee_id,
      department:{value:d?.department_id[0]?._id,label:d?.department_id[0]?.department_name},
      designation_id:{value:d?.designation_id[0]?._id,label:d?.designation_id[0]?.designation_name},
      official_phone:d?.official_phone,
      official_email:d?.official_email,
      dob:d?.dob,
      doj:d?.doj,
      doe:d?.doe,
      roles:roles,
      email:d?.email,
      phone:d?.phone,
      is_active:d?.is_active,
      branch:{value:d?.branch?._id,label:d?.branch?.branch_name},
      is_verified:d?.is_verified,
      is_partially_verified:d?.is_partially_verified,
      sent_for_review:d?.sent_for_review,
      app_access:d?.app_access,
      exit_form:d?.exit_form,
      edit_info:d?.edit_info,
      edit_profile:d?.edit_profile === undefined ? false : d?.edit_profile,
      induction_account_created:d?.induction_account_created === undefined ? false : d?.induction_account_created,
      crendtials_shared:d?.crendtials_shared,
      user_in_review:d?.user_in_review,
      ctc:d?.ctc,
      admin_rejected_remarks:(d?.admin_rejected_remarks !== '' && d?.admin_rejected_remarks !== undefined) ? d?.admin_rejected_remarks : '',
      agree_company_appointment_letter_terms:(d?.agree_company_appointment_letter_terms !== '' && d?.agree_company_appointment_letter_terms !== undefined) ? {label:d?.agree_company_appointment_letter_terms,value:d?.agree_company_appointment_letter_terms} : '',
      approved_by_admin:(d?.approved_by_admin !== '' && d?.approved_by_admin !== undefined) ? {label:d?.approved_by_admin,value:d?.approved_by_admin} : '',
      letter_department:d?.letter_department?._id !== undefined ? {value:d?.letter_department?._id,label:d?.letter_department?.department_name} : '',

    })
  }

  async function handleSelect(e,e1){
    if(e1 === 'department'){
     setdata({...data,department:departmentArr?.find((d)=>d.value === e)})
     seterror({...error,department:''})
    }else  if(e1 === 'designation_id'){
     setdata({...data,designation_id:designationArr?.find((d)=>d.value === e)})
     seterror({...error,designation_id:''})
    }else  if(e1 === 'branch'){
     setdata({...data,branch:branchArr?.find((d)=>d.value === e)})
     seterror({...error,branch:''})
    }else  if(e1 === 'agree_company_appointment_letter_terms'){
      setdata({...data,agree_company_appointment_letter_terms:{label:e,value:e}})
      seterror({...error,agree_company_appointment_letter_terms:''})
     }else  if(e1 === 'approved_by_admin'){
      setdata({...data,approved_by_admin:{label:e,value:e}})
      seterror({...error,approved_by_admin:''})
     }else if(e1 === 'letter_department'){
      setdata({...data,letter_department:departmentArr?.find((d)=>d.value === e)})
      seterror({...error,letter_department:''})
     }else if(e1 === 'roles'){
      let arr = []
      e?.forEach((ele)=>{
        arr?.push(rolesArr?.find((r)=>r?.value === ele))
      })
     setdata({...data,roles:arr})
     seterror({...error,roles:''})
    }
  }

  async function handleSelect1(e,e1){
    if(e1 === 'employeeDepartment'){
     let dataFind = instituteArr?.find((d)=>d.value === e)
     setinductionData({...inductionData,employeeDepartment:dataFind})
     getToken(e)
     setinductionDataErr({...inductionDataErr,employeeDepartment:''})
    }else  if(e1 === 'supervisorId'){
     setinductionData({...inductionData,supervisorId:inductionsuperVisorArr?.find((d)=>d.value === e)})
     setinductionDataErr({...inductionDataErr,supervisorId:''})
    }else if(e1 === 'courseIds'){
      let arr = []
      e?.forEach((ele)=>{
        if(ele !== null){
          arr?.push(inductioncourseIdArr?.find((r)=>r?.value === ele))
        }
      })
     setinductionData({...inductionData,courseIds:arr})
     setinductionDataErr({...inductionDataErr,courseIds:''})
    }
  }
  

  async function submitdata(){
    if(!data.name){
        seterror({...error,name:'This Field is required*'})
    }else if(!data.employee_id){
        seterror({...error,employee_id:'This Field is required*'})
    }else if(!data.phone){
        seterror({...error,phone:'This Field is required*'})
    }else if(data.phone.length !== 10){
        seterror({...error,phone:'Enter a valid 10 digit mobile no*'})
    }else if(!data.dob){
        seterror({...error,dob:'This Field is required*'})
    }else if(!data.doj){
        seterror({...error,doj:'This Field is required*'})
    }else if(!data.email){
        seterror({...error,email:'This Field is required*'})
    }else if(!data.department){
        seterror({...error,department:'This Field is required*'})
    }else if(!data.designation_id){
        seterror({...error,designation_id:'This Field is required*'})
    }else if(!data.branch){
        seterror({...error,branch:'This Field is required*'})
    }else if(!data.official_email){
        seterror({...error,official_email:'This Field is required*'})
    }else if(!data.official_phone){
        seterror({...error,official_phone:'This Field is required*'})
    }else if(data.official_phone.length !== 10){
        seterror({...error,official_phone:'This Field is required*'})
    }else if(!data.ctc){
      seterror({...error,ctc:'This Field is required*'})
    }else{

        
        let send_data = {...data}
        send_data['designation_id'] = data?.designation_id?.value
        send_data['department_id'] = data?.department?.value
        if(data?.letter_department?.value){
          send_data['letter_department'] = data?.letter_department?.value
        }
        send_data['branch'] = data?.branch?.value
        send_data['approved_by_admin'] = data?.approved_by_admin?.value
        send_data['agree_company_appointment_letter_terms'] = data?.agree_company_appointment_letter_terms?.value
        
        if(location.pathname.split('/')[location.pathname.split('/').length - 1] === 'create'){
            const response = await CreateOnboardService(send_data)
            if(response.status === 422){
                seterror({...error,...response?.data?.errors})
            }
            if(response.status === 201){
                toast.success("Onboarded Successfully")
                resetform()
            }
        }else{
            
            if(inductionData?.id !== undefined){
              send_data['induction_data'] = inductionData
              send_data['induction_account_created'] = true
            }


            const response = await UpdateOnboardService(send_data,state)
            if(response.status === 403){
                if(response?.data?.err?.keyPattern['employee_id'] === 1){
                  seterror({...error,employee_id:'User With Employee Id Already Exist'})
                }else if(response?.data?.err?.keyPattern['official_email'] === 1){
                  seterror({...error,official_email:'User with this offical email already Exist'})
                }else if(response?.data?.err?.keyPattern['official_email'] === 1){
                  seterror({...error,official_phone:'User with this offical phone already Exist'})
                }
            }
   
            if(response.status === 422){
                if(response?.data?.errors !== undefined && response?.data?.errors['fidelitus.teams index'] !== undefined){
                  seterror({...error,employee_id:response?.data?.errors['fidelitus.teams index']})
                }
            }
           

            if(response.status === 200){
                toast.success("Onboarded Updated Successfully")
                resetform()
                navigate(-1)
            }
        }
        
    }
  }

  async function updateRoles(){
    let arr = []
    data?.roles?.forEach((r)=>{
      arr?.push(r?.value)
    })
    const response = await UpdateRoleService({roles:arr,user_id:state})
    if(response.status == 200){
      toast.success("Role Assigned Successfully")
      getdata()
    }
  }

  function resetform(){
    setdata({
        name:'',
        profile_photo:'',
        employee_id:'',
        department:'',
        designation_id:'',
        official_phone:'',
        official_email:'',
        dob:'',
        doj:'',
        doe:'',
        email:'',
        phone:'',
        is_active:false,
        branch:'',
        is_verified:false,
        is_partially_verified:false,
        sent_for_review:false,
        app_access:false,
        exit_form:false,
        crendtials_shared:false,
        induction_account_created:false,
        user_in_review:false,
        ctc:''
      })
    seterror({
        name:'',
        profile_photo:'',
        employee_id:'',
        department:'',
        designation_id:'',
        official_phone:'',
        official_email:'',
        dob:'',
        doj:'',
        doe:'',
        email:'',
        phone:'',
        is_active:false,
        branch:'',
        is_verified:false,
        is_partially_verified:false,
        sent_for_review:false,
        app_access:false,
        exit_form:false,
        edit_info:false,
        crendtials_shared:false,
        user_in_review:false,
        induction_account_created:false,
        ctc:''
      })
  }

  async function GenearteUserLetterList(){
    setloader(true)
    let data = {
      id:state,
      appointment_letter:letters?.appointment_letter,
      exit_letter:letters?.exit_letter1
    }

    const response = await GenearteUserLetterListService(state,data)
    if(response?.status == 200){
      setloader(false)
      setletters({appointment:'',offer_letter:'',exit_letter:'',data_added:'',appointment_letter:false,exit_letter:false,exit_letter1:false})
      setshare({email:'',visible:false,type:''})
      getuserLetter()
      toast.success("Letter Generated Successfully")
    }else{
      setloader(false)
      setletters({appointment:'',offer_letter:'',exit_letter:'',data_added:'',appointment_letter:false,exit_letter:false,exit_letter1:false})
      setshare({email:'',visible:false,type:''})
      getuserLetter()
      toast.success("Something Went Wrong")

    }
  }


  function handlechange1(e){
    setinductionData({...inductionData,[e.target.name]:e.target.value})
    setinductionDataErr({...inductionDataErr,[e.target.name]:''})
  }


  const handleDownload = async(url, filename) => {
    await axios.get(url, {
        responseType: 'blob',
    }).then((res) => {
            fileDownload(res.data, filename)
        })
  }


  async function sharelinktoemail(){
    if(share?.email !== ''){
    let datas = {
      name:data?.name,
      email:share?.email,
      file:share?.file,
      type:share?.selected === "Appointment" ? "1" : "2"
    }
    const response = await ShareUserLetterGeneratedService(datas)
    if(response?.status === 200){
      toast.success("Link Shared Successfully")
      setshare({email:'',visible:false,type:''})
    }
   }

  }

  function returnfilename(v){
    let string = v?.split("/")[v?.split("/")?.length - 1]
    return string;
  }

  async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadUserImageService(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
      seterror({...error,[name]:''})
    }
  }

  function presetinductiondata(){
    let data_init = {...inductionData}
    if(!data_init?.employeeName){
      data_init['employeeName'] = data?.name
    }
    if(!data_init?.employeeEmail){
      data_init['employeeEmail'] = data?.official_email
    }
    if(!data_init?.employeePhone){
      data_init['employeePhone'] = data?.official_phone
    }
    setinductionData(data_init)
    setinduction(!induction)
  }


  async function createinductionaccount() {

    let send_data = {...inductionData}

    let course_arr = []

    if(inductionData?.supervisorId?.value !== undefined){
      send_data['supervisorId'] = inductionData?.supervisorId?.value
    }

    if(inductionData?.employeeDepartment?.value !== undefined){
      send_data['employeeDepartment'] = inductionData?.employeeDepartment?.value
    }

    if(inductionData?.courseIds?.length > 0 ){
      inductionData?.courseIds?.forEach((d)=>{
        course_arr.push(d?.value)
      })
    }
    send_data['courseIds'] = course_arr


    const header = `Bearer ${token}`;
    let headers = { "headers": {Authorization: header }}

    const response = await CreateInductionUserListService(send_data,headers)

    if(response?.status === 200){
      setinductionData(response?.data)
      setinduction(false)
      toast.success("Induction Account Created Successfully!")
    }else if(response?.status == 400){
      toast.error("Induction Account Already Exist!")
    }




    
  }

  function openFile(v){
    let url = `${process.env.REACT_APP_BACKEND_IMAGE_URL}${v}`
    window.open(`${url}`,'_blank')
  }


  return (
    <div className='h-screen max-h-screen min-h-screen overflow-hidden'>
       {/* <div className='sm:block md:hidden'>
       <MobileBackOption goback={()=>navigate(-1)} name="HR FORM" />
       </div> */}

       <Modal open={induction} width={300} closable={false} footer={false}>
       <div >
          <h6 className='text-[12px] font-[800]'>Induction Data</h6>
          <h6 className='text-[10px] bg-gray-100 p-1 font-[500] mt-2'>Use the below form to create or update the induction for the users</h6>

          <TextInput 
            label={'Name'}  
            variant="standard"
            name="name"
            type="text"
            error={inductionDataErr?.employeeName}
            value={inductionData?.employeeName}
            handlechange={handlechange1}
            placeholder="Enter your Department name"
            />


          <TextInput 
            label={'Email'}  
            variant="standard"
            name="name"
            type="text"
            error={inductionDataErr?.employeeEmail}
            value={inductionData?.employeeEmail}
            handlechange={handlechange1}
            placeholder="Enter your Department name"
            />

          <TextInput 
            label={'Phone'}  
            variant="standard"
            name="name"
            type="text"
            error={inductionDataErr?.employeePhone}
            value={inductionData?.employeePhone}
            handlechange={handlechange1}
            placeholder="Enter your Department name"
            />

          <h6 className='text-[11px] font-[600] mb-1 mt-1'>Employee Department </h6>
          <Select 
            value={inductionData.employeeDepartment} 
            error={inductionDataErr.employeeDepartment}
            bordered={false}
            placeholder="" 
            onChange={(e)=>handleSelect1(e,'employeeDepartment')} 
            options={instituteArr} 
            className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
            />

          <h6 className='text-[11px] font-[600] mb-1 mt-1'>Super Visor ID </h6>
          <Select 
            value={inductionData.supervisorId} 
            error={inductionDataErr.supervisorId}
            bordered={false}
            placeholder="" 
            onChange={(e)=>handleSelect1(e,'supervisorId')} 
            options={inductionsuperVisorArr} 
            className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
            />

          <h6 className='text-[11px] font-[600] mb-1 mt-1'>Course ID </h6>
          <Select 
            value={inductionData.courseIds} 
            error={inductionDataErr.courseIds}
            bordered={false}
            mode='multiple'
            placeholder="" 
            onChange={(e)=>handleSelect1(e,'courseIds')} 
            options={inductioncourseIdArr} 
            className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
            />


          <div className='flex items-center justify-end mt-5 pt-2 border-t'>
              <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>{setinduction(!induction)}} />
              <h6 className='w-2'></h6>
              <ButtonFilledAutoWidth btnName="Save" onClick={createinductionaccount} />
            </div>


         </div> 
       </Modal>

       <Modal open={share?.visible} width={300} closable={false} footer={false}>
        <div>
       {loader 
         ?
         <div >
          <h6 className='text-[12px] font-[800]'>We are process with {share?.selected} Letter Generation</h6>
          <h6 className='text-[10px] bg-gray-100 p-1 font-[500] mt-2'>Please wait until the process complete don't skip or close the tab until process completed it not take much time</h6>

         </div> 
    
        :
        <>
        {share?.type === 1 && 
        <div>
          <h6 className='text-[12px] font-[800]'>Share The {share?.selected} Letter</h6>
          <h6 className='text-[11px]  leading-[15px] bg-gray-100 p-1'>You enter the mail id seperated by comma to share this {share?.selected} letter as a link to mentioned mail id </h6>
          
          <TextInput 
            label={'Email'}  
            variant="standard"
            name="name"
            type="text"
            error={share.email_err}
            value={share.email}
            handlechange={(e)=>setshare({...share,email:e.target.value})}
            placeholder="Enter your Department name"
            />


            <div className='flex items-center justify-end mt-2 pt-2 border-t'>
              <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>{setshare({...share,visible:!share.visible});getuserLetter()}} />
              <h6 className='w-2'></h6>
              <ButtonFilledAutoWidth btnName="Save" onClick={sharelinktoemail} />
            </div>

        </div>}

        {share?.type === 2 && 
        <div>
          <h6 className='text-[12px] font-[800]'>Re-Generate  {share?.selected} Letter</h6>
          <h6 className='text-[11px]  leading-[15px] bg-gray-100 p-1'>By click on the Regenerate button the {share?.selected} Letter will be Re-Created</h6>
          
         

            <div className='flex items-center justify-end mt-2 pt-2 border-t'>
              <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>{setshare({...share,visible:!share.visible});getuserLetter()}} />
              <h6 className='w-2'></h6>
              <ButtonFilledAutoWidth btnName="Save" onClick={GenearteUserLetterList} />
            </div>

        </div>}
        </>}
        </div>
       </Modal>

        <div className='flex'> 
         <SettingsMenu />
          <div className='px-5 w-full'>

          {(state !== null && !data?.induction_account_created) && <h6 onClick={()=>{presetinductiondata()}} className='bg-slate-100 border border-slate-200 absolute cursor-pointer z-100 right-36 top-4 w-[100px] text-[11px] text-center text-black p-2 rounded-[6px] font-[700]'>Induction Info</h6>}
          {state !== null && <h6 onClick={()=>navigate('/settings/onboard_list/detail',{state:state})} className='bg-slate-600 absolute cursor-pointer z-100 right-10 top-4 w-[100px] text-[11px] text-center text-white p-2 rounded-[6px] font-[700]'>Detail Info</h6>}

          
        <div className='flex w-full h-screen overflow-y-scroll mb-10'>
        <div className='border-r w-64 pr-5 pt-5'>
        <GoBack />  

        <h6 className='font-[700] mb-1'>Create/Edit Onboard Employee</h6>
        <h6 className='text-[11px] p-2  mb-2 bg-slate-100'>Use the below form to create or update a onboard employee</h6>
        

        <h6 className='text-[11px] mt-2 font-[600] mb-1' >Profile Photo</h6>
        <Uploader image={data?.profile_photo}  setimagefunc={(e)=>{uploadfile(e,'profile_photo')}}  removeimageuploadfunc = {()=>setdata({...data,profile_photo:''})}/>


        <TextInput 
         label={'Name'}  
         variant="standard"
         name="name"
         type="text"
         error={error.name}
         value={data.name}
         handlechange={handlechange}
         placeholder="Enter your Department name"
         />

        <TextInput 
         label={'Employee Id'}  
         variant="standard"
         name="employee_id"
         type="text"
         error={error.employee_id}
         value={data.employee_id}
         handlechange={handlechange}
         placeholder="Enter your Employee Id"
         />

        <div className='flex justify-between'>

        <div className="w-full mr-1"> 
        <TextInput 
         label={'Phone'}  
         variant="standard"
         name="phone"
         type="text"
         error={error.phone}
         value={data.phone}
         handlechange={handlechange}
         placeholder="Enter your Phone"
         />
        </div> 

        <div className="w-full ml-1 mt-0.5">     
        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Date of Birth </h6>
        <input className='border p-1.5 w-full  text-[11px] border-gray-300 ' type='Date' value={data?.dob?.slice(0,10)} onChange={(e)=>{setdata({...data,dob:e.target.value});seterror({...error,dob:''})}} />

         {/* <DatePicker ampm={false} placeholder='' style={{maxHeight:'40px',borderWidth:'2px solid #ddd',borderRadius:'4px',minWidth:'100%'}} inputFormat="MM/dd/yyyy" value={data?.dob === '' ? null : moment(data?.dob)} onChange={(v) => {setdata({...data,dob:v});seterror({...error,dob:''})}} /> */}
        {(error?.dob !== '' || error?.dob !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.dob}</h6>}
         </div>
         </div>

         <div className='flex justify-between mt-1 w-full'>
       
         <div className="w-full mr-1">
        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Date of Joining </h6>
        <input className='border p-1.5 text-[11px] w-full  text-[11px] border-gray-300 ' type='Date' value={data?.doj?.slice(0,10)} onChange={(e)=>{setdata({...data,doj:e.target.value});seterror({...error,doj:''})}} />
          {/* <DatePicker placeholder='' style={{maxHeight:'40px',borderWidth:'2px solid #ddd',borderRadius:'4px',minWidth:'100%'}} inputFormat="MM/dd/yyyy" value={data?.doj === '' ? null : moment(data?.doj)} onChange={(v) => {setdata({...data,doj:v});seterror({...error,doj:''})}} /> */}
          {(error?.doj !== '' || error?.doj !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.doj}</h6>}
         </div>



         <div className="w-full ml-1">
        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Date of Exit </h6>
        <input className='border p-1.5 text-[11px] w-full  text-[11px] border-gray-300 ' type='Date' value={data?.doe?.slice(0,10)} onChange={(e)=>{setdata({...data,doe:e.target.value});seterror({...error,doe:''})}} />
          {/* <DatePicker placeholder='' style={{maxHeight:'40px',borderWidth:'2px solid #ddd',borderRadius:'4px',minWidth:'100%'}} inputFormat="MM/dd/yyyy" value={data?.doe === '' ? null : moment(data.doe)} onChange={(v) => {setdata({...data,doe:v});seterror({...error,doe:''})}} /> */}
          {(error?.doe !== '' || error?.doe !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.doe}</h6>}
         </div>
         </div>


        <TextInput 
         label={'Email'}  
         variant="standard"
         name="email"
         type="text"
         error={error.email}
         value={data.email}
         handlechange={handlechange}
         placeholder="Enter your email address"
         />

       


        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Department </h6>
         <Select
           value={data.department} 
           error={error.department}
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'department')} 
           options={departmentArr} 
           className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
           />
         {error.department !== '' && error.department !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.department}</span>
         </div>}


         <h6 className='text-[12px] font-semibold mb-1 mt-1'>Letter Department </h6>
         <Select
           value={data.letter_department} 
           error={error.letter_department}
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'letter_department')} 
           options={departmentArr} 
           className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
           />
         {error.letter_department !== '' && error.letter_department !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.letter_department}</span>
         </div>}



         <h6 className='text-[12px] font-semibold mb-1 mt-1'>Designation </h6>
         <Select
           value={data.designation_id} 
           error={error.designation_id}
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'designation_id')} 
           options={designationArr} 
           className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'

           />
         {error.designation_id !== '' && error.designation_id !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.designation_id}</span>
         </div>}

         <h6 className='text-[12px] font-semibold mb-1 mt-1'>Branch </h6>
         <Select
           bordered={false}
           value={data.branch} 
           error={error.branch}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'branch')} 
           options={branchArr} 
           className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
           />
         {error.branch !== '' && error.branch !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.branch}</span>
         </div>}

        <TextInput 
         label={'Official Email'}  
         variant="standard"
         name="official_email"
         type="text"
         error={error.official_email}
         value={data.official_email}
         handlechange={handlechange}
         placeholder="Enter your official email"
         />


        <TextInput 
         label={'Official Phone'}  
         variant="standard"
         name="official_phone"
         type="text"
         error={error.official_phone}
         value={data.official_phone}
         handlechange={handlechange}
         placeholder="Enter your official phone"
         />

        <TextInput 
         label={'CTC'}  
         variant="standard"
         name="ctc"
         type="text"
         error={error.ctc}
         value={data.ctc}
         handlechange={handlechange}
         placeholder="Enter your ctc"
         />

         <h6 className='text-[12px] font-semibold mb-1 mt-1'>Admin Approval </h6>
         <Select
           bordered={false}
           value={data.approved_by_admin} 
           error={error.approved_by_admin}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'approved_by_admin')} 
           options={option} 
           className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
           />
         {error.approved_by_admin !== '' && error.approved_by_admin !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.approved_by_admin}</span>
         </div>}


         <h6 className='text-[12px] font-semibold mb-1 mt-1'>Employee Approval </h6>
         <Select
           bordered={false}
           value={data.agree_company_appointment_letter_terms} 
           error={error.agree_company_appointment_letter_terms}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'agree_company_appointment_letter_terms')} 
           options={option} 
           className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
           />
         {error.agree_company_appointment_letter_terms !== '' && error.agree_company_appointment_letter_terms !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.agree_company_appointment_letter_terms}</span>
         </div>}

         


          <div className='flex items-center mt-2'>
          <div className='flex items-center mt-0 mr-2'>
          {data?.sent_for_review ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,sent_for_review:!data.sent_for_review})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,sent_for_review:!data.sent_for_review})}  /> }
          {/* <input type={'checkbox'} checked={data?.sent_for_review} onChange={()=>setdata({...data,sent_for_review:!data.sent_for_review})} />  */}
          <h6 className="text-[10px] font-[600] ml-1">Send For Review</h6> 
          </div> 

          <div className='flex items-center mt-0 mr-2'>
          {data?.is_partially_verified ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,is_partially_verified:!data.is_partially_verified})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,is_partially_verified:!data.is_partially_verified})}  /> }
          {/* <input type={'checkbox'} checked={data?.is_partially_verified} onChange={()=>setdata({...data,is_partially_verified:!data.is_partially_verified})} />  */}
          <h6 className="text-[10px] font-[600] ml-1">Partially Verified</h6> 
          </div>  

          
          </div>

          <div  className='flex items-center'>
          <div className='flex items-center mt-0 mr-2'>
          {data?.is_verified ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,is_verified:!data.is_verified})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,is_verified:!data.is_verified})}  /> }
          {/* <input type={'checkbox'} checked={data?.is_verified} onChange={()=>setdata({...data,is_verified:!data.is_verified})} />  */}
          <h6 className="text-[10px] font-[600] ml-1">Is Verified</h6> 
          </div>  

          <div className='flex items-center mt-0 mr-2'>
          {data?.crendtials_shared ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,crendtials_shared:!data.crendtials_shared})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,crendtials_shared:!data.crendtials_shared})}  /> }

          {/* <input type={'checkbox'} checked={data?.crendtials_shared} onChange={()=>setdata({...data,crendtials_shared:!data.crendtials_shared})} />  */}
          <h6 className="text-[10px] font-[600] ml-1">Send Credentials</h6> 
          </div> 
          </div>   

          <div className='flex items-center'>
          <div className='flex items-center mt-0 mr-2'>
          {data?.is_active ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,is_active:!data.is_active})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,is_active:!data.is_active})}  /> }
          {/* <input type={'checkbox'} checked={data?.is_active} onChange={()=>setdata({...data,is_active:!data.is_active})} />  */}
          <h6 className="text-[10px] font-[600] ml-1">Is Active</h6> 
          </div>  

          <div className='flex items-center mt-0 mr-2'>
          {data?.app_access ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,app_access:!data.app_access})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,app_access:!data.app_access})}  /> }
          {/* <input type={'checkbox'} checked={data?.app_access} onChange={()=>setdata({...data,app_access:!data.app_access})} />  */}
          <h6 className="text-[10px] font-[600] ml-1">App Access</h6> 
          </div>

          </div>

          <div className='flex items-center'>
          <div className='flex items-center mt-0 mr-2'>
          {data?.exit_form ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,exit_form:!data.exit_form})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,exit_form:!data.exit_form})}  /> }
          {/* <input type={'checkbox'} checked={data?.app_access} onChange={()=>setdata({...data,app_access:!data.app_access})} />  */}
          <h6 className="text-[10px] font-[600] ml-1">Exit Employee</h6> 
          </div>
          <div className='flex items-center mt-0 mr-2'>
          {data?.user_in_review ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,user_in_review:!data.user_in_review})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,user_in_review:!data.user_in_review})}  /> }
          {/* <input type={'checkbox'} checked={data?.app_access} onChange={()=>setdata({...data,app_access:!data.app_access})} />  */}
          <h6 className="text-[10px] font-[600] ml-1">User In Review</h6> 
          </div>
          </div>

          <div className='flex items-center'>
          <div className='flex items-center mt-0 mr-2'>
          {data?.edit_info ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,edit_info:!data.edit_info})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,edit_info:!data.edit_info})}  /> }
          {/* <input type={'checkbox'} checked={data?.app_access} onChange={()=>setdata({...data,app_access:!data.app_access})} />  */}
          <h6 className="text-[10px] font-[600] ml-1">Edit Profile</h6> 
          </div>

        
         
          </div>

          <div className='flex items-center'>

          <div onClick={()=>setdata({...data,induction_account_created:!data.induction_account_created})} className='flex items-center mt-0 mr-2'>
          {/* onClick={()=>setdata({...data,induction_account_created:!data.induction_account_created})} */}
          {data?.induction_account_created ?  <BiCheckboxSquare size={22} className='text-slate-600'/>  : <BiCheckbox size={22} className='text-slate-300' /> }
          {/* <input type={'checkbox'} checked={data?.app_access} onChange={()=>setdata({...data,app_access:!data.app_access})} />  */}
          <h6 className="text-[10px] font-[600] ml-1">Induction Account Created</h6> 
          </div>
         
          </div>




         <div className=' flex mt-4 pb-5'>
            <ButtonOutlinedAutoWidth btnName={'Back'} onClick={()=>{navigate(-1)}} />
            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName={'Save'} onClick={submitdata} />
            </div>
         </div>
        </div>
       
        {(state !== null && state !== undefined && userroles?.includes('admin') || userroles?.includes('controller') || userroles?.includes('hr_admin')) &&
        <div className='ml-5 mt-[9%] w-64'>
        <h6 className='text-[11px] bg-slate-100 p-2 font-[500]'>Use the below form to create or roles of an onboard employee</h6>
        <h6 className='text-[12px] font-[600] mb-1 mt-1'>Roles </h6>
         <Select
           value={data.roles} 
           error={error.roles}
           bordered={false}
           placeholder="" 
           mode="multiple"
           onChange={(e)=>handleSelect(e,'roles')} 
           options={rolesArr} 
           className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'

           />
         {error.roles !== '' && error.roles !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.roles}</span>
         </div>}

         <div className='flex w-full align-end items-end  mt-2'>
            <ButtonFilledAutoWidth btnName={'Save'} onClick={updateRoles} />
         </div>


         <h6 className='text-[12px] font-[600] mb-1 mt-5'>Employee Documents </h6>
        <h6 className='text-[11px] bg-slate-100 p-2 font-[500]'>Documents Provided By Company For The Employee.</h6>
          {!letters?.data_added ? 
          <div>
                <div className='flex items-center mt-2'>
                  <div className='flex items-center mt-0 mr-2'>
                  {letters?.appointment_letter ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setletters({...letters,appointment_letter:!letters.appointment_letter})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setletters({...letters,appointment_letter:!letters.appointment_letter})} /> }
                  <h6 className="text-[10px] font-[600] ml-1">Appointment</h6> 
                  </div> 
                  <div className='flex items-center mt-0 mr-2'>
                  {letters?.exit_letter ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setletters({...letters,exit_letter:!letters.exit_letter})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setletters({...letters,exit_letter:!letters.exit_letter})}  /> }
                  <h6 className="text-[10px] font-[600] ml-1">Exit/Expirence</h6> 
                  </div> 
                </div>  
               {(letters?.appointment_letter || letters?.exit_letter) &&
              <h6 onClick={GenearteUserLetterList} className='text-[13px] cursor-pointer bg-slate-600 w-[105px] text-center text-white font-[500] px-2 py-2 rounded-[6px] mt-4'>Generate Now</h6>}          
          </div> 
          :
          <div>
          
           {letters?.appointment !== '' && letters?.appointment !== undefined ? 
            <div className=' border mt-2 items-center justify-between overflow-hidden cursor-pointer group'>
                <h1 className='text-[11px] font-[600] group-hover:underline text-blue-600 p-2'>Appointment Letter</h1>
                <span className='flex items-center justify-center border-t'>
                
                <CopyToClipboard text={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${letters?.appointment?.replace('public/','')}`} onCopy={()=>toast.success("Link Copied")}>
                <Tooltip title="Copy link">
                <span className='flex items-center justify-center border-l px-2 py-1.5'><AiOutlineCopy className='mr-2' /></span>
                </Tooltip>
              
                </CopyToClipboard>
                <Tooltip title="Preview File">
                <span  onClick={()=>openFile(letters?.appointment?.replace('public/',''))} className='border-r flex items-center justify-center pr-0 border-l px-2 py-1.5'><VscOpenPreview className='mr-2' /></span>
                </Tooltip>
                <Tooltip title="Download">
                <span onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${letters?.appointment?.replace('public/','')}`,returnfilename(letters?.appointment))} className='border-r flex items-center justify-center px-2 pr-0 py-1.5'><AiOutlineDownload className='mr-2' /></span>
                </Tooltip>
                <Tooltip title="Share">
                <span onClick={()=>setshare({...share,file:`${process.env.REACT_APP_BACKEND_IMAGE_URL}${letters?.appointment?.replace('public/','')}`,visible:!share?.visible,type:1,selected:'Appointment',email:`${data?.official_email},${data?.email}`})} className='border-r flex items-center justify-center px-2 pr-0 py-1.5'><IoMdShareAlt className='mr-2' /></span>
                </Tooltip>
                <Tooltip title="Regenerate">
                <span onClick={()=>{setshare({...share,visible:!share?.visible,type:2,selected:'Appointment'});setletters({...letters,appointment_letter:true})}} className='border-r flex items-center justify-center px-2 py-1.5'><IoIosRefresh size={15} /></span>
                </Tooltip>
                </span>
                
            </div>  
            :
            <div className=' border mt-2 items-center justify-between overflow-hidden cursor-pointer group'>
            <h1 className='text-[11px] font-[600] group-hover:underline text-blue-600 p-2'>Appointment Letter</h1>
            <span className='flex items-center justify-center border-t'>
            
            
            <Tooltip title="Generate">
            <span onClick={()=>{setshare({...share,visible:!share?.visible,type:2,selected:'Appointment'});setletters({...letters,appointment_letter:true})}} className='border-r border-l flex items-center justify-center px-2 py-1.5'><IoIosRefresh size={15} /></span>
            </Tooltip>
            </span>
             </div>} 

             {letters?.exit_letter !== '' && letters?.exit_letter !== undefined ? 
            <div className=' border mt-2 items-center justify-between overflow-hidden cursor-pointer group'>
                <h1 className='text-[11px] font-[600] group-hover:underline text-blue-600 p-2'>Resignation/Experience Letter</h1>
                <span className='flex items-center justify-center border-t'>

                <CopyToClipboard text={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${letters?.exit_letter?.replace('public/','')}`} onCopy={()=>toast.success("Link Copied")}>
                <Tooltip title="Copy link">
                
                <span className='flex items-center justify-center border-l px-2 py-1.5'><AiOutlineCopy className='mr-2' /></span>
                </Tooltip>
              
                </CopyToClipboard> 
               
              
                <Tooltip title="Preview File">
                <span onClick={()=>openFile(letters?.exit_letter?.replace('public/',''))} className='border-r flex items-center justify-center pr-0 border-l px-2 py-1.5'><VscOpenPreview className='mr-2' /></span>
                </Tooltip>
                <Tooltip title="Download">
                <span onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${letters?.exit_letter?.replace('public/','')}`,returnfilename(letters?.exit_letter))} className='border-r flex items-center justify-center px-2 pr-0 py-1.5'><AiOutlineDownload className='mr-2' /></span>
                </Tooltip>
                <Tooltip title="Share">
                <span onClick={()=>setshare({...share,file:`${process.env.REACT_APP_BACKEND_IMAGE_URL}${letters?.exit_letter?.replace('public/','')}`,visible:!share?.visible,type:1,selected:'exit_letter',email:`${data?.official_email},${data?.email}`})} className='border-r flex items-center justify-center px-2 pr-0 py-1.5'><IoMdShareAlt className='mr-2' /></span>
                </Tooltip>
                <Tooltip title="Regenerate">
                <span onClick={()=>{setshare({...share,visible:!share?.visible,type:2,selected:'Resignation/Experience'});setletters({...letters,exit_letter1:true})}} className='border-r flex items-center justify-center px-2 py-1.5'><IoIosRefresh size={15} /></span>
                </Tooltip>
                </span>
                
            </div>  
            :
            <div className=' border mt-2 items-center justify-between overflow-hidden cursor-pointer group'>
            <h1 className='text-[11px] font-[600] group-hover:underline text-blue-600 p-2'>Resignation/Experience Letter</h1>
            <span className='flex items-center justify-center border-t'>
            
            
            <Tooltip title="Generate">
            <span onClick={()=>{setshare({...share,visible:!share?.visible,type:2,selected:'Resignation/Experience'});setletters({...letters,exit_letter1:true})}} className='border-r border-l flex items-center justify-center px-2 py-1.5'><IoIosRefresh size={15} /></span>
            </Tooltip>
            </span>
             </div>} 

              

              

          </div>     
        } 

          
        </div>}


         </div>
         </div>
         </div>




    </div>
  )
}

export default Onboard