import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your Firebase Config
// const firebaseConfig = {
//     apiKey: "AIzaSyCpcPVl2MA_OTlovIxM-tG7Krqkf06iP8A",
//     authDomain: "fcplcrm.firebaseapp.com",
//     projectId: "fcplcrm",
//     storageBucket: "fcplcrm.firebasestorage.app",
//     messagingSenderId: "826346112980",
//     appId: "1:826346112980:web:6d82f5f51dd8335cba7841",
//     measurementId: "G-HE7PGG5SMS"
//   };

const firebaseConfig = {
  apiKey: "AIzaSyD4fgBog-4ztHrxJA25s229k3QLLfdro1g",
  authDomain: "trhiveapplication.firebaseapp.com",
  projectId: "trhiveapplication",
  storageBucket: "trhiveapplication.firebasestorage.app",
  messagingSenderId: "82494287429",
  appId: "1:82494287429:web:02c4101ae63f53e5057d2c",
  measurementId: "G-LP8YXFQ966"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
