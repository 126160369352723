import { deleteRequest, get, post, postfd, publicPost, put } from "../../../helpers/apihelpers";

export const CreateAssetAssignedService=async(data)=>{
    try {
        const result = await post(`api/it/assign_asset/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateAssetAssignedService=async(data,id)=>{
    try {
        const result = await put(`api/it/assign_asset/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAssetAssignedService=async(page,text,department,user)=>{
    try {
        const result = await get(`api/it/assign_asset/get?page=${page}&text=${text}&department=${department}&user=${user}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAssetAssignedDetailService=async(id)=>{
    try {
        const result = await get(`api/it/assign_asset/get_detail/${id}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMyAssetAssignedService=async(id)=>{
    try {
        const result = await get(`api/it/assign_asset/get_my_asset/${id}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMyAssetAssignedUsageService=async()=>{
    try {
        const result = await get(`api/it/assign_asset/get_my_asset_usage_history`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMyAssetAssignExistService=async(id)=>{
    try {
        const result = await get(`api/it/assign_asset/get_asset_exist/${id}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const RetrieveMyAssetAssignListService=async(id,data)=>{
    try {
        const result = await put(`api/it/assign_asset/retrieve_asset/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAssetAssignedService=async(id)=>{
    try {
        const result = await deleteRequest(`api/it/assign_asset/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadAssetAssignedService=async(data)=>{
    try {
        const result = await postfd(`api/it/assign_asset/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadAssetAssignedOptionService=async(text,department,user)=>{
    try {
        const result = await get(`api/it/assign_asset/download_excel?text=${text}&department=${department}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}