import React,{useState,useEffect} from 'react'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { DatePicker, Modal,Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@material-ui/core';
import { AiOutlineEdit,AiOutlineDelete,AiOutlineFileExcel } from 'react-icons/ai';
import toast from 'react-hot-toast';
import axios from 'axios';
import fileDownload from "js-file-download";
import { useSelector } from 'react-redux';
import FTSLeadMenu from '../fts_lead/FTSLeadMenu';
import { GetDepartmentService } from '../../services/DepartmentServices';
import ErrorComponent from '../../components/errorDesign/ErrorComponent';
import Uploader from '../../components/Uploader';
import { BiFilterAlt } from "react-icons/bi";
import { RxReset } from "react-icons/rx";
import { DeleteFTSVerticalMonthlyMeetingReviewService, GetFTSVerticalMonthlyMeetingReviewService, UploadFTSVerticalMonthlyMeetingReviewService } from '../../services/ftsVerticalReportServices/FTSVerticalMonthlyMeetingReviewServices';

function FTSVerticalMonthlyMeetingReviewList() {

  const navigator = useNavigate()
  const roles = useSelector(state=>state.Auth.roles)

  const {pathname} = useLocation()

  const path = pathname?.split('/')[1]

  const status = [{label:"Pending",value:"Pending"},{label:"Wip",value:"Wip"},{label:"Converted",value:"Converted"},{label:"Lost",value:"Lost"}]
  const [departments,setdepartments] = useState([])

  const [modal,setmodal] = useState(false)
  const [modal1,setmodal1] = useState(false)
  const [page,setpage] = useState(1)
  const [data,setdata] = useState({})
  const [selecteddata,setselecteddata] = useState({})

  const [uploadData,setuploadData] = useState({file:'',department:''})
  const [uploadDataErr,setuploadDataErr] = useState({file:'',department:''})


  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',department:'',status:''})
  
  useEffect(()=>{
    getdepartment()
  },[])

  useEffect(()=>{
    getdata()
  },[page])

  async function getdata(){
   const response =  await GetFTSVerticalMonthlyMeetingReviewService(page,search?.text,search?.from_date1,search?.to_date1,search?.department?.value !== undefined ? search?.department?.value:'',search?.status?.value !== undefined ? search?.status?.value :'')
   setdata(response?.data)
  }

  async function getdepartment() {
      const response = await GetDepartmentService();
      let arr = []
      
      response.data.datas.forEach((d)=>{
        if(d?.department_name !== 'Finance Team' && d?.department_name !== 'Gallery Team'  && d?.department_name !== 'Shilpa Foundation'){
          arr.push({label:d?.department_name,value:d?.id})
        }  
      })
  
      setdepartments(arr)
  }

 
  async function applyFilter(){
    setsearch({...search,activate:true})
    setpage(1)
    const response =  await GetFTSVerticalMonthlyMeetingReviewService(1,search?.text,search?.from_date1,search?.to_date1,search?.department?.value !== undefined ? search?.department?.value:'',search?.status?.value !== undefined ? search?.status?.value :'')
    setdata(response?.data)
  }

  async function resetfilter(){
    setsearch({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',department:'',status:''})
    setpage(1)
    const response =  await GetFTSVerticalMonthlyMeetingReviewService(1,'','','')
    setdata(response?.data)
  }

  async function deleteData(){
    const response = await DeleteFTSVerticalMonthlyMeetingReviewService(selecteddata?._id)
    if(response?.status === 200){
        toast.success("Deleted Successfully")
    }else{
        toast.success("Deleted Successfully")
    }
    setmodal(false)
    getdata()
  }


  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
        })
  }

  async function uploadExcel() {
    if(!uploadData?.department){
      setuploadDataErr({...uploadDataErr,department:"This Field is required*"})
    }else if(!uploadData?.file){
      setuploadDataErr({...uploadDataErr,file:"This Field is required*"})
    }else{
      const response = await UploadFTSVerticalMonthlyMeetingReviewService({file:uploadData?.file,department:uploadData?.department?.value})
      if(response?.status === 201){
        toast.success("FTS Vertical Report Uploaded")
        getdata()
        setmodal1(false)
        resetuploadForm()
      }
    }
    
  }

  function resetuploadForm(){
    setuploadData({file:'',department:''})
    setuploadDataErr({file:'',department:''})
  }

  
  return (
    <div className='w-[98%] h-screen overflow-hidden'>

    <Modal
       keepMounted
       open={modal}
       onClose={()=>setmodal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setmodal(false)} />
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteData()}  /> 
           </div>
         </div>
       </div>
     </Modal>


     <Modal
       keepMounted
       open={modal1}
       onClose={()=>setmodal1(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[13px] text-left mb-2 w-full">Upload Excel?</h6>
         <h6 className='bg-slate-100 text-[11px] p-1.5 font-[400]'>Use the below form to upload the bulk data for the fts vertical report based department</h6>

         <h6 className='text-[11px] font-[600] mb-1 mt-2' >{"Department"}</h6>
         
          <div className='border border-l-4 border-l-slate-700 border-gray-300 py-[2px]'>
          <Select
            bordered={false}
            size='small'
            allowClear={true}
            onClear={()=>setdata({...data,department:''})}
            defaultValue={uploadData?.department}
            value={uploadData?.department}
            className='w-[100%]'
            options={departments}
            onChange={(v)=>{setuploadData({...uploadData,department:departments?.find((f)=>f?.value === v)});setuploadDataErr({...uploadDataErr,department:''})}}
          />
          </div>
          <ErrorComponent error={uploadDataErr?.department} />
         
           <div className='absolute top-2 right-2'>
            <a href="https://fcplfmsbucket.s3.ap-south-1.amazonaws.com/Monthly+Meeting+Review.xlsx" downlaod> <h6 className='text-[10px] cursor-pointer font-[700] flex underline items-center'><AiOutlineFileExcel className='mr-1' /> Download Sample</h6></a>
          </div>
          <h6 className='text-[11px] font-[600] mb-1 mt-2' >{"Excel"}</h6>
          <Uploader image={uploadData?.file}  setimagefunc={(e)=>{setuploadData({...uploadData,file:e});setuploadDataErr({...uploadDataErr,file:''})}}  removeimageuploadfunc = {(e)=>{setuploadData({...uploadData,file:''});setuploadDataErr({...uploadDataErr,file:''})}} />

          <ErrorComponent error={uploadDataErr?.file} />


         <div className='flex justify-end mt-3 '>
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>{setmodal1(false);resetuploadForm()}} />
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>uploadExcel()}  /> 
           </div>
         </div>
       </div>
     </Modal>




        <div className='flex'>
            <div className={`min-w-44 ${path == 'profile' && 'min-w-[180px] pl-2'}`}>
               <FTSLeadMenu />
            </div>
            <div className='w-[100%] pl-5 pt-4'>

            <div className='md:w-[100%] flex flex-wrap md:flex-wrap items-center -mt-1 border-b justify-between pb-1'>
                <h6 className='font-[800] text-[13px]'>Total FTS Monthly Meeting Review List  ({data?.pagination?.total})</h6>

                <div className='flex  items-center text-[12px]'>

                    <AiOutlineFileExcel onClick={()=>setmodal1(true)} size={24}  className='mx-2 bg-gray-200 p-1.5' />

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total}  </h6>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                    <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 


                    
                    <div className='hidden md:block mr-2'>
                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                    </div>  


                    <div className='mr-2'>
                      <Select
                        bordered={false}
                        placeholder={'Department'}
                        className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px] rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none' 
                        optionFilterProp="children"
                        onChange={(e)=>setsearch({...search,department:departments?.find((f)=>f?.value === e)})}
                        value={search?.department === '' ? null : search?.department}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={departments}
                    />
                    </div>

                    {/* <div className='mr-2'>
                      <Select
                        bordered={false}
                        placeholder={'Status'}
                        className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px] rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none' 
                        optionFilterProp="children"
                        onChange={(e)=>setsearch({...search,status:status?.find((f)=>f?.value === e)})}
                        value={search?.status === '' ? null : search?.status}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={status}
                    />
                    </div> */}

                     <div className='ml-0 mr-1'>
                      <BiFilterAlt  onClick={()=>applyFilter(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
                      </div> 
                     <div className='ml-1 mr-2'>
                      <RxReset  onClick={resetfilter} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />
                      </div>
                    
                    <ButtonFilledAutoWidth btnName="Add New" onClick={()=>navigator(`/${path}/monthly_meeting_report/create`)} />

                </div>
            </div>

          

            <div className='overflow-x-hidden h-[90vh] mb-[50px] overflow-y-scroll'>
                            
                {data?.datas?.length === 0 &&
                <div className='grid place-items-center mt-20  items-center justify-center'>
                    <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                    <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                    <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
                </div>}

                <div className='h-[88vh] w-[100%] overflow-x-hidden overflow-y-scroll'>
                    {data?.datas?.length > 0 &&
                     <div className='max-h-[85vh] w-[100%] mt-4 border-t  overflow-x-hidden  border-l border-r overflow-y-scroll'>
                          <div className='sticky top-0 z-50 flex flex-wrap md:flex-nowrap  bg-white py-[-5px] border-b border-slate-200 relative'>
                              <h6  className='top-0 bg-white z-50 text-[12px] min-w-[5%]  max-w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>Sl No</h6>
                              <h6 className='sticky top-0 z-50  text-[12px]  min-w-[12%] max-w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r'>New leads generated Internally</h6>
                              <h6 className='sticky top-0 z-50  text-[12px]  min-w-[12%] max-w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r'>No. of leads received by CRM/ Corp/ Social Media. </h6>
                              <h6 className='sticky top-0 z-50  text-[12px]  min-w-[12%] max-w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r'>No. of New Leads shared by business units to other business units. </h6>
                              <h6 className='sticky top-0 z-50  text-[12px] min-w-[12%] max-w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r'>No. of new leads converted </h6>
                              <h6 className='sticky top-0 z-50  text-[12px] min-w-[12%] max-w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r'>No. of new leads rejected.</h6>
                              <h6 className='sticky top-0 z-50  text-[12px] min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r'>Department</h6>
                              <h6 className='sticky top-0 z-50  text-[12px] min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 '>Actions</h6>
                          </div>

                          {data?.datas?.map((d,i)=>(
                          <div className='flex flex-wrap md:flex-nowrap bg-white py-[-5px] border-b border-slate-200 relative'>
                              <h6  className='bg-white text-[12px] min-w-[5%]  max-w-[5%]   px-2 py-1 font-[500] border-r flex items-center justify-center'>{(page > 1 ? i+1+ (data?.pagination?.limit * (page - 1)) : i+1 )}</h6>
                              <h6 className=' text-[12px] truncate min-w-[12%] max-w-[12%] px-2 py-1 font-[500] border-r'>{d?.new_leads_generated}</h6>
                              <h6 className=' text-[12px] truncate min-w-[12%] max-w-[12%] px-2 py-1 font-[500] border-r'>{d?.leads_recieved}</h6>
                              <h6 className=' text-[12px] truncate min-w-[12%] max-w-[12%] px-2 py-1 font-[500] border-r'>{d?.leads_converted}</h6>
                              <h6 className=' text-[12px] truncate min-w-[12%] max-w-[12%]  px-2 py-1 font-[500] border-r'>{d?.leads_share}</h6>
                              <h6 className=' text-[12px] truncate  min-w-[12%] max-w-[12%]  px-2 py-1 font-[500] border-r'>{d?.leads_rejected}</h6>
                              <h6 className=' text-[12px] truncate min-w-[10%] max-w-[10%]  px-2 py-1 font-[500] border-r'>{d?.department?.department_name}</h6>
                              <h6 className='flex items-center text-[12px]  min-w-[10%] max-w-[10%]  px-2 py-1 font-[500] '>
                                <AiOutlineEdit onClick={()=>navigator(`/${path}/monthly_meeting_report/edit`,{state:d})} className='mr-2' />
                                {(roles?.includes('admin') || roles?.includes('delete_data')) &&
                                <AiOutlineDelete onClick={()=>{setselecteddata(d);setmodal(true)}} />}
                              </h6>
                          </div>))}
                     </div>}
                </div>

                </div> 
                
            </div>
        </div>   
    </div>
  )
}

export default FTSVerticalMonthlyMeetingReviewList