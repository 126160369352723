import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineAssignmentInd } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { TbDashboard } from "react-icons/tb";
import { RiFolderSharedLine } from "react-icons/ri";
import { MdOutlineAssignmentReturn } from "react-icons/md";
import { MdOutlineLeaderboard } from "react-icons/md";
import { TbPresentationAnalytics } from "react-icons/tb";
import { TbFileReport } from "react-icons/tb";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { MdOutlineAnalytics } from "react-icons/md";

function FTSLeadMenu() {

  const { pathname } = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 1]
  const roles = useSelector(state => state.Auth.roles)
  // const path1 = pathname.split('/')[1]

  const navigate = useNavigate();

  const menu = [
    { name: 'FTS Dashboard', icon: TbDashboard, path: '/fts_leads/dashboard', id: 1, color: '', roles: ['fts_admin'] },
    { name: 'FTS Leads', icon: MdOutlineAssignmentInd, path: '/fts_leads/list', id: 2, color: '', roles: ['fts_admin', 'fts_user'] },
    { name: 'Leads Shared', icon: RiFolderSharedLine, path: '/fts_leads/shared_by_us', id: 3, color: '', roles: ['fts_admin', 'fts_user'] },
    { name: 'Calls List', icon: TfiHeadphoneAlt, path: '/fts_leads/calls_list', id: 3, color: '', roles: ['fts_admin', 'fts_user'] },
    { name: 'Monthly Review', icon: IoCalendarNumberOutline, path: '/fts_leads/monthly_review', id: 3, color: '', roles: ['fts_admin', 'fts_user'] },
    { name: 'Leads Analytics', icon: MdOutlineAnalytics, path: '/fts_leads/leads_Analytics', id: 3, color: '', roles: ['fts_admin', 'fts_user'] },
  ]

  const menu1 = [
    { name: 'Vertical Analytics', icon: MdOutlineAnalytics, path: '/fts_leads/vertical_Analytics', id: 3, color: '', roles: ['hod', 'admin'] },
    { name: 'Vertical Leads', icon: MdOutlineLeaderboard, path: '/fts_leads/vertical_lead', id: 3, color: '', roles: ['hod', 'admin'] },
    // {name:'Contacts / Calls',icon:BsPhoneFlip,path:'/fts_leads/contacts',id:3,color:'',roles:[]},
    { name: 'Leads Shared', icon: RiFolderSharedLine, path: '/fts_leads/shared_to_fts', id: 3, color: '', roles: ['fts_lead_forwader', 'hod', 'fts_lead_handler'] },
    { name: 'Lead Recieved', icon: MdOutlineAssignmentReturn, path: '/fts_leads/lead_received_from_fts', id: 3, color: '', roles: [] },
  ]

  const menu2 = [
    { name: 'Report Dashboard', icon: TbPresentationAnalytics, path: '/fts/vertical_report_dashboard', id: 1, color: '', roles: [] },
    // {name:'Client List',icon:LuServer,path:'/fts/vertical_report',id:2,color:'',roles:[]},
    { name: 'Monthly Meeting', icon: TbFileReport, path: '/fts/monthly_meeting_report', id: 3, color: '', roles: [] },
  ]

  useEffect(() => {
    // if((roles?.includes('admin') || roles?.includes('hod') || roles?.includes('lead_handler') || roles?.includes('md_lead')) === false){
    //     navigate(-1)
    // }
    if (path === 'fts_leads') {
      if (roles?.includes('fts_user') && !roles?.includes('fts_admin')) {
        navigate('/fts_leads/list')
      } else if (roles?.includes('fts_admin')) {
        navigate('/fts_leads/dashboard')
      } else if (!roles?.includes('fts_admin') && !roles?.includes('fts_admin')) {
        navigate('/fts_leads/shared_to_fts')
      } else {
        navigate('/fts_leads/vertical_report_dashboard')
      }
    }
  }, [path])


  return (
    <div className='mr-0 min-h-screen max-h-sceen border-r min-w-44  w-44 max-w-44  px-2' >

      {/* {path !== 'daily_tasks'  && */}
      <div className='mb-4 pt-5'>

        {['fts_admin', 'fts_user']?.filter((f) => roles?.includes(f))?.length > 0 &&
          <div className='mb-5 border-b pb-5 '>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Basic Option</h6>
            {menu.map((m) => (
              <>
                {(m?.roles?.length === 0 || m?.roles?.filter((f) => roles?.includes(f))?.length > 0) &&
                  <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                    {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                    <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                    <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                  </div>
                }
              </>
            ))}
          </div>}

        {['fts_admin', 'fts_user']?.filter((f) => roles?.includes(f))?.length > 0 &&
          <div className='mb-5 border-b pb-3 '>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Meeting Review Option</h6>
            {menu2.map((m) => (
              <>
                {(m?.roles?.length === 0 || m?.roles?.filter((f) => roles?.includes(f))?.length > 0) &&
                  <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                    {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                    <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                    <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                  </div>
                }
              </>
            ))}
          </div>}

        {(!roles?.includes('fts_user') && !roles?.includes('fts_admin')) && <>
          <h6 className='ml-2 my-2 font-[700] text-[12px]'>More Option</h6>
          {menu1.map((m) => (
            <>
              {(m?.roles?.length === 0 || m?.roles?.filter((f) => roles?.includes(f))?.length > 0) &&
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                  {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                  <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>
              }
            </>
          ))}
        </>}





      </div>
    </div>
  )
}

export default FTSLeadMenu