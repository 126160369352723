import React,{useState,useEffect} from 'react'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { DatePicker, Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@material-ui/core';
import { AiOutlineEdit,AiOutlineDelete,AiOutlineFileExcel } from 'react-icons/ai';
import toast from 'react-hot-toast';
import axios from 'axios';
import fileDownload from "js-file-download";
import FinanceMenu from '../FinanceMenu';
import { DeleteConveyanceVochuerService, DownloadConveyanceVochuerService, GetConveyanceVochuerService, GetConveyanceVochuerService1 } from '../../../services/FinanceFormServices/ConveyanceVochuerServices';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ProfileMenu from '../../profile/ProfileMenu';

function ConveyanceVochuerList() {

  const navigator = useNavigate()
  const roles = useSelector(state=>state.Auth.roles)

  const {pathname} = useLocation()

  const path = pathname?.split('/')[1]

  console.log("path",path)

  const [modal,setmodal] = useState(false)
  const [step,setstep] = useState(1)
  const [page,setpage] = useState(1)
  const [data,setdata] = useState({})
  const [selecteddata,setselecteddata] = useState({})

  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})

  useEffect(()=>{
    getdata()
  },[step,page])

  async function getdata(){
   const response =  await GetConveyanceVochuerService(page,search?.text,search?.from_date1,search?.to_date1,step)
   setdata(response?.data)
  }

 
  async function applyFilter(){
    setsearch({...search,activate:true})
    setpage(1)
    if(path == 'finance'){
      const response =  await GetConveyanceVochuerService1(1,search?.text,search?.from_date1,search?.to_date1,step)
      setdata(response?.data)
    }else{
      const response =  await GetConveyanceVochuerService(1,search?.text,search?.from_date1,search?.to_date1,step)
      setdata(response?.data)
    }
  }

  async function resetfilter(){
    setsearch({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})
    setpage(1)
    if(path == 'finance'){
      const response =  await GetConveyanceVochuerService1(1,search?.text,search?.from_date1,search?.to_date1,step)
      setdata(response?.data)
    }else{
      const response =  await GetConveyanceVochuerService(1,search?.text,search?.from_date1,search?.to_date1,step)
      setdata(response?.data)
    }
  }

  async function deleteData(){
    const response = await DeleteConveyanceVochuerService(selecteddata?._id)
    if(response?.status === 200){
        toast.success("Deleted Successfully")
    }else{
        toast.success("Deleted Successfully")
    }
    setmodal(false)
    getdata()
  }

  async function downloaddata(){
    const response = await DownloadConveyanceVochuerService(1,search?.text,search?.from_date1,search?.to_date1,step)
    let path = response.data.path.replace('public/','')
    handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${path}`,path.split('/')[path.split('/')?.length - 1])

  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
        })
  }

  
  return (
    <div className='w-[98%] h-screen overflow-hidden'>

    <Modal
       keepMounted
       open={modal}
       onClose={()=>setmodal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setmodal(false)} />
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteData()}  /> 
           </div>
         </div>
       </div>
     </Modal>

        <div className='flex'>
            <div className={`min-w-44 ${path == 'profile' && 'min-w-[180px] pl-2'}`}>
              {path == 'profile' && <ProfileMenu />}
              {path == 'finance' && <FinanceMenu />}
            </div>
            <div className='w-[100%] pl-5 pt-4'>

            <div className='md:w-[100%] flex flex-wrap md:flex-nowrap items-center -mt-1 border-b justify-between pb-1'>
                <h6 className='font-[800] text-[13px]'>Total Conveyance Voucher  ({data?.pagination?.total})</h6>

                <div className='flex  items-center text-[12px]'>

                    <AiOutlineFileExcel onClick={()=>downloaddata()} size={24}  className='mx-2 bg-gray-200 p-1.5' />

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total}  </h6>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                    <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                    
                    <div className='hidden md:block mr-2'>
                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                    </div>  
                    <div className='mr-2 hidden md:block'>
                    <ButtonOutlinedAutoWidth btnName="Apply Filter" onClick={()=>applyFilter()} /> 
                    </div>
                    <div className='mr-2 hidden md:block'>
                    <ButtonOutlinedAutoWidth btnName="Reset Filter" onClick={()=>resetfilter()} /> 
                    </div>
                    
                    <ButtonFilledAutoWidth btnName="Add New" onClick={()=>navigator(`/${path}/conveyance_vochuer/create`)} />

                </div>
            </div>

          

            <div className='flex  items-center border-b'>
                <h6 onClick={()=>{setstep(1);setpage(1)}} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[90px] text-[11px]`}>Verified</h6>
                <h6 onClick={()=>{setstep(2);setpage(1)}} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Pending</h6>
           </div>

            <div className='overflow-x-hidden h-[90vh] mb-[50px] overflow-y-scroll'>
                            
                {data?.datas?.length === 0 &&
                <div className='grid place-items-center mt-20  items-center justify-center'>
                    <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                    <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                    <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
                </div>}

                <div className='h-[88vh] w-[100%] overflow-x-hidden overflow-y-scroll'>
                    {data?.datas?.length > 0 &&
                     <div className='max-h-[85vh] w-[100%] mt-4 border-t  overflow-x-hidden  border-l border-r overflow-y-scroll'>
                          <div className='sticky top-0 z-50 flex flex-wrap md:flex-nowrap  bg-white py-[-5px] border-b border-slate-200 relative'>
                              <h6  className='top-0 bg-white z-50 text-[12px] min-w-[5%]  max-w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>Sl No</h6>
                              <h6 className='sticky top-0 z-50  text-[12px]  min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r'>Bill Date</h6>
                              <h6 className='sticky top-0 z-50  text-[12px]  min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r'>Created By</h6>
                              <h6 className='sticky top-0 z-50  text-[12px] min-w-[15%] max-w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Vehicle Info</h6>
                              <h6 className='sticky top-0 z-50  text-[12px] min-w-[8%] max-w-[8%]  px-2 py-1 font-[600] text-slate-600 border-r'>Starting KM</h6>
                              <h6 className='sticky top-0 z-50  text-[12px] min-w-[8%] max-w-[8%]  px-2 py-1 font-[600] text-slate-600 border-r'>Ending KM</h6>
                              <h6 className='sticky top-0 z-50  text-[12px] min-w-[12%] max-w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r'>Bill No</h6>
                              <h6 className='sticky top-0 z-50  text-[12px] min-w-[12%] max-w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r'>Amount</h6>
                              <h6 className='sticky top-0 z-50  text-[12px] min-w-[15%] max-w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Bill Attachment</h6>
                              <h6 className='sticky top-0 z-50  text-[12px] min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 '>Actions</h6>
                          </div>
                          {data?.datas?.map((d,i)=>(
                          <div className='flex flex-wrap md:flex-nowrap bg-white py-[-5px] border-b border-slate-200 relative'>
                              <h6  className='bg-white text-[12px] min-w-[5%]  max-w-[5%]   px-2 py-1 font-[500] border-r flex items-center justify-center'>{(page > 1 ? i+1+ (data?.pagination?.limit * (page - 1)) : i+1 )}</h6>
                              <h6 className=' text-[12px] truncate min-w-[10%] max-w-[10%] px-2 py-1 font-[500] border-r'>{moment(d?.bill_date)?.format('LL')}</h6>
                              <h6 className=' text-[12px] truncate min-w-[10%] max-w-[10%] px-2 py-1 font-[500] border-r'>{d?.created_by?.name}</h6>
                              <h6 className=' text-[12px] truncate min-w-[15%] max-w-[15%]  px-2 py-1 font-[500] border-r'>{d?.vehicle_name}</h6>
                              <h6 className=' text-[12px] truncate  min-w-[8%] max-w-[8%]  px-2 py-1 font-[500] border-r'>{d?.starting_km}</h6>
                              <h6 className=' text-[12px] truncate  min-w-[8%] max-w-[8%] px-2 py-1 font-[500] border-r'>{d?.ending_km}</h6>
                              <h6 className=' text-[12px] truncate min-w-[12%] max-w-[12%]  px-2 py-1 font-[500] border-r'>{d?.bill_no}</h6>
                              <h6 className=' text-[12px] truncate min-w-[12%] max-w-[12%]  px-2 py-1 font-[500] border-r'>{d?.amount}</h6>
                              <h6 className=' text-[12px] truncate  min-w-[15%] max-w-[15%]  px-2 py-1 font-[500] border-r'>{d?.file !== '' && <a className='underline text-sky-600' target='_blank' href={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.file}`}>{d?.file?.split('/')[d?.file?.split('/')?.length - 1]}</a>}</h6>
                              <h6 className='flex items-center text-[12px]  min-w-[10%] max-w-[10%]  px-2 py-1 font-[500] '>
                                <AiOutlineEdit onClick={()=>navigator(`/${path}/conveyance_vochuer/edit`,{state:d})} className='mr-2' />
                                {(roles?.includes('admin') || roles?.includes('delete_data')) &&
                                <AiOutlineDelete onClick={()=>{setselecteddata(d);setmodal(true)}} />}
                              </h6>
                          </div>))}
                     </div>}
                </div>

                </div> 
                
            </div>
        </div>   
    </div>
  )
}

export default ConveyanceVochuerList