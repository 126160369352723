import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateInvoiceRequest, CreateLeadAttachmentFollowUpService, CreateLeadNoteService, CreateLeadReminderService, DeleteLeadAttachmentFollowUpService, GetLeadAttachmentFollowUpService, GetLeadNoteService, GetLeadReminderService, GetLeadsDetailService, UpdateImportantLeads, UpdateLeadAttachmentFollowUpService, createInspectionReview } from '../../services/LeadServices';
import moment from 'moment';
import { AiOutlineMail, AiOutlinePhone, AiOutlineEdit, AiOutlinePlus, AiOutlineContacts, AiOutlineCalendar, AiOutlineCloudDownload, AiOutlineSend, AiOutlineDelete, AiOutlineClose, AiOutlineClockCircle, AiOutlineDownload } from 'react-icons/ai';
import { MdOutlineMeetingRoom, MdClose, MdPayment, MdOutlineLandscape } from 'react-icons/md';
import { HiOutlineBuildingOffice } from 'react-icons/hi2';
import { RiBillLine, RiFilePaper2Line } from 'react-icons/ri';
import { RiUser6Line, RiWalkLine } from 'react-icons/ri';
import { VscInspect } from 'react-icons/vsc';
import LeadMenu from './LeadMenu';
import GoBack from '../../components/back/GoBack';
import { ButtonFilled, ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BsArrowRepeat } from 'react-icons/bs';
import fileDownload from "js-file-download";
import { LuAlarmClock } from 'react-icons/lu';
// import { LuCirclePlus } from "react-icons/lu";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { DatePicker, Select, TimePicker } from 'antd';
import { BiCheckbox, BiCheckboxSquare } from 'react-icons/bi';
import { MdOutlineRateReview } from 'react-icons/md';
import { RiChatFollowUpLine } from "react-icons/ri";
import axios from 'axios';
import { Modal } from 'antd';
import { GetInsentivePaymentsShareBasedOnLeadAndUser, GetInvoiceServiceDetailBasedOnClient, ReviseInvoiceUserRequest, UpdatePaymentInvoiceFinance } from '../../services/InvoiceServices';
import TextArea from 'antd/es/input/TextArea';
import { toast } from 'react-hot-toast';
import { TextInput } from '../../components/input';
import { TextAreaInput1 } from '../../components/input';
import Uploader from '../../components/Uploader';
import { useSelector } from 'react-redux';
import { TbMoneybag } from 'react-icons/tb';
import { GetStageService } from '../../services/StageServices';
import { FaLandMineOn } from "react-icons/fa6";
import { CreateDBRequirmentInfoService, GetDBRequirmentInfoBasedLeadIdService } from '../../services/database/DBrequirmentInfoServices';

function LeadDetail() {

  const user = useSelector(state => state.Auth)
  // console.log("user",user)
  const user_department = user?.department_id[0]?.department_name
  const location = useLocation();
  const navigate = useNavigate();
  const [leaddata, setleaddata] = useState({});
  const [data, setdata] = useState({})
  const [invoicepayments, setinvoicepayments] = useState([])
  const [followUps, setfollowUps] = useState([]);
  const [reminder, setreminder] = useState([]);
  const [notes, setnotes] = useState([]);
  const [followUpArr, setfollowUpArr] = useState([]);
  const [visible, setvisible] = useState(false)
  const [followupmodal, setfollowupmodal] = useState(false)
  const [invoiceModal, setinvoiceModal] = useState(false)
  const [paymentfollowup, setpaymentfollowup] = useState(false)

  const [requirmentModal, setrequirmentModal] = useState(false)
  const [requirmentInfoArr, setrequirmentInfoArr] = useState({ requirment: '', remarks: '', file: '', created_by: '' })
  const [requirmentInfo, setrequirmentInfo] = useState({ requirment: '', remarks: '', file: '', created_by: '' })
  const [requirmentInfoErr, setrequirmentInfoErr] = useState({ requirment: '', remarks: '', file: '', created_by: '' })


  const [inspection_review_modal, setinspection_review_modal] = useState(false)
  const [inspection_review, setinspection_review] = useState({ property_name: '', property_address: '', client: '', send_to_email: '', send_to_phone: '', client: '', question1: '', question2: '', question3: '', ratings: '' })
  const [inspection_review_error, setinspection_review_error] = useState({ send_to_email: '', send_to_phone: '', client: '', question1: '', question2: '', question3: '', ratings: '' })


  const [singleData, setsingleData] = useState({ followup_owner: '', department_id: '', contact_name: '', phone: '', email: '', designation: '', company_name: '', lead_source: '', industry: '', stage: '', website: '', requirment: '', address: '', message: '', important: '', slots: '', type: { label: 'New', value: 'New' } })
  const [error, seterror] = useState({ followup_owner: '', department_id: '', contact_name: '', phone: '', email: '', designation: '', company_name: '', lead_source: '', industry: '', stage: '', website: '', requirment: '', address: '', message: '', important: '', type: '', slots: '' })

  const [aggrementData, setagreementData] = useState({ reminder_active: '', agreement_expire: '', lock_in_period_end_date: '', lock_in_period_from_date: '', lease_years: '', lease_end_date: '' })
  const [aggrementDataErr, setagreementDataErr] = useState({ reminder_active: '', agreement_expire: '', lock_in_period_end_date: '', lock_in_period_from_date: '', lease_years: '', lease_end_date: '' })


  const [reviseInvoice, setreviseInvoice] = useState(false)
  const [paymentRecieved, setpaymentRecieved] = useState(false)
  const [invoiceSelected, setinvoiceSelected] = useState({})
  const [updateActivity, setupdateActivity] = useState(false)
  const [inspectionLoader, setinspectionLoader] = useState(false)

  const [selected_activity, setselected_Activity] = useState({})
  const [selected_activity_error, setselected_Activity_error] = useState({ summary: '' })
  const [reviseSummary, setreviseSummary] = useState('')
  const [stages, setstages] = useState([])

  const [payment, setpayment] = useState({ payment_recieved: false, amount_with_gst: '', amount_without_gst: '', recieved_date: '', payment: '0' })

  const [reminder_data, setreminder_data] = useState({ visible: false, title: '', date: '', time: '', date1: '', time1: '', summary: '' })
  const [reminder_data_err, setreminder_data_err] = useState({ visible: false, title: '', date: '', time: '', summary: '' })

  const [notes_data, setnotes_data] = useState({ visible: false, title: '', summary: '', file: '' })
  const [notes_data_err, setnotes_data_err] = useState({ visible: false, title: '', time: '', summary: '' })
  const [payment_share, setpayment_share] = useState({})
  const [invoiceData, setinvoiceData] = useState({ amount: '', other_details: '', invoice_raising_date: '', invoice_attachment_id: '', payment: '0', gst_no: '', pan_no: '', office_space_closed: '' })
  const [invoiceDataErr, setinvoiceDataErr] = useState({ amount: '', other_details: '', invoice_raising_date: '', invoice_attachment_id: '', payment: '0', gst_no: '', pan_no: '', office_space_closed: '' })


  const [repeatProcess, setrepeatProcess] = useState({ file: '', date: '', time: '', date1: '', time1: '', summary: '' })
  const [repeatProcessErr, setrepeatProcessErr] = useState({ file: '', date: '', time: '', summary: '' })

  const options = [{ label: 'New', value: 'New' }, { label: 'Old', value: 'Old' }]

  const [step, setstep] = useState(1)
  const [step1, setstep1] = useState(1)

  const slots = [
    { label: '09.00 AM - 11.00 AM', value: '09.00 AM - 11.00 AM' },
    { label: '11.00 AM - 01.00 PM', value: '11.00 AM - 01.00 PM' },
    { label: '02.00 PM - 04.00 PM', value: '02.00 PM - 04.00 PM' },
    { label: '04.10 PM - 10.00 PM', value: '04.00 PM - 10.00 PM' },
  ]


  useEffect(() => {
    getdetail()
    getrequirmentInfo()
    LeadFollowUp()
    getreminders()
    getnotes()
    getInvoiceService()
    getstages()
    getoptions()
  }, [])

  async function getstages() {
    if (user_department === 'Transaction Team') {
      const response = await GetStageService('Lead_Stage', user?.department_id[0]?.id)
      let arr = []
      response.data.data.datas.forEach((d) => {
        arr.push({ label: d?.name, value: d?._id })
      })
      setstages(arr)
    }
  }

  async function getrequirmentInfo() {
    const response = await GetDBRequirmentInfoBasedLeadIdService(location.state)
    setrequirmentInfoArr(response?.data)
  }

  async function getdetail() {
    const response = await GetLeadsDetailService(location.state)
    setleaddata(response.data.datas[0])
  }

  function returnfilename(v) {
    let string = v?.split("/")[v?.split("/")?.length - 1]
    return string;
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, filename)
        toast.success("Attachment Downloaded")
      })
  }

  function getFileSizeString(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSizeInBytes /= 1024;
      i++;
    } while (fileSizeInBytes > 1024);
    return Math.max(fileSizeInBytes, 0.1).toFixed(1).slice(0, 4) + byteUnits[i];
  }

  async function updateImportance(v) {
    let send_data = {
      '_id': v._id,
      'important': v?.important === true ? 0 : 1
    }
    const response = await UpdateImportantLeads(send_data)
    if (response.status === 200) {
      getdetail();
    }
  }

  // console.log("leadData",leaddata)
  async function createInvoiceRequest() {
    let send_data = {}
    send_data['leadId'] = leaddata?._id
    send_data['attachment_type'] = 'Lead'
    send_data['stage_type'] = leaddata?.stage?.name
    send_data['stage'] = leaddata?.stage?._id
    send_data['summary'] = ''
    send_data['type_id'] = leaddata?._id

    send_data['finalized'] = 0
    send_data['datetime'] = invoiceData?.invoice_raising_date !== undefined && moment(invoiceData?.invoice_raising_date)?.format('YYYY-MM-DD')
    send_data['amount'] = invoiceData?.amount?.replace(',', '')
    send_data['payment'] = invoiceData?.payment




    const response = await CreateLeadAttachmentFollowUpService(send_data)
    if (response.status === 201) {
      let send_Data = {}
      send_Data['client'] = leaddata?._id
      send_Data['invoice_attachment_id'] = response?.data?.datas?.id
      send_Data['employee'] = user?.id
      send_Data['invoice_raising_date'] = invoiceData?.invoice_raising_date
      send_Data['amount'] = invoiceData?.amount?.split(',')?.join('')
      send_Data['payment'] = invoiceData?.payment


      const response1 = await CreateInvoiceRequest(send_Data)
      if (response1.status === 201) {
        setinvoiceModal(false)
        setinvoiceData({ amount: '', other_details: '', invoice_raising_date: '', invoice_attachment_id: '', payment: '0' })
        setinvoiceDataErr({ amount: '', other_details: '', invoice_raising_date: '', invoice_attachment_id: '', payment: '0' })
        LeadFollowUp()
        // setModal(false)
        // setdata({...data,stage:stage})
        // seterror({...error,stage:''})
        // submitform(stage)
      }
    }
  }

  function handlechange1(e) {
    setinvoiceData({ ...invoiceData, [e.target.name]: e.target.value })
    setinvoiceDataErr({ ...invoiceDataErr, [e.target.name]: '' })
  }

  function handlechange2(e) {
    setinspection_review({ ...inspection_review, [e.target.name]: e.target.value })
    setinspection_review_error({ ...inspection_review_error, [e.target.name]: '' })
  }

  function handlechange3(e) {
    setrequirmentInfo({ ...requirmentInfo, [e.target.name]: e.target.value })
    setrequirmentInfoErr({ ...requirmentInfoErr, [e.target.name]: '' })
  }

  // console.log("selected_activity",selected_activity)

  async function createInspectionReviewForm() {
    if (!inspection_review.property_name) {
      setinspection_review_error({ ...inspection_review_error, property_name: 'This Field is required*' })
    } else if (!inspection_review.property_address) {
      setinspection_review_error({ ...inspection_review_error, property_address: 'This Field is required*' })
    } else if (!inspection_review.client) {
      setinspection_review_error({ ...inspection_review_error, client: 'This Field is required*' })
    } else if (!inspection_review.send_to_email) {
      setinspection_review_error({ ...inspection_review_error, send_to_email: 'This Field is required*' })
    } else if (!inspection_review.send_to_phone || inspection_review.send_to_phone.length !== 10) {
      setinspection_review_error({ ...inspection_review_error, send_to_phone: 'Enter a valid 10 digit phone no*' })
    } else {
      setinspectionLoader(true)
      let sendData = {
        ...inspection_review
      }
      sendData['lead'] = leaddata?._id
      sendData['leadFollowup'] = selected_activity?.id

      // console.log("sendData : ",sendData)
      const response = await createInspectionReview(sendData)
      if (response?.status === 201) {
        setinspectionLoader(false)
        toast.success(response?.data?.data)
        setinspection_review_modal(false)
        setinspection_review({ property_name: '', property_address: '', client: '', send_to_email: '', send_to_phone: '', client: '', question1: '', question2: '', question3: '', ratings: '' })
        setinspection_review_error({ property_name: '', property_address: '', client: '', send_to_email: '', send_to_phone: '', client: '', question1: '', question2: '', question3: '', ratings: '' })
      }
    }
  }

  async function LeadFollowUp() {
    const response = await GetLeadAttachmentFollowUpService(location.state)
    setfollowUps(response?.data?.datas)
  }


  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });


  async function getreminders() {
    const response = await GetLeadReminderService(location.state)
    setreminder(response.data.datas)
  }

  async function getnotes() {
    const response = await GetLeadNoteService(location.state)
    setnotes(response?.data?.datas)
  }

  async function getInvoiceService() {
    const response = await GetInvoiceServiceDetailBasedOnClient(location?.state)

    if (response?.data?.datas[0]?.invoicepayments?.length > 0 && response?.data?.datas[0]?.invoicepayments?.filter((f) => f?.shares_distributed === true)?.length > 0) {
      checkPaymentShareRecieved()
    }
    let payments = []
    response?.data?.datas?.forEach(e => {
      payments?.push(...e?.invoicepayments)
    });

    setinvoicepayments(payments)

    setdata(response?.data?.datas.filter((f) => f?.invoice_no !== undefined))
  }


  // console.log("invoicepayments",invoicepayments)

  const reviseInvoiceRequest = async () => {
    let selected_Data = { ...invoiceSelected }
    selected_Data['invoice_revise'] = true
    selected_Data['invoice_revise_summary'] = reviseSummary
    const response = await ReviseInvoiceUserRequest(invoiceSelected?._id, selected_Data)
    if (response.status === 200) {
      toast.success('Invoice revise request raised')
      setreviseInvoice(false)
      setreviseSummary('')
      getInvoiceService()
      // setselectedData(null)
      // getdata()
    }
  }

  const createPaymentRecieved = async () => {
    let selected_Data = {
      amount_with_gst: payment?.amount_with_gst?.split(',')?.join(''),
      amount_without_gst: payment?.amount_without_gst?.split(',')?.join(''),
      recieved_date: payment?.recieved_date,
      invoice: invoiceSelected?._id,
      client: invoiceSelected?.client?._id,
      payment: payment?.payment,
      payment_recieved: true,
    }

    const response = await UpdatePaymentInvoiceFinance(selected_Data, invoiceSelected?._id)
    // console.log("response here",response)
    if (response.status === 200) {
      setpayment({ visible: false, amount_with_gst: '', amount_without_gst: '', recieved_date: '', payment: '0' })
      setvisible(false)
      // set({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:''})
      toast.success(response?.data?.data)
      // setpaymentDate('')
      getInvoiceService()
    }
  }

  const createreminder = async () => {
    if (reminder_data.title === '') {
      setreminder_data_err({ title: 'This field is required*' });
    } else if (reminder_data.date === '') {
      setreminder_data_err({ date: 'This field is required*' });
    } else if (reminder_data.time === '') {
      setreminder_data_err({ time: 'This field is required*' });
    } else {
      let send_data = {}
      send_data['title'] = reminder_data.title
      send_data['summary'] = reminder_data.summary
      send_data['type'] = 'Lead'
      send_data['type_id'] = location.state
      send_data['description'] = reminder_data.summary
      send_data['repeat'] = "Once"
      send_data['notify'] = "Email"

      // console.log("data krishna",reminder_data)

      if (data?.date !== '') {
        send_data['on_date_time'] = reminder_data?.date1 !== undefined && moment(reminder_data?.date1)?.format('YYYY-MM-DD') + ' ' + reminder_data?.time1
      }

      // console.log("send_data",send_data)

      const response = await CreateLeadReminderService(send_data)
      // console.log("response",response)
      if (response.status === 200) {
        toast.success("Reminder created successfully")
        setreminder_data({ visible: false, title: '', date: '', time: '', summary: '' })
        setvisible(false)
        setreminder_data_err({ title: '', date: '', time: '', summary: '' })
        getreminders()
      }
    }
  }

  const createnotes = async () => {
    if (notes_data.title === '') {
      setnotes_data_err({ title: 'This field is required*' });
    } else if (notes_data.summary === '') {
      setnotes_data_err({ summary: 'This field is required*' });
    } else {
      let fd = new FormData()

      fd.append('note_title', notes_data.title)
      fd.append('summary', notes_data.summary)
      fd.append('type_id', location?.state)
      fd.append('note_type', 'Lead')

      console.log("notes_data.file", notes_data.file)

      if (notes_data.file !== '') {
        fd.append('file', notes_data?.file)
        fd.append('filesize', notes_data?.file?.size)
      }


      const response = await CreateLeadNoteService(fd)
      // console.log("response",response)
      if (response.status === 201) {
        toast.success("Notes created successfully")
        setnotes_data({ visible: false, title: '', summary: '', file: '' })
        setvisible(false)
        setnotes_data_err({ title: '', summary: '', file: '' })
        getnotes()
      }
    }
  }

  const onChange = (e) => {
    setreminder_data({ ...reminder_data, [e.target.name]: e.target.value })
    setreminder_data_err({ ...reminder_data_err, [e.target.name]: '' })
  }

  const onChange1 = (e) => {
    setnotes_data({ ...notes_data, [e.target.name]: e.target.value })
    setnotes_data_err({ ...notes_data_err, [e.target.name]: '' })
  }

  const onChange2 = (e) => {
    setselected_Activity({ ...selected_activity, [e.target.name]: e.target.value })
    setselected_Activity_error({ ...selected_activity_error, [e.target.name]: '' })
  }

  const updateSummaryForActivity = async () => {
    if (!selected_activity.summary) {
      setselected_Activity_error({ ...selected_activity_error, summary: 'This Field is required *' })
    } else {
      let send_data1 = {}
      send_data1['_id'] = selected_activity?.id
      send_data1['attachment_type'] = 'Lead'
      send_data1['stage_type'] = selected_activity?.stage_type
      send_data1['stage'] = selected_activity?.stage
      send_data1['summary'] = selected_activity?.summary
      send_data1['finalized'] = selected_activity?.finalized === true ? 1 : 0
      send_data1['type_id'] = selected_activity?.type_id

      const response = await UpdateLeadAttachmentFollowUpService(send_data1, selected_activity?.id)
      if (response.status === 200) {
        setselected_Activity({});
        setselected_Activity_error({})
        setupdateActivity(false)
        LeadFollowUp()
        toast.success('Followup Summary Updated*')
      }
    }
  }

  const checkPaymentShareRecieved = async () => {
    const response = await GetInsentivePaymentsShareBasedOnLeadAndUser(location.state, user.id)
    //  console.log("response",response?.data)
    setpayment_share(response?.data?.data)
  }

  const repeatProcessfunc1 = async () => {
    if (!repeatProcess.date) {
      setrepeatProcessErr({ ...repeatProcessErr, date: 'Date Field is required*' })
    } else if (!repeatProcess.time) {
      setrepeatProcessErr({ ...repeatProcessErr, time: 'Time Field is required*' })
    } else {

      let send_data1 = {}
      send_data1['attachment_type'] = 'Lead'
      send_data1['stage_type'] = stages?.find((f) => f?.label == 'Payment Collection') !== null ? stages?.find((f) => f?.label == 'Payment Collection')?.label : ''
      send_data1['stage'] = stages?.find((f) => f?.label == 'Payment Collection') !== null ? stages?.find((f) => f?.label == 'Payment Collection')?.value : ''
      send_data1['summary'] = ''
      send_data1['finalized'] = 0
      send_data1['type_id'] = leaddata?._id
      if (data?.date !== '') {
        send_data1['datetime'] = repeatProcess?.date1 + ' ' + repeatProcess?.time1
      }

      const response = await CreateLeadAttachmentFollowUpService(send_data1)
      if (response.status === 201) {
        LeadFollowUp()
        setpaymentfollowup(false)
        setrepeatProcess({ date: '', time: '', file: '' })
        setrepeatProcessErr({ date: '', time: '', file: '' })
        toast.success("Follow up added successfully")
      }

    }
  }

  const repeatProcessfunc = async () => {

    // console.log("Proposal Sent",)
    if (['Inspection', 'Landlord Meeting', 'Client Meeting', 'Meeting&Discussion', 'Follow Up']?.includes(leaddata.stage.name)) {
      if (!repeatProcess.date) {
        setrepeatProcessErr({ ...repeatProcessErr, date: 'Date Field is required*' })
      } else if (!repeatProcess.time) {
        setrepeatProcessErr({ ...repeatProcessErr, time: 'Time Field is required*' })
      } else {
        // console.log("repeatProcess",repeatProcess)
        let send_data1 = {}
        send_data1['attachment_type'] = 'Lead'
        send_data1['stage_type'] = leaddata?.stage?.name
        send_data1['stage'] = leaddata?.stage?._id
        send_data1['summary'] = ''
        send_data1['finalized'] = 0
        send_data1['type_id'] = leaddata?._id
        if (data?.date !== '') {
          send_data1['datetime'] = repeatProcess?.date1 + ' ' + repeatProcess?.time1
        }


        if (data?.file !== '') {
          send_data1['file'] = repeatProcess?.file
          send_data1['fileSize'] = repeatProcess?.file?.size
        }
        const response = await CreateLeadAttachmentFollowUpService(send_data1)
        if (response.status === 201) {
          LeadFollowUp()
          setrepeatProcess({ date: '', time: '', file: '' })
          setrepeatProcessErr({ date: '', time: '', file: '' })
          toast.success("Follow up added successfully")
        }
      }
    } else {
      if (!repeatProcess.file) {
        setrepeatProcessErr({ ...repeatProcessErr, file: 'File Field is required*' })
      } else if (leaddata.stage.name === 'LOI/Agreement' && aggrementData?.reminder_active && aggrementData?.lock_in_period === '') {
        setagreementDataErr({ ...aggrementDataErr, lock_in_period: 'File Field is required*' })
      } else if (leaddata.stage.name === 'LOI/Agreement' && aggrementData?.reminder_active && aggrementData?.agreement_expire === '') {
        setagreementDataErr({ ...aggrementDataErr, agreement_expire: 'File Field is required*' })
      } else {
        let send_data1 = {}
        send_data1['attachment_type'] = 'Lead'
        send_data1['stage_type'] = leaddata?.stage?.name
        send_data1['stage'] = leaddata?.stage?._id
        send_data1['summary'] = ''
        send_data1['task_type'] = singleData?.type?.label
        send_data1['finalized'] = 0
        send_data1['type_id'] = leaddata?._id
        if (data?.date !== '') {
          send_data1['datetime'] = repeatProcess?.date1 + ' ' + repeatProcess?.time1
        }
        if (data?.file !== '') {
          send_data1['file'] = repeatProcess?.file
          send_data1['fileSize'] = repeatProcess?.file?.size
        }

        if (aggrementData?.reminder_active) {
          send_data1['reminder_active'] = aggrementData?.reminder_active
          send_data1['agreement_expire'] = aggrementData?.agreement_expire
          send_data1['lock_in_period_from_date'] = aggrementData?.lock_in_period_from_date
          send_data1['lock_in_period_end_date'] = aggrementData?.lock_in_period_end_date
          send_data1['lease_years'] = aggrementData?.lease_years
          send_data1['lease_end_date'] = aggrementData?.lease_end_date
        }
        const response = await CreateLeadAttachmentFollowUpService(send_data1)
        if (response.status === 201) {
          LeadFollowUp()
          setrepeatProcess({ date: '', time: '', file: '' })
          setagreementData({ reminder_active: '', agreement_expire: '', lock_in_period_end_date: '', lock_in_period_from_date: '', lease_years: '', lease_end_date: '' })
          setagreementDataErr({ reminder_active: '', agreement_expire: '', lock_in_period_end_date: '', lock_in_period_from_date: '', lease_years: '', lease_end_date: '' })
          setrepeatProcessErr({ date: '', time: '', file: '' })
          toast.success("Follow up added successfully")
        }
      }
    }

  }

  async function deleteData(id) {
    const response = await DeleteLeadAttachmentFollowUpService(id)
    if (response.status === 200) {
      toast.success("Follow up deleted successfully")
      LeadFollowUp()
    }
  }

  async function addInspectionReviewForm(id) {
    setinspection_review_modal(!inspection_review_modal);
    setinspection_review({ ...inspection_review, client: leaddata?.contact_name, send_to_email: leaddata?.email, send_to_phone: leaddata?.phone, property_name: '', property_address: '', leadOptions: id })

  }

  async function getoptions() {
    const response = await GetStageService('FollowUp_Options')
    let arr = []
    response?.data?.data?.datas?.forEach((d) => {
      arr.push({ value: d?._id, label: d?.name })
    })
    setfollowUpArr(arr)
  }

  // console.log("singleData.slots",singleData)

  async function addfollowupfun() {

    if (singleData.stage === '') {
      seterror({ ...error, stage: 'This Field is required*' })
    } else if (singleData.slots === '') {
      seterror({ ...error, slots: 'This Field is required*' })
    } if (!singleData.description) {
      seterror({ ...error, description: 'This Field is required*' })
    } else {
      let send_data1 = {}
      send_data1['attachment_type'] = 'Lead'
      send_data1['slots'] = singleData?.slots
      send_data1['stage_type'] = singleData?.stage?.label
      send_data1['task_type'] = singleData?.type?.label
      send_data1['stage'] = singleData?.stage?.value
      send_data1['description'] = singleData?.description
      send_data1['summary'] = ''
      send_data1['finalized'] = 0
      send_data1['type_id'] = leaddata?._id
      const response = await CreateLeadAttachmentFollowUpService(send_data1)
      if (response.status === 201) {
        LeadFollowUp()
        setsingleData({ followup_owner: '', department_id: '', contact_name: '', phone: '', email: '', designation: '', company_name: '', lead_source: '', industry: '', stage: '', website: '', requirment: '', address: '', message: '', important: '', type: { label: 'New', value: 'New' } })
        seterror({ followup_owner: '', department_id: '', contact_name: '', phone: '', email: '', designation: '', company_name: '', lead_source: '', industry: '', stage: '', website: '', requirment: '', address: '', message: '', important: '', type: '' })
        toast.success("Follow up added successfully")
        setfollowupmodal(false)
      }

    }

  }



  async function handlechange(e) {
    setsingleData({ ...singleData, [e.target.name]: e.target.value })
  }

  function openfilefunc(v) {
    window.open(`${process.env.REACT_APP_AWS_IMAGE_URL}${v?.replace('public/', '')}`, '_blank')
  }

  async function requirmentInfoCreation() {
    if (!requirmentInfo?.requirment) {
      setrequirmentInfoErr({ ...requirmentInfoErr, requirment: "This Field is required*" })
    } else {
      if (requirmentInfo?._id === undefined) {
        let sendData = { ...requirmentInfo }
        sendData['created_by'] = user?.id
        sendData['lead_id'] = location?.state
        let response = await CreateDBRequirmentInfoService(sendData)
        if (response?.status === 201) {
          resetRequirmentInfo()
          setrequirmentModal(false)
          getrequirmentInfo()
          toast.success("DB Requirment Created Successfully*")
        }
      }
    }
  }

  function resetRequirmentInfo() {
    setrequirmentInfo({ requirment: '', remarks: '', file: '', created_by: '' })
    setrequirmentInfoErr({ requirment: '', remarks: '', file: '', created_by: '' })
  }


  // console.log("user",user?.roles?.includes('admin'),user?.department_id.filter((f)=>f?.department_name === 'Tranasction Team'))


  return (
    <div className='relative min-h-screen h-screen overflow-hidden max-h-screen'>

      <Modal open={requirmentModal} footer={null} className='relative absolute top-0 left-[0%]' closable={false} width={300}>
        <AiOutlineClose size={17} onClick={() => setrequirmentModal(false)} className='absolute cursor-pointer p-[4px] top-[10px] bg-gray-100 rounded right-[10px]' />
        <h6 className='font-[700] text-[13px]'>Create / Update a Requirment Request from DataBase Team</h6>
        <h6 className='text-[11px] bg-slate-100 p-[5px] lrading-[5px]'>Use the below form to create or update the requirment request for the DataBase Team </h6>

        <>

          <TextAreaInput1 value={requirmentInfo?.requirment} handlechange={handlechange3} mandatory={true} error={requirmentInfoErr?.requirment} name="requirment" label={"Requirment"} />


        </>

        <div className='flex items-center mt-2 border-t pt-2 flex items-center justify-end'>
          <ButtonOutlinedAutoWidth onClick={() => setrequirmentModal(false)} btnName="Cancel" />
          <h6 className='w-[10px]'></h6>
          <ButtonFilledAutoWidth onClick={() => requirmentInfoCreation()} btnName="Save" />
        </div>


      </Modal>

      <Modal open={followupmodal} footer={null} className='relative absolute top-0 left-[0%]' closable={false} width={300}>
        <AiOutlineClose size={17} onClick={() => setfollowupmodal(false)} className='absolute cursor-pointer p-[4px] top-[10px] bg-gray-100 rounded right-[10px]' />
        <h6 className='font-[700] text-[13px]'>Add The Follow Up for the Lead</h6>
        <h6 className='text-[11px] bg-slate-100 p-[5px] lrading-[5px]'>Use the below form to create or update the followup for the Lead </h6>


        {/* {state?.type  !== 'scouting'  && */}
        <>

          <h6 className='text-[11px] font-[600] mb-1 mt-2' >{'Stage'}</h6>
          <Select
            value={singleData?.stage}
            bordered={false}
            className='border w-[100%] border-l-4 border-l-slate-600 border-slate-300 h-[30px]'
            options={followUpArr}
            onChange={(e) => setsingleData({ ...singleData, stage: followUpArr?.find((f) => f?.value === e) })}

          />

          <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Slots'}</h6>
          <Select
            value={singleData?.slots}
            bordered={false}
            className='border w-[100%] border-l-4 border-l-slate-600 border-slate-300 h-[30px]'
            options={slots}
            onChange={(e) => setsingleData({ ...singleData, slots: e })}

          />

          <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Type'}</h6>
          <Select
            value={singleData?.type}
            bordered={false}
            className='border w-[100%] border-l-4 border-l-slate-600 border-slate-300 h-[30px]'
            options={options}
            onChange={(e) => setsingleData({ ...singleData, type: { label: e, value: e } })}

          />

          {/* <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Slots'}</h6>
            <Select
              value={singleData?.slots}
              bordered={false}
              className='border w-[100%] border-l-4 border-l-slate-600 border-slate-300 h-[30px]' 
              options={slots}
              onChange={(e)=>setsingleData({...singleData,slots:{label:e,value:e}})}
              
             /> */}

          {/* {JSON.stringify(singleData?.stage?.label)} */}

          {/* {singleData?.stage?.label  !== 'scouting' && <TextAreaInput1 value={singleData?.address} handlechange={handlechange} mandatory={(singleData?.stage?.label === 'walk_ins' || singleData?.stage?.label === 'property_visit' || singleData?.stage?.label === 'meeting')}  error={error?.address} name="address" label={singleData?.stage?.label === 'walk_ins' ? "Company Address" : singleData?.stage?.label === 'meeting' ? "Location" : "Property Address"} />} */}


          {(['Calls', 'Walk Ins', 'LOI Discussion', 'Agreement Discussion']?.filter((f) => f === singleData?.stage?.label)?.length === 0 && singleData?.stage?.label !== undefined) && <TextAreaInput1 value={singleData?.description} handlechange={handlechange} mandatory={(singleData?.stage?.label === 'Walk Ins' || singleData?.stage?.label === 'Property Visit' || singleData?.stage?.label === 'Meetings')} error={error?.address} name="description" label={singleData?.stage?.label === 'Walk Ins' ? "Company Address" : singleData?.stage?.label === 'Meetings' ? "Location" : singleData?.stage?.label === 'Scouting' ? "Searching For Which Property" : "Property Address"} />}
          {(['Calls', 'Walk Ins', 'LOI Discussion', 'Agreement Discussion']?.filter((f) => f === singleData?.stage?.label)?.length > 0 && singleData?.stage?.label !== undefined) && <TextAreaInput1 value={singleData?.description} handlechange={handlechange} mandatory={true} error={error?.address} name="description" label={"Remarks"} />}




          {/* <TextAreaInput1 value={singleData?.requirment} handlechange={handlechange}  error={error?.singleData} name="requirment" label={"Remarks"} /> */}


        </>
        {/* } */}


        <div className='flex items-center mt-2 border-t pt-2 flex items-center justify-end'>
          <ButtonOutlinedAutoWidth onClick={() => setfollowupmodal(false)} btnName="Cancel" />
          <h6 className='w-[10px]'></h6>
          <ButtonFilledAutoWidth onClick={() => addfollowupfun()} btnName="Save" />
        </div>
      </Modal>

      <Modal open={paymentfollowup} footer={null} className='relative' closable={false} width={300}>
        <MdClose className='absolute right-2 top-2' onClick={() => setpaymentfollowup(false)} />

        <h6 className="font-bold text-[15px]  mb-2 w-full">Add Payment Follow Up For this Client for today</h6>
        <div className='flex'>
          <div>
            <h6 className='text-[11px] font-[600] mb-1' >{'Date'}</h6>
            <div className='border mr-1 border-slate-300'>
              <DatePicker ampm={false} inputFormat="MM/dd/yyyy" placeholder='' className='border-none ' value={repeatProcess.date} onChange={(v, v1) => { setrepeatProcess({ ...repeatProcess, date: v, date1: v1 }); setrepeatProcessErr({ ...repeatProcessErr, date: '' }) }} />
            </div>
            {(repeatProcessErr?.date !== '' || repeatProcessErr?.date !== '') && <h6 className='text-red-600 text-[10px]'>{repeatProcessErr?.date}</h6>}
          </div>

          <div>
            <h6 className='text-[11px] font-[600] mb-1' >{'Time'}</h6>
            <div className='border ml-1  border-slate-300'>
              <TimePicker placeholder='' format="hh:mm" ampm={false} className='border-none' value={repeatProcess.time} onChange={(v, v1) => { setrepeatProcess({ ...repeatProcess, time: v, time1: v1 }); setrepeatProcessErr({ ...repeatProcessErr, time: '' }) }} />
            </div>
            {(repeatProcessErr?.time !== '' || repeatProcessErr?.time !== '') && <h6 className='text-red-600 text-[10px]'>{repeatProcessErr?.time}</h6>}
          </div>
        </div>

        {(repeatProcess?.date !== '' && repeatProcess?.time !== '') &&
          <div className='mt-4'>
            <ButtonFilled btnName={'Save FollowUp'} onClick={() => repeatProcessfunc1()} />
          </div>}


      </Modal>

      <Modal open={invoiceModal} footer={null} className='relative' closable={false} width={300}>
        <h6 className="font-bold text-[15px]  mb-2 w-full">Add Info to raise Invoice For Finance</h6>

        <h6 className='text-[11px] leading-tight bg-slate-100 p-1'>Please add the amount without gst and in description please add the details of the client like <span className='font-[700]'>[Billing To ,Gst No , other information related to invoice raise]</span></h6>


        <MdClose className='absolute right-2 top-2' onClick={() => setinvoiceModal(false)} />

        <div className='mb-2'>
          <TextInput
            mandatory={true}
            label={'Amount'}
            variant="standard"
            name="amount"
            type="text"
            value={invoiceData?.amount}
            error={invoiceDataErr.amount}
            handlechange={handlechange1} />
        </div>

        <div style={{ width: '100%' }}>
          <h6 className='text-[12px] font-[600] mb-1' >{'Invoice Raise Date'}</h6>
          <div
            className='border-l-4 border-l-slate-600 w-full border outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'

          >
            <DatePicker
              placeholder=''
              bordered={false}
              ampm={false}
              style={{ maxHeight: '40px', borderRadius: '3px', width: '100%' }}
              inputFormat="MM/dd/yyyy" value={invoiceData.invoice_raising_date} onChange={(v) => { setinvoiceData({ ...invoiceData, invoice_raising_date: v }); setinvoiceDataErr({ ...invoiceDataErr, invoice_raising_date: '' }) }} />
          </div>
          {(invoiceDataErr?.invoice_raising_date !== '') && <h6 className='text-red-600 text-[10px]'>{invoiceDataErr?.invoice_raising_date}</h6>}
        </div>

        <TextInput
          label={'Gst No'}
          variant="standard"
          name="gst_no"
          type="text"
          value={invoiceData?.gst_no}
          error={invoiceDataErr.gst_no}
          handlechange={handlechange1} />


        <TextInput
          label={'Pan No'}
          variant="standard"
          name="pan_no"
          type="text"
          value={invoiceData?.pan_no}
          error={invoiceDataErr.pan_no}
          handlechange={handlechange1} />

        <h6 className='text-[12px] font-[600] mb-1' >{'Office Space Closed'}</h6>
        <TextArea style={{ borderRadius: '2px' }} value={invoiceData.office_space_closed} onChange={(e) => setinvoiceData({ ...invoiceData, office_space_closed: e.target.value })} />


        <div className='flex items-center mt-2'>
          <div className='flex items-center mt-0 mr-2'>
            {invoiceData?.payment === '1' ? <BiCheckboxSquare size={22} className='text-slate-600' onClick={() => setinvoiceData({ ...invoiceData, payment: '' })} /> : <BiCheckbox size={22} className='text-slate-300' onClick={() => setinvoiceData({ ...invoiceData, payment: '1' })} />}

            {/* <input type={'checkbox'} checked={invoiceData?.payment === '1'} onChange={()=>setinvoiceData({...invoiceData,payment:'1'})} />  */}
            <h6 className="text-[13px] font-[600] ml-2">Cash</h6>
          </div>

          <div className='flex items-center mt-0 mr-2'>
            {invoiceData?.payment === '0' ? <BiCheckboxSquare size={22} className='text-slate-600' onClick={() => setinvoiceData({ ...invoiceData, payment: '' })} /> : <BiCheckbox size={22} className='text-slate-300' onClick={() => setinvoiceData({ ...invoiceData, payment: '0' })} />}
            {/* <input type={'checkbox'} checked={invoiceData?.payment === '0'} onChange={()=>setinvoiceData({...invoiceData,payment:'0'})} />  */}
            <h6 className="text-[13px] font-[600] ml-2">Online</h6>
          </div>
        </div>


        <h6 className='text-[12px] font-[600] mb-1' >{'Invoice Details'}</h6>
        <TextArea style={{ borderRadius: '2px' }} value={invoiceData.other_details} onChange={(e) => setinvoiceData({ ...invoiceData, other_details: e.target.value })} />

        {(invoiceData?.amount !== '' && invoiceData?.invoice_raising_date !== '' && invoiceData?.invoice_raising_date !== undefined && invoiceData?.invoice_raising_date !== null) &&
          <div className='mt-2'>
            <ButtonFilled btnName={'Confirm'} onClick={() => createInvoiceRequest()} />
          </div>
        }
      </Modal>

      <Modal open={inspection_review_modal} footer={null} className='relative' closable={false} width={300}>
        <h6 className="font-bold text-[15px]  mb-2 w-full">Share Inspection Review</h6>

        <h6 className='text-[11px] leading-tight bg-slate-100 p-1'>Please check the below info field before submitting because the following mobile no and email mentioned will recieve the notification in whatsapp and email!</h6>


        <MdClose className='absolute right-2 top-2' onClick={() => setinspection_review_modal(false)} />

        <div className='mb-2'>
          <TextInput
            mandatory={true}
            label={'Property Name'}
            variant="standard"
            name="property_name"
            type="text"
            value={inspection_review?.property_name}
            error={inspection_review_error.property_name}
            handlechange={handlechange2} />
        </div>

        <div className='mb-2'>
          <TextAreaInput1
            mandatory={true}
            label={'Property Address'}
            variant="standard"
            name="property_address"
            type="text"
            value={inspection_review?.property_address}
            error={inspection_review_error.property_address}
            handlechange={handlechange2} />
        </div>



        <TextInput
          label={'Client Name'}
          variant="standard"
          name="client"
          type="text"
          value={inspection_review?.client}
          error={inspection_review_error?.client}
          handlechange={handlechange2} />


        <TextInput
          label={'Send To Email'}
          variant="standard"
          name="send_to_email"
          type="text"
          value={inspection_review?.send_to_email}
          error={inspection_review_error?.send_to_email}
          handlechange={handlechange2} />


        <TextInput
          label={'Send To Phone'}
          variant="standard"
          name="send_to_phone"
          type="text"
          value={inspection_review?.send_to_phone}
          error={inspection_review_error?.send_to_phone}
          handlechange={handlechange2} />




        <div className='mt-2'>
          {inspectionLoader ?
            <ButtonFilled btnName={'Sending LinK'} />
            :
            <ButtonFilled btnName={'Confirm'} onClick={() => createInspectionReviewForm()} />}
        </div>
      </Modal>

      <Modal open={visible} footer={null} closable={false} width={300}>
        <div>

          {step1 === 2 &&
            <div>
              <h6 className='font-[800]'>Add Reminder</h6>
              <h6 className='text-[11px] leading-tight bg-slate-100 p-1'>Use the below form to create the reminder for the current leads</h6>

              <TextInput label="Title" name="title" value={reminder_data?.title} error={reminder_data_err?.title} handlechange={onChange} />
              <TextAreaInput1 label="Summary" name="summary" value={reminder_data?.summary} error={reminder_data_err?.summary} handlechange={onChange} />

              <div className='flex'>
                <div>
                  <h6 className='text-[11px] font-[600] mb-1' >{'Date'}</h6>
                  <div className='border mr-1 border-slate-300'>
                    <DatePicker ampm={false} inputFormat="MM/dd/yyyy" placeholder='' className='border-none ' value={reminder_data?.date} onChange={(v, v1) => { setreminder_data({ ...reminder_data, date: v, date1: v1 }); setreminder_data_err({ ...reminder_data_err, date: '' }) }} />
                  </div>
                  {(reminder_data_err?.date !== '' || reminder_data_err?.date !== '') && <h6 className='text-red-600 text-[10px]'>{reminder_data_err?.date}</h6>}
                </div>

                <div>
                  <h6 className='text-[11px] font-[600] mb-1' >{'Time'}</h6>
                  <div className='border ml-1  border-slate-300'>
                    <TimePicker placeholder='' format="hh:mm" ampm={false} className='border-none' value={reminder_data?.time} onChange={(v, v1) => { setreminder_data({ ...reminder_data, time: v, time1: v1 }); setreminder_data_err({ ...reminder_data_err, time: '' }) }} />
                  </div>
                  {(reminder_data_err?.time !== '' || reminder_data_err?.time !== '') && <h6 className='text-red-600 text-[10px]'>{reminder_data_err?.time}</h6>}
                </div>
              </div>

              <div className='flex justify-end mt-3'>
                <ButtonOutlinedAutoWidth btnName="Close" onClick={() => { setvisible(false); setreminder_data({ ...reminder_data, visible: false }) }} />
                <div className='ml-1'>
                  <ButtonFilledAutoWidth btnName="Save" onClick={() => createreminder()} />
                </div>
              </div>
            </div>}

          {step1 === 3 &&
            <div>
              <h6 className='font-[800]'>Add Notes</h6>
              <h6 className='text-[11px] leading-tight bg-slate-100 p-1'>Use the below form to create the notes for the current leads</h6>

              <TextInput label="Title" name="title" value={notes_data?.title} error={notes_data_err?.title} handlechange={onChange1} />
              <TextAreaInput1 label="Summary" name="summary" value={notes_data?.summary} error={notes_data_err?.summary} handlechange={onChange1} />

              <div>
                <h6 className='text-[11px] font-[600] mb-1' >{'Attachment'}</h6>
                <Uploader image={notes_data.file} setimagefunc={(v) => setnotes_data({ ...notes_data, file: v })} removeimageuploadfunc={() => setnotes_data({ ...notes_data, file: '' })} />
              </div>

              <div className='flex justify-end mt-3'>
                <ButtonOutlinedAutoWidth btnName="Close" onClick={() => { setvisible(false); setnotes_data({ ...notes_data, visible: false }) }} />
                <div className='ml-1'>
                  <ButtonFilledAutoWidth btnName="Save" onClick={() => createnotes()} />
                </div>
              </div>
            </div>}

        </div>

      </Modal>


      <div className='flex'>

        <LeadMenu />
        <div className='px-4 flex w-[88%]'>

          <div className='w-[25%] overflow-scroll relative min-h-screen max-h-screen '>

            <GoBack />
            <div className='grid place-items-center pt-4 -ml-4'>
              {/* <  */}
              {leaddata?.stage?.name !== 'Invoice Raised' && <span onClick={() => navigate(`/leads/contacts/edit`, { state: leaddata?._id })} className=' absolute top-1 right-0 text-slate-600 cursor-pointer font-[600] mr-2 px-1 py-1 text-[10px] flex items-center'><AiOutlineEdit size={13} className='mr-1.5' />  {'Edit'}</span>}
              <h6 className='bg-purple-300 w-20 h-20 p-10 text-[22px] flex items-center justify-center uppercase rounded-full'>{leaddata?.contact_name?.slice(0, 2)}</h6>
              <h6 className='text-[14px] mt-2 font-[900]'>{leaddata?.contact_name}</h6>
              <span className='text-[9.5px] font-[600] text-center'>{leaddata?.company_name}</span>
            </div>

            <div className='grid grid-cols-4 ml-[12%] place-items-center w-[70%] mt-2'>

              <CopyToClipboard text={leaddata?.email} onCopy={() => toast.success('Copied to clipboard')}>
                {/* <Tooltip title={leaddata?.email}> */}
                <div className='grid place-items-center cursor-pointer'>
                  <AiOutlineMail size={30} className='border  mx-1.5 rounded-full p-[7px] text-gray-700' />
                  <span className='text-[9px] text-gray-800 mt-1 font-[600] '>Email</span>
                </div>
                {/* </Tooltip> */}
              </CopyToClipboard>

              <CopyToClipboard text={leaddata?.phone} onCopy={() => toast.success('Copied to clipboard')}>
                {/* <Tooltip title={leaddata?.phone}> */}
                <div className='grid place-items-center cursor-pointer'>
                  <AiOutlinePhone size={30} className='border  mx-1.5 rounded-full p-[7px] text-gray-700' />
                  <span className='text-[9px] text-gray-800 mt-1 font-[600] '>Phone</span>
                </div>
                {/* </Tooltip> */}
              </CopyToClipboard>

              <CopyToClipboard text={leaddata?.industry?.name} onCopy={() => toast.success('Copied to clipboard')}>
                {/* <Tooltip title={leaddata?.industry?.name}> */}
                <div className='grid place-items-center cursor-pointer'>
                  <HiOutlineBuildingOffice size={30} className='border  mx-1.5 rounded-full p-[7px] text-gray-700' />
                  <span className='text-[9px] text-gray-800 mt-1 font-[600] '>Industry</span>
                </div>
                {/* </Tooltip> */}
              </CopyToClipboard>

              {/* <Tooltip title="Attachment"> */}
              <div className='grid place-items-center cursor-pointer' onClick={() => { setstep1(3); setvisible(true) }}>
                <AiOutlinePlus size={30} className='border  mx-1.5 rounded-full p-[7px] text-gray-700' />
                <span className='text-[9px] text-gray-800 mt-1 font-[600] '>Attachment</span>
              </div>
              {/* </Tooltip> */}

            </div>

            <div className='mt-2 w-[90%] ml-[3%]'>
              <ButtonFilled btnName={JSON.stringify(leaddata?.stage?.name)?.toUpperCase()?.slice(1, JSON.stringify(leaddata?.stage?.name)?.length - 1)} />
            </div>


            <h6 className='text-center text-[9px]  mt-2  '>Last Activity : <span className='font-[600]'> {leaddata?.updatedAt?._d !== undefined ? moment(leaddata?.updatedAt?._d)?.format('lll') : moment(leaddata?.updatedAt)?.format('lll')}</span></h6>

            <div className='flex items-center mr-5 mt-5 border-b'>
              <h6 onClick={() => setstep(1)} className={`text-[13px] cursor-pointer w-full text-center  pb-1 ${step === 1 && 'border-b-2 border-b-[#000] font-[700]'}`}>Leads Info</h6>
              <h6 onClick={() => setstep(2)} className={`text-[13px] cursor-pointer w-full text-center  pb-1 ${step === 2 && 'border-b-2 border-b-[#000] font-[700]'}`}>Address Info</h6>
            </div>

            {step === 1 &&
              <div className='mt-5'>
                {leaddata?.email &&
                  <>
                    <h6 className='text-[10px] mb-0 mt-2 font-[500] text-gray-400'>Email</h6>
                    <h6 className='text-[12px] mb-0 font-[600] '>{leaddata?.email}</h6>
                  </>}
                {leaddata?.phone &&
                  <>
                    <h6 className='text-[10px] mb-0 mt-2 font-[500] text-gray-400'>Mobile</h6>
                    <h6 className='text-[12px] mb-0 font-[600] '>{leaddata?.phone}</h6>
                  </>}
                {leaddata?.designation &&
                  <>
                    <h6 className='text-[10px] mb-0 mt-2 font-[500] text-gray-400'>Designation</h6>
                    <h6 className='text-[12px] mb-0 font-[600] '>{leaddata?.designation}</h6>
                  </>}
                {leaddata?.secondary_contact_person &&
                  <>
                    <h6 className='text-[10px] mb-0 mt-2 font-[500] text-gray-400'>Secondary Contact Person</h6>
                    <h6 className='text-[12px] mb-0 font-[600] '>{leaddata?.secondary_contact_person}</h6>
                  </>}
                {leaddata?.secondary_contact_email &&
                  <>
                    <h6 className='text-[10px] mb-0 mt-2 font-[500] text-gray-400'>Secondary Contact Email</h6>
                    <h6 className='text-[12px] mb-0  font-[600]'>{leaddata?.secondary_contact_email}</h6>
                  </>}
                {leaddata?.secondary_contact_phone &&
                  <>
                    <h6 className='text-[10px] mb-0 mt-2 font-[500] text-gray-400'>Secondary Contact Phone</h6>
                    <h6 className='text-[12px] mb-0  font-[600]'>{leaddata?.secondary_contact_phone}</h6>
                  </>}
                {leaddata?.requirment && <>
                  <h6 className='text-[10px] mb-0 mt-3 font-[500] text-gray-400'>Requirment</h6>
                  <h6 className='text-[12px] mb-0  font-[600] pr-2'>{leaddata?.requirment}</h6>
                </>}

                {leaddata?.visiting_card && <>
                  <h6 className='text-[10px] mb-0 mt-3 font-[500] text-gray-400'>Visiting Card</h6>
                  <h6 onClick={() => openfilefunc(leaddata?.visiting_card)} className='text-[11px] underline text-blue-500 cursor-pointer mb-0  font-[600] pr-2'>{leaddata?.visiting_card?.split('/')[leaddata?.visiting_card?.split('/')?.length - 1]}</h6>
                </>}

                <h6> {leaddata?.sqft && <span className='text-slate-800 bg-slate-300 mr-2 px-2 py-1 text-[10px]'>SQFT</span>} {leaddata?.seater && <span className='text-slate-800 bg-slate-300 mr-2 px-2 py-1 text-[10px]'>SEATER</span>} {leaddata?.rent && <span className='text-slate-800 bg-slate-300 mr-2 px-2 py-1 text-[10px]'>RENT</span>} {leaddata?.sale && <span className='text-slate-800 bg-slate-300 mr-2 px-2 py-1 text-[10px]'>SALE</span>}</h6>
              </div>}

            {step === 2 &&
              <div className='mt-5'>
                {leaddata?.company_name &&
                  <>
                    <h6 className='text-[10px] mb-0 mt-2 font-[500] text-gray-400'>Company Name</h6>
                    <h6 className='text-[12px] mb-0 font-[600] '>{leaddata?.company_name}</h6>
                  </>}
                {leaddata?.address &&
                  <>
                    <h6 className='text-[10px] mb-0 mt-2 font-[500] text-gray-400'>Address</h6>
                    <h6 className='text-[12px] mb-0 font-[600] '>{leaddata?.address}</h6>
                  </>}

                {leaddata?.industry &&
                  <>
                    <h6 className='text-[10px] mb-0 mt-2 font-[500] text-gray-400'>Contact Source</h6>
                    <h6 className='text-[12px] mb-0 uppercase '><span className='text-slate-800 bg-slate-300 mr-2 px-2 py-1 text-[10px]'>{leaddata?.lead_source?.name}</span></h6>
                  </>}

                {leaddata?.industry &&
                  <>
                    <h6 className='text-[10px] mb-0 mt-2 font-[500] text-gray-400'>Industry</h6>
                    <h6 className='text-[12px] mb-0 uppercase '><span className='text-slate-800 bg-slate-300 mr-2 px-2 py-1 text-[10px]'>{leaddata?.industry?.name}</span></h6>
                  </>}

              </div>}
          </div>

          <div className='w-[55%] mb-10  overflow-scroll  bg-gray-50  min-h-screen max-h-screen'>
            <div className='bg-gray-50 w-full z-50 sticky top-0  p-2'>
              <div className='flex w-[99.6%]  sticky top-2  rounded justify-evenly px-[4px]  py-[5px]   bg-slate-200 ' >

                <h6 onClick={() => setstep1(1)} className={`px-1.5 py-1.5 w-full text-center cursor-pointer font-[600] rounded text-[11px] ${step1 === 1 ? 'bg-white text-black font-[600]' : 'text-gray-500'}`}>Activity Done</h6>
                <h6 onClick={() => setstep1(2)} className={`px-1.5 py-1.5 w-full text-center cursor-pointer font-[600] rounded text-[11px] ${step1 === 2 ? 'bg-white text-black font-[600]' : 'text-gray-500'}`}>Reminder Added</h6>
                <h6 onClick={() => setstep1(3)} className={`px-1.5 py-1.5 w-full text-center cursor-pointer font-[600] rounded text-[11px] ${step1 === 3 ? 'bg-white text-black font-[600]' : 'text-gray-500'}`}>Notes Added </h6>

                {user_department === 'Transaction Team' &&
                  <>
                    <h6 onClick={() => setstep1(4)} className={`px-1.5 py-1.5 w-full text-center cursor-pointer font-[600] rounded text-[11px] ${step1 === 4 ? 'bg-white text-black font-[600]' : 'text-gray-500'}`}>Invoice Raised</h6>
                    <h6 onClick={() => setstep1(5)} className={`px-1.5 py-1.5 w-full text-center cursor-pointer font-[600] rounded text-[11px] ${step1 === 5 ? 'bg-white text-black font-[600]' : 'text-gray-500'}`}>Payment Received</h6>
                  </>}

              </div>
            </div>

            {console.log("followUps",followUps)}

            {step1 === 1 &&
              <div className='mx-2 z-20 pt-10 relative'>
                {(user?.roles?.includes('admin') || user?.department_id.filter((f) => f?.department_name === 'Transaction Team')?.length > 0) && <span onClick={() => { setfollowupmodal(true); setreminder_data({ ...reminder_data, visible: true }) }} className='absolute right-1 -top-0   rounded  bg-slate-300 flex shadow text-gray-900 items-center p-1 text-[11px] font-[700] cursor-pointer'> <RiChatFollowUpLine size={16} className='mr-1' /> Add Follow up</span>}
                <div className='mt-[0px]'>
                  {followUps?.map((f) => (
                    <>

                      {f?.stage_type === 'Contact' ?
                        <div className='bg-white rounded-md py-2 border border-slate-100'>
                          <div className='flex border-b border-slate-100 px-2 py-1 justify-between'>
                            <h6 className='flex items-center text-[13px] font-[600]'><AiOutlineContacts className='bg-slate-100 p-1 mr-2' size={24} /> Contact Created</h6>
                            <h6 className='flex items-center text-[10px] font-[600] text-gray-400'><AiOutlineCalendar size={14} className='mr-2 text-slate-700' /> {f?.createdAt !== undefined && moment(f?.createdAt)?.format('ll')}</h6>
                          </div>

                          <div className='border m-3 p-3 rounded border-slate-200'>
                            <h6 className='text-[11px] pl-1'>Company Name : <span className='font-[700]'>{leaddata?.company_name}</span></h6>
                            <h6 className='text-[11px] pl-1 border-b pb-2'>Company Address : <span className='font-[700]'>{leaddata?.address}</span></h6>

                            <h6 className='text-[11px] p-1 font-[800]'>Contact Info</h6>
                            <h6 className='text-[10px] ml-1'>Contact Name : <span className='font-[700]'>{leaddata?.contact_name}</span></h6>
                            <h6 className='text-[10px] ml-1'>Phone / Email : <span className='font-[700]'>{leaddata?.phone}</span> / <span className='font-[700]'>{leaddata?.email}</span></h6>
                          </div>

                        </div>
                        :
                        <div className='bg-white  z-50 mt-1 rounded-md py-2 border border-slate-100'>

                          <div className='flex border-b border-slate-100 px-2 py-1 justify-between'>
                            <h6 className='flex items-center text-[13px] font-[600]'>
                              {/* {f?.stage_type} */}
                              {f?.stage_type === 'Landlord Meeting' && <MdOutlineMeetingRoom className={`bg-slate-100 p-1 mr-2 `} size={24} />}
                              {/* {f?.stage_type === 'Calls' && <MdOutlineMeetingRoom className={`bg-slate-100 p-1 mr-2 `} size={24}  />} */}
                              {f?.stage_type === 'Inspection' || f?.stage_type === 'Follow Up' && <VscInspect className={`bg-slate-100 p-1 mr-2 `} size={24} />}
                              {f?.stage_type === 'LOI/Agreement' && <RiBillLine className={`bg-slate-100 p-1 mr-2 `} size={24} />}
                              {f?.stage_type === 'Calls' && <RiUser6Line className={`bg-slate-100 p-1 mr-2 `} size={24} />}
                              {f?.stage_type === 'Walk Ins' && <RiWalkLine className={`bg-slate-100 p-1 mr-2 `} size={24} />}
                              {f?.stage_type === 'Property Visit' && <FaLandMineOn className={`bg-slate-100 p-1 mr-2 `} size={24} />}
                              {f?.stage_type === 'Meetings' && <MdOutlineMeetingRoom className={`bg-slate-100 p-1 mr-2 `} size={24} />}
                              {f?.stage_type === 'LOI Discussion' && <RiFilePaper2Line className={`bg-slate-100 p-1 mr-2 `} size={24} />}
                              {f?.stage_type === 'Agreement Discussion' && <RiFilePaper2Line className={`bg-slate-100 p-1 mr-2 `} size={24} />}
                              {f?.stage_type === 'Scouting' && <MdOutlineLandscape className={`bg-slate-100 p-1 mr-2 `} size={24} />}
                              {f?.stage_type === 'Invoice Raised' && <RiFilePaper2Line className={`bg-slate-100 p-1 mr-2 `} size={24} />}
                              {f?.stage_type} <span className='text-[10px] ml-2 text-blue-500'> {f?.finalized && '( Finalized )'}</span>

                            </h6>
                            <h6 className='flex items-center text-[10px] font-[600] text-gray-400'><AiOutlineCalendar size={14} className='mr-2 text-slate-700' /> {f?.createdAt !== undefined && moment(f?.createdAt)?.format('ll')}</h6>
                          </div>




                          <div className='border m-3 p-3 rounded border-slate-200 relative'>

                            {f?.stage_type === 'Inspection' && <MdOutlineRateReview onClick={() => { setselected_Activity(f); addInspectionReviewForm(f?._id) }} size={20} className='absolute right-10 top-1.5 bg-slate-100 z-40 p-1 rounded-full' />}
                            <AiOutlineEdit size={20} className='absolute right-3 top-1.5 bg-slate-100 z-40 p-1 rounded-full' onClick={() => { setselected_Activity(f); setupdateActivity(true) }} />
                            {user?.roles?.includes('delete_follow_up_tracker') && <AiOutlineDelete size={20} className={`absolute ${(f?.file !== null && f?.file !== undefined && f?.file !== '') ? 'right-[69px]' : 'right-10'} top-1.5 bg-slate-100 z-40 p-1 rounded-full`} onClick={() => { deleteData(f?.id) }} />}
                            {(f?.file !== null && f?.file !== undefined && f?.file !== '') && <AiOutlineCloudDownload size={20} className='absolute right-10 top-1.5 bg-slate-100 p-1 z-40 rounded-full' onClick={() => handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${f?.file}`, returnfilename(f?.file))} />}




                            {(f?.slots !== '' && f?.slots !== undefined) && <h6 className='text-[11px]  '>Slots  : <span className='font-[700]'>{f?.slots}</span></h6>}

                            {(f?.description !== null && f?.description !== undefined) && ['Calls', 'Walk Ins', 'Property Visits', 'Meetings', 'LOI Discussion', 'Agreement Discussion', 'Scouting', 'Followup'] &&
                              <>
                                <h6 className='text-[11px] pb-[5px]'>Description : <span className='font-[700]'> {f?.description}</span></h6>
                                <h6 className='text-[11px] border-b pb-2'>Summary  : <span className='font-[700]'>{f?.summary === '' ? 'No Summary Added Yet' : f?.summary}</span></h6>
                              </>}

                            {['Landlord Meeting', 'Meeting&Discussion', 'Inspection', 'Client Meeting', 'Closed', 'Contact In Future', 'Lost', 'Hold', 'Assesment', 'Negotiation', 'Final Closure', 'BD', 'Follow Up', 'Payment Collection', 'Converted']?.includes(f?.stage_type) &&
                              <>
                                {f?.datetime !== undefined && <h6 className='text-[11px] pl-1'>{f?.stage_type === 'Inspection' && 'Inspection'} {f?.stage_type === 'Landlord Meeting' && 'Meeting'} {f?.stage_type === 'Client Meeting' && 'Client Meeting'}   Date Time : <span className='font-[700]'>{f?.datetime !== undefined && moment(f?.datetime)?.format('lll')}</span></h6>}
                                <h6 className='text-[11px] pl-1 border-b pb-2'>Summary  : <span className='font-[700]'>{f?.summary === '' ? 'No Summary Added Yet' : f?.summary}</span></h6>
                              </>}

                            {(f?.file !== null && f?.file !== undefined && f?.file !== '') &&
                              <>
                                <div className='ml-1  flex relative py-1'>
                                  <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 object-contain' />
                                  <div className='ml-1 -mt-0.5'>
                                    <h6 className='font-[600] text-[11px]'>{returnfilename(f?.file)}</h6>
                                    <h6 className='font-[600] text-[9px] -mt-0.5'>{getFileSizeString(f?.fileSize)}</h6>
                                  </div>

                                </div>

                                {f?.stage_type === 'LOI/Agreement' && f?.reminder_active &&
                                  <div>
                                    <h6 className='text-[10px] ml-1'>Agreement Expire : <span className='font-[700]'>{f?.agreement_expire}</span> </h6>
                                    <h6 className='text-[10px] ml-1'>Lease Years : <span className='font-[700]'>{f?.lease_years}</span> </h6>
                                    <h6 className='text-[10px] ml-1'>Lock In Period  : <span className='font-[700]'>{f?.lock_in_period_from_date?.slice(0, 10)} / {f?.lock_in_period_end_date?.slice(0, 10)}</span> </h6>
                                    <h6 className='bg-green-400 font-[700] px-2 py-1 w-max text-[10px]'>Reminder Follow Up Enabled</h6>
                                  </div>}
                                {f?.stage_type === 'Invoice Raised' && user_department == "Transaction Team" &&
                                  <>
                                    <h6 className='text-[11px] pl-1 '>Amount  : <span className='font-[700]'>{rupee.format(f?.amount)}</span></h6>
                                    <h6 className='text-[11px] pl-1 '>Raised Request Date  : <span className='font-[700]'>{f?.datetime !== undefined && moment(f?.datetime)?.format('lll')}</span></h6>
                                  </>}
                                <h6 className='text-[11px] pl-1 border-b pb-2'>Summary  : <span className='font-[700]'>{f?.summary === '' ? 'No Summary Added Yet' : f?.summary}</span></h6>

                              </>
                            }



                            {(f?.file === '' && f?.stage_type === 'Invoice Raised') &&
                              <>
                                <h6 className='text-[11px] pl-1 '>Amount  : <span className='font-[700]'>{rupee.format(f?.amount)}</span></h6>
                                <h6 className='text-[11px] pl-1 '>Raised Request Date  : <span className='font-[700]'>{f?.datetime !== undefined && moment(f?.datetime)?.format('lll')}</span></h6>
                                <h6 className='text-[11px] pl-1 border-b pb-2 mt-1'>Status  : <span className='text-[11px] font-[700] bg-slate-100 p-1'>{'Waiting For Invoice'}</span></h6>

                              </>


                            }
                            {/* <h6 className='text-[11px] p-1 font-[800]'>Created By</h6> */}
                            <h6 className='text-[11px] mt-1.5 flex items-center z-50'><span className='bg-slate-100 p-2 h-5 flex rounded-full items-center font-[700]' >{f?.user_id?.name?.slice(0, 1)}</span> <span className='font-[700] ml-1'>{f?.user_id?.name} / {f?.user_id?.employee_id}</span></h6>
                          </div>



                        </div>}
                    </>
                  ))}
                </div>

              </div>
            }

            {step1 === 2 &&
              <div className='mx-2 z-20 relative'>
                <span onClick={() => { setvisible(true); setreminder_data({ ...reminder_data, visible: true }) }} className='absolute right-1 -top-10   rounded  bg-slate-300 flex shadow text-gray-900 items-center p-1 text-[11px] font-[700] cursor-pointer'> <LuAlarmClock size={16} className='mr-1' /> Add Reminder</span>

                <div className='grid grid-cols-2 gap-1 mt-10'>
                  {reminder?.map((r) => (
                    <div className='bg-white p-2'>
                      <h6 className='text-[11px]'>Title : <span className='font-[600] text-[11px]'>{r?.title}</span> </h6>
                      <h6 className='text-[11px]'>Summary : <span className='font-[500] text-[11px]'>{r?.description}</span> </h6>
                      <h6 className='text-[11px] pb-2 border-b'>Date Time : <span className='font-[600] text-[11px]'>{r?.on_date_time !== undefined && moment(r?.on_date_time)?.format('lll')}</span> </h6>
                      <h6 className='text-[11px] mt-1.5 flex items-center z-50'><span className='bg-slate-100 p-2 h-5 flex rounded-full items-center font-[700]' >{r?.user_id?.name?.slice(0, 1)}</span> <span className='font-[700] ml-1'>{r?.user_id?.name} / {r?.user_id?.employee_id}</span></h6>
                    </div>
                  ))}
                </div>

                {reminder?.length === 0 &&
                  <div className='grid place-items-center mt-20  items-center justify-center'>
                    <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' />
                    <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                    <h6 className='font-[500] w-[80%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any particual reminder added for this leads.</h6>
                  </div>}
              </div>
            }

            {step1 === 3 &&
              <div className='mx-2 z-20 relative'>
                <span onClick={() => { setvisible(true); setreminder_data({ ...reminder_data, visible: true }) }} className='absolute right-1 -top-10   rounded  bg-slate-300 flex shadow text-gray-900 items-center p-1 text-[11px] font-[700] cursor-pointer'> <RiBillLine size={16} className='mr-1' /> Add Notes</span>

                {/* <span className='absolute'>Total Notes</span> */}

                <div className='grid grid-cols-2 gap-1 mt-10'>
                  {notes?.map((r) => (
                    <div className='bg-white p-2'>
                      <h6 className='text-[11px]'>Title : <span className='font-[600] text-[11px]'>{r?.note_title}</span> </h6>
                      <h6 className='text-[11px]'>Summary : <span className='font-[400] text-[11px]'>{r?.summary}</span> </h6>
                      {(r?.note_file !== null && r?.note_file !== '' && r?.note_file !== undefined) &&
                        <>
                          <div className='ml-1 mt-1 border-slate-100  flex relative py-1'>
                            <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 object-contain' />
                            <div className='ml-1 -mt-0.5'>
                              <h6 className='font-[600] text-[11px]'>{returnfilename(r?.note_file)}</h6>
                              <h6 className='font-[600] text-[9px] -mt-0.5'>{getFileSizeString(r?.filesize)} </h6>
                            </div>
                            {(r?.note_file !== null && r?.note_file !== undefined && r?.note_file !== '') && <AiOutlineCloudDownload size={23} className='absolute right-1 top-1.5 bg-slate-100 p-1 rounded-full' onClick={() => handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${r?.note_file}`, returnfilename(r?.note_file))} />}

                          </div>
                        </>}

                      <h6 className='text-[11px] mt-1.5 border-t pt-2 border-slate-100 flex items-center z-50'><span className='bg-slate-100 p-2 h-5 flex rounded-full items-center font-[700]' >{r?.user_id?.name?.slice(0, 1)}</span> <span className='font-[700] ml-1'>{r?.user_id?.name} / {r?.user_id?.employee_id}</span></h6>
                      <h6 className='text-[11px] mt-1.5 border-t pt-2 border-slate-100 flex items-center z-50'><span className='bg-slate-100 p-2 h-5 flex rounded-full items-center font-[700]' >Created At : </span> <span className='font-[700] ml-1'>{r?.createdAt !== undefined && moment(r?.createdAt)?.format('lll')}</span></h6>
                    </div>
                  ))}
                </div>

                {notes?.length === 0 &&
                  <div className='grid place-items-center mt-20  items-center justify-center'>
                    <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' />
                    <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                    <h6 className='font-[500] w-[80%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any particual notes added for this leads.</h6>
                  </div>}
              </div>
            }

            {/* && data?.invoice_file !== null && data?.invoice_file !== undefined && data?.invoice_file !== '' */}
            {(step1 === 4) &&

              <>
                {data?.length > 0 &&
                  <div className='relative'>
                    {user_department === 'Transaction Team' && <span onClick={() => { setpaymentfollowup(true); }} className={`absolute ${invoicepayments?.filter(f => f?.finance_approved == true)?.length > 0 ? 'right-28' : 'right-4'} -top-10   rounded  bg-slate-300 flex shadow text-gray-900 items-center p-1 text-[11px] font-[700] cursor-pointer`}> <TbMoneybag size={16} className='mr-1' /> Payment Follow Up</span>}

                    {invoicepayments?.filter(f => f?.finance_approved == true)?.length > 0 &&
                      <>
                        <span onClick={() => { setinvoiceModal(true); }} className='absolute right-4 -top-10   rounded  bg-slate-300 flex shadow text-gray-900 items-center p-1 text-[11px] font-[700] cursor-pointer'> <RiBillLine size={16} className='mr-1' /> New Invoice</span>
                      </>}


                    {data?.map((d, i) => (
                      <div className='relative'>
                        <div className={`mx-2 ${i === 0 ? 'mt-10' : 'mt-2'} z-20 w-[97%] bg-white p-2 rounded-md relative`}>
                          {(d?.invoice_file !== null && d?.invoice_file !== undefined && d?.invoice_file !== '') && <AiOutlineCloudDownload size={24} className='absolute right-2 z-50 top-1.5 bg-slate-100 p-1 rounded-full' onClick={() => handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.invoice_file}`, returnfilename(d?.invoice_file))} />}
                          {(!d?.invoice_revise) && <BsArrowRepeat onClick={() => { setreviseInvoice(true); setinvoiceSelected(d) }} size={24} className='absolute right-12 z-50 top-1.5 bg-slate-100 p-1 rounded-full' />}
                          {(!d?.invoice_revise) && <MdPayment onClick={() => { setpaymentRecieved(true); setinvoiceSelected(d) }} size={24} className='absolute right-[87px] z-50 top-1.5 bg-slate-100 p-1 rounded-full' />}

                          {(d?.invoice_file !== null && d?.invoice_file !== undefined && d?.invoice_file !== '') &&
                            <>

                              <div className='ml-1 mt-1 border-slate-100  flex relative py-1'>
                                <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 object-contain' />
                                <div className='ml-1 -mt-0.5'>
                                  <h6 className='font-[600] text-[11px] w-[95%]'>{returnfilename(d?.invoice_file)}</h6>
                                  {/* <h6 className='font-[600] text-[9px] -mt-0.5'>{getFileSizeString(data?.invoice_file)}</h6> */}
                                </div>
                              </div>
                              <h6 className='text-[11px] pl-1  '>Amount  : <span className='font-[700]'>{rupee.format(d?.amount)}</span></h6>
                              <h6 className='text-[11px] pl-1 border-b pb-2 border-slate-100'>Raised Request Date  : <span className='font-[700]'>{d?.datetime !== undefined && moment(d?.datetime)?.format('LL')}</span></h6>
                              {d?.invoice_revise && <h6 className='text-[11px] p-1 mt-1 bg-slate-100'>Revise request  : <span className='font-[700]'>{d?.invoice_revise_summary}</span></h6>}

                              {/* {data?.stage_type === 'Invoice Raised' && */}
                              <>
                                <h6 className='text-[11px]  mt-2 pl-1 font-[800]'>Employee Info</h6>
                                <span className='font-[700] text-[11px] p-1 bg-slate-100'>{d?.employee?.name} / {d?.employee?.employee_id}</span>

                              </>

                              <>
                                <h6 className='text-[11px] border-t pt-2 border-slate-100 mt-2 pl-1 font-[800]'>Finance Info</h6>
                                <h6 className='text-[11px] pl-1  '>Invoice No  : <span className='font-[700]'>{d?.invoice_no}</span></h6>
                                <h6 className='text-[11px] pl-1  '>Invoice Amount Incl GST  : <span className='font-[700]'>{rupee.format(d?.invoice_amount_with_gst)?.split('.')[0]}</span></h6>
                                <h6 className='text-[11px] pl-1 '>Invoice Amount Excl GST  : <span className='font-[700]'>{rupee.format(d?.invoice_amount_without_gst)?.split('.')[0]}</span></h6>
                                {(d?.gst_no !== null && d?.gst_no !== undefined) && <h6 className='text-[11px] pl-1 '>Gst No : <span className='font-[700]'>{d?.gst_no}</span></h6>}
                                {(d?.pan_no !== null && d?.pan_no !== undefined) && <h6 className='text-[11px] pl-1 '>Pan No : <span className='font-[700]'>{d?.pan_no}</span></h6>}
                                {(d?.office_space_closed !== null && d?.office_space_closed !== undefined) && <h6 className='text-[11px] pl-1 '>Office Space Closed : <span className='font-[700]'>{d?.office_space_closed}</span></h6>}
                                {d?.description !== '' && <h6 className='text-[11px] p-1 mt-1 bg-slate-100'>Description  : <span className='font-[700]'>{d?.description}</span></h6>}

                              </>
                              {/* } */}

                            </>
                          }
                        </div>
                      </div>))}
                  </div>}
              </>
            }

            {step1 === 5 &&
              <>
                {invoicepayments?.length === 0 &&
                  <div className='grid place-items-center mt-20  items-center justify-center'>
                    <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' />
                    <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                    <h6 className='font-[500] w-[90%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any payment  added for this leads.</h6>
                  </div>}
                <div className='grid grid-cols-2 gap-1 mx-2'>



                  {invoicepayments?.map((f) => (
                    <div className='z-20  bg-white p-2 rounded-md overflow-hidden relative'>
                      <>


                        <h6 className='text-[11px]'>Amount With Gst  : <span className='font-[700]'>{rupee.format(f?.amount_with_gst)?.split('.')[0]}</span></h6>
                        <h6 className='text-[11px]'>Amount Without Gst : <span className='font-[700]'>{rupee.format(f?.amount_without_gst)?.split('.')[0]}</span></h6>
                        <h6 className='text-[11px]'>TDS Deduction : <span className='font-[700]'>{f?.tds_deduction !== undefined ? rupee.format(f?.tds_deduction)?.split('.')[0] : rupee.format(0)?.split('.')[0]}</span></h6>
                        <h6 className='absolute top-[-10px] right-[2px] p-[2px]  '> <span className='text-[8px] font-[700]'>Mode : {f?.payment === '0' ? 'ONLINE' : 'CASH'}</span></h6>

                        <h6 className='text-[11px] mt-1'>Finance Approved : <span className={`font-[600] ${f?.is_deleted ? 'bg-red-100' : 'bg-slate-100'} p-1`}>{f?.is_deleted ? 'Deleted  : ' + f?.deleted_reason : f?.finance_approved ? 'Approved' : 'Waiting For Approval'}</span></h6>
                        {(f?.finance_approved && f?.deleted_reason !== '') && <h6 className='text-[11px] mt-2'>Remarks : <span className={`font-[600] `}>{f?.deleted_reason}</span></h6>}
                        <h6 className='text-[11px] mt-2'>Created At : <span className='font-[600] bg-slate-100 p-1'>{f?.createdAt !== undefined && moment(f?.createdAt)?.format('lll')}</span></h6>



                      </>

                    </div>))}
                </div>
              </>

            }



          </div>

          <div className='min-w-[24%] max-w-[24%]  bg-white p-2 no-scrollbar  min-h-screen max-h-screen overflow-y-scroll'>
            <div className='border-b border-slate-100 pb-2'>
              <h6 className='text-[13px] font-[700]'>Company</h6>
              <div className='flex px-2 pt-4'>
                <h6 className='bg-slate-300 w-8 h-8 p-1 text-[10px] flex items-center justify-center uppercase rounded-full'>{leaddata?.company_name?.slice(0, 1)}</h6>
                <div className='ml-2'>
                  <h6 className='text-[12px] font-[600]'>{leaddata?.company_name}</h6>
                  <h6 className='text-[10px] -mt-0.5'>{leaddata?.email}</h6>
                </div>


              </div>
              <div className='mx-3 border mt-2 p-3 rounded border-dotted border-slate-200'>
                <h6 className='flex items-center text-gray-300 mb-1'><AiOutlineMail /> <span className='text-[11px] ml-2 font-[600] text-black'>{leaddata?.email}</span></h6>
                <h6 className='flex items-center text-gray-300'><AiOutlinePhone /> <span className='text-[11px] ml-2 font-[600] text-black'>{leaddata?.phone}</span></h6>
              </div>
              <div>
              </div>

            </div>


            {payment_share?.length > 0 &&
              <div className='border-b pb-2'>
                <h6 className='text-[13px] font-[700] mt-2'>Payment Share</h6>
                <h6 className='bg-slate-100 mt-1 bg-salte-700 p-2 leading-tight text-[10px]'>Congrats you have recieved an payment share working on this lead!. </h6>
                <h6 className='text-[12px] mt-1 font-[500]'>Amount : <span className='font-[700]'> {rupee?.format(payment_share[0]?.amount)}</span> [ percentage : <span className='font-[700]'>{payment_share[0]?.percentage} %</span> ]</h6>

                <div>
                  <h6 onClick={() => { navigate('/leads/contacts/create', { state: { type: 'convert', data: leaddata } }) }} className='text-[10px] cursor-pointer text-slate-600 font-[600] text-center border p-2 mt-2 rounded'>Create a New Business</h6>
                </div>
              </div>}

            {

              (!['Invoice Raised', 'Contact', 'Assesment', 'Negotiation', 'Final Closure', 'Lost', 'Hold', 'Execution', 'Closed', 'Contact In Future', 'Waiting For Requirment', 'Converted']?.includes(leaddata?.stage?.name)) &&
              <div>
                <h6 className='text-[13px] font-[700] mt-2'>Repeat Process {leaddata?.stage?.name}</h6>

                <h6 className='bg-slate-100 mt-1 bg-salte-700 p-2 leading-tight text-[10px]'>Use the below form to update the repeated work made by you on the same stage. </h6>


                <div className='mt-2'>
                  {['Inspection', 'Landlord Meeting', 'Follow Up', 'Client Meeting', 'Meeting&Discussion']?.includes(leaddata?.stage?.name) &&
                    <div className='flex'>
                      <div>
                        <h6 className='text-[11px] font-[600] mb-1' >{'Date'}</h6>
                        <div className='border mr-1 border-slate-300'>
                          <DatePicker ampm={false} inputFormat="MM/dd/yyyy" placeholder='' className='border-none ' value={repeatProcess.date} onChange={(v, v1) => { setrepeatProcess({ ...repeatProcess, date: v, date1: v1 }); setrepeatProcessErr({ ...repeatProcessErr, date: '' }) }} />
                        </div>
                        {(repeatProcessErr?.date !== '' || repeatProcessErr?.date !== '') && <h6 className='text-red-600 text-[10px]'>{repeatProcessErr?.date}</h6>}
                      </div>

                      <div>
                        <h6 className='text-[11px] font-[600] mb-1' >{'Time'}</h6>
                        <div className='border ml-1  border-slate-300'>
                          <TimePicker placeholder='' format="hh:mm" ampm={false} className='border-none' value={repeatProcess.time} onChange={(v, v1) => { setrepeatProcess({ ...repeatProcess, time: v, time1: v1 }); setrepeatProcessErr({ ...repeatProcessErr, time: '' }) }} />
                        </div>
                        {(repeatProcessErr?.time !== '' || repeatProcessErr?.time !== '') && <h6 className='text-red-600 text-[10px]'>{repeatProcessErr?.time}</h6>}
                      </div>
                    </div>}


                  {['Options Shared', 'Proposal Sent', 'Purchase Order', 'LOI/Agreement', 'LOI Signing', 'Proposal Sent', 'Agreement', 'Design', 'QS', 'MEP', 'BOQ', 'In Progress','Utilization Report']?.includes(leaddata?.stage?.name) &&
                    <>
                      <h6 className='text-[11px] font-[600] mb-1'>Attachment</h6>
                      <Uploader image={repeatProcess?.file} setimagefunc={(e) => { setrepeatProcess({ ...repeatProcess, file: e }); setrepeatProcessErr({ ...repeatProcessErr, file: '' }) }} removeimageuploadfunc={() => setrepeatProcess({ ...repeatProcess, file: '' })} />
                    </>}


                  {leaddata?.stage?.name === 'LOI/Agreement' && <>
                    <div className='overflow-hidden'>
                      <div className='flex items-center mt-2' onClick={() => setagreementData({ ...aggrementData, reminder_active: !aggrementData.reminder_active })}>
                        {!aggrementData?.reminder_active ? <BiCheckbox size={20} /> : <BiCheckboxSquare size={20} />}
                        <h6 className='text-[11px]'>LOI / Agreement Reminder</h6>
                      </div>
                      {aggrementData?.reminder_active &&
                        <div className='w-[100%]'>

                          <div className='flex items-center'>
                            <div>
                              <h6 className='text-[10px] font-[700] mt-2'>Lock in period (From Date)</h6>
                              <div className='border w-[95%] mt-2 '>
                                <input type='date' style={{ width: '100%' }} className='p-1 h-[30px] border-l-[3px] border-l-slate-700  focus:outline-none focus:ring-0' value={aggrementData?.lock_in_period_from_date} onChange={(v) => { setagreementData({ ...aggrementData, lock_in_period_from_date: v.target.value }); setagreementDataErr({ ...aggrementDataErr, lock_in_period_from_date: '' }) }} />
                              </div>

                              {(aggrementDataErr?.lock_in_period_from_date !== '' || aggrementDataErr?.lock_in_period_from_date !== null) && <h6 className='text-red-600 text-[10px]'>{aggrementDataErr?.lock_in_period_from_date}</h6>}
                            </div>

                            <div>

                              <h6 className='text-[10px] font-[700] mt-2'>Lock in period (To Date)</h6>
                              <div className='border w-[80%] mt-2 '>
                                <input type='date' style={{ width: '100%' }} className='p-1 h-[30px] border-l-[3px] border-l-slate-700  focus:outline-none focus:ring-0' value={aggrementData?.lock_in_period_end_date} onChange={(v) => { setagreementData({ ...aggrementData, lock_in_period_end_date: v.target.value }); setagreementDataErr({ ...aggrementDataErr, lock_in_period_end_date: '' }) }} />
                              </div>
                            </div>
                          </div>

                          {(aggrementDataErr?.lock_in_period_end_date !== '' || aggrementDataErr?.lock_in_period_end_date !== null) && <h6 className='text-red-600 text-[10px]'>{aggrementDataErr?.lock_in_period_end_date}</h6>}

                          <div className='flex w-[100%] items-center'>
                            <div className='mr-[2%] '>
                              <h6 className='text-[11px] font-[700] mt-2'>Lease End Date</h6>
                              <div className='border w-[98%] mt-2 '>
                                <input type='date' className='p-1 h-[30px] w-[100%] focus:outline-none focus:ring-0' value={aggrementData?.lease_end_date} onChange={(v) => { setagreementData({ ...aggrementData, lease_end_date: v.target.value }); setagreementDataErr({ ...aggrementDataErr, lease_end_date: '' }) }} />
                              </div>
                              {(aggrementDataErr?.lease_end_date !== '' || aggrementDataErr?.lease_end_date !== null) && <h6 className='text-red-600 text-[10px]'>{aggrementDataErr?.lease_end_date}</h6>}
                            </div>

                            <div className='ml-[0%]'>
                              <h6 className='text-[11px] font-[700] mt-2'>Lease Years</h6>
                              <div className='border w-[98%] mt-2 '>
                                <input type='text' className='p-1 h-[30px] w-[100%] focus:outline-none focus:ring-0' value={aggrementData?.lease_years} onChange={(v) => { setagreementData({ ...aggrementData, lease_years: v.target.value }); setagreementDataErr({ ...aggrementDataErr, lease_years: '' }) }} />
                              </div>

                              {(aggrementDataErr?.lease_years !== '' || aggrementDataErr?.lease_years !== null) && <h6 className='text-red-600 text-[10px]'>{aggrementDataErr?.lease_years}</h6>}
                            </div>
                          </div>

                          <h6 className='text-[11px] font-[700] mt-2'>Agreement Expire</h6>
                          <div className='border mt-2'>
                            <input type='date' sx={{ height: '40px' }} style={{ width: '100%' }} className='p-1  border-l-[3px] border-l-slate-700  focus:outline-none focus:ring-0' value={aggrementData?.agreement_expire} onChange={(v) => { setagreementData({ ...aggrementData, agreement_expire: v.target.value }); setagreementDataErr({ ...aggrementDataErr, agreement_expire: '' }) }} />
                          </div>

                          {(aggrementDataErr?.agreement_expire !== '' || aggrementDataErr?.agreement_expire !== null) && <h6 className='text-red-600 text-[10px]'>{aggrementDataErr?.agreement_expire}</h6>}

                        </div>}
                    </div>
                  </>}







                  {/* <h6>{data?.stage}</h6> */}

                  <div className='flex justify-end mt-2'>
                    <h6 onClick={() => repeatProcessfunc()} className='flex items-center justify-center rounded text-[12px] bg-slate-600 cursor-pointer font-[600] px-2 py-1 text-white w-[80px]'>Send <AiOutlineSend className='ml-1' /></h6>
                  </div>
                </div>

              </div>}
            {leaddata?.stage?.name === 'Invoice Raised' && user_department == 'Transaction Team' &&
              <div className='mt-4'>
                <h6 className='text-[10.5px]  leading-tight p-2 bg-blue-50'>Congrats you have reached the final stage of the lead check whether the invocie payment is recieved or not in the payment recieved tab <span className='text-slate-800 font-[900] cursor-pointer' onClick={() => setstep1(5)}>Payment Status</span></h6>
              </div>
            }

            {(['bd_user', 'manager', 'hod', 'admin'].filter((f) => user?.roles?.includes(f)).length > 0 && user?.department_id[0].department_name == 'Transaction Team') &&
              <div className='border-b border-slate-100 mt-2 pt-2 pb-2'>
                <div className='flex items-center justify-between mt-2'>
                  <h6 className='text-[13px] font-[700]'>Request Data From DB Team ({requirmentInfoArr?.data?.length})</h6>
                  <AiOutlinePlusCircle className='cursor-pointer' onClick={() => setrequirmentModal(!requirmentModal)} />
                </div>

                {requirmentInfoArr?.data?.map((r) => (
                  <div className='border-t pt-2 mt-2' key={r?._id}>
                    <h6 className='text-[12px]'><span className='font_bold'>Requirment :</span> {r?.requirment}</h6>
                    {r?.status !== 'Completed' ?
                      <h6 className='text-[11px]'>Status : <span className='font-[800] text-slate-800'>{r?.status}</span></h6>
                      :
                      <div>
                        <div className='flex relative mt-1'>
                          <img src="https://cdn-icons-png.flaticon.com/512/179/179483.png" className='w-5 h-5 object-contain z-0' />
                          <h6 className='text-[9px] font-[800] ml-1 w-[85%]  overflow-hidden line-clamp-2'>{r?.file?.split('/')[r?.file?.split('/')?.length - 1]}</h6>
                          <AiOutlineDownload size={23} className='cursor-pointer bg-slate-700 rounded text-white p-1 text-[40px] absolute right-1' onClick={() => handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${r?.file}`, r?.file?.split('/')[r?.file?.split('/')?.length - 1])} />
                        </div>
                        <h6 className='text-[11px] mt-1'>Requirment Handled By : <span className='font-[800] text-slate-800'>{r?.handled_by?.name}</span></h6>
                      </div>}
                    <h6 className='text-[11.5px] bg-slate-100 px-2 py-[2px]'>Remarks : {r?.remarks}</h6>
                  </div>))}

              </div>}

          </div>



          {/* <div className='sm:block md:hidden'>
            <div className='bg-[#158a93] h-10 flex justify-between items-center w-full'>
            <IoChevronBack size={24} color="#000" onClick={()=>navigate(-1)} />
            <div className='flex bg-[#158a93]'>
              <span className='mr-3 cursor-pointer'  onClick={()=>navigate('reminder',{state:leaddata?._id})}>
              <MdAccessAlarms size={20} />
              </span>
              <span  className='mr-3 cursor-pointer'  onClick={()=>navigate('followup',{state:leaddata})}>
              <MdEventRepeat size={20}  />
              </span>
              <span  className='mr-2 cursor-pointer'  onClick={()=>navigate('notes',{state:leaddata?._id})}>
              <CgNotes size={18}  />
              </span>
              <span  className='mr-2 cursor-pointer' onClick={()=>navigate('edit',{state:leaddata?._id})}>
              <BiDotsVerticalRounded size={20}  />
              </span>
            </div>
            </div>
        </div>

        <div sx={{display:{xs:'hidden',sm:'hidden',md:'block',lg:'block'}}} className='hidden md:block '>
            <div className=' h-10 flex justify-between items-center w-full  mt-5'>

            <div className='flex items-center justify-end w-full'>
              <Tooltip title="Reminder">
              <span className='mr-3 cursor-pointer'  onClick={()=>navigate('reminder',{state:leaddata?._id})}>
              <MdAccessAlarms size={20} />
              </span>
              </Tooltip>
              <Tooltip title="Follow Ups">
              <span  className='mr-3 cursor-pointer'  onClick={()=>navigate('followup',{state:leaddata})}>
              <MdEventRepeat size={20}  />
              </span>
              </Tooltip>
              <Tooltip title="Notes">
              <span  className='mr-2 cursor-pointer'  onClick={()=>navigate('notes',{state:leaddata?._id})}>
              <CgNotes size={18}  />
              </span>
              </Tooltip>
              <Tooltip title="Edit">
              <span  className='mr-2 cursor-pointer' onClick={()=>navigate('edit',{state:leaddata?._id})}>
              <BiDotsVerticalRounded size={20}  />
              </span>
              </Tooltip>
            </div>
            </div>
        </div>

        <div>
          <div key={leaddata._id} className={`px-1 py-2 flex relative`}>
            <Avatar sx={{ width: 35, height: 35,fontSize:'17px',textTransform:'uppercase' }} style={{backgroundColor:`#${leaddata?.color?.split('0xff')[1]}`}}>{leaddata?.contact_name?.slice(0,1)}</Avatar>
            <div className='ml-2'>
              <h5 className='text-[14px] font-[800] mb-0 pb-0'>{leaddata?.contact_name}</h5>
              <h5 className='text-[13px] font-[500] mb-0 -mt-1 pb-0'>{leaddata?.company_name}</h5>
              <h5 className='text-[12px] -mt-1 mb-0 pb-0 '>{leaddata?.stage?.name}</h5>
            </div>
            <h6 className='absolute right-2 top-1 text-[9px]'>{moment(leaddata?.createdAt).format('ll')}</h6>
            {leaddata?.important === false ?
            <AiOutlineStar size={20} className='absolute right-2 top-5' style={{color:'#c7c7c7'}} onClick={()=>updateImportance(leaddata)}/>
            :
            <AiFillStar size={20} className='absolute right-2 top-5' style={{color:'#fccb42'}} onClick={()=>updateImportance(leaddata)} />
            }
            </div>

            <div className='border-t pt-5 pb-5 px-2'>
              <h4 className='text-[15px] font-black'>Company Info</h4>
              <div className='flex items-center my-2 justify-between'>
                <div className='w-2/4'>
                  <h6 className='text-[12px] font-[400] text-[#adadad]'>Industry</h6>
                  <h6 className='font-[500] text-[14px]'>{leaddata?.industry?.name}</h6>
                </div>

                <div className='w-2/4'>
                  <h6 className='text-[12px] font-[400] text-[#adadad]'>Contact Source</h6>
                  <h6 className='font-[500] text-[14px]'>{leaddata?.lead_source?.name}</h6>
                </div>

              </div>

              <div className='flex items-center my-2 justify-between'>
                <div>
                  <h6 className='text-[12px] font-[400] text-[#adadad]'>Lead Status</h6>
                  <h6 className='font-[500] text-[14px]'>{leaddata?.stage?.name}</h6>
                </div>
              </div>
            </div>

            <div className='border-t pt-5 pb-5  px-2'>
              <h4 className='text-[15px] font-black'>Lead Info</h4>
              <div className='flex items-center my-2 '>
                <div className='w-2/4'>
                  <h6 className='text-[12px] font-[400] text-[#adadad]'>Zone</h6>
                  <h6 className='font-[500] text-[14px]'>{leaddata?.zone?.name}</h6>
                </div>

                <div className='w-2/4'>
                  <h6 className='text-[12px] font-[400] text-[#adadad]'>Measurment</h6>
                  <h6 className='font-[500] text-[14px] '>{leaddata?.sale && 'Sale'} {leaddata?.rent && 'Rent'} {leaddata?.sqft && 'Sqft'} {leaddata?.seater && 'Seater'}</h6>
                </div>

              </div>

              <div className='flex items-center my-2 '>
                <div className='w-full'>
                  <h6 className='text-[12px] font-[400] text-[#adadad]'>Operators</h6>
                  {leaddata.operators?.map((o)=>(
                  <span key={o?._id} className='font-[500] text-[14px]'>{o?.name},</span>
                  ))}
                </div>
              </div>

              <div className='flex items-center my-2 '>
                <div className='w-full'>
                  <h6 className='text-[12px] font-[400] text-[#adadad]'>Requirment</h6>
                  <h6 className='font-[500] text-[14px] truncate'>{leaddata?.requirment}</h6>
                </div>
              </div>
            </div>

            <div className='border-t pt-5 border-b pb-5  px-2'>
              <h4 className='text-[15px] font-black'>Contact Info</h4>
              <div className='flex items-center my-2 truncate'>
                <div className='w-2/4'>
                  <h6 className='text-[12px] font-[400] text-[#adadad]'>Primary Contact</h6>
                  <h6 className='font-[500] text-[14px]truncate'>{leaddata?.phone}</h6>
                </div>

                <div className='w-2/4'>
                  <h6 className='text-[12px] font-[400] text-[#adadad]'>Primary Email</h6>
                  <h6 className='font-[500] text-[14px] truncate'>{leaddata?.email}</h6>
                </div>

              </div>
              <div className='flex items-center my-2 '>
                <div className='w-2/4'>
                  <h6 className='text-[12px] font-[400] text-[#adadad]'>Secondary Contact Name</h6>
                  <h6 className='font-[500] text-[14px] truncate'>{leaddata?.secondary_contact_person}</h6>
                </div>
              </div>  

              <div className='flex items-center my-2 '>
                <div className='w-2/4'>
                  <h6 className='text-[12px] font-[400] text-[#adadad]'>Secondary Contact</h6>
                  <h6 className='font-[500] text-[14px] truncate'>{leaddata?.secondary_contact_phone}</h6>
                </div>

                <div className='w-2/4'>
                  <h6 className='text-[12px] font-[400] text-[#adadad]'>Secondary Email</h6>
                  <h6 className='font-[500] text-[14px] truncate'>{leaddata?.secondary_contact_email}</h6>
                </div>

              </div>

              <div className='flex items-center my-2 '>
                <div className='w-full'>
                  <h6 className='text-[12px] font-[400] text-[#adadad]'>Company Address</h6>
                  <h6 className='font-[500] text-[14px]'>{leaddata?.address}</h6>
                </div>
              </div>
            </div>

        </div> */}

          {updateActivity &&
            <div className='w-[25%] z-50 shadow rounded-lg overflow-hidden  h-[30%] bg-white border fixed bottom-0 right-16'>
              <div className='flex items-center justify-between bg-slate-100 p-2 text-[13px] font-[500] text-black'>
                <h6 className='font-[700]' >Update Activity Status</h6>
                <MdClose onClick={() => setupdateActivity(false)} />
              </div>

              <div className='px-2'>

                <TextAreaInput1 label="Summary" value={selected_activity?.summary} name="summary" handlechange={onChange2} />

                <div>
                  <span className='flex items-center text-[12px]'> {!selected_activity?.finalized ? <BiCheckbox onClick={() => setselected_Activity({ ...selected_activity, finalized: !selected_activity.finalized })} className='text-slate-300 mr-1' size={20} /> : <BiCheckboxSquare onClick={() => setselected_Activity({ ...selected_activity, finalized: !selected_activity.finalized })} className='text-slate-600 mr-1' size={20} />} <span >Finalized</span>  </span>
                </div>

                <div className='flex justify-end mt-1'>
                  <h6 className={`flex ${selected_activity?.summary?.length > 3 ? 'opacity-100' : 'opacity-50'} cursor-pointer text-[11px] justify-center items-center px-2 py-2 w-[70px] text-white font-[500] bg-slate-700 p-1 rounded`} onClick={() => updateSummaryForActivity()}>Send <AiOutlineSend className='ml-1' /></h6>
                </div>
              </div>


            </div>}

          {reviseInvoice &&
            <div className='w-[25%] z-50 shadow rounded-lg overflow-hidden  h-[60%] bg-white border fixed bottom-0 right-16'>
              <div className='flex items-center justify-between bg-slate-100 p-2 text-[13px] font-[500] text-black'>
                <h6 className='font-[700]' >Invoice Revise Request</h6>
                <MdClose onClick={() => setreviseInvoice(false)} />
              </div>

              <div className='px-2'>
                {(invoiceSelected?.invoice_file !== null && invoiceSelected?.invoice_file !== undefined && invoiceSelected?.invoice_file !== '') &&
                  <>

                    <div className='ml-1 mt-1 border-slate-100  flex relative py-1'>
                      <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 object-contain' />
                      <div className='ml-1 -mt-0.5'>
                        <h6 className='font-[600] text-[11px]'>{returnfilename(invoiceSelected?.invoice_file)}</h6>
                      </div>
                    </div>
                    <h6 className='text-[11px] pl-1  '>Amount  : <span className='font-[700]'>{rupee.format(invoiceSelected?.amount)}</span></h6>
                    <h6 className='text-[11px] pl-1 border-b pb-2 border-slate-100'>Raised Request Date  : <span className='font-[700]'>{invoiceSelected?.datetime !== undefined && moment(invoiceSelected?.datetime)?.format('lll')}</span></h6>

                    <>
                      <h6 className='text-[11px]  mt-2 pl-1 font-[800]'>Finance Info</h6>
                      <h6 className='text-[11px] pl-1  '>Invoice No  : <span className='font-[700]'>{invoiceSelected?.invoice_no}</span></h6>
                      <h6 className='text-[11px] pl-1  '>Invoice Amount Incl GST  : <span className='font-[700]'>{rupee.format(invoiceSelected?.invoice_amount_with_gst)?.split('.')[0]}</span></h6>
                      <h6 className='text-[11px] pl-1 '>Invoice Amount Excl GST  : <span className='font-[700]'>{rupee.format(invoiceSelected?.invoice_amount_without_gst)?.split('.')[0]}</span></h6>
                      <h6 className='text-[11px] p-1 mt-1 bg-slate-100'>Description  : <span className='font-[700]'>{invoiceSelected?.description}</span></h6>

                    </>
                    {/* } */}

                  </>
                }
              </div>

              <div className='absolute bottom-0 border-t w-full flex items-center'>
                <TextArea value={reviseSummary} onChange={(e) => setreviseSummary(e.target.value)} placeholder='Enter your message...' className='border-none w-[80%] text-[12px] focus:none focus:ring-0' />
                <h6 className={`flex ${reviseSummary?.length > 3 ? 'opacity-100' : 'opacity-50'} cursor-pointer text-[11px] items-center mx-2 px-2 text-white font-[500] bg-slate-700 p-1 rounded`} onClick={() => reviseInvoiceRequest()}>Send <AiOutlineSend className='ml-1' /></h6>


              </div>
            </div>}

          {paymentRecieved &&
            <div className='w-[22%] z-50 shadow rounded-lg overflow-hidden  h-[240px] bg-white border fixed bottom-0 right-16'>
              <div className='flex items-center justify-between bg-slate-600 text-white p-2 text-[13px] font-[500] text-black'>
                <h6 className='font-[400] text-[12px]' >Payment Recieved</h6>
                <MdClose onClick={() => setpaymentRecieved(false)} />
              </div>

              <div className='px-2'>
                <div className='flex justify-between'>
                  <div className='w-full mr-1'>
                    <TextInput handlechange={(e) => setpayment({ ...payment, amount_with_gst: e.target.value })} value={payment?.amount_with_gst} label="Amount With GST" />
                  </div>
                  <div className='w-full ml-1'>
                    <TextInput handlechange={(e) => setpayment({ ...payment, amount_without_gst: e.target.value })} value={payment?.amount_without_gst} label="Amount Without GST" />
                  </div>

                </div>

                <h6 className='text-[11px] font-[600] mt-2' >{'Date'}</h6>
                <div className='border  mt-1 border-slate-300'>
                  <input type="date" value={payment?.recieved_date} onChange={(e) => setpayment({ ...payment, recieved_date: e.target.value })} className='px-2 text-[12px] w-full justify-between py-[5px] focus:ring-0 focus:outline-none' />
                  {/* <DatePicker placeholder='' value={payment?.recieved_date === '' ? null : moment(payment?.recieved_date)} onChange={(e,e1)=>setpayment({...payment,recieved_date:e1})} className='w-full' bordered={false} /> */}
                </div>

              </div>

              <div className='flex mt-2 ml-2'>
                <span onClick={() => setpayment({ ...payment, payment: '1' })} className='flex items-center text-[12px]'> {payment?.payment === '1' ? <BiCheckboxSquare className='text-slate-600 mr-1 z-50' size={20} /> : <BiCheckbox className='text-slate-300 mr-1' size={20} />} CASH  </span>
                <span onClick={() => setpayment({ ...payment, payment: '0' })} className='flex items-center text-[12px] ml-2'> {payment?.payment === '0' ? <BiCheckboxSquare className='text-slate-600 mr-1 z-50' size={20} /> : <BiCheckbox className='text-slate-300 mr-1' size={20} />} ONLINE  </span>
              </div>

              {(payment?.amount_with_gst !== '' && payment?.amount_without_gst !== '' && payment?.recieved_date !== '') &&
                <div className='flex justify-end'>
                  <h6 className={`flex  text-center w-[90px] text-[13px] mt-4 items-center justify-center mx-2 px-1 py-1.5 text-white font-[600] bg-slate-700 p-1 rounded cursor-pointer`} onClick={() => createPaymentRecieved()}>Send <AiOutlineSend className='ml-2' /></h6>
                </div>}

            </div>}

        </div>
      </div>
    </div>
  )
}

export default LeadDetail