import React, { useEffect } from 'react';
import {useLocation,useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VscGraphLine } from 'react-icons/vsc';
import { MdOutlineFeaturedPlayList,MdCleaningServices,MdOutlineApproval } from "react-icons/md";
import { GrStatusInfo } from "react-icons/gr";
import { GrMapLocation } from "react-icons/gr";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { MdOutlineLandslide } from "react-icons/md";
import { RiHomeOfficeLine } from "react-icons/ri";
import { BiCategoryAlt } from "react-icons/bi";
import { VscGroupByRefType } from "react-icons/vsc";
import { TbLayoutDashboard } from "react-icons/tb";

import { RiFileList2Line } from "react-icons/ri";

function DatabaseMenu() {

  const {pathname} = useLocation();
  const path = pathname.split('/')[2]
  const user = useSelector(state=>state.Auth)


  const navigate = useNavigate();

  useEffect(()=>{
    if(path === undefined){
      navigate('/database/dashboard')
    }
  },[path])


  const menu0 = [
    {name:'DB Dashboard',icon:TbLayoutDashboard,path:`/database/dashboard`,id:1,color:'',roles:[]},
  ] 
 
  
  const menu = [
    {name:'Managed Office',icon:HiOutlineBuildingOffice,path:`/database/managed_office`,id:2,color:'',roles:[]},
    {name:'Office Space Option',icon:RiHomeOfficeLine,path:`/database/office_space`,id:1,color:'',roles:[]},
  ] 

  

  const menu1 = [
    {name:'Property Category',icon:BiCategoryAlt,path:`/database/property_category`,id:1,color:'',roles:[]},
    {name:'Property Type',icon:MdOutlineLandslide,path:`/database/property_type`,id:1,color:'',roles:[]},
    {name:'Facility Type',icon:VscGroupByRefType,path:`/database/facility_type`,id:1,color:'',roles:[]},
    {name:'Property Status',icon:GrStatusInfo,path:`/database/property_status`,id:11,color:'',roles:[]},
    {name:'Zone Type',icon:VscGraphLine,path:`/database/property_zone`,id:2,color:'',roles:[]},
    {name:'Location vs Zone',icon:GrMapLocation,path:`/database/location_based_zone`,id:3,color:'',roles:['admin']},
    // {name:'Conversion Type',icon:LuBarChartBig,path:`/database/conversion_type`,id:4,color:'',roles:['admin']},
    {name:'Furnish Type',icon:MdCleaningServices,path:`/database/furnish_type`,id:5,color:'',roles:['admin']},
    {name:'Amenities Type',icon:MdOutlineFeaturedPlayList,path:`/database/property_amenities`,id:6,color:'',roles:['admin']},
    // {name:'Plot Type',icon:SiTestcafe,path:`/database/plot_type`,id:7,color:'',roles:['admin']},
    {name:'Approved Type',icon:MdOutlineApproval,path:`/database/approved_type`,id:8,color:'',roles:['admin']},
    // {name:'Katha Type',icon:MdOutlineSettingsInputComposite,path:`/database/katha_type`,id:9,color:'',roles:['admin']},
    // {name:'Ownership Type',icon:TbUserSquareRounded,path:`/database/owner_type`,id:10,color:'',roles:['admin']},
  ] 


  const menu2 = [
    {name:'Requirment List',icon:RiFileList2Line,path:`/database/requirment_info`,id:2,color:'',roles:[]},
  ] 
  
  
  

  return (
    <div className='mr-0 min-h-screen overflow-y-scroll no-scrollbar max-h-screen border-r min-w-44  max-w-44 min-w-44 px-2' >


        {((user?.roles?.filter((f)=>(['admin','db_head','db_manager']?.includes(f)))?.length > 0)) &&
        <div className='mb-4 mt-5 border-b pb-2'>
        <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Basic Info</h6>

            {menu0.map((m)=>(
              <div key={m?.path}>
               <React.Fragment key={m?.path}>
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                  <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
              </React.Fragment>
              </div>
            ))}
        </div>}

        {((user?.roles?.filter((f)=>['admin','db_head','db_manager','db_user']?.includes(f))?.length > 0)) &&
        <div className='mb-3 mt-5'>
        <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Commercial Info</h6>

            {menu.map((m)=>(
              <div key={m?.path}>
               <React.Fragment key={m?.path}>
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                  <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
              </React.Fragment>
              </div>
              ))}
        </div>}

        {((user?.roles?.filter((f)=>['admin','db_head','db_manager','db_user']?.includes(f))?.length > 0)) &&
        <div>
        <div className={'border-b pt-4 border-t pb-2'}>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Requirment Option</h6>
            {menu2.map((m)=>(
                <React.Fragment key={m?.path}>
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16} />
                 <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>
                </React.Fragment>   
            ))} 
        </div>
        </div>}


        {((user?.roles?.filter((f)=>['admin','db_head','db_manager']?.includes(f))?.length > 0)) &&
        <div  >
        <div className={'mt-4 pt-2 border-b pb-2'}>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Property Option</h6>
            {menu1.map((m)=>(
                <React.Fragment key={m?.path}>
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16} />
                 <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>
                </React.Fragment>   
            ))} 
        </div>
        </div>}

      

       
    </div>
  )
}

export default DatabaseMenu