import React, { useState, useEffect } from 'react'
import DashboardMenu from '../dashboard/DashboardMenu'
import { FilterCalendarService } from '../../services/CalendarServices'
import { IconButton } from "@mui/material";
import { MdOutlineEventNote } from "react-icons/md";
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';

import { IoCallSharp } from "react-icons/io5";
import { MdOutlineMeetingRoom } from "react-icons/md";
import { BiFilterAlt } from "react-icons/bi";
import { TbLayoutSidebarLeftExpand } from "react-icons/tb";
import { GetFTSLeadsSharedToFTSBasedUserAndDepartmentService } from '../../services/FTSLeadServices';
import { GetVericalBasedAssignedRecievedBasedUserService } from '../../services/ftsVerticalReportServices/FTSVerticalLeadReportServices';
import { MdOutlineEventRepeat } from "react-icons/md";
import { RiBillLine } from 'react-icons/ri';
import { AiOutlineEdit, AiOutlineDelete, AiOutlineClose } from 'react-icons/ai';
import { CreateCasesFollowupService, DeleteCasesFollowupService, DeleteCasesService, GetCasesFollowupService, GetCasesService, UpdateCasesFollowupService } from '../../services/govtservices/CaseServices';
import toast from 'react-hot-toast';

import { GetDepartmentService } from '../../services/DepartmentServices';
import { AiOutlineFilter } from 'react-icons/ai';
import { MdOutlineFeaturedPlayList } from "react-icons/md";
import { BsArrowRepeat } from 'react-icons/bs';
import { RxReset } from "react-icons/rx";
import { DatePicker, Select, Drawer, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
// import { ButtonFilledAutoWidth } from '../../components/button';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { BiTable } from "react-icons/bi";
import { RiLayoutGridLine } from "react-icons/ri";
import moment from 'moment';
import AdminProfitLoss from '../dashboard/AdminProfitLoss';
import ReactApexChart from 'react-apexcharts';
import { PiChartBarBold } from "react-icons/pi";
import { TextAreaInput1, TextInput } from '../../components/input';

import Priceconstants from '../../constants/imageConstants';




function AdminDashboard() {

  const roles = useSelector(state => state.Auth.roles)

  const [step, setstep] = useState(1)
  const [subStep, setSubStep] = useState(1);
  const [data, setdata] = useState({})
  const [dataCase, setdataCase] = useState({})
  const [eventData, seteventData] = useState({})
  const [meetingData, setmeetingData] = useState({})
  const [taskData, settaskData] = useState({})
  const [data1, setdata1] = useState({})


  const [page, setpage] = useState(1)
  const [page1, setpage1] = useState(1)
  const [page2, setpage2] = useState(1)
  const [page3, setpage3] = useState(1)

  const [leads, setleads] = useState({ datas: [], pagination: { total: 0, totalPages: 0 } })
  const [department, setdepartment] = useState([])
  const [search, setsearch] = useState({ text: '', from_date: '', to_date: '', search_text: '', from_date1: '', to_date1: '', department: '', activate: false })
  const [search1, setsearch1] = useState({ text: '', from_date: '', to_date: '', from_date1: '', to_date1: '', department: '', activate: false })
  const [search2, setsearch2] = useState({ text: '', from_date: '', to_date: '', from_date1: '', to_date1: '', department: '', activate: false })
  const [search3, setsearch3] = useState({ text: '', from_date: '', to_date: '', from_date1: '', to_date1: '', department: '', activate: false })

  const [caseStep, setCaseStep] = useState(1)
  const [followup, setfollowup] = useState([])

  const [selectedData, setselectedData] = useState({})
  const [selectedData1, setselectedData1] = useState({})
  const [modal, setmodal] = useState(false)
  const [modal1, setmodal1] = useState(false)
  const [modal2, setmodal2] = useState(false)
  const [modal3, setmodal3] = useState(false)

  const navigate = useNavigate()


  const teams = [
    "Transaction Team",
    "Project Team",
    "HR Team",
    "FMS Team",
    "Fidelitus Gallery",
    "Shilpa Foundation",
  ];

  const leadsChartOptions = {
    chart: { type: 'donut' },
    labels: teams,
    colors: ["#FF4560", "#008FFB", "#00E396", "#FEB019", "#775DD0", "#546E7A"],
    dataLabels: { enabled: false },
    legend: { show: false }
  };


  const teamNames = [
    "Transaction Team",
    "Project Team",
    "HR Team",
    "FMS Team",
    "Fidelitus Gallery",
    "Shilpa Foundation"
  ]

  const seriesData = teams.map((team) => getLeadCount(team));

  const estimationValuesforChart = teamNames.map((team) => getEstiamtedRevenue(team));
  const actualEstimationValuessforChart = teamNames.map((team) => getActualRevenue(team));

  const barChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: { show: false }
    },
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {

      bar: {
        horizontal: false,
        dataLabels: {
          enabled: false
        },
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },

      },
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#fafafa',
    },

    stroke: {
      show: true,
      width: 1,
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Transaction', 'Project', 'HR Labs', 'FMS', 'Fidelitus Gallery', 'Shilpa Foundation'],
    },
    yaxis: {
      title: {
        text: 'Estimation / Actual Forecast'
      },
      labels: {
        formatter: function (val) {
          return numDifferentiation(val)
        }
      }
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return numDifferentiation(val)
        }
      }
    },
  };

  const type = [{ label: 'Event', value: 'Event' }, { label: 'Out of office', value: 'Out of office' }, { label: 'Task', value: 'Task' }, { label: 'Meeting', value: 'Meeting' }, { label: 'Calls', value: 'Calls' }]

  let user = useSelector(state => state.Auth)

  const department_FCPL_FTS_BACKEND = user?.department_id?.filter((f) => f?.department_name == 'FTS Team' || f?.department_name == 'Corp Team' || f?.department_name == 'Backend Team')?.length > 0 ? true : false


  useEffect(() => {
    getdepartments()
    setstepfunc(step)
  }, [step, page, page1, page2, page3])

  async function getdata() {
    const response = await GetFTSLeadsSharedToFTSBasedUserAndDepartmentService(page, search?.text, search?.from_date1, search?.to_date1, search?.department)
    setleads(response?.data);
  }

  async function getdata1() {
    const response = await GetVericalBasedAssignedRecievedBasedUserService(search?.from_date1, search?.to_date1, '', '', page)
    setdata1(response?.data?.data)
  }

  async function getCalenderData() {
    const response = await FilterCalendarService(search?.from_date1, search?.to_date1, search?.type, page)
    setdata(response?.data)
  }

  async function deleteData() {
    const response = await DeleteCasesService(selectedData?._id)
    if (response?.status == 200) {
      toast.success("Deleted Successfully!")
      getdata()
      setmodal(false)
    }
  }

  async function getfollwup(d) {
    const response = await GetCasesFollowupService('case', d?._id)
    setfollowup(response?.data?.datas)
  }

  async function deleteData1() {
    const response = await DeleteCasesFollowupService(selectedData1?._id)
    if (response?.status == 200) {
      toast.success("Deleted Successfully!")
      let oldData = selectedData?.govtfollowups?.filter((f) => f?._id !== selectedData1?._id)
      setselectedData({ ...selectedData, govtfollowups: oldData })
      setmodal2(false)
      getfollwup(selectedData)
    }
  }

  async function getdepartments() {
    const response = await GetDepartmentService()

    let d = response?.data?.datas

    let arr = []
    let transaction = d?.find((f) => f?.department_name === "Transaction Team")
    let project = d?.find((f) => f?.department_name === "Project Team")
    let hr = d?.find((f) => f?.department_name === "HR Team")
    let fms = d?.find((f) => f?.department_name === "FMS Team")
    let fidelitus_gallery = d?.find((f) => f?.department_name === "Fidelitus Gallery")
    let shilpa_foundation = d?.find((f) => f?.department_name === "Shilpa Foundation")

    if (![null, undefined, '', 'null', 'undefined']?.includes(transaction)) {
      arr.push(transaction)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(project)) {
      arr.push(project)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(hr)) {
      arr.push(hr)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(fms)) {
      arr.push(fms)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(fidelitus_gallery)) {
      arr.push(fidelitus_gallery)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(shilpa_foundation)) {
      arr.push(shilpa_foundation)
    }
    setdepartment(arr)

    // response.data.datas.forEach((d) => {
    //   if (!['Finance Team', 'Software Team', 'Backend Team', 'Digital Media']?.includes(d?.department_name)) {
    //     if (user.department_id[0].department_name !== d.department_name) {
    //       arr.push({ value: d.id, label: d.department_name })
    //     }
    //   }
    //   setdepartment(arr)
    // })

  }


  function numDifferentiation(value) {
    const val = Math.abs(value)
    if (val >= 10000000) return `${(value / 10000000).toFixed(0)} Cr`
    if (val >= 100000) return `${(value / 100000).toFixed(0)} Lac`
    return value;
  }

  //barchart end 
  function getLeadCount(v) {
    if (data1.length > 0) {
      let findData = data1?.find((f) => f?._id?.department_name == v)
      if (![null, undefined, 'null', 'undefined']?.includes(findData)) {
        return findData?.count
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  function getEstiamtedRevenue(v) {
    if (data1.length > 0) {
      let findData = data1?.find((f) => f?._id?.department_name == v)
      if (![null, undefined, 'null', 'undefined']?.includes(findData)) {
        return findData?.estimated_revenue
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  function getActualRevenue(v) {
    if (data1.length > 0) {
      let findData = data1?.find((f) => f?._id?.department_name == v)
      if (![null, undefined, 'null', 'undefined']?.includes(findData)) {
        return findData?.actual_revenue_achieved
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  function retutnTotalValue(type) {
    if (data1.length > 0) {
      let total = 0
      if (type == 'leads') {
        total += data1?.reduce((a, d) => a + d?.count, 0)
        return total
      }

      if (type == 'estimated_revenue') {
        total += data1?.reduce((a, d) => a + d?.estimated_revenue, 0)
        return total
      }

      if (type == 'actual_revenue_achieved') {
        total += data1?.reduce((a, d) => a + d?.actual_revenue_achieved, 0)
        return total
      }
    } else {
      return 0
    }
  }

  let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  async function setstepfunc(v) {
    if (v === 1) {
      setstep(1)
      const response = await FilterCalendarService(search1?.from_date1, search1?.to_date1, "Event", page1)
      seteventData(response?.data)
    } else if (v == 2) {
      setstep(2)
      const response = await FilterCalendarService(search2?.from_date1, search2?.to_date1, "Meeting", page2)
      setmeetingData(response?.data)
    } else if (v == 3) {
      setstep(3)
      const response = await FilterCalendarService(search3?.from_date1, search3?.to_date1, "Task", page3)
      settaskData(response?.data)
    } else if (v == 4) {
      setstep(4)
      const response = await FilterCalendarService(search?.from_date1, search?.to_date1, search?.type, page)
      setdata(response?.data)
    } else if (v == 5) {
      setstep(5)
      getdata()
      getdata1()
      // getCalenderData()
    } else if (v == 6) {
      setstep(6)
      getdata1()
    } else if (v == 7) {
      setCaseStep(2)
      getCasedata()
      setstep(7)
    }
  }



  async function getCasedata() {
    const response = await GetCasesService(page, caseStep, search?.from_date1, search?.to_date1, search?.search_text)
    setCaseStep(response.data)
  }

  async function applyfilterfunction() {
    setpage(1)
    const response = await FilterCalendarService(search?.from_date1, search?.to_date1, search?.type, 1)
    setdata(response?.data)
  }

  async function resetfilter() {
    setpage(1)
    setsearch({ text: '', from_date: '', from_date1: '', to_date: '', to_date1: '', type: '', activate: false })
    const response = await FilterCalendarService('', '', '', 1)
    setdata(response?.data)
  }

  async function resetfunc() {
    setpage(1)
    setsearch({ text: '', from_date: '', from_date1: '', to_date: '', to_date1: '', department: '' })
    const response = await GetFTSLeadsSharedToFTSBasedUserAndDepartmentService(1, '', '', '', '')
    setleads(response?.data)
  }

  async function applyfilter() {
    setpage(1)
    const response = await GetFTSLeadsSharedToFTSBasedUserAndDepartmentService(1, search?.text, search?.from_date1, search?.to_date1, search?.department)
    setleads(response?.data)
  }

  async function applyfilterfunctiontype(v) {
    if (step == 1) {
      setpage1(1)
      const response = await FilterCalendarService(search1.from_date1, search1?.to_date1, "Event", 1)
      seteventData(response?.data)
    }

    if (step == 2) {
      setpage2(1)
      const response = await FilterCalendarService(search2.from_date1, search2?.to_date1, "Meeting", 1)
      setmeetingData(response?.data)
    }

    if (step == 3) {
      setpage3(1)
      const response = await FilterCalendarService(search3.from_date1, search3?.to_date1, "Task", 1)
      settaskData(response?.data)
    }

  }

  async function resetfiltertype() {
    if (step == 1) {
      setpage1(1)
      setsearch1({ text: '', from_date: '', from_date1: '', to_date: '', to_date1: '', type: '', activate: false })
      const response = await FilterCalendarService('', '', 'Event', 1)
      seteventData(response?.data)
    }

    if (step == 2) {
      setpage2(1)
      setsearch2({ text: '', from_date: '', from_date1: '', to_date: '', to_date1: '', type: '', activate: false })
      const response = await FilterCalendarService('', '', 'Meeting', 1)
      seteventData(response?.data)
    }

    if (step == 3) {
      setpage3(1)
      setsearch2({ text: '', from_date: '', from_date1: '', to_date: '', to_date1: '', type: '', activate: false })
      const response = await FilterCalendarService('', '', 'Task', 1)
      settaskData(response?.data)
    }
  }

  async function navigateDetail() {

  }


  async function submitDataFollowUp() {

    if (selectedData1?._id === undefined) {
      let sendData = { ...selectedData1 }
      sendData['case'] = selectedData?._id
      const response = await CreateCasesFollowupService(sendData)
      if (response?.status === 201) {
        getdata()
        setselectedData1({ date: '', remarks: '' })
        toast.success("Case Followup Created Successfully!")
        setmodal3(false)
        setmodal2(false)
        setmodal1(false)
      }
    } else {
      let sendData = { ...selectedData1 }
      delete sendData.created_by
      const response = await UpdateCasesFollowupService(selectedData1?._id, sendData)
      if (response?.status === 200) {
        getdata()
        setselectedData1({ date: '', remarks: '' })
        getfollwup(selectedData)
        toast.success("Case Followup Updated Successfully!")
        setmodal3(false)
        setmodal2(false)
      }
    }
  }

  return (
    <div className='flex max-h-screen min-h-screen  '>
      <DashboardMenu />
      <div className='mx-5 w-[85%] mb-10'>
        <div className='flex items-center justify-between  pb-2 pt-2'>
          <h6 className='font-[700] text-[14px] '>MD Dashboard</h6>

        </div>

        <div className='flex bg-slate-100 items-center justify-center py-[4px]'>
          <h6 onClick={() => setstepfunc(1)} className={`cursor-pointer py-[4px] rounded ${step == 1 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px]  text-center text-[12px] font-[600] ml-2`}>Events</h6>
          <h6 onClick={() => setstepfunc(2)} className={`cursor-pointer py-[4px] rounded ${step == 2 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px]  text-center text-[12px] font-[600] ml-2`}>Meetings</h6>
          <h6 onClick={() => setstepfunc(3)} className={`cursor-pointer py-[4px] rounded ${step == 3 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px]  text-center text-[12px] font-[600] ml-2`}>Task</h6>
          <h6 onClick={() => setstepfunc(7)} className={`cursor-pointer py-[4px] rounded ${step == 7 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px]  text-center text-[12px] font-[600] ml-2`}>Litigations</h6>

          <h6 onClick={() => setstepfunc(4)} className={`-ml-4 cursor-pointer py-[4px] rounded ${step == 4 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px] mr-2 text-center text-[12px] font-[600] ml-2`}>My Work</h6>
          <h6 onClick={() => setstepfunc(5)} className={`cursor-pointer py-[4px] rounded ${step == 5 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px] mr-2 text-center text-[12px] font-[600]`}>Lead Assigned</h6>
          <h6 onClick={() => setstepfunc(6)} className={`cursor-pointer py-[4px] rounded ${step == 6 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px]  text-center text-[12px] font-[600]`}>Finance</h6>

        </div>
        {step === 1 &&
          <div className='overflow-y-scroll relative no-scrollbar no-scrollbar'>
            <div className='flex items-center justify-between border-b  mt-4 pb-2'>
              <h6 className='font-[800] text-[14px]'>Event List ({eventData?.pagination?.total})</h6>
              <div className='flex items-center justify-center'>
                <IconButton onClick={resetfiltertype} ><BsArrowRepeat size={16} /></IconButton>
                <div>
                  <IconButton onClick={() => { page1 > 1 ? setpage1(page1 - 1) : console.log('') }}><FiChevronLeft className={`${page1 === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                  <IconButton onClick={() => { page1 < eventData?.pagination?.totalPages ? setpage1(page1 + 1) : console.log('') }} ><FiChevronRight className={`${(eventData?.pagination?.totalPages === page1 || eventData?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                </div>

                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search1?.from_date} onChange={(v, v1) => { setsearch1({ ...search1, from_date: v, from_date1: v1 }) }} />
                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search1?.to_date} onChange={(v, v1) => { setsearch1({ ...search1, to_date: v, to_date1: v1 }) }} />


                <div className='mr-2'>
                  <BiFilterAlt onClick={() => applyfilterfunctiontype(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
                </div>

                <RxReset onClick={resetfiltertype} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />

              </div>
            </div>

            <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
              <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r '>Type</h6>
                <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Title</h6>
                <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Description</h6>
                <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r '>Task Type</h6>
                <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[600] text-slate-600 border-r '>Department</h6>
                <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600'>Remarks</h6>
              </div>

              {eventData?.datas?.map((d, i) => (
                <div key={d?._id} className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                  <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[500]  border-r '>{(page > 1 ? i + 1 + (eventData?.pagination?.limit * (page - 1)) : i + 1)}</h6>
                  <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[500]  border-r '>{d?.type !== '' && <span className='bg-blue-100 text-[10px] p-[4px] font-[600] rounded'>{d?.type}</span>}</h6>
                  <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.title}</h6>
                  <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.description}</h6>
                  <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[500]  border-r '>{!['', null, undefined, 'null', 'undefined']?.includes(d?.calendar_type) && <span className={` ${d?.calendar_type == 'New' ? 'bg-green-100' : 'bg-red-100'} text-[10px] p-[4px] font-[600] rounded`}>{d?.calendar_type}</span>}</h6>
                  <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[500]  border-r '>{d?.department?.department_name}</h6>
                  <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500] '>{d?.remarks}</h6>
                </div>))}


            </div>

          </div>}
        {step === 2 &&
          <div className='overflow-y-scroll relative no-scrollbar no-scrollbar'>
            <div className='flex items-center justify-between border-b  mt-4 pb-2'>
              <h6 className='font-[800] text-[14px]'>Meeting List ({meetingData?.pagination?.total})</h6>
              <div className='flex items-center justify-center'>
                <IconButton onClick={resetfiltertype} ><BsArrowRepeat size={16} /></IconButton>
                <div>
                  <IconButton onClick={() => { page2 > 1 ? setpage2(page2 - 1) : console.log('') }}><FiChevronLeft className={`${page2 === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                  <IconButton onClick={() => { page2 < meetingData?.pagination?.totalPages ? setpage2(page2 + 1) : console.log('') }} ><FiChevronRight className={`${(meetingData?.pagination?.totalPages === page2 || meetingData?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                </div>

                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search2?.from_date} onChange={(v, v1) => { setsearch2({ ...search2, from_date: v, from_date1: v1 }) }} />
                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search2?.to_date} onChange={(v, v1) => { setsearch2({ ...search2, to_date: v, to_date1: v1 }) }} />


                <div className='mr-2'>
                  <BiFilterAlt onClick={() => applyfilterfunctiontype(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
                </div>

                <RxReset onClick={resetfiltertype} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />

              </div>
            </div>

            <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
              <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r '>Type</h6>
                <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Title</h6>
                <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Description</h6>
                <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r '>Task Type</h6>
                <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[600] text-slate-600 border-r '>Department</h6>
                <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600'>Remarks</h6>
              </div>

              {meetingData?.datas?.map((d, i) => (
                <div key={d?._id} className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                  <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[500]  border-r '>{(page > 1 ? i + 1 + (eventData?.pagination?.limit * (page - 1)) : i + 1)}</h6>
                  <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[500]  border-r '>{d?.type !== '' && <span className='bg-blue-100 text-[10px] p-[4px] font-[600] rounded'>{d?.type}</span>}</h6>
                  <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.title}</h6>
                  <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.description}</h6>
                  <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[500]  border-r '>{!['', null, undefined, 'null', 'undefined']?.includes(d?.calendar_type) && <span className={` ${d?.calendar_type == 'New' ? 'bg-green-100' : 'bg-red-100'} text-[10px] p-[4px] font-[600] rounded`}>{d?.calendar_type}</span>}</h6>
                  <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[500]  border-r '>{d?.department?.department_name}</h6>
                  <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500] '>{d?.remarks}</h6>
                </div>))}


            </div>


          </div>}
        {step === 3 &&
          <div className='overflow-y-scroll relative no-scrollbar no-scrollbar'>
            <div className='flex items-center justify-between border-b  mt-4 pb-2'>
              <h6 className='font-[800] text-[14px]'>Task List ({taskData?.pagination?.total})</h6>
              <div className='flex items-center justify-center'>
                <IconButton onClick={resetfiltertype} ><BsArrowRepeat size={16} /></IconButton>
                <div>
                  <IconButton onClick={() => { page3 > 1 ? setpage3(page3 - 1) : console.log('') }}><FiChevronLeft className={`${page3 === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                  <IconButton onClick={() => { page3 < taskData?.pagination?.totalPages ? setpage3(page3 + 1) : console.log('') }} ><FiChevronRight className={`${(taskData?.pagination?.totalPages === page3 || taskData?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                </div>

                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search3?.from_date} onChange={(v, v1) => { setsearch3({ ...search3, from_date: v, from_date1: v1 }) }} />
                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search3?.to_date} onChange={(v, v1) => { setsearch3({ ...search3, to_date: v, to_date1: v1 }) }} />


                <div className='mr-2'>
                  <BiFilterAlt onClick={() => applyfilterfunctiontype(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
                </div>

                <RxReset onClick={resetfiltertype} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />

              </div>
            </div>

            <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
              <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r '>Type</h6>
                <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Title</h6>
                <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Description</h6>
                <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r '>Task Type</h6>
                <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[600] text-slate-600 border-r '>Department</h6>
                <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600'>Remarks</h6>
              </div>

              {taskData?.datas?.map((d, i) => (
                <div key={d?._id} className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                  <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[500]  border-r '>{(page > 1 ? i + 1 + (eventData?.pagination?.limit * (page - 1)) : i + 1)}</h6>
                  <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[500]  border-r '>{d?.type !== '' && <span className='bg-blue-100 text-[10px] p-[4px] font-[600] rounded'>{d?.type}</span>}</h6>
                  <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.title}</h6>
                  <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.description}</h6>
                  <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[500]  border-r '>{!['', null, undefined, 'null', 'undefined']?.includes(d?.calendar_type) && <span className={` ${d?.calendar_type == 'New' ? 'bg-green-100' : 'bg-red-100'} text-[10px] p-[4px] font-[600] rounded`}>{d?.calendar_type}</span>}</h6>
                  <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[500]  border-r '>{d?.department?.department_name}</h6>
                  <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500] '>{d?.remarks}</h6>
                </div>))}


            </div>

          </div>}
        {step === 4 &&
          <div className='overflow-y-scroll mx-5 no-scrollbar no-scrollbar'>
            <div className='flex items-center justify-between border-b  mt-4 pb-2'>
              <h6 className='font-[800] text-[14px]'>Dashboard Report ({data?.pagination?.total})</h6>
              <div className='flex items-center justify-center'>
                <IconButton onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>
                <div>
                  <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                  <IconButton onClick={() => { page < data?.pagination?.totalPages ? setpage(page + 1) : console.log('') }} ><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                </div>

                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { setsearch({ ...search, from_date: v, from_date1: v1 }) }} />
                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { setsearch({ ...search, to_date: v, to_date1: v1 }) }} />

                <div className='mr-1.5 rounded-md border border-slate-300 py-[0px]'>
                  <Select
                    placeholder='Select Type'
                    bordered={false}
                    size='small'
                    defaultValue={search?.type}
                    value={search?.type == '' ? null : search?.type}
                    style={{ width: 110 }}
                    onChange={(v) => setsearch({ ...search, type: v })}
                    options={type}
                  />
                </div>

                <div className='mr-2'>
                  <BiFilterAlt onClick={() => applyfilterfunction(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
                </div>

                <RxReset onClick={resetfilter} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />

              </div>
            </div>
            <div className='grid grid-cols-5 mt-2 gap-5 '>

              <div className='px-2  bg-yellow-200 rounded py-2 w-full' >
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><IoCallSharp size={23} className='mr-2 bg-white p-1 text-slate-500' />Calls</h6>
                <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f => f?._id === 'Calls') !== undefined && data?.analytics?.find(f => f?._id === 'Calls') !== null) ? data?.analytics?.find(f => f?._id === 'Calls')?.count : 0)?.split('.')[0].slice(1)}</h6>
              </div>

              <div className='px-2  bg-blue-200 rounded py-2 w-full' >
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineMeetingRoom size={23} className='mr-2 bg-white p-1 text-slate-500' />Meeting</h6>
                <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f => f?._id === 'Meeting') !== undefined && data?.analytics?.find(f => f?._id === 'Meeting') !== null) ? data?.analytics?.find(f => f?._id === 'Meeting')?.count : 0)?.split('.')[0].slice(1)}</h6>
              </div>

              <div className='px-2  bg-red-200 rounded py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineEventNote size={23} className='mr-2 bg-white p-1 text-slate-500' />Event</h6>
                <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f => f?._id === 'Event') !== undefined && data?.analytics?.find(f => f?._id === 'Event') !== null) ? data?.analytics?.find(f => f?._id === 'Event')?.count : 0)?.split('.')[0].slice(1)}</h6>
              </div>


              <div className='px-2  bg-green-200 rounded py-2 w-full' >
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><TbLayoutSidebarLeftExpand size={23} className='mr-2 bg-white p-1 text-slate-500' />Out of Office</h6>
                <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f => f?._id === 'Out of office') !== undefined && data?.analytics?.find(f => f?._id === 'Out of office') !== null) ? data?.analytics?.find(f => f?._id === 'Out of office')?.count : 0)?.split('.')[0].slice(1)}</h6>
              </div>


              <div className='px-2  bg-violet-200 rounded py-2 w-full'>
                <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineFeaturedPlayList size={23} className='mr-2 bg-white p-1 text-slate-500' />Task</h6>
                <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f => f?._id === 'Task') !== undefined && data?.analytics?.find(f => f?._id === 'Task') !== null) ? data?.analytics?.find(f => f?._id === 'Task')?.count : 0)?.split('.')[0].slice(1)}</h6>
              </div>

            </div>
            <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
              <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r '>Type</h6>
                <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Title</h6>
                <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Description</h6>
                <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r '>Task Type</h6>
                <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[600] text-slate-600 border-r '>Department</h6>
                <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600'>Remarks</h6>
              </div>

              {data?.datas?.map((d, i) => (
                <div key={d?._id} className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                  <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[500]  border-r '>{(page > 1 ? i + 1 + (data?.pagination?.limit * (page - 1)) : i + 1)}</h6>
                  <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[500]  border-r '>{d?.type !== '' && <span className='bg-blue-100 text-[10px] p-[4px] font-[600] rounded'>{d?.type}</span>}</h6>
                  <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.title}</h6>
                  <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.description}</h6>
                  <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[500]  border-r '>{!['', null, undefined, 'null', 'undefined']?.includes(d?.calendar_type) && <span className={` ${d?.calendar_type == 'New' ? 'bg-green-100' : 'bg-red-100'} text-[10px] p-[4px] font-[600] rounded`}>{d?.calendar_type}</span>}</h6>
                  <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[500]  border-r '>{d?.department?.department_name}</h6>
                  <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500] '>{d?.remarks}</h6>
                </div>))}


            </div>

          </div>}

        {step === 5 &&
          <div className="w-[100%] px-4 pt-4">
            <div className='flex  items-center justify-between  border-b pb-2 '>
              <h6 className='font-[700] text-[14px] '>Total Leads Shared</h6>
              <div className='flex items-center text-[12px] '>

                <h6 className='mr-2 font-[600]'>{page == 1 ? leads?.datas?.length > 0 ? 1 : 0 : (page - 1) * leads?.pagination?.limit} - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                  <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                  <IconButton onClick={() => { page < leads?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>

                </div>
                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, from_date: v, from_date1: v1 }) }} />
                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, to_date: v, to_date1: v1 }) }} />
                <div>
                  <input id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e) => setsearch({ ...search, text: e.target.value })} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 mr-2 rounded-md border-slate-300' />
                </div>

                <div className='mr-2'>
                  <Select
                    bordered={false}
                    placeholder={'Department'}
                    className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px] rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                    optionFilterProp="children"
                    onChange={(e) => setsearch({ ...search, department: e })}
                    value={search?.department === '' ? null : search?.department}
                    options={department}
                  />
                </div>
                <AiOutlineFilter onClick={applyfilter} size={24} className='cursor-pointer bg-slate-700 p-[5px] rounded text-white mr-2' />
                {/* {department_FCPL_FTS_BACKEND && */}
                  <div onClick={() => setSubStep(subStep == 1 ? 2 : 1)} className='flex cursor-pointer items-center px-[7px]'>
                    <BiTable size={20} className={`${subStep == 1 ? 'bg-slate-700 text-white' : 'text-black bg-gray-100'} px-[3px] rounded mr-1 py-[2px]`} />
                    <PiChartBarBold size={20} className={`${subStep == 2 ? 'bg-slate-700 text-white' : 'text-black bg-gray-100'} px-[3px] rounded mr-1 py-[2px]`} />
                  </div>
                  {/* } */}
              </div>
            </div>
            <>
              <>
                {subStep === 1 &&
                  <>
                    <>
                      {leads?.datas?.length > 0 &&
                        <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                          <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                            <h6 className='top-0 bg-white z-50 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Info</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Recieved Department</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[180px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Converted</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[180px] px-2 py-1 font-[600] text-slate-600 border-r'>Summary</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 '>Created On</h6>
                          </div>

                          {leads?.datas?.map((d, i) => (
                            <div key={d?._id} className={`flex z-50 h-full border-b border-slate-200 z-0`}  >
                              <h6 className='sticky top-0 z-30 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex justify-center'>{page === 1 ? i + 1 : (i + 1) + ((page - 1) * leads?.pagination?.limit)}</h6>
                              <h6 className='text-[12px] font-[500] w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d?.fts_lead?.company_name}</h6>
                              <h6 className='text-[12px] font-[500] w-[180px] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                                <div>
                                  <h6>{d?.fts_lead?.contact_name}</h6>
                                  <span>{d?.fts_lead?.phone} / {d?.fts_lead?.email}  </span>
                                </div>
                              </h6>
                              <h6 className='text-[12px] font-[500] w-[150px] px-2 py-1 break-all border-r border-slate-200'>
                                <span className='text-sky-500 text-[11px] font-[600]'>{d?.department_id?.department_name}</span>
                              </h6>
                              <h6 className={`text-[12px] font-[500] w-[180px] px-2 py-1  truncate  border-r  border-slate-200 ${d?.requirment ? 'block' : 'text-[#fff]'}`}>{d?.requirment ? d?.requirment : 'Not Added'}</h6>
                              <h6 className='text-[12px] text-center font-[500] w-[80px] px-2 py-1 truncate border-r  border-slate-200'>{d?.converted === '5' && <span className='bg-orange-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '5' && 'Lead Generated'}</span>} {d?.converted === '1' && <span className='bg-green-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '1' && 'Converted'}</span>} {d?.converted === '2' && <span className='bg-red-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '2' && 'Rejected'}</span>} {d?.converted === '3' && <span className='bg-yellow-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '3' && 'Pending'}</span>} {d?.converted === '4' && <span className='bg-blue-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '4' && 'Progress'}</span>} </h6>
                              <h6 className={`text-[12px] font-[500] w-[180px] px-2 py-1 truncate border-r  border-slate-200 ${d?.remarks ? 'block' : 'text-[#fff]'}`}>{d?.remarks ? d?.remarks : 'Not Added'}</h6>

                              <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>

                            </div>))}
                        </div>}
                    </>
                  </>
                }
              </>
              {subStep === 2 &&

                <>
                  <div className="overflow-x-auto mt-2">
                    <div className="w-full border text-sm text-left">

                      <div className="flex items-center text-slate-600 text-[11.5px] font-[700]">
                        <h6 className="p-1 w-[15%] text-slate-600">(FTS/CORP/BACKEND)</h6>
                        {department?.map((d) => (
                          <h6 key={d?._id} className="p-1 border-l text-slate-600 uppercase min-w-[14.33%] max-w-[14.33%] text-center">{d?.department_name}</h6>
                        ))}
                      </div>

                      <div>
                        <div className="border-t flex  items-center">
                          <h6 className="p-1 text-[13px] text-gray-700  font-bold w-[15%]">Leads</h6>
                          <h6 onClick={() => navigateDetail('Transaction Team')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('Transaction Team')}</h6>
                          <h6 onClick={() => navigateDetail('Project Team')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('Project Team')}</h6>
                          <h6 onClick={() => navigateDetail('HR Team')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('HR Team')}</h6>
                          <h6 onClick={() => navigateDetail('FMS Team')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('FMS Team')}</h6>

                          <h6 onClick={() => navigateDetail('Fidelitus Gallery')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('Fidelitus Gallery')}</h6>
                          <h6 onClick={() => navigateDetail('Shilpa Foundation')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('Shilpa Foundation')}</h6>
                        </div>

                        <div className="border-t flex  items-center">
                          <h6 className="p-1 text-[13px] text-gray-700  font-bold w-[15%]">Estimated Revenue</h6>
                          <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('Transaction Team'))}</h6>
                          <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('Project Team'))}</h6>
                          <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('HR Team'))}</h6>
                          <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('FMS Team'))}</h6>
                          <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('Fidelitus Gallery'))}</h6>
                          <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('Shilpa Foundation'))}</h6>
                        </div>

                        <div className="border-t flex  items-center">
                          <h6 className="p-1 text-[13px] text-gray-700  font-bold w-[15%]">Actual Revenue</h6>
                          <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('Transaction Team'))}</h6>
                          <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('Project Team'))}</h6>
                          <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('HR Team'))}</h6>
                          <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('FMS Team'))}</h6>
                          <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('Fidelitus Gallery'))}</h6>
                          <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('Shilpa Foundation'))}</h6>
                        </div>



                      </div>
                    </div>
                  </div>

                  <div className="flex mt-4 justify-between items-start">
                    <div className="flex-[0.3] relative h-[280px] border mr-2 border-slate-200">
                      <h6 className='font-[800] text-[13px] px-2 pt-2 text-gray-700' >Lead Forecast</h6>
                      <div className='absolute top-[140px] left-[100px] z-50' >
                        <h6 className='font-[800] text-center text-[11px]'>Total Leads Assigned</h6>
                        <h6 className='text-center text-blue-600 font-[900]'>{retutnTotalValue("leads")}</h6>
                      </div>
                      <ReactApexChart options={leadsChartOptions} series={seriesData} type="donut" width={320} />
                    </div>
                    <div className="flex-[0.7] h-[280px] border border-slate-200">

                      <h6 className='font-[800] text-[13px] px-2 pt-2 text-gray-700'>Revenue Forecast</h6>
                      <ReactApexChart
                        options={barChartOptions}
                        series={[
                          { name: "Estimation Revenue", data: estimationValuesforChart },
                          { name: "Actual Revenue", data: actualEstimationValuessforChart }
                        ]}
                        type="bar"
                        width={'100%'}
                        height={250}
                      />
                    </div>
                  </div>

                  <div className="flex mt-4 justify-between items-start">
                    <div className='w-[30%]'>

                      <h6 className='font-[700] text-[13px] border-b pb-1 mb-1 text-gray-700'>Total Analytics Report</h6>
                      <div className='flex border'>
                        <div className='w-[50%]'>
                          <h6 className="p-1 text-[13px] border-r text-slate-600 font-bold">Leads</h6>
                          <h6 className="p-1 text-[13px] border-r border-t text-slate-600 font-bold">Estimated Revenue</h6>
                          <h6 className="p-1 text-[13px] border-r border-t text-slate-600 font-bold">Actual Revenue</h6>
                        </div>
                        <div className='w-[50%]'>
                          <h6 className="p-1 text-[13px] text-blue-600 font-bold">{retutnTotalValue('leads')}</h6>
                          <h6 className="p-1 text-[13px] border-t font-bold">{Priceconstants(retutnTotalValue('estimated_revenue'))}</h6>
                          <h6 className="p-1 text-[13px] border-t font-bold">{Priceconstants(retutnTotalValue('actual_revenue_achieved'))}</h6>
                        </div>
                      </div>
                    </div>

                  </div>
                </>


              }

            </>
            {leads?.datas?.length === 0 &&
              <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' />
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
              </div>
            }
          </div>
        }
        {step === 6 &&
          <div className='overflow-y-scroll relative no-scrollbar no-scrollbar'>
            <AdminProfitLoss />

          </div>}
        {step === 7 &&

          <div className='overflow-y-scroll relative no-scrollbar no-scrollbar'>

            <Modal
              keepMounted
              open={modal}
              onClose={() => setmodal(false)}
              width={300}
              footer={false}
              closable={false}

            >
              <div >
                <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
                <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
                <div className='flex justify-end mt-3 '>
                  {/* <div className='mr-1 w-full'> */}
                  <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={() => setmodal(false)} />
                  {/* </div> */}
                  <div className='ml-2'>
                    <ButtonFilledAutoWidth btnName={'Confirm'} onClick={() => deleteData()} />
                  </div>
                </div>
              </div>
            </Modal>

            <Modal
              keepMounted
              open={modal2}
              onClose={() => setmodal2(false)}
              width={300}
              footer={false}
              closable={false}

            >
              <div >
                <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
                <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
                <div className='flex justify-end mt-3 '>
                  {/* <div className='mr-1 w-full'> */}
                  <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={() => setmodal2(false)} />
                  {/* </div> */}
                  <div className='ml-2'>
                    <ButtonFilledAutoWidth btnName={'Confirm'} onClick={() => deleteData1()} />
                  </div>
                </div>
              </div>
            </Modal>

            <Modal
              keepMounted
              open={modal3}
              onClose={() => setmodal3(false)}
              width={300}
              footer={false}
              closable={false}

            >
              <div >
                <h6 className="font-bold text-[13px] w-full">Create / Edit Followup</h6>
                <h6 className='bg-slate-100 text-[12px] p-2 leading-[14px] font-[400]'>Are you sure want to create or edit the followup for the selected data </h6>


                <TextInput
                  label={'Date'}
                  name={'date'}
                  type="date"
                  mandatory={true}
                  value={selectedData1?.date}
                  handlechange={(e) => setselectedData1({ ...selectedData1, date: e.target.value })}
                />


                <TextAreaInput1
                  label={'Remarks'}
                  name={'remarks'}
                  mandatory={true}
                  value={selectedData1?.remarks}
                  handlechange={(e) => setselectedData1({ ...selectedData1, remarks: e.target.value })}
                />


                <div className='flex justify-end mt-3 '>
                  <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={() => setmodal3(false)} />
                  <div className='ml-2'>
                    <ButtonFilledAutoWidth btnName={'Confirm'} onClick={() => submitDataFollowUp()} />
                  </div>
                </div>
              </div>
            </Modal>
            <Drawer open={modal1} closable={false}>
              <div className='relative'>
                <AiOutlineClose onClick={() => setmodal1(false)} className='absolute right-0' />

                <h6 className='text-[14px] font-[700]'>Followup Tracker ({followup?.length})</h6>
                <h6 className='text-[12px] bg-slate-100 p-2 my-1'>Are you sure  want to check all the followup tracks made for the selected Lead</h6>

                <div className='border-t border-r border-l'>
                  {followup?.map((g, i) => (
                    <div key={i} className='px-2 py-1 border-b  relative'>
                      <h6 className='text-[11px]'><span className='font-[600]'>Follow up Date : </span>{moment(g?.date)?.format('LL')}</h6>

                      <h6 className='text-[10.5px] bg-slate-100 p-2'><span>Remarks : </span>{g?.remarks}</h6>
                      <h6 className='text-[9px]'><span className='font-[600]'>Created Date : </span>{moment(g?.createdAt)?.format('LLL')}</h6>
                      <h6 className='text-[9px]'><span className='font-[600]'>Created By : </span>{g?.created_by?.name}</h6>

                      <div className='flex absolute top-0 right-0 bg-white p-1 border-l border-b items-center'>
                        <AiOutlineEdit size={14} className='p-[2px] pr-[4px] border-r' onClick={() => { setselectedData1({ ...g, date: g?.date?.slice(0, 10) }); setmodal3(true) }} />
                        {(roles?.includes('admin') || roles?.includes('lawyer_hod')) &&
                          <AiOutlineDelete size={14} className='p-[2px] pl-[4px]' onClick={() => { setselectedData1(g); setmodal2(true) }} />}
                      </div>
                    </div>
                  ))}
                </div>

              </div>
            </Drawer>
            {caseStep?.datas?.length == 0 &&
              <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' />
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
              </div>
            }
            {caseStep?.datas?.length > 0 &&
              <div className='max-h-[82vh] mt-4 border-t w-[100%]  border-l border-r overflow-y-scroll'>
                <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                  <h6 className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'> SL NO </h6>
                  <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Name</h6>
                  <h6 className='sticky top-0 z-50  text-[12px] w-[200px]  px-2 py-1 font-[600] text-slate-600 border-r'>Description</h6>
                  <h6 className='top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Recieved Date</h6>
                  <h6 className='top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Department</h6>
                  <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Status</h6>
                  <h6 className='sticky top-0 z-50  text-[12px] w-[200px] px-2 py-1 font-[600] text-slate-600 border-r'>Remarks</h6>
                  <h6 className='sticky top-0 z-50  text-[12px] w-[130px] px-2 py-1 font-[600] text-slate-600 border-r'>Next Hearing Date</h6>
                  <h6 className='sticky top-0 z-50  text-[12px] w-[80px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                </div>
                {caseStep?.datas?.map((d, i) => (
                  <div key={i} className=' flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6 className=' bg-white  text-[12px] w-[60px]  px-2 py-1 font-[400]  border-r flex  justify-center'> {page === 1 ? i + 1 : (i + 1) + ((page - 1) * data?.pagination?.limit)}</h6>
                    <h6 className='  text-[12px] w-[140px] px-2 py-1 font-[400]  border-r'>{d?.caseBoughtBy}</h6>
                    <h6 className='  text-[12px] w-[200px]  px-2 py-1 font-[400]  border-r'>{d?.caseDescription} <br></br> {(d?.attachment !== null && d?.attachment !== undefined && d?.attachment !== '') && <a href={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.attachment?.replace('public/', '')}`} target='_blank'><span className='text-slate-700 cursor-pointer text-[10px] font-[800] underline'>Download File</span></a>}</h6>
                    <h6 className='  text-[12px] w-[120px]  px-2 py-1 font-[400]  border-r'>{moment(d?.caseRecievedDate)?.format('LL')}</h6>
                    <h6 className='  text-[12px] w-[120px]  px-2 py-1 font-[400]  border-r'>{d?.department?.department_name}</h6>
                    <h6 className='  text-[12px] w-[100px] px-2 py-1 font-[400]  border-r'>{d?.stage}</h6>
                    <h6 className='  text-[12px] w-[200px] px-2 py-1 font-[400]  border-r'>{d?.remarks}</h6>
                    <h6 className='  text-[12px] w-[130px] px-2 py-1 font-[400] border-r '>{(d?.caseRehearingDate !== '' && d?.caseRehearingDate !== undefined && d?.caseRehearingDate !== null) && moment(d?.caseRehearingDate)?.format('LL')}</h6>
                    <h6 className='  text-[12px] w-[80px]  px-2 py-1 flex font-[600] text-slate-600 sticky right-0'>

                      <MdOutlineEventRepeat size={16} className='mr-2' onClick={() => { setselectedData(d); setselectedData1({ date: '', remarks: '' }); setmodal3(true) }} />
                      <RiBillLine size={14} className='mr-2' onClick={() => { setmodal1(true); setselectedData(d); getfollwup(d) }} />
                      {/* <AiOutlineEdit size={14} className='mr-2' onClick={() => { navigate('govt/cases/edit', { state: d }) }} /> */}
                      {/* {(roles?.includes('admin') || roles?.includes('lawyer_hod')) &&
                        <AiOutlineDelete size={14} onClick={() => { setselectedData(d); setmodal(true) }} />} */}
                    </h6>


                  </div>))}
              </div>}

          </div>}



        <div>

        </div>
      </div>

    </div>
  )
}

export default AdminDashboard








//////////================================================>>>>>>>>Old

// import React, { useState, useEffect } from 'react'
// import DashboardMenu from '../dashboard/DashboardMenu'
// import { FilterCalendarService } from '../../services/CalendarServices'
// import { IconButton } from "@mui/material";
// import { MdOutlineEventNote } from "react-icons/md";
// import { IoCallSharp } from "react-icons/io5";
// import { MdOutlineMeetingRoom } from "react-icons/md";
// import { BiFilterAlt } from "react-icons/bi";
// import { TbLayoutSidebarLeftExpand } from "react-icons/tb";
// import { GetFTSLeadsSharedToFTSBasedUserAndDepartmentService } from '../../services/FTSLeadServices';
// import { GetVericalBasedAssignedRecievedBasedUserService } from '../../services/ftsVerticalReportServices/FTSVerticalLeadReportServices';

// import { GetDepartmentService } from '../../services/DepartmentServices';
// import { AiOutlineFilter } from 'react-icons/ai';
// import { MdOutlineFeaturedPlayList } from "react-icons/md";
// import { BsArrowRepeat } from 'react-icons/bs';
// import { RxReset } from "react-icons/rx";
// import { DatePicker, Select } from 'antd';
// import { useNavigate } from 'react-router-dom';
// import { ButtonFilledAutoWidth } from '../../components/button';
// import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
// import { useSelector } from 'react-redux';
// import { BiTable } from "react-icons/bi";
// import { RiLayoutGridLine } from "react-icons/ri";
// import moment from 'moment';
// import AdminProfitLoss from '../dashboard/AdminProfitLoss';
// import ReactApexChart from 'react-apexcharts';
// import { PiChartBarBold } from "react-icons/pi";
// import Priceconstants from '../../constants/imageConstants';




// function AdminDashboard() {
//   const navigate = useNavigate()
//   const [step, setstep] = useState(1)
//   const [subStep, setSubStep] = useState(1);
//   const [data, setdata] = useState({})
//   const [eventData, seteventData] = useState({})
//   const [meetingData, setmeetingData] = useState({})
//   const [taskData, settaskData] = useState({})
//   const [data1, setdata1] = useState({})


//   const [page, setpage] = useState(1)
//   const [page1, setpage1] = useState(1)
//   const [page2, setpage2] = useState(1)
//   const [page3, setpage3] = useState(1)

//   //for section two
//   const [leads, setleads] = useState({ datas: [], pagination: { total: 0, totalPages: 0 } })
//   const [department, setdepartment] = useState([])
//   const [search, setsearch] = useState({ text: '', from_date: '', to_date: '', from_date1: '', to_date1: '', department: '', activate: false })
//   const [search1, setsearch1] = useState({ text: '', from_date: '', to_date: '', from_date1: '', to_date1: '', department: '', activate: false })
//   const [search2, setsearch2] = useState({ text: '', from_date: '', to_date: '', from_date1: '', to_date1: '', department: '', activate: false })
//   const [search3, setsearch3] = useState({ text: '', from_date: '', to_date: '', from_date1: '', to_date1: '', department: '', activate: false })

//   const type = [{ label: 'Event', value: 'Event' }, { label: 'Out of office', value: 'Out of office' }, { label: 'Task', value: 'Task' }, { label: 'Meeting', value: 'Meeting' }, { label: 'Calls', value: 'Calls' }]

//   //getDepartments 
//   let user = useSelector(state => state.Auth)
//   useEffect(() => {
//     getdata()
//     getdata1()
//     getCalenderData()
//     getdepartments()
//   }, [page])


//   const department_FCPL_FTS_BACKEND = user?.department_id?.filter((f) => f?.department_name == 'FTS Team' || f?.department_name == 'Corp Team' || f?.department_name == 'Backend Team')?.length > 0 ? true : false

//   console.log("@@@@@@@@", data)






//   async function getdata() {
//     const response = await GetFTSLeadsSharedToFTSBasedUserAndDepartmentService(page, search?.text, search?.from_date1, search?.to_date1, search?.department)
//     setleads(response?.data);
//   }


//   async function getdata1() {
//     const response = await GetVericalBasedAssignedRecievedBasedUserService(search?.from_date1, search?.to_date1, '', '', page)
//     setdata1(response?.data?.data)
//   }

//   async function getCalenderData() {
//     const response = await FilterCalendarService(search?.from_date1, search?.to_date1, search?.type, page)
//     setdata(response?.data)
//   }
//   //Department
//   // async function getdepartments() {
//   //   const response = await GetDepartmentService()
//   //   let arr = []
//   //   response.data.datas.forEach((d) => {
//   //     if (!['Finance Team', 'Software Team', 'Backend Team', 'Digital Media']?.includes(d?.department_name)) {
//   //       if (user.department_id[0].department_name !== d.department_name) {
//   //         arr.push({ value: d.id, label: d.department_name })
//   //       }
//   //     }
//   //     setdepartment(arr)
//   //   })

//   // }
//   async function getdepartments() {
//     const response = await GetDepartmentService()

//     let d = response?.data?.datas

//     let arr = []
//     let transaction = d?.find((f) => f?.department_name === "Transaction Team")
//     let project = d?.find((f) => f?.department_name === "Project Team")
//     let hr = d?.find((f) => f?.department_name === "HR Team")
//     let fms = d?.find((f) => f?.department_name === "FMS Team")
//     let fidelitus_gallery = d?.find((f) => f?.department_name === "Fidelitus Gallery")
//     let shilpa_foundation = d?.find((f) => f?.department_name === "Shilpa Foundation")

//     if (![null, undefined, '', 'null', 'undefined']?.includes(transaction)) {
//       arr.push(transaction)
//     }
//     if (![null, undefined, '', 'null', 'undefined']?.includes(project)) {
//       arr.push(project)
//     }
//     if (![null, undefined, '', 'null', 'undefined']?.includes(hr)) {
//       arr.push(hr)
//     }
//     if (![null, undefined, '', 'null', 'undefined']?.includes(fms)) {
//       arr.push(fms)
//     }
//     if (![null, undefined, '', 'null', 'undefined']?.includes(fidelitus_gallery)) {
//       arr.push(fidelitus_gallery)
//     }
//     if (![null, undefined, '', 'null', 'undefined']?.includes(shilpa_foundation)) {
//       arr.push(shilpa_foundation)
//     }
//     setdepartment(arr)

//     // response.data.datas.forEach((d) => {
//     //   if (!['Finance Team', 'Software Team', 'Backend Team', 'Digital Media']?.includes(d?.department_name)) {
//     //     if (user.department_id[0].department_name !== d.department_name) {
//     //       arr.push({ value: d.id, label: d.department_name })
//     //     }
//     //   }
//     //   setdepartment(arr)
//     // })

//   }


//   ///chart related data 

//   const teams = [
//     "Transaction Team",
//     "Project Team",
//     "HR Team",
//     "FMS Team",
//     "Fidelitus Gallery",
//     "Shilpa Foundation",
//   ];

//   const seriesData = teams.map((team) => getLeadCount(team));

//   const leadsChartOptions = {
//     chart: { type: 'donut' },
//     labels: teams,
//     colors: ["#FF4560", "#008FFB", "#00E396", "#FEB019", "#775DD0", "#546E7A"],
//     dataLabels: { enabled: false },
//     legend: { show: false }
//   };


//   const teamNames = [
//     "Transaction Team",
//     "Project Team",
//     "HR Team",
//     "FMS Team",
//     "Fidelitus Gallery",
//     "Shilpa Foundation"
//   ];

//   function numDifferentiation(value) {
//     const val = Math.abs(value)
//     if (val >= 10000000) return `${(value / 10000000).toFixed(0)} Cr`
//     if (val >= 100000) return `${(value / 100000).toFixed(0)} Lac`
//     return value;
//   }

//   const estimationValuesforChart = teamNames.map((team) => getEstiamtedRevenue(team));
//   const actualEstimationValuessforChart = teamNames.map((team) => getActualRevenue(team));

//   const barChartOptions = {
//     chart: {
//       type: "bar",
//       height: 350,
//       toolbar: { show: false }
//     },
//     chart: {
//       toolbar: {
//         show: false
//       }
//     },
//     plotOptions: {

//       bar: {
//         horizontal: false,
//         dataLabels: {
//           enabled: false
//         },
//         columnWidth: '55%',
//         endingShape: 'rounded',
//         dataLabels: {
//           position: 'top',
//         },

//       },
//     },
//     dataLabels: {
//       enabled: false
//     },
//     grid: {
//       show: true,
//       borderColor: '#fafafa',
//     },

//     stroke: {
//       show: true,
//       width: 1,
//       curve: 'smooth',
//       colors: ['transparent']
//     },
//     xaxis: {
//       categories: ['Transaction', 'Project', 'HR Labs', 'FMS', 'Fidelitus Gallery', 'Shilpa Foundation'],
//     },
//     yaxis: {
//       title: {
//         text: 'Estimation / Actual Forecast'
//       },
//       labels: {
//         formatter: function (val) {
//           return numDifferentiation(val)
//         }
//       }
//     },
//     fill: {
//       opacity: 1,
//     },
//     tooltip: {
//       y: {
//         formatter: function (val) {
//           return numDifferentiation(val)
//         }
//       }
//     },
//   };

//   //barchart end 
//   function getLeadCount(v) {
//     if (data1.length > 0) {
//       let findData = data1?.find((f) => f?._id?.department_name == v)
//       if (![null, undefined, 'null', 'undefined']?.includes(findData)) {
//         return findData?.count
//       } else {
//         return 0
//       }
//     } else {
//       return 0
//     }
//   }

//   function getEstiamtedRevenue(v) {
//     if (data1.length > 0) {
//       let findData = data1?.find((f) => f?._id?.department_name == v)
//       if (![null, undefined, 'null', 'undefined']?.includes(findData)) {
//         return findData?.estimated_revenue
//       } else {
//         return 0
//       }
//     } else {
//       return 0
//     }
//   }

//   function getActualRevenue(v) {
//     if (data1.length > 0) {
//       let findData = data1?.find((f) => f?._id?.department_name == v)
//       if (![null, undefined, 'null', 'undefined']?.includes(findData)) {
//         return findData?.actual_revenue_achieved
//       } else {
//         return 0
//       }
//     } else {
//       return 0
//     }
//   }

//   function retutnTotalValue(type) {
//     if (data1.length > 0) {
//       let total = 0
//       if (type == 'leads') {
//         total += data1?.reduce((a, d) => a + d?.count, 0)
//         return total
//       }

//       if (type == 'estimated_revenue') {
//         total += data1?.reduce((a, d) => a + d?.estimated_revenue, 0)
//         return total
//       }

//       if (type == 'actual_revenue_achieved') {
//         total += data1?.reduce((a, d) => a + d?.actual_revenue_achieved, 0)
//         return total
//       }
//     } else {
//       return 0
//     }
//   }




//   let rupeeIndian = Intl.NumberFormat("en-IN", {
//     style: "currency",
//     currency: "INR",
//   });



//   async function setstepfunc(v) {
//     if (v === 1) {
//       setstep(1)
//       const response = await FilterCalendarService(search1?.from_date1, search1?.to_date1, "Event", page1)
//       seteventData(response?.data)
//     } else if (v == 2) {
//       setstep(2)
//       const response = await FilterCalendarService(search2?.from_date1, search2?.to_date1, "Meeting", page2)
//       setmeetingData(response?.data)
//     } else if (v == 3) {
//       setstep(3)
//       const response = await FilterCalendarService(search3?.from_date1, search3?.to_date1, "Task", page3)
//       settaskData(response?.data)
//     } else if (v == 4) {
//       setstep(4)
//       const response = await FilterCalendarService(search?.from_date1, search?.to_date1, search?.type, page)
//       setdata(response?.data)
//     } else if (v == 5) {
//       setstep(5)
//     } else if (v == 6) {
//       setstep(6)
//     } else if (v == 7) {
//       setstep(7)
//     }
//   }


//   async function applyfilterfunction() {
//     setpage(1)
//     const response = await FilterCalendarService(search?.from_date1, search?.to_date1, search?.type, 1)
//     setdata(response?.data)
//   }

//   async function resetfilter() {
//     setpage(1)
//     setsearch({ text: '', from_date: '', from_date1: '', to_date: '', to_date1: '', type: '', activate: false })
//     const response = await FilterCalendarService('', '', '', 1)
//     setdata(response?.data)
//   }


//   //section 2
//   async function resetfunc() {
//     setpage(1)
//     setsearch({ text: '', from_date: '', from_date1: '', to_date: '', to_date1: '', department: '' })
//     const response = await GetFTSLeadsSharedToFTSBasedUserAndDepartmentService(1, '', '', '', '')
//     setleads(response?.data)
//   }

//   async function addData() {
//     navigate('/fts_leads/list/create',)

//   }

//   async function applyfilter() {
//     setpage(1)
//     const response = await GetFTSLeadsSharedToFTSBasedUserAndDepartmentService(1, search?.text, search?.from_date1, search?.to_date1, search?.department)
//     setleads(response?.data)
//   }

//   async function applyfilterfunctiontype(v) {
//     if (step == 1) {
//       setpage1(1)
//       const response = await FilterCalendarService(search1.from_date1, search1?.to_date1, "Event", 1)
//       seteventData(response?.data)
//     }

//     if (step == 2) {
//       setpage2(1)
//       const response = await FilterCalendarService(search2.from_date1, search2?.to_date1, "Meeting", 1)
//       setmeetingData(response?.data)
//     }

//     if (step == 3) {
//       setpage3(1)
//       const response = await FilterCalendarService(search3.from_date1, search3?.to_date1, "Task", 1)
//       settaskData(response?.data)
//     }

//   }

//   async function resetfiltertype() {
//     if (step == 1) {
//       setpage1(1)
//       setsearch1({ text: '', from_date: '', from_date1: '', to_date: '', to_date1: '', type: '', activate: false })
//       const response = await FilterCalendarService('', '', 'Event', 1)
//       seteventData(response?.data)
//     }

//     if (step == 2) {
//       setpage2(1)
//       setsearch2({ text: '', from_date: '', from_date1: '', to_date: '', to_date1: '', type: '', activate: false })
//       const response = await FilterCalendarService('', '', 'Meeting', 1)
//       seteventData(response?.data)
//     }

//     if (step == 3) {
//       setpage3(1)
//       setsearch2({ text: '', from_date: '', from_date1: '', to_date: '', to_date1: '', type: '', activate: false })
//       const response = await FilterCalendarService('', '', 'Task', 1)
//       settaskData(response?.data)
//     }
//   }


//   console.log("taskData", taskData)



//   async function navigateDetail() {

//   }

//   return (
//     <div className='flex max-h-screen min-h-screen  '>
//       <DashboardMenu />
//       {/* {Priceconstants} */}
//       <div className='mx-5 w-[85%] mb-10'>
//         <div className='flex items-center justify-between  pb-2 pt-2'>
//           <h6 className='font-[700] text-[14px] '>MD Dashboard 123</h6>

//         </div>

//         <div className='flex bg-slate-100 items-center justify-center py-[4px]'>
//           <h6 onClick={() => setstepfunc(1)} className={`cursor-pointer py-[4px] rounded ${step == 1 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px]  text-center text-[12px] font-[600] ml-2`}>Events</h6>
//           <h6 onClick={() => setstepfunc(2)} className={`cursor-pointer py-[4px] rounded ${step == 2 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px]  text-center text-[12px] font-[600] ml-2`}>Meetings</h6>
//           <h6 onClick={() => setstepfunc(3)} className={`cursor-pointer py-[4px] rounded ${step == 3 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px]  text-center text-[12px] font-[600] ml-2`}>Task</h6>
//           <h6 onClick={() => setstepfunc(7)} className={`cursor-pointer py-[4px] rounded ${step == 7 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px]  text-center text-[12px] font-[600] ml-2`}>Litigations</h6>

//           <h6 onClick={() => setstepfunc(4)} className={`-ml-4 cursor-pointer py-[4px] rounded ${step == 4 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px] mr-2 text-center text-[12px] font-[600] ml-2`}>My Work</h6>
//           <h6 onClick={() => setstepfunc(5)} className={`cursor-pointer py-[4px] rounded ${step == 5 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px] mr-2 text-center text-[12px] font-[600]`}>Lead Assigned</h6>
//           <h6 onClick={() => setstepfunc(6)} className={`cursor-pointer py-[4px] rounded ${step == 6 ? 'bg-slate-600 text-white' : 'bg-white'} rounded-[4px] min-w-[100px] max-w-[100px]  text-center text-[12px] font-[600]`}>Finance</h6>

//         </div>
//         {step === 1 &&
//           <div className='overflow-y-scroll relative no-scrollbar no-scrollbar'>
//             <div className='flex items-center justify-between border-b  mt-4 pb-2'>
//               <h6 className='font-[800] text-[14px]'>Event List ({eventData?.pagination?.total})</h6>
//               <div className='flex items-center justify-center'>
//                 <IconButton onClick={resetfiltertype} ><BsArrowRepeat size={16} /></IconButton>
//                 <div>
//                   <IconButton onClick={() => { page1 > 1 ? setpage1(page1 - 1) : console.log('') }}><FiChevronLeft className={`${page1 === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                   <IconButton onClick={() => { page1 < eventData?.pagination?.totalPages ? setpage1(page1 + 1) : console.log('') }} ><FiChevronRight className={`${(eventData?.pagination?.totalPages === page1 || eventData?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                 </div>

//                 <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search1?.from_date} onChange={(v, v1) => { setsearch1({ ...search1, from_date: v, from_date1: v1 }) }} />
//                 <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search1?.to_date} onChange={(v, v1) => { setsearch1({ ...search1, to_date: v, to_date1: v1 }) }} />


//                 <div className='mr-2'>
//                   <BiFilterAlt onClick={() => applyfilterfunctiontype(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
//                 </div>

//                 <RxReset onClick={resetfiltertype} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />

//               </div>
//             </div>

//             <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
//               <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
//                 <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
//                 <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r '>Type</h6>
//                 <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Title</h6>
//                 <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Description</h6>
//                 <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r '>Task Type</h6>
//                 <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[600] text-slate-600 border-r '>Department</h6>
//                 <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600'>Remarks</h6>
//               </div>

//               {eventData?.datas?.map((d, i) => (
//                 <div key={d?._id} className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
//                   <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[500]  border-r '>{(page > 1 ? i + 1 + (eventData?.pagination?.limit * (page - 1)) : i + 1)}</h6>
//                   <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[500]  border-r '>{d?.type !== '' && <span className='bg-blue-100 text-[10px] p-[4px] font-[600] rounded'>{d?.type}</span>}</h6>
//                   <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.title}</h6>
//                   <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.description}</h6>
//                   <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[500]  border-r '>{!['', null, undefined, 'null', 'undefined']?.includes(d?.calendar_type) && <span className={` ${d?.calendar_type == 'New' ? 'bg-green-100' : 'bg-red-100'} text-[10px] p-[4px] font-[600] rounded`}>{d?.calendar_type}</span>}</h6>
//                   <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[500]  border-r '>{d?.department?.department_name}</h6>
//                   <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500] '>{d?.remarks}</h6>
//                 </div>))}


//             </div>

//           </div>}
//         {step === 2 &&
//           <div className='overflow-y-scroll relative no-scrollbar no-scrollbar'>
//             <div className='flex items-center justify-between border-b  mt-4 pb-2'>
//               <h6 className='font-[800] text-[14px]'>Meeting List ({meetingData?.pagination?.total})</h6>
//               <div className='flex items-center justify-center'>
//                 <IconButton onClick={resetfiltertype} ><BsArrowRepeat size={16} /></IconButton>
//                 <div>
//                   <IconButton onClick={() => { page2 > 1 ? setpage2(page2 - 1) : console.log('') }}><FiChevronLeft className={`${page2 === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                   <IconButton onClick={() => { page2 < meetingData?.pagination?.totalPages ? setpage2(page2 + 1) : console.log('') }} ><FiChevronRight className={`${(meetingData?.pagination?.totalPages === page2 || meetingData?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                 </div>

//                 <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search2?.from_date} onChange={(v, v1) => { setsearch2({ ...search2, from_date: v, from_date1: v1 }) }} />
//                 <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search2?.to_date} onChange={(v, v1) => { setsearch2({ ...search2, to_date: v, to_date1: v1 }) }} />


//                 <div className='mr-2'>
//                   <BiFilterAlt onClick={() => applyfilterfunctiontype(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
//                 </div>

//                 <RxReset onClick={resetfiltertype} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />

//               </div>
//             </div>

//             <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
//               <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
//                 <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
//                 <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r '>Type</h6>
//                 <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Title</h6>
//                 <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Description</h6>
//                 <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r '>Task Type</h6>
//                 <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[600] text-slate-600 border-r '>Department</h6>
//                 <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600'>Remarks</h6>
//               </div>

//               {meetingData?.datas?.map((d, i) => (
//                 <div key={d?._id} className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
//                   <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[500]  border-r '>{(page > 1 ? i + 1 + (eventData?.pagination?.limit * (page - 1)) : i + 1)}</h6>
//                   <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[500]  border-r '>{d?.type !== '' && <span className='bg-blue-100 text-[10px] p-[4px] font-[600] rounded'>{d?.type}</span>}</h6>
//                   <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.title}</h6>
//                   <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.description}</h6>
//                   <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[500]  border-r '>{!['', null, undefined, 'null', 'undefined']?.includes(d?.calendar_type) && <span className={` ${d?.calendar_type == 'New' ? 'bg-green-100' : 'bg-red-100'} text-[10px] p-[4px] font-[600] rounded`}>{d?.calendar_type}</span>}</h6>
//                   <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[500]  border-r '>{d?.department?.department_name}</h6>
//                   <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500] '>{d?.remarks}</h6>
//                 </div>))}


//             </div>


//           </div>}
//         {step === 3 &&
//           <div className='overflow-y-scroll relative no-scrollbar no-scrollbar'>
//             <div className='flex items-center justify-between border-b  mt-4 pb-2'>
//               <h6 className='font-[800] text-[14px]'>Task List ({taskData?.pagination?.total})</h6>
//               <div className='flex items-center justify-center'>
//                 <IconButton onClick={resetfiltertype} ><BsArrowRepeat size={16} /></IconButton>
//                 <div>
//                   <IconButton onClick={() => { page3 > 1 ? setpage3(page3 - 1) : console.log('') }}><FiChevronLeft className={`${page3 === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                   <IconButton onClick={() => { page3 < taskData?.pagination?.totalPages ? setpage3(page3 + 1) : console.log('') }} ><FiChevronRight className={`${(taskData?.pagination?.totalPages === page3 || taskData?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                 </div>

//                 <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search3?.from_date} onChange={(v, v1) => { setsearch3({ ...search3, from_date: v, from_date1: v1 }) }} />
//                 <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search3?.to_date} onChange={(v, v1) => { setsearch3({ ...search3, to_date: v, to_date1: v1 }) }} />


//                 <div className='mr-2'>
//                   <BiFilterAlt onClick={() => applyfilterfunctiontype(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
//                 </div>

//                 <RxReset onClick={resetfiltertype} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />

//               </div>
//             </div>

//             <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
//               <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
//                 <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
//                 <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r '>Type</h6>
//                 <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Title</h6>
//                 <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Description</h6>
//                 <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r '>Task Type</h6>
//                 <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[600] text-slate-600 border-r '>Department</h6>
//                 <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600'>Remarks</h6>
//               </div>

//               {taskData?.datas?.map((d, i) => (
//                 <div key={d?._id} className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
//                   <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[500]  border-r '>{(page > 1 ? i + 1 + (eventData?.pagination?.limit * (page - 1)) : i + 1)}</h6>
//                   <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[500]  border-r '>{d?.type !== '' && <span className='bg-blue-100 text-[10px] p-[4px] font-[600] rounded'>{d?.type}</span>}</h6>
//                   <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.title}</h6>
//                   <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.description}</h6>
//                   <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[500]  border-r '>{!['', null, undefined, 'null', 'undefined']?.includes(d?.calendar_type) && <span className={` ${d?.calendar_type == 'New' ? 'bg-green-100' : 'bg-red-100'} text-[10px] p-[4px] font-[600] rounded`}>{d?.calendar_type}</span>}</h6>
//                   <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[500]  border-r '>{d?.department?.department_name}</h6>
//                   <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500] '>{d?.remarks}</h6>
//                 </div>))}


//             </div>

//           </div>}
//         {step === 4 &&
//           <div className='overflow-y-scroll mx-5 no-scrollbar no-scrollbar'>
//             <div className='flex items-center justify-between border-b  mt-4 pb-2'>
//               <h6 className='font-[800] text-[14px]'>Dashboard Report ({data?.pagination?.total})</h6>
//               <div className='flex items-center justify-center'>
//                 <IconButton onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>
//                 <div>
//                   <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                   <IconButton onClick={() => { page < data?.pagination?.totalPages ? setpage(page + 1) : console.log('') }} ><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                 </div>

//                 <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { setsearch({ ...search, from_date: v, from_date1: v1 }) }} />
//                 <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { setsearch({ ...search, to_date: v, to_date1: v1 }) }} />

//                 <div className='mr-1.5 rounded-md border border-slate-300 py-[0px]'>
//                   <Select
//                     placeholder='Select Type'
//                     bordered={false}
//                     size='small'
//                     defaultValue={search?.type}
//                     value={search?.type == '' ? null : search?.type}
//                     style={{ width: 110 }}
//                     onChange={(v) => setsearch({ ...search, type: v })}
//                     options={type}
//                   />
//                 </div>

//                 <div className='mr-2'>
//                   <BiFilterAlt onClick={() => applyfilterfunction(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
//                 </div>

//                 <RxReset onClick={resetfilter} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />

//               </div>
//             </div>
//             <div className='grid grid-cols-5 mt-2 gap-5 '>

//               <div className='px-2  bg-yellow-200 rounded py-2 w-full' >
//                 <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><IoCallSharp size={23} className='mr-2 bg-white p-1 text-slate-500' />Calls</h6>
//                 <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f => f?._id === 'Calls') !== undefined && data?.analytics?.find(f => f?._id === 'Calls') !== null) ? data?.analytics?.find(f => f?._id === 'Calls')?.count : 0)?.split('.')[0].slice(1)}</h6>
//               </div>

//               <div className='px-2  bg-blue-200 rounded py-2 w-full' >
//                 <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineMeetingRoom size={23} className='mr-2 bg-white p-1 text-slate-500' />Meeting</h6>
//                 <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f => f?._id === 'Meeting') !== undefined && data?.analytics?.find(f => f?._id === 'Meeting') !== null) ? data?.analytics?.find(f => f?._id === 'Meeting')?.count : 0)?.split('.')[0].slice(1)}</h6>
//               </div>

//               <div className='px-2  bg-red-200 rounded py-2 w-full'>
//                 <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineEventNote size={23} className='mr-2 bg-white p-1 text-slate-500' />Event</h6>
//                 <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f => f?._id === 'Event') !== undefined && data?.analytics?.find(f => f?._id === 'Event') !== null) ? data?.analytics?.find(f => f?._id === 'Event')?.count : 0)?.split('.')[0].slice(1)}</h6>
//               </div>


//               <div className='px-2  bg-green-200 rounded py-2 w-full' >
//                 <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><TbLayoutSidebarLeftExpand size={23} className='mr-2 bg-white p-1 text-slate-500' />Out of Office</h6>
//                 <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f => f?._id === 'Out of office') !== undefined && data?.analytics?.find(f => f?._id === 'Out of office') !== null) ? data?.analytics?.find(f => f?._id === 'Out of office')?.count : 0)?.split('.')[0].slice(1)}</h6>
//               </div>


//               <div className='px-2  bg-violet-200 rounded py-2 w-full'>
//                 <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineFeaturedPlayList size={23} className='mr-2 bg-white p-1 text-slate-500' />Task</h6>
//                 <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f => f?._id === 'Task') !== undefined && data?.analytics?.find(f => f?._id === 'Task') !== null) ? data?.analytics?.find(f => f?._id === 'Task')?.count : 0)?.split('.')[0].slice(1)}</h6>
//               </div>

//             </div>
//             <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
//               <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
//                 <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
//                 <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r '>Type</h6>
//                 <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Title</h6>
//                 <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Description</h6>
//                 <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r '>Task Type</h6>
//                 <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[600] text-slate-600 border-r '>Department</h6>
//                 <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600'>Remarks</h6>
//               </div>

//               {data?.datas?.map((d, i) => (
//                 <div key={d?._id} className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
//                   <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[500]  border-r '>{(page > 1 ? i + 1 + (data?.pagination?.limit * (page - 1)) : i + 1)}</h6>
//                   <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[500]  border-r '>{d?.type !== '' && <span className='bg-blue-100 text-[10px] p-[4px] font-[600] rounded'>{d?.type}</span>}</h6>
//                   <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.title}</h6>
//                   <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.description}</h6>
//                   <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[500]  border-r '>{!['', null, undefined, 'null', 'undefined']?.includes(d?.calendar_type) && <span className={` ${d?.calendar_type == 'New' ? 'bg-green-100' : 'bg-red-100'} text-[10px] p-[4px] font-[600] rounded`}>{d?.calendar_type}</span>}</h6>
//                   <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[500]  border-r '>{d?.department?.department_name}</h6>
//                   <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500] '>{d?.remarks}</h6>
//                 </div>))}


//             </div>

//           </div>}

//         {step === 5 &&
//           <div className="w-[100%] px-4 pt-4">
//             <div className='flex  items-center justify-between  border-b pb-2 '>
//               <h6 className='font-[700] text-[14px] '>Total Leads Shared</h6>
//               <div className='flex items-center text-[12px] '>

//                 <h6 className='mr-2 font-[600]'>{page == 1 ? leads?.datas?.length > 0 ? 1 : 0 : (page - 1) * leads?.pagination?.limit} - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
//                 <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

//                 <div>
//                   <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//                   <IconButton onClick={() => { page < leads?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>

//                 </div>
//                 <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, from_date: v, from_date1: v1 }) }} />
//                 <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, to_date: v, to_date1: v1 }) }} />
//                 <div>
//                   <input id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e) => setsearch({ ...search, text: e.target.value })} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 mr-2 rounded-md border-slate-300' />
//                 </div>

//                 <div className='mr-2'>
//                   <Select
//                     bordered={false}
//                     placeholder={'Department'}
//                     className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px] rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
//                     optionFilterProp="children"
//                     onChange={(e) => setsearch({ ...search, department: e })}
//                     value={search?.department === '' ? null : search?.department}
//                     options={department}
//                   />
//                 </div>
//                 <AiOutlineFilter onClick={applyfilter} size={24} className='cursor-pointer bg-slate-700 p-[5px] rounded text-white mr-2' />
//                 {department_FCPL_FTS_BACKEND &&
//                   <div onClick={() => setSubStep(subStep == 1 ? 2 : 1)} className='flex cursor-pointer items-center px-[7px]'>
//                     <BiTable size={20} className={`${subStep == 1 ? 'bg-slate-700 text-white' : 'text-black bg-gray-100'} px-[3px] rounded mr-1 py-[2px]`} />
//                     <PiChartBarBold size={20} className={`${subStep == 2 ? 'bg-slate-700 text-white' : 'text-black bg-gray-100'} px-[3px] rounded mr-1 py-[2px]`} />
//                   </div>}
//               </div>
//             </div>
//             <>
//               <>
//                 {subStep === 1 &&
//                   <>
//                     <>
//                       {leads?.datas?.length > 0 &&
//                         <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
//                           <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
//                             <h6 className='top-0 bg-white z-50 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Info</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Recieved Department</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[180px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Converted</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[180px] px-2 py-1 font-[600] text-slate-600 border-r'>Summary</h6>
//                             <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 '>Created On</h6>
//                           </div>

//                           {leads?.datas?.map((d, i) => (
//                             <div key={d?._id} className={`flex z-50 h-full border-b border-slate-200 z-0`}  >
//                               <h6 className='sticky top-0 z-30 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex justify-center'>{page === 1 ? i + 1 : (i + 1) + ((page - 1) * leads?.pagination?.limit)}</h6>
//                               <h6 className='text-[12px] font-[500] w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d?.fts_lead?.company_name}</h6>
//                               <h6 className='text-[12px] font-[500] w-[180px] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
//                                 <div>
//                                   <h6>{d?.fts_lead?.contact_name}</h6>
//                                   <span>{d?.fts_lead?.phone} / {d?.fts_lead?.email}  </span>
//                                 </div>
//                               </h6>
//                               <h6 className='text-[12px] font-[500] w-[150px] px-2 py-1 break-all border-r border-slate-200'>
//                                 <span className='text-sky-500 text-[11px] font-[600]'>{d?.department_id?.department_name}</span>
//                               </h6>
//                               <h6 className={`text-[12px] font-[500] w-[180px] px-2 py-1  truncate  border-r  border-slate-200 ${d?.requirment ? 'block' : 'text-[#fff]'}`}>{d?.requirment ? d?.requirment : 'Not Added'}</h6>
//                               <h6 className='text-[12px] text-center font-[500] w-[80px] px-2 py-1 truncate border-r  border-slate-200'> {d?.converted === '1' && <span className='bg-green-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '1' && 'Converted'}</span>} {d?.converted === '2' && <span className='bg-red-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '2' && 'Rejected'}</span>} {d?.converted === '3' && <span className='bg-yellow-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '3' && 'Pending'}</span>} {d?.converted === '4' && <span className='bg-blue-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '4' && 'Progress'}</span>} </h6>
//                               <h6 className={`text-[12px] font-[500] w-[180px] px-2 py-1 truncate border-r  border-slate-200 ${d?.remarks ? 'block' : 'text-[#fff]'}`}>{d?.remarks ? d?.remarks : 'Not Added'}</h6>

//                               <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>

//                             </div>))}
//                         </div>}
//                     </>
//                   </>
//                 }
//               </>
//               {subStep === 2 &&

//                 <>
//                   <div className="overflow-x-auto mt-2">
//                     <div className="w-full border text-sm text-left">

//                       <div className="flex items-center text-slate-600 text-[11.5px] font-[700]">
//                         <h6 className="p-1 w-[15%] text-slate-600">(FTS/CORP/BACKEND)</h6>
//                         {department?.map((d) => (
//                           <h6 key={d?._id} className="p-1 border-l text-slate-600 uppercase min-w-[14.33%] max-w-[14.33%] text-center">{d?.department_name}</h6>
//                         ))}
//                       </div>

//                       <div>
//                         <div className="border-t flex  items-center">
//                           <h6 className="p-1 text-[13px] text-gray-700  font-bold w-[15%]">Leads</h6>
//                           <h6 onClick={() => navigateDetail('Transaction Team')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('Transaction Team')}</h6>
//                           <h6 onClick={() => navigateDetail('Project Team')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('Project Team')}</h6>
//                           <h6 onClick={() => navigateDetail('HR Team')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('HR Team')}</h6>
//                           <h6 onClick={() => navigateDetail('FMS Team')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('FMS Team')}</h6>

//                           <h6 onClick={() => navigateDetail('Fidelitus Gallery')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('Fidelitus Gallery')}</h6>
//                           <h6 onClick={() => navigateDetail('Shilpa Foundation')} className={`p-1 text-[16px] font-[800] text-[#007996] border-l hover:bg-blue-100 cursor-pointer text-center min-w-[14.33%] max-w-[14.33%]`}>{getLeadCount('Shilpa Foundation')}</h6>
//                         </div>

//                         <div className="border-t flex  items-center">
//                           <h6 className="p-1 text-[13px] text-gray-700  font-bold w-[15%]">Estimated Revenue</h6>
//                           <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('Transaction Team'))}</h6>
//                           <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('Project Team'))}</h6>
//                           <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('HR Team'))}</h6>
//                           <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('FMS Team'))}</h6>
//                           <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('Fidelitus Gallery'))}</h6>
//                           <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getEstiamtedRevenue('Shilpa Foundation'))}</h6>
//                         </div>

//                         <div className="border-t flex  items-center">
//                           <h6 className="p-1 text-[13px] text-gray-700  font-bold w-[15%]">Actual Revenue</h6>
//                           <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('Transaction Team'))}</h6>
//                           <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('Project Team'))}</h6>
//                           <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('HR Team'))}</h6>
//                           <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('FMS Team'))}</h6>
//                           <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('Fidelitus Gallery'))}</h6>
//                           <h6 className={`p-1 text-[12px] font-[800]  border-l  text-center min-w-[14.33%] max-w-[14.33%]`}>{Priceconstants(getActualRevenue('Shilpa Foundation'))}</h6>
//                         </div>



//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex mt-4 justify-between items-start">
//                     <div className="flex-[0.3] relative h-[280px] border mr-2 border-slate-200">
//                       <h6 className='font-[800] text-[13px] px-2 pt-2 text-gray-700' >Lead Forecast</h6>
//                       <div className='absolute top-[140px] left-[100px] z-50' >
//                         <h6 className='font-[800] text-center text-[11px]'>Total Leads Assigned</h6>
//                         <h6 className='text-center text-blue-600 font-[900]'>{retutnTotalValue("leads")}</h6>
//                       </div>
//                       <ReactApexChart options={leadsChartOptions} series={seriesData} type="donut" width={320} />
//                     </div>
//                     <div className="flex-[0.7] h-[280px] border border-slate-200">

//                       <h6 className='font-[800] text-[13px] px-2 pt-2 text-gray-700'>Revenue Forecast</h6>
//                       <ReactApexChart
//                         options={barChartOptions}
//                         series={[
//                           { name: "Estimation Revenue", data: estimationValuesforChart },
//                           { name: "Actual Revenue", data: actualEstimationValuessforChart }
//                         ]}
//                         type="bar"
//                         width={'100%'}
//                         height={250}
//                       />
//                     </div>
//                   </div>

//                   <div className="flex mt-4 justify-between items-start">
//                     <div className='w-[30%]'>

//                       <h6 className='font-[700] text-[13px] border-b pb-1 mb-1 text-gray-700'>Total Analytics Report</h6>
//                       <div className='flex border'>
//                         <div className='w-[50%]'>
//                           <h6 className="p-1 text-[13px] border-r text-slate-600 font-bold">Leads</h6>
//                           <h6 className="p-1 text-[13px] border-r border-t text-slate-600 font-bold">Estimated Revenue</h6>
//                           <h6 className="p-1 text-[13px] border-r border-t text-slate-600 font-bold">Actual Revenue</h6>
//                         </div>
//                         <div className='w-[50%]'>
//                           <h6 className="p-1 text-[13px] text-blue-600 font-bold">{retutnTotalValue('leads')}</h6>
//                           <h6 className="p-1 text-[13px] border-t font-bold">{Priceconstants(retutnTotalValue('estimated_revenue'))}</h6>
//                           <h6 className="p-1 text-[13px] border-t font-bold">{Priceconstants(retutnTotalValue('actual_revenue_achieved'))}</h6>
//                         </div>
//                       </div>
//                     </div>

//                   </div>
//                 </>


//               }

//             </>
//             {leads?.datas?.length === 0 &&
//               <div className='grid place-items-center mt-20  items-center justify-center'>
//                 <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' />
//                 <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
//                 <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
//               </div>
//             }
//           </div>
//         }
//         {step === 6 &&
//           <div className='overflow-y-scroll relative no-scrollbar no-scrollbar'>
//             <AdminProfitLoss />

//           </div>}
//         {step === 7 &&
//           <div className='overflow-y-scroll relative no-scrollbar no-scrollbar'>
//             <h1>Litigation Table</h1>

//           </div>}



//         <div>

//         </div>
//       </div>

//     </div>
//   )
// }

// export default AdminDashboard