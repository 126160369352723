import React,{useState,useEffect} from 'react'
import CalendarMenu from './CalendarMenu';
import { useSelector } from 'react-redux';
import { DatePicker,Select } from 'antd';
import { BiFilterAlt } from "react-icons/bi";
import { RxReset } from "react-icons/rx";
import { FilterCalendarService } from '../../services/CalendarServices';

import { MdOutlineEventNote } from "react-icons/md";
import { IoCallSharp } from "react-icons/io5";
import { MdOutlineMeetingRoom } from "react-icons/md";
import { TbLayoutSidebarLeftExpand } from "react-icons/tb";
import { MdOutlineFeaturedPlayList } from "react-icons/md";

import { IconButton } from "@mui/material";
import { BsArrowRepeat } from 'react-icons/bs';
import { FiChevronRight,FiChevronLeft } from 'react-icons/fi';
import moment from 'moment';
import { GetUsersByDepartment } from '../../services/DepartmentServices';

import ReactApexChart from 'react-apexcharts';
import { GetReportingMembersDetail } from '../../services/AdminServicesfile/ReportingServices';


function CalendarReportDashboard() {

  const [chartOptions1, setchartOptions1] = useState({
    series: [],
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        distributed: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#fafafa',
    },
    stroke: {
      width: 1,
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Calls', 'Meeting', 'Event', 'Out Of Office', 'Task'],
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    colors: ["#fef08a", "#bfdbfe", "#fecaca", "#bbf7d0", "#ddd6fe"], // Define colors for each bar
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        }
      }
    }
  })


  const roles = useSelector(state=>state.Auth.roles)
  const userId = useSelector(state=>state.Auth.id)
  const auth = useSelector(state=>state.Auth)
  const departmentId = auth.department_id[0].id

  const [users,setusers] = useState([])

  const [page,setpage] = useState(1)
  const [data,setdata] = useState({datas:[],pagination:{total:0,limit:25}})

  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',type:'',activate:false,user:''})

  const type = [{label:'Event',value:'Event'},{label:'Out of office',value:'Out of office'},{label:'Task',value:'Task'},{label:'Meeting',value:'Meeting'},{label:'Calls',value:'Calls'}]
  

  useEffect(()=>{
    if((roles.includes('hod') || roles?.includes('reporting_manager')) && !roles.includes('admin')){
      getuserbaseddepartment(departmentId)
    }
    getdata()
  },[page])
  
  async function getdata(){
    const response = await FilterCalendarService(search?.from_date1,search?.to_date1,search?.type,page)
    
    let series1 = []
    let d = response?.data?.analytics

    if(d.find((f)=>f?._id == 'Calls') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Calls')?.count)
    }else{
      series1.push(0)
    }

    if(d.find((f)=>f?._id == 'Meeting') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Meeting')?.count)
    }else{
      series1.push(0)
    }

    if(d.find((f)=>f?._id == 'Event') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Event')?.count)
    }else{
      series1.push(0)
    }

    if(d.find((f)=>f?._id == 'Out of Office') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Out of Office')?.count)
    }else{
      series1.push(0)
    }

    if(d.find((f)=>f?._id == 'Task') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Task')?.count)
    }else{
      series1.push(0)
    }

    let category = ['Calls','Meeting','Event','Out of Office','Task']
   
    setchartOptions1({
      ...chartOptions1
      , series: [{
          name:"count",
          data:series1
        }]
      , xaxis: {
        categories: category,
      },

    })

    setdata(response?.data)
  }

  function handlechange(e){
    setsearch({...search,user:users.find((f)=>f.value == e)})
  }

  async function applyfilterfunction(){
    setpage(1)
    const response = await FilterCalendarService(search?.from_date1,search?.to_date1,search?.type,1,search?.user?.value !== undefined ? search?.user?.value : '')
    let series1 = []
    let d = response?.data?.analytics

    if(d.find((f)=>f?._id == 'Calls') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Calls')?.count)
    }else{
      series1.push(0)
    }

    if(d.find((f)=>f?._id == 'Meeting') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Meeting')?.count)
    }else{
      series1.push(0)
    }

    if(d.find((f)=>f?._id == 'Event') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Event')?.count)
    }else{
      series1.push(0)
    }

    if(d.find((f)=>f?._id == 'Out of Office') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Out of Office')?.count)
    }else{
      series1.push(0)
    }

    if(d.find((f)=>f?._id == 'Task') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Task')?.count)
    }else{
      series1.push(0)
    }

    let category = ['Calls','Meeting','Event','Out of Office','Task']
   
    setchartOptions1({
      ...chartOptions1
      , series: [{
          name:"count",
          data:series1
        }]
      , xaxis: {
        categories: category,
      },

    })

    setdata(response?.data)
  }

  async function resetfilter(){
    setpage(1)
    setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',type:'',user:'',activate:false})
    const response = await FilterCalendarService('','','',1)
    let series1 = []
    let d = response?.data?.analytics

    if(d.find((f)=>f?._id == 'Calls') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Calls')?.count)
    }else{
      series1.push(0)
    }

    if(d.find((f)=>f?._id == 'Meeting') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Meeting')?.count)
    }else{
      series1.push(0)
    }

    if(d.find((f)=>f?._id == 'Event') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Event')?.count)
    }else{
      series1.push(0)
    }

    if(d.find((f)=>f?._id == 'Out of Office') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Out of Office')?.count)
    }else{
      series1.push(0)
    }

    if(d.find((f)=>f?._id == 'Task') !== undefined){
      series1.push(d.find((f)=>f?._id == 'Task')?.count)
    }else{
      series1.push(0)
    }

    let category = ['Calls','Meeting','Event','Out of Office','Task']
   
    setchartOptions1({
      ...chartOptions1
      , series: [{
          name:"count",
          data:series1
        }]
      , xaxis: {
        categories: category,
      },

    })
    setdata(response?.data)
  }

  async function getuserbaseddepartment(id){
    if(roles.includes('hod')){
      const response = await GetUsersByDepartment(id) 
      let arr = []
      response.data.datas.forEach((d)=>{
          arr.push({label:d?.name,value:d?._id})
      })
      setusers(arr)
    }else if(roles?.includes('reporting_manager')){
      const response = await GetReportingMembersDetail(userId)
      let arr = []
      // console.log("response?.data",response?.data)
      response.data.datas[0]?.users.forEach((d)=>{
        // console.log("d here",d)
          arr.push({label:d?.name,value:d?._id})
      })
      setusers(arr)
    }
  }
  

  let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });



  return (
    <div className='h-screen flex w-[100%] max-h-screen '>

     


      <div className='flex w-[100%] '>
        <CalendarMenu /> 
        <div className='w-[87%]'>
            <div className='px-4 py-4'>
              <div className='flex items-center justify-between border-b pb-2'>
                <h6 className='font-[800] text-[14px]'>Dashboard Report ({data?.pagination?.total})</h6>
                <div className='flex items-center justify-center'>
                <IconButton  onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>
                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}} ><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                </div>

                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 
                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 

                <div className='mr-1.5 rounded-md border border-slate-300 py-[0px]'>
                <Select
                    placeholder='Select Type'
                    bordered={false}
                    size='small'
                    defaultValue={search?.type}
                    value={search?.type == '' ? null :search?.type}
                    style={{ width: 110 }}
                    onChange={(v)=>setsearch({...search,type:v})}
                    options={type}
                />
                </div>

                {(!roles?.includes('admin') && (roles?.includes('hod') || roles?.includes('reporting_manager'))) && 
                  <div className='ml-1 h-[28px] rounded-md border border-slate-300 py-[2px]'>
                    <Select
                      placeholder='Select User'
                      bordered={false}
                      size='small'
                      defaultValue={search?.user}
                      value={search?.user == '' ? null : search?.user}
                      style={{ width: 110,marginTop:'-10px' }}
                      onChange={(v) => handlechange(v, 'user')}
                      options={users}
                    />
                  </div>}

                <div className='mr-2'>
                  <BiFilterAlt  onClick={()=>applyfilterfunction(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
                </div> 

                <RxReset  onClick={resetfilter} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />

                </div>
              </div>

              <div className='grid grid-cols-5 mt-2 gap-5 '>

                  <div className='px-2  bg-yellow-200 rounded py-2 w-full' >
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><IoCallSharp size={23} className='mr-2 bg-white p-1 text-slate-500' />Calls</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f=>f?._id === 'Calls') !== undefined && data?.analytics?.find(f=>f?._id === 'Calls') !== null) ? data?.analytics?.find(f=>f?._id === 'Calls')?.count : 0 )?.split('.')[0].slice(1)}</h6>
                  </div>

                  <div className='px-2  bg-blue-200 rounded py-2 w-full' >
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineMeetingRoom size={23} className='mr-2 bg-white p-1 text-slate-500' />Meeting</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f=>f?._id === 'Meeting') !== undefined && data?.analytics?.find(f=>f?._id === 'Meeting') !== null) ? data?.analytics?.find(f=>f?._id === 'Meeting')?.count : 0 )?.split('.')[0].slice(1)}</h6>
                  </div>

                  <div className='px-2  bg-red-200 rounded py-2 w-full'>
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineEventNote size={23} className='mr-2 bg-white p-1 text-slate-500' />Event</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f=>f?._id === 'Event') !== undefined && data?.analytics?.find(f=>f?._id === 'Event') !== null) ? data?.analytics?.find(f=>f?._id === 'Event')?.count : 0 )?.split('.')[0].slice(1)}</h6>
                  </div>


                  <div className='px-2  bg-green-200 rounded py-2 w-full' >
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><TbLayoutSidebarLeftExpand size={23} className='mr-2 bg-white p-1 text-slate-500' />Out of Office</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f=>f?._id === 'Out of office') !== undefined && data?.analytics?.find(f=>f?._id === 'Out of office') !== null) ? data?.analytics?.find(f=>f?._id === 'Out of office')?.count : 0 )?.split('.')[0].slice(1)}</h6>
                  </div>


                  <div className='px-2  bg-violet-200 rounded py-2 w-full'>
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineFeaturedPlayList size={23} className='mr-2 bg-white p-1 text-slate-500' />Task</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format((data?.analytics?.find(f=>f?._id === 'Task') !== undefined && data?.analytics?.find(f=>f?._id === 'Task') !== null) ? data?.analytics?.find(f=>f?._id === 'Task')?.count : 0 )?.split('.')[0].slice(1)}</h6>
                  </div>

              </div>

              {/* {search?.type == '' &&
              <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1?.series} type="bar" width={'100%'} height={240} />
              } */}

              {/* {search?.type !== '' && */}
              <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
                  <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                      <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                      <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r '>Type</h6>
                      <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Title</h6>
                      <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[600] text-slate-600 border-r '>Description</h6>
                      <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r '>Task Type</h6>
                      <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[600] text-slate-600 border-r '>Department</h6>
                      <h6 className='text-[12px] w-[10%] border-r  px-2 py-1 font-[600] text-slate-600'>Created At</h6>
                      <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600'>Remarks</h6>
                  </div>
                  
                  {data?.datas?.map((d,i)=>(
                  <div key={d?._id} className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                      <h6 className='top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[500]  border-r '>{(page > 1 ? i+1+ (data?.pagination?.limit * (page - 1)) : i+1 )}</h6>
                      <h6 className='text-[12px] w-[12%]  px-2 py-1 font-[500]  border-r '>{d?.type !== '' && <span className='bg-blue-100 text-[10px] p-[4px] font-[600] rounded'>{d?.type}</span>}</h6>
                      <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.title}</h6>
                      <h6 className='text-[12px] w-[20%]  px-2 py-1 font-[500]  border-r '>{d?.description}</h6>
                      <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[500]  border-r '>{!['',null,undefined,'null','undefined']?.includes(d?.calendar_type) && <span className={` ${d?.calendar_type == 'New' ? 'bg-green-100' : 'bg-red-100'} text-[10px] p-[4px] font-[600] rounded`}>{d?.calendar_type}</span>}</h6>
                      <h6 className='text-[12px] w-[13%]  px-2 py-1 font-[500]  border-r '>{d?.department?.department_name}</h6>
                      <h6 className='text-[11px] w-[10%]  px-2 py-1 font-[500] border-r'>{moment(d?.date_time)?.format('ll')} </h6>
                      <h6 className='text-[12px] w-[10%]  px-2 py-1 font-[500] '>{d?.remarks}</h6>
                  </div>))}
     

              </div>
              {/* } */}


            </div>
            
        </div>
      </div>
    </div>
  )
}

export default CalendarReportDashboard