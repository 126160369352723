import React, { useEffect, useState } from 'react'
import DatabaseMenu from '../DatabaseMenu'
import GoBack from '../../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
import { DeleteManagedOfficeHistoryService, GetManagedOfficeDetailService, GetManagedOfficeHistoryService, UpdateManagedOfficeHistoryService } from '../../../services/database/mainoptions/ManagedOfficeServices'
import toast from 'react-hot-toast'
import { GrLocationPin } from "react-icons/gr";
import { AiOutlineClose, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { CgPlayTrackPrevR } from "react-icons/cg";
import { Drawer, Modal } from 'antd'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { TextAreaInput1 } from '../../../components/input'
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, } from '@mui/material'
import { useSelector } from 'react-redux'


function ManagedOfficeDetail() {

  const {state} = useLocation() 
  const navigate = useNavigate()
  const roles = useSelector(state=>state.Auth.roles)
  
  console.log("roles",roles)


  const [data,setdata] = useState({})
  const [page,setpage] = useState(1)
  const [openModal,setopenModal] = useState(null)
  const [modal,setmodal] = useState(null)
  const [historyData,sethistoryData] = useState({datas:[],pagination:{total:0,totalPages:0,limit:25}})
  const [selectedhistoryData,setselectedhistoryData] = useState({})
  const [selectedhistoryDataErr,setselectedhistoryDataErr] = useState({})

  const [selectedImage,setselectedImage] = useState("")

//   console.log("data",data)
  
  useEffect(()=>{
    getmanagedofficehistoryfun()
  },[page])

  useEffect(()=>{
    getdetail()
  },[state])

  async function getdetail() {
    const response = await GetManagedOfficeDetailService(state)
    if(response?.status == 200){
        setselectedImage(response?.data?.datas[0]?.main_image)
        setdata(response?.data?.datas[0])
    }else{
        navigate(-1)
        toast.error("Data Not Found")
    }
  }

  function openFile(v){
    let url = `${v}`
    window.open(url,'_blank')
   }


   async function getmanagedofficehistoryfun(){
    const response = await GetManagedOfficeHistoryService(page,state)
    sethistoryData(response?.data?.data)
   }

   async function deletehistoryfunc() {
     if(!selectedhistoryData?._id){
        toast.error("Managed Office History Data Not Selected ")
     }else{
        const response = await DeleteManagedOfficeHistoryService(selectedhistoryData?._id)
        if(response?.status == 200){
            toast.success("Managed Office History Data Deleted")
            setmodal('')
            getmanagedofficehistoryfun()
            setpage(1)
          }
     }
   }

   async function update_managed_office_history_func() {
    if(!selectedhistoryData?.remarks){
       setselectedhistoryDataErr({...selectedhistoryDataErr,remarks:"This Field is required*"})
    }else{
      let sendData = {...selectedhistoryData}
      delete sendData.managed_office
      delete sendData.updated_by

      const response = await UpdateManagedOfficeHistoryService(sendData?._id,sendData)
      if(response?.status == 200){
        toast.success("Managed Office History Data Updated")
        setmodal('')
        getmanagedofficehistoryfun()
        setpage(1)
      }
    }
   }

  return (
    <div className='flex'>
        <Drawer placement='right' width={400}  footer={false} closable={false} open={openModal == 1}>
            <div className='relative'>
                <AiOutlineClose onClick={()=>setopenModal('')} size={18} className='absolute right-1 top-2 bg-slate-200 cursor-pointer p-1' />
                <div className='mb-4'>
                  <h6 className='pt-1.5 font-[800] text-[13px]'>Data Update History ({historyData?.pagination?.total})</h6>
                </div>   

                 {historyData?.datas?.map((d)=>(
                    <div className='border p-2 relative rounded-[10px] mt-2' key={d?._id}>


                        <div className='flex border-l border-b cursor-pointer items-center absolute right-0 top-0 p-1'>
                          <AiOutlineEdit onClick={()=>{setselectedhistoryData(d);setmodal(2)}} size={17} className='mr-1 pr-1 border-r' />
                          <AiOutlineDelete onClick={()=>{setselectedhistoryData(d);setmodal(1)}} size={12} className='' />
                        </div>     

                        

                        <h6 className='text-[13px] font-[800]'>Update Made : </h6>
                        <div className='flex items-center'>
                            <h6 className='text-[11px] break-word' dangerouslySetInnerHTML={{__html:d?.updatedFields}}></h6>
                        </div>

                        <h6 className='bg-slate-100 p-1 mt-1 text-[11px]'><span className='font-[800]'>Remarks :</span> {d?.remarks}</h6>
                        
                        <div>
                          <h6 className='text-[10px]'><span className='font-[800]'>Modified By :</span> <span>{d?.updated_by?.name} / {d?.updated_by?.employee_id}</span></h6>
                        </div>
                    </div>  

                    ))}

                    <div className='flex items-center justify-center mx-auto mt-4'>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <h6 className='mx-2 bg-slate-100 w-[20px] text-center rounded'>{page}</h6>
                        <IconButton onClick={()=>{ page < historyData?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(historyData?.pagination?.totalPages === page || historyData?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    </div>
                    
            </div>
             
        </Drawer>

         <Modal open={modal == 1 || modal == 2} className='absolute top-0 left-[42%]' width={300} footer={false} closable={false}>
            {modal == 1 &&
            <div>
                <h6 className='text-[12px] font-[700]'>Delete Managed Office History Data</h6>
                <h6 className='text-[10px] bg-slate-100 p-[4px] leading-[14px]'>Are you sure want to delete the selected managed office history data once deleted will not be retrieved</h6>
                
                <div className='flex mt-2 justify-end border-t pt-2'>
                <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>setmodal('')} />  
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth  btnName="Save" onClick={()=>deletehistoryfunc()} />  
                </div>    
            </div>}

            {modal == 2 &&
            <div>
                <h6 className='text-[12px] font-[700]'>Update Managed Office History Data</h6>
                <h6 className='text-[10px] bg-slate-100 p-[4px] leading-[14px]'>Use the below form to update the remarks for the managed office history data</h6>
                

                <TextAreaInput1  error={selectedhistoryDataErr?.remarks} value={selectedhistoryData?.remarks} handlechange={(e)=>{setselectedhistoryData({...selectedhistoryData,remarks:e.target.value});setselectedhistoryDataErr({remarks:''})}} />




                <div className='flex mt-2 justify-end border-t pt-2'>
                <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>setmodal('')} />  
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth  btnName="Save" onClick={()=>update_managed_office_history_func()} />  
                </div>    
            </div>}
        </Modal>

       
        <div className='min-w-[180px] w-[180px] max-w-[180px]'>
        <DatabaseMenu /> 
        </div>
        <div className='ml-4 w-[100%] mt-4 pr-5 min-h-screen max-h-screen h-screen overflow-y-scroll'>
            <div>
            <GoBack />

            <div className='border flex relative rounded p-2'>
               {(roles?.includes('admin') || roles?.includes('db_head')) &&
                <div onClick={()=>{getmanagedofficehistoryfun();setopenModal(1)}} className='flex items-center justify-center bg-slate-700 rounded pr-3 pl-2 cursor-pointer z-100 absolute right-20 p-1 border border-slate-700 text-white'>
                    <CgPlayTrackPrevR className='mr-2 text-[10px]' />
                    <h6 className='text-[11px]'>History</h6>
                </div>}

                {!data?.is_deleted &&
                <div onClick={()=>navigate('/database/managed_office/edit',{state:state})} className='flex items-center justify-center bg-slate-100 rounded pr-3 pl-2 cursor-pointer z-100 absolute right-2 p-1 border border-slate-400 '>
                    <AiOutlineEdit className='mr-2 text-[10px]' />
                    <h6 className='text-[11px]'>Edit</h6>
                </div>}

                <div className='w-[250px] border border-slate-200 rounded p-1 mr-4 h-[150px]'>
                    <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${selectedImage}`} className='w-[100%] h-[100%] object-cover' />
                </div>

                <div>
                   <h6 className='font-[800] text-[13px]'>{data?.managed_office_name}</h6>
                   {data?.managed_office_address !== '' && <h6 className='bg-slate-100 px-2 py-[4px] flex items-start text-[12px]'><GrLocationPin className='mt-[3px] mr-2' /> {data?.managed_office_address}</h6>}
                   <h6 className='text-[12px] mt-1'>Zone : <span className='font-[800]'>{data?.zone?.name}</span> / Area : <span className='font-[800]'>{data?.location_of_property?.name}</span></h6>
                   <h6 className='text-[12px] mt-1'>Landmark To Property : <span className='font-[800]'>{data?.landmark_to_property}</span></h6>
                    
                  <div className='flex mt-2'>
                    {!['',null,undefined,'null','undefined']?.includes(data?.main_image) &&
                    <div onClick={()=>setselectedImage(data?.main_image)} className='w-[40px] cursor-pointer rounded border border-slate-200 p-1 mr-1 h-[40px]'>
                        <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.main_image}`} className='w-[100%] h-[100%] object-cover' />
                    </div>}
                    {!['',null,undefined,'null','undefined']?.includes(data?.image1) &&
                    <div onClick={()=>setselectedImage(data?.image1)} className='w-[40px] cursor-pointer rounded border border-slate-200 p-1 mr-1 h-[40px]'>
                        <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.image1}`} className='w-[100%] h-[100%] object-cover' />
                    </div>}
                    {!['',null,undefined,'null','undefined']?.includes(data?.image2) &&
                    <div onClick={()=>setselectedImage(data?.image2)} className='w-[40px] cursor-pointer rounded border border-slate-200 p-1 mr-1 h-[40px]'>
                        <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.image2}`} className='w-[100%] h-[100%] object-cover' />
                    </div>}
                    {!['',null,undefined,'null','undefined']?.includes(data?.image3) &&
                    <div onClick={()=>setselectedImage(data?.image3)} className='w-[40px] cursor-pointer rounded border border-slate-200 p-1 mr-1 h-[40px]'>
                        <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.image3}`} className='w-[100%] h-[100%] object-cover' />
                    </div>}
                    {!['',null,undefined,'null','undefined']?.includes(data?.image4) &&
                    <div onClick={()=>setselectedImage(data?.image4)} className='w-[40px] cursor-pointer rounded border border-slate-200 p-1 mr-1 h-[40px]'>
                        <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.image4}`} className='w-[100%] h-[100%] object-cover' />
                    </div>}
                  </div>  
               
                </div>
            
            </div> 


            <div className='mt-2 flex border p-2 rounded'>




                <div className='w-[30%] mr-5'>
                    <h6 className='font-[900] text-[12px] mt-3 mb-2'>More Information</h6>
                    <div className='border'>
                        
                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Seater Offered</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.seater_offered}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Rent Per Seat</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.rent_per_seat}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Escalation</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.escalation}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Amenities Availablility</h6>
                            <h6 className='w-[50%] p-1 font-[800] flex flex-wrap border-b text-[12px]'>{data?.amenities?.map((a)=><span className='bg-slate-100 px-2 py-1 rounded text-[9px] mr-1 mb-1'>{a?.name}</span>)}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Amenities List</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.amenities_available ? 'Available' : 'Not Available'}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>No of Car Parking Slots</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.no_of_car_parking_slots}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Car Parking Charges</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.car_parking_charges}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Security Deposit</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.security_deposit}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Lock In Period</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.lock_in_period}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Power And Backup</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.power_and_backup}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r '>OC Availablility</h6>
                            <h6 className='w-[50%] p-1 font-[800] '>{data?.oc_availability ? 'True' : 'False'}</h6>
                        </div>
                    </div>

                    <h6 className='font-[900] text-[12px] mt-3 mb-2'>Creator Information</h6>
                    <div className='border'>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Name</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.added_by?.name}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Employee Id</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px] uppercase'>{data?.added_by?.employee_id}</h6>
                    </div>
                    </div>
                </div>

                <div className='w-[30%]'>
                    <h6 className='font-[900] text-[12px] mt-3 mb-2'>Contact Information</h6>
                    <div className='border'>
                        
                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Primary Contact Name</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.primary_contact_name}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Priamary Contact No</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.primary_contact_no}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Secondary Contact Name</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.secondary_contact_name}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%]'>Secondary Contact No</h6>
                            <h6 className='w-[50%] p-1 font-[800]' >{data?.secondary_contact_no}</h6>
                        </div>

                    </div>

                    <h6 className='font-[900] text-[12px] mt-3 mb-2'>Location Information</h6>
                    <div className='border'>
                        
                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Latitude</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.latitude}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Longitude</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.longitude}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r'>Google Map Link</h6>
                            <h6 className='w-[50%] p-1 font-[800] text-[12px] break-all'><span onClick={()=>openFile(data?.google_coordinate_link)} className='underline text-blue-500 cursor-pointer'>{data?.google_coordinate_link}</span></h6>
                        </div>


                    </div>

                </div>

            </div>

            
            </div>
        </div>
    </div>
  )
}

export default ManagedOfficeDetail