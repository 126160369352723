import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from './folder/components/routes/router';

import { MdOutlineBrowserUpdated } from "react-icons/md";

import Login from './folder/screens/auth/Login';
import PrivateRoute from './folder/components/routes/PrivateRoute';
import MobileOTP from './folder/screens/auth/Mobile';
import ForgotPassword from './folder/screens/auth/ForgotPassword';
import UpdatePassword from './folder/screens/auth/UpdatePassword';


import { HiOutlineTicket } from "react-icons/hi";
import { CgWebsite } from "react-icons/cg";
import { AiOutlineSetting, AiOutlineSchedule } from 'react-icons/ai';
import { HiOutlineMenu } from 'react-icons/hi';
import { FiUser } from 'react-icons/fi';
import { BsPhoneFlip } from "react-icons/bs";
import { MdOutlineSpaceDashboard, MdOutlineFormatStrikethrough, MdOutlineFoundation } from 'react-icons/md';
import { SiGoogleadsense } from 'react-icons/si';
import { Toaster } from 'react-hot-toast';
import CssBaseline from "@mui/material/CssBaseline";
import { HiLogout } from 'react-icons/hi';
import { TbMoneybag, TbEaseInOutControlPoints } from 'react-icons/tb';
import { BiMoneyWithdraw } from "react-icons/bi";
import { btnColor } from './folder/constants/themeConstants';
import { LuAlarmClock } from 'react-icons/lu'
import { CreateUpdateStickyNotes, GetStickyNotes } from './folder/services/StickyNotesServices';
import { LogOutAction } from './folder/redux/actions/authAction'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { RiGalleryLine } from 'react-icons/ri';
import { LiaHospitalAltSolid, LiaLandmarkSolid, } from "react-icons/lia";
import { TbDatabaseEdit } from "react-icons/tb";
import { TbSubtask } from "react-icons/tb";
import { TbLayoutSidebarLeftExpand } from "react-icons/tb";
import ProductDetail from './folder/screens/gallery/products/ProductDetail';
import LeadInspectionReviewParamsDetail from './folder/screens/lead/LeadInspectionReviewParamsDetail';
import { LuBookUp2 } from "react-icons/lu";
import { PiPlaceholder } from "react-icons/pi";
import { RiGovernmentLine } from "react-icons/ri";
import { GrCloudComputer } from 'react-icons/gr';
import PrivacyPolicy from './folder/screens/staticscreens/PrivacyPolicy';
import { MdOutlineLeaderboard } from "react-icons/md";
import ConveyanceVochuerCE from './folder/screens/finance/finance_forms/ConveyanceVochuerCE';
import { IoMdCalendar } from "react-icons/io";
import { MdOutlineLocalLibrary } from "react-icons/md";
import { appVersion } from './folder/constants/dataConstants';
import { GetAppUpdateService } from './folder/services/AppUpdateService';
import { Modal } from 'antd';
import axios from 'axios';
import SocialAuth from './folder/screens/SocialAuth';

// import AppBar from './folder/screens/appbar/AppBar';
// import Footer from './folder/screens/footer/Footer';
// import {SuccessNotification,  ErrorNotification, WarningNotification } from './folder/components/noti_popup_ui/SuccessNotification';


function App() {
  const zoom = useSelector(state => state.Static.fullScreen)
  const dispatch = useDispatch()
  const location = useLocation()
  const { pathname } = useLocation()
  const auth = useSelector(state => state.Auth)

  const path = pathname.split('/')[1]

  const [notes_visible, setnotes_visible] = useState(false)
  const [data, setdata] = useState('')

  const navigator = useNavigate()

  const [mobileOpen, setMobileOpen] = useState(false);
  const [appUpdate, setappUpdate] = useState({});
  const [forceUpdate, setforceUpdate] = useState(false);


  useEffect(() => {

    if (pathname !== '/password_reset' && pathname !== '/privacy_policy' && path !== 'gallery' && !pathname.includes('/gallery/product_list/detail/') && !pathname?.includes('inspection_review')) {
      // console.log("going inside")
      if (auth?.isAuthenticated && auth?.token !== '') {
        getdata()
        if (path === '') {
          if (auth?.roles?.includes('hod') || auth?.roles?.includes('manager') || auth?.roles?.includes('bd_user') || auth?.roles?.includes('admin')) {
            navigator('/dashboard')
          } else if (auth?.roles?.includes('finance_head') || auth?.roles?.includes('finance_manager')) {
            navigator('/finance')
          }
        }
      } else {
        if (path !== 'gallery' || path !== 'inspection_review') {
          navigator('/login', { replace: true })
        }
        // console.log("kp here")
      }

    } else {
      // console.log("anna iam here")
    }

    // console.log("auth?.isAuthenticated",auth?.isAuthenticated)
  }, [auth?.isAuthenticated])


  async function getdata() {
    const response1 = await GetAppUpdateService()
    if (response1?.data?.datas?.length > 0) {
      let d = response1?.data?.datas[0]
      // console.log("d here",d)
      if (!['Low', 'Medium']?.includes(d.update_priority)) {
        setforceUpdate(true)
      }
      setappUpdate(response1?.data?.datas[0])
    }
    const response = await GetStickyNotes();
    setdata(response?.data?.data[0]?.data)
  }

  const disabled_drawer = ['/', '/login', '/forgot_password', '/update_password', '/password_reset', '/privacy', 'self_onboard', '/self_onboard', 'exit_formalities', '/exit_formalities', 'login', 'forgot_password',]
  // const disabled_margin_left = ['','login','forgot_password','password_reset']
  const enable_drawer = ['/dashboard', '/profile', '/daily_tasks', '/support', '/settings']

  // TbLayoutSidebarLeftExpand
  const userRoute = [
    { name: "Dashboard", to: "/dashboard", icon: MdOutlineSpaceDashboard, size: 18, roles: ['bd_user','reporting_manager', 'manager', 'hod', 'admin'] },
    { name: "Raise Ticket", to: "/support_ticket", icon: HiOutlineTicket, size: 18, roles: [] },
    { name: "In-Out", to: "/in_out", icon: TbEaseInOutControlPoints, size: 18, roles: ['admin', 'hod_exit_approval', 'hr_exit_approval', 'it_head', 'it_exit_approval'] },
    { name: "Finance", to: "/finance", icon: TbMoneybag, size: 18, roles: ['admin', 'finance_head', 'finance_manager'] },
    { name: "IT Tracker", to: "/it", icon: GrCloudComputer, size: 18, roles: ['it_head', 'admin'] },
    { name: "My Contact", to: "/calls", icon: BsPhoneFlip, size: 18, roles: ['tele_caller'] },
    // { name: "MD Calls", to: "/md_calls", icon: MdOutlineLocalLibrary ,size:18,roles:['admin']},
    { name: "Calendar", to: "/calendar", icon: IoMdCalendar, size: 18, roles: [] },
    { name: "DataBase", to: "/database", icon: TbDatabaseEdit, size: 18, roles: ['admin','db_user', 'db_head', 'db_manager'] },
    { name: "Leads", to: "/leads", icon: SiGoogleadsense, size: 18, roles: ['admin', 'bd_user', 'hod', 'manager'], department: ['Transaction Team', 'Project Team', 'HR Team', 'FMS Team', 'Digital Media', 'Fidelitus Gallery', 'Shilpa Foundation','Corp Team','Backend Team'] },
    { name: "ILS", to: "/ils", icon: LiaLandmarkSolid, size: 18, roles: ['admin', 'ils_hod', 'ils_manager', 'ils_user'] },
    // { name: "Assign Leads", to: "/assign_leads", icon: SiGoogleadsense ,size:18,roles:['admin','hod','md_lead','lead_handler']},
    // 'admin'
    { name: "FTS Leads", to: "/fts_leads", icon: MdOutlineLeaderboard, size: 18, roles: ['admin', 'fts_admin', 'fts_user', 'hod', 'fts_lead_forwader', 'fts_lead_handler'] },
    { name: "Profit / Loss", to: "/profit_loss", icon: BiMoneyWithdraw, size: 18, roles: ['admin', 'revenue_creator', 'finance_head'] },
    { name: "ATS", to: "/ats", icon: MdOutlineFormatStrikethrough, size: 18, roles: ['admin', 'ats_head', 'ats_manager', 'ats_user'] },
    // 'Fidelitus Gallery', 'Shilpa Foundation', 'FTS Team', 'Corp Team', 'Backend Team'
    { name: "Tasks", to: "/tasks", icon: AiOutlineSchedule, size: 18, roles: ['daily_task_add'] },
    { name: "MD FollowUp", to: "/md_followup", icon: TbSubtask, size: 18, roles: ['admin', 'controller',] },
    { name: "NMR", to: "/nmr", icon: PiPlaceholder, size: 18, roles: ['admin', 'property_promoter',] },
    { name: "Agreement", to: "/agreement", icon: LuBookUp2, size: 18, roles: ['admin', 'agreement_head'] },
    { name: "Reminder", to: "/reminder/active", icon: LuAlarmClock, size: 18, roles: ['admin', 'controller', 'admin_reminder'] },
    { name: "Gallery", to: "/gallery", icon: RiGalleryLine, size: 18, roles: ['admin', 'gallery_inventory'] },
    { name: "FCares", to: "/fcares", icon: LiaHospitalAltSolid, size: 19, roles: ['admin'] },
    { name: "Legal", to: "/govt", icon: RiGovernmentLine, size: 19, roles: ['admin', 'lawyer_hod', 'lawyer_manager', 'lawyer_user'] },
    { name: "Foundation", to: "/shilpa_foundation", icon: MdOutlineFoundation, size: 19, roles: ['admin', 'foundation_head'] },
    { name: "Website", to: "/website", icon: CgWebsite, size: 19, roles: ['admin', 'content_writer', 'hr_content_writer', 'project_content_writer', 'fms_content_writer', 'transaction_content_writer'] },
    { name: "Reception", to: "/reception", icon: TbLayoutSidebarLeftExpand, size: 18, roles: ['admin', 'reception', 'reception_user', 'reception_head'] },
    { name: "Profile", to: "/profile", icon: FiUser, size: 19, roles: [] },
    { name: "Settings", to: "/settings", icon: AiOutlineSetting, size: 16, roles: ['onboard_executive', 'admin', 'controller', 'hr_admin'] },
  ];


  const handleLogout = () => {
    dispatch(LogOutAction())
    navigator("/", { replace: true });
  };

  function gotodashboard() {
    if (auth?.roles?.includes('hod') || auth?.roles?.includes('manager') || auth?.roles?.includes('bd_user') || auth?.roles?.includes('admin')) {
      navigator('/dashboard')
    } else if (auth?.roles?.includes('finance_head') || auth?.roles?.includes('finance_manager')) {
      navigator('/finance')
    }
  }

  const drawer = (
    <div className={`relative overflow-y-scroll bg-slate-800 min-h-screen `}>
      <div className='grid place-items-center py-3'>
        <img onClick={gotodashboard} className='w-6 h-6 flex items-center opacity-50 hover:opacity-100' src="https://fidecrmfiles.s3.amazonaws.com/fcpl_white_logo.png" />
      </div>
      {userRoute?.map((item) => (
        <>
          {(item?.department === undefined || auth?.department_id?.filter((f) => item?.department?.includes(f?.department_name))?.length > 0) &&
            <React.Fragment key={item?.to}>
              {item.roles.length > 0 && item?.roles?.filter((e) => auth?.roles?.includes(e))?.length > 0 ?
                <React.Fragment>

                  {item?.name === 'Incentive' ?
                    <div key={item?.name}>
                      <div className={`grid py-1.5 place-items-center  text-white  mb-1 cursor-pointer ${path === item?.to?.split('/')[1] ? 'opacity-100 bg-slate-900 ' : 'opacity-50'}  ${path === item?.to?.split('/')[1] && 'opacity-100'} `} onClick={() => { navigator(item.to); setMobileOpen(false) }}>
                        {item.icon && <item.icon size={item.size} />}
                        <span className="mt-1 text-[9px] font-[600]">
                          {item.name}
                        </span>
                      </div>

                    </div>
                    :
                    <>
                      {item?.name !== 'Incentive' &&
                        <div key={item?.name}>
                          <div className={`grid py-1.5 place-items-center  text-white  mb-1 cursor-pointer ${path === item?.to?.split('/')[1] ? 'opacity-100 bg-slate-900 ' : 'opacity-50'}  ${path === item?.to?.split('/')[1] && 'opacity-100'} `} onClick={() => { navigator(item.to); setMobileOpen(false) }}>
                            {item.icon && <item.icon size={item.size} />}
                            <span className="mt-1 text-[9px] font-[600]">
                              {item.name}
                            </span>
                          </div>
                        </div>
                      }</>}
                </React.Fragment>
                :
                <React.Fragment>
                  {item.roles.length === 0 &&
                    <div key={item?.name}>
                      <div className={`grid py-1.5 place-items-center text-white  mb-1 cursor-pointer ${path === item?.to?.split('/')[1] ? 'opacity-100 bg-slate-900 ' : 'opacity-50'} ${path === item?.to?.split('/')[1] && 'opacity-100'} `} onClick={() => { navigator(item.to); setMobileOpen(false) }}>
                        {item.icon && <item.icon size={item.size} />}
                        <span className="mt-1 text-[9px] font-[600]">
                          {item.name}
                        </span>
                      </div>
                    </div>}
                </React.Fragment>
              }
            </React.Fragment>}
        </>
      ))}

      <div className="grid  bottom-0 py-2 border-white place-items-center opacity-50 hover:opacity-100 text-white  mb-1 cursor-pointer " onClick={handleLogout}>
        <HiLogout size={14} />
        <span className="mt-1 text-[9px] font-[600]">
          {'Log out'}
        </span>
      </div>
    </div>
  );


  async function submitform() {
    if (notes_visible) {
      await CreateUpdateStickyNotes({ data: data })
      setnotes_visible(!notes_visible)
    } else {
      setnotes_visible(!notes_visible)
    }
  }

  async function submitform1() {
    if (notes_visible) {
      await CreateUpdateStickyNotes({ data: data })
    }
  }


  function browserReload() {
    window.location.reload();
    setforceUpdate(false)
  }

  // return (
  //    <SocialAuth />
  // )

  return (
    <div className='dark max-h-screen min-h-screen overflow-hidden w-screen overflow-x-hidden'>



      {(['Low', 'Medium']?.includes(appUpdate?.update_priority) && (appUpdate?.version != appVersion) && pathname == '/dashboard/my_dashboard') &&
        <div onClick={browserReload} className='absolute bg-green-100 border cursor-pointer z-50 right-2 top-2 border-green-400 rounded-[14px] px-1'>
          <button>
            <h6 className='text-[11px] flex items-center text-green-600 font-[800] px-2 py-1'>Update <MdOutlineBrowserUpdated size={14} className='ml-2' /> </h6>
          </button>
        </div>}

      <Modal open={false} footer={false} closable={false} width={300} className='absolute top-0 left-[40%]'>
        <div>
          <h6 className='font-bold'>Oops!</h6>
          <h6 className='text-[11px] bg-slate-100 p-1'>There is an new update available for all the users which is mandatory to update.</h6>

          <div onClick={browserReload} className='flex mt-3 w-max mx-auto items-center justify-center bg-red-100 border cursor-pointer z-50  border-red-400 rounded-[14px] px-1'>
            <button>
              <h6 className='text-[11px] flex items-center text-red-600 font-[800] px-2 py-1'>Update <MdOutlineBrowserUpdated size={14} className='ml-2' /> </h6>
            </button>
          </div>
        </div>

      </Modal>



      {enable_drawer?.includes(location.pathname) && <div className={`flex  items-center p-2 w-full h-10 sticky top-0 md:hidden bg-[${btnColor}]`}>
        <HiOutlineMenu className='cursor-pointer' size={24} onClick={() => setMobileOpen(!mobileOpen)} />
        <h6 className='ml-2 font-[600] capitalize'>{location.pathname.replaceAll('/', '')}</h6>
      </div>}

      <div className="relative flex w-full border-box">





        {(zoom === false || zoom === undefined) && <>
          {location.pathname !== "/" && !disabled_drawer.includes(location.pathname) && auth?.isAuthenticated && (
            <>
              <CssBaseline />

              <div className='hidden min-h-screen max-h-screen overflow-y-scroll no-scrollbar  md:block min-w-[70px]  w-[70px] max-w-[70px]'>
                {drawer}
              </div>
            </>
          )}
        </>}




        {/* <AppBar /> */}


        {/* <h1 className='ml-20 p-2 bg-slate-600' onClick={()=>toast.success('Hello Im here')}>Amma</h1> */}



        <div className={` bg-gray-0 h-screen relative ${zoom ? 'w-[100vw]' : 'md:min-w-[95vw]  md:w-[95vw]'} overflow-x-hidden`}>
          <Toaster position="bottom-center" toastOptions={{
            success: {
              style: {
                background: 'black',
                color: 'white',
                padding: '4px 7px',
                fontSize: '12px',
                borderRadius: '2px',
                iconTheme: {
                  fontSize: '4px'
                }
              }
            },
            error: {
              style: {
                background: 'black',
                color: 'white',
                padding: '4px 7px',
                fontSize: '12px',
                borderRadius: '2px',
                iconTheme: {
                  fontSize: '4px'
                }
              }
            }
          }} />




          <Routes>
            <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
            {/* <Route exact path="/conveyance_vochuer_create" element={<ConveyanceVochuerCE />} /> */}
            <Route exact path="/gallery/product_list/detail/:id?" element={<ProductDetail />} />
            <Route exact path="/inspection_review/:id?" element={<LeadInspectionReviewParamsDetail />} />
            {routes.map((screen, index) => (
              <Route key={index} exact path={screen.path} element={<PrivateRoute roles={screen.roles}  >{screen.component}</PrivateRoute>} />
            ))}
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot_password" element={<MobileOTP />} />
            <Route exact path="/password_reset?" element={<ForgotPassword />} />
            <Route exact path="/update_password" element={<UpdatePassword />} />
          </Routes>
        </div>


        {!disabled_drawer?.includes(path) && auth?.isAuthenticated &&
          <div className='z-50'>
            <FaCaretUp onClick={() => setnotes_visible(!notes_visible)} size={25} className='bg-slate-600 absolute bottom-[0px] right-[11.5%] px-1.5 py-1 h-[20px] cursor-pointer text-white' />
            {notes_visible && <div onMouseLeave={() => { submitform1() }} className='w-[250px] p-1 min-h-[200px] bg-sky-50 max-h-[200px] border-box  absolute bottom-0 right-[50px] '>
              <FaCaretDown onClick={() => { setnotes_visible(!notes_visible); submitform() }} size={25} className='bg-slate-600 absolute -top-[21px] left-[44%]  px-1.5 py-1 h-[20px] cursor-pointer text-white' />
              <textarea value={data} onChange={(e) => setdata(e.target.value)} className='h-48 focus:outline-none text-[12px] w-full bg-transparent ' />
            </div>}
          </div>}

      </div>


    </div>
  );
}

export default App;
