import React, { useState, useEffect } from 'react';
import FTSLeadMenu from './FTSLeadMenu';
import { GetVericalBasedAssignedRecievedService1 } from '../../services/ftsVerticalReportServices/FTSVerticalLeadReportServices';
import { GetDepartmentService } from '../../services/DepartmentServices';
import { DatePicker, Select } from 'antd';
import { BiFilterAlt } from 'react-icons/bi'
import { BsArrowRepeat } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineEdit } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { IconButton, Tooltip } from '@mui/material'
import { SearchUser } from '../../services/AuthServices';

function FTSleadsAnalyticsDetail() {

  const roles = useSelector(state => state.Auth.roles)

  const { state } = useLocation()

  console.log("State", state)
  const navigate = useNavigate()

  const [department, setdepartment] = useState([])
  const [user, setuser] = useState([])

  const [search, setsearch] = useState({ from_date: state?.from_date !== undefined ? state?.from_date : '', to_date: state?.to_date !== undefined ? state?.to_date : '', from_date1: state?.from_date1 !== undefined ? state?.from_date1 : '', to_date1: state?.to_date1 !== undefined ? state?.to_date1 : '', assigned_department: '', recieved_department: state?.recieved_department !== undefined ? state?.recieved_department : '', user: state?.user !== undefined ? state?.user : '' });
  const [data1, setdata1] = useState({ data: [], pagination: { total: 0, } })

  const [page, setpage] = useState(1)

  useEffect(() => {
    getdepartment();
    getdata1();
  }, [page, state]);


  // async function getdata1() {
  //   const response = await GetVericalBasedAssignedRecievedService1(search?.from_date1, search?.to_date1, search?.assigned_department?.value, search?.recieved_department?.value, search?.user?.value, page)
  //   setdata1(response?.data)
  //   console.log("API Response:", response?.data);
  // }

  async function getdata1() {
    const response = await GetVericalBasedAssignedRecievedService1(
      search?.from_date1,
      search?.to_date1,
      search?.assigned_department?.value,
      search?.recieved_department?.value,
      search?.user?.value,
      page
    );

    console.log("Raw API Response:", response);

    if (response?.data) {
      console.log("API Data:", response?.data);
      console.log("First Data Entry:", response?.data?.data?.[0]);
    }

    setdata1(response?.data);
  }





  async function getdepartment() {
    const response = await GetDepartmentService()
    let d = response?.data?.datas

    let arr = []
    d?.forEach((d1) => {
      arr.push({ label: d1?.department_name, value: d1?.id })
    })

    console.log("data1", data1)
    console.log("Mapped Data:", d);
    console.log("Actual Revenue Achieved:", d?.actual_revenue_achieved);
    console.log("Estimated Revenue:", d?.estimated_revenue);
    setdepartment(arr)
  }

  async function applyfilterfunction() {
    setpage(1)
    const response = await GetVericalBasedAssignedRecievedService1(search?.from_date1, search?.to_date1, search?.assigned_department?.value, search?.recieved_department?.value, search?.user?.value, 1)
    setdata1(response?.data)
  }

  async function resetfilterfunction() {
    setsearch({ from_date: '', to_date: '', from_date1: '', to_date1: '', assigned_department: '', recieved_department: '' })
    setpage(1)
    const response = await GetVericalBasedAssignedRecievedService1('', '', '', '', '', 1)
    setdata1(response?.data)
  }

  function handleChangedepartment(v, type) {
    if (type == 1) {
      let findData = department?.find((f) => f?.value == v)
      setsearch({ ...search, assigned_department: findData })
    } else if (type == 2) {
      let findData = department?.find((f) => f?.value == v)
      setsearch({ ...search, recieved_department: findData })
    } else if (type == 3) {
      let findData = user?.find((f) => f?.value == v)
      setsearch({ ...search, user: findData })
    }
  }

  async function onSearch(v) {
    const response = await SearchUser(v)
    let arr = []
    response?.data?.forEach((d) => {
      arr.push({ value: d?._id, label: d?.name })
    })
    setuser(arr)
    // console.log("response kp na",response)
  }




  return (
    <div className="flex bg-white">
      <div className="min-w-44">
        <FTSLeadMenu />
      </div>

      <div className="p-3 bg-white w-full h-full text-black">
        <div className="mb-3  border-b flex flex-col p-2">
          <h2 className="text-sm font-extrabold text-gray-700">FTS Leads Analytics For Vertical ( {search?.recieved_department?.value !== undefined && `${search?.recieved_department?.label} - `}({data1?.pagination?.total}) )</h2>

          <div className='flex items-center mt-2'>
            <h6 className='mr-2 text-[12px] font-[600]'>{page == 1 ? data1?.data?.length > 0 ? 1 : 0 : (page - 1) * data1?.pagination?.limit} - {data1?.pagination?.limit} of {data1?.pagination?.total} </h6>
            <IconButton onClick={resetfilterfunction}><BsArrowRepeat size={16} /></IconButton>

            <div>
              <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
              <IconButton onClick={() => { page < data1?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data1?.pagination?.totalPages === page || data1?.data?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>

            </div>
            <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, from_date: v, from_date1: v1 }) }} />
            <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, to_date: v, to_date1: v1 }) }} />

            <Select placeholder={'Assigned Department'} value={search?.assigned_department == '' ? null : search?.assigned_department} onChange={(e) => { console.log("clicked"); handleChangedepartment(e, 1) }} bordered={false} options={department} className='w-[120px] mr-2 h-[28px] border border-slate-300 rounded-[5px] ' />
            <Select placeholder={'Search User'} filterOption={false} showSearch onSearch={onSearch} value={search?.user == '' ? null : search?.user} onChange={(e) => { console.log("clicked"); handleChangedepartment(e, 3) }} bordered={false} options={user} className='w-[120px] mr-2 h-[28px] border border-slate-300 rounded-[5px] ' />
            <Select placeholder={"Recieved Department"} value={search?.recieved_department == '' ? null : search?.recieved_department} onChange={(e) => handleChangedepartment(e, 2)} bordered={false} options={department} className='w-[120px] mr-2 border h-[28px] border-slate-300 rounded-[5px] ' />

            <BiFilterAlt onClick={() => applyfilterfunction(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
          </div>

        </div>

        <div className="overflow-x-auto">
          <>
            {data1?.data?.length > 0 &&
              <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
                <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                  <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                  <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                  <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person Info</h6>
                  <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Created Department</h6>
                  <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Assigned Department</h6>
                  <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600  border-r'>Remarks</h6>
                  <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600  border-r'>Stage</h6>
                  <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Status</h6>
                </div>

                {data1?.data?.map((d, i) => (
                  <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
                    <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1  border-r border-slate-200 '>{(page > 1 ? i + 1 + (25 * (page - 1)) : i + 1)}</h6>
                    <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 border-r border-slate-200'>{d?.fts_lead?.company_name}
                      {d?.zone !== undefined && <h6 className='text-[10px]'>Zone : {d?.fts_lead?.zone}</h6>}</h6>
                    <h6 className='text-[12px] font-[500] w-[15%] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                      <div>
                        <h6>{d?.fts_lead?.contact_name}</h6>
                        <span>{d?.fts_lead?.phone} / {d?.fts_lead?.email}  </span>
                      </div>
                    </h6>
                    <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
                      <span className='text-sky-500'>{d?.fts_lead?.lead_owner_department?.department_name}</span>
                      <h6 className='text-[10px]'>Created By : {d?.fts_lead?.lead_owner?.name}</h6>
                      {d?.fts_lead?.previously_handled_by != '' && <h6 className='text-[10px] font-[500]'>Previously Handled User : <span className='font-[800]'>{d?.fts_lead?.previously_handled_by}</span></h6>}
                    </h6>
                    <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
                      {d?.department_id?.department_name}
                    </h6>
                    <h6 className='text-[11px]  w-[15%]  px-2 py-1  border-r border-slate-200'>
                      Requirment :  <span className='font-[800]'>{d?.requirment}</span>  /
                      Remarks :  <span className='font-[800]'>{d?.remarks}</span>

                      {d?.converted == '1' && <h6 onClick={() => navigate('/leads/contacts/detail', { state: d?.lead_id?._id })} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>}

                    </h6>
                    <h6 className='text-[12px]  flex items-start break-word  font-[500] w-[10%] border-r px-2 py-1 border-slate-200 '>
                      {d?.lead_id?._id !== undefined && <span className='bg-slate-100 px-2 py-1 rounded'>{d?.lead_id?.stage?.name}</span>}
                    </h6>


                    <h6 className='text-[12px]   font-[500] w-[10%]  px-2 py-1 truncate border-slate-200 '>
                      {d?.converted == '3' && <span className='bg-blue-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-blue-600 font-[700]'>Pending</span>}
                      {d?.converted == '4' && <span className='bg-violet-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-violet-600 font-[700]'>Progress</span>}
                      {d?.converted == '2' && <span className='bg-red-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-red-600 font-[700]'>Rejected</span>}
                      {d?.converted == '1' && <span className='bg-green-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-green-600 font-[700]'>Converted</span>}
                      {roles?.includes('admin') &&
                        // <AiOutlineEdit className='mt-1 ml-1' onClick={() => navigate('/fts_leads_assign_remark/list/edit', { state: d })} />
                        <AiOutlineEdit
                          className='mt-1 ml-1'
                          onClick={() =>
                            navigate('/fts_leads_assign_remark/list/edit', {
                              state: {
                                ...d,
                                actual_revenue_achieved: d?.actual_revenue_achieved || 0,
                                estimated_revenue: d?.estimated_revenue || 0
                              }
                            })
                          }
                        />

                      }
                    </h6>
                  </div>))}
              </div>}
          </>

          {data1?.data?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
              <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' />
              <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
              <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>
          }

        </div>
      </div>
    </div>
  );
}

export default FTSleadsAnalyticsDetail;





/////
// import React, { useState, useEffect } from 'react';
// import FTSLeadMenu from './FTSLeadMenu';
// import { GetVericalBasedAssignedRecievedService1 } from '../../services/ftsVerticalReportServices/FTSVerticalLeadReportServices';
// import { GetDepartmentService } from '../../services/DepartmentServices';
// import { DatePicker, Select } from 'antd';
// import { BiFilterAlt } from 'react-icons/bi'
// import { BsArrowRepeat } from 'react-icons/bs';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { AiOutlineEdit } from 'react-icons/ai';
// import { useSelector } from 'react-redux';
// import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
// import { IconButton, Tooltip } from '@mui/material'
// import { SearchUser } from '../../services/AuthServices';

// function FTSleadsAnalyticsDetail() {

//   const roles = useSelector(state => state.Auth.roles)

//   const { state } = useLocation()

//   console.log("State", state)
//   const navigate = useNavigate()

//   const [department, setdepartment] = useState([])
//   const [user, setuser] = useState([])

//   const [search, setsearch] = useState({ from_date: state?.from_date !== undefined ? state?.from_date : '', to_date: state?.to_date !== undefined ? state?.to_date : '', from_date1: state?.from_date1 !== undefined ? state?.from_date1 : '', to_date1: state?.to_date1 !== undefined ? state?.to_date1 : '', assigned_department: '', recieved_department: state?.recieved_department !== undefined ? state?.recieved_department : '', user: '' });
//   const [data1, setdata1] = useState({ data: [], pagination: { total: 0, } })

//   const [page, setpage] = useState(1)

//   useEffect(() => {
//     getdepartment()
//     getdata1()
//   }, [page, state])

//   console.log("search", search)

//   async function getdata1() {
//     const response = await GetVericalBasedAssignedRecievedService1(search?.from_date1, search?.to_date1, search?.assigned_department?.value, search?.recieved_department?.value, search?.user?.value, page)
//     setdata1(response?.data)
//   }

//   async function getdepartment() {
//     const response = await GetDepartmentService()
//     let d = response?.data?.datas

//     let arr = []
//     d?.forEach((d1) => {
//       arr.push({ label: d1?.department_name, value: d1?.id })
//     })
//     setdepartment(arr)
//   }

//   async function applyfilterfunction() {
//     setpage(1)
//     const response = await GetVericalBasedAssignedRecievedService1(search?.from_date1, search?.to_date1, search?.assigned_department?.value, search?.recieved_department?.value, search?.user?.value, 1)
//     setdata1(response?.data)
//   }

//   async function resetfilterfunction() {
//     setsearch({ from_date: '', to_date: '', from_date1: '', to_date1: '', assigned_department: '', recieved_department: '' })
//     setpage(1)
//     const response = await GetVericalBasedAssignedRecievedService1('', '', '', '', '', 1)
//     setdata1(response?.data)
//   }

//   function handleChangedepartment(v, type) {
//     if (type == 1) {
//       let findData = department?.find((f) => f?.value == v)
//       setsearch({ ...search, assigned_department: findData })
//     } else if (type == 2) {
//       let findData = department?.find((f) => f?.value == v)
//       setsearch({ ...search, recieved_department: findData })
//     } else if (type == 3) {
//       let findData = user?.find((f) => f?.value == v)
//       setsearch({ ...search, user: findData })
//     }
//   }

//   async function onSearch(v) {
//     const response = await SearchUser(v)
//     let arr = []
//     response?.data?.forEach((d) => {
//       arr.push({ value: d?._id, label: d?.name })
//     })
//     setuser(arr)
//     // console.log("response kp na",response)
//   }


//   console.log("data1", data1)

//   return (
//     <div className="flex bg-white">
//       <div className="min-w-44">
//         <FTSLeadMenu />
//       </div>

//       <div className="p-3 bg-white w-full h-full text-black">
//         <div className="mb-3  border-b flex flex-col p-2">
//           <h2 className="text-sm font-extrabold text-gray-700">FTS Leads Analytics For Vertical123 ( {search?.recieved_department?.value !== undefined && `${search?.recieved_department?.label} - `}({data1?.pagination?.total}) )</h2>

//           <div className='flex items-center mt-2'>
//             <h6 className='mr-2 text-[12px] font-[600]'>{page == 1 ? data1?.data?.length > 0 ? 1 : 0 : (page - 1) * data1?.pagination?.limit} - {data1?.pagination?.limit} of {data1?.pagination?.total} </h6>
//             <IconButton onClick={resetfilterfunction}><BsArrowRepeat size={16} /></IconButton>

//             <div>
//               <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
//               <IconButton onClick={() => { page < data1?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data1?.pagination?.totalPages === page || data1?.data?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>

//             </div>
//             <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, from_date: v, from_date1: v1 }) }} />
//             <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, to_date: v, to_date1: v1 }) }} />

//             <Select placeholder={'Assigned Department'} value={search?.assigned_department == '' ? null : search?.assigned_department} onChange={(e) => { console.log("clicked"); handleChangedepartment(e, 1) }} bordered={false} options={department} className='w-[120px] mr-2 h-[28px] border border-slate-300 rounded-[5px] ' />
//             <Select placeholder={'Search User'} filterOption={false} showSearch onSearch={onSearch} value={search?.user == '' ? null : search?.user} onChange={(e) => { console.log("clicked"); handleChangedepartment(e, 3) }} bordered={false} options={user} className='w-[120px] mr-2 h-[28px] border border-slate-300 rounded-[5px] ' />
//             <Select placeholder={"Recieved Department"} value={search?.recieved_department == '' ? null : search?.recieved_department} onChange={(e) => handleChangedepartment(e, 2)} bordered={false} options={department} className='w-[120px] mr-2 border h-[28px] border-slate-300 rounded-[5px] ' />

//             <BiFilterAlt onClick={() => applyfilterfunction(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
//           </div>

//         </div>

//         <div className="overflow-x-auto">
//           <>
//             {data1?.data?.length > 0 &&
//               <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
//                 <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
//                   <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
//                   <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
//                   <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person Info</h6>
//                   <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Created Department</h6>
//                   <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Assigned Department</h6>
//                   <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600  border-r'>Remarks</h6>
//                   <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600  border-r'>Stage</h6>
//                   <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Status</h6>
//                 </div>

//                 {data1?.data?.map((d, i) => (
//                   <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
//                     <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1  border-r border-slate-200 '>{(page > 1 ? i + 1 + (25 * (page - 1)) : i + 1)}</h6>
//                     <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 border-r border-slate-200'>{d?.fts_lead?.company_name}
//                       {d?.zone !== undefined && <h6 className='text-[10px]'>Zone : {d?.fts_lead?.zone}</h6>}</h6>
//                     <h6 className='text-[12px] font-[500] w-[15%] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
//                       <div>
//                         <h6>{d?.fts_lead?.contact_name}</h6>
//                         <span>{d?.fts_lead?.phone} / {d?.fts_lead?.email}  </span>
//                       </div>
//                     </h6>
//                     <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
//                       <span className='text-sky-500'>{d?.fts_lead?.lead_owner_department?.department_name}</span>
//                       <h6 className='text-[10px]'>Created By : {d?.fts_lead?.lead_owner?.name}</h6>
//                       {d?.fts_lead?.previously_handled_by != '' && <h6 className='text-[10px] font-[500]'>Previously Handled User : <span className='font-[800]'>{d?.fts_lead?.previously_handled_by}</span></h6>}
//                     </h6>
//                     <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
//                       {d?.department_id?.department_name}
//                     </h6>
//                     <h6 className='text-[11px]  w-[15%]  px-2 py-1  border-r border-slate-200'>
//                       Requirment :  <span className='font-[800]'>{d?.requirment}</span>  /
//                       Remarks :  <span className='font-[800]'>{d?.remarks}</span>

//                       {d?.converted == '1' && <h6 onClick={() => navigate('/leads/contacts/detail', { state: d?.lead_id?._id })} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>}

//                     </h6>
//                     <h6 className='text-[12px]  flex items-start break-word  font-[500] w-[10%] border-r px-2 py-1 border-slate-200 '>
//                       {d?.lead_id?._id !== undefined && <span className='bg-slate-100 px-2 py-1 rounded'>{d?.lead_id?.stage?.name}</span>}
//                     </h6>


//                     <h6 className='text-[12px]   font-[500] w-[10%]  px-2 py-1 truncate border-slate-200 '>
//                       {d?.converted == '3' && <span className='bg-blue-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-blue-600 font-[700]'>Pending</span>}
//                       {d?.converted == '4' && <span className='bg-violet-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-violet-600 font-[700]'>Progress</span>}
//                       {d?.converted == '2' && <span className='bg-red-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-red-600 font-[700]'>Rejected</span>}
//                       {d?.converted == '1' && <span className='bg-green-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-green-600 font-[700]'>Converted</span>}
//                       {roles?.includes('admin') &&
//                         <AiOutlineEdit className='mt-1 ml-1' onClick={() => navigate('/fts_leads_assign_remark/list/edit', { state: d })} />}
//                     </h6>
//                   </div>))}
//               </div>}
//           </>

//           {data1?.data?.length === 0 &&
//             <div className='grid place-items-center mt-20  items-center justify-center'>
//               <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' />
//               <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
//               <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
//             </div>
//           }

//         </div>
//       </div>
//     </div>
//   );
// }

// export default FTSleadsAnalyticsDetail;