import { deleteRequest, get, post, postfd, put } from "../helpers/apihelpers";



export const CreateFTSLeadsService = async (data) => {
    try {
        const result = await post(`api/fts_lead/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const CreateFTSLeadsService1 = async (data) => {
    try {

        const result = await post(`api/fts_lead/create_lead`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}


///update
export const UpdateFTSLeadsService1 = async (id, data) => {
    try {
        const result = await put(`api/fts_lead/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateFTSListBasedonIdService = async (data) => {
    try {
        const result = await post(`api/assign_lead/convert_data_to_contact`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFTSLeadsService = async (page, step) => {
    try {
        const result = await get(`api/fts_lead/get/null/${page}?step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllFTSLeadsService = async (department = '', users = '', created_department = '', page = 1, from_date = '', to_date = '') => {
    try {
        const result = await get(`api/fts_lead/getallleads/${department}/${users}/${page}?created_department=${created_department}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllFTSLeadsFilterService = async (department = null, created_department = null, users = null, page = 1, search_text = '', from_date = '', to_date = '', zone = '') => {

    try {
        const result = await get(`api/fts_lead/getallleads_filter/${department}/${users}/${page}?search_text=${search_text}&from_date=${from_date}&to_date=${to_date}&zone=${zone}&created_department=${created_department}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllFTSLeadsToMeService = async (department, page) => {
    try {
        const result = await get(`api/fts_lead/fidelitus_leads_shared_to_me/${department}/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllFTSLeadsBasedStageService = async (department, page, step, assigned_to) => {
    try {
        const result = await get(`api/fts_lead/getbased_stage?department=${department}&page=${page}&step=${step}&assigned_to=${assigned_to}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllFTSLeadsGraphService = async (department, users = '', created_department = '', from_date = '', to_date = '') => {
    try {
        const result = await get(`api/fts_lead/getallleads_graph/${department}?user=${users}&created_department=${created_department}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllFTSLeadsIndivisualDateService = async (department, users = '', created_department = '', from_date = '', to_date = '') => {
    try {
        const result = await get(`api/fts_lead/getfidelitusleads_indivisualdate/${department}?user=${users}&created_department=${created_department}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllFTSLeadsBasedDateService = async (department, users = '', created_department = '', search_text = '', from_date = '', to_date = '', page = 1) => {
    try {
        const result = await get(`api/fts_lead/getfidelitusleads_basedDate/${department}?user=${users}&created_department=${created_department}&search_text=${search_text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllFTSLeadsCalendarService = async (department = '', from_date = '', to_date = '', created_department = '', users = '',) => {
    try {
        const result = await get(`api/fts_lead/getallleads_calendar/${department}?from_date=${from_date}&to_date=${to_date}&created_department=${created_department}&user=${users}`);
        return result;
    } catch (err) {
        return err.response;
    }
}



export const GetFTSLeadsDetailService = async (id) => {
    try {
        const result = await get(`api/fts_lead/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SearchFTSLeadsService = async ({ search_text, status, from_date, to_date, zone, page, department = '' }) => {
    try {
        const result = await get(`api/fts_lead/search?search_text=${search_text}&status=${status}&from_date=${from_date}&to_date=${to_date}&zone=${zone}&page=${page}&department=${department}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SearchFTSLeadsService1 = async ({ search_text, status, from_date, to_date, zone, page, department = '', step }) => {
    try {
        const result = await get(`api/fts_lead/search1?search_text=${search_text}&status=${status}&from_date=${from_date}&to_date=${to_date}&zone=${zone}&page=${page}&department=${department}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFTSLeadsService = async (data, id) => {
    try {
        const result = await put(`api/fts_lead/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFTSImportantLeads = async (data) => {
    try {
        const result = await put(`api/assign_lead/update_importance/${data._id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const SearchFTSLeadService = async ({ search_text, status, from_date, to_date, zone, page }) => {
    try {
        const result = await get(`api/assign_lead/search?search_text=${search_text}&status=${status}&from_date=${from_date}&to_date=${to_date}&zone=${zone}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFTSLeadService = async (id) => {
    try {
        const result = await deleteRequest(`api/fts_lead/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}
export const DeleteFTSLeadAssignedService = async (id) => {
    try {
        const result = await deleteRequest(`api/fts_lead/delete_fts_lead_assigned/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadFTSLeadExcel = async (file) => {
    try {
        const result = await postfd(`api/fts_lead/upload_excel`, { file: file });
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFTSLead = async () => {
    try {
        const result = await deleteRequest(`api/fts_lead/deleteData`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFTSLeadAssigned = async ({ id, data }) => {

    try {
        const result = await put(`api/fts_lead/update_fidelitus_lead_Assigned/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const CreateFTSLeadsShareToFTSService = async (data) => {
    try {
        const result = await post(`api/fts_lead/create_lead_share_to_fts`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetFTSLeadsSharedByUsService = async (page = 1, step, from_date, to_date, text, department, user) => {
    try {
        const result = await get(`api/fts_lead/get_lead_shared_from_fts/null/${page}?converted=${step}&from_date=${from_date}&to_date=${to_date}&search_text=${text}&department=${department}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFTSLeadsSharedToUsService = async (page = 1, step, from_date, to_date, department, user, text) => {
    try {
        const result = await get(`api/fts_lead/get_lead_shared_to_fts/null/${page}?lead_shared_status=${step}&from_date=${from_date}&to_date=${to_date}&department=${department}&user=${user}&search=${text}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFTSLeadsSharedToUsBasedUserService = async (page = 1) => {
    try {
        const result = await get(`api/fts_lead/get_fts_leads_shared_to_us_based_user/null/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFTSLeadsSharedToFTSBasedUserAndDepartmentService = async (page = 1, text, from_date, to_date, department,status,user) => {
    try {
        const result = await get(`api/fts_lead/getftsleads_shared_by_us_to_fts_portal/null/${page}?search=${text}&from_date=${from_date}&to_date=${to_date}&department=${department}&status=${status}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFTSLeadsSharedToUsBasedUserFTSService = async (page = 1, text, from_date, to_date, department,status) => {
    try {
        const result = await get(`api/fts_lead/getftsleads_shared_to_us_by_fts/null/${page}?search=${text}&from_date=${from_date}&to_date=${to_date}&department=${department}&status=${status}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFTSDashboardService = async () => {
    try {
        const result = await get(`api/fts_lead/get_fts_dashboard`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const UpdateFTSLeadAssignedNew = async ({ id, data }) => {

    try {
        const result = await put(`api/fts_lead/update_fts_lead_Assigned/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

