import React,{useEffect, useState} from 'react'
import GalleryAppBar from '../GalleryAppBar'
import { TextAreaInput1, TextInput } from '../../../components/input'
import GoBack from '../../../components/back/GoBack'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { useNavigate,useLocation } from 'react-router-dom'
import Uploader from '../../../components/Uploader'
import { toast } from 'react-hot-toast'
import { CreateGalleryProductList, UpdateGalleryProductList } from '../../../services/Gallery/GalleryProductServices'
import { Select } from 'antd'
import { SearchGalleryProductCategoryService } from '../../../services/Gallery/GalleryProductCategoryServices'
import { SearchGalleryProductBrandService } from '../../../services/Gallery/GalleryProductBrandServices'
import { SearchGalleryProductTagsService } from '../../../services/Gallery/GalleryProductTagsServices'
import { GetGalleryArtistList, SearchGalleryArtistService } from '../../../services/Gallery/GalleryArtistServices'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'

function GalleryProductCE() {

  const {pathname,state} = useLocation()
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  
  const navigate = useNavigate()  
  const [loader,setloader] = useState(false)
  const [dropdown,setdropdown] = useState({category:[],brand:[],tags:[],artist:[]})
  const [old_dropdown,setold_dropdown] = useState({category:[],brand:[],tags:[]})
  const [data,setdata] = useState({name:'',measurment:'',available:true,qty:'',description:'',specification:'',stock_placement:'',image:'',mrp:'',selling_price:'',category:[],tags:[],brand:[],discount_percentage:'',sold_location:'',sold_person_information:'',sold_price:''})  
  const [error,seterror] = useState({name:'',measurment:'',available:'',qty:'',description:'',specification:'',stock_placement:'',image:'',mrp:'',selling_price:'',category:'',tags:'',brand:'',sold_person_information:'',sold_price:''})  
  
  const sold_location = [{label:'Fidelitus Gallery',value:'Fidelitus Gallery'},{label:'Work Of Art',value:'Work Of Art'}]

  useEffect(()=>{
      getData()
      if(path === 'edit'){
         let tags_list = []
         let category_list = []
         let brand_list = []

         state?.data?.category?.forEach((d)=>{
          category_list.push({label:d?.name,value:d?._id})
         })
         state?.data?.brand?.forEach((d)=>{
          brand_list.push({label:d?.name,value:d?._id})
         })
         state?.data?.tags?.forEach((d)=>{
          tags_list.push({label:d?.name,value:d?._id})
         })

         setdropdown({category:category_list,tags:tags_list,brand:brand_list})
         setold_dropdown({category:category_list,tags:tags_list,brand:brand_list})

         setdata({
          ...state?.data,
          category:category_list,
          brand:brand_list,
          tags:tags_list,
          sold_location:state?.data?.sold_location !== '' ? {label:state?.data?.sold_location,value:state?.data?.sold_location} : '',
          sold_person_information:state?.data?.sold_person_information !== '' ? state?.data?.sold_person_information: '',
          sold_price:state?.data?.sold_price !== '' ? state?.data?.sold_price: '',
          artist:{label:state?.data?.artist?.name,value:state?.data?.artist?._id}
         })

      }
  },[])

  async function getData(){
    const response = await GetGalleryArtistList(1)
    let arr = []
    response?.data?.datas?.forEach(d => {
        arr.push({label:`${d?.name}`,value:d?._id})
    });
    setdropdown({...dropdown,artist:arr})
    
  }

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function submitdata(){
    if(!data?.name){
        seterror({...error,name:'The name field is required'})
    }else{
        setloader(true)
        const fd = new FormData()
        fd.append('name',data?.name)
        fd.append('measurment',data?.measurment)
        fd.append('available',data?.available ? 1 : 0)
        if(!data?.available){
          fd.append('sold_location',data?.sold_location?.value)
        }
        fd.append('discount_percentage',data?.discount_percentage)
        fd.append('stock_placement',data?.stock_placement)
        fd.append('qty',data?.qty)
        fd.append('description',data?.description)
        fd.append('specification',data?.specification)
        fd.append('mrp',data?.mrp)
        fd.append('artist',data?.artist?.value)
        fd.append('selling_price',data?.selling_price)
        fd.append('sold_person_information',data?.sold_person_information)
        fd.append('sold_price',data?.sold_price)

        console.log(data?.artist)
        if(data?.category?.length > 0){
          data?.category?.forEach((c,i)=>{
            fd.append(`category[${i}]`,c?.value)
          })
        }

        if(data?.brand?.length > 0){
          data?.brand?.forEach((c,i)=>{
            fd.append(`brand[${i}]`,c?.value)
          })
        }

        if(data?.tags?.length > 0){
          data?.tags?.forEach((c,i)=>{
            fd.append(`tags[${i}]`,c?.value)
          })
        }

        if(data?.image?.name !== undefined){
            fd.append('file',data?.image)
        }
       
        if(path === 'create'){
            const response = await CreateGalleryProductList(fd)
            if(response.status === 201){
                setloader(false)
                toast.success("Created successfully") 
                resetForm()
            }
        }else if(path === 'edit'){
            const response = await UpdateGalleryProductList(state?.data?._id,fd)
            if(response.status === 200){
                setloader(false)
                toast.success("Updated successfully") 
                navigate(-1)
            }
        }
        

    }

  }

  async function searchDropdown(v,v1){
    if(v1 === 'category'){   
        const response = await SearchGalleryProductCategoryService({search_text:v,from_date:'',to_date:'',page:1})
        let arr = []
        response?.data?.datas?.forEach(d => {
            arr.push({label:`${d?.name}`,value:d?._id})
        });
        if(data?.category?.length > 0){
        setold_dropdown({...old_dropdown,category:data.category})
        }
        setdropdown({...dropdown,category:arr})
        return arr
    }else if(v1 === 'brand'){
      const response = await SearchGalleryProductBrandService({search_text:v,from_date:'',to_date:'',page:1})
        let arr = []
        response?.data?.datas?.forEach(d => {
            arr.push({label:`${d?.name}`,value:d?._id})
        });
        if(data?.brand?.length > 0){
        setold_dropdown({...old_dropdown,brand:data.brand})
        }
        setdropdown({...dropdown,brand:arr})
        return arr
    }else if(v1 === 'tags'){
      const response = await SearchGalleryProductTagsService({search_text:v,from_date:'',to_date:'',page:1})
        let arr = []
        response?.data?.datas?.forEach(d => {
            arr.push({label:`${d?.name}`,value:d?._id})
        });
        if(data?.tags?.length > 0){
        setold_dropdown({...old_dropdown,tags:data.tags})
        }
        setdropdown({...dropdown,tags:arr})
        return arr
    }else if(v1 === 'artist'){
      const response = await SearchGalleryArtistService({search_text:v,from_date:'',to_date:'',page:1})
        let arr = []
        response?.data?.datas?.forEach(d => {
            arr.push({label:`${d?.name}`,value:d?._id})
        });
        if(data?.artist?.length > 0){
        setold_dropdown({...old_dropdown,artist:data.artist})
        }
        setdropdown({...dropdown,artist:arr})
        return arr
    }
  }

  async function handleSelect(e,e1){
    if(e1 === "category"){
        let category_list = []
        e?.forEach((d)=>{
          let selected_data = dropdown.category.find((f)=>f.value === d)
          if(selected_data === undefined){
            let selected_data1 = old_dropdown.category.find((f)=>f.value === d)
            if(selected_data1 !== undefined){
              category_list.push(selected_data1) 
            }
          }else{
              category_list.push(selected_data)
          }
        })
        setdata({...data,category:category_list})
      }else if(e1 === "brand"){
        let brand_list = []
        e?.forEach((d)=>{
          let selected_data = dropdown.brand.find((f)=>f.value === d)
          if(selected_data === undefined){
            let selected_data1 = old_dropdown.brand.find((f)=>f.value === d)
            if(selected_data1 !== undefined){
              brand_list.push(selected_data1) 
            }
          }else{
              brand_list.push(selected_data)
          }
        })
        setdata({...data,brand:brand_list})
      }else if(e1 === "tags"){
        let tags_list = []
        e?.forEach((d)=>{
          let selected_data = dropdown.tags.find((f)=>f.value === d)
          if(selected_data === undefined){
            let selected_data1 = old_dropdown.tags.find((f)=>f.value === d)
            if(selected_data1 !== undefined){
              tags_list.push(selected_data1) 
            }
          }else{
            tags_list.push(selected_data)
          }
        })
        setdata({...data,tags:tags_list})
      }else if(e1 === "artist"){
        setdata({...data,artist:dropdown?.artist?.find((f)=>f.value === e)})
      }else if(e1 === "sold_location"){
        setdata({...data,sold_location:sold_location?.find((f)=>f.value === e)})
      }
  
  }

  function resetForm(){
    setdata({name:'',measurment:'',available:true,qty:'',description:'',specification:'',stock_placement:'',image:'',mrp:'',selling_price:'',category:[],tags:[],brand:[],discount_percentage:'',sold_location:''})
    seterror({name:'',measurment:'',available:'',qty:'',description:'',specification:'',image:'',mrp:'',selling_price:''})
  }


  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
        <div className='block sm:flex'>
            <GalleryAppBar />
            <div className='w-[85%]  ml-4 '>
            <div className='w-full  pb-4 '>

          
              <div className='flex w-[50%] overflow-x-hidden   border-r h-screen overflow-y-scroll mb-10 '>
              <div className='pr-5  py-5'>
              <GoBack />  

            

              <h6 className='font-[700] mb-1 w-64'>Create/Edit Product Data</h6>
              <h6 className='text-[11px] p-2 w-64 mb-2 bg-slate-100'>Use the below form to create or update the  product data</h6>

              <div className='flex  pr-5'>   
                  <div className='w-64 mr-5'>
              <h6 className='text-[11px] font-[600] mb-1' >{'Photo'}</h6>
                <Uploader image={data?.image}  setimagefunc={(e)=>{setdata({...data,image:e});seterror({...error,image:''})}}  removeimageuploadfunc = {()=>{setdata({...data,image:''});seterror({...error,image:''})}} />

                  <TextInput 
                      label={'Name'}  
                      variant="standard"
                      name="name"
                      type="text"
                      error={error.name}
                      value={data.name}
                      handlechange={handlechange}
                      placeholder="Enter your name"
                  />

                  <TextInput 
                      label={'Measurment'}  
                      variant="standard"
                      name="measurment"
                      type="text"
                      error={error.measurment}
                      value={data.measurment}
                      handlechange={handlechange}
                      placeholder="Enter your mobile no"
                  />

              <TextInput 
                      label={'Quantity'}  
                      variant="standard"
                      name="qty"
                      type="number"
                      error={error.qty}
                      value={data.qty}
                      handlechange={handlechange}
                      placeholder="Enter your email address"
                  />

              

                  <TextInput 
                      label={'Mrp'}  
                      variant="standard"
                      name="mrp"
                      type="text"
                      error={error.mrp}
                      value={data.mrp}
                      handlechange={handlechange}
                      placeholder="Enter your email address"
                  />

                  <TextInput 
                      label={'Selling Price'}  
                      variant="standard"
                      name="selling_price"
                      type="text"
                      error={error.selling_price}
                      value={data.selling_price}
                      handlechange={handlechange}
                      placeholder="Enter your email address"
                  />

                  <div className=' flex mt-4 pb-5'>
                      <ButtonOutlinedAutoWidth btnName={'Back'} onClick={()=>navigate(-1)}  />
                      <div className='ml-2'>
                      <ButtonFilledAutoWidth btnName={loader ? 'Uploading' : 'Save'} onClick={submitdata} />
                    </div>
                  </div>  

                  </div>
                  <div className='w-64 ml-5 pb-5'>

                  <TextInput 
                      label={'Discount Percentage'}  
                      variant="standard"
                      name="discount_percentage"
                      type="text"
                      error={error.discount_percentage}
                      value={data.discount_percentage}
                      handlechange={handlechange}
                      placeholder="Enter your email address"
                  />   

                  <h6 className='text-[12px] font-[600] mb-1 mt-2'>Artist </h6>
                  <Select
                    value={data.artist} 
                    error={error.artist}
                    bordered={false}
                    filterOption={false}
                    placeholder="" 
                    onSearch={(v)=>searchDropdown(v,'artist')}
                    onChange={(e)=>handleSelect(e,'artist')} 
                    options={dropdown?.artist} 
                    className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'

                    />  

                <div className='flex mt-2.5'>
                <div className='flex items-center mt-0 mr-2'>
                {data?.available ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,available:!data.available})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,available:!data.available})} /> }
                <h6 className="text-[11.5px] font-[600] ml-1">Available</h6> 
                </div>  

                <div className='flex items-center mt-0 mr-2'>
                {!data?.available ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,available:!data.available})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,available:!data.available})} /> }
                <h6 className="text-[11.5px] font-[600] ml-1">Sold Out</h6> 
                </div>  
                </div>


                  {!data?.available &&   
                  <>
                  <h6 className='text-[12px] font-[600] mb-1 mt-2'>Sold Location </h6>
                  <Select
                    value={data.sold_location} 
                    error={error.sold_location}
                    bordered={false}
                    filterOption={false}
                    placeholder="" 
                    onChange={(e)=>handleSelect(e,'sold_location')} 
                    options={sold_location} 
                    className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>  
                  </>}  

                  {/* <h6 className='text-[12px] font-[600] mb-1 mt-1'>Category </h6>
                  <Select
                    value={data.category} 
                    error={error.category}
                    bordered={false}
                    // showSearch 
                    onSearch={(v)=>searchDropdown(v,'category')}
                    filterOption={false}
                    placeholder="" 
                    mode="multiple"
                    onChange={(e)=>handleSelect(e,'category')} 
                    options={dropdown?.category} 
                    className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'

                    />


                  <h6 className='text-[12px] font-[600] mb-1 mt-2'>Brand </h6>
                  <Select
                    value={data.brand} 
                    error={error.brand}
                    bordered={false}
                    filterOption={false}
                    placeholder="" 
                    mode="multiple"
                    onSearch={(v)=>searchDropdown(v,'brand')}
                    onChange={(e)=>handleSelect(e,'brand')} 
                    options={dropdown?.brand} 
                    className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'

                    />  

                  <h6 className='text-[12px] font-[600] mb-1 mt-2'>Tags </h6>
                  <Select
                    value={data.tags} 
                    error={error.tags}
                    bordered={false}
                    filterOption={false}
                    placeholder="" 
                    mode="multiple"
                    onSearch={(v)=>searchDropdown(v,'tags')}
                    onChange={(e)=>handleSelect(e,'tags')} 
                    options={dropdown?.tags} 
                    className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'

                    />   */}

<TextAreaInput1 
                      label={'Description'}  
                      variant="standard"
                      name="description"
                      type="text"
                      error={error.description}
                      value={data.description}
                      handlechange={handlechange}
                      placeholder=""
                  /> 

                  

                  <TextAreaInput1 
                      label={'Specification'}  
                      variant="standard"
                      name="specification"
                      type="text"
                      error={error.specification}
                      value={data.specification}
                      handlechange={handlechange}
                      placeholder=""
                  /> 

                <TextAreaInput1 
                      label={'Stock Placement'}  
                      variant="standard"
                      name="stock_placement"
                      type="text"
                      error={error.stock_placement}
                      value={data.stock_placement}
                      handlechange={handlechange}
                      placeholder=""
                  /> 

                <TextAreaInput1 
                      label={'Sold Person Information'}  
                      variant="standard"
                      name="sold_person_information"
                      type="text"
                      error={error.sold_person_information}
                      value={data.sold_person_information}
                      handlechange={handlechange}
                      placeholder=""
                  /> 

                  <TextInput 
                      label={'Sold Price'}  
                      variant="standard"
                      name="sold_price"
                      type="text"
                      error={error.sold_price}
                      value={data.sold_price}
                      handlechange={handlechange}
                      placeholder=""
                  /> 


                  </div>
              </div>   
              </div>
              </div>

              </div>
            </div>
        </div>
    </div>
  )
}

export default GalleryProductCE