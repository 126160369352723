import React, { useEffect, useState } from 'react'
import { BsArrowRepeat } from 'react-icons/bs';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { IconButton } from '@mui/material'
import FTSLeadMenu from './FTSLeadMenu';
import { GetFTSLeadsSharedToFTSBasedUserAndDepartmentService } from '../../services/FTSLeadServices';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineFilter } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { DatePicker, Select } from 'antd';
import { BiTable } from "react-icons/bi";
import { RiLayoutGridLine } from "react-icons/ri";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineClose } from 'react-icons/ai';

import moment from 'moment';
import { ButtonFilledAutoWidth } from '../../components/button';
import { GetDepartmentService, GetUsersByDepartment } from '../../services/DepartmentServices';
import { GetVericalBasedAssignedRecievedBasedUserService } from '../../services/ftsVerticalReportServices/FTSVerticalLeadReportServices';
import ReactApexChart from 'react-apexcharts';
import { PiChartBarBold } from "react-icons/pi";
import Priceconstants from '../../constants/imageConstants';
import { GetReportingMembersDetail } from '../../services/AdminServicesfile/ReportingServices';

function FTSLeadSharedToFTSUserDetail() {

  const {state} = useLocation()


  const navigate = useNavigate()
  const [search, setsearch] = useState({ text: '', from_date: state?.from_date != undefined ? state?.from_date : '', from_date1: state?.from_date1 != undefined ? state?.from_date1 : '', to_date: state?.to_date != undefined ? state?.to_date : '', to_date1: state?.to_date1 != undefined ? state?.to_date1 : '', department: state?.department?.value !== undefined ? state?.department?.value : '',status:state?.status !== undefined ? state?.status : '',user:state?.user !== undefined ? state?.user : '' })

  const converted = [
    { label: 'Converted', value: 1 },
    { label: 'Rejected', value: 2 },
    { label: 'Pending', value: 3 },
    { label: 'Progress', value: 4 },
    { label: 'Lead Generated', value: 5 },
  ]

  const [page, setpage] = useState(1)
  const [department, setdepartment] = useState([])
  const [department1, setdepartment1] = useState([])
  const [leads, setleads] = useState({ datas: [], pagination: { total: 0, totalPages: 0 } })
  const [step, setstep] = useState(1)
  const [data1, setdata1] = useState({})

  const [users,setusers] = useState([])
  

  function handlechange(e){
    setsearch({...search,user:e})
  }


  let auth = useSelector(state => state.Auth)
  let departmentId = auth.department_id[0].id
  let roles = auth.roles

  const department_FCPL_FTS_BACKEND = auth?.department_id?.filter((f) => f?.department_name == 'FTS Team' || f?.department_name == 'Corp Team' || f?.department_name == 'Backend Team')?.length > 0 ? true : false


  useEffect(() => {
    if((roles.includes('hod') || roles?.includes('reporting_manager'))){
      getuserbaseddepartment(departmentId)
    }
    getdata()
    // getdata1()
    getdepartments()
  }, [page])

   async function getuserbaseddepartment(id){
        if(roles.includes('hod')){
          const response = await GetUsersByDepartment(id) 
          let arr = []
          response.data.datas.forEach((d)=>{
              arr.push({label:d?.name,value:d?._id})
          })
          setusers(arr)
        }else if(roles?.includes('reporting_manager')){
          const response = await GetReportingMembersDetail(auth?.id)
          let arr = []
          response.data.datas[0]?.users.forEach((d)=>{
              arr.push({label:d?.name,value:d?._id})
          })
          setusers(arr)
        }
      }



  //Department
  async function getdepartments() {
    const response = await GetDepartmentService()

    let d = response?.data?.datas

    let arr = []
    let transaction = d?.find((f) => f?.department_name === "Transaction Team")
    let project = d?.find((f) => f?.department_name === "Project Team")
    let hr = d?.find((f) => f?.department_name === "HR Team")
    let fms = d?.find((f) => f?.department_name === "FMS Team")
    let fidelitus_gallery = d?.find((f) => f?.department_name === "Fidelitus Gallery")
    let shilpa_foundation = d?.find((f) => f?.department_name === "Shilpa Foundation")

    if (![null, undefined, '', 'null', 'undefined']?.includes(transaction)) {
      arr.push(transaction)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(project)) {
      arr.push(project)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(hr)) {
      arr.push(hr)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(fms)) {
      arr.push(fms)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(fidelitus_gallery)) {
      arr.push(fidelitus_gallery)
    }
    if (![null, undefined, '', 'null', 'undefined']?.includes(shilpa_foundation)) {
      arr.push(shilpa_foundation)
    }
    setdepartment1(arr)

    let arr1 = []
    response.data.datas.forEach((d) => {
      if (!['Finance Team', 'Software Team', 'Backend Team', 'Digital Media']?.includes(d?.department_name)) {
        if (auth.department_id[0].department_name !== d.department_name) {
          arr1.push({ value: d.id, label: d.department_name })
        }
      }
      setdepartment(arr1)
    })

  }

  const teams = [
    "Transaction Team",
    "Project Team",
    "HR Team",
    "FMS Team",
    "Fidelitus Gallery",
    "Shilpa Foundation",
  ];

  const seriesData = teams.map((team) => getLeadCount(team));

  const leadsChartOptions = {
    chart: { type: 'donut' },
    labels: teams,
    colors: ["#FF4560", "#008FFB", "#00E396", "#FEB019", "#775DD0", "#546E7A"],
    dataLabels: { enabled: false },
    legend: { show: false }
  };


  const teamNames = [
    "Transaction Team",
    "Project Team",
    "HR Team",
    "FMS Team",
    "Fidelitus Gallery",
    "Shilpa Foundation"
  ];

  function numDifferentiation(value) {
    const val = Math.abs(value)
    if (val >= 10000000) return `${(value / 10000000).toFixed(0)} Cr`
    if (val >= 100000) return `${(value / 100000).toFixed(0)} Lac`
    return value;
  }

  const estimationValuesforChart = teamNames.map((team) => getEstiamtedRevenue(team));
  const actualEstimationValuessforChart = teamNames.map((team) => getActualRevenue(team));

  const barChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: { show: false }
    },
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {

      bar: {
        horizontal: false,
        dataLabels: {
          enabled: false
        },
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },

      },
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      borderColor: '#fafafa',
    },

    stroke: {
      show: true,
      width: 1,
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Transaction', 'Project', 'HR Labs', 'FMS', 'Fidelitus Gallery', 'Shilpa Foundation'],
    },
    yaxis: {
      title: {
        text: 'Estimation / Actual Forecast'
      },
      labels: {
        formatter: function (val) {
          return numDifferentiation(val)
        }
      }
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return numDifferentiation(val)
        }
      }
    },
  };

  //barchart end 
  function getLeadCount(v) {
    if (data1?.length > 0) {
      let findData = data1?.find((f) => f?._id?.department_name == v)
      if (![null, undefined, 'null', 'undefined']?.includes(findData)) {
        return findData?.count
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  function getEstiamtedRevenue(v) {
    if (data1.length > 0) {
      let findData = data1?.find((f) => f?._id?.department_name == v)
      if (![null, undefined, 'null', 'undefined']?.includes(findData)) {
        return findData?.estimated_revenue
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  function getActualRevenue(v) {
    if (data1.length > 0) {
      let findData = data1?.find((f) => f?._id?.department_name == v)
      if (![null, undefined, 'null', 'undefined']?.includes(findData)) {
        return findData?.actual_revenue_achieved
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  function retutnTotalValue(type) {
    if (data1.length > 0) {
      let total = 0
      if (type == 'leads') {
        total += data1?.reduce((a, d) => a + d?.count, 0)
        return total
      }

      if (type == 'estimated_revenue') {
        total += data1?.reduce((a, d) => a + d?.estimated_revenue, 0)
        return total
      }

      if (type == 'actual_revenue_achieved') {
        total += data1?.reduce((a, d) => a + d?.actual_revenue_achieved, 0)
        return total
      }
    } else {
      return 0
    }
  }



  async function getdata() {
    const response = await GetFTSLeadsSharedToFTSBasedUserAndDepartmentService(page, search?.text, search?.from_date1, search?.to_date1, search?.department,search?.status,state?.user)
    setleads(response?.data)
  }

  async function getdata1() {
    const response = await GetVericalBasedAssignedRecievedBasedUserService(search?.from_date1, search?.to_date1, '', '', page)
    setdata1(response?.data?.data)
  }

  async function resetfunc() {
    setpage(1)
    setsearch({ text: '', from_date: '', from_date1: '', to_date: '', to_date1: '', department: '' })
    const response = await GetFTSLeadsSharedToFTSBasedUserAndDepartmentService(1, '', '', '', '','')
    setleads(response?.data)
    // const response1 = await GetVericalBasedAssignedRecievedBasedUserService('', '', '', '', 1)
    // setdata1(response1?.data?.data)
  }

  async function addData() {
    navigate('/fts_leads/list/create',)

  }

  async function editData(d) {
    navigate('/fts_leads_assign/list/edit',{state:d})
  }

  async function applyfilter() {
    setpage(1)
    const response = await GetFTSLeadsSharedToFTSBasedUserAndDepartmentService(1, search?.text, search?.from_date1, search?.to_date1, search?.department,search?.status,search?.user)
    setleads(response?.data)
    // const response1 = await GetVericalBasedAssignedRecievedBasedUserService(search?.from_date1, search?.to_date1, '', '', 1)
    // setdata1(response1?.data?.data)
  }

  async function navigateDetail() {

  }


  // apply filter pending 
  // reset filter pending 

  return (
    <div className='h-screen max-h-screen'>
      <div className='flex'>
        <div className={`min-w-44`}>
          <FTSLeadMenu />
        </div>
        <div className="w-[87%] px-4 pt-4">

          {/* <div className='flex justify-end border-b'>
            <div onClick={() => setstep(step == 1 ? 2 : 1)} className='flex cursor-pointer items-center'>
              <BiTable size={20} className={`${step == 1 ? 'bg-slate-700 text-white' : 'text-black bg-gray-100'} px-[3px] rounded mr-1 py-[2px]`} />
              <RiLayoutGridLine size={20} className={`${step == 2 ? 'bg-slate-700 text-white' : 'text-black bg-gray-100'} px-[3px] rounded mr-1 py-[2px]`} />
            </div>
          </div> */}

          <div className='flex flex-col border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>{step == 1 ? `Total Leads Shared From FTS to Other BU (${leads?.pagination?.total})` : `Leads Analytics`}</h6>
            <div className='flex  items-center'>
              <div className='flex mt-4 items-center text-[12px]'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? leads?.datas?.length > 0 ? 1 : 0 : (page - 1) * leads?.pagination?.limit} - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                  <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                  <IconButton onClick={() => { page < leads?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>

                </div>
                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, from_date: v, from_date1: v1 }) }} />
                <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, to_date: v, to_date1: v1 }) }} />
                <div>
                  <input id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e) => setsearch({ ...search, text: e.target.value })} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 mr-2 rounded-md border-slate-300' />
                </div>
                {step === 1 &&
                  <div className='mr-2'>
                    <Select
                      bordered={false}
                      placeholder={'Department'}
                      className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px] rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                      optionFilterProp="children"
                      onChange={(e) => setsearch({ ...search, department: e })}
                      value={search?.department === '' ? null : search?.department}
                      options={department}
                    />
                  </div>}

                    {((roles?.includes('hod') || roles?.includes('reporting_manager'))) && 
                        <div className='mr-1.5 h-[30px] rounded-md border border-slate-300 py-[2px]'>
                          <Select
                            placeholder='Select User'
                            bordered={false}
                            size='small'
                            defaultValue={search?.user}
                            value={search?.user == '' ? null : search?.user}
                            style={{ width: 110,marginTop:'0px' }}
                            onChange={(v) => handlechange(v, 'user')}
                            options={users}
                          />
                        </div>}

                  <div className='mr-2'>
                    <Select
                      bordered={false}
                      placeholder={'Status'}
                      className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px] rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none' 
                      optionFilterProp="children"
                      onChange={(e)=>setsearch({...search,status:e})}
                      value={search?.status === '' ? null : search?.status}
                      options={converted}
                  />
                  </div>
                <AiOutlineFilter onClick={applyfilter} size={24} className='cursor-pointer bg-slate-700 p-[5px] rounded text-white mr-2' />
                <ButtonFilledAutoWidth btnName="Add Data" onClick={() => addData()} />

                {/* {department_FCPL_FTS_BACKEND &&
                  <div onClick={() => setstep(step == 1 ? 2 : 1)} className='flex cursor-pointer items-center px-[7px]'>
                    <BiTable size={20} className={`${step == 1 ? 'bg-slate-700 text-white' : 'text-black bg-gray-100'} px-[3px] rounded mr-1 py-[2px]`} />
                    <PiChartBarBold size={20} className={`${step == 2 ? 'bg-slate-700 text-white' : 'text-black bg-gray-100'} px-[3px] rounded mr-1 py-[2px]`} />
                  </div>} */}

              </div>




            </div>
          </div>

          <div>
              <>
                <>
                  {leads?.datas?.length > 0 &&
                    <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                      <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                        <h6 className='top-0 bg-white z-50 text-[12px] w-[65px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[160px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Info</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Recieved Department</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[180px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[90px] px-2 py-1 font-[600] text-slate-600 border-r'>Converted</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[180px] px-2 py-1 font-[600] text-slate-600 border-r'>Summary</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r '>Created On</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 '>Actions</h6>

                      </div>

                      {leads?.datas?.map((d, i) => (
                        <div key={d?._id} className={`flex z-50 h-full border-b border-slate-200 z-0`}  >
                          <h6 className='sticky top-0 z-30 text-[12px] w-[63px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex justify-center'>{page === 1 ? i + 1 : (i + 1) + ((page - 1) * leads?.pagination?.limit)}</h6>
                          <h6 className='text-[12px] font-[500] w-[153.7px] px-2 py-1 truncate border-r  border-slate-200'>{d?.fts_lead?.company_name}</h6>
                          <h6 className='text-[12px] font-[500] w-[173px] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                            <div>
                              <h6>{d?.fts_lead?.contact_name}</h6>
                              <span>{d?.fts_lead?.phone} / {d?.fts_lead?.email}  </span>
                            </div>
                          </h6>
                          <h6 className='text-[12px] font-[500] w-[142px] px-2 py-1 break-all border-r border-slate-200'>
                            <span className='text-sky-500 text-[11px] font-[600]'>{d?.department_id?.department_name}</span>
                          </h6>
                          <h6 className={`text-[12px] font-[500] w-[172px] px-2 py-1  truncate  border-r  border-slate-200 ${d?.requirment ? 'block' : 'text-[#fff]'}`}>{d?.requirment ? d?.requirment : 'Not Added'}</h6>
                          <h6 className='text-[12px] text-center font-[500] w-[87px] px-2 py-1 truncate border-r  border-slate-200'>{d?.converted === '5' && <span className='bg-orange-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '5' && 'Lead Generated'}</span>} {d?.converted === '1' && <span className='bg-green-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '1' && 'Converted'}</span>} {d?.converted === '2' && <span className='bg-red-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '2' && 'Rejected'}</span>} {d?.converted === '3' && <span className='bg-yellow-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '3' && 'Pending'}</span>} {d?.converted === '4' && <span className='bg-blue-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '4' && 'Progress'}</span>} </h6>
                          <h6 className={`text-[12px] font-[500] w-[172px] px-2 py-1 border-r  border-slate-200 ${d?.remarks ? 'block' : 'text-[#fff]'}`}>{d?.remarks ? d?.remarks : 'Not Added'}</h6>

                          <h6 className='text-[12px] font-[500] w-[96px]  px-2 py-1 truncate  border-slate-200 sticky right-0 border-r'>{moment(d?.createdAt).format('ll')}</h6>
                          <h6 className='  text-[12px] w-[80px]  px-2 py-1 flex font-[600] text-slate-600 sticky right-0'>

                            <AiOutlineEdit size={14} className='mr-2' onClick={() => editData(d)} />

                          </h6>

                        </div>))}
                    </div>}
                </>
              </>
           

            {leads?.datas?.length === 0 &&
              <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' />
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
              </div>
            }
          </div>
        </div>
      </div>

    </div>
  )
}

export default FTSLeadSharedToFTSUserDetail
