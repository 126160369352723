import React,{useState,useEffect} from 'react'
import { Select,DatePicker } from 'antd';
import { useLocation } from 'react-router-dom';
import { GetFTSVerticalReportGraphDataService } from '../../services/ftsVerticalReportServices/FTSVerticalReportServices';
import FTSLeadMenu from '../fts_lead/FTSLeadMenu';
import { GetDepartmentService } from '../../services/DepartmentServices';
import { BiFilterAlt } from "react-icons/bi";
import { RxReset } from "react-icons/rx";
import { GetFTSVerticalMonthlyMeetingReviewGraphDataService } from '../../services/ftsVerticalReportServices/FTSVerticalMonthlyMeetingReviewServices';
import ReactApexChart from 'react-apexcharts';


function FTSVerticalReportDashboard() {


  const {pathname} = useLocation()

  const path = pathname?.split('/')[1]

  const [departments,setdepartments] = useState([])

  const [data,setdata] = useState({})
  const [data1,setdata1] = useState({})


  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',department:'',status:'',filterApplied:false})
  
  const [chartOptions1,setchartOptions1] = useState({
    series:[],
    chart: {
      toolbar: {
        show: false
      }
    },
      plotOptions: {
        
        bar: {
          horizontal: false,
          distributed: true,
          columnWidth: '55%',
          endingShape: 'rounded',
          dataLabels: {
            position: 'top', // top, center, bottom
          },
          
        },
      },
      colors: ["#3a7aba", "#40ad75", "#d48d37", "#c42b8c", "#a61b2d"],
      dataLabels: {
        enabled: false
      },
      grid:{
        show: true,
        borderColor: '#fafafa',
      },
    
      stroke: {
        width: 1,
        
        curve: 'smooth',
        colors: ['transparent']
      },
      xaxis: {
        // categories: ['New Leads Generated Internally', 'No of Leads receieved by CRM/Corp/Social Media', 'No of leads shared by business units to other business units', 'No of new leads converted', 'No of New Leads Rejected'],
        categories: ['New Leads Generated', 'Leads Recieved (from CRM/FTS)', 'Leads Shared (other BU)', 'New Leads converted', 'Leads Rejected'],
      },
      yaxis: {
        title: {
          text: 'Count'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            console.log("val",val)
            return  val
          }
        }
      },
      fill:['#F44336', '#E91E63', '#9C27B0','#E91E63', '#9C27B0']
  })

  useEffect(()=>{
    getdepartment()
    getdata1()
  },[])


  async function getdata1() {
   const response =  await GetFTSVerticalMonthlyMeetingReviewGraphDataService(search?.department?.value,search?.from_date1,search?.to_date1)
   let d = response?.data?.data[0]
   let form = [
    {
      name:'count',
      // data:[d?.new_clients_approched,d?.no_of_proposal_terms_sents,d?.no_of_agreement_signed,d?.no_of_active_req_shared_by_clients,d?.references_provided_to_other_business_verticals]
      data:[d?.new_leads_generated,d?.leads_recieved,d?.leads_share,d?.leads_converted,d?.leads_rejected]
    }]  
   setchartOptions1({...chartOptions1,series:[...form]})
   setdata1(response?.data?.data[0])
  }
 
  async function getdata(department){
   const response =  await GetFTSVerticalReportGraphDataService(department)
   setdata(response?.data?.data)
  }

  async function getdepartment() {
      const response = await GetDepartmentService();
      let arr = []
      response.data.datas.forEach((d)=>{
        if(d?.department_name !== 'Finance Team'  && d?.department_name !== 'Software Team' && d?.department_name !== 'Digital Media' && d?.department_name !== 'Backend Team'){
          arr.push({label:d?.department_name,value:d?.id})
        }  
      })
      setdepartments(arr)
  }


  async function applyFilter() {
    setsearch({...search,filterApplied:true})
    getdata1()
    // setsearch({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',department:'',status:''})
  }


  async function resetfilter() {
    setsearch({...search,filterApplied:false})
    setsearch({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',department:'',status:''})
    const response =  await GetFTSVerticalMonthlyMeetingReviewGraphDataService('','','')
   let d = response?.data?.data[0]
   let form = [
    {
      name:'count',
      data:[d?.new_leads_generated,d?.leads_recieved,d?.leads_share,d?.leads_converted,d?.leads_rejected]
      // data:[d?.new_clients_approched,d?.no_of_proposal_terms_sents,d?.no_of_agreement_signed,d?.no_of_active_req_shared_by_clients,d?.references_provided_to_other_business_verticals]
    }]  
   setchartOptions1({...chartOptions1,series:[...form]})
   setdata1(response?.data?.data[0])
  }


  function openFile(v){
    let url = `${process.env.REACT_APP_BACKEND_IMAGE_URL}${v}`
    let url2 = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}`;
  //  let url1 = `https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`;
    window.open(url2,'_blank')
   }


  
  return (
    <div className='w-[98%] h-screen overflow-hidden'>


        <div className='flex'>
            <div className={`min-w-44 ${path == 'profile' && 'min-w-[180px] pl-2'}`}>
               <FTSLeadMenu />
            </div>
            <div className='w-[100%] pl-5 pt-4'>

            <div className='md:w-[100%] flex flex-wrap md:flex-nowrap items-center -mt-1 border-b justify-between pb-1'>
                <h6 className='font-[800] text-[13px]'>FTS Vertical Dashboard Report  </h6>

                <div className='flex  items-center text-[12px]'>

                    <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 



                    <div className='mr-2'>
                      <Select
                        bordered={false}
                        placeholder={'Department'}
                        className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px] rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none' 
                        optionFilterProp="children"
                        onChange={(e)=>setsearch({...search,department:departments?.find((f)=>f?.value === e)})}
                        value={search?.department === '' ? null : search?.department}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={departments}
                    />
                    </div>

                     <div className='ml-0 mr-1'>
                      <BiFilterAlt onClick={()=>applyFilter(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
                      </div> 
                      <div className='ml-1 mr-2'>
                      <RxReset  onClick={resetfilter} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />
                      </div>


                </div>
            </div>

          

            <div className='overflow-x-hidden h-[90vh] mb-[50px] overflow-y-scroll'>
              <div className='border  relative w-[100%] flex-1  mt-2 border-slate-100 px-3 py-2 relative'>
                {(![undefined,null,'null','undefined','']?.includes(data1?.file) && search?.filterApplied && ![undefined,null,'null','undefined','']?.includes(search?.department)) && <h6 onClick={()=>openFile(data1?.file)} className='bg-green-200 text-[11px] border-green-600 border-2 rounded px-2 py-1 font-[700] w-max  absolute cursor-pointer right-4'>View Excel</h6>}
                <h1 className='text-[13px] font-[700]'>Total Report</h1>
                <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1?.series} type="bar" width={'100%'}  height={240} />
              </div>
            </div> 
                
            </div>
        </div>   
    </div>
  )
}

export default FTSVerticalReportDashboard