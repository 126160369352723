import { deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const CreateCallService = async (data) => {
    try {
        const result = await post(`api/calls_lead/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterCallService = async (page, from_date, to_date, search_text, step, type) => {
    try {
        const result = await get(`api/calls_lead/filter?page=${page}&type=${type}&from_date=${from_date}&to_date=${to_date}&search_text=${search_text}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteManyCallService = async (step) => {
    try {
        const result = await deleteRequest(`api/calls_lead/delete_many?step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const RemoveDuplicateCallService = async () => {
    try {
        const result = await get(`api/calls_lead/remove_duplicates`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadExcelCallService = async (file) => {
    try {
        const result = await postfd(`api/calls_lead/upload_excel`, { file: file });
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadExcelCallService = async (step) => {
    try {
        const result = await get(`api/calls_lead/download_excel?step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetCallService = async (page, step) => {
    try {
        const result = await get(`api/calls_lead/get?page=${page}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetCallBasedUserDepartmentService = async (page, step, from_date, to_date, text, type, user) => {
    try {
        const result = await get(`api/calls_lead/get_data_based_vertical_user?page=${page}&step=${step}&from_date=${from_date}&to_date=${to_date}&text=${text}&type=${type}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetCallDetailService = async (id) => {
    try {
        const result = await get(`api/calls_lead/get?id=${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateCallService = async (id, data) => {
    try {
        const result = await put(`api/calls_lead/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteCallService = async (id) => {
    try {
        const result = await deleteRequest(`api/calls_lead/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAdminCallService = async (page, stage, type, from_date, to_date, search, user) => {
    try {
        const result = await get(`api/calls_lead/get_team_calls?page=${page}&type=${type}&from_date=${from_date}&to_date=${to_date}&stage=${stage}&search_text=${search}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}