import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineSpaceDashboard, MdOutlineLeaderboard, MdPayment } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { TbMoneybag, TbFileReport } from 'react-icons/tb';
import { TfiDashboard } from 'react-icons/tfi';
import { VscGraphLine } from 'react-icons/vsc';
import { FaRegCalendarAlt } from "react-icons/fa";
import { GoTasklist } from "react-icons/go";
import { FcInspection } from "react-icons/fc";
import { VscGitPullRequestGoToChanges } from "react-icons/vsc";
import { RiTaskLine } from "react-icons/ri";
import { LuBarChartBig } from "react-icons/lu";
import { PiHandbagSimpleBold } from "react-icons/pi";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { TbScreenShare } from 'react-icons/tb';
import { TbPigMoney, TbReportAnalytics } from "react-icons/tb";
import { PiPlaceholder } from "react-icons/pi";
import { LuAlignStartVertical } from "react-icons/lu";
import { VscFileSymlinkDirectory } from "react-icons/vsc";
import { TiBook } from "react-icons/ti";
import { TbLayoutList } from "react-icons/tb";
import { GrWorkshop } from "react-icons/gr";
import { HiOutlineDocumentReport } from "react-icons/hi";

function DashboardMenu() {

  const { pathname } = useLocation();
  const path = pathname.split('/')[2]
  const user = useSelector(state => state.Auth)
  

  const navigate = useNavigate();

  useEffect(() => {
    if (path === undefined) {
      navigate('/dashboard/my_dashboard')
    }
  }, [])

  // HiOutlineDocumentReport

  const menu = [
    { name: 'My Dashboard', icon: MdOutlineSpaceDashboard, path: '/dashboard/my_dashboard', id: 1, color: '', roles: ['bd_user', 'manager', 'hod', 'admin'] },
    { name: 'My Revenue', icon: MdPayment, path: '/dashboard/my_revenue', id: 1, color: '', roles: ['bd_user', 'manager', 'hod', 'admin'] },
    { name: 'Individual Report', icon: FaRegCalendarAlt, path: `/dashboard/weekly_report1`, id: 1, color: '', roles: ['bd_user', 'manager', 'hod', 'admin'],department:['Transaction Team'] },
    { name: 'Team Leads', icon: MdOutlineLeaderboard, path: '/dashboard/team_leads', id: 2, color: '', roles: ['manager', 'hod', 'admin'] },
    { name: 'My Analytics', icon: HiOutlineDocumentReport, path: '/dashboard/dashboard_report', id: 2, color: '', roles: ['manager', 'hod', 'admin'],department:['Transaction Team'] },
    { name: 'Team Analytics', icon: HiOutlineDocumentReport, path: '/dashboard/calendar_report', id: 2, color: '', roles: ['admin'],department:['Transaction Team'] },
  ]

  const menu1 = [
    { name: 'Invoice Payment', icon: MdPayment, path: `/dashboard/payment`, id: 1, color: '', roles: ['manager', 'hod', 'admin'],department:['Transaction Team'] },
    { name: 'Team Revenue', icon: TbMoneybag, path: `/dashboard/team_target_achieved`, id: 1, color: '', roles: ['manager', 'hod', 'admin'],department:['Transaction Team'] },
    { name: 'Weekly Report', icon: FaRegCalendarAlt, path: `/dashboard/weekly_report`, id: 1, color: '', roles: ['manager', 'hod', 'admin'],department:['Transaction Team'] },
    { name: 'Team Calls', icon: HiDevicePhoneMobile, path: '/dashboard/team_calls', id: 2, color: '', roles: ['manager', 'hod', 'admin'],department:['Transaction Team'] },
    { name: 'Daily Tasks', icon: GoTasklist, path: '/dashboard/daily_tasks', id: 2, color: '', roles: ['manager', 'hod', 'admin'],department:['Transaction Team'] },
    { name: 'Team Collection', icon: PiHandbagSimpleBold, path: '/dashboard/team_collection', id: 2, color: '', roles: ['manager', 'hod', 'admin'],department:['Transaction Team'] },
    { name: 'Outstanding Report', icon: TbReportAnalytics, path: '/dashboard/out_standing', id: 2, color: '', roles: ['manager', 'hod', 'admin'],department:['Transaction Team'] },
    // { name: 'Corporate Leads', icon: VscFileSymlinkDirectory, path: '/dashboard/md_leads_data', id: 2, color: '', roles: ['hod', 'admin'],department:['Transaction Team'] },
  ]

  const menu5 = [
    { name: 'Team Leads', icon: MdOutlineLeaderboard, path: '/dashboard/team_leads', id: 2, color: '', roles: ['manager', 'hod', 'admin', 'reporting_manager'] },
    { name: 'Weekly Report', icon: FaRegCalendarAlt, path: `/dashboard/reporting_manager_weekly_report`, id: 1, color: '', roles: ['manager', 'hod', 'reporting_manager', 'admin'],department:['Transaction Team'] },
    { name: 'Daily Tasks', icon: GoTasklist, path: '/dashboard/reporting_manager_daily_tasks', id: 2, color: '', roles: ['manager', 'hod', 'reporting_manager', 'admin'],department:['Transaction Team'] },
  ]

  const menu2 = [
    { name: 'Admin Dashboard', icon: TfiDashboard, path: `/dashboard/admin_dashboard`, id: 1, color: '', roles: ['manager', 'hod', 'admin'] },
    { name: 'Employee Revenue', icon: VscGraphLine, path: `/dashboard/employee_target`, id: 1, color: '', roles: ['manager', 'hod', 'admin'] },
    // { name: 'Fidelitus Leads', icon: TbMoneybag, path: `/dashboard/fidelitus_leads`, id: 1, color: '', roles: ['admin'] },
    { name: 'Profit / Loss', icon: LuBarChartBig, path: `/dashboard/profit_loss_admin`, id: 1, color: '', roles: ['admin'] },
    { name: 'Team Inspection', icon: FcInspection, path: `/dashboard/admin_inspection_review`, id: 1, color: '', roles: ['admin'] },
  ]

  const menu3 = [
    { name: 'ILS Requirment', icon: VscGitPullRequestGoToChanges, path: `/dashboard/land_requirment`, id: 1, color: '', roles: ['admin', 'ils_manager', 'ils_hod'] },
    { name: 'Land Data', icon: TbScreenShare, path: `/dashboard/land_data_admin`, id: 1, color: '', roles: ['admin', 'ils_manager', 'ils_hod'] },
  ]

  const menu4 = [
    { name: 'Backend Report', icon: TbFileReport, path: `/dashboard/backend_team_work`, id: 1, color: '', roles: ['admin'] },
    { name: 'Daily Task', icon: RiTaskLine, path: `/dashboard/backend_daily_tasks`, id: 1, color: '', roles: ['admin'] },
    { name: 'MOM Task', icon: RiTaskLine, path: `/dashboard/mom_tasks`, id: 2, color: '', roles: ['admin'] },
  ]

  const menu6 = [
    { name: 'Reimbursement List', icon: TbPigMoney, path: '/dashboard/reimbusment_list', id: 2, color: '', roles: ['manager', 'hod', 'admin'] },

  ]

  const menu7 = [
    { name: 'NMR List', icon: PiPlaceholder, path: '/dashboard/admin_nmr_list', id: 2, color: '', roles: ['manager', 'hod', 'admin'] },

  ]

  const menu8 = [
    { name: 'Induction List', icon: LuAlignStartVertical, path: '/dashboard/induction_list', id: 1, color: '', roles: ['manager', 'hod', 'admin'] },
    { name: 'Course List', icon: TiBook, path: '/dashboard/course_list', id: 1, color: '', roles: ['manager', 'hod', 'admin'] },
    { name: 'SuperVisor List', icon: TbLayoutList, path: '/dashboard/supervisor_list', id: 1, color: '', roles: ['manager', 'hod', 'admin'] },

  ]



  return (
    <div className='mr-0 min-h-screen overflow-y-scroll no-scrollbar max-h-screen border-r min-w-44  max-w-44 w-44 px-2' >

      <div className='mb-4 mt-5'>
        <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Our Dashboard</h6>

        {menu.map((m) => (
          <>
          {(m?.department === undefined || user?.department_id?.filter((f) => m?.department?.includes(f?.department_name))?.length > 0) &&
            <>
            <div key={m?.path}>
              {(m?.path === '/dashboard/my_revenue' || m?.path === '/dashboard/weekly_report1') && (user?.roles?.includes('admin') || user?.department_id[0]?.department_name === 'Transaction Team') ?
                <React.Fragment key={m?.path}>
                  {m?.roles?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
                    <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                      {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                      <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                      <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                    </div>}
                </React.Fragment>
                :
                <div key={m?.path}>
                  {m?.path !== '/dashboard/my_revenue' &&
                    <React.Fragment key={m?.path}>
                      {m?.roles?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
                        <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                          {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                          <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                          <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                        </div>}
                    </React.Fragment>}
                </div>
              }


            </div>
            </>}
          </>
        ))}
      </div>

      {(user?.roles?.includes('admin') || user?.department_id[0]?.department_name === 'Transaction Team') &&
        <div >
          
          {['admin', 'hod', 'manager']?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
            <div className={` ${path === 'daily_tasks' ? 'mt-4 pt-4 border-t border-b' : 'border-t border-b pt-4 '}`}>
              <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>{(user?.roles?.includes('admin') || user?.roles?.includes('hod')) ? 'HOD' : 'Manager'} Option</h6>
              {menu1.map((m) => (
                <React.Fragment key={m?.path}>
                  {m?.roles?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
                    <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                      {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                      <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                      <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                    </div>}
                </React.Fragment>
              ))}
            </div>
          }
        </div>}

      {(user?.roles?.includes('admin') || (user?.roles?.includes('reporting_manager') && (user?.department_id[0]?.department_name === 'Transaction Team' || user?.department_id[0]?.department_name === 'Corp Team') )) &&
        <div >
          
          {['admin', 'hod', 'manager', 'reporting_manager']?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
            <div className={` ${path === 'daily_tasks' ? 'mt-4   border-b' : 'pt-4 '}`}>
              <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>{'Reporting Manger'} Option</h6>
              {menu5.map((m) => (
                <>
                {(m?.department === undefined || user?.department_id?.filter((f) => m?.department?.includes(f?.department_name))?.length > 0) &&
                <>
                <React.Fragment key={m?.path}>
                  {m?.roles?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
                    <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                      {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                      <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                      <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                    </div>}
                </React.Fragment>
                </>}
                </>
              ))}
            </div>
          }
        </div>}

      {user?.roles?.includes('admin') &&
        <div className={` ${path === 'daily_tasks' ? 'mt-4' : 'border-t pt-4 '}`}>


          <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Admin Option</h6>
          {menu2.map((m) => (
            <React.Fragment key={m?.path}>
              {m?.roles?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                  {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                  <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>}
            </React.Fragment>
          ))}
        </div>}


      {(user?.roles?.includes('admin') || user?.roles?.includes('ils_hod') || user?.roles?.includes('ils_manager')) &&
        <div className='border-t pt-4 '>
          <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>ILS Option</h6>
          {menu3.map((m) => (
            <React.Fragment key={m?.path}>
              {m?.roles?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                  {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                  <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>}
            </React.Fragment>
          ))}
        </div>}

      {user?.roles?.includes('admin') &&
        <>
          <div className='border-t pt-4 '>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Backend Option</h6>
            {menu4.map((m) => (
              <React.Fragment key={m?.path}>
                {m?.roles?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
                  <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                    {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                    <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                    <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                  </div>}
              </React.Fragment>
            ))}
          </div>

          <div className='border-t pt-4 '>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Property Visit Option</h6>
            {menu7.map((m) => (
              <React.Fragment key={m?.path}>
                {m?.roles?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
                  <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                    {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                    <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                    <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                  </div>}
              </React.Fragment>
            ))}
          </div>

        </>}

      {['manager', 'hod', 'admin']?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
        <div className='border-t pt-4 '>
          <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Reimbusment Option</h6>
          {menu6.map((m) => (
            <React.Fragment key={m?.path}>
              {m?.roles?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                  {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                  <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>}
            </React.Fragment>
          ))}
        </div>}

      {['admin', 'controller']?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
        <div className='border-t pt-4'>
          <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Induction Option</h6>
          {menu8.map((m) => (
            <React.Fragment key={m?.path}>
              {m?.roles?.filter((f) => user?.roles?.includes(f))?.length > 0 &&
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname === m.path && 'bg-[#f4f5f7]'}`} onClick={() => navigate(m?.path)}>
                  {pathname === m.path && <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{ backgroundColor: m?.color, padding: '2px' }} size={16} />
                  <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>}
            </React.Fragment>
          ))}
        </div>}

    </div>
  )
}

export default DashboardMenu