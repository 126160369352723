import React, { useState, useEffect } from 'react'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { DatePicker, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { IconButton } from '@material-ui/core';
import { AiOutlineEdit, AiOutlineDelete, AiOutlineFileExcel } from 'react-icons/ai';
import toast from 'react-hot-toast';
import axios from 'axios';
import fileDownload from "js-file-download";
import SettingsMenu from '../staticscreens/SettingsMenu';
import { DeleteFidelitusCorpUserService, DownloadFidelitusCorpUserService, GetFidelitusCorpUserService } from '../../services/fidelituscorpservices/FidelitusCorpUserListServices';
import { DeleteFidelitusCorpVisitorService, DownloadFidelitusCorpVisitorService, GetFidelitusCorpVisitorService } from '../../services/fidelituscorpservices/FidelitusCorpVIsitorListServices';

function FidelitusCorpVisitorList() {

  const navigator = useNavigate()

  const [modal, setmodal] = useState(false)
  const [step, setstep] = useState(1)
  const [page, setpage] = useState(1)
  const [data, setdata] = useState({})
  const [selecteddata, setselecteddata] = useState({})

  const [search, setsearch] = useState({ text: '', from_date: '', to_date: '', from_date1: '', to_date1: '', activate: false })

  useEffect(() => {
    getdata()
  }, [step, page])

  async function getdata() {
    const response = await GetFidelitusCorpVisitorService(page, search?.text, search?.from_date1, search?.to_date1)
    setdata(response?.data)
  }


  async function applyFilter() {
    setsearch({ ...search, activate: true })
    setpage(1)
    const response = await GetFidelitusCorpVisitorService(1, search?.text, search?.from_date1, search?.to_date1)
    setdata(response?.data)
  }

  async function resetfilter() {
    setsearch({ text: '', from_date: '', to_date: '', from_date1: '', to_date1: '', activate: false })
    setpage(1)
    const response = await GetFidelitusCorpVisitorService(1, '', '', '')
    setdata(response?.data)
  }

  async function deleteData() {
    const response = await DeleteFidelitusCorpVisitorService(selecteddata?._id)
    if (response?.status === 200) {
      toast.success("Deleted Successfully")
    } else {
      toast.success("Deleted Successfully")
    }
    setmodal(false)
    getdata()
  }

  async function downloaddata() {
    const response = await DownloadFidelitusCorpVisitorService(1, search?.text, search?.from_date1, search?.to_date1, '')
    let path = response.data.path.replace('public/', '')
    handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${path}`, path.split('/')[path.split('/')?.length - 1])

  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }


  return (
    <div className='w-[98%] h-screen overflow-hidden'>

      <Modal
        keepMounted
        open={modal}
        onClose={() => setmodal(false)}
        width={300}
        footer={false}
        closable={false}

      >
        <div >
          <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
          <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
          <div className='flex justify-end mt-3 '>
            <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={() => setmodal(false)} />
            <div className='ml-2'>
              <ButtonFilledAutoWidth btnName={'Confirm'} onClick={() => deleteData()} />
            </div>
          </div>
        </div>
      </Modal>

      <div className='flex'>
        <div className='min-w-44'>
          <SettingsMenu />
        </div>
        <div className='w-[85%] pl-5 mt-4'>

          <div className='flex items-center -mt-1 border-b justify-between pb-1'>
            <h6 className='font-[800] text-[13px]'>Total Visitor  ({data?.pagination?.total})</h6>

            <div className='flex items-center text-[12px]'>

              <AiOutlineFileExcel onClick={() => downloaddata()} size={24} className='mx-2 bg-gray-200 p-1.5' />

              <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit} - {data?.pagination?.limit} of {data?.pagination?.total}  </h6>

              <div>
                <IconButton onClick={() => { page > 1 ? setpage(page - 1) : console.log('') }}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                <IconButton onClick={() => { page < data?.pagination?.totalPages ? setpage(page + 1) : console.log('') }}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`} size={16} /></IconButton>

              </div>
              <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.from_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, from_date: v, from_date1: v1 }) }} />

              <DatePicker size='small' style={{ fontSize: '11px' }} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2' value={search?.to_date} onChange={(v, v1) => { console.log('v na', new Date(v).toLocaleDateString()); setsearch({ ...search, to_date: v, to_date1: v1 }) }} />


              <div className='mr-2'>


                <input id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e) => setsearch({ ...search, text: e.target.value })} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />


              </div>
              <div className='mr-2'>
                <ButtonOutlinedAutoWidth btnName="Apply Filter" onClick={() => applyFilter()} />
              </div>
              <div className='mr-2'>
                <ButtonOutlinedAutoWidth btnName="Reset Filter" onClick={() => resetfilter()} />
              </div>

              <ButtonFilledAutoWidth btnName="Add New" onClick={() => navigator('create')} />

            </div>
          </div>



          <div className=' h-[90vh] mb-[50px] overflow-y-scroll'>

            {data?.datas?.length === 0 &&
              <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' />
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
              </div>}

            <div className='h-[88vh] w-[100%] overflow-x-hidden overflow-y-scroll'>
              {data?.datas?.length > 0 &&
                <div className='max-h-[85vh] w-[100%] mt-4 border-t  overflow-x-hidden  border-l border-r overflow-y-scroll'>
                  <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6 className='top-0 bg-white z-50 text-[12px] min-w-[5%]  max-w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>Sl No</h6>
                    <h6 className='sticky top-0 z-50  text-[12px]  min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r'>Name</h6>
                    <h6 className='sticky top-0 z-50  text-[12px]  min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] min-w-[12%] max-w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r'>Designation</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r'>Company</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] min-w-[12%] max-w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r'>Purpose</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] min-w-[12%] max-w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r'>Department</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] min-w-[12%] max-w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r'>User</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 '>Actions</h6>
                  </div>
                  {data?.datas?.map((d, i) => (
                    <div className='flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                      <h6 className='bg-white text-[12px] min-w-[5%]  max-w-[5%]   px-2 py-1 font-[500] border-r flex items-center justify-center'>{(page > 1 ? i + 1 + (data?.pagination?.limit * (page - 1)) : i + 1)}</h6>
                      <h6 className=' text-[12px] truncate min-w-[10%] max-w-[10%] px-2 py-1 font-[500] border-r'>{d?.name}</h6>
                      <h6 className=' text-[12px] truncate min-w-[10%] max-w-[10%] px-2 py-1 font-[500] border-r'>{d?.mobile}</h6>
                      <h6 className=' text-[12px] truncate min-w-[12%] max-w-[12%]  px-2 py-1 font-[500] border-r'>{d?.email}</h6>
                      <h6 className=' text-[12px] truncate  min-w-[10%] max-w-[10%]  px-2 py-1 font-[500] border-r'>{d?.designation}</h6>
                      <h6 className=' text-[12px] truncate  min-w-[10%] max-w-[10%] px-2 py-1 font-[500] border-r'>{d?.company}</h6>
                      <h6 className=' text-[12px] truncate min-w-[12%] max-w-[12%]  px-2 py-1 font-[500] border-r'>{d?.purpose}</h6>
                      <h6 className=' text-[12px] truncate  min-w-[12%] max-w-[12%]  px-2 py-1 font-[500] border-r'>{d?.department?.department_name}</h6>
                      <h6 className=' text-[12px] truncate  min-w-[12%] max-w-[12%]  px-2 py-1 font-[500] border-r'>{d?.meet_user?.name}</h6>
                      <h6 className='flex items-center text-[12px]  min-w-[10%] max-w-[10%]  px-2 py-1 font-[500] '>
                        <AiOutlineEdit onClick={() => navigator('edit', { state: d })} className='mr-2' />
                        <AiOutlineDelete onClick={() => { setselecteddata(d); setmodal(true) }} />
                      </h6>
                    </div>))}
                </div>}
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default FidelitusCorpVisitorList