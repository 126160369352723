import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { TextAreaInput1, TextInput } from '../../components/input';
import { BiErrorCircle, BiCheckbox, BiCheckboxSquare } from 'react-icons/bi'
import { GetStageService } from '../../services/StageServices';
import { ButtonFilled, ButtonOutlined } from '../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateLeadsService, GetLeadsDetailService, GetOperatorsService, UpdateLeadsService, CreateLeadAttachmentFollowUpService, CreateInvoiceRequest, UploadVisitingCardFile, VerifyLeadsExistService } from '../../services/LeadServices';
import { toast } from 'react-hot-toast';
import moment from 'moment'
import Uploader from '../../components/Uploader';
import { IoIosClose } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { DatePicker, TimePicker, Modal, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import GoBack from '../../components/back/GoBack';
import DailyTaskCE from './dailyTask/DailyTaskCE';
import Uploader1 from '../../components/Uploader1';
import Uploader2 from '../../components/Uploader2';


function LeadCE() {

  const list = ['contacts', 'options_shared', 'inspections', 'loi_signing', 'loi_agreement', 'invoice_raised', 'non_payable_closure', 'hold', 'design_shared', 'qs_stage', 'mep_stage', 'boq_stage', 'contact_in_future', 'lost_client', 'hold_client', 'execution_stage', 'closed_stage', 'follow_up', 'waiting_requirment', 'closed', 'lost', 'hold', 'in_progress', 'agreement', 'final_closure', 'negotiation', 'proposal_sent', 'assesement', 'meeting_discussion', 'contact', 'followup', 'converted', 'purchase_order']
  const { pathname } = useLocation();

  const path = pathname.split('/')[pathname?.split('/')?.length - 2]

  const [old_Data, setold_Data] = useState({})
  const [data, setdata] = useState({
    department_id: '',
    contact_name: '',
    phone: '',
    email: '',
    designation: '',
    company_name: '',
    secondary_contact_person: '',
    secondary_contact_phone: '',
    secondary_contact_email: '',
    lead_source: '',
    industry: '',
    stage: '',
    contact_stage: '',
    zone: '',
    sqft: false,
    seater: false,
    rent: false,
    sale: false,
    website: '',
    requirment: '',
    location: '',
    sqft_info: '',
    address: '',
    operators: [],
    transferred: '',
    important: '',
    color: '',
    land_lord: false,
    follow_up_remark: '',
    called_status: false,
    follow_up: false,
    follow_up_date: '',
    visiting_card: '',
    revenue_estiamted:0

  })
  const [invoiceData, setinvoiceData] = useState({ amount: '', other_details: '', invoice_raising_date: '', invoice_attachment_id: '', payment: '0', gst_no: '', pan_no: '', office_space_closed: '' })
  const [invoiceDataErr, setinvoiceDataErr] = useState({ amount: '', other_details: '', invoice_raising_date: '', invoice_attachment_id: '', payment: '0', gst_no: '', pan_no: '', office_space_closed: '' })
  const [contactSourceArr, setcontactSourceArr] = useState([])
  const [stage, setstage] = useState({ selected_stage: '', date: '', time: '', file: '' })
  const [stageerr, setstageerr] = useState({ selected_stage: '', date: '', time: '', file: '' })
  const [IndustyArr, setIndustyArr] = useState([])
  const [leadStatusArr, setleadStatusArr] = useState([])
  const [zoneArr, setzoneArr] = useState([])
  const [OperatorsArr, setOperatorsArr] = useState([])
  const [modal, setModal] = useState(false);
  const [loader, setloader] = useState(false);


  const [aggrementData, setagreementData] = useState({ reminder_active: false, agreement_expire: '', lock_in_period_end_date: '', lock_in_period_from_date: '', lease_years: '', lease_end_date: '' })
  const [aggrementDataErr, setagreementDataErr] = useState({ reminder_active: '', agreement_expire: '', lock_in_period_end_date: '', lock_in_period_from_date: '', lease_years: '', lease_end_date: '' })

  const [lead_exist_data, setlead_exist_data] = useState({})

  const colors = []


  const [error, seterror] = useState({
    department_id: '',
    contact_name: '',
    phone: '',
    email: '',
    designation: '',
    company_name: '',
    secondary_contact_person: '',
    secondary_contact_phone: '',
    secondary_contact_email: '',
    lead_source: '',
    industry: '',
    stage: '',
    contact_stage: '',
    zone: '',
    sqft: '',
    seater: '',
    rent: '',
    sale: '',
    website: '',
    requirment: '',
    address: '',
    operators: '',
    transferred: '',
    important: '',
    color: '',
    follow_up_remark: '',
    called_status: '',
    follow_up: '',
    follow_up_date: '',
    visiting_card: '',
    revenue_estiamted:''
  })

  const navigate = useNavigate()
  const location = useLocation()

  const user = useSelector(state => state.Auth)

  const user_department = user?.department_id[0]?.id
  const user_department_name = user?.department_id[0]?.department_name


  async function handleSelect(e, e1) {
    if (e1 === 'Country') {
      setdata({ ...data, lead_source: contactSourceArr?.find((i) => i.value === e) })
      seterror({ ...error, lead_source: '' })
    } else if (e1 === 'Industry') {
      setdata({ ...data, industry: IndustyArr?.find((i) => i.value === e) })
      seterror({ ...error, industry: '' })
    } else if (e1 === 'LeadStage') {
      setstage({ ...stage, selected_stage: leadStatusArr?.find((i) => i.value === e) })
      checkdocsrequired(leadStatusArr?.find((i) => i.value === e))
    } else if (e1 === 'contact_stage') {
      setdata({ ...data, contact_stage: { label: e, value: e } })
      checkdocsrequired(leadStatusArr?.find((i) => i.value === e))
    } else if (e1 === 'Zone') {
      setdata({ ...data, zone: zoneArr?.find((i) => i.value === e) })
      seterror({ ...error, zone: '' })
    } else if (e1 === 'Operator') {
      let arr = []
      e?.forEach((ele) => {
        arr?.push(OperatorsArr?.find((i) => i.value === ele))
      })
      setdata({ ...data, operators: arr })
      seterror({ ...error, operators: '' })
    }
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '1px solid rgb(227 231 234)',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0px 6px',
      fontSize: '13px',
      fontWeight: 600,
      marginBottom: '0px',
      paddingBottom: '0px'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
  };

  const contact_stages = [
    { label: 'Not Recieved', value: 'Not Recieved' },
    { label: 'Invalid No', value: 'Invalid No' },
    { label: 'Not Interested', value: 'Not Interested' },
    { label: 'Call Recieved', value: 'Call Recieved' },
    { label: 'Lead Generated', value: 'Lead Generated' },
    { label: 'Not a Concern Person', value: 'Not a Concern Person' }
  ]

  async function getbasicdatareq() {
    const response = await GetStageService('Lead_Stage', user?.department_id[0]?.id)
    const response1 = await GetStageService('Contact_Source')
    const response2 = await GetStageService('Industry')
    const response3 = await GetStageService('Zones')

    let arr = []
    let arr1 = []
    let arr2 = []
    let arr3 = []

    response.data.data.datas.forEach((d) => {
      if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'create') {
        if (d?.name !== 'Calls' && d?.name !== 'Walk-Ins' && d?.name !== 'Meetings' && d?.name !== 'Landlord Meeting' && d?.name !== 'Invoice Raised' && d?.name !== 'Property-Visit' && d?.name !== 'Payment Collection') {
          arr.push({ label: d?.name, value: d?._id })
        }
      } else {
        if (['loi_agreement', 'loi_signing', 'purchase_order', 'proposal_sent']?.includes(location?.pathname?.split('/')[location?.pathname?.split('/').length - 2])) {
          if (d?.name !== 'Calls' && d?.name !== 'Walk-Ins' && d?.name !== 'Meetings' && d?.name !== 'Landlord Meeting' && d?.name !== 'Property-Visit' && d?.name !== 'Payment Collection') {
            arr.push({ label: d?.name, value: d?._id })
          }
        } else {
          if (d?.name !== 'Calls' && d?.name !== 'Invoice Raised' && d?.name !== 'Walk-Ins' && d?.name !== 'Meetings' && d?.name !== 'Landlord Meeting' && d?.name !== 'Property-Visit' && d?.name !== 'Payment Collection') {
            arr.push({ label: d?.name, value: d?._id })
          }
        }

      }

      if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'create' && location?.state !== null && location?.state?.type === 'convert') {
        setdata({ ...location?.state?.data, stage: arr?.find(s => s.label === 'Contact') })
      }
    })
    response1.data.data.datas.forEach((d) => {
      arr1.push({ value: d._id, label: d.name })
    })
    response2.data.data.datas.forEach((d) => {
      arr2.push({ value: d._id, label: d.name })
    })
    response3.data.data.datas.forEach((d) => {
      arr3.push({ value: d._id, label: d.name })
    })
    setleadStatusArr(arr)
    setcontactSourceArr(arr1)
    setIndustyArr(arr2)
    setzoneArr(arr3)

    if (location?.pathname?.split('/')[2] === 'create' && location?.pathname?.split('/')[1] == 'contacts') {
      let lead_stage = response.data.data.datas.find((d) => d?.name === 'Contact')
      setdata({ ...data, stage: { value: lead_stage._id, label: lead_stage?.name } })
    }
  }

  async function getleadexistinfo() {
    let sendData = {
      "phone": data?.phone,
      "email": data?.email
    }
    if (data.phone !== '' && data.email !== '') {
      const response = await VerifyLeadsExistService(sendData)
      let d = response?.data?.data
      if (d !== null) {
        toast.error(`Lead With this email or phone already exist contact ${d?.department_id?.department_name} with the user ${d?.lead_owner?.name}`)
        setlead_exist_data(response?.data?.data)
      }
    }
  }


  async function getoperators(v) {
    const response = await GetOperatorsService(v, user_department)
    let arr = []
    response.data.forEach((d) => {
      arr.push({ value: d._id, label: d.name })
    })
    setOperatorsArr([...arr])
    return arr;
  }

  useEffect(() => {


    getbasicdatareq()

    if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') {
      geteditdata()
    } else if (location?.state !== null && location?.state?.type !== undefined && location?.state?.type === 'convert') {
      let datas = location?.state?.data
      delete datas._id
      delete datas.followup_owner
      delete datas.stage
      setold_Data({ ...datas })
      setdata({ ...datas })
    }
  }, [])


  useEffect(() => {
    if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'create') {
      if (data?.phone !== null || data?.email !== null) {
        getleadexistinfo()
      }
    }
  }, [location?.pathname, data?.phone, data?.email])

  async function geteditdata() {
    const response = await GetLeadsDetailService(location?.state);
    let d = response?.data?.datas[0]


    let operators_Arr = []
    if (d.operators.length > 0) {
      for (let i = 0; i < d.operators.length; i++) {
        operators_Arr.push({ value: d?.operators[i]?._id, label: d?.operators[i]?.name })
      }
    }

    let passData = {
      id: d?._id,
      department_id: d?.department_id,
      contact_name: d?.contact_name,
      phone: d?.phone,
      email: d?.email,
      designation: d?.designation,
      company_name: d?.company_name,
      secondary_contact_person: d?.secondary_contact_person,
      secondary_contact_phone: d?.secondary_contact_phone,
      secondary_contact_email: d?.secondary_contact_email,
      lead_source: (d?.lead_source !== undefined && d?.lead_source !== null) ? { value: d?.lead_source?._id, label: d?.lead_source?.name } : {},
      industry: (d?.industry !== undefined && d?.industry !== null) ? { value: d?.industry?._id, label: d?.industry?.name } : {},
      stage: (d?.stage !== undefined && d?.stage !== null) ? { value: d?.stage?._id, label: d?.stage?.name } : {},
      contact_stage: (d?.contact_stage !== undefined && d?.contact_stage !== null && d?.contact_stage !== '') ? { label: d?.contact_stage, value: d?.contact_stage } : '',
      zone: (d?.zone !== undefined && d?.zone !== null) ? { value: d?.zone?._id, label: d?.zone?.name } : {},
      sqft: d?.sqft,
      sqft_info: d?.sqft_info,
      seater: d?.seater,
      rent: d?.rent,
      sale: d?.sale,
      website: d?.website,
      requirment: d?.requirment,
      location: d?.location !== undefined ? d?.location : '',
      address: d?.address,
      operators: operators_Arr,
      transferred: d?.transferred,
      important: d?.important,
      color: d?.color,
      called_status: d?.called_status === undefined ? false : d?.called_status,
      follow_up: d?.follow_up === undefined ? false : d?.follow_up,
      follow_up_date: d?.follow_up_date === undefined ? '' : d?.follow_up_date?.slice(0, 10),
      follow_up_remark: d?.follow_up_remark === undefined ? false : d?.follow_up_remark,
      visiting_card: [undefined, null, 'undefined', 'null', '']?.includes(d?.visiting_card) ? '' : d?.visiting_card,
      revenue_estiamted: [undefined, null, 'undefined', 'null', '']?.includes(d?.revenue_estiamted) ? 0 : d?.revenue_estiamted,
    }
    setold_Data({ ...passData })
    setdata({ ...passData })

  }

  function handlechange(e) {
    setdata({ ...data, [e.target.name]: e.target.value })
    seterror({ ...error, [e.target.name]: '' })
  }

  function handlechange1(e) {
    setinvoiceData({ ...invoiceData, [e.target.name]: e.target.value })
    setinvoiceDataErr({ ...invoiceDataErr, [e.target.name]: '' })
  }



  async function submitform(stagedata) {

    if (!data.contact_name) {
      seterror({ ...error, contact_name: 'This Field is required*' })
    } else if (data?.stage?.label !== 'Contact' && !data.phone) {
      seterror({ ...error, phone: 'This Field is required*' })
    } else if (data?.stage?.label !== 'Contact' && data.phone.length !== 10) {
      seterror({ ...error, phone: 'Enter a valid 10 digit mobile no*' })
    } else if (data?.stage?.label !== 'Contact' && !data.email) {
      seterror({ ...error, email: 'This Field is required*' })
    } else if (data?.stage?.label !== 'Contact' && !data.designation) {
      seterror({ ...error, designation: 'This Field is required*' })
    } else if (data.secondary_contact_phone && data.secondary_contact_phone.length !== 10) {
      seterror({ ...error, secondary_contact_phone: 'Enter a valid 10 digit mobile no*' })
    } else if (!data.company_name) {
      seterror({ ...error, company_name: 'This Field is required*' })
    } else if (!data.lead_source) {
      seterror({ ...error, lead_source: 'This Field is required*' })
    } else if (!data.industry) {
      seterror({ ...error, industry: 'This Field is required*' })
    } else if (!data.stage) {
      seterror({ ...error, stage: 'This Field is required*' })
    } else if (data.stage.label !== 'Contact' && data?.stage?.label !== 'Calls' && data?.stage?.label !== 'Walk-Ins' && data?.stage?.label !== 'Property-Visit' && data?.stage?.label !== 'Meetings' && data?.stage?.label !== 'Waiting For Requirment' && data?.stage?.label !== 'Hold') {
      if (!data.zone.label && user_department === 'Transaction Team') {
        seterror({ ...error, zone: 'This Field is required*' })
      } else if (!data.requirment) {
        seterror({ ...error, requirment: 'This Field is requirment*' })
      } else if (!data.sqft_info && user_department === 'Transaction Team') {
        seterror({ ...error, sqft_info: 'This Field is requirment*' })
      } else if (!data.location && user_department === 'Transaction Team') {
        seterror({ ...error, location: 'This Field is requirment*' })
      } else {
        let send_data = {}
        send_data['contact_name'] = data.contact_name;
        send_data['phone'] = data.phone;
        send_data['email'] = data.email;
        send_data['designation'] = data.designation;
        send_data['department_id'] = location?.pathname?.split('/')[2] === 'edit' ? data.department_id : user.department_id[0]['id'];
        send_data['secondary_contact_person'] = data.secondary_contact_person;
        send_data['secondary_contact_phone'] = data.secondary_contact_phone;
        send_data['secondary_contact_email'] = data.secondary_contact_email;
        send_data['company_name'] = data.company_name;
        send_data['address'] = data.address;
        send_data['requirment'] = data.requirment;
        send_data['location'] = data.location;
        send_data['website'] = '';
        send_data['lead_source'] = data.lead_source?.value;
        send_data['industry'] = data.industry?.value;
        send_data['stage'] = (stagedata !== undefined && stagedata !== '' && stagedata !== null) ? stagedata?.value : stage?.selected_stage !== '' ? stage?.selected_stage?.value : data.stage?.value;
        send_data['contact_stage'] = data.contact_stage?.value === undefined ? data?.contact_stage : data.contact_stage?.value
        send_data['called_status'] = data.called_status
        send_data['follow_up'] = data.follow_up
        send_data['follow_up_date'] = data.follow_up_date
        send_data['follow_up_remark'] = data.follow_up_remark
        send_data['zone'] = data.zone?.value;
        send_data['sqft'] = data.sqft;
        send_data['sqft_info'] = data.sqft_info;
        send_data['seater'] = data.seater;
        send_data['rent'] = data.rent;
        send_data['visiting_card'] = data?.visiting_card;
        send_data['revenue_estiamted'] = data?.revenue_estiamted;
        send_data['sale'] = data.sale;
        if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') {
          send_data['color'] = colors[Math.abs(Math.floor(Math.random() * colors.length - 1))]
        }

        let arr = []
        if (data.operators.length > 0) {
          for (let i = 0; i < data.operators.length; i++) {
            arr.push(data?.operators[i]?.value)
          }
        }
        send_data['operators'] = arr;

        setloader(true)


        if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') {
          const response = await UpdateLeadsService(send_data, data?.id)
          if (response.status === 200) {
            let send_data1 = {}
            send_data1['leadId'] = data?.id
            send_data1['attachment_type'] = 'Lead'
            send_data1['stage_type'] = (stagedata !== undefined && stagedata !== '' && stagedata !== null) ? stagedata?.label : stage?.selected_stage?.label
            send_data1['stage'] = (stagedata !== undefined && stagedata !== '' && stagedata !== null) ? stagedata?.value : stage?.selected_stage?.value
            send_data1['summary'] = ''
            send_data1['type_id'] = data?.id

            send_data1['finalized'] = 0

            if (stage?.date !== undefined && stage?.date !== null && stage?.date !== '') {
              send_data1['datetime'] = moment(stage?.date).format('YYYY-MM-DD') + ' ' + moment(stage?.time).format('hh:mm')
            }

            if (stage?.file !== '' && stage?.file !== undefined) {
              send_data1['fileSize'] = stage?.file?.size
              send_data1['file'] = stage?.file
            }

            // send_data1['agreement_expire'] = aggrementData?.agreement_expire
            // send_data1['lock_in_period'] = aggrementData?.lock_in_period
            // send_data1['reminder_active'] = aggrementData?.reminder_active

            send_data1['agreement_expire'] = aggrementData?.agreement_expire
            send_data1['lock_in_period_from_date'] = aggrementData?.lock_in_period_from_date
            send_data1['lock_in_period_end_date'] = aggrementData?.lock_in_period_end_date
            send_data1['reminder_active'] = aggrementData?.reminder_active
            send_data1['lease_years'] = aggrementData?.lease_years
            send_data1['lease_end_date'] = aggrementData?.lease_end_date
            if (old_Data?.stage?.label !== stage?.selected_stage?.label && stagedata === null) {
              if (user?.department_id[0].department_name === 'Fidelitus Gallery' && stage?.selected_stage?.label == 'Follow Up') {
                send_data1['description'] = data?.requirment
              }

              await CreateLeadAttachmentFollowUpService(send_data1)
              setagreementData({ reminder_active: false, agreement_expire: '', lock_in_period: '' })
              setagreementDataErr({ reminder_active: '', agreement_expire: '', lock_in_period: '' })
              setloader(false)
            }
            toast.success('Lead Updated Successfully')
            resetform()
            navigate(-1)
          }
        } else {
          const response = await CreateLeadsService(send_data)
          if (response.status === 201) {
            setloader(false)
            // if(data?.stage?.label !== 'Contact'){
            let send_data1 = {}
            send_data1['leadId'] = response.data.datas?.id
            send_data1['attachment_type'] = 'Lead'
            send_data1['stage_type'] = (stagedata !== undefined && stagedata !== '' && stagedata !== null) ? stagedata?.label : data?.stage?.label
            send_data1['stage'] = (stagedata !== undefined && stagedata !== '' && stagedata !== null) ? stagedata?.value : data?.stage?.value
            send_data1['summary'] = ''
            send_data1['type_id'] = response.data.datas?.id

            send_data1['finalized'] = 0

            if (stage?.date !== undefined && stage?.date !== null && stage?.date !== '') {
              send_data1['datetime'] = moment(stage?.date).format('YYYY-MM-DD') + ' ' + moment(stage?.time).format('hh:mm')
            }

            if (stage?.file !== '' && stage?.file !== undefined) {
              send_data1['fileSize'] = stage?.file?.size
              send_data1['file'] = stage?.file
            }


            if (user?.department_id[0].department_name === 'Fidelitus Gallery' && stage?.selected_stage?.label == 'Follow Up') {
              send_data1['description'] = data?.requirment
            }
            // if(setold_Data?.stage?.label !== stage?.selected_stage?.label && data?.stage?.label !== 'Invoice Raised'){
            let response1 = await CreateLeadAttachmentFollowUpService(send_data1)
            setagreementData({ reminder_active: false, agreement_expire: '', lock_in_period_end_date: '', lock_in_period_from_date: '', lease_years: '', lease_end_date: '' })
            setagreementDataErr({ reminder_active: '', agreement_expire: '', lock_in_period_end_date: '', lock_in_period_from_date: '', lease_years: '', lease_end_date: '' })
            setloader(false)
            // }
            toast.success('Lead Created Successfully')
            resetform()
            // }
            // else{
            //   setloader(false)
            //   toast.success('Lead Created Successfully')
            //   navigate(-1)
            // }
          }
        }
      }
    } else {
      let send_data = {}
      send_data['contact_name'] = data?.contact_name;
      send_data['phone'] = data?.phone;
      send_data['email'] = data?.email;
      send_data['department_id'] = location?.pathname?.split('/')[2] === 'edit' ? data?.department_id : user.department_id[0]['id'];
      send_data['secondary_contact_person'] = data?.secondary_contact_person;
      send_data['secondary_contact_phone'] = data?.secondary_contact_phone;
      send_data['secondary_contact_email'] = data?.secondary_contact_email;
      send_data['company_name'] = data?.company_name;
      send_data['designation'] = data.designation;
      send_data['address'] = data?.address;
      send_data['requirment'] = data?.requirment;
      send_data['website'] = '';
      send_data['lead_source'] = data?.lead_source?.value;
      send_data['industry'] = data?.industry?.value;
      send_data['stage'] = (stagedata !== undefined && stagedata !== '' && stagedata !== null) ? stagedata?.value : data?.stage?.value
      send_data['contact_stage'] = data.contact_stage?.value === undefined ? data?.contact_stage : data.contact_stage?.value
      send_data['called_status'] = data.called_status
      send_data['follow_up'] = data.follow_up
      send_data['follow_up_date'] = data.follow_up_date
      send_data['follow_up_remark'] = data.follow_up_remark
      send_data['zone'] = data?.zone?.value;
      send_data['sqft'] = data?.sqft;
      send_data['sqft_info'] = data?.sqft_info;
      send_data['seater'] = data?.seater;
      send_data['rent'] = data?.rent;
      send_data['sale'] = data?.sale;
      send_data['visiting_card'] = data?.visiting_card;
      send_data['revenue_estiamted'] = data?.revenue_estiamted;
      send_data['color'] = colors[Math.abs(Math.floor(Math.random() * colors.length - 1))]
      let arr = []
      if (data?.operators?.length > 0) {
        for (let i = 0; i < data?.operators.length; i++) {
          arr.push(data?.operators[i]?.value)
        }
      }
      send_data['operators'] = arr



      if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') {
        setloader(true)
        const response = await UpdateLeadsService(send_data, data?.id)
        if (response.status === 200) {
          if (data?.stage?.label !== stage?.selected_stage?.label && data.stage.label !== 'Contact' && data?.stage?.label !== 'Calls' && data?.stage?.label !== 'Walk-Ins' && data?.stage?.label !== 'Property-Visit' && data?.stage?.label !== 'Meetings' && data?.stage?.label !== 'Hold') {
            let send_data1 = {}
            send_data1['leadId'] = data?.id
            send_data1['attachment_type'] = 'Lead'
            send_data1['stage_type'] = (stagedata !== undefined && stagedata !== '' && stagedata !== null) ? stagedata?.label : data?.stage?.label
            send_data1['stage'] = (stagedata !== undefined && stagedata !== '' && stagedata !== null) ? stagedata?.value : data?.stage?.value
            send_data1['summary'] = ''
            send_data1['file'] = stage?.file
            send_data1['finalized'] = 0
            send_data1['type_id'] = data?.id
            send_data1['datetime'] = ''
            send_data1['fileSize'] = stage?.file?.size

            send_data1['agreement_expire'] = aggrementData?.agreement_expire
            send_data1['lock_in_period_from_date'] = aggrementData?.lock_in_period_from_date
            send_data1['lock_in_period_end_date'] = aggrementData?.lock_in_period_end_date
            send_data1['reminder_active'] = aggrementData?.reminder_active
            send_data1['lease_years'] = aggrementData?.lease_years
            send_data1['lease_end_date'] = aggrementData?.lease_end_date

            if (data?.stage?.label !== 'Invoice Raised') {

              await CreateLeadAttachmentFollowUpService(send_data1)
              setloader(false)
              setagreementData({ reminder_active: false, agreement_expire: '', lock_in_period_end_date: '', lock_in_period_from_date: '', lease_years: '', lease_end_date: '' })
              setagreementDataErr({ reminder_active: '', agreement_expire: '', lock_in_period_end_date: '', lock_in_period_from_date: '', lease_years: '', lease_end_date: '' })
            }
            toast.success('Lead Updated Successfully')
            resetform()
            navigate(-1)
          } else {
            setloader(false)
            toast.success('Lead Updated Successfully')
            resetform()
            navigate(-1)
          }
        }
      } else {

        setloader(true)
        const response = await CreateLeadsService(send_data)
        if (response.status === 201) {
          // if(data?.stage?.label !== 'Contact'){
          let send_data1 = {}
          send_data1['leadId'] = response.data.datas?.id
          send_data1['attachment_type'] = 'Lead'
          send_data1['stage_type'] = (stagedata !== undefined && stagedata !== '' && stagedata !== null) ? stagedata?.label : data?.stage?.label
          send_data1['stage'] = (stagedata !== undefined && stagedata !== '' && stagedata !== null) ? stagedata?.value : data?.stage?.value
          send_data1['summary'] = ''
          send_data1['type_id'] = response.data.datas?.id
          send_data1['finalized'] = 0

          if (stage?.date !== undefined && stage?.date !== null && stage?.date !== '') {
            send_data1['datetime'] = moment(stage?.date).format('YYYY-MM-DD') + ' ' + moment(stage?.time).format('hh:mm')
          }

          if (stage?.file !== '' && stage?.file !== undefined) {
            send_data1['fileSize'] = stage?.file?.size
            send_data1['file'] = stage?.file
          }



          // if(((stagedata !== undefined && stagedata !== '' && stagedata !== null) ?  stagedata?.label : data?.stage?.label) === 'LOI/Agreement'){
          send_data1['agreement_expire'] = aggrementData?.agreement_expire
          send_data1['lock_in_period_from_date'] = aggrementData?.lock_in_period_from_date
          send_data1['lock_in_period_end_date'] = aggrementData?.lock_in_period_end_date
          send_data1['reminder_active'] = aggrementData?.reminder_active
          send_data1['lease_years'] = aggrementData?.lease_years
          send_data1['lease_end_date'] = aggrementData?.lease_end_date
          // }


          if (old_Data?.stage?.label !== stage?.selected_stage?.label) {
            if (user?.department_id[0].department_name === 'Fidelitus Gallery' && stage?.selected_stage?.label == 'Follow Up') {
              send_data1['description'] = data?.requirment
            }
            await CreateLeadAttachmentFollowUpService(send_data1)
            setagreementData({ reminder_active: false, agreement_expire: '', lock_in_period_end_date: '', lock_in_period_from_date: '', lease_years: '', lease_end_date: '' })
            setagreementDataErr({ reminder_active: '', agreement_expire: '', lock_in_period_end_date: '', lock_in_period_from_date: '', lease_years: '', lease_end_date: '' })
            setloader(false)
            toast.success('Lead Created Successfully')
            resetform()
            navigate(-1)
            setloader(false)
          } else {
            setloader(false)
            toast.success('Lead Created Successfully')
            resetform()
            navigate(-1)
          }
          // }
          // else{
          //   setloader(false)
          //   toast.success('Lead Created Successfully')
          //   resetform()
          // }
        }
      }
    }
  }

  function resetform() {
    setdata({
      department_id: '',
      contact_name: '',
      phone: '',
      email: '',
      designation: '',
      company_name: '',
      secondary_contact_person: '',
      secondary_contact_phone: '',
      secondary_contact_email: '',
      lead_source: '',
      industry: '',
      stage: '',
      zone: '',
      sqft: false,
      seater: false,
      rent: false,
      sale: false,
      website: '',
      requirment: '',
      address: '',
      operators: [],
      transferred: '',
      important: '',
      color: '',
      visiting_card: '',
      revenue_estiamted:''
    })

    seterror({
      department_id: '',
      contact_name: '',
      phone: '',
      email: '',
      designation: '',
      company_name: '',
      secondary_contact_person: '',
      secondary_contact_phone: '',
      secondary_contact_email: '',
      lead_source: '',
      industry: '',
      stage: '',
      zone: '',
      sqft: '',
      seater: '',
      rent: '',
      sale: '',
      website: '',
      requirment: '',
      address: '',
      operators: '',
      transferred: '',
      important: '',
      color: '',
      visiting_card: '',
      revenue_estiamted:''
    })

    setstage({ selected_stage: '', date: '', time: '', file: '' })
    setstageerr({ selected_stage: '', date: '', time: '', file: '' })
  }

  function checkdocsrequired(v) {
    if (v?.label !== data?.stage?.label) {
      // 'Follow Up',
      let labels = ['Contact', 'Calls', 'Walk-Ins', 'Property-Visit', 'Hold', 'BD', 'Non Payable Closure', 'Contact In Future', 'Lost', 'Hold', 'Closed', 'Assesment', 'Negotiation', 'Final Closure', 'Hold', 'Execution', 'Waiting For Requirment', 'Converted', 'In Progress']
      let labels1 = ['Inspection', 'Landlord Meeting', 'Meetings', 'Follow Up']


      if (location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit') {
        if (labels?.includes(v?.label)) {
          setdata({ ...data, stage: v })
          seterror({ ...error, stage: '' })
        } else if (labels1?.includes(v.label)) {
          setModal(true)
        } else {
          setModal(true)
        }
      } else {
        if (labels?.includes(v?.label)) {
          setdata({ ...data, stage: v })
          seterror({ ...error, stage: '' })
        } else if (labels1?.includes(v.label)) {
          setModal(true)
        } else {
          setModal(true)
        }
      }
    }
  }

  async function uploadfile(v, name) {
    const fd = new FormData()
    fd.append('file', v);
    const response = await UploadVisitingCardFile(fd)
    if (response?.status === 200) {
      setdata({ ...data, [name]: response?.data?.data })
      seterror({ ...error, [name]: '' })
    }
  }


  async function createInvoiceRequestfunc() {

    if (stage?.selected_stage?.label === 'Invoice Raised') {

      let send_data = {}
      send_data['leadId'] = data?.id
      send_data['attachment_type'] = 'Lead'
      send_data['stage_type'] = (stage !== undefined && stage !== '' && stage !== null) ? stage?.selected_stage?.label : data?.stage?.label
      send_data['stage'] = (stage !== undefined && stage !== '' && stage !== null) ? stage?.selected_stage?.value : data?.stage?.value
      send_data['summary'] = ''
      send_data['type_id'] = data?.id
      send_data['finalized'] = 0
      send_data['datetime'] = moment(invoiceData?.invoice_raising_date).format('YYYY-MM-DD')
      send_data['amount'] = parseInt(invoiceData?.amount?.split(',').join(''))
      send_data['payment'] = invoiceData?.payment
      send_data['gst_no'] = invoiceData?.gst_no
      send_data['pan_no'] = invoiceData?.pan_no
      send_data['office_space_closed'] = invoiceData?.office_space_closed


      if (user?.department_id[0].department_name === 'Fidelitus Gallery' && stage?.selected_stage?.label == 'Follow Up') {
        send_data['description'] = data?.requirment
      }
      const response = await CreateLeadAttachmentFollowUpService(send_data)
      if (response.status === 201) {
        let send_Data = { ...invoiceData }
        send_Data['client'] = data?.id
        send_Data['amount'] = parseInt(invoiceData?.amount?.split(',').join(''))
        send_Data['invoice_attachment_id'] = response?.data?.datas?.id
        send_Data['employee'] = user?.id

        const response1 = await CreateInvoiceRequest(send_Data)
        if (response1.status === 201) {
          setModal(false)
          setdata({ ...data, stage: stage })
          seterror({ ...error, stage: '' })
          submitform(stage?.selected_stage)
        }
      }
    }

  }
  // console.log("stage?.selected_stage?.label", stage?.selected_stage?.label)

  return (
    <>
      {list?.includes(path) ?
        <div className='pr-5 min-h-screen max-h-screen h-screen overflow-y-scroll' >


          <Modal
            open={modal}
            closable={false}
            maskClosable={false}
            onClose={() => setModal(false)}
            bodyStyle={{ borderRadius: '0px !important' }}
            style={{ maxWidth: '300px', borderRadius: '0px !important' }}
            footer={null}
          >
            <div style={{ maxWidth: '300px', borderRadius: '0px !important' }} className="relative z-0 ">
              {/* <h6>{stage?.selected_stage?.label}</h6> */}
              <IoIosClose size={20} className="absolute -right-4 -top-4 z-10" onClick={() => { setModal(false); setstage({ selected_stage: '', datetime: '', file: '' }) }} />
              {['Inspection', 'Landlord Meeting', 'Meetings', 'Meeting&Discussion', 'Client Meeting', 'Follow Up',]?.includes(stage?.selected_stage?.label) ?
                <>
                  <h6 className="font-bold text-[15px]  mb-2 w-full">Add a date and time for {stage?.selected_stage?.label}</h6>
                  <div className='flex'>
                    <div>
                      <div className='border'>
                        <DatePicker bordered={false} placeholder='' ampm={false} sx={{ height: '40px' }} inputFormat="MM/dd/yyyy" value={stage.date} onChange={(v) => { setstage({ ...stage, date: v }); setstageerr({ ...stageerr, date: '' }) }} />
                      </div>
                      {(stageerr?.date !== '' || stageerr?.time !== '') && <h6 className='text-red-600 text-[10px]'>{stageerr?.date}</h6>}
                    </div>
                    <p style={{ width: 10 }}></p>
                    <div>
                      <div className='border'>
                        <TimePicker bordered={false} placeholder='' format="hh:mm" ampm={false} sx={{ height: '40px' }} value={stage.time} onChange={(v) => { setstage({ ...stage, time: v }); setstageerr({ ...stageerr, time: '' }) }} />
                      </div>
                      {(stageerr?.date !== '' || stageerr?.time !== '') && <h6 className='text-red-600 text-[10px]'>{stageerr?.time}</h6>}
                    </div>
                  </div>
                  {(stage?.date !== undefined && stage?.time !== undefined) &&
                    <div className='mt-2'>
                      <ButtonFilled btnName={'Confirm'} onClick={() => { setModal(false); setdata({ ...data, stage: stage?.selected_stage }); seterror({ ...error, stage: '' }) }} />
                    </div>}
                </>
                :
                <>
                  {['Design', 'QS', 'MEP', 'BOQ', 'Proposal Sent', 'Agreement', 'Options Shared', 'LOI Signing', 'LOI/Agreement', 'Purchase Order','Utilization Report']?.includes(stage?.selected_stage?.label) || (stage?.selected_stage?.label === 'Invoice Raised' && user_department_name !== 'Transaction Team') ?
                    <>
                      <h6 className="font-bold text-[15px]  mb-2 w-full">Add a docs for {stage?.selected_stage?.label}</h6>
                      <Uploader image={stage?.file} setimagefunc={(e) => setstage({ ...stage, file: e })} removeimageuploadfunc={() => setstage({ ...stage, file: '' })} />
                      {(stage?.selected_stage?.label === 'LOI/Agreement') &&
                        <div>
                          <div className='flex items-center mt-2' onClick={() => setagreementData({ ...aggrementData, reminder_active: !aggrementData.reminder_active })}>
                            {!aggrementData?.reminder_active ? <BiCheckbox size={20} /> : <BiCheckboxSquare size={20} />}
                            <h6 className='text-[11px]'>LOI / Agreement Reminder</h6>
                          </div>

                          {aggrementData?.reminder_active && <>


                            <h6 className='text-[10px] font-[700] mt-2'>Lease Years</h6>
                            <div className='border w-[100%] mt-2 '>
                              <input type='text' style={{ width: '100%' }} className='p-1 focus:outline-none focus:ring-0' value={aggrementData?.lease_years} onChange={(v) => { setagreementData({ ...aggrementData, lease_years: v.target.value }); setagreementDataErr({ ...aggrementDataErr, lease_years: '' }) }} />
                            </div>


                            <div className='flex items-center'>
                              <div className='mr-[0px]'>
                                <h6 className='text-[10px] font-[700] mt-2'>Lock in period (<span className='text-[7px]'>From Date</span>) </h6>
                                <div className='border w-[90%] mt-2 '>
                                  <input type='date' style={{ width: '100%' }} className='p-1 border-l-[3px] border-l-slate-700 focus:outline-none focus:ring-0' value={aggrementData?.lock_in_period_from_date} onChange={(v) => { setagreementData({ ...aggrementData, lock_in_period_from_date: v.target.value }); setagreementDataErr({ ...aggrementDataErr, lock_in_period_from_date: '' }) }} />
                                </div>
                              </div>

                              <div >
                                <h6 className='text-[10px] font-[700] mt-2'>Lock in period (<span className='text-[7px]'>To Date</span>)</h6>
                                <div className='border w-[90%] mt-2 '>
                                  <input type='date' style={{ width: '100%' }} className='p-1 border-l-[3px] border-l-slate-700 focus:outline-none focus:ring-0' value={aggrementData?.lock_in_period_end_date} onChange={(v) => { setagreementData({ ...aggrementData, lock_in_period_end_date: v.target.value }); setagreementDataErr({ ...aggrementDataErr, lock_in_period_end_date: '' }) }} />
                                </div>
                              </div>
                            </div>

                            <h6 className='text-[11px] font-[700] mt-2'>Agreement Expire</h6>
                            <div className='border mt-2'>
                              <input type='date' sx={{ height: '40px' }} style={{ width: '100%' }} className='p-1 border-l-[3px] border-l-slate-700 focus:outline-none focus:ring-0' value={aggrementData?.agreement_expire} onChange={(v) => { setagreementData({ ...aggrementData, agreement_expire: v.target.value }); setagreementDataErr({ ...aggrementDataErr, agreement_expire: '' }) }} />
                            </div>

                            <h6 className='text-[11px] font-[700] mt-2'>Lease End Date</h6>
                            <div className='border mt-2'>
                              <input type='date' sx={{ height: '40px' }} style={{ width: '100%' }} className='p-1 focus:outline-none focus:ring-0' value={aggrementData?.lease_end_date} onChange={(v) => { setagreementData({ ...aggrementData, lease_end_date: v.target.value }); setagreementDataErr({ ...aggrementDataErr, lease_end_date: '' }) }} />
                            </div>
                          </>}

                        </div>
                      }

                      {(stage?.file !== '' && stage?.file !== undefined && stage?.file !== null)
                        &&
                        (aggrementData?.reminder_active && aggrementData?.lock_in_period_from_date && aggrementData?.lock_in_period_end_date && aggrementData?.agreement_expire) ?
                        <div className='mt-2'>
                          <ButtonFilled btnName={'Confirm'} onClick={() => { setModal(false); setdata({ ...data, stage: stage?.selected_stage }); seterror({ ...error, stage: '' }) }} />
                        </div>
                        :
                        <>
                          {(!aggrementData?.reminder_active && (stage?.file !== '' && stage?.file !== undefined && stage?.file !== null)) &&
                            <div className='mt-2'>
                              <ButtonFilled btnName={'Confirm'} onClick={() => { setModal(false); setdata({ ...data, stage: stage?.selected_stage }); seterror({ ...error, stage: '' }) }} />
                            </div>}
                        </>
                      }
                    </>
                    :
                    <>
                      <h6 className="font-bold text-[15px]  mb-2 w-full">Add Info to raise Invoice For Finance</h6>


                      <h6 className='text-[11px] leading-tight bg-slate-100 p-1'>Please add the amount without gst and in description please add the details of the client like <span className='font-[700]'>[Billing To ,Gst No , other information related to invoice raise]</span></h6>

                      <div className='mb-2'>
                        <TextInput
                          mandatory={true}
                          label={'Amount'}
                          variant="standard"
                          name="amount"
                          type="text"
                          value={invoiceData.amount}
                          error={invoiceDataErr.amount}
                          handlechange={handlechange1} />
                      </div>

                      <div style={{ width: '100%' }}>
                        <h6 className='text-[12px] font-[600] mb-1' >{'Invoice Raise Date'}</h6>
                        <div
                          className='border-l-4 border-l-slate-600 w-full border outline-0 rounded-[0px] focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'

                        >
                          <DatePicker
                            placeholder=''
                            bordered={false}
                            ampm={false}
                            style={{ maxHeight: '40px', borderRadius: '3px', width: '100%' }}
                            inputFormat="MM/dd/yyyy" value={invoiceData.invoice_raising_date} onChange={(v) => { setinvoiceData({ ...invoiceData, invoice_raising_date: v }); setinvoiceDataErr({ ...invoiceDataErr, invoice_raising_date: '' }) }} />
                        </div>
                        {(invoiceDataErr?.invoice_raising_date !== '') && <h6 className='text-red-600 text-[10px]'>{invoiceDataErr?.invoice_raising_date}</h6>}
                      </div>

                      <TextInput
                        label={'Gst No'}
                        variant="standard"
                        name="gst_no"
                        type="text"
                        value={invoiceData.gst_no}
                        error={invoiceDataErr.gst_no}
                        handlechange={handlechange1} />

                      <TextInput
                        label={'Pan No'}
                        variant="standard"
                        name="pan_no"
                        type="text"
                        value={invoiceData.pan_no}
                        error={invoiceDataErr.pan_no}
                        handlechange={handlechange1} />

                      <h6 className='text-[12px] font-[600] mb-1' >{'Office Space Closed'}</h6>
                      <TextArea style={{ borderRadius: '2px' }} value={invoiceData.office_space_closed} onChange={(e) => setinvoiceData({ ...invoiceData, office_space_closed: e.target.value })} />


                      <div className='flex items-center mt-2'>
                        <div className='flex items-center mt-0 mr-2'>
                          {invoiceData?.payment === '1' ? <BiCheckboxSquare size={22} className='text-slate-600' onClick={() => setinvoiceData({ ...invoiceData, payment: '' })} /> : <BiCheckbox size={22} className='text-slate-300' onClick={() => setinvoiceData({ ...invoiceData, payment: '1' })} />}

                          {/* <input type={'checkbox'} checked={invoiceData?.payment === '1'} onChange={()=>setinvoiceData({...invoiceData,payment:'1'})} />  */}
                          <h6 className="text-[13px] font-[600] ml-2">Cash</h6>
                        </div>

                        <div className='flex items-center mt-0 mr-2'>
                          {invoiceData?.payment === '0' ? <BiCheckboxSquare size={22} className='text-slate-600' onClick={() => setinvoiceData({ ...invoiceData, payment: '' })} /> : <BiCheckbox size={22} className='text-slate-300' onClick={() => setinvoiceData({ ...invoiceData, payment: '0' })} />}
                          {/* <input type={'checkbox'} checked={invoiceData?.payment === '0'} onChange={()=>setinvoiceData({...invoiceData,payment:'0'})} />  */}
                          <h6 className="text-[13px] font-[600] ml-2">Online</h6>
                        </div>
                      </div>


                      <h6 className='text-[12px] font-[600] mb-1' >{'Invoice Details'}</h6>
                      <TextArea style={{ borderRadius: '0px' }} value={invoiceData.other_details} onChange={(e) => setinvoiceData({ ...invoiceData, other_details: e.target.value })} />

                      {(invoiceData?.amount !== '' && invoiceData?.invoice_raising_date !== '' && invoiceData?.invoice_raising_date !== undefined && invoiceData?.invoice_raising_date !== null) &&
                        <div className='mt-2'>
                          <ButtonFilled btnName={'Confirm'} onClick={createInvoiceRequestfunc} />
                        </div>
                      }
                    </>}


                </>
              }
            </div>
          </Modal>


          <div className='w-[50%] min-w-[50%] max-w-[50%] '>
            <GoBack />
            <div className='border-b  pb-2'>
              <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit' ? 'Edit' : 'Create'}  Lead</h6>
              <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the Lead for your reference to get the continous task progress made by you all the time.</h6>
            </div>
            <Grid container spacing={2}  >
              <Grid item xs={12} md={6} >
                <TextInput
                  mandatory={true}
                  label={'Name'}
                  variant="standard"
                  name="contact_name"
                  type="text"
                  value={data.contact_name}
                  error={error.contact_name}
                  handlechange={handlechange}
                  placeholder="Enter contact name"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />
                <TextInput
                  mandatory={data?.stage?.label !== 'Contact'}
                  label={'Phone'}
                  variant="standard"
                  name="phone"
                  type="text"
                  value={data.phone}
                  error={error.phone}
                  handlechange={handlechange}
                  placeholder="Enter contact phone"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />
                <TextInput
                  mandatory={data?.stage?.label !== 'Contact'}
                  label={'Email'}
                  variant="standard"
                  name="email"
                  type="text"
                  value={data.email}
                  error={error.email}
                  handlechange={handlechange}
                  placeholder="Enter contact email"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />
                <TextInput
                  mandatory={data?.stage?.label !== 'Contact'}
                  label={'Designation'}
                  variant="standard"
                  name="designation"
                  type="text"
                  value={data.designation}
                  error={error.designation}
                  handlechange={handlechange}
                  placeholder="Enter Designation"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />

                <h6 className="font-bold text-[11px]  my-2 w-full">Visiting Card</h6>
                <Uploader2 image={data?.visiting_card} setimagefunc={(e) => uploadfile(e, 'visiting_card')} removeimageuploadfunc={() => setdata({ ...data, visiting_card: '' })} />

                <TextInput
                  label={'Secondary Contact'}
                  variant="standard"
                  name="secondary_contact_person"
                  type="text"
                  value={data.secondary_contact_person}
                  error={error.secondary_contact_person}
                  handlechange={handlechange}
                  placeholder="Enter secondary contact name"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />
                <TextInput
                  label={'Secondary Phone'}
                  variant="standard"
                  name="secondary_contact_phone"
                  type="text"
                  value={data.secondary_contact_phone}
                  error={error.secondary_contact_phone}
                  handlechange={handlechange}
                  placeholder="Enter secondary contact phone"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />
                <TextInput
                  label={'Secondary Email'}
                  variant="standard"
                  name="secondary_contact_email"
                  type="text"
                  value={data.secondary_contact_email}
                  error={error.secondary_contact_email}
                  handlechange={handlechange}
                  placeholder="Enter secondary contact email"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />

                {user_department === 'Transaction Team' &&
                  <>
                    {data?.stage?.label !== 'Contact' && data?.stage?.label !== 'Calls' && data?.stage?.label !== 'Walk-Ins' && data?.stage?.label !== 'Property-Visit' && data?.stage?.label !== 'Meetings' && data?.stage?.label !== 'Hold' && data.stage !== '' &&
                      <div >
                        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Measurment</h6>

                        <div className='flex'>
                          <div className='flex items-center mt-0 mr-2'>
                            {data?.sqft ? <BiCheckboxSquare size={22} className='text-slate-600' onClick={() => setdata({ ...data, sqft: !data.sqft })} /> : <BiCheckbox size={22} className='text-slate-300' onClick={() => setdata({ ...data, sqft: !data.sqft })} />}
                            <h6 className="text-[11.5px] font-[600] ml-1">Sqft</h6>
                          </div>
                          <div className='flex items-center mt-0 mr-2'>
                            {data?.seater ? <BiCheckboxSquare size={22} className='text-slate-600' onClick={() => setdata({ ...data, seater: !data.seater })} /> : <BiCheckbox size={22} className='text-slate-300' onClick={() => setdata({ ...data, seater: !data.seater })} />}
                            <h6 className="text-[11.5px] font-[600] ml-1">Seater</h6>
                          </div>
                          <div className='flex items-center mt-0 mr-2'>
                            {data?.rent ? <BiCheckboxSquare size={22} className='text-slate-600' onClick={() => setdata({ ...data, rent: !data.rent })} /> : <BiCheckbox size={22} className='text-slate-300' onClick={() => setdata({ ...data, rent: !data.rent })} />}
                            <h6 className="text-[11.5px] font-[600] ml-1">Rent</h6>
                          </div>
                          <div className='flex items-center mt-0 mr-2'>
                            {data?.sale ? <BiCheckboxSquare size={22} className='text-slate-600' onClick={() => setdata({ ...data, sale: !data.sale })} /> : <BiCheckbox size={22} className='text-slate-300' onClick={() => setdata({ ...data, sale: !data.sale })} />}
                            <h6 className="text-[11.5px] font-[600] ml-1">Sale</h6>
                          </div>
                        </div>

                      </div>}
                  </>}

              </Grid>
              <Grid item xs={12} md={6} >
                <TextInput
                  mandatory={true}
                  label={'Company Name'}
                  variant="standard"
                  name="company_name"
                  type="text"
                  value={data.company_name}
                  error={error.company_name}
                  handlechange={handlechange}
                  placeholder="Enter company name"
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />
                <TextAreaInput1
                  label={'Company Address'}
                  variant="standard"
                  name="address"
                  type="text"
                  value={data.address}
                  error={error.address}
                  handlechange={handlechange}
                  InputLabelProps={{
                    style: { color: '#fff', },
                  }} />

{/*  */}
                <h6 className='text-[12px] font-semibold mb-1 mt-1'>Contact Source</h6>
                <Select
                  value={data.lead_source}
                  error={error.lead_source}
                  placeholder=""
                  bordered={false}
                  onChange={(e) => handleSelect(e, 'Country')}
                  styles={customStyles}
                  options={contactSourceArr}
                  style={{ fontSize: '12px' }}
                  className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />
                {error.lead_source !== '' && error.lead_source !== undefined &&
                  <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{error.lead_source}</span>
                  </div>}

                <h6 className='text-[12px] font-semibold mb-1 mt-1'>Industry </h6>
                <Select
                  value={data.industry}
                  error={error.industry}
                  placeholder=""
                  bordered={false}
                  onChange={(e) => handleSelect(e, 'Industry')}
                  styles={customStyles}
                  options={IndustyArr}
                  className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'

                />
                {error.industry !== '' && error.industry !== undefined &&
                  <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{error.industry}</span>
                  </div>}





                <h6 className='text-[12px] font-semibold mb-1 mt-1'>Lead Status</h6>
                <Select
                  value={data.stage}
                  error={error.stage}
                  bordered={false}
                  placeholder=""
                  onChange={(e) => handleSelect(e, 'LeadStage')}
                  styles={customStyles}
                  options={leadStatusArr}
                  style={{ borderRadius: '0px' }}
                  className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />
                {error.stage !== '' && error.stage !== undefined &&
                  <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{error.stage}</span>
                  </div>}

                {data?.stage?.label === 'Contact' &&
                  <>
                    <div className='flex items-center mt-2'>
                      <div className='flex items-center mt-0 mr-2'>
                        {data?.called_status ? <BiCheckboxSquare size={22} className='text-slate-600' onClick={() => setdata({ ...data, called_status: !data.called_status })} /> : <BiCheckbox size={22} className='text-slate-300' onClick={() => setdata({ ...data, called_status: !data.called_status })} />}
                        <h6 className="text-[11.5px] font-[600] ml-1">Called Status</h6>
                      </div>
                      <div className='flex items-center mt-0 mr-2'>
                        {data?.follow_up ? <BiCheckboxSquare size={22} className='text-slate-600' onClick={() => setdata({ ...data, follow_up: !data.follow_up })} /> : <BiCheckbox size={22} className='text-slate-300' onClick={() => setdata({ ...data, follow_up: !data.follow_up })} />}
                        <h6 className="text-[11.5px] font-[600] ml-1">Follow up</h6>
                      </div>
                    </div>

                    <h6 className='text-[12px] font-semibold mb-1 mt-1'>Contact Stage</h6>
                    <Select
                      value={data.contact_stage}
                      error={error.contact_stage}
                      bordered={false}
                      placeholder=""
                      onChange={(e) => handleSelect(e, 'contact_stage')}
                      styles={customStyles}
                      options={contact_stages}
                      style={{ borderRadius: '0px' }}
                      className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                    />
                    {error.contact_stage !== '' && error.contact_stage !== undefined &&
                      <div className='flex items-center mt-1'>
                        <BiErrorCircle className='text-red-500' size={14} />
                        <span className='text-[10px] text-red-500 ml-1'>{error.contact_stage}</span>
                      </div>}
                  </>}




                {data?.stage?.label !== 'Contact' && data?.stage?.label !== 'Calls' && data?.stage?.label !== 'Walk-Ins' && data?.stage?.label !== 'Property-Visit' && data?.stage?.label !== 'Meetings' && data?.stage?.label !== 'Hold' && data.stage?.label !== 'Follow Up' && data.stage?.label !== 'Waiting For Requirment' && data.stage !== '' && <>

                  {/* <div sx={{display:{lg:'none',sm:'block'}}}>
            <h6 className='text-[12px] font-semibold mb-0 mt-1'>Measurment</h6>

            
            
                <div className='flex'>
                <div className='flex items-center mt-0 mr-2'>
                <input className='border border-slate-600' type={'checkbox'} checked={data?.sqft} onChange={()=>setdata({...data,sqft:!data.sqft})} /> 
                <h6 className="text-[13px] font-[600] ml-2">Sqft</h6> 
                </div>  
                <div className='flex items-center mt-0 mr-2'>
                <input type={'checkbox'} checked={data?.seater} onChange={()=>setdata({...data,seater:!data.seater})} /> 
                <h6 className="text-[13px] font-[600] ml-2">Seater</h6> 
                </div>  
                <div className='flex items-center mt-0 mr-2'>
                <input type={'checkbox'} checked={data?.rent} onChange={()=>setdata({...data,rent:!data.rent})} /> 
                <h6 className="text-[13px] font-[600] ml-2">Rent</h6> 
                </div>  
                <div className='flex items-center mt-0 mr-2'>
                <input type={'checkbox'} checked={data?.sale} onChange={()=>setdata({...data,sale:!data.sale})} /> 
                <h6 className="text-[13px] font-[600] ml-2">Sale</h6> 
                </div>
                </div>  

            </div> */}



                  {user_department_name === 'Transaction Team' &&
                    <>
                      <h6 className='text-[12px] font-semibold mb-1 mt-1'>Zone</h6>
                      <Select
                        value={data.zone}
                        error={error.zone}
                        bordered={false}
                        placeholder=""
                        onChange={(e) => handleSelect(e, 'Zone')}
                        styles={customStyles}
                        options={zoneArr}
                        className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                      />
                      {error.zone !== '' && error.zone !== undefined &&
                        <div className='flex items-center mt-1'>
                          <BiErrorCircle className='text-red-500' size={14} />
                          <span className='text-[10px] text-red-500 ml-1'>{error.zone}</span>
                        </div>}


                      <TextInput
                        label={'SQFT/SEATER/ACRES'}
                        variant="standard"
                        name="sqft_info"
                        type="text"
                        value={data.sqft_info}
                        error={error.sqft_info}
                        mandatory={true}
                        handlechange={handlechange}
                        placeholder=""
                        InputLabelProps={{
                          style: { color: '#fff', },

                        }} />

                      <TextInput
                        mandatory={true}
                        label={'Location'}
                        variant="standard"
                        name="location"
                        type="text"
                        value={data.location}
                        error={error.location}
                        handlechange={handlechange}
                        placeholder=""
                        className='border-l-2 border-l-slate-600 rounded-md'
                        InputLabelProps={{
                          style: { color: '#fff', },

                        }} />
                    </>}







                </>}

                <TextAreaInput1

                  mandatory={true}
                  label={'Requirment'}
                  variant="standard"
                  name="requirment"
                  type="text"
                  value={data.requirment}
                  error={error.requirment}
                  handlechange={handlechange}
                  placeholder=""
                  className='border-l-2 border-l-slate-600 rounded-md'
                  InputLabelProps={{
                    style: { color: '#fff', },

                  }} />

                  {user_department_name === 'Transaction Team' &&
                  <>
                    <h6 className='text-[12px] font-semibold mb-1 mt-1'>Operators</h6>
                    <Select
                      showSearch
                      mode="multiple"
                      optionFilterProp="children"
                      bordered={false}
                      value={data.operators}
                      error={error.operators}
                      placeholder=""
                      onSearch={getoperators}
                      onChange={(e) => handleSelect(e, 'Operator')}
                      //  loadOptions={getoperators}
                      styles={customStyles}
                      options={OperatorsArr}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                    />


                    {error.operators !== '' && error.operators !== undefined &&
                      <div className='flex items-center mt-1'>
                        <BiErrorCircle className='text-red-500' size={14} />
                        <span className='text-[10px] text-red-500 ml-1'>{error.operators}</span>
                      </div>}



                  </>}

                  {(user_department_name === 'Fidelitus Gallery' ||  user_department_name === 'Shilpa Foundation' || user?.roles?.includes('admin')) &&
                  <TextInput
                          mandatory={false}
                          label={'Revenue Estimated'}
                          variant="standard"
                          name="revenue_estiamted"
                          type="date"
                          value={data.revenue_estiamted}
                          error={error.revenue_estiamted}
                          handlechange={handlechange}
                          placeholder=""
                          className='border-l-2 border-l-slate-600 rounded-md'
                          InputLabelProps={{
                            style: { color: '#fff', },

                          }} />
                        }

                {data?.stage?.label === 'Contact' &&
                  <>

                    {data?.follow_up &&
                      <>

                        <TextInput
                          mandatory={false}
                          label={'Follow Up Date'}
                          variant="standard"
                          name="follow_up_date"
                          type="date"
                          value={data.follow_up_date}
                          error={error.follow_up_date}
                          handlechange={handlechange}
                          placeholder=""
                          className='border-l-2 border-l-slate-600 rounded-md'
                          InputLabelProps={{
                            style: { color: '#fff', },

                          }} />

                        <TextAreaInput1
                          mandatory={false}
                          label={'Remarks'}
                          variant="standard"
                          name="follow_up_remark"
                          type="text"
                          value={data.follow_up_remark}
                          error={error.follow_up_remark}
                          handlechange={handlechange}
                          placeholder=""
                          className='border-l-2 border-l-slate-600 rounded-md'
                          InputLabelProps={{
                            style: { color: '#fff', },

                          }} />
                      </>}
                  </>}


              </Grid>
            </Grid>


            <div >
              <div className='flex items-center mt-5 mb-10  border-t pt-5'>
                <div className='mr-2'>
                  <ButtonOutlined btnName={'Back'} onClick={() => navigate(-1)} />
                </div>
                <div>
                  {loader ?
                    <ButtonFilled btnName={loader ? 'Uploading' : 'Save'} />
                    :
                    <ButtonFilled btnName={loader ? 'Uploading' : 'Save'} onClick={() => submitform(null)} />}
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <DailyTaskCE />
      }
    </>
  )
}

export default LeadCE